import React from "react";
import { Prerequisites } from "../../../model/Syllabus";
import H4 from "components/viewer/customization/H4";
import Container from "../../viewer/customization/Container";
import RichTextEditorViewer from "../../ui-kit/RichTextEditor/Viewer/RichTextEditorViewer";
import { createHash } from "../../../helpers/createHash";

interface PrerequisitesSectionProps {
  section: Prerequisites;
}

// Prerequisites viewer component. All prerequisite texts are specified in markdown
const PrerequisitesSection: React.FC<PrerequisitesSectionProps> = (props) => {
  const section = props.section;
  return (
    <>
      <H4 a11yTitle={"Prerequisites Section"}>Prerequisite(s)</H4>
      {section.prerequisites.map((prerequisite, index) => {
        return (
          <div key={"prerequisite-" + index}>
            <Container>
              <RichTextEditorViewer
                value={prerequisite.body}
                key={createHash(JSON.stringify(prerequisite.body))}
              />
            </Container>
          </div>
        );
      })}
    </>
  );
};

export default PrerequisitesSection;
