import { Syllabus } from "../../../model/Syllabus";
import {
  SyllabusTemplateActions,
  SyllabusTemplateActionType,
} from "../types/SyllabusTemplateTypes";

export interface SyllabusTemplateState {
  tempSyllabus: Syllabus[];
  school: string;
}

const initialState: SyllabusTemplateState = {
  tempSyllabus: [],
  school: "",
};

export const SyllabusTemplateReducer = (
  state = initialState,
  action: SyllabusTemplateActions
): SyllabusTemplateState => {
  switch (action.type) {
    case SyllabusTemplateActionType.SaveTempSyllabus:
      return {
        ...state,
        tempSyllabus: [
          ...state.tempSyllabus.filter(
            (s) => s.parentId !== action.payload.parentId
          ),
          action.payload,
        ],
      };
    case SyllabusTemplateActionType.DeleteTempSyllabus:
      return {
        ...state,
        tempSyllabus: state.tempSyllabus.filter(
          (syllabus) => syllabus.parentId !== action.payload
        ),
      };
    case SyllabusTemplateActionType.SaveSchool:
      return {
        ...state,
        school: action.payload,
      };
    default:
      return state;
  }
};
