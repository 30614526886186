import React from "react";
import { Blank, IconProps } from "grommet-icons";
import { IconColors } from "../../theme/GlobalTheme";
import { ChildrenIconProps } from "./FileTypeIcon";

const XLS: React.FC<IconProps & ChildrenIconProps> = (props) => {
  return (
    <Blank viewBox="0 0 39 42" {...props} color="plain">
      <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
      >
        <path
          fill="white"
          d="M14.81.61H8A2.29,2.29,0,0,0,5.67,2.91V21.19A2.29,2.29,0,0,0,8,23.48H20.54a2.29,2.29,0,0,0,1.62-.67,2.32,2.32,0,0,0,.66-1.62V8.61Z"
        />
        <path
          fill={
            props.disabled
              ? IconColors["icon-disabled"]
              : IconColors["icon-dark-blue"]
          }
          d="M14.57,1.19l7.67,7.66V21.18a1.7,1.7,0,0,1-.5,1.21,1.67,1.67,0,0,1-1.21.51H8a1.71,1.71,0,0,1-1.72-1.71V2.9a1.74,1.74,0,0,1,.5-1.21A1.72,1.72,0,0,1,8,1.19h6.62m.24-.58H8A2.29,2.29,0,0,0,5.67,2.91V21.19A2.29,2.29,0,0,0,8,23.48H20.54a2.29,2.29,0,0,0,1.62-.67,2.32,2.32,0,0,0,.66-1.62V8.61l-8-8Z"
        />
        <path
          fill={
            props.disabled
              ? IconColors["icon-disabled"]
              : IconColors["icon-light-blue"]
          }
          d="M14.81.9V6.33A2.29,2.29,0,0,0,17.1,8.61h5.43Z"
        />
        <path
          fill={
            props.disabled
              ? IconColors["icon-disabled"]
              : IconColors["icon-dark-blue"]
          }
          d="M22.53,8.89H17.1a2.57,2.57,0,0,1-2.57-2.57V.9A.27.27,0,0,1,14.7.64.27.27,0,0,1,15,.7l7.72,7.71a.28.28,0,0,1-.2.48ZM15.1,1.59V6.33a2,2,0,0,0,2,2h4.74Z"
        />
        <rect
          fill={
            props.disabled
              ? IconColors["icon-disabled"]
              : IconColors["icon-blue"]
          }
          x="1.11"
          y="11.19"
          width="20"
          height="9.14"
          rx="0.86"
        />
        <path
          fill={
            props.disabled
              ? IconColors["icon-disabled"]
              : IconColors["icon-dark-blue"]
          }
          d="M20.25,11.75a.29.29,0,0,1,.28.29v7.43a.29.29,0,0,1-.29.28H2a.28.28,0,0,1-.29-.28V12.05A.3.3,0,0,1,2,11.76H20.25m0-.57H2a.87.87,0,0,0-.86.86v7.43a.86.86,0,0,0,.86.86l18.28,0a.86.86,0,0,0,.86-.85V12a.85.85,0,0,0-.85-.86Z"
        />
        <path
          fill="white"
          d="M8.54,18.1l-1-1.55-.9,1.55h-1l1.45-2.31L5.59,13.51H6.65l1,1.54.89-1.54h1L8.14,15.82,9.6,18.1Z"
        />
        <path fill="white" d="M11.22,17.37h1.52v.73H10.31V13.51h.92Z" />
        <path
          fill="white"
          d="M14,18a1.4,1.4,0,0,1-.61-.48,1.27,1.27,0,0,1-.23-.73h1a.63.63,0,0,0,.2.45.7.7,0,0,0,.5.16.76.76,0,0,0,.5-.15.51.51,0,0,0,.19-.4.55.55,0,0,0-.13-.34.85.85,0,0,0-.31-.21c-.13-.05-.3-.1-.52-.16a6.16,6.16,0,0,1-.72-.26,1.29,1.29,0,0,1-.48-.39,1.05,1.05,0,0,1-.21-.69,1.19,1.19,0,0,1,.21-.71A1.21,1.21,0,0,1,14,13.6a2.17,2.17,0,0,1,.84-.16,1.81,1.81,0,0,1,1.15.35,1.26,1.26,0,0,1,.49,1h-1a.5.5,0,0,0-.2-.39.77.77,0,0,0-.5-.16.65.65,0,0,0-.43.14.53.53,0,0,0-.16.4.39.39,0,0,0,.13.31.93.93,0,0,0,.3.2l.51.17a5.38,5.38,0,0,1,.73.26,1.23,1.23,0,0,1,.48.4,1.05,1.05,0,0,1,.21.69A1.3,1.3,0,0,1,15.78,18a1.86,1.86,0,0,1-.87.19A2.21,2.21,0,0,1,14,18Z"
        />
      </svg>
    </Blank>
  );
};
export default XLS;
