import React, { useState } from "react";
import {
  Box,
  DataTable,
  Keyboard,
  Text,
  ThemeContext,
  ThemeType,
} from "grommet";
import styled from "styled-components";
import { CourseTitle, Syllabus } from "model/Syllabus";
import Sortable from "../../../assets/icons/Sortable";
import ArrowTop from "../../../assets/icons/ArrowTop";
import ArrowBottom from "../../../assets/icons/ArrowBottom";
import { format } from "date-fns";
import { useHistory } from "react-router-dom";
import DeleteSyllabusConfirmModal from "../../modals/DeleteSyllabusConfirmModal";
import Delete from "../../../assets/icons/Delete";

interface TableProps {
  /**
   * The syllabus connected to this card
   */
  syllabus: Syllabus[];
  /**
   * Action to take when clicking 'Show'
   */
  onClickShow?: () => void;
  /**
   * Action to take when clicking 'Edit'
   */
  onClickEdit?: () => void;
  onDeleteSyllabus: (syllabusId: string) => void;
}

const ColumnName = styled(Text)`
  font-family: Poppins, serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  color: #474e61;

  &.warning {
    align-content: center;
    justify-content: center;
  }
`;

const StyledDelete = styled(Delete)`
  color: #e51144;
`;

const RowText = styled(Text)`
  margin: 10px 12px;

  &.primary {
    font-family: Open Sans, serif;
    font-size: 15px;
    font-weight: 600;
    line-height: 20px;
    color: #030b1d;
  }

  &.secondary {
    font-family: Open Sans, serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    color: #030b1d;
  }
`;

/**
 * A card for displaying basic syllabus information on the dashboard screen.  Includes buttons to show or edit the syllabus.
 */
const Table: React.FC<TableProps> = (props) => {
  const history = useHistory();
  const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState<boolean>(false);
  const [deleteSyllabusId, setDeleteSyllabusId] = useState<string | undefined>(
    undefined
  );

  const renderDeleteDialog = (
    <DeleteSyllabusConfirmModal
      isOpen={isOpenDeleteDialog}
      onClickCancel={() => {
        setIsOpenDeleteDialog(false);
      }}
      onClickAction={async () => {
        if (deleteSyllabusId !== undefined) {
          props.onDeleteSyllabus(deleteSyllabusId);
          setDeleteSyllabusId(undefined);
          setIsOpenDeleteDialog(false);
        }
      }}
    />
  );

  const customTheme: ThemeType = {
    dataTable: {
      header: {
        extend: ({ column, sort, sortable }) => `
         ${
           sortable &&
           sort &&
           sort.property !== column &&
           `
              :hover {
                svg {
                  opacity: 100%;
                }
              }
           `
         }
         `,
      },
      body: {
        extend: `
          border-collapse: separate;
          border-spacing:0 8px;
          margin-bottom: 20px;
          & thead th {
            border-bottom: none;
          }
          
          & tbody tr {
            background: #FFFFFF;
            border: 1px solid #F1F6FF;
            box-sizing: border-box;
            border-radius: 10px;
            margin-bottom: 8px;
          }
          
          & tbody tr:hover {
            background: #FFFFFF;
            border: 1px solid #F1F6FF;
            box-sizing: border-box;
            box-shadow: 0px 2px 5px rgba(0, 30, 97, 0.15);
            border-radius: 10px;
            cursor: pointer;
          }
          
          & tbody tr th:first-child {
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
          }
          
          & tbody tr td:last-child {
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
          }
        `,
      },
      icons: {
        sortable: Sortable,
        ascending: ArrowTop,
        descending: ArrowBottom,
      },
    },
  };

  return (
    <ThemeContext.Extend value={customTheme}>
      <DataTable
        pad={{
          body: {
            horizontal: "0px",
          },
        }}
        columns={[
          {
            header: <ColumnName>Name & Number</ColumnName>,
            property: "name",
            size: "300px",
          },
          {
            header: <ColumnName>Institute</ColumnName>,
            property: "institute",
            size: "300px",
          },
          {
            header: <ColumnName>Semester</ColumnName>,
            property: "semester",
            size: "160px",
          },
          {
            header: <ColumnName>Course Number</ColumnName>,
            property: "number",
            size: "170px",
          },
          {
            header: <ColumnName>Date of Creation</ColumnName>,
            property: "creation",
            size: "210px",
          },
          {
            header: <ColumnName>Last Modification</ColumnName>,
            property: "modified",
            size: "210px",
          },
          {
            property: "options",
            sortable: false,
            align: "end",
            size: "40px",
          },
        ]}
        onClickRow={(event) => {
          history.push("/edit/" + event.datum.id);
        }}
        data={
          props.syllabus.length > 0
            ? props.syllabus.map((s) => {
                const courseTitle = s.sections.find(
                  (section) => section.type === "CourseTitle"
                ) as CourseTitle | undefined;
                return {
                  id: s.firebaseId,
                  name: (
                    <Keyboard
                      onEnter={() => {
                        history.push("/edit/" + s.firebaseId);
                      }}
                    >
                      <RowText tabIndex={0} className="primary">
                        {courseTitle?.title || "Course Title Not Found"}
                      </RowText>
                    </Keyboard>
                  ),
                  institute: (
                    <RowText className="secondary">
                      {courseTitle?.school}
                    </RowText>
                  ),
                  semester: (
                    <RowText className="secondary">
                      {courseTitle?.semester}
                    </RowText>
                  ),
                  number: (
                    <RowText className="secondary">
                      {courseTitle?.courseNumber}
                    </RowText>
                  ),
                  creation: (
                    <RowText className="secondary">
                      {format(s.createdAt!, "MM/dd/yyyy")}
                    </RowText>
                  ),
                  modified: (
                    <RowText className="secondary">
                      {format(s.createdAt!, "MM/dd/yyyy")}
                    </RowText>
                  ),
                  options: (
                    <Box
                      alignSelf="center"
                      aria-label="Delete Syllabus"
                      onClick={(
                        e: React.MouseEvent<
                          HTMLButtonElement | HTMLAnchorElement,
                          MouseEvent
                        >
                      ) => {
                        e.stopPropagation();
                        setDeleteSyllabusId(s.firebaseId);
                        setIsOpenDeleteDialog(true);
                      }}
                    >
                      <StyledDelete />
                    </Box>
                  ),
                };
              })
            : []
        }
      />
      {props.syllabus.length === 0 && (
        <Box align="center" pad={{ vertical: "medium" }}>
          <ColumnName className="warning">
            You don't have any syllabus yet.{" "}
          </ColumnName>
        </Box>
      )}
      {renderDeleteDialog}
    </ThemeContext.Extend>
  );
};

export default Table;
