import React, { useContext } from "react";
import styled from "styled-components";
import ScheduleTable from "./ScheduleTable";
import { Module, Schedule } from "../../../model/Syllabus";
import WeekRow from "./WeekRow";
import TableHeader from "./TableHeader";
import TableRow from "./TableRow";
import Th from "./Th";
import TableBody from "./TableBody";
import { Activity } from "../../../model/Resource";
import { SyllabusThemeContext } from "../../viewer/customization/SyllabusTheme";

interface ScheduleTableWrapperProps {
  schedule: Schedule;
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
  onClickWeek?: (week: Module) => void;
  onClickActivity?: (activity: Activity) => void;
}

const Wrapper = styled.div<{ borderColor: string }>`
  border-radius: 10px;
  border: 1px solid ${(props) => props.borderColor};
  @media (max-width: 1200px) {
    overflow-x: auto;
  }
`;

const ScheduleTableWrapper: React.FC<ScheduleTableWrapperProps> = (props) => {
  const { colorScheme } = useContext(SyllabusThemeContext);

  return (
    <Wrapper onClick={props.onClick} borderColor={colorScheme?.lines!}>
      <ScheduleTable>
        <TableHeader>
          <TableRow>
            <Th>W</Th>
            <Th>Date</Th>
            <Th>Reading & Homework</Th>
            <Th>Learning Objectives</Th>
          </TableRow>
        </TableHeader>
        <TableBody>
          {props.schedule.modules.map((week, index) => (
            <WeekRow
              key={week.id}
              module={week}
              onClickWeek={props.onClickWeek}
              onClickActivity={props.onClickActivity}
              index={index}
            />
          ))}
        </TableBody>
      </ScheduleTable>
    </Wrapper>
  );
};

export default ScheduleTableWrapper;
