export enum MicrosoftTeamsActionType {
  SetTokensMicrosoftTeams = "SetTokensMicrosoftTeams",
}

export interface SetTokens {
  type: typeof MicrosoftTeamsActionType.SetTokensMicrosoftTeams;
  payload: {
    refreshToken: string;
    accessToken: string;
  };
}

export type MicrosoftTeamsActions = SetTokens;
