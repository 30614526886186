import React, { forwardRef } from "react";
import styled from "styled-components";
import clsx from "clsx";
import { Box, Button, Text } from "grommet";
import { FileType } from "../../../theme/GlobalTheme";
import FileTypeIcon from "../../../assets/icons/FileTypeIcon";
import Download from "../../../assets/icons/Download";
import Loading from "../../../assets/icons/Loading";
import Reorder from "../../../assets/icons/Reorder";
import EyeOn from "../../../assets/icons/EyeOn";
import Exit from "../../../assets/icons/Exit";
import truncate from "../../../utils/truncate";

export type FileButtonMode = "student-view" | "teacher-view" | "teacher-editor";

interface FileButtonProps {
  disabled?: boolean;
  loading?: boolean;
  fileType: FileType;
  mode?: FileButtonMode;
  fileName: string;
  fileDate?: string;
  fileInfo?: string;
  onClickPreview?: (event: React.MouseEvent) => void;
  onClickDelete?: (event: React.MouseEvent) => void;
  onClickDownload?: (event: React.MouseEvent) => void;
  url?: string;
  id?: string;
}

const defaultProps: Partial<FileButtonProps> = {
  mode: "student-view",
};

const Container = styled(Box)`
  &.container {
    min-height: 54px !important;
    background: #eff3ff;
    border-radius: 4px;
    width: 311px;
    box-sizing: border-box;
    border: 1px solid transparent;

    &.student-view {
      padding: 7px 18px;
    }

    &.teacher-view {
      padding: 7px 4px 7px 13px;
    }

    &.teacher-editor {
      padding: 0 4px 0 13px;
    }
  }

  &:hover {
    background: #dde6ff;
  }

  &:active {
    background: #dde6ff;
    border: 1px solid #0d3af8;
    box-sizing: border-box;
  }

  &.disabled {
    background: #ffffff;
    border: 1px solid #9da2ac;
    cursor: not-allowed;

    .iconColor {
      color: #9da2ac;
    }
  }
`;

const FileName = styled(Text)`
  font-family: Open Sans, serif;

  &.name {
    font-size: 14px;
    font-weight: 600;
    line-height: 19px;
    color: #030b1d;

    &.disabled {
      color: #9da2ac;
    }
  }

  &.info {
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #474e61;

    &.disabled {
      color: #9da2ac;
    }
  }
`;

const StyledButton = styled(Button)`
  align-content: center;
  justify-content: center;

  &.disabled {
    cursor: not-allowed;
  }
`;

const StyledIcon = styled(Button)`
  padding: 0 !important;

  &.iconColor {
    color: #2256f2;
  }

  &.disabled {
    cursor: not-allowed;

    &.iconColor {
      color: #9da2ac;
    }
  }
`;

const FileIcons = styled(Box)`
  align-content: center;
  justify-content: center;

  &.iconColor {
    color: #2256f2;
  }

  &.disabled {
    &.iconColor {
      color: #9da2ac;
    }
  }
`;

const StyledIconsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  color: #2256f2;
  margin-left: 8px;
`;

const StyledReorder = styled(Reorder)`
  color: #193da9;
`;

const StyledExit = styled(Exit)`
  transform: scale(1);
`;

const StyledEyeOn = styled(EyeOn)`
  transform: scale(0.7);
`;

const FileButton: React.FC<FileButtonProps> = forwardRef((props) => {
  const renderIcon = () => {
    if (props.mode === "teacher-editor") {
      if (props.loading) {
        return <StyledIcon icon={<Loading color="currentColor" />} />;
      } else {
        return (
          <>
            <StyledIcon
              onClick={(event: React.MouseEvent) =>
                !props.disabled && props.onClickDelete?.(event)
              }
              icon={<StyledExit disabled={props.disabled} />}
            />
            <StyledIcon
              onClick={() =>
                !props.disabled && window.open(props.url, "_blank")
              }
              icon={<StyledEyeOn disabled={props.disabled} />}
            />
          </>
        );
      }
    } else if (props.mode === "teacher-view") {
      return null;
    } else if (props.mode === "student-view") {
      return (
        <StyledIcon
          icon={<Download color="currentColor" />}
          onClick={(event: React.MouseEvent) => {
            event.stopPropagation();
            !props.disabled && props.onClickDownload?.(event);
          }}
        />
      );
    }
  };

  return (
    <Container
      data-drag-handle
      direction="row"
      className={clsx({
        container: true,
        disabled: props.disabled,
        "student-view": props.mode === "student-view",
        "teacher-view": props.mode === "teacher-view",
        "teacher-editor": props.mode === "teacher-editor",
      })}
    >
      <StyledButton
        onClick={(event: React.MouseEvent) => {
          if (props.mode === "student-view") {
            props.onClickPreview?.(event);
          }
        }}
        className={clsx({
          disabled: props.disabled,
        })}
      >
        <Box direction="row" align="center" justify="center">
          {props.mode === "teacher-editor" && (
            <FileIcons
              margin={{ right: "5px", left: "-5px" }}
              className="iconColor"
            >
              <StyledReorder />
            </FileIcons>
          )}

          <FileIcons margin={{ right: "10px" }}>
            <FileTypeIcon fileType={props.fileType} disabled={props.disabled} />
          </FileIcons>
          <Box width="190px">
            <FileName
              className={clsx({
                name: true,
                disabled: props.disabled,
              })}
            >
              {truncate(props.fileName, 45)}
            </FileName>
            <FileName
              className={clsx({
                info: true,
                disabled: props.disabled,
              })}
            >
              {["GoogleBook", "Video", "GoogleCustomSearchResult"].includes(
                props.fileType
              )
                ? `${truncate(props.url ?? "", 25)}`
                : props.mode === "teacher-editor" ||
                  props.mode === "teacher-view"
                ? `${props.fileDate ?? ""} | ${truncate(
                    props.fileInfo ?? "",
                    25
                  )}`
                : truncate(props.fileInfo ?? "", 25)}
            </FileName>
          </Box>
        </Box>
      </StyledButton>
      <StyledIconsWrapper>{renderIcon()}</StyledIconsWrapper>
    </Container>
  );
});

FileButton.defaultProps = defaultProps;

export default FileButton;
