import { Syllabus } from "../model/Syllabus";
import { convertFbTimestampToDate } from "../utils/fbUtil";
import * as firebase from "firebase";

const firestore = firebase.firestore();

class PublicSyllabusRepository {
  static collectionName = "syllabi_public";

  async findById(id: string) {
    const syllabus = await firestore
      .collection(PublicSyllabusRepository.collectionName)
      .doc(id)
      .get();
    const result = convertFbTimestampToDate(syllabus.data()) as Syllabus;
    return result;
  }

  async save(id: string, syllabus: Syllabus) {
    await firestore
      .collection(PublicSyllabusRepository.collectionName)
      .doc(id)
      .set(syllabus);
  }
}

export default PublicSyllabusRepository;
