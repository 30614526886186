import React, { useState } from "react";
import { useSelector } from "react-redux";
import { AppState, useAppSelector } from "../redux/configureStore";
import { Instructors, Syllabus } from "../model/Syllabus";
import { addYears } from "date-fns";
import SyllabusFactory from "../model/SyllabusFactory";
import { Box, Footer, Heading, Layer } from "grommet";
import styled from "styled-components";
import RoundedButton from "./ui-kit/RoundedButton/RoundedButton";
import Create from "../assets/icons/Create";
import Table from "./ui-kit/Table/Table";
import createEmptySection from "./editor/SectionFactory";
import TextInput from "./ui-kit/TextInput/TextInput";
import Select from "./ui-kit/Select/Select";
import { SyllabusValidator } from "model/SyllabusValidator/SyllabusValidator";
import SyllabusErrorBag from "model/SyllabusValidator/SyllabusErrorBag";

interface SyllabusListViewerProps {
  syllabi: Syllabus[];
  handleSave: (emptySyllabus: Syllabus) => void;
  onDeleteSyllabus: (syllabusId: string) => void;
}

const ContainerBox = styled(Box)`
  margin: 33px 40px;
`;

const Header = styled(Heading)`
  font-family: Poppins;
  font-size: 22px;
  font-weight: 500;
  line-height: 33px;
  color: #030b1d;
`;

const StyledFooter = styled(Footer)`
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid gray;
  padding: 12px 5%;
`;

const StyledLayer = styled(Layer)`
  width: 70%;
  max-height: 550px;
  margin-top: 25px;
`;

const ContentBox = styled(Box)`
  padding: 16px 10%;
  min-height: 475px;
`;

const semestersList = [
  { key: "", label: "None" },
  {
    key: "Spring",
    label: "Spring",
  },
  { key: "Summer", label: "Summer" },
  { key: "Fall", label: "Fall" },
  { key: "Winter", label: "Winter" },
];
const yearsList = [
  new Date().getFullYear().toString(),
  addYears(new Date(), 1).getFullYear().toString(),
  addYears(new Date(), 2).getFullYear().toString(),
];
const yearListOptions = [
  { key: "", label: "None" },
  { key: yearsList[0], label: yearsList[0] },
  { key: yearsList[1], label: yearsList[1] },
  { key: yearsList[2], label: yearsList[2] },
];

/**
 * The dashboard component for listing a users available common.  These are pulled from firebase using the useGetMySyllabi hook.  Clicking the 'Add Syllabus' button opens a modal to either direct users to the template gallery or input basic information for creating a new syllabus.
 */
const SyllabusListViewer: React.FC<SyllabusListViewerProps> = (props) => {
  const auth = useSelector((state: AppState) => state.firebase.auth);
  const userProfile = useAppSelector((state) => state.firebase.profile);
  const [openDialog, setOpenDialog] = useState(false);
  const [newTitle, setNewTitle] = useState("");
  const [newCourseNumber, setNewCourseNumber] = useState("");
  const [newSemester, setNewSemester] = useState(semestersList[0].key);
  const [newYear, setNewYear] = useState(yearListOptions[0].key);
  const [school, setSchool] = useState("");

  const [errorBag, setErrorBag] = useState<SyllabusErrorBag>({
    success: true,
    sections: [],
  });
  const courseTitleErrors = errorBag.sections.find(
    (s) => s.section.type === "CourseTitle"
  );

  const handleClose = () => {
    window.analytics.track("Closed Create Syllabus Dialog");
    setOpenDialog(false);
  };

  const handleClick = () => {
    setNewTitle("");
    setNewCourseNumber("");
    setNewSemester("");
    setNewYear("");
    setSchool("");
    window.analytics.track("Clicked Add Syllabus");
    setOpenDialog(true);
  };

  const renderDialog = () => {
    return (
      <Box aria-label="Course Name - Course Number">
        {openDialog && (
          <StyledLayer onEsc={handleClose} onClickOutside={handleClose}>
            <ContentBox>
              <h3>Create a Syllabus</h3>
              <Box margin="small">
                <TextInput
                  required={true}
                  label="Course Name"
                  placeholder="Course Name"
                  value={newTitle}
                  onChange={(e) => {
                    setNewTitle(e.target.value);
                  }}
                  a11yTitle="Course Name"
                  error={courseTitleErrors?.errors.title}
                />
              </Box>
              <Box margin="small">
                <TextInput
                  label="Course Number"
                  required={true}
                  placeholder="Course Number"
                  a11yTitle="Course Number"
                  value={newCourseNumber}
                  onChange={(e) => {
                    setNewCourseNumber(e.target.value);
                  }}
                  error={courseTitleErrors?.errors.courseNumber}
                />
              </Box>
              <Box margin="small">
                <TextInput
                  label="School"
                  placeholder="School"
                  a11yTitle="School"
                  value={school ? school : userProfile.school}
                  onChange={(e) => {
                    setSchool(e.target.value);
                  }}
                />
              </Box>
              <Box
                pad={{ vertical: "xsmall", horizontal: "small" }}
                gap="medium"
                direction="row"
              >
                <Box>
                  <Select
                    label="Year"
                    a11yTitle="Year (optional)"
                    value={{
                      key: newYear,
                    }}
                    onChange={(option) => {
                      setNewYear(option.key);
                    }}
                    options={yearListOptions}
                    error={courseTitleErrors?.errors.year}
                  />
                </Box>
                <Box>
                  <Select
                    label="Semester"
                    a11yTitle="Semester (optional)"
                    value={{
                      key: newSemester,
                    }}
                    onChange={(option) => {
                      setNewSemester(option.key);
                    }}
                    options={semestersList}
                    error={courseTitleErrors?.errors.semester}
                  />
                </Box>
              </Box>
            </ContentBox>
            <StyledFooter>
              <Box>
                <RoundedButton
                  type="tertiary"
                  variant="blue"
                  size="regular"
                  onClick={handleClose}
                >
                  Cancel
                </RoundedButton>
              </Box>
              <Box>
                <RoundedButton
                  type="primary"
                  onClick={() => {
                    const syllabusFactory = new SyllabusFactory();
                    const emptySyllabus = syllabusFactory.create({
                      createdBy: auth.uid,
                      title: newTitle,
                      semester: `${newSemester} ${newYear}`,
                      courseNumber: newCourseNumber,
                      school: school ? school : userProfile.school,
                    });
                    const instructor = {
                      name: userProfile.firstName + " " + userProfile.lastName,
                      email: userProfile.email,
                      phone: "",
                    };
                    const instructors = createEmptySection(
                      "Instructors"
                    ) as Instructors;
                    instructors.instructors.push(instructor);
                    emptySyllabus.sections.push(instructors);

                    const syllabusValidator = new SyllabusValidator();
                    const validation =
                      syllabusValidator.validate(emptySyllabus);
                    if (!validation.success) {
                      setErrorBag(validation);
                      return;
                    }

                    props.handleSave(emptySyllabus);
                  }}
                >
                  Save
                </RoundedButton>
              </Box>
            </StyledFooter>
          </StyledLayer>
        )}
      </Box>
    );
  };

  return (
    <>
      <ContainerBox direction="column">
        <Box
          direction="row"
          margin={{ bottom: "12px" }}
          align="center"
          gap="30px"
        >
          <Header>Your Syllabus</Header>
          <RoundedButton
            type="primary"
            variant="blue"
            size="regular"
            icon={<Create />}
            onClick={handleClick}
          >
            Create New Syllabus
          </RoundedButton>
          {renderDialog()}
        </Box>
        <Table
          syllabus={props.syllabi}
          onDeleteSyllabus={(syllabusId) => props.onDeleteSyllabus(syllabusId)}
        />
      </ContainerBox>
    </>
  );
};

export default SyllabusListViewer;
