import React from "react";
import styled from "styled-components";

interface CalendarItemProps {
  label: string;
  subject: string;
}

const StyledCalendarItem = styled.div`
  & .label {
    border-radius: 6px;
    background: #dde6ff;
    font-family: "Open Sans", serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #030b1d;
    text-align: left;
    padding: 10px;
    z-index: 8;
    position: relative;
  }

  & .subject {
    border-radius: 6px;
    background: #193da9;
    font-family: "Poppins", serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 12px;
    color: #ffffff;
    text-align: left;
    padding: 13px 5px 5px 10px; // Padding top has +8 in addition to compensate the -8px added by the margin
    margin-top: -8px;
    z-index: 7;
    position: relative;
  }
`;

const CalendarItem: React.FC<CalendarItemProps> = (props) => {
  return (
    <StyledCalendarItem>
      <div className="label">{props.label}</div>
      <div className="subject">{props.subject}</div>
    </StyledCalendarItem>
  );
};

export default CalendarItem;
