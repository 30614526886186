import React from "react";
import { CourseTitle, Schedule, Syllabus } from "../../model/Syllabus";
import SectionComponent from "../sections/view/SectionComponent";
import styled from "styled-components";
import { Box } from "grommet";
import Toc from "./Toc";
import { useHistory, useLocation } from "react-router-dom";
import OneWeekPlanHeader from "../one-week-plan/OneWeekPlanHeader/OneWeekPlanHeader";
import SyllabusViewerContextProvider from "./SyllabusViewerContextProvider";
import SyllabusThemeContextProvider from "./customization/SyllabusTheme";
import RichTextEditorContextProvider from "../ui-kit/RichTextEditor/RichTextEditorContext";
import RoundedButton from "components/ui-kit/RoundedButton/RoundedButton";
import { useAppSelector } from "redux/configureStore";
import { Profile } from "model/User";
import { HidableComponent } from "components/hoc/Hidable";

const Root = styled.div`
  min-height: 100vh;
  background: #fbfdff;
`;

const Content = styled(Box)`
  background: #ffffff;
  border: 1px solid #f1f6ff;
  box-sizing: border-box;
  box-shadow: 0 0 8px 1px rgba(7, 26, 67, 0.05);
  border-radius: 6px;
  margin: 35px;
  height: 100%;
  padding: 75px;

  & a {
    color: #2256f2;
  }
`;

export interface NormalMode {
  type: "normal";
  onClickLinkSyllabusToStudent?: () => void;
}

export interface OneWeekPlanMode {
  type: "one-week-plan";
  onClickContinueEditing: () => void;
}

export type Mode = NormalMode | OneWeekPlanMode;

interface SyllabusViewerProps {
  /**
   * the syllabus to view
   */
  syllabus: Syllabus;

  mode?: Mode;

  publicMode?: boolean;

  clickOnPrivateContent?: () => void;
}

/**
 * The publicly available syllabus viewer for students/users without edit access to a syllabus.
 */
const SyllabusViewer: React.FC<SyllabusViewerProps> = ({
  syllabus,
  mode,
  publicMode,
  clickOnPrivateContent,
}) => {
  const courseTitle = syllabus.sections.find(
    (section) => section.type === "CourseTitle"
  ) as CourseTitle;

  const history = useHistory();
  const location = useLocation().pathname;
  const profile = useAppSelector(
    (state) => state.firebase.profile
  ) as unknown as Profile;

  const renderedSyllabus = (
    <Content role="Syllabus Container">
      <RichTextEditorContextProvider
        mode="student-view"
        unlocked={publicMode || syllabus.password === ""}
        clickOnPrivateContent={() => clickOnPrivateContent?.()}
      >
        {syllabus.sections.map((section, index) => (
          <Box background="white" pad={{ vertical: "xsmall" }} key={index}>
            <SectionComponent
              id={"section-" + index}
              key={"section-" + index}
              section={section}
              onClickActivity={(activity) => {
                if (activity) {
                  if (section.type === "Schedule") {
                    const schedule = section as Schedule;
                    const mIndex =
                      schedule.modules.findIndex((module) =>
                        module.activities.some((a) => a.id === activity.id)
                      ) || 0;
                    const aIndex =
                      schedule.modules[mIndex].activities.indexOf(activity) ||
                      0;
                    history.push(
                      `${location}/module/${mIndex}#activity-${aIndex}`
                    );
                  }
                }
              }}
              onClickWeek={(module) => {
                if (section.type === "Schedule") {
                  const schedule = section as Schedule;
                  const mIndex = schedule.modules.indexOf(module) || 0;
                  history.push(`${location}/module/${mIndex}`);
                }
              }}
            />
          </Box>
        ))}
      </RichTextEditorContextProvider>
    </Content>
  );

  return (
    <SyllabusViewerContextProvider publicMode={publicMode}>
      <SyllabusThemeContextProvider
        fontFamily={syllabus.customization.fontFamily}
        primaryColor={syllabus.customization.primaryColor}
      >
        <RichTextEditorContextProvider mode="student-view">
          <Root role={"Syllabus Viewer"}>
            <Toc syllabus={syllabus} onClick={() => {}} />
            <Box
              margin={{ left: "19em" }}
              a11yTitle={`Syllabus Viewer ${courseTitle.title}, move to your left to navigate from the sidebar`}
            >
              {mode && mode.type === "one-week-plan" && (
                <OneWeekPlanHeader onClick={mode.onClickContinueEditing} />
              )}
              <HidableComponent
                isVisible={
                  profile &&
                  profile.role === "Student" &&
                  !profile.syllabi?.some((s) => s === syllabus.firebaseId)
                }
              >
                <Box
                  justify="end"
                  direction="row"
                  margin={{ top: "30px" }}
                  pad={"0 30px"}
                >
                  <Box width="300px">
                    <RoundedButton
                      onClick={async () => {
                        mode?.type === "normal" &&
                          mode?.onClickLinkSyllabusToStudent?.();
                        return;
                      }}
                    >
                      Link Syllabus
                    </RoundedButton>
                  </Box>
                </Box>
              </HidableComponent>
              {renderedSyllabus}
            </Box>
          </Root>
        </RichTextEditorContextProvider>
      </SyllabusThemeContextProvider>
    </SyllabusViewerContextProvider>
  );
};

export default SyllabusViewer;
