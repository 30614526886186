import React from "react";
import { Blank, IconProps } from "grommet-icons";
import { IconColors } from "../../theme/GlobalTheme";
import { ChildrenIconProps } from "./FileTypeIcon";

const PPT: React.FC<IconProps & ChildrenIconProps> = (props) => {
  return (
    <Blank viewBox="0 0 39 42" {...props} color="plain">
      <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
      >
        <path
          fill="white"
          d="M14.81.59H8A2.29,2.29,0,0,0,5.67,2.89V21.17A2.29,2.29,0,0,0,8,23.46H20.54a2.29,2.29,0,0,0,1.62-.67,2.32,2.32,0,0,0,.66-1.62V8.59Z"
        />
        <path
          fill={
            props.disabled
              ? IconColors["icon-disabled"]
              : IconColors["icon-dark-blue"]
          }
          d="M14.57,1.17l7.67,7.66V21.16a1.7,1.7,0,0,1-.5,1.21,1.67,1.67,0,0,1-1.21.51H8a1.66,1.66,0,0,1-1.22-.5,1.7,1.7,0,0,1-.5-1.21V2.89a1.74,1.74,0,0,1,.5-1.22A1.72,1.72,0,0,1,8,1.17h6.62m.24-.58H8A2.29,2.29,0,0,0,5.67,2.89V21.17A2.29,2.29,0,0,0,8,23.46H20.54a2.29,2.29,0,0,0,1.62-.67,2.32,2.32,0,0,0,.66-1.62V8.59l-8-8Z"
        />
        <path
          fill={
            props.disabled
              ? IconColors["icon-disabled"]
              : IconColors["icon-light-blue"]
          }
          d="M14.81.88V6.31A2.29,2.29,0,0,0,17.1,8.59h5.43Z"
        />
        <path
          fill={
            props.disabled
              ? IconColors["icon-disabled"]
              : IconColors["icon-dark-blue"]
          }
          d="M22.53,8.88H17.1a2.57,2.57,0,0,1-2.57-2.57V.88A.27.27,0,0,1,14.7.62.27.27,0,0,1,15,.68l7.72,7.71a.27.27,0,0,1,.06.31A.28.28,0,0,1,22.53,8.88ZM15.1,1.57V6.31a2,2,0,0,0,2,2h4.74Z"
        />
        <rect
          fill={
            props.disabled
              ? IconColors["icon-disabled"]
              : IconColors["icon-blue"]
          }
          x="1.11"
          y="11.17"
          width="20"
          height="9.14"
          rx="0.86"
        />
        <path
          fill={
            props.disabled
              ? IconColors["icon-disabled"]
              : IconColors["icon-dark-blue"]
          }
          d="M20.25,11.73a.29.29,0,0,1,.28.29v7.43a.28.28,0,0,1-.29.28L2,19.75a.29.29,0,0,1-.29-.29V12A.28.28,0,0,1,2,11.75l18.29,0m0-.57H2A.87.87,0,0,0,1.1,12v7.43a.86.86,0,0,0,.86.86H20.25a.87.87,0,0,0,.86-.86V12a.86.86,0,0,0-.85-.86Z"
        />
        <path
          fill="white"
          d="M8.84,15.6a1.26,1.26,0,0,1-.55.52,2,2,0,0,1-.93.2H6.59v1.76H5.67V13.49H7.36a1.93,1.93,0,0,1,.9.19,1.25,1.25,0,0,1,.56.5,1.4,1.4,0,0,1,.19.73A1.47,1.47,0,0,1,8.84,15.6Zm-1-.2a.65.65,0,0,0,.19-.49c0-.44-.25-.67-.75-.67H6.59v1.34h.73A.77.77,0,0,0,7.88,15.4Z"
        />
        <path
          fill="white"
          d="M12.83,15.6a1.24,1.24,0,0,1-.54.52,2,2,0,0,1-.94.2h-.77v1.76H9.66V13.49h1.69a2.08,2.08,0,0,1,.91.18,1.32,1.32,0,0,1,.56.51,1.47,1.47,0,0,1,.19.73A1.42,1.42,0,0,1,12.83,15.6Zm-.95-.2a.66.66,0,0,0,.18-.49c0-.45-.25-.67-.75-.67h-.73v1.33h.73A.79.79,0,0,0,11.88,15.4Z"
        />
        <path
          fill="white"
          d="M16.78,13.49v.74H15.56v3.84h-.92V14.23H13.41v-.74Z"
        />
      </svg>
    </Blank>
  );
};
export default PPT;
