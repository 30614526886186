import React, { useEffect, useState } from "react";
import RoundedButton from "../ui-kit/RoundedButton/RoundedButton";
import styled from "styled-components";
import { Box } from "grommet";
import CustomModal from "../modals/CustomModal";
import Delete from "../../assets/icons/Delete";
import PasswordInput from "../ui-kit/PasswordInput/PasswordInput";

interface PublishSyllabusModalProps {
  opened: boolean;
  onClickConfirm?: (password: string) => void;
  onClickCancel?: () => void;
  onClickDelete?: () => void;
  errorMessage?: string;
  title?: string;
  description?: string;
  onFocus?: () => void;
  isNewPassword?: boolean;
  password?: string;
}

const ModalWrapper = styled.div`
  .primary-text {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #193da9;
  }

  .secondary-text {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #474e61;
  }

  .reset-password {
    font-size: 12px;
    color: #2256f2;
    text-align: right;

    & span:hover,
    :focus-visible {
      cursor: pointer;
      color: #0d3af8;
    }
  }
`;

const StyledRoundedButton = styled(RoundedButton)`
  width: 292px;
  padding: 0 !important;
  align-self: center;
  margin-top: 38px;
`;

const PublishSyllabusModal: React.FC<PublishSyllabusModalProps> = (props) => {
  const [password, setPassword] = useState<string>("");
  const [repeatPassword, setRepeatPassword] = useState<string>("");
  const [_isNewPassword] = useState(props.isNewPassword);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setPassword("");
  }, [props.opened]);

  return (
    <CustomModal
      handleClose={() => props.onClickCancel?.()}
      opened={props.opened}
      size="regular"
      closeButton={true}
    >
      <ModalWrapper>
        <>
          <p className="primary-text">Syllabus Password</p>
          <p className="secondary-text">
            Create a password to protect your syllabus and make it private.
            Students and faculty members who wish to view your syllabus will
            have to enter the password to be able to do it.
          </p>
        </>
        <Box alignContent="center" justify="center">
          <Box width="425px" alignSelf="center">
            <PasswordInput
              value={password}
              onChange={(event: any) => {
                if (event.target.value !== undefined) {
                  setPassword(event.target.value);
                }
              }}
              a11yTitle="New password field"
              error={props.errorMessage}
              label="New Password"
              onFocus={() => props.onFocus?.()}
            />
            <PasswordInput
              value={repeatPassword}
              onChange={(event: any) => {
                if (event.target.value !== undefined) {
                  setRepeatPassword(event.target.value);
                }
              }}
              a11yTitle="Repeat password field"
              label="Repeat Password"
            />
            <StyledRoundedButton
              onClick={async () => {
                try {
                  setLoading(true);
                  await props.onClickConfirm?.(password);
                } finally {
                  setLoading(false);
                }
              }}
              loading={loading}
            >
              {_isNewPassword || props.password === ""
                ? "Generate Password and Publish"
                : "Modify Password and Publish"}
            </StyledRoundedButton>
            {_isNewPassword ||
              (props.password !== "" && (
                <StyledRoundedButton
                  type="tertiary"
                  variant="red"
                  icon={<Delete />}
                  onClick={async () => await props.onClickDelete?.()}
                >
                  Remove Password
                </StyledRoundedButton>
              ))}
          </Box>
        </Box>
      </ModalWrapper>
    </CustomModal>
  );
};

export default PublishSyllabusModal;
