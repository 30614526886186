import { Syllabus } from "../../../model/Syllabus";
import {
  OneWeekPlanActions,
  OneWeekPlanActionType,
} from "../types/OneWeekPlanTypes";
import { Activity } from "../../../model/Resource";
import { JSONContent } from "@tiptap/core";

export interface OneWeekPlanState {
  tempSyllabus?: Syllabus;
  objectives: JSONContent;
  activities: Activity[];
  instructor: string;
  courseTitle: string;
  school: string;
}

const initialState: OneWeekPlanState = {
  tempSyllabus: undefined,
  objectives: {},
  activities: [],
  instructor: "",
  courseTitle: "",
  school: "",
};

export const OneWeekPlanReducer = (
  state = initialState,
  action: OneWeekPlanActions
): OneWeekPlanState => {
  switch (action.type) {
    case OneWeekPlanActionType.SaveTempSyllabus:
      return {
        ...state,
        tempSyllabus: action.payload,
      };
    case OneWeekPlanActionType.DeleteTempSyllabus:
      return {
        ...state,
        tempSyllabus: undefined,
      };
    case OneWeekPlanActionType.SaveObjectives:
      return {
        ...state,
        objectives: action.payload,
      };
    case OneWeekPlanActionType.SaveActivities:
      return {
        ...state,
        activities: action.payload,
      };
    case OneWeekPlanActionType.SaveInstructor:
      return {
        ...state,
        instructor: action.payload,
      };
    case OneWeekPlanActionType.SaveCourseTitle:
      return {
        ...state,
        courseTitle: action.payload,
      };
    case OneWeekPlanActionType.SaveSchool:
      return {
        ...state,
        school: action.payload,
      };
    default:
      return state;
  }
};
