import React from "react";
import { Blank, IconProps } from "grommet-icons";
import { ChildrenIconProps } from "./FileTypeIcon";
import { IconColors } from "../../theme/GlobalTheme";

const EPS: React.FC<IconProps & ChildrenIconProps> = (props) => {
  return (
    <Blank viewBox="0 0 24 24" {...props} color="plain">
      <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
      >
        <path
          fill="white"
          d="M14.84.56H8a2.34,2.34,0,0,0-1.62.68A2.26,2.26,0,0,0,5.7,2.85V21.14a2.31,2.31,0,0,0,.68,1.61A2.26,2.26,0,0,0,8,23.42H20.57a2.29,2.29,0,0,0,2.29-2.28V8.55Z"
        />
        <path
          fill={
            props.disabled
              ? IconColors["icon-disabled"]
              : IconColors["icon-dark-blue"]
          }
          d="M14.61,1.13l7.67,7.66V21.13a1.72,1.72,0,0,1-1.72,1.71H8a1.71,1.71,0,0,1-1.71-1.71l0-18.29a1.67,1.67,0,0,1,.51-1.21A1.7,1.7,0,0,1,8,1.14h6.62m.23-.57H8a2.34,2.34,0,0,0-1.62.68A2.26,2.26,0,0,0,5.7,2.85V21.14a2.31,2.31,0,0,0,.68,1.61A2.26,2.26,0,0,0,8,23.42H20.57a2.29,2.29,0,0,0,2.29-2.28V8.55l-8-8Z"
        />
        <path
          fill={
            props.disabled
              ? IconColors["icon-disabled"]
              : IconColors["icon-light-blue"]
          }
          d="M14.84.85V6.27a2.28,2.28,0,0,0,2.29,2.29h5.42Z"
        />
        <path
          fill={
            props.disabled
              ? IconColors["icon-disabled"]
              : IconColors["icon-dark-blue"]
          }
          d="M22.56,8.84H17.14a2.57,2.57,0,0,1-2.58-2.57V.85a.3.3,0,0,1,.17-.27.31.31,0,0,1,.32.06l7.72,7.71a.29.29,0,0,1-.21.49Zm-7.43-7.3V6.27a2,2,0,0,0,2,2h4.74Z"
        />
        <rect
          fill={
            props.disabled
              ? IconColors["icon-disabled"]
              : IconColors["icon-blue"]
          }
          x="1.14"
          y="11.13"
          width="20"
          height="9.14"
          rx="0.86"
        />
        <path
          fill={
            props.disabled
              ? IconColors["icon-disabled"]
              : IconColors["icon-dark-blue"]
          }
          d="M20.28,11.7a.29.29,0,0,1,.29.29v7.42a.29.29,0,0,1-.28.29H2a.29.29,0,0,1-.29-.28V12A.29.29,0,0,1,2,11.71H20.28m0-.57H2a.85.85,0,0,0-.85.86v7.43a.86.86,0,0,0,.86.85H20.29a.85.85,0,0,0,.85-.86V12a.86.86,0,0,0-.86-.85Z"
        />
        <path
          fill="white"
          d="M6.77,14.2v1.15H8.31v.73H6.77V17.3H8.51V18H5.85v-4.6H8.51v.75Z"
        />
        <path
          fill="white"
          d="M12.51,15.56a1.19,1.19,0,0,1-.54.52,1.9,1.9,0,0,1-.94.2h-.77V18H9.34V13.46H11a2.11,2.11,0,0,1,.91.18,1.3,1.3,0,0,1,.56.5,1.5,1.5,0,0,1,.19.73A1.37,1.37,0,0,1,12.51,15.56Zm-.95-.2a.63.63,0,0,0,.18-.49c0-.44-.25-.67-.75-.66h-.73v1.33H11A.8.8,0,0,0,11.56,15.36Z"
        />
        <path
          fill="white"
          d="M14.06,17.92a1.38,1.38,0,0,1-.61-.47,1.22,1.22,0,0,1-.23-.73h1a.63.63,0,0,0,.2.45.69.69,0,0,0,.49.16.78.78,0,0,0,.51-.15.52.52,0,0,0,.18-.41.46.46,0,0,0-.12-.33,1,1,0,0,0-.31-.21,5.33,5.33,0,0,0-.52-.17,5.38,5.38,0,0,1-.73-.26,1.33,1.33,0,0,1-.48-.38,1.16,1.16,0,0,1-.2-.7,1.28,1.28,0,0,1,.2-.71,1.34,1.34,0,0,1,.58-.46,2,2,0,0,1,.84-.16,1.81,1.81,0,0,1,1.15.34,1.26,1.26,0,0,1,.49,1h-1a.57.57,0,0,0-.2-.39.78.78,0,0,0-.5-.15.65.65,0,0,0-.43.14.48.48,0,0,0-.16.4.41.41,0,0,0,.12.3.76.76,0,0,0,.31.2l.51.18a4.15,4.15,0,0,1,.72.26,1.33,1.33,0,0,1,.49.39,1.15,1.15,0,0,1,.21.69,1.4,1.4,0,0,1-.19.69,1.44,1.44,0,0,1-.56.5,2,2,0,0,1-.88.19A2.13,2.13,0,0,1,14.06,17.92Z"
        />
      </svg>
    </Blank>
  );
};
export default EPS;
