import React, { useContext } from "react";
import { Keyboard, TableCell as GrommetTableCell } from "grommet";
import styled from "styled-components";
import clsx from "clsx";
import { SyllabusThemeContext } from "../../viewer/customization/SyllabusTheme";

interface TableCellProps {
  className?: string;
  rowspan?: number;
  week?: boolean;
  index?: number;
  colspan?: number;
  onClick?: () => void;
  tabIndex?: number;
}

const GrometTableCellStyled = styled(GrommetTableCell)<{
  borderColor: string;
  hoverColor: string;
  activeColor: string;
}>`
  font-size: 16px;
  padding: 26px 20px;
  box-sizing: border-box;
  color: #030b1d;
  border: 1px solid ${(props) => props.borderColor ?? "#d0dcf1"};
  font-weight: 600;

  &:hover {
    background: ${(props) => props.hoverColor ?? "#f5f8ff"};
  }

  &:active {
    background: ${(props) => props.activeColor ?? "#e9efff"};
  }

  &.week-wrapper {
    width: 30px;
    padding: 0;
  }

  & .week {
    min-height: 100px;
    writing-mode: vertical-rl;
    text-align: center;
    color: #030b1d;
    font-weight: 600;
    font-size: 15px;
    transform: rotate(180deg);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    text-transform: uppercase;
  }
`;

const Td: React.FC<TableCellProps> = (props) => {
  const { colorScheme } = useContext(SyllabusThemeContext);

  return (
    <Keyboard onEnter={() => props.onClick?.()}>
      <GrometTableCellStyled
        plain
        rowSpan={props.rowspan}
        colSpan={props.colspan}
        className={clsx({
          [`${props.className}`]: true,
          "week-wrapper": props.week,
          odd: props.index && props.index % 2 !== 0,
          even: !props.index || props.index % 2 === 0,
        })}
        onClick={props.onClick}
        tabIndex={props.tabIndex}
        borderColor={colorScheme!.lines}
        hoverColor={colorScheme!.fieldHover}
        activeColor={colorScheme!.fieldPressed}
      >
        {props.week ? (
          <div className="week">{props.children}</div>
        ) : (
          props.children
        )}
      </GrometTableCellStyled>
    </Keyboard>
  );
};

export default Td;
