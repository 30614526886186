import React from "react";
import { Blank, IconProps } from "grommet-icons";
import { ChildrenIconProps } from "./FileTypeIcon";

const Exit: React.FC<IconProps & ChildrenIconProps> = (props) => {
  return (
    <Blank viewBox="0 0 24 24" {...props} color="plain">
      <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
      >
        <path
          fill="currentColor"
          d="M16.24,7.76a1,1,0,0,0-1.41,0L12,10.59,9.17,7.76A1,1,0,0,0,7.76,9.17L10.59,12,7.76,14.83a1,1,0,0,0,0,1.41,1,1,0,0,0,1.41,0L12,13.41l2.83,2.83a1,1,0,0,0,1.41,0,1,1,0,0,0,0-1.41L13.41,12l2.83-2.83A1,1,0,0,0,16.24,7.76Z"
        />
      </svg>
    </Blank>
  );
};
export default Exit;
