import React from "react";
import styled from "styled-components";
import Title from "../../ui-kit/OneWeekPlanTitle/Title";
import Subtitle from "../../ui-kit/OneWeekPlanSubtitle/Subtitle";
import { Image } from "grommet";
import SuccessImage from "../../../assets/images/success.png";
import RoundedButton from "../../ui-kit/RoundedButton/RoundedButton";
import SyllabusFactory from "../../../model/SyllabusFactory";
import ScheduleModuleFactory from "../../../model/ScheduleModuleFactory";
import createEmptySection from "../../editor/SectionFactory";
import { useAppDispatch, useAppSelector } from "../../../redux/configureStore";
import { Schedule, Syllabus } from "../../../model/Syllabus";
import { saveTempSyllabus } from "../../../redux/one-week-plan/actions/OneWeekPlanActions";
import { useHistory } from "react-router-dom";
import StepBar from "../StepBar/StepBar";

interface CongratulationsViewerProps {
  onClickContinue?: () => void;
}

const Content = styled.div`
  width: 100%;
  display: grid;
  justify-items: center;
`;

const StyledTitle = styled(Title)`
  padding: 110px 0 9px 0;
`;

const QuestionTitle = styled(Title)`
  padding: 25px 0 20px 0;
  font-size: 20px;
  line-height: 30px;
`;

const ContinueSubtitle = styled(Subtitle)`
  font-size: 16px;
  line-height: 22px;
  color: #030b1d;
  padding: 50px 0 20px 0;
`;

const CongratulationsViewer: React.FC<CongratulationsViewerProps> = (props) => {
  const objectives = useAppSelector((state) => state.oneWeekPlan.objectives);
  const activities = useAppSelector((state) => state.oneWeekPlan.activities);
  const courseTitle = useAppSelector((state) => state.oneWeekPlan.courseTitle);
  const instructor = useAppSelector((state) => state.oneWeekPlan.instructor);
  const school = useAppSelector((state) => state.oneWeekPlan.school);

  const dispatch = useAppDispatch();
  const history = useHistory();

  const createSyllabus = async () => {
    const syllabusFactory = new SyllabusFactory();
    const moduleFactory = new ScheduleModuleFactory();
    const syllabus = await syllabusFactory.create({
      createdBy: "one-week-user",
      title: courseTitle,
      courseNumber: "Add your course number",
      semester: "",
      school: school,
      instructor: instructor,
    });
    const schedule = createEmptySection("Schedule") as Schedule;
    schedule.modules = moduleFactory.createFromQuantity(
      new Date(),
      1,
      0,
      objectives,
      activities
    );
    syllabus.sections = [...syllabus.sections, schedule];

    return syllabus;
  };

  const saveSyllabus = async () => {
    const syllabus = (await createSyllabus()) as Syllabus;
    dispatch(saveTempSyllabus(syllabus));
  };

  return (
    <Content>
      <StepBar percentage={80} text="Step 4 out of 5" />
      <StyledTitle text="Congratulations!" />
      <Subtitle text="You just planned Week 1 of your syllabus. You can now share it with your students so they can get started." />
      <Image
        src={SuccessImage}
        width="388px"
        height="233px"
        alignSelf="center"
      />
      <QuestionTitle text="Do you want to see what your syllabus looks like?" />
      <RoundedButton
        type="primary"
        variant="blue"
        onClick={() => {
          saveSyllabus();
          history.replace("/one-week-plan/preview");
        }}
      >
        Preview Student View
      </RoundedButton>
      <ContinueSubtitle text="Or continue adding and editing the rest of your term on BlendEd!" />
      <RoundedButton
        type="secondary"
        variant="blue"
        onClick={() => {
          saveSyllabus();
          props.onClickContinue?.();
        }}
      >
        Add Week 2
      </RoundedButton>
    </Content>
  );
};

export default CongratulationsViewer;
