import React from "react";
import { Blank, IconProps } from "grommet-icons";
import { IconColors } from "../../theme/GlobalTheme";
import { ChildrenIconProps } from "./FileTypeIcon";

const TIF: React.FC<IconProps & ChildrenIconProps> = (props) => {
  return (
    <Blank viewBox="0 0 39 42" {...props} color="plain">
      <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
      >
        <path
          fill="white"
          d="M14.86.61H8A2.29,2.29,0,0,0,5.72,2.91V21.19a2.33,2.33,0,0,0,.67,1.62A2.29,2.29,0,0,0,8,23.48H20.59a2.29,2.29,0,0,0,2.29-2.29V8.61Z"
        />
        <path
          fill={
            props.disabled
              ? IconColors["icon-disabled"]
              : IconColors["icon-dark-blue"]
          }
          d="M14.63,1.19,22.3,8.85V21.18a1.74,1.74,0,0,1-1.72,1.72H8a1.71,1.71,0,0,1-1.71-1.71l0-18.29A1.71,1.71,0,0,1,8,1.19h6.62m.23-.58H8A2.29,2.29,0,0,0,5.72,2.91V21.19a2.33,2.33,0,0,0,.67,1.62A2.29,2.29,0,0,0,8,23.48H20.59a2.29,2.29,0,0,0,2.29-2.29V8.61l-8-8Z"
        />
        <path
          fill={
            props.disabled
              ? IconColors["icon-disabled"]
              : IconColors["icon-light-blue"]
          }
          d="M14.86.9V6.33a2.28,2.28,0,0,0,2.28,2.28h5.43Z"
        />
        <path
          fill={
            props.disabled
              ? IconColors["icon-disabled"]
              : IconColors["icon-dark-blue"]
          }
          d="M22.58,8.89H17.15a2.58,2.58,0,0,1-2.57-2.57V.9a.28.28,0,0,1,.17-.26.29.29,0,0,1,.32.06l7.71,7.71a.28.28,0,0,1,.07.31A.3.3,0,0,1,22.58,8.89Zm-7.43-7.3V6.33a2,2,0,0,0,2,2h4.74Z"
        />
        <rect
          fill={
            props.disabled
              ? IconColors["icon-disabled"]
              : IconColors["icon-blue"]
          }
          x="1.16"
          y="11.19"
          width="20"
          height="9.14"
          rx="0.86"
        />
        <path
          fill={
            props.disabled
              ? IconColors["icon-disabled"]
              : IconColors["icon-dark-blue"]
          }
          d="M20.3,11.75a.28.28,0,0,1,.28.29v7.43a.29.29,0,0,1-.29.28H2a.28.28,0,0,1-.29-.28V12.05A.3.3,0,0,1,2,11.76H20.3m0-.57H2a.86.86,0,0,0-.85.86v7.43a.87.87,0,0,0,.86.86l18.28,0a.85.85,0,0,0,.86-.85V12a.86.86,0,0,0-.86-.86Z"
        />
        <path fill="white" d="M10.18,13.51v.74H9V18.1H8V14.25H6.82v-.74Z" />
        <path fill="white" d="M11.77,13.51V18.1h-.92V13.51Z" />
        <path
          fill="white"
          d="M15.51,13.51v.74H13.6v1.18h1.46v.72H13.6v1.93h-.92V13.51Z"
        />
      </svg>
    </Blank>
  );
};
export default TIF;
