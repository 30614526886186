import {
  ClassTimes,
  CourseDescription,
  CourseObjectives,
  CourseTitle,
  CreditHours,
  CustomSection,
  GradingPolicy,
  Instructors,
  Location,
  Materials,
  OfficeHours,
  Prerequisites,
  Schedule,
  Section,
  SectionType,
  Skills,
} from "../../model/Syllabus";

// Creates a unique id which can be assigned to sections
// for react rendering
export const getUniqueId = () => {
  return "_" + Math.random().toString(36);
};

const createEmptySection = (sectionType: SectionType): Section => {
  switch (sectionType) {
    /*case "Policies":
      return {
        id: getUniqueId(),
        type: "Policies",
        policies: [],
      } as Policies;*/
    case "Skills":
      return {
        id: getUniqueId(),
        type: "Skills",
        skills: [],
      } as Skills;
    case "Prerequisites":
      return {
        id: getUniqueId(),
        type: "Prerequisites",
        prerequisites: [],
      } as Prerequisites;
    case "Location":
      return {
        id: getUniqueId(),
        type: "Location",
        kind: "",
        description: {
          type: "doc",
          content: [
            {
              type: "paragraph",
            },
          ],
        },
      } as Location;
    case "Materials":
      return {
        id: getUniqueId(),
        type: "Materials",
        materials: [],
      } as Materials;
    case "Schedule": {
      return {
        id: getUniqueId(),
        type: "Schedule",
        modules: [],
        start_at: new Date(),
        end_at: new Date(),
      } as Schedule;
    }
    case "GradingPolicy":
      return {
        id: getUniqueId(),
        type: "GradingPolicy",
        title: "",
        description: "",
        items: [],
      } as GradingPolicy;
    /*case "RequiredTexts":
      return {
        id: getUniqueId(),
        type: "RequiredTexts",
        readings: [],
      } as RequiredTexts;*/
    case "Instructors":
      return {
        id: getUniqueId(),
        type: "Instructors",
        instructors: [],
      } as Instructors;
    case "ClassTimes":
      return {
        id: getUniqueId(),
        type: "ClassTimes",
        classTimes: "",
      } as ClassTimes;
    case "CourseDescription":
      return {
        id: getUniqueId(),
        type: "CourseDescription",
        description: {
          type: "doc",
          content: [
            {
              type: "paragraph",
            },
          ],
        },
        attachments: [],
      } as CourseDescription;
    case "CourseObjectives":
      return {
        id: getUniqueId(),
        type: "CourseObjectives",
        objectives: {},
      } as CourseObjectives;
    case "CourseTitle":
      return {
        id: getUniqueId(),
        type: "CourseTitle",
        title: "",
        courseNumber: "",
        semester: "",
        school: "",
      } as CourseTitle;
    case "CreditHours":
      return {
        id: getUniqueId(),
        type: "CreditHours",
        creditHours: "",
      } as CreditHours;
    case "CustomSection":
      return {
        id: getUniqueId(),
        type: "CustomSection",
        title: "",
        body: {
          type: "doc",
          content: [
            {
              type: "paragraph",
            },
          ],
        },
      } as CustomSection;
    case "OfficeHours":
      return {
        id: getUniqueId(),
        type: "OfficeHours",
        officeHours: "",
      } as OfficeHours;
    default:
      throw Error;
  }
};

export default createEmptySection;
