import React, { useEffect } from "react";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";
import SyllabusIdViewer from "./viewer/SyllabusIdViewer";
import SyllabusList from "./SyllabusList";
import SyllabusIdEditor from "./editor/SyllabusIdEditor";
import { useDispatch, useSelector } from "react-redux";
import { addVisit } from "../redux/statistics/actions/StatisticsActions";
import Registration from "./Registration";
import Login from "./Login";
import Account from "./account/Account";
import ProfileComplete from "./ProfileComplete";
import Logout from "./Logout";
import { PublicRoute } from "../layouts/PublicRoute";
import { PrivateRoute } from "layouts/PrivateRoute";
import { LayoutTopbar } from "layouts/LayoutTopbar";
import { LayoutNoTopbar } from "layouts/LayoutNoTopbar";
import { AppState } from "../redux/configureStore";
import { useFirestoreConnect } from "react-redux-firebase";
import LoaderRedirect from "./account/components/Integrations/loaderRedirect";
import FloatingHelpButton from "./floating-help-button/FloatingHelpButton";
import Loading from "./Loading";
import MyNewSyllabus from "./editor/MyNewSyllabus";
import moment from "moment";
import { deleteRedirect } from "../redux/common/actions/CommonActions";
import ForgotPasswordPage from "./ForgotPasswordPage";
import { GrommetTheme } from "../GrommetTheme";
import { Grommet } from "grommet";
import PublicSyllabusIdViewer from "./viewer/PublicSyllabusIdViewer";
import ModulePageViewer from "./viewer/Module/ModulePageViewer";
import PublicModulePageViewer from "./viewer/Module/PublicModulePageViewer";
import OneWeekPlan from "./one-week-plan/OneWeekPlan";
import OneWeekPlanPreviewPage from "./one-week-plan/OneWeekPlanPreview/OneWeekPlanPreviewPage";
import OneWeekPlanModulePage from "./one-week-plan/OneWeekPlanPreview/OneWeekPlanModulePage";
import TemplatesListPage from "./templates/TemplatesListPage";
import SyllabusTemplateEditor from "./editor/SyllabusTemplateEditor";
import SyllabusEditorTeacherPage from "components/syllabus-editor-teacher-page/SyllabusEditorTeacherPage";
import SyllabiListPage from "./student-view/SyllabiListPage";
import BubbleAuth from "./BubbleAuth";

const publicRoutes = [
  {
    key: "login",
    path: "/login",
    component: Login,
    exact: true,
  },
  {
    key: "forgot-password",
    path: "/forgot-password",
    component: ForgotPasswordPage,
    exact: true,
  },
  {
    key: "signup",
    path: "/signup",
    component: Registration,
    exact: true,
  },
  {
    key: "public-module-page",
    path: "/s/:id/module/:index",
    component: PublicModulePageViewer,
    exact: false,
  },
  {
    key: "public-syllabus-id",
    path: "/s/:id",
    component: PublicSyllabusIdViewer,
    exact: false,
  },
  {
    key: "loaderRedirect",
    path: "/loaderRedirect",
    component: LoaderRedirect,
    exact: true,
  },
  {
    key: "my-new-syllabus",
    path: "/my-new-syllabus",
    component: MyNewSyllabus,
    exact: false,
  },
  {
    key: "one-week-plan",
    path: "/one-week-plan",
    component: OneWeekPlan,
    exact: true,
  },
  {
    key: "one-week-plan-preview",
    path: "/one-week-plan/preview",
    component: OneWeekPlanPreviewPage,
    exact: true,
  },
  {
    key: "module-page",
    path: "/one-week-plan/preview/module/:index",
    component: OneWeekPlanModulePage,
    exact: false,
  },
  {
    key: "edit-template-id",
    path: "/edit-template/:id",
    component: SyllabusTemplateEditor,
    exact: false,
  },
  {
    key: "templates-list-page",
    path: "/templates/:source",
    component: TemplatesListPage,
    exact: false,
  },
  {
    key: "syllabus-editor-teacher-page",
    path: "/t/:id",
    component: SyllabusEditorTeacherPage,
    exact: false,
  },
  {
    key: "students-page",
    path: "/students/",
    component: SyllabiListPage,
    exact: false,
  },
  {
    key: "bubble-authenticate",
    path: "/bubble/authorize",
    component: BubbleAuth,
    exact: false,
  },
];

const privateRoutes = [
  {
    key: "logout",
    path: "/logout",
    component: Logout,
    exact: true,
  },
  {
    key: "complete",
    path: "/complete",
    component: ProfileComplete,
    exact: true,
  },
  {
    key: "dashboard",
    path: "/dashboard",
    component: SyllabusList,
    exact: true,
  },
  {
    key: "account",
    path: "/account/:pageId?",
    component: Account, // sub routing is handled in that component
    exact: false,
  },
  {
    key: "edit-id",
    path: "/edit/:id",
    component: SyllabusIdEditor,
    exact: false,
  },
  {
    key: "module-page",
    path: "/syllabus/:id/module/:index",
    component: ModulePageViewer,
    exact: false,
  },
  {
    key: "syllabus-id",
    path: "/syllabus/:id",
    component: SyllabusIdViewer,
    exact: false,
  },
];

const Main: React.FC = () => {
  const dispatch = useDispatch();
  const profile = useSelector((state: AppState) => state.firebase.profile);
  const redirect = useSelector((state: AppState) => state.common.redirect);
  const history = useHistory();
  const location = useLocation();
  const privatePathsWithTopBar = [
    "/logout",
    "/dashboard",
    "/account",
    "/account/*",
    "/importJson",
    "/youtube",
    "/complete",
  ];
  const publicPaths = [
    "/forgot-password",
    "/s/:id/module/:index",
    "/s/:id",
    "/oauth-complete",
    "/loaderRedirect",
    "/my-new-syllabus",
    "/one-week-plan",
    "/one-week-plan/preview",
    "/edit-template/:id",
    "/t/:id",
    "/students",
  ];
  const publicPathsWithTopbar = ["/templates/:source"];

  useEffect(() => {
    window.analytics.page(location.pathname);
  }, [location]);

  useEffect(() => {
    if (history.location.pathname === "/dashboard") {
      if (redirect) {
        const expirationUrl = moment(redirect.expiration);
        if (expirationUrl.isAfter(moment())) {
          dispatch(deleteRedirect());
          history.push("/my-new-syllabus");
          return;
        }
      }

      if (
        profile.firstName &&
        profile.lastName &&
        profile.school &&
        profile.role
      ) {
        history.push("/dashboard");
      } else {
        history.push("/complete");
      }
    }
    dispatch(addVisit());
  }, [dispatch, profile, history, redirect]);

  useFirestoreConnect({
    collection: "schools",
    storeAs: "school",
  });
  useFirestoreConnect({
    collection: "policies",
  });

  const auth = useSelector((state: AppState) => state.firebase.auth);
  const isAuthenticated = !!auth.uid;
  localStorage.setItem("isAuthenticated", `${isAuthenticated}`);

  if (!isAuthenticated) {
    publicPaths.push("/login");
    publicPaths.push("/signup");
    publicPaths.push("/bubble/authorize");
  }

  console.log({ isAuthenticated: isAuthenticated });

  if (!auth.isLoaded || !profile.isLoaded) {
    return <Loading />;
  }
  return (
    <>
      <Grommet theme={GrommetTheme} full>
        <Switch>
          <Route path={privatePathsWithTopBar}>
            <LayoutTopbar>
              <FloatingHelpButton />
              <Switch>
                {privateRoutes.map((privateRouteProps) => (
                  <PrivateRoute {...privateRouteProps} />
                ))}
              </Switch>
            </LayoutTopbar>
          </Route>

          <Route
            path={[
              "/edit/:id",
              "/json/:id",
              "/syllabus/:id/module/:index",
              "/syllabus/:id",
            ]}
          >
            <LayoutNoTopbar>
              <FloatingHelpButton />
              <Switch>
                {privateRoutes.map((privateRouteProps) => (
                  <PrivateRoute {...privateRouteProps} />
                ))}
              </Switch>
            </LayoutNoTopbar>
          </Route>

          <Route path={publicPaths}>
            <LayoutNoTopbar>
              <Switch>
                {publicRoutes.map((publicRouteProps) => (
                  <PublicRoute {...publicRouteProps} />
                ))}
              </Switch>
            </LayoutNoTopbar>
          </Route>

          <Route path={publicPathsWithTopbar}>
            <LayoutTopbar>
              <Switch>
                {publicRoutes.map((publicRouteProps) => (
                  <PublicRoute {...publicRouteProps} />
                ))}
              </Switch>
            </LayoutTopbar>
          </Route>

          <Route path="*">
            <LayoutTopbar>
              <Switch>
                <Route component={Login} />
              </Switch>
            </LayoutTopbar>
          </Route>
        </Switch>
      </Grommet>
    </>
  );
};

export default Main;
