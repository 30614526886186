import React, { useContext, useRef, useState } from "react";
import { FileResource } from "../../model/Resource";
import useExitPrompt from "../../hooks/useExitPrompt";
import { AppState, useAppDispatch } from "../../redux/configureStore";
import { addSnackbarMessage } from "../../redux/common/actions/CommonActions";
import { v4 as uuidv4 } from "uuid";
import { SyllabusEditorContext } from "../editor/SyllabusEditorContextProvider";
import SquaredButton from "components/ui-kit/SquaredButton/SquaredButton";
import SignUpModal from "../one-week-plan/SignUpModal/SignUpModal";
import { Syllabus } from "../../model/Syllabus";
import SyllabusRepository from "../../repositories/SyllabusRepository";
import { deleteTempSyllabus } from "../../redux/syllabus_templates/actions/SyllabusTemplateActions";
import { useHistory } from "react-router-dom";
import useTemporalTemplate from "../../hooks/useTemporalTemplate";
import uploadFile from "../../helpers/uploadFile";
import { useSelector } from "react-redux";

interface FileUploadProps {
  onUploadFile?: (attachment: FileResource) => void;
  buttonText?: string;
  button?: JSX.Element;
  fileTypes?: string[];
  disabled?: boolean;
}

const FileUpload: React.FC<FileUploadProps> = (props) => {
  const [loading, setLoading] = useState(false);
  useExitPrompt(loading);
  const dispatch = useAppDispatch();
  const fileInputRef = useRef<HTMLInputElement>();
  const { overridePrivateActions } = useContext(SyllabusEditorContext);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const reduxSyllabi: Syllabus[] = useTemporalTemplate();
  const auth = useSelector((state: AppState) => state.firebase.auth);

  const history = useHistory();

  const renderSignUpModal = () => (
    <SignUpModal
      opened={openModal}
      closeButton={true}
      handleClose={() => setOpenModal(false)}
      onNewLogin={(userId) => {
        reduxSyllabi.map(async (syllabus) => {
          const newSyllabus = {
            ...syllabus,
            createdBy: userId,
          } as Syllabus;
          window.analytics.track(`Successful signup`, { id: userId });
          window.analytics.identify(auth.uid, {
            email: auth.email,
          });
          window.analytics.track("Saved User Email");
          const syllabusRepository = new SyllabusRepository();
          await syllabusRepository.create(newSyllabus!);
          dispatch(deleteTempSyllabus(syllabus.parentId!));
        });
        history.replace("/dashboard");
      }}
    />
  );

  return (
    <>
      <div>
        <div
          onClick={() => {
            if (props.disabled) {
              setOpenModal(true);
            } else {
              if (overridePrivateActions) {
                overridePrivateActions();
              } else {
                fileInputRef.current?.click();
              }
            }
          }}
        >
          <SquaredButton
            type="secondary"
            variant="dark-blue"
            size="small"
            loading={loading}
          >
            {props.buttonText}
          </SquaredButton>
        </div>
        <input
          onChange={async (event) => {
            const fileList = event.target.files;
            if (!fileList) return;
            setLoading(true);
            const fileAttachments = await uploadFile(fileList);
            setLoading(false);

            const id = uuidv4();
            dispatch(
              addSnackbarMessage({
                id: id,
                message: "Your file was uploaded successfully.",
                type: "normal",
              })
            );

            for (const file of fileAttachments) {
              props.onUploadFile?.(file);
            }

            if (fileInputRef.current) {
              fileInputRef.current.value = "";
            }
          }}
          ref={fileInputRef}
          type="file"
          hidden
        />
      </div>
      {renderSignUpModal()}
    </>
  );
};

export default FileUpload;
