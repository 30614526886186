import React, { useState } from "react";
import styled from "styled-components";

import ArrowNext from "../../../assets/icons/ArrowNext";
import ArrowPrevious from "../../../assets/icons/ArrowPrevious";

import { format, nextMonday, previousMonday } from "date-fns";
import { CourseTitle, Module, Schedule, Syllabus } from "model/Syllabus";
import RoundedButton from "components/ui-kit/RoundedButton/RoundedButton";
import { Box } from "grommet";
import { HidableComponent } from "components/hoc/Hidable";
import Select from "components/ui-kit/Select/Select";
import ScheduleCalendarTable from "./ScheduleCalendarTable";

interface ScheduleCalendarProps {
  syllabi: Syllabus[];
}

const StyledWrapper = styled.div`
  & .header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  & .title {
    font-family: "Poppins", serif;
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 33px;
    color: #030b1d;
    margin-bottom: 22.75px;
  }

  & .month {
    font-family: "Open Sans", serif;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    color: #193da9;
  }

  & .setWeekWrapper {
    margin-bottom: 35px;
    display: flex;
    align-items: center;
  }

  & .arrow-button {
    box-sizing: border-box;
    width: 32px;
    height: 32px;
    background: #ffffff;
    border: 1px solid #d0dcf1;
    box-shadow: 0 1px 2px rgba(7, 26, 67, 0.1);
    border-radius: 4px;

    &:nth-child(2) {
      margin-left: 30px;
    }

    &:nth-child(3) {
      margin-left: 5px;
    }

    & div {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &:hover {
      background: #eff3ff;
      border: 1px solid #bfd1f0;
      cursor: pointer;
    }

    &:active {
      background: #dde6ff;
      border: 1px solid #bfd1f0;
      box-shadow: inset 0 1px 2px rgba(7, 26, 67, 0.2);
    }

    &.is-active {
      background: #dde6ff;
      border: 1px solid #bfd1f0;
      box-shadow: inset 0 1px 2px rgba(7, 26, 67, 0.2);
    }
  }
`;

const ScheduleCalendar: React.FC<ScheduleCalendarProps> = (props) => {
  const [initialDate, setInitialDate] = useState(previousMonday(new Date()));
  const [selectedSyllabi, setSelectedSyllabi] = useState<string>("all");

  const activities = props.syllabi
    .filter(
      (s) => selectedSyllabi === "all" || s.firebaseId === selectedSyllabi
    )
    .map((syllabus: Syllabus) => {
      const title = syllabus.sections.find(
        (s) => s.type === "CourseTitle"
      ) as CourseTitle;
      const schedule = syllabus.sections.find(
        (s) => s.type === "Schedule"
      ) as Schedule;
      return (
        schedule?.modules?.map((module: Module) =>
          module.activities.map((activity) => ({
            ...activity,
            subject: title.title,
          }))
        ) ?? []
      );
    })
    .flat(2);

  return (
    <StyledWrapper>
      <div className="header">
        <Box direction="row" justify="between" width="100%">
          <h2 className="title">Calendar View</h2>
          <Select
            options={[
              { label: "All Syllabi", key: "all" },
              ...props.syllabi.map((s) => {
                const title = s.sections.find(
                  (section) => section.type === "CourseTitle"
                ) as CourseTitle;

                return {
                  key: s.firebaseId!,
                  label: title.title,
                };
              }),
            ]}
            value={{ key: selectedSyllabi }}
            onChange={(option) => {
              setSelectedSyllabi(option.key);
            }}
            a11yTitle="Select Syllabus to Filter"
          />
        </Box>
        <Box width="100%">
          <div className="setWeekWrapper">
            <span className="month">{format(initialDate, "MMMM")} 2022</span>
            <button
              className="arrow-button"
              onClick={() => setInitialDate(previousMonday(initialDate))}
            >
              <div>
                <ArrowPrevious />
              </div>
            </button>
            <button
              className="arrow-button"
              onClick={() => setInitialDate(nextMonday(initialDate))}
            >
              <div>
                <ArrowNext />
              </div>
            </button>
            <HidableComponent
              isVisible={
                format(initialDate, "d/M/Y") !==
                format(previousMonday(new Date()), "d/M/Y")
              }
            >
              <Box margin={{ left: "24px" }}>
                <RoundedButton
                  size="small"
                  onClick={() => setInitialDate(previousMonday(new Date()))}
                >
                  Today
                </RoundedButton>
              </Box>
            </HidableComponent>
          </div>
          <Box justify="center">
            <ScheduleCalendarTable
              initialDate={initialDate}
              data={
                activities?.map((activity) => ({
                  date: activity?.dueDate,
                  label: activity?.title,
                  subject: activity?.subject,
                })) ?? []
              }
            />
          </Box>
        </Box>
      </div>
    </StyledWrapper>
  );
};

export default ScheduleCalendar;
