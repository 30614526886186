// canvas
// stripe
// rff
// youtube
// google books
// google custom search
// firebase
//app
// reflect
import {
  appConfig as appCommon,
  canvasConfig as canvasCommon,
  courseMapConfig,
  customSearchConfig as customSearchCommon,
  firebaseConfig as firebaseCommon,
  googleBooksConfig as googleBooksCommon,
  reflectConfig as reflectCommon,
  rrfConfig as rffCommon,
  stripeConfig as stripeCommon,
  youtubeConfig as youtubeCommon,
} from "./configCommon";

// re-export
export const canvasConfig = { ...canvasCommon };
export const stripeConfig = { ...stripeCommon };
export const rrfConfig = { ...rffCommon };
export const firebaseConfig = { ...firebaseCommon };
export const youtubeConfig = { ...youtubeCommon };
export const googleBooksConfig = { ...googleBooksCommon };
export const customSearchConfig = {
  ...customSearchCommon,
};
export const appConfig = { ...appCommon };
export const reflectConfig = reflectCommon;
export { courseMapConfig };
