import React from "react";
import { Blank, IconProps } from "grommet-icons";
import { IconColors } from "../../theme/GlobalTheme";
import { ChildrenIconProps } from "./FileTypeIcon";

const BooksFileButton: React.FC<IconProps & ChildrenIconProps> = (props) => {
  return (
    <Blank
      viewBox="0 0 128 128"
      {...props}
      color="plBooksFileButton
  n"
    >
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 42 42"
        xmlSpace="preserve"
      >
        <title>books-filebtn</title>
        <path
          fill="white"
          d="M5,1h32c2.2,0,4,1.8,4,4v32c0,2.2-1.8,4-4,4H5c-2.2,0-4-1.8-4-4V5C1,2.8,2.8,1,5,1z"
        />
        <g>
          <path
            fill={
              props.disabled
                ? IconColors["icon-disabled"]
                : IconColors["icon-blue"]
            }
            d="M16.1,17h9.7c0.6,0,1-0.4,1-1s-0.4-1-1-1h-9.7c-0.6,0-1,0.4-1,1S15.6,17,16.1,17z"
          />
          <path
            fill={
              props.disabled
                ? IconColors["icon-disabled"]
                : IconColors["icon-blue"]
            }
            d="M32,10H12.5C11.7,10,11,9.3,11,8.5S11.7,7,12.5,7H32c0.6,0,1-0.4,1-1s-0.4-1-1-1H12.5C10.6,5,9.1,6.5,9,8.3
		c0,0,0,0.1,0,0.1V33c0,2.2,1.8,4,4,4h16c2.2,0,4-1.8,4-4V11C33,10.4,32.6,10,32,10z M29,35H13c-1.1,0-2-0.9-2-2V11.6
		c0.5,0.2,1,0.4,1.5,0.4H31v21C31,34.1,30.1,35,29,35z"
          />
          <path
            fill={
              props.disabled
                ? IconColors["icon-disabled"]
                : IconColors["icon-blue"]
            }
            d="M26,19H16c-0.6,0-1,0.4-1,1v11c0,0.6,0.4,1,1,1h10c0.6,0,1-0.4,1-1V20C27,19.4,26.6,19,26,19z M25,30h-8v-9h8
		V30z"
          />
        </g>
      </svg>
    </Blank>
  );
};

export default BooksFileButton;
