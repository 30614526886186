import { deepMerge } from "grommet/utils";
import {
  StatusCritical,
  StatusGood,
  StatusInfo,
  StatusWarning,
} from "grommet-icons";

export const GrommetTheme = deepMerge({
  global: {
    breakpoints: {
      small: {
        value: 368,
        size: {
          xxsmall: "24px",
          xsmall: "48px",
          small: "96px",
          medium: "192px",
          large: "384px",
          xlarge: "768px",
          full: "100%",
        },
      },
      medium: {
        value: 768,
        size: {
          xxsmall: "48px",
          xsmall: "96px",
          small: "192px",
          medium: "384px",
          large: "768px",
          xlarge: "1200px",
          full: "100%",
        },
      },
      large: {},
    },
    colors: {
      // Overriding existing grommet colors
      brand: "#0D3AF8",
      "accent-1": "#0D3AF8",
    },
    focus: {
      border: {
        color: "#0D3AF8",
      },
      outline: {
        color: "#0D3AF8",
        size: "1px",
      },
      shadow: {
        color: "#0D225A",
        size: "1px",
      },
    },
    drop: {
      zIndex: 1400,
    },
    input: {
      font: {
        weight: 600,
        family: "Lato",
        size: "16px",
      },
    },
    control: {
      disabled: {
        opacity: 1,
      },
    },
  },
  font: {
    family: "Open Sans",
  },
  formField: {
    error: {
      size: "12px",
    },
    border: {
      color: "transparent",
    },
    focus: {
      border: {
        color: "transparent !important",
      },
    },
    disabled: {
      background: {
        color: "transparent",
      },
      label: {
        color: "#9DA2AC",
      },
    },
  },
  layer: {
    border: {
      radius: "10px",
      intelligentRounding: true,
    },
    overlay: {
      background: "rgba(0, 0, 0, 0.5)",
    },
  },
  tabs: {
    header: {
      extend: `
        justify-content: space-between;
      `,
    },
  },
  tab: {
    border: {
      side: "bottom",
      color: "#DDE6FF",
      active: {
        color: "#0d3af8",
      },
      hover: {
        color: "black",
      },
    },
    active: {
      color: "#0d3af8",
    },
    margin: "0 1px",
  },
  card: {
    container: {
      round: "xsmall",
      elevation: "medium",
    },
  },
  notification: {
    critical: {
      icon: StatusCritical,
    },
    unknown: {
      icon: StatusInfo,
    },
    normal: {
      icon: StatusGood,
    },
    warning: {
      icon: StatusWarning,
    },
    container: {
      alignContent: "center",
      width: "large",
      margin: {
        vertical: "-20px",
        horizontal: "500px",
      },
    },
  },
  button: {
    disabled: {
      opacity: 1,
    },
  },
});
