import React, { useEffect, useState } from "react";
import ModuleViewer from "./ModuleViewer";
import {
  CourseTitle,
  Module,
  Schedule,
  Syllabus,
} from "../../../model/Syllabus";
import styled from "styled-components";
import ModuleSidebar from "./ModuleSidebar";
import ModuleHeader from "./ModuleHeader";
import { useLocation } from "react-router-dom";
import SyllabusThemeContextProvider from "../customization/SyllabusTheme";
import RichTextEditorContextProvider from "../../ui-kit/RichTextEditor/RichTextEditorContext";

const Root = styled.div`
  font-family: Open Sans;
  height: 100%;
  min-height: 100vh;
  background: #fbfdff;
`;

interface ModulePageProps {
  syllabus: Syllabus;
  moduleIndex: number;
  unlocked?: boolean;
  clickOnPrivateContent?: () => void;
}

const ModulePage: React.FC<ModulePageProps> = ({
  syllabus,
  moduleIndex,
  unlocked,
  clickOnPrivateContent,
}) => {
  const location = useLocation().pathname;
  const schedule = syllabus.sections.find(
    (section) => section.type === "Schedule"
  ) as Schedule;
  const [module, setModule] = useState<Module>(schedule.modules[moduleIndex]);
  const [index, setIndex] = useState<number>(moduleIndex);
  const courseTitle = syllabus.sections.find(
    (section) => section.type === "CourseTitle"
  ) as CourseTitle;

  useEffect(() => {
    window.history.pushState(
      {},
      "",
      location.substring(0, location.lastIndexOf("e/") + 2) + index
    );
  }, [index, location]);

  useEffect(() => {
    setModule(schedule.modules[index]);
  }, [index, schedule.modules]);

  if (module && courseTitle) {
    return (
      <Root role="main">
        <SyllabusThemeContextProvider
          primaryColor={syllabus.customization.primaryColor}
          fontFamily={syllabus.customization.fontFamily}
        >
          <ModuleHeader
            module={module}
            prev={!(index - 1 < 0)}
            next={!(index + 1 >= schedule.modules.length)}
            onClickNext={() => {
              setIndex(Number(index) + 1);
            }}
            onClickPrev={() => {
              setIndex(Number(index) - 1);
            }}
          />
          <ModuleSidebar module={module} courseTitle={courseTitle} />
          <RichTextEditorContextProvider
            mode="student-view"
            unlocked={unlocked || syllabus.password === ""}
            clickOnPrivateContent={() => clickOnPrivateContent?.()}
          >
            <ModuleViewer
              module={module}
              locked={!unlocked || syllabus.password === ""}
              syllabus={syllabus}
            />
          </RichTextEditorContextProvider>
        </SyllabusThemeContextProvider>
      </Root>
    );
  } else {
    return <h1>No module found</h1>;
  }
};

export default ModulePage;
