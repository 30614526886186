import React from "react";
import styled from "styled-components";
import TextInput from "../TextInput/TextInput";
import Minus from "../../../assets/icons/Minus";
import Create from "../../../assets/icons/Create";
import { Button } from "grommet";
import clsx from "clsx";

interface WeekSelectorProps {
  value: number;
  onChange: (value: number) => void;
  disableMinusButton?: boolean;
  disablePlusButton?: boolean;
  label?: string;
}

const WeekSelectorWrapper = styled.div`
  width: 100%;
  position: relative;

  .buttons {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    z-index: 1000;
  }
`;

const StyledButton = styled(Button)`
  color: #0d3af8;
  background: none;
  border: none;
  padding: 0 10px;

  &:hover {
    cursor: pointer;
  }

  &.disabled {
    color: #9da2ac;

    &:hover {
      cursor: not-allowed;
    }
  }
`;

const StyledTextInput = styled(TextInput)`
  & input {
    text-align: center !important;
    font-family: "Open Sans" !important;
    font-size: 16px !important;

    color: #030b1d !important;
  }
`;

const WeekSelector: React.FC<WeekSelectorProps> = (props) => {
  return (
    <WeekSelectorWrapper>
      <StyledTextInput
        label={props.label}
        a11yTitle="Select Week"
        onChange={() => {}}
        value={`${props.value} weeks`}
        extra={
          <div className="buttons">
            <StyledButton
              className={clsx({ disabled: props.disableMinusButton })}
              disabled={props.disableMinusButton}
              onClick={() => props.onChange(props.value - 1)}
            >
              <Minus />
            </StyledButton>
            <StyledButton
              className="icon-button"
              disabled={props.disablePlusButton}
              onClick={() => props.onChange(props.value + 1)}
            >
              <Create />
            </StyledButton>
          </div>
        }
      />
    </WeekSelectorWrapper>
  );
};

export default WeekSelector;
