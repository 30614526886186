import React, { useEffect, useState } from "react";
import moment from "moment";
import {
  addDays,
  addWeeks,
  differenceInWeeks,
  endOfWeek,
  getDay,
  isAfter,
  isBefore,
  startOfWeek,
} from "date-fns";
import { HidableComponent } from "../../../hoc/Hidable";
import SquaredButton from "../../../ui-kit/SquaredButton/SquaredButton";
import { Box, Grid, Text } from "grommet";
import styled from "styled-components";
import { Alert } from "grommet-icons";
import Calendar from "../../../ui-kit/Calendar/Calendar";
import WeekSelector from "../../../ui-kit/WeekSelector/WeekSelector";
import format from "date-fns/format";

interface ScheduleBuilderProps {
  startAt: Date;
  endAt: Date;
  currentModules: number;
  onGenerate: (start: Date, end: Date) => void;
  onEditDates: (start: Date, end: Date, courseLength: number) => void;
  mode: "creator" | "editor";
}

const StyledCourseInfo = styled.p`
  line-height: 19px;
`;

export const validateGeneratorParams = (
  startDate: Date | null,
  endDate: Date | null
): { isValid: boolean; error?: string } => {
  let isValid = true;
  let errorMessage: string | undefined = undefined;
  const endDateMoment = moment(endDate);
  const startDateMoment = moment(startDate);
  const minDate = `01/01/${new Date().getFullYear() - 1}`;
  const maxDate = `12/31/${new Date().getFullYear() + 1}`;

  if (!startDate) {
    isValid = false;
    errorMessage = "Missing start date";
  } else if (!endDate) {
    isValid = false;
    errorMessage = "Missing end date";
  } else if (startDateMoment.isAfter(endDateMoment)) {
    isValid = false;
    errorMessage = "Start can't be after end";
  } else if (
    isBefore(startDate, new Date(minDate)) ||
    isAfter(endDate, new Date(maxDate))
  ) {
    isValid = false;
    errorMessage = `Starting course day falls outside date range. Mininum date: ${minDate}`;
  }

  return {
    isValid: isValid,
    error: errorMessage,
  };
};

const StyledScheduleBuilder = styled.div`
  .schedule-information {
    font-family: "Open Sans";
    font-weight: 400;
    font-size: 14px;
    padding: 14px 0;
  }
`;

// Component for providing information (start date, end date, #modules, ...)
// to generate an empty schedule
export const ScheduleBuilder: React.FC<ScheduleBuilderProps> = (props) => {
  const [startDate, setStartDate] = useState<Date>(props.startAt);
  const [endDate, setEndDate] = useState<Date>(props.endAt);
  const [openStartDate, setOpenStartDate] = useState<boolean>(false);
  const [openEndDate, setOpenEndDate] = useState<boolean>(false);
  const [courseLength, setCourseLength] = useState<number>(
    props.currentModules
  );
  const distanceInWeeks =
    differenceInWeeks(endOfWeek(endDate), startOfWeek(startDate)) + 1;
  const weeksToBeCreated = courseLength - props.currentModules;

  useEffect(() => {
    setStartDate(props.startAt);
  }, [props.startAt]);
  useEffect(() => {
    setEndDate(props.endAt);
  }, [props.endAt]);
  useEffect(() => {
    setCourseLength(props.currentModules);
  }, [props.currentModules]);

  const pluralize = (word: string, plural: boolean) => {
    return plural ? `${word}s` : word;
  };

  const onClickGenerate = () => {
    if (startDate === null || endDate === null) {
      return;
    }
    const startDateStartOfWeek =
      getDay(startDate) === 6 // If the day is sunday I don't want to move to the start of week because I'm already there. If we remove this conditional the system moves to the previous week.
        ? startDate
        : startOfWeek(startDate, { weekStartsOn: 0 });
    const endDateEndOfWeek = endOfWeek(endDate);
    props.onGenerate(startDateStartOfWeek, endDateEndOfWeek);
  };

  const validation = validateGeneratorParams(startDate, endDate);

  const updateCourseLength = (newCourseLength: number) => {
    if (newCourseLength < props.currentModules) return;
    setCourseLength(newCourseLength);

    const startWeek = startOfWeek(startDate);
    const endDate = endOfWeek(addWeeks(startWeek, newCourseLength - 1));

    setEndDate(endDate);
  };

  const onUpdateSchedule = () => {
    props.onEditDates(startDate, endDate, courseLength);
  };

  return (
    <StyledScheduleBuilder>
      <Grid
        rows={["50px"]}
        columns={["1/3", "1/3"]}
        gap="small"
        areas={[
          { name: "startDate", start: [0, 0], end: [0, 0] },
          { name: "endDate", start: [1, 0], end: [1, 0] },
        ]}
      >
        <Box align="left" gridArea="startDate" margin={{ top: "-20px" }}>
          <Calendar
            open={openStartDate}
            onClose={() => setOpenStartDate(false)}
            onOpen={() => setOpenStartDate(true)}
            date={startDate}
            onSelect={(stringDate: string) => {
              const date = new Date(stringDate);
              setStartDate(date);
              let newEndDate: Date;
              if (
                endDate?.getTime() === endDate.getTime() &&
                courseLength === 0
              ) {
                newEndDate = addDays(date!, 84);
              } else {
                newEndDate = addDays(date!, courseLength * 7);
              }
              setEndDate(newEndDate);
              setOpenStartDate(false);
            }}
            a11yTitle={"Start Date Calendar"}
            label={"Start Date"}
          />
        </Box>
        <Box align="left" gridArea="endDate" margin={{ top: "-20px" }}>
          <Calendar
            open={openEndDate}
            onClose={() => setOpenEndDate(false)}
            onOpen={() => setOpenEndDate(true)}
            date={endDate}
            onSelect={(stringDate: string) => {
              const date = new Date(stringDate);
              setEndDate(date);
              setOpenEndDate(false);
            }}
            a11yTitle={"End Date Calendar"}
            label={"End Date"}
            disabled={props.mode === "editor"}
          />
        </Box>
      </Grid>
      <StyledCourseInfo>
        {props.mode === "creator" && (
          <div>
            <p className="schedule-information">
              Your course has a length of {distanceInWeeks} weeks. <br />
              <b>{distanceInWeeks} weeks of modules will be created.</b>
            </p>
            <SquaredButton
              type="primary"
              variant="blue"
              onClick={onClickGenerate}
              disabled={!validation.isValid}
            >
              Create Modules
            </SquaredButton>
          </div>
        )}
        {props.mode === "editor" && (
          <div>
            <Grid
              rows={["50px"]}
              columns={["1/3", "1/3"]}
              gap="small"
              areas={[
                { name: "courseLength", start: [0, 0], end: [0, 0] },
                { name: "", start: [1, 0], end: [1, 0] },
              ]}
            >
              <WeekSelector
                label="Course Length"
                value={courseLength}
                onChange={(newNumber) => updateCourseLength(newNumber)}
                disableMinusButton={props.currentModules === courseLength}
              />
            </Grid>
            <Box gap="small" margin={{ top: "20px" }}>
              {props.currentModules === courseLength ? (
                <p className="schedule-information">
                  You can extend the length of your course by adding more weeks
                  above. <br /> To shorten your course, delete the section that
                  corresponds to the last week.{" "}
                </p>
              ) : (
                <p className="schedule-information">
                  Your course currently has a length of {props.currentModules}{" "}
                  weeks. <br />
                  <b>
                    {weeksToBeCreated} {pluralize("week", weeksToBeCreated > 1)}{" "}
                    of modules will be added. <br /> Your course will have a
                    length of {courseLength}{" "}
                    {pluralize("week", courseLength > 1)}.
                  </b>
                  <br />
                  <b>Click save button to add these weeks to the schedule.</b>
                </p>
              )}
            </Box>
            <SquaredButton
              type="primary"
              variant="blue"
              onClick={() => onUpdateSchedule()}
              disabled={
                !validation.isValid ||
                (props.currentModules === courseLength &&
                  format(props.startAt, "yyyy-MM-dd") ===
                    format(startDate, "yyyy-MM-dd"))
              }
            >
              Update Schedule
            </SquaredButton>
          </div>
        )}
      </StyledCourseInfo>
      <Box pad={{ vertical: "small" }} justify={"start"}>
        <HidableComponent isVisible={!!validation.error}>
          <Box
            direction="row"
            background="#E51144"
            height="35px"
            align="center"
            gap="small"
            pad={{ horizontal: "small" }}
            round="xsmall"
          >
            <Alert color="white" />
            <Text color="white">{validation.error!}</Text>
          </Box>
        </HidableComponent>
      </Box>
    </StyledScheduleBuilder>
  );
};
