import React from "react";
import { Blank, IconProps } from "grommet-icons";
import { ChildrenIconProps } from "./FileTypeIcon";
import { IconColors } from "../../theme/GlobalTheme";

const EPUB: React.FC<IconProps & ChildrenIconProps> = (props) => {
  return (
    <Blank viewBox="0 0 24 24" {...props} color="plain">
      <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
      >
        <path
          fill="white"
          d="M15.42.63H8.56A2.29,2.29,0,0,0,6.27,2.92l0,18.29A2.24,2.24,0,0,0,7,22.82a2.28,2.28,0,0,0,1.61.67H21.14a2.29,2.29,0,0,0,1.62-.67,2.26,2.26,0,0,0,.67-1.61V8.63Z"
        />
        <path
          fill={
            props.disabled
              ? IconColors["icon-disabled"]
              : IconColors["icon-dark-blue"]
          }
          d="M15.18,1.2l7.67,7.66V21.2a1.7,1.7,0,0,1-.5,1.21,1.74,1.74,0,0,1-1.22.5H8.57a1.72,1.72,0,0,1-1.21-.5,1.7,1.7,0,0,1-.5-1.21V2.92a1.66,1.66,0,0,1,.5-1.21,1.7,1.7,0,0,1,1.21-.5h6.62m.24-.57H8.56A2.29,2.29,0,0,0,6.27,2.92l0,18.29A2.24,2.24,0,0,0,7,22.82a2.28,2.28,0,0,0,1.61.67H21.14a2.29,2.29,0,0,0,1.62-.67,2.26,2.26,0,0,0,.67-1.61V8.63l-8-8Z"
        />
        <path
          fill={
            props.disabled
              ? IconColors["icon-disabled"]
              : IconColors["icon-light-blue"]
          }
          d="M15.42.92V6.35a2.28,2.28,0,0,0,2.29,2.28h5.43Z"
        />
        <path
          fill={
            props.disabled
              ? IconColors["icon-disabled"]
              : IconColors["icon-dark-blue"]
          }
          d="M23.14,8.91H17.71a2.58,2.58,0,0,1-2.58-2.57V.92a.29.29,0,0,1,.49-.21l7.72,7.71a.31.31,0,0,1,.06.32A.29.29,0,0,1,23.14,8.91ZM15.7,1.61V6.35a2,2,0,0,0,2,2h4.74Z"
        />
        <rect
          fill={
            props.disabled
              ? IconColors["icon-disabled"]
              : IconColors["icon-blue"]
          }
          x="0.57"
          y="11.21"
          width="21.14"
          height="9.14"
          rx="0.86"
        />
        <path
          fill={
            props.disabled
              ? IconColors["icon-disabled"]
              : IconColors["icon-dark-blue"]
          }
          d="M20.85,11.77a.29.29,0,0,1,.29.29v7.42a.29.29,0,0,1-.28.29H1.43a.28.28,0,0,1-.29-.28V12.07a.29.29,0,0,1,.28-.29H20.85m0-.57H1.42a.86.86,0,0,0-.85.86V19.5a.85.85,0,0,0,.86.85H20.86a.85.85,0,0,0,.85-.86V12.06a.86.86,0,0,0-.86-.86Z"
        />
        <path
          fill="white"
          d="M4.36,14.27v1.15H5.91v.73H4.36v1.22H6.11v.75H3.45V13.53H6.1v.75Z"
        />
        <path
          fill="white"
          d="M10.11,15.64a1.29,1.29,0,0,1-.54.52,2,2,0,0,1-.94.19H7.86v1.76H6.94V13.53H8.63a2.11,2.11,0,0,1,.91.18,1.26,1.26,0,0,1,.56.51,1.47,1.47,0,0,1,.19.73A1.42,1.42,0,0,1,10.11,15.64Zm-.95-.2A.66.66,0,0,0,9.34,15c0-.45-.25-.67-.75-.67H7.86v1.33h.73A.86.86,0,0,0,9.16,15.44Z"
        />
        <path
          fill="white"
          d="M11.84,13.53v2.84a1,1,0,0,0,.25.71,1.1,1.1,0,0,0,1.37,0,1,1,0,0,0,.24-.72V13.53h.93v2.84a1.82,1.82,0,0,1-.25,1,1.58,1.58,0,0,1-.68.6,2.09,2.09,0,0,1-.94.21,2.18,2.18,0,0,1-.93-.2,1.55,1.55,0,0,1-.66-.61,1.91,1.91,0,0,1-.25-1V13.53Z"
        />
        <path
          fill="white"
          d="M18.76,16.14a1.19,1.19,0,0,1,.25.73,1.14,1.14,0,0,1-.18.64,1.16,1.16,0,0,1-.53.44,1.78,1.78,0,0,1-.8.16h-2V13.52h1.9a1.94,1.94,0,0,1,.8.15,1.07,1.07,0,0,1,.69,1,1.08,1.08,0,0,1-.21.68,1.2,1.2,0,0,1-.57.38A1,1,0,0,1,18.76,16.14Zm-2.32-.72h.84a.75.75,0,0,0,.5-.15.52.52,0,0,0,.18-.43.52.52,0,0,0-.18-.42.75.75,0,0,0-.5-.15h-.84Zm1.45,1.78a.54.54,0,0,0,.18-.44.58.58,0,0,0-.19-.47.83.83,0,0,0-.54-.16h-.9v1.23h.92A.78.78,0,0,0,17.89,17.2Z"
        />
      </svg>
    </Blank>
  );
};
export default EPUB;
