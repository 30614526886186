import React from "react";
import { Blank, IconProps } from "grommet-icons";
import styled from "styled-components";

const StyledIcon = styled(Blank)`
  animation: spin 1s ease infinite;
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
`;

const Loading: React.FC<IconProps> = (props) => {
  return (
    <StyledIcon viewBox="0 0 24 24" {...props} color="plain">
      <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
      >
        <circle fill="currentColor" cx="17.25" cy="5.85" r="2" />
        <path
          fill="currentColor"
          d="M19.55,9.75a1.8,1.8,0,1,0,1.8,1.8A1.79,1.79,0,0,0,19.55,9.75Z"
        />
        <path
          fill="currentColor"
          d="M17.25,15.55a1.6,1.6,0,1,0,1.6,1.6A1.58,1.58,0,0,0,17.25,15.55Z"
        />
        <path
          fill="currentColor"
          d="M11.55,18.15a1.4,1.4,0,0,0,0,2.8,1.43,1.43,0,0,0,1.4-1.4A1.37,1.37,0,0,0,11.55,18.15Z"
        />
        <path
          fill="currentColor"
          d="M7.15,17.25A1.16,1.16,0,0,0,6,16.05a1.08,1.08,0,0,0-1.2,1.1A1.22,1.22,0,0,0,6,18.35,1.13,1.13,0,0,0,7.15,17.25Z"
        />
        <path
          fill="currentColor"
          d="M3.55,10.55a1,1,0,0,0-.9,1,.9.9,0,0,0,.9.9,1,1,0,0,0,1-.9A1.08,1.08,0,0,0,3.55,10.55Z"
        />
        <circle fill="currentColor" cx="5.95" cy="5.85" r="0.7" />
        <circle fill="currentColor" cx="11.55" cy="3.55" r="0.5" />
      </svg>
    </StyledIcon>
  );
};
export default Loading;
