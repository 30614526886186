import { generateHTML } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import { Link } from "@tiptap/extension-link";

import { transformValues } from "./transformValues";
import type { JSONContent } from "@tiptap/core";

export const generateCanvasHtml = (content: JSONContent): string => {
  const parsedJsonContent = transformValues(
    content,
    (key: string, value: any) => value.type === "file-button",
    (value: any) => ({
      type: "paragraph",
      content: [
        {
          marks: [
            {
              type: "link",
              attrs: {
                target: "_blank",
                href: value.attrs.url,
              },
            },
          ],
          type: "text",
          text: value.attrs.name,
        },
      ],
    })
  );

  return generateHTML(parsedJsonContent, [StarterKit, Link]);
};
