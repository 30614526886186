import React from "react";
import { Blank } from "grommet-icons";

interface PencilProps {}

const Edit: React.FC<PencilProps> = (props) => {
  return (
    <Blank viewBox="0 0 24 24" {...props} color="plain">
      <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
      >
        <path
          fill="currentColor"
          d="M19.5,18.13H12a1,1,0,0,0,0,2h7.5a1,1,0,0,0,0-2Z"
        />
        <path
          fill="currentColor"
          d="M8.54,19,19,8.59a2.9,2.9,0,0,0,.6-.9,2.75,2.75,0,0,0,.21-1.06,2.78,2.78,0,0,0-.21-1.06,2.85,2.85,0,0,0-.6-.89,2.73,2.73,0,0,0-.9-.6,2.81,2.81,0,0,0-3,.6L4.63,15.09a1.06,1.06,0,0,0-.27.47l-.83,3.33a1,1,0,0,0,.26.95,1,1,0,0,0,.71.29,1,1,0,0,0,.24,0l3.34-.83A1,1,0,0,0,8.54,19ZM5.87,17.76l.37-1.45L16.46,6.09a.79.79,0,0,1,.83-.17.74.74,0,0,1,.25.17.85.85,0,0,1,.17.25.72.72,0,0,1,.06.29.8.8,0,0,1-.06.3.85.85,0,0,1-.17.25L7.32,17.4Z"
        />
      </svg>
    </Blank>
  );
};

export default Edit;
