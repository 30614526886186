import React, { useState } from "react";
import { Skill, Skills } from "../../../model/Syllabus";
import SectionEditorProps from "./SectionEditorProps";
import useDelayedState from "../../../hooks/useDelayedState";
import TextInputListItem from "../../TextInputListItem";
import TextInputList from "../../TextInputList";
import { HidableComponent } from "components/hoc/Hidable";
import Alert from "components/Alert";

interface SkillEditorProps {
  skill: Skill;
  onChange: (skill: Skill) => void;
  onDelete: () => void;
  error?: string;
  label?: string;
}

// Editor component for a single skill. At the moment we only have a text body,
// specified in markdown format
export const SkillEditor: React.FC<SkillEditorProps> = (props) => {
  const [skill, setSkill] = useDelayedState<Skill>(props.skill, props.onChange);

  return (
    <TextInputListItem
      autoFocus={true}
      required={true}
      gridA11yTitle={""}
      value={skill.body}
      boxA11yTitle={""}
      inputA11yTitle={"Skill input"}
      inputPlaceholder={
        "What skills are required? What skills will be developed?"
      }
      onInputChange={(event: any) => {
        if (event.target.value !== undefined) {
          setSkill({
            ...skill,
            body: event.target.value,
          });
        }
      }}
      buttonA11yTitle={"Button to remove current skill"}
      onButtonClick={() => {
        props.onDelete();
      }}
      error={props.error}
      label={props.label}
    />
  );
};

// Editor for list of skills:
// - add a skill
// - change skills
//
const SkillsEditor: React.FC<SectionEditorProps> = ({
  section,
  onChange,
  error,
}) => {
  const [_section, _setSection] = useState({ ...(section as Skills) });
  const [indexOffset, setIndexOffset] = useState(0);

  // Propagate changes to parent
  const setSection = (newSection: Skills) => {
    onChange(newSection);
    _setSection(newSection);
  };

  // Replace an existing skill
  const onChangeSkill = (skill: Skill, index: number) => {
    const newSkills = [..._section.skills];
    newSkills[index] = skill;
    setSection({
      ..._section,
      skills: newSkills,
    });
  };

  // Append a new skill to the section
  const addSkill = () => {
    setSection({
      ..._section,
      skills: [..._section.skills, { body: "", id: "" } as Skill],
    });
  };

  // Delete a skill from the section
  const onDeleteSkill = (index: number) => {
    const newSkills = _section;
    newSkills.skills.splice(index, 1);
    setSection(newSkills);
    setIndexOffset(indexOffset + newSkills.skills.length + 1);
  };

  // Render
  return (
    <>
      <TextInputList
        gridA11yTitle={"Add Skills"}
        boxA11yTitle={""}
        buttonA11yTitle={"Button to add another skill"}
        addContent={() => addSkill()}
        buttonText={"Add a Skill"}
      >
        {_section.skills.map((skill, index) => (
          <SkillEditor
            key={"skill-" + index + indexOffset}
            skill={skill}
            onChange={(skill) => onChangeSkill(skill, index)}
            onDelete={() => onDeleteSkill(index)}
            error={error?.errors[`skills[${index}].body`]}
            label={`Skill ${index + 1}`}
          />
        ))}
      </TextInputList>
      <HidableComponent isVisible={!!error?.errors.skills}>
        <Alert>{error?.errors.skills}</Alert>
      </HidableComponent>
    </>
  );
};

export default SkillsEditor;
