import React from "react";
import { Blank, IconProps } from "grommet-icons";

const Link: React.FC<IconProps> = (props) => {
  return (
    <Blank viewBox="0 0 24 24" {...props} color="plain">
      <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
      >
        <defs>
          <style>
            {
              ".cls-1{fill:none;stroke:#000417;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px}"
            }
          </style>
        </defs>
        <title>link</title>
        <path
          className="cls-1"
          d="M10.87,7.47,12,6.34a4,4,0,0,1,5.66,0h0a4,4,0,0,1,0,5.66l-1.13,1.13"
        />
        <path
          className="cls-1"
          d="M7.47,10.87,6.34,12a4,4,0,0,0,0,5.66h0a4,4,0,0,0,5.66,0l1.13-1.13"
        />
        <line className="cls-1" x1="10.59" y1="13.41" x2="13.41" y2="10.59" />
      </svg>
    </Blank>
  );
};
export default Link;
