import React from "react";
import { Text, Anchor, Box } from "grommet";

const Copyright: React.FC = () => {
  return (
    <Box justify="center" align="center">
      <Text color="inherit" a11yTitle="Copyright BlendEd">
        {"Copyright © "}
        <Anchor
          color="inherit"
          href="https://blendedcourse.com/"
          label={"BlendEd"}
        />{" "}
        {new Date().getFullYear()}
        {"."}
      </Text>
    </Box>
  );
};

export default Copyright;
