export const canvasScopes = [
  "url:GET|/api/v1/accounts",
  "/auth/userinfo",
  "url:GET|/api/v1/courses",
  "url:GET|/api/v1/accounts",
  "url:POST|/api/v1/accounts/:account_id/courses",
  "url:GET|/api/v1/courses/:course_id/pages",
  "url:DELETE|/api/v1/courses/:course_id/pages/:url_or_id",
  "url:GET|/api/v1/courses/:course_id/assignments",
  "url:DELETE|/api/v1/courses/:course_id/assignments/:id",
  "url:GET|/api/v1/courses/:course_id/modules",
  "url:DELETE|/api/v1/courses/:course_id/modules/:id",
  "url:PUT|/api/v1/courses/:id",
  "url:POST|/api/v1/courses/:course_id/modules",
  "url:POST|/api/v1/courses/:course_id/assignments",
  "url:POST|/api/v1/courses/:course_id/modules/:module_id/items",
  "url:POST|/api/v1/courses/:course_id/pages",
  "url:POST|/api/v1/courses/:course_id/outcome_imports(/group/:learning_outcome_group_id)",
  "url:GET|/api/v1/courses/:course_id/outcome_imports/:id/created_group_ids",
  "url:POST|/api/v1/courses/:course_id/outcome_groups/:id/outcomes",
  "url:POST|/api/v1/courses/:course_id/rubrics",
  "url:GET|/api/v1/outcomes/:id",
  "url:GET|/api/v1/courses/:course_id/rubrics/:id",
  "url:POST|/api/v1/courses/:course_id/rubric_associations",
  "url:GET|/api/v1/courses/:course_id/rubrics",
  "url:GET|/api/v1/courses/:course_id/outcome_groups/:id",
  "url:GET|/api/v1/courses/:course_id/outcome_groups/:id/outcomes",
  "url:GET|/api/v1/courses/:course_id/outcome_imports/:id",
];
