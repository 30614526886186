import { BaseSection } from "../../Syllabus";
import { SectionErrorItem } from "../SyllabusErrorBag";
import _ from "lodash";

export abstract class SectionValidator {
  sectionErrorItem?: SectionErrorItem;

  abstract validate(section: BaseSection): SectionErrorItem | undefined;

  existError(): boolean {
    return !_.isEmpty(this.sectionErrorItem?.errors);
  }
}
