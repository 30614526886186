import React from "react";
import { Blank, IconProps } from "grommet-icons";
import { ChildrenIconProps } from "./FileTypeIcon";
import { IconColors } from "../../theme/GlobalTheme";

const RAR: React.FC<IconProps & ChildrenIconProps> = (props) => {
  return (
    <Blank viewBox="0 0 39 42" {...props} color="plain">
      <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
      >
        <path
          fill="white"
          d="M14.83.56H8a2.34,2.34,0,0,0-1.62.68,2.29,2.29,0,0,0-.66,1.61V21.14A2.29,2.29,0,0,0,8,23.42H20.56a2.29,2.29,0,0,0,2.28-2.28V8.55Z"
        />
        <path
          fill={
            props.disabled
              ? IconColors["icon-disabled"]
              : IconColors["icon-dark-blue"]
          }
          d="M14.59,1.13l7.67,7.66V21.13a1.72,1.72,0,0,1-.5,1.21,1.74,1.74,0,0,1-1.21.5H8a1.74,1.74,0,0,1-1.21-.5,1.7,1.7,0,0,1-.5-1.21V2.85a1.66,1.66,0,0,1,.5-1.21A1.7,1.7,0,0,1,8,1.14h6.62m.24-.57H8a2.34,2.34,0,0,0-1.62.68,2.29,2.29,0,0,0-.66,1.61V21.14A2.29,2.29,0,0,0,8,23.42H20.56a2.29,2.29,0,0,0,2.28-2.28V8.55l-8-8Z"
        />
        <path
          fill={
            props.disabled
              ? IconColors["icon-disabled"]
              : IconColors["icon-light-blue"]
          }
          d="M14.83.85V6.27a2.29,2.29,0,0,0,2.29,2.29h5.43Z"
        />
        <path
          fill={
            props.disabled
              ? IconColors["icon-disabled"]
              : IconColors["icon-dark-blue"]
          }
          d="M22.55,8.84H17.12a2.58,2.58,0,0,1-2.58-2.57V.85A.29.29,0,0,1,15,.64l7.72,7.71a.3.3,0,0,1,.06.31A.28.28,0,0,1,22.55,8.84Zm-7.44-7.3V6.27a2,2,0,0,0,2,2h4.74Z"
        />
        <rect
          fill={
            props.disabled
              ? IconColors["icon-disabled"]
              : IconColors["icon-blue"]
          }
          x="1.12"
          y="11.13"
          width="20"
          height="9.14"
          rx="0.86"
        />
        <path
          fill={
            props.disabled
              ? IconColors["icon-disabled"]
              : IconColors["icon-dark-blue"]
          }
          d="M20.26,11.7a.29.29,0,0,1,.29.29v7.42a.28.28,0,0,1-.28.29H2a.28.28,0,0,1-.28-.28V12A.29.29,0,0,1,2,11.71H20.26m0-.57H2a.85.85,0,0,0-.86.86v7.43a.85.85,0,0,0,.85.85H20.27a.86.86,0,0,0,.86-.86V12a.85.85,0,0,0-.86-.85Z"
        />
        <path
          fill="white"
          d="M7.38,18.05l-1-1.79H5.93v1.79H5V13.46H6.73a1.94,1.94,0,0,1,.91.19,1.25,1.25,0,0,1,.56.5,1.33,1.33,0,0,1,.19.71,1.33,1.33,0,0,1-1.05,1.33L8.44,18ZM5.93,15.57H6.7a.77.77,0,0,0,.56-.18.69.69,0,0,0,.18-.51.66.66,0,0,0-.18-.49.81.81,0,0,0-.56-.17H5.93Z"
        />
        <path
          fill="white"
          d="M12,17.17H10.21l-.3.87h-1l1.65-4.59h1.07L13.31,18h-1Zm-.25-.74-.67-1.92-.66,1.92Z"
        />
        <path
          fill="white"
          d="M16.3,18l-1-1.79h-.43V18h-.92V13.45h1.72a2,2,0,0,1,.91.19,1.26,1.26,0,0,1,.56.51,1.42,1.42,0,0,1,.19.71,1.37,1.37,0,0,1-.27.82,1.32,1.32,0,0,1-.78.5L17.36,18Zm-1.45-2.48h.77a.79.79,0,0,0,.56-.18.68.68,0,0,0,.18-.5.66.66,0,0,0-.18-.49.79.79,0,0,0-.56-.18h-.77Z"
        />
      </svg>
    </Blank>
  );
};

export default RAR;
