import React from "react";
import styled from "styled-components";
import { Keyboard, Box } from "grommet";
import { TableColors } from "../ui-kit/Table/TableColors";

interface ColorSelectorProps {
  onSelectedColor?: (color: string) => void;
  selectedColor?: string;
  a11yTitle: string;
}

const colors = Object.values(TableColors).map(
  (colorScheme) => colorScheme!.primaryColor
);

const names = Object.values(TableColors).map(
  (colorScheme) => colorScheme!.name
);

const defaultProps: Partial<ColorSelectorProps> = {
  selectedColor: colors[0],
};

const StyledTitle = styled.h2`
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #474e61;
  margin-bottom: 6px;
`;

const StyledColorWrapper = styled.div`
  width: 220px;
  height: 107.12px;
  background: #ffffff;
  border: 1px solid #f1f6ff;
  box-sizing: border-box;
  border-radius: 4px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 15px 15px;
  padding: 5px;
`;

const StyledColorContent = styled.div`
  background: ${(props) => props.theme.color};
  height: 32px;
  width: 32px;
  cursor: pointer;
  border-radius: 50%;
  position: relative;
  top: 4px;
  left: 4px;

  &::before {
    content: "";
    position: absolute;
    background: transparent;
    width: 40px;
    height: 40px;
    top: -4px;
    left: -4px;
    box-sizing: border-box;
    border: 1px solid #a5aec1;
    border-radius: 50%;
    visibility: ${(props) => (props.theme.active ? "visible" : "hidden")};
  }
`;

const ColorSelector: React.FC<ColorSelectorProps> = (props) => {
  return (
    <div>
      <StyledTitle>Color</StyledTitle>
      <div>
        <StyledColorWrapper>
          {colors.map((color, index) => (
            <Keyboard
              onEnter={() => {
                props.onSelectedColor?.(color);
              }}
            >
              <Box a11yTitle={names[index]}>
                <StyledColorContent
                  tabIndex={0}
                  key={`index-${color}`}
                  theme={{
                    color: color,
                    active: props.selectedColor === color,
                  }}
                  onClick={() => {
                    props.onSelectedColor?.(color);
                  }}
                />
              </Box>
            </Keyboard>
          ))}
        </StyledColorWrapper>
      </div>
    </div>
  );
};

ColorSelector.defaultProps = defaultProps;

export default ColorSelector;
