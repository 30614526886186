import {
  CommonActions,
  CommonActionType,
  SnackbarMessage,
} from "../types/CommonTypes";
import { PendingAssignment } from "../../../model/Syllabus";

export interface CommonState {
  editionMode: boolean;
  clicked: boolean;
  snackbarMessages: SnackbarMessage[];
  pendingAssignment: PendingAssignment[];
  redirect?: {
    redirectUrl: string;
    expiration: Date;
  };
}

const initialState: CommonState = {
  snackbarMessages: [],
  editionMode: false,
  pendingAssignment: [],
  clicked: false,
  redirect: undefined,
};

export const CommonReducer = (
  state = initialState,
  action: CommonActions
): CommonState => {
  switch (action.type) {
    case CommonActionType.SetEditMode:
      return {
        ...state,
        editionMode: action.editionMode,
      };
    case CommonActionType.AddSnackbarMessage:
      return {
        ...state,
        snackbarMessages: [...state.snackbarMessages, action.payload],
      };
    case CommonActionType.RemoveSnackbarMessage:
      return {
        ...state,
        snackbarMessages: state.snackbarMessages.filter(
          (snackbarMessage) => snackbarMessage.id !== action.payload
        ),
      };
    case CommonActionType.SetLoadingContent:
      return {
        ...state,
        clicked: action.payload,
      };
    case CommonActionType.AddAssignment:
      return {
        ...state,
        pendingAssignment: [...state.pendingAssignment, action.payload],
      };
    case CommonActionType.RemoveAssignment:
      return {
        ...state,
        pendingAssignment: state.pendingAssignment.filter(
          (pendingAssignment) =>
            pendingAssignment.assignment.resource?.contentRef !== action.payload
        ),
      };
    case CommonActionType.SetRedirect:
      return {
        ...state,
        redirect: {
          redirectUrl: action.payload.redirectUrl,
          expiration: action.payload.expiration,
        },
      };
    case CommonActionType.DeleteRedirect:
      return {
        ...state,
        redirect: initialState.redirect,
      };
    default:
      return state;
  }
};
