import React from "react";
import { CreditHours } from "../../../model/Syllabus";
import styled from "styled-components";
import Paragraph from "../../viewer/customization/Paragraph";
import H4 from "../../viewer/customization/H4";

interface CreditHoursSectionProps {
  section: CreditHours;
}

const StyledParagraph = styled(Paragraph)`
  color: #030b1d;
`;

const CreditHoursSection: React.FC<CreditHoursSectionProps> = (props) => {
  const section = props.section;
  return (
    <>
      <H4 a11yTitle={"Credit Hours Section"}>Credit Hours</H4>
      <StyledParagraph>{section.creditHours}</StyledParagraph>
    </>
  );
};

export default CreditHoursSection;
