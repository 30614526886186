/*
 * Apply transformation to an object recursively if the condition given is true
 */
export function transformValues(
  firebaseObject: any,
  condition: (key: string, value: any) => boolean,
  transformation: (value: any) => any
) {
  if (!firebaseObject) {
    return firebaseObject;
  }
  //If the value given is an array we call the function for each child.
  for (const [key, value] of Object.entries(firebaseObject)) {
    // covert items inside array
    if (value && Array.isArray(value)) {
      firebaseObject[key] = value.map((item) =>
        transformValues(item, condition, transformation)
      );
    }
    //If the value is an object, we call the function for each element.
    // convert inner objects
    if (value && typeof value === "object") {
      firebaseObject[key] = transformValues(value, condition, transformation);
    }

    // convert simple properties
    // @ts-ignore
    if (value && condition(key, value)) {
      firebaseObject[key] = transformation(value);
    }
  }
  return firebaseObject;
}
