import { useAppSelector } from "../redux/configureStore";
import { transformValues } from "../utils/fbUtil";
import { OpenedResource } from "../model/Resource";

export const useOpenedResources = (): OpenedResource[] => {
  const openedResources = useAppSelector(
    (state) => state.syllabus.openedResources
  );

  return transformValues(
    openedResources,
    (key, value) => key === "openedAt" && !!Date.parse(value),
    (value) => new Date(value)
  );
};
