import React from "react";
import { Blank, IconProps } from "grommet-icons";
import { ChildrenIconProps } from "./FileTypeIcon";
import { IconColors } from "../../theme/GlobalTheme";

const AI: React.FC<IconProps & ChildrenIconProps> = (props) => {
  return (
    <Blank viewBox="0 0 128 128" {...props} color="plain">
      <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
      >
        <path
          fill="white"
          d="M14.84.59H8A2.29,2.29,0,0,0,5.7,2.89V21.17a2.34,2.34,0,0,0,.68,1.62A2.26,2.26,0,0,0,8,23.46H20.57a2.29,2.29,0,0,0,2.29-2.29V8.59Z"
        />

        <path
          fill={
            props.disabled
              ? IconColors["icon-disabled"]
              : IconColors["icon-dark-blue"]
          }
          d="M14.57,1.2l7.67,7.66V21.2a1.71,1.71,0,0,1-1.71,1.71H8a1.74,1.74,0,0,1-1.22-.5,1.7,1.7,0,0,1-.5-1.21V2.92A1.71,1.71,0,0,1,8,1.21h6.62m.24-.57H8A2.29,2.29,0,0,0,5.67,2.92V21.21a2.29,2.29,0,0,0,.67,1.62A2.32,2.32,0,0,0,8,23.49H20.54a2.34,2.34,0,0,0,1.62-.68,2.29,2.29,0,0,0,.66-1.61V8.63l-8-8Z"
        />
        <path
          fill={
            props.disabled
              ? IconColors["icon-disabled"]
              : IconColors["icon-light-blue"]
          }
          d="M14.81.92V6.35A2.29,2.29,0,0,0,17.1,8.63h5.43Z"
        />
        <path
          fill={
            props.disabled
              ? IconColors["icon-disabled"]
              : IconColors["icon-dark-blue"]
          }
          d="M22.53,8.91H17.1a2.58,2.58,0,0,1-2.57-2.57V.92A.29.29,0,0,1,14.7.65.28.28,0,0,1,15,.72l7.72,7.7a.29.29,0,0,1,.06.32A.28.28,0,0,1,22.53,8.91ZM15.1,1.61V6.35a2,2,0,0,0,2,2h4.74Z"
        />
        <rect
          fill={
            props.disabled
              ? IconColors["icon-disabled"]
              : IconColors["icon-blue"]
          }
          x="1.11"
          y="11.21"
          width="20"
          height="9.14"
          rx="0.86"
        />
        <path
          fill={
            props.disabled
              ? IconColors["icon-disabled"]
              : IconColors["icon-dark-blue"]
          }
          d="M20.25,11.77a.29.29,0,0,1,.28.29v7.43a.29.29,0,0,1-.29.28H2a.28.28,0,0,1-.29-.28V12.07A.29.29,0,0,1,2,11.78H20.25m0-.57H2a.87.87,0,0,0-.86.86V19.5a.85.85,0,0,0,.86.85H20.25a.86.86,0,0,0,.86-.86V12.06a.85.85,0,0,0-.85-.86Z"
        />
        <path
          fill="white"
          d="M11.14,17.2H9.31l-.3.88h-1l1.64-4.6h1.07l1.66,4.6h-1Zm-.25-.73-.66-1.92-.67,1.92Z"
        />
        <path fill="white" d="M14,13.49v4.58H13V13.49Z" />
      </svg>
    </Blank>
  );
};

export default AI;
