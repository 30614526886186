import React from "react";
import { Module, Section } from "../../../model/Syllabus";
import CourseDescriptionSection from "./CourseDescriptionSection";
import CourseTitleSection from "./CourseTitleSection";
import InstructorsSection from "./InstructorsSection";
import CreditHoursSection from "./CreditHoursSection";
import ClassTimesSection from "./ClassTimesSection";
import OfficeHoursSection from "./OfficeHoursSection";
import ScheduleSection from "./ScheduleSection";
import SkillsSection from "./SkillsSection";
import PrerequisitesSection from "./PrerequisitesSection";
import GradingPolicySection from "./GradingPolicySection";
import LocationSection from "./LocationSection";
import MaterialsSection from "./MaterialsSection";
import ObjectivesSection from "./ObjectivesSection";
import CustomSectionSection from "./CustomSection";
import { Box } from "grommet";
import ModuleSection from "./ModuleSection";
import { Activity } from "../../../model/Resource";

interface SectionComponentProps {
  section: Section;
  id: string;
  onClickWeek?: (week: Module) => void;
  onClickActivity?: (activity: Activity) => void;
}

const renderSection = (
  section: Section,
  onClickWeek?: (week: Module) => void,
  onClickActivity?: (activity: Activity) => void
) => {
  switch (section.type) {
    case "CourseTitle":
      return <CourseTitleSection section={section} />;
    case "CourseDescription":
      return <CourseDescriptionSection section={section} />;
    case "CourseObjectives":
      return <ObjectivesSection section={section} />;
    case "CustomSection":
      return <CustomSectionSection section={section} />;
    case "Instructors":
      return <InstructorsSection section={section} />;
    case "CreditHours":
      return <CreditHoursSection section={section} />;
    case "ClassTimes":
      return <ClassTimesSection section={section} />;
    case "OfficeHours":
      return <OfficeHoursSection section={section} />;
    case "Schedule":
      return (
        <ScheduleSection
          section={section}
          onClickWeek={onClickWeek}
          onClickActivity={onClickActivity}
        />
      );
    /*case "RequiredTexts":
      return <RequiredTextsSection section={section} />;*/
    case "GradingPolicy":
      return <GradingPolicySection section={section} />;
    /*case "Policies":
      return <PoliciesSection section={section} />;*/
    case "Skills":
      return <SkillsSection section={section} />;
    case "Prerequisites":
      return <PrerequisitesSection section={section} />;
    case "Materials":
      return <MaterialsSection section={section} />;
    case "Location":
      return <LocationSection section={section} />;
    case "Module":
      return <ModuleSection section={section} />;
    default:
      return <></>;
  }
};

const SectionComponent: React.FC<SectionComponentProps> = (props) => {
  const section = props.section;
  return (
    <Box id={props.id} pad={"xsmall"} tabIndex={1} focusIndicator={true}>
      {renderSection(section, props.onClickWeek, props.onClickActivity)}
    </Box>
  );
};

export default SectionComponent;
