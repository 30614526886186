import React, { useEffect, useState } from "react";
import { match } from "react-router-dom";
import { Syllabus } from "../../../model/Syllabus";
import { useFirestore } from "react-redux-firebase";
import { Identifiable } from "utils/Identifiable";
import PublicSyllabusRepository from "../../../repositories/PublicSyllabusRepository";
import ModulePage from "./ModulePage";
import { useAppDispatch, useAppSelector } from "redux/configureStore";
import styled from "styled-components";
import { createHash } from "../../../helpers/createHash";
import { addPassword } from "../../../redux/students/actions/SavedPasswordsActions";
import { HidableComponent } from "../../hoc/Hidable";
import AskPasswordModal from "../AskPasswordModal";
import SyllabusThemeContextProvider from "../customization/SyllabusTheme";

const PublicModulePageViewerWrapper = styled.div`
  .blur {
    position: absolute;
    width: 100%;
    height: 100vh;
    backdrop-filter: blur(12px);
    z-index: 1;
  }
`;

interface ModulePageViewerProps {
  match: match<Identifiable>;
}

const ModulePageViewer: React.FC<ModulePageViewerProps> = ({ match }) => {
  const firestore = useFirestore();
  const [syllabus, setSyllabus] = useState<Syllabus | undefined>(undefined);
  const [unlocked, setUnlocked] = useState<boolean>(false);
  const id = match.params.id;
  const index: number = match.params.index;

  const savedPasswords = useAppSelector(
    (state) => state.students.savedPasswords.passwords
  );

  useEffect(() => {
    (async () => {
      try {
        const repository = new PublicSyllabusRepository();
        const syllabus = await repository.findById(id);
        setSyllabus(syllabus);
        window.analytics.track("Module Requested", {
          syllabusId: id,
          moduleIndex: index,
        });
      } catch (error) {
        window.analytics.track("Module Not Found", {
          syllabusId: id,
          moduleIndex: index,
        });
      }
    })();
  }, [firestore, id, index]);

  useEffect(() => {
    const password = savedPasswords.find(
      (password) => password.syllabusId === id
    );
    if (
      (password && password.password === syllabus?.password) ||
      syllabus?.password === ""
    ) {
      setUnlocked(true);
      window.analytics.track("Syllabus Viewed", { syllabusId: id });
    }
  }, [savedPasswords, syllabus, id]);

  const [showAskPasswordModal, setShowAskPasswordModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const dispatch = useAppDispatch();

  const checkPassword = (password: string) => {
    const hash = createHash(password);
    if (syllabus?.password === hash) {
      setShowAskPasswordModal(false);
      dispatch(addPassword(syllabus.firebaseId!, hash));
      window.analytics.track("Password Accepted", {
        syllabusId: syllabus.firebaseId!,
      });
      window.analytics.track("Syllabus Viewed", {
        syllabusId: syllabus.firebaseId!,
      });
    } else {
      setErrorMessage("Password incorrect");
      window.analytics.track("Incorrect Password Entered", {
        syllabusId: syllabus?.firebaseId,
      });
    }
  };

  const renderShowModalPassword = () => {
    return (
      <HidableComponent isVisible={showAskPasswordModal}>
        <div className="blur" />
        <AskPasswordModal
          opened={showAskPasswordModal}
          onClickConfirm={(password) => checkPassword(password)}
          onClickCancel={() => setShowAskPasswordModal(false)}
          errorMessage={errorMessage}
          title="This is a private syllabus"
          description="Please, write the password to unlock the content of this syllabus"
          onFocus={() => setErrorMessage("")}
        />
      </HidableComponent>
    );
  };

  if (syllabus) {
    return (
      <PublicModulePageViewerWrapper>
        <SyllabusThemeContextProvider
          fontFamily={syllabus.customization.fontFamily}
          primaryColor={syllabus.customization.primaryColor}
        >
          <ModulePage
            syllabus={syllabus}
            moduleIndex={index}
            unlocked={unlocked}
            clickOnPrivateContent={() => {
              setShowAskPasswordModal(true);
            }}
          />
          {renderShowModalPassword()}
        </SyllabusThemeContextProvider>
      </PublicModulePageViewerWrapper>
    );
  } else {
    return <div />;
  }
};

export default ModulePageViewer;
