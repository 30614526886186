import React, { forwardRef, useImperativeHandle, useState } from "react";
import { Button } from "grommet";
import clsx from "clsx";
import styled from "styled-components";
import {
  ButtonSize,
  ButtonType,
  ButtonVariant,
} from "../../../theme/GlobalTheme";
import { Blank } from "grommet-icons";
import Check from "assets/icons/Check";
import Loading from "../../../assets/icons/Loading";

export interface RoundedButtonRef {
  startSuccess: () => void;
}

interface RoundedButtonProps {
  type?: ButtonType;
  variant?: ButtonVariant;
  size?: ButtonSize;
  /**
   * Disabled buttons can't be pressed
   */
  disabled?: boolean;
  /**
   * Uses loading state. A button with loading=true can't be pressed.
   */
  loading?: boolean;
  className?: string;
  onClick?: () => void;
  /**
   * Icon that will be shown inside the button. It has to be a Grommet Icon or a SVG icon. The color value of fill and stroke props must be 'currentColor'
   */
  icon?: JSX.Element;
  /**
   * If it's true, icon will be shown to the right side instead of left side.
   */
  reverseIcon?: boolean;
}

const defaultProps: Partial<RoundedButtonProps> = {
  size: "regular",
  type: "primary",
  variant: "blue",
};

const StyledButton = styled(Button)`
  &.button {
    font-family: Poppins;
    font-weight: 500;
    line-height: 22px;
    border-radius: 20px;
    text-align: center;
  }

  &.button-primary {
    &.blue {
      color: white;
      background: #0d3af8;
      box-shadow: 0 2px 4px rgba(7, 26, 67, 0.25);
      border: 1px solid transparent;

      &:hover {
        background: #2256f2;
        box-shadow: 0 3px 8px rgba(7, 26, 67, 0.35);
      }

      &.disabled {
        background: #9da2ac;
        box-shadow: none;

        &:hover {
          cursor: not-allowed;
        }
        &:active {
          background: #9da2ac;
          box-shadow: none;
          border: 1px solid transparent;
        }
      }

      &:active {
        background: #1141d3;
        border: 1px solid #193da9;
        box-shadow: none;
      }

      &.loading {
        background: #7f91c9;
        box-shadow: none;
        cursor: not-allowed;
        &:active {
          border: 1px solid transparent;
        }
      }
    }

    &.red {
      color: white;
      background: #e51144;
      box-shadow: 0 2px 4px rgba(67, 7, 7, 0.25);
      border: 1px solid transparent;

      &:hover {
        background: #fe5166;
        box-shadow: 0 3px 8px rgba(67, 7, 7, 0.35);
      }

      &.disabled {
        background: #9da2ac;
        box-shadow: none;
        &:hover {
          cursor: not-allowed;
        }
        &:active {
          background: #9da2ac;
          box-shadow: none;
          border: 1px solid transparent;
        }
      }

      &:active {
        background: #ca0937;
        border: 1px solid #960427;
        box-shadow: none;
      }

      &.loading {
        background: #ce747f;
        box-shadow: none;
        cursor: not-allowed;
        &:active {
          border: 1px solid transparent;
        }
      }
    }

    &.dark-blue {
      background: #193da9;
      color: white;
      box-shadow: 0 2px 4px rgba(7, 26, 67, 0.25);
      border: 1px solid transparent;

      &:hover {
        background: #2c58db;
        box-shadow: 0 3px 8px rgba(7, 26, 67, 0.35);
      }
      &.disabled {
        background: #9da2ac;
        box-shadow: none;
        &:hover {
          cursor: not-allowed;
        }
        &:active {
          background: #9da2ac;
          box-shadow: none;
          border: 1px solid transparent;
        }
      }

      &:active {
        background: #08298a;
        border: 1px solid #021b67;
        box-shadow: none;
      }

      &.loading {
        background: #596ba3;
        box-shadow: none;
        cursor: not-allowed;
        &:active {
          border: 1px solid transparent;
        }
      }
    }
  }

  &.button-secondary {
    &.blue {
      color: #0d3af8;
      background: white;
      border: 1.5px solid #0d3af8;

      &:hover {
        background: #eff3ff;
        box-shadow: none;
      }

      &.disabled {
        color: #9da2ac;
        border: 1.5px solid #9da2ac;
        &:hover {
          color: #9da2ac;
          border: 1.5px solid #9da2ac;
          background: white;
          cursor: not-allowed;
        }
        &:active {
          color: #9da2ac;
          border: 1.5px solid #9da2ac;
          background: white;
        }
      }

      &:active {
        background: #dde6ff;
        color: #193da9;
        border: 1.5px solid #193da9;
      }

      &.loading {
        background: #eff3ff;
        color: #596ba3;
        border: 1.5px solid #596ba3;
        cursor: not-allowed;
      }
    }

    &.red {
      color: #e51144;
      background: white;
      border: 1.5px solid #e51144;

      &:hover {
        background: #ffe7ec;
      }

      &.disabled {
        border: 1.5px solid #9da2ac;
        color: #9da2ac;
        &:hover {
          color: #9da2ac;
          border: 1.5px solid #9da2ac;
          background: white;
          cursor: not-allowed;
        }
        &:active {
          color: #9da2ac;
          border: 1.5px solid #9da2ac;
          background: white;
        }
      }

      &:active {
        background: #fbd9dc;
        border: 1.5px solid #e51144;
      }

      &.loading {
        background: #ffe7ec;
        border: 1.5px solid #ce747f;
        color: #ce747f;
        cursor: not-allowed;
      }
    }

    &.dark-blue {
      background: white;
      color: #193da9;
      border: 1.5px solid #193da9;
      &:hover {
        background: #eff3ff;
      }
      &.disabled {
        border: 1.5px solid #9da2ac;
        color: #9da2ac;
        &:hover {
          color: #9da2ac;
          border: 1.5px solid #9da2ac;
          background: white;
          cursor: not-allowed;
        }
        &:active {
          color: #9da2ac;
          border: 1.5px solid #9da2ac;
          background: white;
        }
      }
      &:active {
        background: #dde6ff;
      }
      &.loading {
        background: #eff3ff;
        border: 1.5px solid #596ba3;
        color: #596ba3;
        cursor: not-allowed;
      }
    }
  }

  &.button-tertiary {
    &.blue {
      color: #0d3af8;
      background: none;
      border: 1px solid transparent;

      &:hover {
        background: #eff3ff;
      }

      &.disabled {
        color: #9da2ac;
        &:hover {
          background: none;
          cursor: not-allowed;
        }
        &:active {
          color: #9da2ac;
          background: none;
          border: 1px solid transparent;
        }
      }

      &:active {
        background: #dde6ff;
        border: 1px solid #d0dbfa;
        color: #193da9;
      }

      &.loading {
        background: #eff3ff;
        color: #596ba3;
        cursor: not-allowed;
        &:active {
          border: 1px solid transparent;
        }
      }
    }

    &.red {
      color: #e51144;
      background: none;
      border: 1px solid transparent;

      &:hover {
        background: #ffe7ec;
      }

      &.disabled {
        color: #9da2ac;
        &:hover {
          background: none;
          cursor: not-allowed;
        }
        &:active {
          color: #9da2ac;
          background: none;
          border: 1px solid transparent;
        }
      }

      &:active {
        background: #fbd9dc;
        border: 1px solid #efc3c7;
        box-sizing: border-box;
      }

      &.loading {
        background: #ffe7ec;
        color: #ce747f;
        cursor: not-allowed;
        &:active {
          border: 1px solid transparent;
        }
      }
    }

    &.dark-blue {
      background: none;
      color: #193da9;
      border: 1px solid transparent;

      &:hover {
        background: #eff3ff;
      }
      &.disabled {
        color: #9da2ac;
        &:hover {
          background: none;
          cursor: not-allowed;
        }
        &:active {
          color: #9da2ac;
          background: none;
          border: 1px solid transparent;
        }
      }
      &:active {
        background: #dde6ff;
        border: 1px solid #d0dbfa;
      }
      &.loading {
        background: #eff3ff;
        color: #596ba3;
        cursor: not-allowed;
        &:active {
          border: 1px solid transparent;
        }
      }
    }
  }

  &.button-small {
    height: 32px;
    font-size: 13px;
    padding: 0 30px;
  }

  &.button-regular {
    height: 40px;
    font-size: 15px;
    padding: 0 36px;
  }

  &.button-hero {
    height: 48px;
    font-size: 17px;
    padding: 0 44px;
    border-radius: 24px;
  }
`;

const StyledDiv = styled.div<RoundedButtonProps>`
  display: flex;
  flex-direction: ${(props) => (props.reverseIcon ? "row-reverse" : "row")};
  justify-content: center;

  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: ${(props) => (props.reverseIcon ? "0" : "0 3px")};
    width: 24px;
  }

  .text {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: ${(props) => (props.reverseIcon ? "0 9px 0 9px" : "0 9px 0 3px")};
    position: relative;
    left: -12px;
  }

  .alignLeft {
    left: 0;
  }
`;

const RoundedButton: React.FC<RoundedButtonProps> = forwardRef((props, ref) => {
  const [success, setSuccess] = useState<boolean | undefined>(false);

  const renderIcon = () => {
    if (props.loading) {
      return <Loading size="18px" color="currentColor" />;
    } else if (success) {
      return <Check size="18px" color="currentColor" />;
    } else if (props.icon) {
      return (
        <Blank size="18px" color="plain">
          {props.icon}
        </Blank>
      );
    } else {
      return undefined;
    }
  };
  useImperativeHandle(ref, () => ({ startSuccess }));

  function startSuccess() {
    setSuccess(true);
    setTimeout(() => {
      setSuccess(false);
    }, 3000);
  }

  return (
    <StyledButton
      onClick={() => !props.disabled && !props.loading && props.onClick?.()}
      className={clsx({
        button: true,
        [`button-${props.type}`]: true,
        [props.className ?? ""]: true,
        [`button-${props.size}`]: true,
        disabled: props.disabled,
        loading: props.loading,
        [`${props.variant}`]: true,
      })}
      justify="center"
    >
      <StyledDiv reverseIcon={props.reverseIcon}>
        <span className="icon">{renderIcon()}</span>
        <span
          className={clsx({
            text: true,
            alignLeft: renderIcon(),
          })}
        >
          {props.children}
        </span>
      </StyledDiv>
    </StyledButton>
  );
});
RoundedButton.defaultProps = defaultProps;

export default RoundedButton;
