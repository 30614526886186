import { Syllabus } from "../../../model/Syllabus";
import {
  TeacherModeActions,
  TeacherModeActionType,
} from "redux/teacher-mode/types/TeacherModeTypes";

export const saveTempSyllabus = (syllabus: Syllabus): TeacherModeActions => ({
  type: TeacherModeActionType.SaveTempSyllabus,
  payload: syllabus,
});

export const deleteTempSyllabus = (id: string): TeacherModeActions => ({
  type: TeacherModeActionType.DeleteTempSyllabus,
  payload: id,
});

export const deleteAllSylabi = (school: string): TeacherModeActions => ({
  type: TeacherModeActionType.DeleteAllSyllabi,
  payload: undefined,
});
