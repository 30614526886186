import React from "react";
import { useParams } from "react-router-dom";
import clsx from "clsx";
import ProfileComplete from "../ProfileComplete";
import { Box, Card, Text } from "grommet";
import styled from "styled-components";

type RouteParams = {
  pageId: string;
};

const Hero = styled(Box)`
  width: 100%;
  background: #2196f3;
  height: 200px;
`;

const AnchorLabel = styled(Text)`
  &.label {
    color: #546e7a;
    font-weight: 400;
    font-size: 16px;
  }
  &.active {
    color: black;
  }
`;

const StyledAnchor = styled(Box)`
  &.anchor {
    padding-right: 10px;
    padding-left: 10px;
    border-left: 2px solid transparent;
  },
  &.active {
    border-left: 2px solid #2256F2;
  },
`;

const Container = styled(Box)`
  position: relative;
  padding-top: 20px;
`;

const CardAnchors = styled(Card)`
  height: auto;
  align-items: flex-start;
`;

interface TabPanelProps {
  value: string;
  index: string;
}

const subPages = [
  {
    id: "general",
    href: "/account/general",
    title: "General",
  },
];

const TabPanel: React.FC<TabPanelProps> = (props) => {
  const { children, value, index } = props;

  return (
    <Box as="div" hidden={value !== index}>
      {value === index && children}
    </Box>
  );
};
/**
 * A container for account related components. Currently includes basic info for setting name and selecting school,
 * but also has disabled components for billing and account security in `src/components/account/components`
 */
const Account: React.FC = () => {
  const pageId = useParams<RouteParams>().pageId ?? "general";

  return (
    <>
      <Hero
        gap="small"
        justify="center"
        pad={{ horizontal: "350px" }}
        a11yTitle={"Account Settings Container"}
      >
        <Text
          size="xxlarge"
          color="white"
          textAlign="start"
          a11yTitle="Account Settings"
        >
          Account Settings
        </Text>
        <Text
          size="large"
          color="white"
          a11yTitle="Change account information and settings"
        >
          Change account information and settings
        </Text>
      </Hero>
      <Container direction="row" justify="center">
        <Box pad={{ right: "medium" }} width="250px">
          <CardAnchors a11yTitle="General">
            {subPages.map((item, index) => (
              <StyledAnchor
                a11yTitle={item.title}
                margin={{ vertical: "xsmall", horizontal: "none" }}
                key={index}
                className={clsx({
                  anchor: true,
                  active: pageId === item.id,
                })}
              >
                <AnchorLabel
                  className={clsx({
                    label: true,
                    active: pageId === item.id,
                  })}
                >
                  {item.title}
                </AnchorLabel>
              </StyledAnchor>
            ))}
          </CardAnchors>
        </Box>
        <Box width="large">
          <Card align="left">
            <TabPanel value={pageId} index={"general"}>
              <ProfileComplete hideOrientation={true} disableRole={true} />
            </TabPanel>
          </Card>
        </Box>
      </Container>
    </>
  );
};

export default Account;
