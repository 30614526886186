import { SectionValidator } from "./SectionValidator";
import { CustomSection } from "model/Syllabus";
import Joi from "joi";
import { errorMapper } from "../../../helpers/errorMapper";
import _ from "lodash";

export default class CustomSectionValidator extends SectionValidator {
  validate(section: CustomSection) {
    const schema = Joi.object({
      title: Joi.string().required().min(3),
    }).options({ allowUnknown: true });

    const sectionErrorItem = {
      section: section,
      errors: errorMapper(section, schema),
    };

    return !_.isEmpty(sectionErrorItem.errors) ? sectionErrorItem : undefined;
  }
}
