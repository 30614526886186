import { EditorContent, JSONContent, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import React, { forwardRef, useImperativeHandle } from "react";
import Toolbar from "../Toolbar/Toolbar";
import { Link } from "@tiptap/extension-link";
import { Image } from "@tiptap/extension-image";
import styled from "styled-components";
import FileComponent from "../CustomComponents/FileComponent";
import { Underline } from "@tiptap/extension-underline";
import { Placeholder } from "@tiptap/extension-placeholder";
import { FileResource } from "model/Resource";
import FileButtonContext from "../Editor/FileButtonContext";
import { FileUploaderPlugin } from "./FileUploaderPlugin";
import { HidableComponent } from "components/hoc/Hidable";
import clsx from "clsx";

export interface RichTextEditorRef {
  forceRerender?: (content: JSONContent) => void;
}

interface TipTapRichTextEditorProps {
  uploadButton?: {
    visible: boolean;
    onUploadFile: (file: FileResource) => void;
    disabled?: boolean;
  };
  searchMaterialsButton?: {
    visible: boolean;
    onClick: () => void;
  };
  onChange?: (content: JSONContent) => void;
  id: string;
  value: JSONContent;
  placeholder?: string;
  error?: string;
  label?: string;
  sublabel?: string;
  required?: boolean;
}

const StyledEditorContent = styled(EditorContent)`
  border: 1px solid #e8f0fe;
  box-shadow: inset 0 0.5px 4px rgba(13, 34, 90, 0.15);
  border-radius: 4px;
  max-height: 300px;
  overflow-y: scroll;
  height: 300px;
  margin: 11px 0 0;
  padding: 15px;
  z-index: 5000;

  &.error {
    border: 1px solid #e51144;
  }

  & .ProseMirror:focus {
    outline: none;
  }

  & .ProseMirror p.is-editor-empty:first-child::before {
    color: #adb5bd;
    content: attr(data-placeholder);
    float: left;
    height: 0;
    pointer-events: none;
  }

  & .ProseMirror blockquote {
    background: #eee;
    border-radius: 4px;
    padding: 10px 18px;
    margin: 0;
  }

  & .ProseMirror pre {
    background: #eee;
    border-radius: 4px;
    padding: 10px 18px;
    margin: 8px 0;
  }

  & > div p {
    margin: 0;
  }
`;

const StyledLabel = styled.span`
  display: block;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #474e61;
  margin: 8px 0;

  & .required {
    color: #e51144;
    margin-left: 5px;
  }
`;

const StyledSubLabel = styled.span`
  display: block;
  font-family: Open Sans;
  font-style: normal;
  font-size: 12px;
  line-height: 16px;
  color: #474e61;
  margin: 8px 0;

  & .required {
    color: #e51144;
    margin-left: 5px;
  }
`;

const TextError = styled.span`
  margin: 3px 0;
  font-family: Open Sans;
  font-size: 12px;
  line-height: 16px;

  color: #e51144;

  .text {
    margin-left: 6px;
  }
`;

const RichTextEditor: React.FC<TipTapRichTextEditorProps> = forwardRef(
  (props, ref) => {
    // const onChangeCustom = (content: JSONContent) => {
    //   const editorJson = content;
    //   editorJson.content = editorJson.content!.filter((item) => {
    //     return !(item.type === "file-button" && item.attrs!.loading === true);
    //   });
    //   props.onChange?.(editorJson);
    // };

    const editor = useEditor({
      extensions: [
        StarterKit,
        FileComponent,
        Link,
        Image,
        Underline,
        Placeholder.configure({
          placeholder: props.placeholder,
        }),
        FileUploaderPlugin,
      ],
      editable: true,
      content: props.value,
      onUpdate({ editor }) {
        props.onChange?.(editor?.getJSON());
      },
    });

    useImperativeHandle(ref, () => ({ forceRerender }));

    function forceRerender(content: JSONContent) {
      editor?.commands.setContent(content, true);
    }

    const onDelete = (fileButtonId: string) => {
      const content = editor?.getJSON();
      const filteredContent = content?.content?.filter(
        (item) => item?.attrs?.id !== fileButtonId
      );
      editor?.commands?.setContent(filteredContent!, true);
      props.onChange?.(editor?.getJSON()!);
    };

    return (
      <FileButtonContext
        onDelete={(fileButtonId: string) => onDelete(fileButtonId)}
      >
        {props.label && (
          <StyledLabel>
            {props.label}
            {props.required && <span className="required">*</span>}
          </StyledLabel>
        )}
        {props.sublabel && <StyledSubLabel>{props.sublabel}</StyledSubLabel>}
        <Toolbar
          editor={editor}
          searchMaterialsButton={props.searchMaterialsButton}
          uploadButton={props.uploadButton}
        />
        <StyledEditorContent
          className={clsx({ error: !!props.error })}
          editor={editor}
        />
        <HidableComponent isVisible={!!props.error}>
          <TextError>{props.error}</TextError>
        </HidableComponent>
      </FileButtonContext>
    );
  }
);

export default RichTextEditor;
