import { SectionValidator } from "./SectionValidator";
import { ClassTimes } from "model/Syllabus";
import Joi from "joi";
import { errorMapper } from "../../../helpers/errorMapper";
import _ from "lodash";

export default class OfficeHoursValidator extends SectionValidator {
  validate(section: ClassTimes) {
    const schema = Joi.object({
      officeHours: Joi.string().required(),
    }).options({ allowUnknown: true, abortEarly: false });

    const sectionErrorItem = {
      section: section,
      errors: {
        ...errorMapper(section, schema),
      },
    };

    return !_.isEmpty(sectionErrorItem.errors) ? sectionErrorItem : undefined;
  }
}
