import React from "react";
import { Box } from "grommet";
import styled from "styled-components";
import CustomModal from "components/modals/CustomModal";
import RoundedButton from "../../ui-kit/RoundedButton/RoundedButton";

interface DeleteAssignmentConfirmModalProps {
  opened: boolean;
  onClickConfirm?: () => void;
  onClickCancel?: () => void;
}

const MainText = styled.p`
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 25px;
  text-align: center;
  margin: 4px;
`;

const StyledRoundedButton = styled(RoundedButton)`
  width: 200px;
  padding: 0 !important;
`;

const DeleteAssignmentConfirmModal: React.FC<
  DeleteAssignmentConfirmModalProps
> = (props) => {
  return (
    <CustomModal opened={props.opened} size="small">
      <div>
        <MainText>
          Are you sure you want to <b>delete</b> the assignment and its content?
        </MainText>
        <Box
          margin={{ top: "20px" }}
          align="center"
          gap="medium"
          direction="row"
        >
          <StyledRoundedButton
            type="secondary"
            onClick={() => props.onClickCancel?.()}
          >
            Keep It
          </StyledRoundedButton>

          <StyledRoundedButton
            type="primary"
            variant="red"
            onClick={() => props.onClickConfirm?.()}
          >
            Delete
          </StyledRoundedButton>
        </Box>
      </div>
    </CustomModal>
  );
};

export default DeleteAssignmentConfirmModal;
