import {
  BlackboardActions,
  BlackboardActionType,
} from "../types/BlackboardTypes";

export interface BlackboardState {
  accessToken: string | undefined;
  refreshToken: string | undefined;
  userId: string | undefined;
}

const initialState: BlackboardState = {
  accessToken: undefined,
  refreshToken: undefined,
  userId: undefined,
};

export const BlackboardReducer = (
  state = initialState,
  action: BlackboardActions
): BlackboardState => {
  switch (action.type) {
    case BlackboardActionType.SetTokensBlackboard:
      return {
        ...state,
        accessToken: action.payload.accessToken,
        refreshToken: action.payload.refreshToken,
        userId: action.payload.userId,
      };
    default:
      return state;
  }
};
