import React from "react";
import { Materials } from "../../../model/Syllabus";
import styled from "styled-components";
import Container from "../../viewer/customization/Container";
import H4 from "../../viewer/customization/H4";
import RichTextEditorViewer from "../../ui-kit/RichTextEditor/Viewer/RichTextEditorViewer";
import { createHash } from "../../../helpers/createHash";

interface MaterialsSectionProps {
  section: Materials;
}

const StyledContainer = styled(Container)`
  padding: 4px;
`;

// Materials viewer component
const MaterialsSection: React.FC<MaterialsSectionProps> = (props) => {
  const section = props.section;

  return (
    <>
      <H4 a11yTitle={"Materials Section"}>Material(s)</H4>
      {section.materials.map((material, index) => {
        return (
          <div key={"skill-" + index}>
            <StyledContainer>
              <RichTextEditorViewer
                value={material.body}
                key={createHash(JSON.stringify(material.body))}
              />
            </StyledContainer>
          </div>
        );
      })}
    </>
  );
};

export default MaterialsSection;
