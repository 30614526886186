import React, { useState } from "react";
import { Box, Button, FormField, TextInput as TextInputGrommet } from "grommet";
import styled from "styled-components";
import clsx from "clsx";
import Error from "../../../assets/icons/Error";
import EyeOn from "../../../assets/icons/EyeOn";
import EyeOff from "../../../assets/icons/EyeOff";

interface PasswordInputProps {
  value: string | number;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: () => void;
  a11yTitle?: string;
  placeholder?: string;
  error?: string;
  label?: string;
  disabled?: boolean;
  className?: string;
  autoFocus?: boolean;
}

const StyledFormField = styled(FormField)`
  &.disabled .iconColor {
    color: #9da2ac !important;
  }

  & > input:focus {
    background: none;
    border: none;
  }

  .text-error {
    margin: 3px 0;
    font-family: Open Sans;
    font-size: 12px;
    line-height: 16px;

    color: #e51144;

    .text {
      margin-left: 6px;
    }
  }

  .label {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #474e61;
    margin: 8px 0;

    &.disabled {
      color: #9da2ac;
    }
  }

  .input-container {
    position: relative;
  }

  input {
    font-family: Open Sans;
    font-weight: normal;
    color: #030b1d;
    font-size: 16px;

    &:active,
    &.active,
    &:focus {
      border: none !important;
      outline: none;
      box-shadow: none !important;

      &.disabled {
        border: none !important;
      }
    }

    &::placeholder {
      color: #474e61 !important;
    }

    &.withIcon {
      padding-left: 36px;
    }
  }

  &.disabled {
    color: #9da2ac;
    box-shadow: none;

    &:hover {
      cursor: not-allowed;
    }

    &::placeholder {
      color: #9da2ac !important;
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  & .iconColor {
    color: #0d3af8;
  }
`;

const StyledBox = styled(Box)`
  background: #ffffff;
  border: 1px solid #e8f0fe;
  box-shadow: inset 0 0.5px 4px rgba(13, 34, 90, 0.15);
  border-radius: 4px;
  padding: 0 16px;
  height: 40px;

  &:active,
  &.active,
  &:focus {
    border: 1px solid #0d3af8 !important;
    outline: none;
    box-shadow: none !important;

    &.disabled {
      border: 1px solid #9da2ac !important;
    }
  }

  &:hover {
    border: 1px solid #bfd1f0;
  }

  &.disabled {
    color: #9da2ac;
    border: 1px solid #9da2ac;
    box-shadow: none;

    &:hover {
      cursor: not-allowed;
    }

    &::placeholder {
      color: #9da2ac !important;
    }
  }
  &.error {
    border: 1px solid #e51144 !important;
  }
`;

const PasswordInput: React.FC<PasswordInputProps> = (props) => {
  const [reveal, setReveal] = useState<boolean>(false);

  return (
    <StyledFormField
      disabled={props.disabled}
      className={clsx({ disabled: props.disabled, [props.className!]: true })}
    >
      {props.label && (
        <span
          className={clsx({
            label: true,
            disabled: props.disabled,
          })}
        >
          {props.label}
        </span>
      )}
      <div className="input-container">
        <StyledBox
          direction="row"
          round="small"
          border
          align="center"
          className={clsx({
            disabled: props.disabled,
            error: !!props.error,
          })}
        >
          <TextInputGrommet
            plain
            className={clsx({
              disabled: props.disabled,
            })}
            autoFocus={props.autoFocus}
            disabled={props.disabled}
            placeholder={props.placeholder}
            value={props.value}
            a11yTitle={props.a11yTitle}
            onChange={props.onChange}
            type={reveal ? "text" : "password"}
            focusIndicator={true}
            onFocus={() => props.onFocus?.()}
          />
          <Button
            icon={reveal ? <EyeOn /> : <EyeOff />}
            onClick={() => setReveal(!reveal)}
          />
        </StyledBox>
      </div>
      {props.error && (
        <div className="text-error">
          <Error size="8px" />
          <span className="text">{props.error}</span>
        </div>
      )}
    </StyledFormField>
  );
};

export default PasswordInput;
