import React, { useState } from "react";
import { GradingItem } from "../../../model/Syllabus";
import { TableCell } from "grommet";
import TextInput from "components/ui-kit/TextInput/TextInput";

interface GradingPolicyItemEditorProps {
  item: GradingItem;
  onChange: (item: GradingItem) => void;
}

const GradingPolicyItemEditor: React.FC<GradingPolicyItemEditorProps> = ({
  item,
  onChange,
}) => {
  const [gradingItem, _setGradingItem] = useState({ ...(item as GradingItem) });

  const setGradingItem = (newGradingItem: GradingItem) => {
    onChange(newGradingItem);
    _setGradingItem(newGradingItem);
  };

  return (
    <>
      <TableCell scope="row">
        <TextInput
          value={gradingItem.title}
          placeholder="Describe your grading policy item"
          onChange={(event) => {
            if (event.target.value !== undefined) {
              setGradingItem({
                ...gradingItem,
                title: event.target.value,
              });
            }
          }}
          a11yTitle="Describe your grading policy item"
        />
      </TableCell>
      <TableCell align="right">
        <TextInput
          placeholder="Weight"
          a11yTitle="Define the weight of the item"
          value={gradingItem.weight}
          onChange={(event: any) => {
            if (event.target.value !== undefined) {
              setGradingItem({
                ...gradingItem,
                weight: event.target.value,
              });
            }
          }}
        />
      </TableCell>
    </>
  );
};

export default GradingPolicyItemEditor;
