import { convertFbTimestampToDate } from "../utils/fbUtil";
import * as firebase from "firebase";
import { v4 as uuidv4 } from "uuid";
import { RepositoryError } from "./RepositoryError";
import { SyllabusTemplate } from "../model/SyllabusTemplate";
import { CourseTitle } from "../model/Syllabus";

const firestore = firebase.firestore();

export default class SyllabusTemplateRepository {
  static collectionName = "syllabus_templates";

  async all(
    sourceName: string,
    searchTerm?: string
  ): Promise<{
    SyllabusTemplateList: SyllabusTemplate[];
    lastKey: Date | undefined;
    isLastPage: boolean;
  }> {
    const firestoreResults = await firestore
      .collection(SyllabusTemplateRepository.collectionName)
      .orderBy("syllabus.createdAt")
      .where("source.code", "==", sourceName)
      .get();

    const syllabi = convertFbTimestampToDate(
      firestoreResults.docs.map((doc) => doc.data())
    ) as SyllabusTemplate[];

    const filteredSyllabi = syllabi
      .filter((syllabusTemplate) => {
        const courseTitleSection = syllabusTemplate.syllabus.sections.find(
          (section) => section.type === "CourseTitle"
        ) as CourseTitle;
        return (
          searchTerm === undefined ||
          courseTitleSection.title
            .toUpperCase()
            .includes(searchTerm.toUpperCase()) ||
          courseTitleSection.courseNumber
            .toUpperCase()
            .includes(searchTerm.toUpperCase())
        );
      })
      .slice(0, 11);

    const lastKey =
      filteredSyllabi[filteredSyllabi.length - 2]?.syllabus.createdAt;

    const isLastPage = filteredSyllabi.length < 11;

    return {
      SyllabusTemplateList: filteredSyllabi,
      lastKey: lastKey,
      isLastPage: isLastPage,
    };
  }

  async getNextBatch(
    key: Date,
    sourceName: string,
    searchTerm?: string
  ): Promise<{
    SyllabusTemplateList: SyllabusTemplate[];
    lastKey: Date | undefined;
    isLastPage: boolean;
  }> {
    const firestoreResults = await firestore
      .collection(SyllabusTemplateRepository.collectionName)
      .orderBy("syllabus.createdAt")
      .where("source.code", "==", sourceName)
      .startAfter(key)
      .get();

    const syllabi = convertFbTimestampToDate(
      firestoreResults.docs.map((doc) => doc.data())
    ) as SyllabusTemplate[];

    const filteredSyllabi = syllabi
      .filter((syllabusTemplate) => {
        const courseTitleSection = syllabusTemplate.syllabus.sections.find(
          (section) => section.type === "CourseTitle"
        ) as CourseTitle;
        return (
          searchTerm === undefined ||
          courseTitleSection.title
            .toUpperCase()
            .includes(searchTerm.toUpperCase()) ||
          courseTitleSection.courseNumber
            .toUpperCase()
            .includes(searchTerm.toUpperCase())
        );
      })
      .slice(0, 11);

    const lastKey =
      filteredSyllabi[filteredSyllabi.length - 2]?.syllabus.createdAt;

    const isLastPage = filteredSyllabi.length < 11;

    return {
      SyllabusTemplateList: filteredSyllabi,
      lastKey: lastKey,
      isLastPage: isLastPage,
    };
  }

  async save(id: string, syllabusTemplate: SyllabusTemplate) {
    try {
      await firestore
        .collection(SyllabusTemplateRepository.collectionName)
        .doc(id)
        .update(syllabusTemplate);
    } catch (e) {
      const id = uuidv4();
      const callableCreate = firebase.functions().httpsCallable("saveLog");
      callableCreate({
        message: e.message,
        payload: syllabusTemplate,
        id: id,
      });
      throw new RepositoryError(e.message, id);
    }
  }

  async create(syllabusTemplate: SyllabusTemplate): Promise<string> {
    const docRef = await firestore
      .collection(SyllabusTemplateRepository.collectionName)
      .add({
        ...syllabusTemplate,
        syllabus: {
          ...syllabusTemplate.syllabus,
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        },
      });
    const doc = await docRef.get();
    return doc.id;
  }
}
