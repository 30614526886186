import React from "react";
import { Blank, IconProps } from "grommet-icons";
import { ChildrenIconProps } from "./FileTypeIcon";

const OER: React.FC<IconProps & ChildrenIconProps> = (props) => {
  return (
    <Blank viewBox="0 0 24 24" {...props} color="plain">
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 24 24"
        xmlSpace="preserve"
      >
        <g>
          <path
            fill="currentColor"
            d="M8,20H7c-0.6,0-1-0.4-1-1V4c0-0.6,0.4-1,1-1l5,0v3c0,1.7,1.3,3,3,3h3v4.9c0,0.6,0.4,1,1,1s1-0.4,1-1V7.1
		c0-0.5-0.2-1-0.6-1.4l-4.1-4.1C14.9,1.2,14.4,1,13.9,1H7C5.3,1,4,2.3,4,4v15c0,1.7,1.3,3,3,3h1c0.6,0,1-0.4,1-1S8.6,20,8,20z
		 M17.9,7H15c-0.6,0-1-0.4-1-1V3.1L17.9,7z"
          />
          <path
            fill="currentColor"
            d="M18.5,16h-1c-0.6,0-1,0.4-1,1s0.4,1,1,1h1c0.8,0,1.5,0.7,1.5,1.5S19.3,21,18.5,21h-1c-0.6,0-1,0.4-1,1
		s0.4,1,1,1h1c1.9,0,3.5-1.6,3.5-3.5S20.4,16,18.5,16z"
          />
          <path
            fill="currentColor"
            d="M14.5,21h-1c-0.8,0-1.5-0.7-1.5-1.5s0.7-1.5,1.5-1.5h1c0.6,0,1-0.4,1-1s-0.4-1-1-1h-1c-1.9,0-3.5,1.6-3.5,3.5
		s1.6,3.5,3.5,3.5h1c0.6,0,1-0.4,1-1S15.1,21,14.5,21z"
          />
          <path
            fill="currentColor"
            d="M14,19.5c0,0.4,0.3,0.8,0.8,0.8h2.5c0.4,0,0.8-0.3,0.8-0.8s-0.3-0.8-0.8-0.8h-2.5C14.3,18.8,14,19.1,14,19.5z"
          />
        </g>
      </svg>
    </Blank>
  );
};
export default OER;
