import {
  SavedPasswordsActions,
  SavedPasswordsType,
} from "../types/SavedPasswordsTypes";

export const addPassword = (
  syllabusId: string,
  password: string
): SavedPasswordsActions => ({
  type: SavedPasswordsType.AddPasword,
  payload: {
    syllabusId,
    password,
  },
});
