import { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import { addSnackbarMessage } from "../redux/common/actions/CommonActions";
import { useAppDispatch } from "../redux/configureStore";

const initBeforeUnLoad = (showExitPrompt: boolean, dispatch: any) => {
  window.onbeforeunload = (event: any) => {
    if (showExitPrompt) {
      const e = event || window.event;
      e.preventDefault();
      const id = uuidv4();
      dispatch(
        addSnackbarMessage({
          id: id,
          message: "Please, wait until your files are uploaded.",
          type: "critical",
        })
      );
      if (e) {
        e.returnValue = "";
      }
      return "";
    }
  };
};

export default function useExitPrompt(isLoading: boolean) {
  const dispatch = useAppDispatch();
  const [showExitPrompt, setShowExitPrompt] = useState(isLoading);

  window.onload = function () {
    initBeforeUnLoad(showExitPrompt, dispatch);
  };

  useEffect(() => {
    initBeforeUnLoad(showExitPrompt, dispatch);
  }, [showExitPrompt, dispatch]);

  useEffect(() => {
    setShowExitPrompt(isLoading);
  }, [isLoading]);

  return showExitPrompt;
}
