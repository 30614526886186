import { Syllabus } from "../model/Syllabus";
import { convertFbTimestampToDate } from "../utils/fbUtil";
import * as firebase from "firebase";
import { v4 as uuidv4 } from "uuid";
import { RepositoryError } from "./RepositoryError";

const firestore = firebase.firestore();

class SyllabusRepository {
  static collectionName = "syllabi";

  async findById(id: string) {
    const syllabus = await firestore
      .collection(SyllabusRepository.collectionName)
      .doc(id)
      .get();
    console.log({ SYLLABUS: syllabus });
    const result = convertFbTimestampToDate(syllabus.data()) as Syllabus;
    result.firebaseId = id;
    return result;
  }

  async findManyBiIds(ids: string[]) {
    if (ids === undefined || ids.length === 0) return [];
    const syllabi = await firestore
      .collection(SyllabusRepository.collectionName)
      .where("firebaseId", "in", ids)
      .get();
    const result = syllabi.docs.map(
      (s) => convertFbTimestampToDate(s.data()) as Syllabus
    );
    return result;
  }

  async findByEnrollmentCode(id: string) {
    const syllabus = await firestore
      .collection(SyllabusRepository.collectionName)
      .where("enrollmentCode", "==", id)
      .get();
    const result = convertFbTimestampToDate(
      syllabus.docs[0]?.data()
    ) as Syllabus;
    return result;
  }

  async save(id: string, syllabus: Syllabus) {
    try {
      await firestore.collection("syllabi").doc(id).update(syllabus);
    } catch (e) {
      const id = uuidv4();
      const callableCreate = firebase.functions().httpsCallable("saveLog");
      callableCreate({
        message: e.message,
        payload: syllabus,
        id: id,
      });
      throw new RepositoryError(e.message, id);
    }
  }

  async create(syllabus: Syllabus): Promise<string> {
    const docRef = await firestore.collection("syllabi").add({
      ...syllabus,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    });
    const doc = await docRef.get();
    window.analytics.track("Successfully created a Syllabus", {
      syllabusId: doc.id,
    });

    return doc.id;
  }
}

export default SyllabusRepository;
