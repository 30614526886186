import React from "react";
import styled from "styled-components";

import Button from "./Button";
import Select from "./Select";

import BulletList from "../../../../assets/icons/BulletList";
import OrderedList from "../../../../assets/icons/OrderedList";
import Link from "../../../../assets/icons/Link";
import ClearStyle from "../../../../assets/icons/ClearStyle";

import type { Editor } from "@tiptap/react";
import Search from "assets/icons/Search";
import { FileResource } from "model/Resource";
import SquaredButton from "components/ui-kit/SquaredButton/SquaredButton";
import FileUpload from "components/file-upload/FileUpload";

interface ToolbarProps {
  editor: Editor | null;
  uploadButton?: {
    visible: boolean;
    onUploadFile: (file: FileResource) => void;
    disabled?: boolean;
  };
  searchMaterialsButton?: {
    visible: boolean;
    onClick: () => void;
  };
}

const StyledToolbarWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledSearchForMaterialButton = styled(SquaredButton)`
  width: 200px;
  padding: 0 !important;
  margin: 0 3px;
`;

const StyledOptionsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledButtonsWrapper = styled.div`
  display: flex;
`;

const StyledBoldIcon = styled.div`
  font-weight: 700;
`;

const StyledItalicIcon = styled.div`
  font-style: italic;
`;

const StyledStrikeIcon = styled.div`
  text-decoration-line: line-through;
`;

const StyledUnderlineIcon = styled.div`
  text-decoration-line: underline;
`;

const StyledLinkIcon = styled(Link)``;

const StyledBulletListIcon = styled(BulletList)`
  transform: scale(0.9);
  padding-top: 6px;
`;

const StyledOrderedListIcon = styled(OrderedList)`
  transform: scale(0.8);
`;

const StyledClearStyleIcon = styled(ClearStyle)`
  transform: scale(1.3);
`;

const Toolbar: React.FC<ToolbarProps> = (props) => {
  if (!props.editor) return null;
  return (
    <StyledToolbarWrapper>
      <StyledOptionsWrapper>
        <Select editor={props.editor} />
        <Button
          onClick={() => props.editor?.chain().focus().toggleBold().run()}
          className={props.editor.isActive("bold") ? "is-active" : ""}
          a11yTitle="Bold"
          label={<StyledBoldIcon>B</StyledBoldIcon>}
        />
        <Button
          onClick={() => props.editor?.chain().focus().toggleItalic().run()}
          className={props.editor.isActive("italic") ? "is-active" : ""}
          a11yTitle="Italic"
          label={<StyledItalicIcon>I</StyledItalicIcon>}
        />
        <Button
          onClick={() => props.editor?.chain().focus().toggleStrike().run()}
          className={props.editor.isActive("strike") ? "is-active" : ""}
          a11yTitle="Strike"
          label={<StyledStrikeIcon>S</StyledStrikeIcon>}
        />
        <Button
          onClick={() => props.editor?.chain().focus().toggleUnderline().run()}
          className={props.editor.isActive("underline") ? "is-active" : ""}
          a11yTitle="Underline"
          label={<StyledUnderlineIcon>U</StyledUnderlineIcon>}
        />
        <Button
          onClick={() => {
            let link = prompt("Enter the link:");
            let text = link === null || link === "" ? "" : link;
            props.editor
              ?.chain()
              .focus()
              .extendMarkRange("link")
              .setLink({ href: text, target: "_blank" })
              .run();
          }}
          className={props.editor.isActive("link") ? "is-active" : ""}
          a11yTitle="Link"
          label={<StyledLinkIcon />}
        />
        <Button
          onClick={() => props.editor?.chain().focus().toggleBulletList().run()}
          className={props.editor.isActive("bulletList") ? "is-active" : ""}
          a11yTitle="Bullet List"
          label={<StyledBulletListIcon />}
        />
        <Button
          onClick={() =>
            props.editor?.chain().focus().toggleOrderedList().run()
          }
          className={props.editor.isActive("orderedList") ? "is-active" : ""}
          a11yTitle="Order List"
          label={<StyledOrderedListIcon />}
        />
        <Button
          onClick={() => props.editor?.chain().focus().unsetAllMarks().run()}
          className={""}
          a11yTitle="Clear"
          label={<StyledClearStyleIcon />}
        />
      </StyledOptionsWrapper>
      <StyledButtonsWrapper>
        {props.searchMaterialsButton?.visible && (
          <StyledSearchForMaterialButton
            type="primary"
            variant="dark-blue"
            size="small"
            onClick={() => props.searchMaterialsButton?.onClick()}
            icon={<Search />}
          >
            Search for Materials
          </StyledSearchForMaterialButton>
        )}
        {props.uploadButton?.visible && (
          <FileUpload
            buttonText="Upload"
            onUploadFile={props.uploadButton?.onUploadFile}
            disabled={props.uploadButton?.disabled}
          />
        )}
      </StyledButtonsWrapper>
    </StyledToolbarWrapper>
  );
};

export default Toolbar;
