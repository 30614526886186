import React, { useState } from "react";
import styled from "styled-components";
import Title from "../../ui-kit/OneWeekPlanTitle/Title";
import StepBar from "../StepBar/StepBar";
import RoundedButton from "../../ui-kit/RoundedButton/RoundedButton";
import { Box, TextInput, Text } from "grommet";

interface CourseInfoEditorProps {
  onClickContinue: (title: string, instructor: string, school: string) => void;
  onChange?: () => void;
  title: string;
  instructor: string;
  school: string;
}

const Content = styled(Box)`
  width: 100%;
  height: 100%;

  & .buttons {
    padding-top: 20px;
    text-align: center;
  }
`;

const StyledTitle = styled(Title)`
  padding: 0 0 42px 0;
`;

const CourseInfoEditor: React.FC<CourseInfoEditorProps> = (props) => {
  const [courseName, setCourseName] = useState(props.title);
  const [school, setSchool] = useState(props.school);
  const [instructor, setInstructor] = useState(props.instructor);

  return (
    <Content direction="column" justify="between">
      <Box direction="column">
        <StepBar percentage={20} text="1 out of 5" />
        <StyledTitle text="What course are you planning?" />
        <Box margin={"small"}>
          <Text margin={{ bottom: "xsmall" }}>Course Name</Text>
          <TextInput
            placeholder="ex. Intro to BlendEd"
            value={courseName}
            onChange={(e) => {
              setCourseName(e.target.value);
            }}
            a11yTitle="Course Name"
          />
        </Box>
        <Box margin={"small"}>
          <Text margin={{ bottom: "xsmall" }}>Instructor Name</Text>
          <TextInput
            placeholder="ex. David Boone"
            a11yTitle="Insructor Name"
            value={instructor}
            onChange={(e) => {
              setInstructor(e.target.value);
            }}
          />
        </Box>
        <Box margin={"small"}>
          <Text margin={{ bottom: "xsmall" }}>School</Text>
          <TextInput
            placeholder="ex. BlendEd U"
            a11yTitle="School"
            value={school}
            onChange={(e) => {
              setSchool(e.target.value);
            }}
          />
        </Box>
      </Box>
      <div className="buttons">
        <RoundedButton
          type="primary"
          variant="blue"
          onClick={() => props.onClickContinue(courseName, instructor, school)}
        >
          Continue
        </RoundedButton>
      </div>
    </Content>
  );
};

export default CourseInfoEditor;
