import React from "react";
import { Blank, IconProps } from "grommet-icons";
import { IconColors } from "../../theme/GlobalTheme";
import { ChildrenIconProps } from "./FileTypeIcon";

const WAV: React.FC<IconProps & ChildrenIconProps> = (props) => {
  return (
    <Blank viewBox="0 0 39 42" {...props} color="plain">
      <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
      >
        <path
          fill="white"
          d="M14.83.61H8a2.29,2.29,0,0,0-1.62.67,2.32,2.32,0,0,0-.66,1.62V21.19A2.29,2.29,0,0,0,8,23.48H20.56a2.29,2.29,0,0,0,2.28-2.29V8.61Z"
        />
        <path
          fill={
            props.disabled
              ? IconColors["icon-disabled"]
              : IconColors["icon-dark-blue"]
          }
          d="M14.59,1.19l7.67,7.66V21.18a1.72,1.72,0,0,1-1.71,1.72H8a1.71,1.71,0,0,1-1.71-1.71V2.9A1.71,1.71,0,0,1,8,1.19h6.62m.24-.58H8a2.29,2.29,0,0,0-1.62.67,2.32,2.32,0,0,0-.66,1.62V21.19A2.29,2.29,0,0,0,8,23.48H20.56a2.29,2.29,0,0,0,2.28-2.29V8.61l-8-8Z"
        />
        <path
          fill={
            props.disabled
              ? IconColors["icon-disabled"]
              : IconColors["icon-light-blue"]
          }
          d="M14.83.9V6.33a2.29,2.29,0,0,0,2.29,2.28h5.43Z"
        />
        <path
          fill={
            props.disabled
              ? IconColors["icon-disabled"]
              : IconColors["icon-dark-blue"]
          }
          d="M22.55,8.89H17.12a2.58,2.58,0,0,1-2.58-2.57V.9a.27.27,0,0,1,.18-.26A.28.28,0,0,1,15,.7l7.72,7.71a.29.29,0,0,1,.06.31A.29.29,0,0,1,22.55,8.89Zm-7.44-7.3V6.33a2,2,0,0,0,2,2h4.74Z"
        />
        <rect
          fill={
            props.disabled
              ? IconColors["icon-disabled"]
              : IconColors["icon-blue"]
          }
          x="1.12"
          y="11.19"
          width="20"
          height="9.14"
          rx="0.86"
        />
        <path
          fill={
            props.disabled
              ? IconColors["icon-disabled"]
              : IconColors["icon-dark-blue"]
          }
          d="M20.26,11.75a.29.29,0,0,1,.29.29v7.43a.28.28,0,0,1-.28.28H2a.27.27,0,0,1-.28-.28V12.05A.3.3,0,0,1,2,11.76H20.26m0-.57H2a.86.86,0,0,0-.86.86v7.43a.86.86,0,0,0,.85.86l18.29,0a.86.86,0,0,0,.86-.85V12a.85.85,0,0,0-.86-.86Z"
        />
        <path
          fill="white"
          d="M9.65,13.51,8.37,18.1H7.29l-.86-3.27-.9,3.27H4.45l-1.24-4.6h1L5,17.08l.93-3.57H7l.89,3.54.81-3.54Z"
        />
        <path
          fill="white"
          d="M13.07,17.22H11.24l-.3.88H10l1.65-4.6h1.07l1.65,4.59h-1Zm-.25-.73-.67-1.92-.66,1.92Z"
        />
        <path
          fill="white"
          d="M19.08,13.5,17.4,18.09H16.28l-1.69-4.58h1l1.26,3.64L18.1,13.5Z"
        />
      </svg>
    </Blank>
  );
};
export default WAV;
