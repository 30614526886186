import {
  SavedPasswordsActions,
  SavedPasswordsType,
} from "../types/SavedPasswordsTypes";

interface SavedPassword {
  syllabusId: string;
  password: string;
}

export interface SavedPasswordsState {
  passwords: SavedPassword[];
}

const initialState: SavedPasswordsState = {
  passwords: [],
};

export const SavedPasswordsReducer = (
  state = initialState,
  action: SavedPasswordsActions
): SavedPasswordsState => {
  switch (action.type) {
    case SavedPasswordsType.AddPasword:
      return {
        ...state,
        passwords: [
          ...state.passwords.filter(
            (password) => password.syllabusId !== action.payload.syllabusId
          ),
          action.payload,
        ],
      };
    default:
      return state;
  }
};
