import { googleBooksConfig } from "../../config/config";
import axios from "axios";
import { Book } from "./VolumeResponse";
import { GoogleBookResource } from "../../model/Resource";
import { Result } from "../../utils/Result";

export default class GoogleBookSDK {
  async search(query: string): Promise<Result<GoogleBookResource[]>> {
    try {
      const res = await axios.get(
        `https://www.googleapis.com/books/v1/volumes?q=${query}&key=${googleBooksConfig.key}`
      );
      const volumes = (await res.data.items) as Book[];
      return Result.ok(
        volumes.map(
          (volume) =>
            ({
              id: volume.id,
              title: volume.volumeInfo.title,
              url: volume.volumeInfo.infoLink,
              type: "GoogleBook",
              image: volume.volumeInfo.imageLinks?.smallThumbnail,
              volumeInfo: {
                authors: volume.volumeInfo.authors || [],
                publishedDate:
                  volume.volumeInfo.publishedDate || "Unknown Published Date",
                publisher: volume.volumeInfo.publisher || "Unknown Publisher",
              },
              selfLink: volume.selfLink,
            } as GoogleBookResource)
        )
      );
    } catch (e) {
      return Result.fail(e.message);
    }
  }
}
