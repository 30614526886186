import React from "react";
import { Blank, IconProps } from "grommet-icons";
import { ChildrenIconProps } from "./FileTypeIcon";
import { IconColors } from "../../theme/GlobalTheme";

const MP3: React.FC<IconProps & ChildrenIconProps> = (props) => {
  return (
    <Blank viewBox="0 0 39 42" {...props} color="plain">
      <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
      >
        <path
          fill="white"
          d="M14.77.56H7.92a2.34,2.34,0,0,0-1.62.68,2.26,2.26,0,0,0-.67,1.61V21.14a2.3,2.3,0,0,0,2.29,2.28H20.5a2.29,2.29,0,0,0,2.29-2.28V8.55Z"
        />
        <path
          fill={
            props.disabled
              ? IconColors["icon-disabled"]
              : IconColors["icon-dark-blue"]
          }
          d="M14.54,1.13l7.67,7.66V21.13a1.72,1.72,0,0,1-1.72,1.71H7.93a1.7,1.7,0,0,1-1.21-.5,1.66,1.66,0,0,1-.5-1.21l0-18.29a1.67,1.67,0,0,1,.51-1.21,1.7,1.7,0,0,1,1.21-.5h6.62m.23-.57H7.92a2.34,2.34,0,0,0-1.62.68,2.26,2.26,0,0,0-.67,1.61V21.14a2.3,2.3,0,0,0,2.29,2.28H20.5a2.29,2.29,0,0,0,2.29-2.28V8.55l-8-8Z"
        />
        <path
          fill={
            props.disabled
              ? IconColors["icon-disabled"]
              : IconColors["icon-light-blue"]
          }
          d="M14.77.85V6.27a2.28,2.28,0,0,0,2.28,2.29h5.43Z"
        />
        <path
          fill={
            props.disabled
              ? IconColors["icon-disabled"]
              : IconColors["icon-dark-blue"]
          }
          d="M22.49,8.84H17.06a2.57,2.57,0,0,1-2.57-2.57V.85a.3.3,0,0,1,.17-.27A.31.31,0,0,1,15,.64L22.7,8.35a.29.29,0,0,1-.21.49Zm-7.43-7.3V6.27a2,2,0,0,0,2,2H21.8Z"
        />
        <rect
          fill={
            props.disabled
              ? IconColors["icon-disabled"]
              : IconColors["icon-blue"]
          }
          x="1.07"
          y="11.13"
          width="20"
          height="9.14"
          rx="0.86"
        />
        <path
          fill={
            props.disabled
              ? IconColors["icon-disabled"]
              : IconColors["icon-dark-blue"]
          }
          d="M20.21,11.7a.29.29,0,0,1,.29.29v7.42a.29.29,0,0,1-.29.29H1.93a.29.29,0,0,1-.29-.28V12a.29.29,0,0,1,.28-.29H20.21m0-.57H1.92a.85.85,0,0,0-.85.86v7.43a.86.86,0,0,0,.86.85H20.22a.85.85,0,0,0,.85-.86V12a.86.86,0,0,0-.86-.85Z"
        />
        <path
          fill="white"
          d="M9.6,13.46V18H8.69v-3l-1.22,3h-.7l-1.24-3v3H4.6V13.46H5.65l1.46,3.41,1.45-3.41Z"
        />
        <path
          fill="white"
          d="M13.68,15.56a1.24,1.24,0,0,1-.54.52,2,2,0,0,1-.94.2h-.77V18h-.92V13.46H12.2a2.11,2.11,0,0,1,.91.19,1.3,1.3,0,0,1,.56.5,1.61,1.61,0,0,1,.19.73A1.37,1.37,0,0,1,13.68,15.56Zm-1-.2a.61.61,0,0,0,.19-.49c0-.44-.25-.67-.75-.67h-.73v1.34h.73A.77.77,0,0,0,12.72,15.36Z"
        />
        <path
          fill="white"
          d="M14.88,13.5A1.68,1.68,0,0,1,16,13.14a1.88,1.88,0,0,1,.82.16,1.3,1.3,0,0,1,.52.46,1.25,1.25,0,0,1,.17.64,1.14,1.14,0,0,1-.21.7,1,1,0,0,1-.51.39v0a1,1,0,0,1,.59.42,1.25,1.25,0,0,1,.22.77,1.35,1.35,0,0,1-.18.7,1.26,1.26,0,0,1-.53.49,2,2,0,0,1-.85.17,1.88,1.88,0,0,1-1.21-.37,1.42,1.42,0,0,1-.49-1.11h.89a.76.76,0,0,0,.22.52.8.8,0,0,0,.57.19.7.7,0,0,0,.52-.18.69.69,0,0,0,.18-.49.64.64,0,0,0-.25-.56,1.37,1.37,0,0,0-.78-.17h-.19v-.76h.19c.62,0,.93-.21.93-.62a.57.57,0,0,0-.16-.44.69.69,0,0,0-.49-.16.64.64,0,0,0-.47.17.69.69,0,0,0-.2.42h-.9A1.32,1.32,0,0,1,14.88,13.5Z"
        />
      </svg>
    </Blank>
  );
};

export default MP3;
