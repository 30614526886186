import React from "react";
import { CustomSection } from "../../../model/Syllabus";
import SectionEditorProps from "./SectionEditorProps";
import useDelayedState from "../../../hooks/useDelayedState";
import RichTextEditorWrapper from "../../ui-kit/RichTextEditor/RichTextEditorWrapper";
import TextInput from "components/ui-kit/TextInput/TextInput";
import useNoRenderRef from "../../../hooks/useNoRenderRef";

const CustomSectionEditor: React.FC<SectionEditorProps> = ({
  section,
  onChange,
  error,
}) => {
  const [custom, setCustom] = useDelayedState<CustomSection>(
    { ...(section as CustomSection) },
    onChange
  );
  const customRef = useNoRenderRef(custom);

  return (
    <>
      <TextInput
        required={true}
        label="Title"
        value={custom.title as string}
        onChange={(event) => {
          if (event.target.value !== undefined) {
            setCustom({
              ...customRef.current!,
              title: event.target.value,
            });
          }
        }}
        a11yTitle={"Custom Section Title"}
        placeholder={"Title"}
        error={error?.errors.title}
      />
      <div style={{ marginTop: "12px" }}>
        <RichTextEditorWrapper
          placeholder="Details for your custom section"
          value={custom.body}
          onChange={(content) => {
            setCustom({
              ...customRef.current!,
              body: content,
            });
          }}
          id="custom-editor-text-editor"
          label="Edit Custom Section"
        />
      </div>
    </>
  );
};

export default CustomSectionEditor;
