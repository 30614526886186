import React from "react";
import { Spinner } from "grommet";
import styled from "styled-components";

interface LoadingProps {
  text?: string;
  size?: "small" | "medium" | "large";
  color?: string;
  screenReaderMessage?: object;
}

const defaultProps: Partial<LoadingProps> = {
  size: "medium",
  color: "#2256F2",
  screenReaderMessage: { start: "Loading spinner", end: "Loaded" },
  text: "",
};

const Container = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
`;

const Text = styled.span`
  justify-content: center;
  position: fixed;
  padding-top: 100px;
`;

const Loading: React.FC<LoadingProps> = (props) => {
  return (
    <Container>
      <Spinner
        message={props.screenReaderMessage}
        size={props.size}
        color={props.color}
      />
      <Text>{props.text}</Text>
    </Container>
  );
};

Loading.defaultProps = defaultProps;

export default Loading;
