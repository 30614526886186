import React from "react";
import { Blank, IconProps } from "grommet-icons";

const ArrowTop: React.FC<IconProps> = (props) => {
  return (
    <Blank viewBox="0 0 24 24" {...props} color="plain">
      <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
      >
        <path
          d="M6.86496 6H1.13504C0.287204 6 -0.175953 5.01114 0.366819 4.35982L3.23178 0.921865C3.63157 0.442111 4.36843 0.44211 4.76822 0.921865L7.63318 4.35982C8.17595 5.01114 7.7128 6 6.86496 6Z"
          fill="rgba(13, 58, 248, 1)"
        />
      </svg>
    </Blank>
  );
};
export default ArrowTop;
