import { v4 as uuidv4 } from "uuid";

export default class ActivityFactory {
  create(startDate: Date = new Date()) {
    return {
      id: uuidv4(),
      title: "New Activity",
      body: {
        type: "doc",
        content: [
          {
            type: "paragraph",
          },
        ],
      },
      dueDate: startDate,
      resources: [],
    };
  }
}
