import React from "react";
import { Blank, IconProps } from "grommet-icons";
import { IconColors } from "../../theme/GlobalTheme";
import { ChildrenIconProps } from "./FileTypeIcon";

const PDF: React.FC<IconProps & ChildrenIconProps> = (props) => {
  return (
    <Blank viewBox="0 0 39 42" {...props} color="plain">
      <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
      >
        <path
          fill="white"
          d="M14.81.58H8A2.29,2.29,0,0,0,5.67,2.87V21.15A2.29,2.29,0,0,0,8,23.44H20.54a2.29,2.29,0,0,0,1.62-.67,2.32,2.32,0,0,0,.66-1.62V8.57Z"
        />
        <path
          fill={
            props.disabled
              ? IconColors["icon-disabled"]
              : IconColors["icon-dark-blue"]
          }
          d="M14.57,1.15l7.67,7.66V21.14a1.74,1.74,0,0,1-.5,1.22,1.7,1.7,0,0,1-1.21.5H8a1.71,1.71,0,0,1-1.72-1.72V2.87A1.72,1.72,0,0,1,8,1.15h6.62m.24-.57H8A2.29,2.29,0,0,0,5.67,2.87V21.15A2.29,2.29,0,0,0,8,23.44H20.54a2.29,2.29,0,0,0,1.62-.67,2.32,2.32,0,0,0,.66-1.62V8.57l-8-8Z"
        />
        <path
          fill={
            props.disabled
              ? IconColors["icon-disabled"]
              : IconColors["icon-light-blue"]
          }
          d="M14.81.86V6.29A2.3,2.3,0,0,0,17.1,8.58h5.43Z"
        />
        <path
          fill={
            props.disabled
              ? IconColors["icon-disabled"]
              : IconColors["icon-dark-blue"]
          }
          d="M22.53,8.86H17.1a2.57,2.57,0,0,1-2.57-2.57V.86A.28.28,0,0,1,14.7.6.29.29,0,0,1,15,.66l7.72,7.71a.27.27,0,0,1,.06.31A.27.27,0,0,1,22.53,8.86ZM15.1,1.55V6.29a2,2,0,0,0,2,2h4.74Z"
        />
        <rect
          fill={
            props.disabled
              ? IconColors["icon-disabled"]
              : IconColors["icon-blue"]
          }
          x="1.11"
          y="11.15"
          width="20"
          height="9.14"
          rx="0.86"
        />
        <path
          fill={
            props.disabled
              ? IconColors["icon-disabled"]
              : IconColors["icon-dark-blue"]
          }
          d="M20.25,11.72a.28.28,0,0,1,.28.28v7.43a.29.29,0,0,1-.29.29H2a.29.29,0,0,1-.29-.29V12A.29.29,0,0,1,2,11.73H20.25m0-.57H2A.86.86,0,0,0,1.1,12v7.42A.85.85,0,0,0,2,20.3H20.25a.87.87,0,0,0,.86-.86V12a.84.84,0,0,0-.85-.85Z"
        />
        <path
          fill="white"
          d="M8.64,15.58a1.26,1.26,0,0,1-.55.52,1.9,1.9,0,0,1-.94.2H6.38v1.76H5.47V13.48H7.15a2.08,2.08,0,0,1,.91.18,1.21,1.21,0,0,1,.56.51,1.34,1.34,0,0,1,.19.72A1.47,1.47,0,0,1,8.64,15.58Zm-1-.2a.62.62,0,0,0,.18-.48c0-.45-.25-.67-.75-.67H6.38v1.33h.73A.78.78,0,0,0,7.68,15.38Z"
        />
        <path
          fill="white"
          d="M12.33,13.76a1.93,1.93,0,0,1,.85.8,2.59,2.59,0,0,1,0,2.42,2.09,2.09,0,0,1-.84.8,2.74,2.74,0,0,1-1.28.28H9.46V13.47h1.6A2.7,2.7,0,0,1,12.33,13.76Zm-.18,3.12a1.5,1.5,0,0,0,.39-1.11,1.54,1.54,0,0,0-.39-1.12,1.51,1.51,0,0,0-1.12-.4h-.65v3H11A1.51,1.51,0,0,0,12.15,16.88Z"
        />
        <path
          fill="white"
          d="M17,13.47v.74H15.09v1.18h1.47v.73H15.09v1.94h-.92V13.47Z"
        />
      </svg>
    </Blank>
  );
};
export default PDF;
