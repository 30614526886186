import React from "react";
import { Blank, IconProps } from "grommet-icons";
import { ChildrenIconProps } from "./FileTypeIcon";
import { IconColors } from "../../theme/GlobalTheme";

const BMP: React.FC<IconProps & ChildrenIconProps> = (props) => {
  return (
    <Blank viewBox="0 0 128 128" {...props} color="plain">
      <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
      >
        <path
          fill="white"
          d="M14.86.58H8A2.29,2.29,0,0,0,5.72,2.87V21.15a2.33,2.33,0,0,0,.67,1.62A2.29,2.29,0,0,0,8,23.44H20.59a2.29,2.29,0,0,0,2.29-2.29V8.57Z"
        />
        <path
          fill={
            props.disabled
              ? IconColors["icon-disabled"]
              : IconColors["icon-dark-blue"]
          }
          d="M14.63,1.15,22.3,8.81V21.14a1.72,1.72,0,0,1-1.72,1.72H8a1.71,1.71,0,0,1-1.71-1.72l0-18.28A1.72,1.72,0,0,1,8,1.15h6.62m.23-.57H8A2.29,2.29,0,0,0,5.72,2.87V21.15a2.33,2.33,0,0,0,.67,1.62A2.29,2.29,0,0,0,8,23.44H20.59a2.29,2.29,0,0,0,2.29-2.29V8.57l-8-8Z"
        />
        <path
          fill={
            props.disabled
              ? IconColors["icon-disabled"]
              : IconColors["icon-light-blue"]
          }
          d="M14.86.86V6.29a2.29,2.29,0,0,0,2.28,2.29h5.43Z"
        />
        <path
          fill={
            props.disabled
              ? IconColors["icon-disabled"]
              : IconColors["icon-dark-blue"]
          }
          d="M22.58,8.86H17.15a2.58,2.58,0,0,1-2.57-2.57V.86A.29.29,0,0,1,14.75.6a.31.31,0,0,1,.32.06l7.71,7.71a.28.28,0,0,1,.07.31A.29.29,0,0,1,22.58,8.86ZM15.15,1.55V6.29a2,2,0,0,0,2,2h4.74Z"
        />
        <rect
          fill={
            props.disabled
              ? IconColors["icon-disabled"]
              : IconColors["icon-blue"]
          }
          x="1.16"
          y="11.15"
          width="20"
          height="9.14"
          rx="0.86"
        />
        <path
          fill={
            props.disabled
              ? IconColors["icon-disabled"]
              : IconColors["icon-dark-blue"]
          }
          d="M20.3,11.72a.27.27,0,0,1,.28.28v7.43a.29.29,0,0,1-.29.29H2a.29.29,0,0,1-.29-.29V12A.29.29,0,0,1,2,11.73H20.3m0-.57H2a.85.85,0,0,0-.85.86v7.42A.86.86,0,0,0,2,20.3H20.3a.86.86,0,0,0,.86-.86V12a.85.85,0,0,0-.86-.85Z"
        />
        <path
          fill="white"
          d="M7.79,16.1a1.21,1.21,0,0,1,.07,1.37,1.23,1.23,0,0,1-.52.43,1.86,1.86,0,0,1-.81.16h-2V13.48H6.44a1.94,1.94,0,0,1,.8.15,1.16,1.16,0,0,1,.52.42,1.14,1.14,0,0,1,.17.61,1.05,1.05,0,0,1-.21.67,1.09,1.09,0,0,1-.57.38A1.05,1.05,0,0,1,7.79,16.1Zm-2.32-.73h.84a.73.73,0,0,0,.5-.15A.52.52,0,0,0,7,14.8a.52.52,0,0,0-.18-.43.73.73,0,0,0-.5-.15H5.47Zm1.45,1.79a.58.58,0,0,0,.18-.45.56.56,0,0,0-.19-.46.75.75,0,0,0-.53-.17H5.47v1.23H6.4A.82.82,0,0,0,6.92,17.16Z"
        />
        <path
          fill="white"
          d="M13.77,13.47v4.59h-.92v-3l-1.22,3h-.7l-1.24-3v3H8.78V13.47H9.82l1.46,3.41,1.45-3.41Z"
        />
        <path
          fill="white"
          d="M17.85,15.58a1.29,1.29,0,0,1-.54.52,2.15,2.15,0,0,1-.94.2H15.6v1.76h-.92V13.47h1.69a2.11,2.11,0,0,1,.91.18,1.32,1.32,0,0,1,.56.51,1.47,1.47,0,0,1,.19.73A1.42,1.42,0,0,1,17.85,15.58Zm-1-.2a.66.66,0,0,0,.18-.49c0-.45-.25-.67-.75-.67H15.6v1.33h.73A.86.86,0,0,0,16.9,15.38Z"
        />
      </svg>
    </Blank>
  );
};

export default BMP;
