import React from "react";
import { Blank, IconProps } from "grommet-icons";

const Download: React.FC<IconProps> = (props) => {
  return (
    <Blank viewBox="0 0 24 24" {...props} color="plain">
      <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
      >
        <path
          fill="currentColor"
          d="M19,22a3,3,0,0,0,3-3V15a1,1,0,0,0-2,0v4a1,1,0,0,1-1,1H5a1,1,0,0,1-1-1V15a1,1,0,0,0-2,0v4a3,3,0,0,0,3,3Z"
        />
        <path
          fill="currentColor"
          d="M11.29,15.71a1,1,0,0,0,.33.21.94.94,0,0,0,.76,0,1,1,0,0,0,.33-.21l5-5a1,1,0,0,0-1.42-1.42L13,12.59V3a1,1,0,0,0-2,0v9.59L7.71,9.29a1,1,0,0,0-1.42,1.42Z"
        />
      </svg>
    </Blank>
  );
};
export default Download;
