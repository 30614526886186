import {
  MicrosoftTeamsActions,
  MicrosoftTeamsActionType,
} from "../types/MicrosoftTeamsTypes";

export interface MicrosoftTeamsState {
  accessToken: string | undefined;
  refreshToken: string | undefined;
}

const initialState: MicrosoftTeamsState = {
  accessToken: undefined,
  refreshToken: undefined,
};

export const MicrosoftTeamsReducer = (
  state = initialState,
  action: MicrosoftTeamsActions
): MicrosoftTeamsState => {
  switch (action.type) {
    case MicrosoftTeamsActionType.SetTokensMicrosoftTeams:
      return {
        ...state,
        accessToken: action.payload.accessToken,
        refreshToken: action.payload.refreshToken,
      };
    default:
      return state;
  }
};
