export enum CommonActionType {
  SetExport = "SetExport",
}

export interface SetExport {
  type: typeof CommonActionType.SetExport;
  payload: ExportType;
}

export type CommonActions = SetExport;

export enum ExportType {
  NO = "no",
  BLACKBOARD = "blackboard",
  CANVAS = "canvas",
}
