import { WebexActions, WebexActionType } from "../types/WebexTypes";

export interface WebexState {
  accessToken: string | undefined;
  refreshToken: string | undefined;
}

const initialState: WebexState = {
  accessToken: undefined,
  refreshToken: undefined,
};

export const WebexReducer = (
  state = initialState,
  action: WebexActions
): WebexState => {
  switch (action.type) {
    case WebexActionType.SetTokensWebex:
      return {
        ...state,
        accessToken: action.payload.accessToken,
        refreshToken: action.payload.refreshToken,
      };
    default:
      return state;
  }
};
