import React from "react";
import { Box } from "grommet";
import ConfirmDialog from "./ConfirmDialog";

interface DeleteSectionDialogProps {
  onClickCancel?: () => void;
  onClickAction?: () => void;
  handleClose?: () => void;
  isOpen: boolean;
  disableButtons?: boolean;
}

const DeleteSyllabusConfirmModal: React.FC<DeleteSectionDialogProps> = (
  props
) => {
  return (
    <ConfirmDialog
      isOpen={props.isOpen}
      titleLabel={
        <Box>
          Are you sure you want to <b>delete</b>
          this syllabus?
        </Box>
      }
      confirmButtonProps={{
        label: "Delete",
        type: "primary",
        variant: "red",
        onClick: () => props.onClickAction?.(),
      }}
      cancelButtonProps={{
        label: "Keep",
        type: "secondary",
        variant: "blue",
        onClick: () => props.onClickCancel?.(),
      }}
      handleClose={props.handleClose}
      // description={"Saved changes won't be lost"}
      disableButtons={props.disableButtons}
    />
  );
};

export default DeleteSyllabusConfirmModal;
