import React from "react";
import styled from "styled-components";
import clsx from "clsx";

interface BlurrableContentProps {
  locked?: boolean;
  onClickOnBlur?: () => void;
}

const StyledDiv = styled.div`
  &.locked {
    filter: blur(6px);
    user-select: none;

    & :hover {
      cursor: pointer;
    }

    & a {
      display: none;
    }
  }
`;

const BlurrableContent: React.FC<BlurrableContentProps> = (props) => {
  return (
    <StyledDiv
      className={clsx({ locked: props.locked })}
      onClick={() => props.locked && props.onClickOnBlur?.()}
    >
      {props.children}
    </StyledDiv>
  );
};

export default BlurrableContent;
