import React, { useState } from "react";
import {
  addMoreModulesToSchedule,
  Schedule,
  Section,
} from "../../../../model/Syllabus";
import "date-fns";
import { ScheduleBuilder } from "./ScheduleBuilder";
import ScheduleModuleFactory from "../../../../model/ScheduleModuleFactory";
import _ from "lodash";
import {
  addDays,
  addWeeks,
  differenceInDays,
  endOfWeek,
  getDay,
  startOfWeek,
} from "date-fns";

interface ScheduleEditorProps {
  section: Section;
  onChange: (section: Section) => void;
  index: number;
  needsRegistration?: boolean;
}

const ScheduleEditor: React.FC<ScheduleEditorProps> = ({
  section,
  onChange,
  // onScheduleGenerated,
}) => {
  const [_section, _setSection] = useState({ ...(section as Schedule) });

  const schedule = section as Schedule;

  const setSection = (newSection: Schedule) => {
    onChange(newSection);
    _setSection(newSection);
  };

  const onGenerateSchedule = (start: Date, end: Date) => {
    const moduleFactory = new ScheduleModuleFactory();
    const newSection: Schedule = {
      ..._section,
      start_at: start,
      end_at: end,
      modules: moduleFactory.createMany(start, end),
    };
    setSection(newSection);
  };

  return (
    <>
      <ScheduleBuilder
        onGenerate={onGenerateSchedule}
        startAt={(section as Schedule).start_at}
        endAt={(section as Schedule).end_at}
        currentModules={(section as Schedule).modules.length}
        onEditDates={(startDate, endDate, newCourseLength) => {
          const newSchedule = _.cloneDeep(schedule);
          const modulesToAdd = newCourseLength - schedule.modules.length;

          newSchedule.start_at =
            getDay(startDate) === 6 // If the day is sunday I don't want to move to the start of week because I'm already there. If we remove this conditional the system moves to the previous week.
              ? startDate
              : startOfWeek(startDate, { weekStartsOn: 0 });
          newSchedule.end_at = endOfWeek(endDate);

          newSchedule.modules = newSchedule.modules.map((module, index) => {
            const newStartDateOfModule = addWeeks(newSchedule.start_at, index);
            const newEndDateOfModule = endOfWeek(newStartDateOfModule);

            module.activities = module.activities.map((activity) => {
              const distanceInDays = differenceInDays(
                activity.dueDate,
                module.start_at
              );

              activity.dueDate = addDays(newStartDateOfModule, distanceInDays);

              return activity;
            });

            module.start_at = newStartDateOfModule;
            module.end_at = newEndDateOfModule;

            return module;
          });

          const lastModule = _.last(newSchedule.modules)!;
          const newSection = addMoreModulesToSchedule(
            newSchedule,
            lastModule.end_at,
            modulesToAdd
          ) as Schedule;

          setSection(newSection);
        }}
        mode={(section as Schedule).modules.length === 0 ? "creator" : "editor"}
      />
    </>
  );
};

export default ScheduleEditor;
