import React from "react";
import { Blank, IconProps } from "grommet-icons";

const Sortable: React.FC<IconProps> = (props) => {
  return (
    <Blank viewBox="0 0 24 24" {...props} color="plain">
      <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
      >
        <path
          d="M7.86496 6H2.13504C1.2872 6 0.824047 5.01114 1.36682 4.35982L4.23178 0.921865C4.63157 0.442111 5.36843 0.44211 5.76822 0.921865L8.63318 4.35982C9.17595 5.01114 8.7128 6 7.86496 6Z"
          fill="#A5AEC1"
        />
        <path
          d="M2.13504 11L7.86496 11C8.7128 11 9.17595 11.9889 8.63318 12.6402L5.76822 16.0781C5.36843 16.5579 4.63157 16.5579 4.23178 16.0781L1.36682 12.6402C0.824048 11.9889 1.2872 11 2.13504 11Z"
          fill="#A5AEC1"
        />
      </svg>
    </Blank>
  );
};
export default Sortable;
