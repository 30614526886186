import React, { useContext } from "react";
import { Section, SyllabusComponent } from "../../model/Syllabus";
import { SubPageType } from "./carousel/EditorSlide";
import SectionFooter from "./SectionFooter";
import AddMaterialPageFooter from "./AddMaterialPageFooter";
import styled from "styled-components";
import { EditorSlideContext } from "./carousel/EditorSlideContextProvider";

interface EditorSlideFooterProps {
  onDeleteComponent?: (component: SyllabusComponent) => void;
  selectedSection: Section;
  sections: Section[];
  disableButtons?: boolean;
  onSaveAll: () => void;
  subPageSelected?: SubPageType;
  onClearSelection?: () => void;
  onAddSelection?: () => void;
  onCancelAndClose?: () => void;
}

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 10;
  background: #ffffff;
  box-shadow: 0px -4px 10px rgba(7, 26, 67, 0.1);
  height: 70px;
  padding: 0 30px;
`;

const EditorSlideFooter: React.FC<EditorSlideFooterProps> = (props) => {
  const { resourcesToBeAdded } = useContext(EditorSlideContext);

  const renderFooter = () => {
    if (props.subPageSelected === "activity") {
      return (
        <AddMaterialPageFooter
          onClearSelection={props.onClearSelection}
          onAddSelection={props.onAddSelection}
          quantityOfResources={resourcesToBeAdded.length}
        />
      );
    }
    return (
      <SectionFooter
        selectedSection={props.selectedSection}
        sections={props.sections}
        onSaveAll={props.onSaveAll}
        onDeleteComponent={props.onDeleteComponent}
        disableButtons={props.disableButtons}
        onCancelAndClose={props.onCancelAndClose}
      />
    );
  };
  return <Footer>{renderFooter()}</Footer>;
};

export default EditorSlideFooter;
