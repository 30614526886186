import React from "react";
import { Blank, IconProps } from "grommet-icons";

const ArrowNext: React.FC<IconProps> = (props) => {
  return (
    <Blank viewBox="0 0 24 24" {...props} color="plain">
      <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
      >
        <path
          fill="currentColor"
          d="M11.89,6.29a1,1,0,0,0,0,1.42L15.19,11H6a1,1,0,0,0,0,2h9.19l-3.3,3.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L19,12l-5.7-5.71A1,1,0,0,0,11.89,6.29Z"
        />
      </svg>
    </Blank>
  );
};
export default ArrowNext;
