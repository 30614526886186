import clsx from "clsx";
import React from "react";
import styled from "styled-components";

interface AuxiliarPixelProps {
  visible?: boolean;
}

const StyledAnchor = styled.a`
  height: 4px;
  width: 4px;
  position: absolute;
  z-index: 9999;

  &.visible {
    background: #000000;
  }

  &.invisible {
    background: #ffffff;
  }
`;

const AuxiliarPixel: React.FC<AuxiliarPixelProps> = (props) => {
  return (
    <StyledAnchor
      className={clsx({
        visible: props.visible,
        invisible: !props.visible,
      })}
      href="#"
    />
  );
};

export default AuxiliarPixel;
