import React from "react";
import useDelayedState from "../../../hooks/useDelayedState";
import RichTextEditorWrapper from "../../ui-kit/RichTextEditor/RichTextEditorWrapper";
import { JSONContent } from "@tiptap/react";
import { SectionErrorItem } from "model/SyllabusValidator/SyllabusErrorBag";

interface ObjectivesEditorProps {
  objectives: JSONContent;
  onChange: (objectives: JSONContent) => void;
  error?: SectionErrorItem;
}

const ObjectivesEditor: React.FC<ObjectivesEditorProps> = ({
  objectives,
  onChange,
  error,
}) => {
  const [_objectives, _setObjectives] = useDelayedState<JSONContent>(
    objectives,
    onChange
  );
  const onObjectivesChange = (text: JSONContent) => {
    _setObjectives(text);
  };

  return (
    <>
      <RichTextEditorWrapper
        id="objectives-text-editor"
        key="Objectives-objectives"
        placeholder="What do you want students to take away from your course?"
        value={_objectives}
        onChange={(text: JSONContent) => {
          onObjectivesChange(text);
        }}
        error={error?.errors.objectives}
        required={true}
        label="Edit Learning Objectives"
      />
    </>
  );
};

export default ObjectivesEditor;
