import React, { useState } from "react";
import Copyright from "./Copyright";
import ReCAPTCHA from "react-google-recaptcha";
import firebase from "firebase";
import TermsOfUseDialog from "./TermsOfUseDialog";
import { useAppDispatch } from "../redux/configureStore";
import { addSnackbarMessage } from "../redux/common/actions/CommonActions";
import { v4 as uuidv4 } from "uuid";
import { Anchor, Avatar, Box, Button, Text } from "grommet";
import styled from "styled-components";
import SquaredButton from "./ui-kit/SquaredButton/SquaredButton";
import TextInput from "./TextInput";
import { Lock } from "grommet-icons";
import { reflectConfig } from "../config/config";
import CheckBox from "./ui-kit/CheckBox/CheckBox";
import UsersRepository, { Result } from "../repositories/UsersRepository";

const TermsOfUseButton = styled(Button)`
  font-weight: 400;
  font-size: 18px;
  color: #2196f3;
  margin-left: 5px;
  "&:hover": {
    background-color: #f4f6f8;
  }
`;
const Container = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 50px;
`;

const Form = styled.form`
  padding-top: 10px;
  padding-bottom: 10px;
`;

const Registration: React.FC = () => {
  const [email, setEmail] = useState("");
  const [isValidated, setIsValidated] = useState(false);
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState<{
    email?: string;
    password?: string;
  }>({});
  const [isOpenDialog, setIsOpenDialog] = useState<boolean>(false);
  const [checked, setChecked] = useState(false);
  const dispatch = useAppDispatch();

  const handleCheckboxChange = (event: any) => {
    setChecked(event.target.checked);
  };

  const handleSubmit = (e?: any) => {
    e?.preventDefault();
    (async () => {
      if (!checked) {
        dispatch(
          addSnackbarMessage({
            type: "warning",
            message:
              "To continue you must agree to the Terms of Use of our platform.",
            id: uuidv4(),
          })
        );
        return;
      }

      const repository = new UsersRepository();
      const saveUser = (await repository.create(
        email,
        password,
        checked
      )) as Result;
      if (!saveUser.error) {
        window.analytics.identify(saveUser.user, { email: saveUser.email });
        window.analytics.track("Successful signup");
      } else {
        window.analytics.track("Unsuccessful signup", { email: email });
        if (saveUser.error?.message.includes("email")) {
          setErrorMessage({ ...errorMessage, email: saveUser.error?.message });
        } else {
          setErrorMessage({
            ...errorMessage,
            password: saveUser.error?.message,
          });
        }
        setIsOpenDialog(false);
      }
    })();
  };

  const renderTermsAndConditions = (
    <TermsOfUseDialog
      open={isOpenDialog}
      title={"Terms of Use"}
      onClickClose={() => {
        setIsOpenDialog(false);
      }}
      cancelLabel={"Go Back"}
      acceptLabel={"Agreed"}
      onClickAccept={() => {
        setChecked(true);
        setIsOpenDialog(false);
      }}
    />
  );

  return (
    <Container>
      <Box width="medium">
        <Box margin={{ bottom: "medium" }} align="center" gap="xsmall">
          <Avatar size="medium" background="light-2">
            <Lock color="black" />
          </Avatar>
          <Text size="large">Sign Up</Text>
        </Box>
        <Form noValidate>
          <TextInput
            margin={{ vertical: "small" }}
            error={errorMessage.email}
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              setErrorMessage({ ...errorMessage, email: "" });
            }}
            a11yTitle={"Email Address"}
            placeholder={"Email Address"}
          />
          <TextInput
            margin={{ vertical: "small" }}
            a11yTitle={"Password"}
            placeholder={"Password"}
            error={errorMessage.password}
            type="password"
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
              setErrorMessage({ ...errorMessage, password: "" });
            }}
          />
          <CheckBox
            checked={checked}
            onChange={handleCheckboxChange}
            label={
              <Box direction="row">
                {"I agree to"}
                <TermsOfUseButton onClick={() => setIsOpenDialog(true)}>
                  Terms of use
                </TermsOfUseButton>
              </Box>
            }
          />
          {isValidated ||
          ((window as any).isReflectTest &&
            reflectConfig.avoidRecaptchaUsingReflect) ? (
            <Box align="center" pad={{ vertical: "small" }}>
              <SquaredButton type="primary" onClick={() => handleSubmit()}>
                Sign Up
              </SquaredButton>
            </Box>
          ) : (
            <Box align="center" pad={{ vertical: "small" }}>
              <ReCAPTCHA
                sitekey="6LfxqHsbAAAAAO5PuMb4WIQpsMGaSVPakVkdT2H_"
                onChange={async (token) => {
                  const callableValidate = firebase
                    .functions()
                    .httpsCallable("validateRecaptcha");
                  const response = await callableValidate(token);
                  setIsValidated(response.data.success);
                }}
              />
            </Box>
          )}
          <Box pad={{ vertical: "small" }} align="end">
            <Anchor
              href="/login"
              label={"Already have an account? Sign In"}
              color="#2256F2"
              weight="normal"
            />
          </Box>
        </Form>
        <Box margin={{ vertical: "large" }}>
          <Copyright />
        </Box>
        {renderTermsAndConditions}
      </Box>
    </Container>
  );
};

export default Registration;
