import { OpenedResource } from "../../../model/Resource";

export enum SyllabusTypes {
  AddOpenedResource = "AddOpenedResource",
  ClearOpenedResources = "ClearOpenedResources",
}

export interface AddOpenedResourceAction {
  type: typeof SyllabusTypes.AddOpenedResource;
  payload: {
    openedResources: OpenedResource[];
  };
}

export interface ClearOpenedResourcesAction {
  type: typeof SyllabusTypes.ClearOpenedResources;
  payload: undefined;
}

export type SyllabusActions =
  | AddOpenedResourceAction
  | ClearOpenedResourcesAction;
