import React from "react";
import { Blank, IconProps } from "grommet-icons";
import { ChildrenIconProps } from "./FileTypeIcon";

const Close: React.FC<IconProps & ChildrenIconProps> = (props) => {
  return (
    <Blank viewBox="0 0 24 24" {...props} color="plain">
      <svg
        version="1.1"
        id="Layer_1"
        x="0px"
        y="0px"
        viewBox="0 0 24 24"
        xmlSpace="preserve"
      >
        <path
          fill="currentColor"
          d="M12,7c0-0.6-0.4-1-1-1s-1,0.4-1,1v6c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.5,0.5C10.7,14,10.9,14,11,14h6
		c0.6,0,1-0.4,1-1c0-0.6-0.4-1-1-1h-3.6l7.3-7.3c0.4-0.4,0.4-1,0-1.4s-1-0.4-1.4,0L12,10.6V7z"
        />
        <path
          fill="currentColor"
          d="M21,10c-0.6,0-1,0.4-1,1v8c0,0.6-0.4,1-1,1H5c-0.6,0-1-0.4-1-1V5c0-0.6,0.4-1,1-1h8c0.6,0,1-0.4,1-1
		s-0.4-1-1-1H5C3.3,2,2,3.3,2,5v14c0,1.7,1.3,3,3,3h14c1.7,0,3-1.3,3-3v-8C22,10.4,21.6,10,21,10z"
        />
      </svg>
    </Blank>
  );
};

export default Close;
