import React from "react";
import styled from "styled-components";
import { Table } from "grommet";

interface ScheduleTableProps {}

const StyledTable = styled(Table)`
  border-radius: 10px;
  border-style: hidden;
  width: 100%;
`;

const ScheduleTable: React.FC<ScheduleTableProps> = (props) => {
  return <StyledTable>{props.children}</StyledTable>;
};

export default ScheduleTable;
