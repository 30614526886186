import React from "react";
import { Blank, IconProps } from "grommet-icons";
import { IconColors } from "../../theme/GlobalTheme";
import { ChildrenIconProps } from "./FileTypeIcon";

const ZIP: React.FC<IconProps & ChildrenIconProps> = (props) => {
  return (
    <Blank viewBox="0 0 24 24" {...props} color="plain">
      <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
      >
        <path
          fill="white"
          d="M14.83.58H8a2.29,2.29,0,0,0-1.62.67,2.32,2.32,0,0,0-.66,1.62V21.15A2.29,2.29,0,0,0,8,23.44H20.56a2.29,2.29,0,0,0,2.28-2.29V8.57Z"
        />
        <path
          fill={
            props.disabled
              ? IconColors["icon-disabled"]
              : IconColors["icon-dark-blue"]
          }
          d="M14.59,1.15l7.67,7.66V21.14a1.71,1.71,0,0,1-1.71,1.72H8a1.71,1.71,0,0,1-1.71-1.72V2.87A1.72,1.72,0,0,1,8,1.15h6.62m.24-.57H8a2.29,2.29,0,0,0-1.62.67,2.32,2.32,0,0,0-.66,1.62V21.15A2.29,2.29,0,0,0,8,23.44H20.56a2.29,2.29,0,0,0,2.28-2.29V8.57l-8-8Z"
        />
        <path
          fill={
            props.disabled
              ? IconColors["icon-disabled"]
              : IconColors["icon-light-blue"]
          }
          d="M14.83.86V6.29a2.3,2.3,0,0,0,2.29,2.29h5.43Z"
        />
        <path
          fill={
            props.disabled
              ? IconColors["icon-disabled"]
              : IconColors["icon-dark-blue"]
          }
          d="M22.55,8.86H17.12a2.58,2.58,0,0,1-2.58-2.57V.86A.28.28,0,0,1,14.72.6.3.3,0,0,1,15,.66l7.72,7.71a.29.29,0,0,1,.06.31A.28.28,0,0,1,22.55,8.86ZM15.11,1.55V6.29a2,2,0,0,0,2,2h4.74Z"
        />
        <rect
          fill={
            props.disabled
              ? IconColors["icon-disabled"]
              : IconColors["icon-blue"]
          }
          x="1.12"
          y="11.15"
          width="20"
          height="9.14"
          rx="0.86"
        />
        <path
          fill={
            props.disabled
              ? IconColors["icon-disabled"]
              : IconColors["icon-dark-blue"]
          }
          d="M20.26,11.72a.28.28,0,0,1,.29.28v7.43a.28.28,0,0,1-.28.29H2a.28.28,0,0,1-.28-.29V12A.29.29,0,0,1,2,11.73H20.26m0-.57H2a.85.85,0,0,0-.86.86v7.42A.85.85,0,0,0,2,20.3H20.27a.87.87,0,0,0,.86-.86V12a.85.85,0,0,0-.86-.85Z"
        />
        <path
          fill="#FFFFFF"
          d="M7.71,17.29H9.78v.78H6.65v-.71l2-3.1h-2v-.77H9.77v.71Z"
        />
        <path fill="#FFFFFF" d="M11.48,13.47v4.59h-.92V13.47Z" />
        <path
          fill="#FFFFFF"
          d="M15.56,15.58a1.32,1.32,0,0,1-.55.52,2,2,0,0,1-.94.2H13.3v1.76h-.92V13.47h1.69a2.08,2.08,0,0,1,.91.18,1.33,1.33,0,0,1,.56.51,1.37,1.37,0,0,1,.19.73A1.53,1.53,0,0,1,15.56,15.58Zm-1-.2a.66.66,0,0,0,.18-.49c0-.45-.25-.67-.75-.67H13.3v1.33H14A.78.78,0,0,0,14.6,15.38Z"
        />
      </svg>
    </Blank>
  );
};
export default ZIP;
