import React, { useEffect, useState } from "react";
import { match } from "react-router-dom";
import SyllabusViewer from "./SyllabusViewer";
import { Syllabus } from "../../model/Syllabus";
import { useSelector } from "react-redux";
import { AppState } from "../../redux/configureStore";
import { useFirestore } from "react-redux-firebase";
import { convertFbTimestampToDate } from "../../utils/fbUtil";
import { Identifiable } from "utils/Identifiable";
import { appConfig } from "../../config/config";

interface SyllabusIdViewerProps {
  match: match<Identifiable>;
}

const SyllabusIdViewer: React.FC<SyllabusIdViewerProps> = (props) => {
  const firestore = useFirestore();
  const auth = useSelector((state: AppState) => state.firebase.auth);
  const [syllabus, setSyllabus] = useState<Syllabus | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const id = props.match.params.id;

  useEffect(() => {
    window.analytics.identify(auth.uid);
  }, [auth.uid]);

  useEffect(() => {
    (async () => {
      try {
        const result = await firestore.collection("syllabi").doc(id).get();
        const syllabus = convertFbTimestampToDate(result.data()) as Syllabus;
        if (syllabus.version === appConfig.syllabusVersion) {
          setSyllabus(syllabus);
        }
        window.analytics.track("Syllabus Viewer Opened", { syllabusId: id });
        setIsLoading(false);
      } catch (error) {
        window.analytics.track("Syllabus Not Found", { syllabusId: id });
        setIsLoading(false);
      }
    })();
  }, [firestore, id]);

  if (syllabus) {
    return (
      <>
        <SyllabusViewer
          mode={{
            type: "normal",
          }}
          syllabus={syllabus}
        />
      </>
    );
  } else if (isLoading) {
    return <div />;
  } else {
    return <h1>No syllabus found with id {id}</h1>;
  }
};

export default SyllabusIdViewer;
