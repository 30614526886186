import React from "react";
import { Location, LocationKind } from "../../../model/Syllabus";
import SectionEditorProps from "./SectionEditorProps";
import useDelayedState from "../../../hooks/useDelayedState";
import { Box } from "grommet";
import RichTextEditorWrapper from "components/ui-kit/RichTextEditor/RichTextEditorWrapper";
import { JSONContent } from "@tiptap/react";
import Select from "components/ui-kit/Select/Select";
import useNoRenderRef from "../../../hooks/useNoRenderRef";

const LocationEditor: React.FC<SectionEditorProps> = ({
  section,
  onChange,
  error,
}) => {
  const [location, setLocation] = useDelayedState<Location>(
    { ...(section as Location) },
    onChange
  );

  const locationRef = useNoRenderRef(location);

  const onChangeDescription = (description: JSONContent) => {
    setLocation({
      ...locationRef.current!,
      description: description,
    });
  };

  const onChangeKind = (kind: LocationKind) => {
    setLocation({
      ...locationRef.current!,
      kind: kind,
    });
  };

  const locationsList = [
    {
      key: "Online",
      label: "Online",
    },
    { key: "In Person", label: "In Person" },
    { key: "Blend Ed", label: "BlendED" },
  ];

  return (
    <>
      <Box margin={{ vertical: "small" }}>
        <Select
          required={true}
          a11yTitle="Select Kind"
          label="Kind"
          value={{ key: location.kind }}
          onChange={(val) => {
            onChangeKind(val.key as LocationKind);
          }}
          options={locationsList}
          error={error?.errors.kind}
        />
      </Box>
      <RichTextEditorWrapper
        label="Where will students meet?"
        id="location-editor-text-editor"
        key="Location-details"
        value={location.description!}
        onChange={(content) => {
          onChangeDescription(content);
        }}
        placeholder="Where will students meet?"
      />
    </>
  );
};

export default LocationEditor;
