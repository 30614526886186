import React from "react";
import styled from "styled-components";
import { Text } from "grommet";

interface TitleProps {
  text: string;
  className?: string;
}

const StyledTitle = styled(Text)`
  font-family: "Poppins";
  font-weight: 500;
  font-size: 22px;
  text-align: center;
  color: #030b1d;
`;

const Title: React.FC<TitleProps> = (props) => {
  return <StyledTitle className={props.className}>{props.text}</StyledTitle>;
};

export default Title;
