import React from "react";

interface SyllabusEditorContextProps {
  disabledSaveButton: boolean;
  setDisabledSaveButton: (disabled: boolean) => void;
  currentSection?: string;
  currentTab?: number;
  overridePrivateActions?: () => void;
  setShowAuxiliarPixel: (disabled: boolean) => void;
}

export const SyllabusEditorContext =
  React.createContext<SyllabusEditorContextProps>({
    disabledSaveButton: false,
    setDisabledSaveButton: (disabled: boolean) => {},
    currentSection: undefined,
    currentTab: undefined,
    overridePrivateActions: undefined,
    setShowAuxiliarPixel: (show: boolean) => {},
  });

const SyllabusEditorContextProvider: React.FC<SyllabusEditorContextProps> = ({
  children,
  disabledSaveButton,
  setDisabledSaveButton,
  currentSection,
  currentTab,
  overridePrivateActions,
  setShowAuxiliarPixel,
}) => {
  return (
    <SyllabusEditorContext.Provider
      value={{
        disabledSaveButton: disabledSaveButton,
        setDisabledSaveButton: setDisabledSaveButton,
        currentSection: currentSection,
        currentTab: currentTab,
        overridePrivateActions: overridePrivateActions,
        setShowAuxiliarPixel: setShowAuxiliarPixel,
      }}
    >
      {children}
    </SyllabusEditorContext.Provider>
  );
};

export default SyllabusEditorContextProvider;
