import React, { useEffect, useState } from "react";
import { match, useHistory } from "react-router-dom";
import {
  deleteComponentOnSyllabus,
  Syllabus,
  SyllabusComponent,
} from "../../model/Syllabus";
import { useGetSyllabus } from "../../hooks/useGetSyllabus";
import { Identifiable } from "utils/Identifiable";
import SyllabusEditor from "./SyllabusEditor";
import { AppState, useAppDispatch } from "../../redux/configureStore";
import { useSelector } from "react-redux";
import firebase from "firebase";
import { addSnackbarMessage } from "../../redux/common/actions/CommonActions";
import { v4 as uuidv4 } from "uuid";
import SyllabusRepository from "../../repositories/SyllabusRepository";
import PublicSyllabusRepository from "../../repositories/PublicSyllabusRepository";
import SyllabusTeacherRepository from "repositories/SyllabusTeacherRepository";
import { HidableComponent } from "../hoc/Hidable";
import styled from "styled-components";
import Exit from "../../assets/icons/Exit";

interface SyllabusIdEditorProps {
  match: match<Identifiable>;
}

const StyledExit = styled(Exit)`
  color: #f1f6ff;
`;

const Alert = styled.div`
  position: fixed;
  display: flex;
  bottom: 25px;
  background-color: rgb(4, 170, 109);
  color: white;
  opacity: 1;
  transition: opacity 0.6s ease 0s;
  padding: 20px;
  right: 25%;
`;

const SyllabusIdEditor: React.FC<SyllabusIdEditorProps> = (props) => {
  const auth = useSelector((state: AppState) => state.firebase.auth);
  const profile = useSelector((state: AppState) => state.firebase.profile);
  const id = props.match.params.id;
  const { syllabus: dbSyllabus, isLoading } = useGetSyllabus(id);
  const [syllabus, setSyllabus] = useState<Syllabus | undefined>(undefined);
  const [syllabusSharedWithTeacher, setSyllabusSharedWithTeacher] =
    useState<boolean>(false);

  const history = useHistory();

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (profile.role !== "Teacher") {
      history.replace("/");
    }
  }, [profile, history]);

  useEffect(() => {
    if (syllabusSharedWithTeacher) {
      setTimeout(() => {
        setSyllabusSharedWithTeacher(false);
      }, 800);
    }
  });

  useEffect(() => {
    setSyllabus(dbSyllabus);
  }, [dbSyllabus]);

  useEffect(() => {
    window.analytics.identify(auth.uid);
  }, [auth.uid]);

  const savePassword = async (hashedPassword: string) => {
    try {
      const repository = new SyllabusRepository();
      const oldSyllabus = await repository.findById(id);
      oldSyllabus.password = hashedPassword;
      oldSyllabus.shared = true;
      await repository.save(id, oldSyllabus);
    } catch (e) {
      const id = uuidv4();
      dispatch(
        addSnackbarMessage({
          id: id,
          message: "There was an error saving your syllabus.",
          type: "critical",
        })
      );
      const callableCreate = firebase.functions().httpsCallable("saveLog");
      callableCreate({
        message: e.message,
        payload: syllabus,
        id: id,
      });
      throw e;
    }
  };

  const deleteSection = async (componentToDelete: SyllabusComponent) => {
    const o = deleteComponentOnSyllabus(componentToDelete, syllabus!);

    setSyllabus(o);
    const repository = new SyllabusRepository();
    await repository.save(id, o);

    window.analytics.track("Section Deleted", {
      sectionType: componentToDelete!.type,
      syllabusId: syllabus!.firebaseId,
    });
  };

  if (syllabus) {
    return (
      <>
        <SyllabusEditor
          syllabus={syllabus}
          onReorderSyllabus={(syllabus: Syllabus) => {
            const repository = new SyllabusRepository();
            repository.save(id, syllabus);
          }}
          onSavingSyllabus={async (newSyllabus) => {
            const repository = new SyllabusRepository();
            await repository.save(newSyllabus.firebaseId!, newSyllabus);
          }}
          onChangePassword={(hashedPassword) => savePassword(hashedPassword)}
          onDeleteComponent={(component) => deleteSection(component)}
          onSyllabusIsShared={async (syllabus) => {
            const repository = new PublicSyllabusRepository();
            await repository.save(syllabus.firebaseId!, syllabus);

            window
              .open(
                `${window.location.protocol}//${window.location.host}/s/${syllabus.firebaseId}`,
                "_blank"
              )!
              .focus();
          }}
          onSyllabusIsSharedWithTeachers={async (syllabus) => {
            const repository = new SyllabusRepository();
            const oldSyllabus = await repository.findById(id);
            oldSyllabus.sharedWithTeacher = true;
            await repository.save(id, oldSyllabus);
            const syllabusTeacherPublicRepository =
              new SyllabusTeacherRepository();
            await syllabusTeacherPublicRepository.save(
              syllabus.firebaseId!,
              syllabus
            );
            setSyllabusSharedWithTeacher(true);
          }}
          onClickBackButton={() => {
            history.push("/dashboard");
          }}
        />
        <HidableComponent isVisible={syllabusSharedWithTeacher}>
          <Alert>
            Your collaborator link has been created.
            <span onClick={() => setSyllabusSharedWithTeacher(false)}>
              <StyledExit />
            </span>
          </Alert>
        </HidableComponent>
        ;
      </>
    );
  } else if (isLoading) {
    return <div />;
  } else {
    return <h1>No syllabus found with id {id}</h1>;
  }
};

export default SyllabusIdEditor;
