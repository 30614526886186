import React, { useState } from "react";
import styled from "styled-components";
import { Box, Keyboard } from "grommet";
import { ArrowBack } from "@material-ui/icons";
import Download from "../../../assets/icons/Download";
import Open from "../../../assets/icons/Open";
import Alert from "../../../assets/icons/Alert";
import { FileRender } from "@whatsnextedu/content-viewer";

interface ContentPreviewerOverlayProps {
  url: string;
  fileType: string;
  fileName: string;
  onClickBack: () => void;
  onClickDownload: () => void;
  onClickOpen: () => void;
}

const Overlay = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(3, 11, 29, 0.9);
  z-index: 99999;
  overflow: auto;
`;

const Header = styled(Box)`
  &.header {
    background: linear-gradient(
      180deg,
      rgba(3, 11, 29, 0.8) 0%,
      rgba(3, 11, 29, 0) 100%
    );
    height: 76px;
  }
  .icons {
    color: #ffffff;
    cursor: pointer;
  }

  .text {
    color: #ffffff;
    font-family: "Open Sans";
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;
  }
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 10px 5px rgba(3, 11, 29, 0.2);
  margin-bottom: 20px;
`;

const NoContent = styled.div`
  font-family: "Open Sans";
  font-weight: 600;
  font-size: 30px;
  line-height: 41px;
  color: #ffffff;
  text-align: center;
`;

const StyledAlert = styled(Alert)`
  transform: scale(1.5);
`;

const ContentPreviewerOverlay: React.FC<ContentPreviewerOverlayProps> = (
  props
) => {
  const [error, setError] = useState(false);

  const setUrl = (fileType: string, url: string) => {
    if (fileType === "custom/youtube") {
      return url.split("/")[3];
    }

    if (fileType.includes("application")) {
      return encodeURIComponent(url);
    }

    return url;
  };

  const setSize = (fileType: string) => {
    if (fileType === "custom/youtube") {
      return {
        height: "600px",
        width: "860px",
      };
    }

    if (fileType.includes("image")) {
      return {
        height: "800px",
        width: "650px",
      };
    }

    return {
      height: "800px",
      width: "860px",
    };
  };
  return (
    <Keyboard onEsc={() => props.onClickBack?.()}>
      <Overlay onClick={() => props.onClickBack?.()}>
        <Header
          direction="row"
          justify="between"
          className="header"
          align="center"
          pad={{ horizontal: "44px" }}
        >
          <div className="icons" onClick={() => props.onClickBack?.()}>
            <ArrowBack />
          </div>
          <div className="text">{props.fileName}</div>
          <Box direction="row" justify="between" gap="35px" className="icons">
            {!props.fileType.includes("custom") && (
              <div onClick={() => props.onClickDownload?.()}>
                <Download />
              </div>
            )}
            <div onClick={() => props.onClickOpen?.()}>
              <Open />
            </div>
          </Box>
        </Header>
        <Box direction="row" justify="center" align="center">
          {!error ? (
            <Content>
              <FileRender
                fileType={props.fileType}
                source={setUrl(props.fileType, props.url)}
                onError={() => setError(true)}
                size={setSize(props.fileType)}
              />
            </Content>
          ) : (
            <NoContent>
              <div>
                <StyledAlert />
              </div>
              <text>Sorry, this file cannot be previewed.</text>
              <br></br>
              <text>Please download it.</text>
            </NoContent>
          )}
        </Box>
      </Overlay>
    </Keyboard>
  );
};

export default ContentPreviewerOverlay;
