import React from "react";
import { CourseTitle } from "../../../model/Syllabus";
import styled from "styled-components";
import H1 from "../../viewer/customization/H1";
import H4 from "../../viewer/customization/H4";
import H3 from "../../viewer/customization/H3";

interface CourseTitleSectionProps {
  section: CourseTitle;
}

const StyledH1 = styled(H1)`
  margin: 7px 0 20px 0 !important;
`;

const CourseTitleSection: React.FC<CourseTitleSectionProps> = (props) => {
  const section = props.section;
  return (
    <>
      <H4>{section.school}</H4>
      <StyledH1>
        {section.courseNumber} - {section.title}
      </StyledH1>
      <H3>{section.semester}</H3>
    </>
  );
};

export default CourseTitleSection;
