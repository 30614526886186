import React from "react";
import { Blank, IconProps } from "grommet-icons";
import { ChildrenIconProps } from "./FileTypeIcon";
import { IconColors } from "../../theme/GlobalTheme";

const PPTX: React.FC<IconProps & ChildrenIconProps> = (props) => {
  return (
    <Blank viewBox="0 0 39 42" {...props} color="plain">
      <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
      >
        <path
          fill="white"
          d="M15.35.59H8.49A2.29,2.29,0,0,0,6.2,2.89l0,18.28A2.29,2.29,0,0,0,8.5,23.46H21.07a2.29,2.29,0,0,0,2.29-2.29V8.59Z"
        />
        <path
          fill={
            props.disabled
              ? IconColors["icon-disabled"]
              : IconColors["icon-dark-blue"]
          }
          d="M15.11,1.17l7.67,7.66V21.16a1.72,1.72,0,0,1-1.72,1.72H8.5a1.66,1.66,0,0,1-1.21-.5,1.7,1.7,0,0,1-.5-1.21V2.89a1.66,1.66,0,0,1,.5-1.22,1.7,1.7,0,0,1,1.21-.5h6.62m.24-.58H8.49A2.29,2.29,0,0,0,6.2,2.89l0,18.28A2.29,2.29,0,0,0,8.5,23.46H21.07a2.29,2.29,0,0,0,2.29-2.29V8.59l-8-8Z"
        />
        <path
          fill={
            props.disabled
              ? IconColors["icon-disabled"]
              : IconColors["icon-light-blue"]
          }
          d="M15.35.88V6.31a2.28,2.28,0,0,0,2.29,2.28h5.42Z"
        />
        <path
          fill={
            props.disabled
              ? IconColors["icon-disabled"]
              : IconColors["icon-dark-blue"]
          }
          d="M23.06,8.87H17.64a2.58,2.58,0,0,1-2.58-2.57V.88a.27.27,0,0,1,.18-.26.28.28,0,0,1,.31.06l7.72,7.71a.29.29,0,0,1,.06.31A.3.3,0,0,1,23.06,8.87Zm-7.43-7.3V6.31a2,2,0,0,0,2,2h4.73Z"
        />
        <rect
          fill={
            props.disabled
              ? IconColors["icon-disabled"]
              : IconColors["icon-blue"]
          }
          x="0.5"
          y="11.17"
          width="21.14"
          height="9.14"
          rx="0.86"
        />
        <path
          fill={
            props.disabled
              ? IconColors["icon-disabled"]
              : IconColors["icon-dark-blue"]
          }
          d="M20.78,11.73a.29.29,0,0,1,.29.29v7.43a.28.28,0,0,1-.28.28l-19.43,0a.29.29,0,0,1-.29-.29V12a.27.27,0,0,1,.28-.28l19.43,0m0-.57H1.35A.86.86,0,0,0,.5,12v7.43a.86.86,0,0,0,.86.86l19.43,0a.85.85,0,0,0,.85-.85V12a.87.87,0,0,0-.86-.86Z"
        />
        <path
          fill="white"
          d="M6.55,15.6a1.26,1.26,0,0,1-.55.52,2,2,0,0,1-.93.2H4.3v1.76H3.38V13.49H5.07a1.93,1.93,0,0,1,.9.19,1.25,1.25,0,0,1,.56.5,1.4,1.4,0,0,1,.19.73A1.47,1.47,0,0,1,6.55,15.6Zm-1-.2a.65.65,0,0,0,.19-.49c0-.44-.25-.67-.75-.67H4.3v1.34H5A.77.77,0,0,0,5.59,15.4Z"
        />
        <path
          fill="white"
          d="M10.54,15.6a1.24,1.24,0,0,1-.54.52,2,2,0,0,1-.94.2H8.29v1.76H7.37V13.49H9.06a2.08,2.08,0,0,1,.91.18,1.39,1.39,0,0,1,.56.51,1.5,1.5,0,0,1,.19.73A1.37,1.37,0,0,1,10.54,15.6Zm-.95-.2a.66.66,0,0,0,.18-.49c0-.45-.25-.67-.75-.67H8.29v1.33H9A.79.79,0,0,0,9.59,15.4Z"
        />
        <path
          fill="white"
          d="M14.49,13.49v.74H13.27v3.85h-.92V14.23H11.12v-.74Z"
        />
        <path
          fill="white"
          d="M17.91,18.07l-1-1.54L16,18.07H15l1.44-2.31L15,13.49H16L17,15l.89-1.53h1L17.5,15.79,19,18.07Z"
        />
      </svg>
    </Blank>
  );
};
export default PPTX;
