import React from "react";
import { GradingPolicy } from "../../../model/Syllabus";
import { Table, TableBody, TableCell, TableHeader, TableRow } from "grommet";
import styled from "styled-components";
import Paragraph from "../../viewer/customization/Paragraph";
import Container from "components/viewer/customization/Container";
import H4 from "../../viewer/customization/H4";

interface GradingPolicySectionProps {
  section: GradingPolicy;
}

const StyledParagraph = styled(Paragraph)`
  color: #030b1d;
  margin: 4px 0;
`;

const StyledContainer = styled(Container)`
  padding: 4px;
`;

const GradingPolicySection: React.FC<GradingPolicySectionProps> = ({
  section,
}) => {
  let total: number = 0;
  for (const item in section.items) {
    total += +section.items[item].weight;
  }
  return (
    <>
      <H4>Grading Policy</H4>
      <StyledParagraph>{section.title}</StyledParagraph>
      <StyledParagraph>{section.description}</StyledParagraph>
      <Table a11yTitle="Grading Policy">
        <TableHeader>
          <TableRow>
            <TableCell>
              <StyledContainer>Item</StyledContainer>
            </TableCell>
            <TableCell align="right">
              <StyledContainer>Weight</StyledContainer>
            </TableCell>
          </TableRow>
        </TableHeader>
        <TableBody>
          {section.items.map((item, index) => (
            <TableRow key={"grading-item-" + index}>
              <TableCell scope="row">
                <StyledContainer>{item.title}</StyledContainer>
              </TableCell>
              <TableCell align="right">
                <StyledContainer>{item.weight}</StyledContainer>
              </TableCell>
            </TableRow>
          ))}
          <TableRow key={"grading-item-total"}>
            <TableCell scope="row">
              <StyledContainer>Total</StyledContainer>
            </TableCell>
            <TableCell align="right">
              <StyledContainer>{total}</StyledContainer>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </>
  );
};

export default GradingPolicySection;
