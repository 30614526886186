import React, { useContext } from "react";
import styled from "styled-components";
import { SyllabusThemeContext } from "./SyllabusTheme";
import { Paragraph as GrommetParagraph } from "grommet";
import { FontFamily, resolveFontFamily } from "./FontConfiguration";

interface ParagraphProps {
  className?: string;
}

interface StyledComponentProps {
  fontFamily: FontFamily;
  customColor: string;
}

const StyledParagraph = styled(GrommetParagraph)<StyledComponentProps>`
  margin: 0;
  ${(props: StyledComponentProps) => `
    line-height: 1;
    font-family: ${props.fontFamily};
    color: ${props.customColor};
    font-size: ${resolveFontFamily(props.fontFamily, "paragraph").size}px;
    font-weight: ${resolveFontFamily(props.fontFamily, "paragraph").weight};
  `}
`;

const Paragraph: React.FC<ParagraphProps> = (props) => {
  const { fontFamily, primaryColor } = useContext(SyllabusThemeContext);
  return (
    <StyledParagraph
      className={props.className}
      customColor={primaryColor}
      fontFamily={fontFamily}
    >
      {props.children}
    </StyledParagraph>
  );
};

export default Paragraph;
