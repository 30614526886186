import React from "react";
import styled from "styled-components";

interface WarningMessageBoxProps {}

const Root = styled.div`
  padding: 13px 24px;
  background: #8defd2;
  font-family: Poppins;
  font-size: 14px;
  color: #030b1d;
  font-weight: 500;
  &.a {
    color: #030b1d;
    text-decoration: underline;
  }
`;

const WarningMessageBox: React.FC<WarningMessageBoxProps> = (props) => {
  return <Root>{props.children}</Root>;
};

export default WarningMessageBox;
