import React from "react";
import ConfirmDialog from "./ConfirmDialog";

interface CancelConfirmationConfirmDialogProps {
  onClickCancel?: () => void;
  onClickAction?: () => void;
  handleClose?: () => void;
  isOpen: boolean;
  disableButtons?: boolean;
}

const CancelConfirmationConfirmDialog: React.FC<
  CancelConfirmationConfirmDialogProps
> = (props) => {
  return (
    <ConfirmDialog
      isOpen={props.isOpen}
      titleLabel={
        <div role="note">
          You have unsaved changes <br /> <b>Do you want to save them?</b>
        </div>
      }
      confirmButtonProps={{
        label: "Save All & Close",
        type: "primary",
        variant: "blue",
        onClick: () => props.onClickAction?.(),
      }}
      cancelButtonProps={{
        label: "Delete Changes",
        type: "secondary",
        variant: "red",
        onClick: () => props.onClickCancel?.(),
      }}
      handleClose={props.handleClose}
      closeButton={false}
      // description={"Saved changes won't be lost"}
      disableButtons={props.disableButtons}
    />
  );
};

export default CancelConfirmationConfirmDialog;
