import React from "react";

interface FileButtonContextProviderProps {
  onDelete: (id: string) => void;
}

export const FileButtonContext =
  React.createContext<FileButtonContextProviderProps>({
    onDelete: () => {},
  });

const FileButtonContextProvider: React.FC<FileButtonContextProviderProps> = (
  props
) => {
  return (
    <FileButtonContext.Provider
      value={{
        onDelete: props.onDelete,
      }}
    >
      {props.children}
    </FileButtonContext.Provider>
  );
};

export default FileButtonContextProvider;
