import React, { useEffect, useState } from "react";
import RoundedButton from "../ui-kit/RoundedButton/RoundedButton";
import styled from "styled-components";
import { Box, Select } from "grommet";
import CustomModal from "../modals/CustomModal";
import { useAppDispatch, useAppSelector } from "redux/configureStore";
import { useFirestore } from "react-redux-firebase";
import { canvasConfig } from "config/config";
import firebase from "firebase";
import Alert from "components/Alert";
import { Syllabus } from "model/Syllabus";
import { HidableComponent } from "components/hoc/Hidable";
import Loading from "components/Loading";
import { clearTokens } from "redux/integrations/actions/CanvasActions";
import Link from "components/ui-kit/Link/Link";

import { generateHTML } from "../../helpers/generateHTMLFromCanvasTemplate";
import _ from "lodash";

const ModalWrapper = styled.div`
  .primary-text {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #193da9;
  }

  .secondary-text {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #474e61;
  }

  .reset-password {
    font-size: 12px;
    color: #2256f2;
    text-align: right;

    & span:hover,
    :focus-visible {
      cursor: pointer;
      color: #0d3af8;
    }
  }
`;

const StyledRoundedButton = styled(RoundedButton)`
  width: 292px;
  padding: 0 !important;
  align-self: center;
  margin-top: 38px;
`;

const GoToMyCanvasSyllabusLink = styled(Link)`
  text-align: center;
`;

interface CanvasCourseListItem {
  name: string;
  id: string;
}

interface SyllabusCanvasExportDialogProps {
  onClose: () => void;
  onClickCancel?: () => void;
  onExport: (id: string | undefined) => void;
  opened: boolean;
  syllabus: Syllabus;
  loading?: boolean;
  canvasId?: string;
}

const CanvasExportModalDialog: React.FC<SyllabusCanvasExportDialogProps> = (
  props
) => {
  const [selectedCourse, setSelectedCourse] = useState<any>(undefined);
  const [showWarning, setShowWarning] = useState(false);
  const canvas = useAppSelector((state) => state.integrations.canvas);
  const [courseItems, setCourseItems] = useState<CanvasCourseListItem[]>([]);
  const [defaultItems, setDefaultItems] = useState<CanvasCourseListItem[]>([]);
  const [hasCourses, setHasCourses] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const firestore = useFirestore();
  const dispatch = useAppDispatch();

  useEffect(() => {
    let active = true;
    (async () => {
      //@ts-ignore
      const instanceToken = canvas.refreshToken;
      const data = {
        url: canvasConfig.canvasURL,
        clientId: canvasConfig.clientId,
        clientSecret: canvasConfig.clientSecret,
        instanceToken: instanceToken,
      };
      if (instanceToken) {
        const callableMyCanvasCourses = firebase
          .functions()
          .httpsCallable("getMyCanvasCourses");
        setLoading(true);
        const response = await callableMyCanvasCourses(data);
        setLoading(false);
        if (response.data.status === 401) {
          dispatch(clearTokens());
          return;
        }
        const myCanvasCourses = response.data;
        if (myCanvasCourses.data.length > 0) {
          setHasCourses(true);
        }
        if (active) {
          setCourseItems(myCanvasCourses.data as CanvasCourseListItem[]);
          setDefaultItems(myCanvasCourses.data as CanvasCourseListItem[]);
          setSelectedCourse(
            props.syllabus.canvasId
              ? myCanvasCourses.data.find(
                  (course: CanvasCourseListItem) =>
                    course.id === props.syllabus.canvasId
                )
              : undefined
          );
        }
      }
    })();

    return () => {
      active = false;
    };
  }, [firestore, canvas, props.syllabus.canvasId, dispatch]);

  const handleChange = (canvasCourse: any) => {
    setShowWarning(canvasCourse ? true : false);
    setSelectedCourse(canvasCourse);
  };

  const exportSelectedSyllabus = () => {
    props.onExport(selectedCourse?.id);
    localStorage.setItem("export", "no");
  };
  const exportSyllabus = () => {
    props.onExport(undefined);
    localStorage.setItem("export", "no");
  };

  return (
    <CustomModal
      handleClose={() => props.onClose()}
      opened={props.opened}
      size="regular"
      closeButton={true}
      zIndex="1400"
    >
      <ModalWrapper>
        <>
          <p className="primary-text">Export to Canvas</p>
          <p className="secondary-text">
            That's it, the hard parts over. We have it from here. Just select
            the course in Canvas you want us to build or create a new one.
          </p>
        </>
        <HidableComponent isVisible={!props.loading && !loading}>
          <Box alignContent="center" justify="center">
            <Box width="425px" alignSelf="center">
              <HidableComponent isVisible={hasCourses}>
                <Select
                  id="canvas-course-select"
                  name="canvas course select"
                  placeholder="Select a course from Canvas"
                  labelKey="name"
                  valueKey={{ key: "id" }}
                  onChange={({ value: nextValue }) => handleChange(nextValue)}
                  options={courseItems}
                  value={selectedCourse}
                  onSearch={(text: string) => {
                    const escapedText = text.replace(
                      /[-\\^$*+?.()|[\]{}]/g,
                      "\\$&"
                    );
                    const exp = new RegExp(escapedText, "i");
                    setCourseItems(
                      defaultItems.filter((o) => exp.test(o.name))
                    );
                  }}
                  size="medium"
                  onClose={() => setCourseItems(defaultItems)}
                />

                <HidableComponent isVisible={showWarning}>
                  <Alert>
                    Are you sure you want to proceed? This will append all
                    syllabus data to the existing course in Canvas.
                  </Alert>
                </HidableComponent>
              </HidableComponent>
              <Box alignContent="center" justify="evenly">
                <StyledRoundedButton
                  disabled={!selectedCourse}
                  onClick={exportSelectedSyllabus}
                  type="secondary"
                >
                  Overwrite This Course
                </StyledRoundedButton>
                <StyledRoundedButton onClick={exportSyllabus} type="primary">
                  Create A New Course
                </StyledRoundedButton>
                <HidableComponent isVisible={!!props.canvasId}>
                  <hr />
                  <GoToMyCanvasSyllabusLink
                    target="_blank"
                    href={`${canvasConfig.canvasURL}/courses/${props.canvasId}`}
                  >
                    Go to my canvas course
                  </GoToMyCanvasSyllabusLink>
                </HidableComponent>
              </Box>
            </Box>
          </Box>
        </HidableComponent>

        <HidableComponent isVisible={!!props.loading || loading}>
          <Loading />
        </HidableComponent>
      </ModalWrapper>
    </CustomModal>
  );
};

interface SyllabusCanvasExportProps {
  syllabus: Syllabus;
  onClickCancel?: () => void;
  onClose: () => void;
  onExport: (canvasId: string) => void;
  opened: boolean;
}

const CanvasExportModal: React.FC<SyllabusCanvasExportProps> = (props) => {
  const [canvasId, setCanvasId] = useState<string | undefined>(undefined);
  const { syllabus } = props;
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const canvas = useAppSelector((state) => state.integrations.canvas);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const handleCanvasLogin = async () => {
      const instanceToken = canvas.refreshToken;
      let courseId = props.syllabus.firebaseId;
      const instanceData = {
        url: canvasConfig.canvasURL,
        clientId: canvasConfig.clientId,
        clientSecret: canvasConfig.clientSecret,
        scope: canvasConfig.scopes.join(" "),
        redirectUrl: canvasConfig.canvasRedirectUrl,
      };
      const sameScopes = _.isEqual(canvas.scopes, canvasConfig.scopes);
      if (!instanceToken || !canvas.scopes || !sameScopes) {
        const url = `${instanceData.url}/login/oauth2/auth?client_id=${instanceData.clientId}&response_type=code&state=canvassyllabusId=${courseId}&redirect_uri=${instanceData.redirectUrl}&scope=${instanceData.scope}`;
        // @ts-ignore
        window.location = url;
      } else {
        setIsOpenDialog(true);
      }
    };

    handleCanvasLogin();
    setIsOpenDialog(true);
  }, [canvas, props.syllabus]);

  const onClickExportToCanvas = async (courseId: string | undefined) => {
    const instanceToken = canvas.refreshToken;
    const instanceData = {
      url: canvasConfig.canvasURL,
      clientId: canvasConfig.clientId,
      clientSecret: canvasConfig.clientSecret,
    };

    if (instanceToken !== "") {
      const callableCreate = firebase
        .functions()
        .httpsCallable("exportToCanvas");
      try {
        setLoading(true);
        const result = await callableCreate({
          syllabusId: syllabus.firebaseId,
          refreshToken: instanceToken,
          courseId: courseId,
          instanceData: instanceData,
          instanceName: "BlendEd",
          htmlString: generateHTML("Classic", syllabus),
        });
        if (result.data.status === "ok") {
          setCanvasId(result.data.data.courseId);
          props.onExport(courseId ?? result.data.data);
          const url = `${canvasConfig.canvasURL}/courses/${result.data.data.courseId}`;
          window.open(url, "_blank");
          setIsOpenDialog(false);
        } else {
          throw new Error(result.data.message);
        }
      } catch (error) {
        alert("Error: " + error.message);
        console.error(error);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <>
      <HidableComponent isVisible={isOpenDialog}>
        <CanvasExportModalDialog
          onExport={async (courseId) => {
            await onClickExportToCanvas(courseId);
          }}
          onClose={() => {
            if (loading) return;
            setIsOpenDialog(false);
            props.onClose();
          }}
          onClickCancel={() => props.onClickCancel?.()}
          syllabus={syllabus}
          opened={isOpenDialog}
          loading={loading}
          canvasId={canvasId}
        />
      </HidableComponent>
    </>
  );
};

export default CanvasExportModal;
