import React from "react";
import styled from "styled-components";

import Header from "./Sections/Header";
import Instructors from "./Sections/Instructors";
import Description from "./Sections/Description";
import Objectives from "./Sections/Objectives";
import Schedule from "./Sections/Schedule";
import OfficeHours from "./Sections/OfficeHours";

import type { ThemeInterface } from "../../types";

const ClassicWrapper = styled.table`
  table {
    height: 29px;
    width: 100%;
    border-collapse: collapse;
    background-color: #389ebb;
    border-color: #5991a6;
    border-style: none;
  }
`;

const Classic: React.FC<ThemeInterface> = (props) => {
  return (
    <ClassicWrapper>
      <table
        style={
          props.header.title && props.header.number
            ? {
                height: "29px",
                width: "100%",
                borderCollapse: "collapse",
                backgroundColor: "#389ebb",
                borderColor: "#5991a6",
                borderStyle: "none",
              }
            : {}
        }
      >
        <tbody>
          {props.header.title && props.header.number && (
            <Header title={props.header.title} number={props.header.number} />
          )}
        </tbody>
        <table>
          {props.instructors.instructors && (
            <Instructors instructors={props.instructors.instructors} />
          )}
          {props.officeHours.officeHours && (
            <OfficeHours officeHours={props.officeHours.officeHours} />
          )}
          {props.description.description && (
            <Description description={props.description.description} />
          )}
          {props.objectives.objectives && (
            <Objectives objectives={props.objectives.objectives} />
          )}
          {props.schedule.weeks && <Schedule weeks={props.schedule.weeks} />}
        </table>
        <p>
          <a
            href={`https://test.blendedcourse.com/syllabus/${props.firebaseId}`}
          >
            Complete BlendEd syllabus
          </a>
        </p>
      </table>
    </ClassicWrapper>
  );
};

export default Classic;
