import React, { useCallback, useEffect, useState } from "react";
import { Link, match, useHistory, useLocation } from "react-router-dom";
import { Syllabus } from "../../model/Syllabus";
import { useFirestore } from "react-redux-firebase";
import { Identifiable } from "utils/Identifiable";
import SyllabusEditor from "./SyllabusEditor";
import { AppState, useAppDispatch } from "../../redux/configureStore";
import { useSelector } from "react-redux";
import {
  addSnackbarMessage,
  setRedirect,
} from "../../redux/common/actions/CommonActions";
import { v4 as uuidv4 } from "uuid";
import SyllabusFactory from "../../model/SyllabusFactory";
import {
  deleteTempSyllabus,
  saveTempSyllabus,
} from "../../redux/onboarding/actions/OnboardingActions";
import SignUpForBlendedModal from "./SignUpForBlendedModal";
import moment from "moment";
import WarningMessageBox from "../WarningMessageBox";
import ConfirmModal from "./ConfirmModal";
import useTempSyllabus from "../../hooks/useTempSyllabus";
import _ from "lodash";

interface MyNewSyllabusProps {
  match: match<Identifiable>;
}

const MyNewSyllabus: React.FC<MyNewSyllabusProps> = (props) => {
  const firestore = useFirestore();
  const auth = useSelector((state: AppState) => state.firebase.auth);
  const id = props.match.params.id;
  const [syllabus, setSyllabus] = useState<Syllabus | undefined>(undefined);
  const dispatch = useAppDispatch();
  const tempSyllabus = useTempSyllabus();
  const [registerUserModal, setRegisterUserModal] = useState<boolean>(false);
  const [confirmModal, setConfirmModal] = useState<boolean>(false);
  const history = useHistory();
  const location = useLocation();

  const createNewSyllabus = useCallback(
    (from: string, to: string, days: string) => {
      try {
        const syllabusFactory = new SyllabusFactory();
        const daysList = days?.split(",").map((d) => parseInt(d));
        const daysInBoolean = [
          false,
          false,
          false,
          false,
          false,
          false,
          false,
        ].map((_, index) => daysList!.includes(index));
        const newSyllabus = syllabusFactory.create({
          title: "Add your course title",
          semester: "",
          courseNumber: "",
          createdBy: "",
          from,
          to,
          days: daysInBoolean,
          school: "",
        });

        return newSyllabus;
      } catch (e) {
        dispatch(
          addSnackbarMessage({
            type: "critical",
            message: "There was an error creating your syllabus",
            id: uuidv4(),
          })
        );
        throw e;
      }
    },
    [dispatch]
  );

  useEffect(() => {
    (async () => {
      if (auth.uid) {
        if (tempSyllabus) {
          dispatch(deleteTempSyllabus());
          const completedSyllabus = {
            ...tempSyllabus!,
            createdBy: auth.uid,
            createdAt: firestore.FieldValue.serverTimestamp(),
          };
          await firestore.collection("syllabi").add(completedSyllabus);
        }
        history.push("/dashboard/");
        return;
      }

      let newSyllabus: Syllabus;
      const query = new URLSearchParams(location.search);
      const from = query.get("from") || undefined;
      const to = query.get("to") || undefined;
      const days = query.get("days") || undefined;

      if (from && to && days && tempSyllabus === undefined) {
        // If user visits the page coming from Landing Page and we have to create a new syllabus
        try {
          newSyllabus = createNewSyllabus(from, to, days);
          dispatch(saveTempSyllabus(newSyllabus));
        } catch (e) {
          return;
        }
        history.push("/my-new-syllabus");
      } else if (from && to && days && tempSyllabus !== undefined) {
        setConfirmModal(true);
        newSyllabus = _.cloneDeep(tempSyllabus);
      } else if (!(from && to && days) && tempSyllabus !== undefined) {
        // If user visits the page directly and already has a syllabus
        newSyllabus = _.cloneDeep(tempSyllabus);
      } else {
        // If user visits the page directly but does not have any syllabus
        setSyllabus(undefined);
        return;
      }

      setSyllabus(newSyllabus);
    })();
  }, [
    auth.uid,
    dispatch,
    firestore,
    history,
    location.search,
    tempSyllabus,
    createNewSyllabus,
  ]);

  const save = async (syllabus: Syllabus): Promise<void> => {
    dispatch(saveTempSyllabus(syllabus));
  };

  const redirectAndSavePrevUrl = (to: string) => {
    const expirationDate = moment().add(4, "m").toDate();
    dispatch(setRedirect("/my-new-syllabus", expirationDate));
    history.push(to);
  };

  if (syllabus) {
    return (
      <>
        <ConfirmModal
          opened={confirmModal}
          onClickConfirm={() => {
            const query = new URLSearchParams(location.search);
            const from = query.get("from") || undefined;
            const to = query.get("to") || undefined;
            const days = query.get("days") || undefined;
            try {
              if (from && to && days) {
                const s = createNewSyllabus(from, to, days);
                setSyllabus(s);
                dispatch(saveTempSyllabus(s));
                history.push("/my-new-syllabus");
                window.location.reload();
              }
            } catch (e) {
              console.error(e);
              return;
            } finally {
              setConfirmModal(false);
            }
          }}
          onClickCancel={() => {
            setConfirmModal(false);
            history.push("/my-new-syllabus");
          }}
        />
        <SignUpForBlendedModal
          opened={registerUserModal}
          handleClose={() => setRegisterUserModal(false)}
          onClickOnSignup={() => redirectAndSavePrevUrl("/register")}
          onClickOnLogin={() => redirectAndSavePrevUrl("/login")}
        />
        <SyllabusEditor
          syllabus={syllabus}
          onSavingSyllabus={(newSyllabus) => {
            save(newSyllabus);
          }}
          onClickDelete={(syllabus: Syllabus) => {
            setRegisterUserModal(true);
          }}
          overridePrivateActions={() => {
            setRegisterUserModal(true);
          }}
          warningMessage={
            <WarningMessageBox>
              You are using a trial version.{" "}
              <Link to="#" onClick={() => redirectAndSavePrevUrl("/register")}>
                Sign up to use our full version, it’s free!
              </Link>
            </WarningMessageBox>
          }
          onDeleteSection={(sectionId) => {
            const oldSyllabus = _.cloneDeep(syllabus);
            oldSyllabus.sections = oldSyllabus.sections.filter(
              (section) => section.id !== sectionId
            );
            dispatch(saveTempSyllabus(oldSyllabus));
          }}
        />
      </>
    );
  } else {
    return <h1>No syllabus found with id {id}</h1>;
  }
};

export default MyNewSyllabus;
