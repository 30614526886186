import React from "react";
import { Anchor } from "grommet";
import styled from "styled-components";
import clsx from "clsx";
import { LinkType } from "../../../theme/GlobalTheme";

interface LinkProps {
  disabled?: boolean;
  type?: LinkType;
  onClick?: () => void;
  tabIndex?: number;
  color?: string;
  activeColor?: string;
  target?: string;
  href?: string;
  className?: string;
}

const defaultProps: Partial<LinkProps> = {
  type: "default",
};

const StyledAnchor = styled(Anchor)<Pick<LinkProps, "color" | "activeColor">>`
  &.link {
    font-family: inherit;
    color: ${(props) =>
      props.color ?? (props.type === "default" ? "#0d3af8" : "#193DA9")};
    font-size: inherit;
    font-weight: ${(props) => (props.type === "activity" ? 700 : 600)};
    text-decoration-line: ${(props) =>
      props.type === "default" ? "underline" : "none"};
    opacity: 1;
  }

  &:hover {
    font-weight: ${(props) => (props.type === "activity" ? 700 : 600)};
    text-decoration: underline;
  }

  &:active {
    color: ${(props) =>
      props.activeColor ?? (props.type === "default" ? " #193da9" : "#08298A")};
    font-weight: ${(props) => (props.type === "activity" ? 700 : 600)};
  }

  &.disabled {
    color: #9da2ac;

    &:hover {
      font-weight: 400;
      cursor: not-allowed;
    }
  }
`;

const Link: React.FC<LinkProps> = (props) => {
  return (
    <StyledAnchor
      color={props.color}
      activeColor={props.activeColor}
      label={props.children}
      disabled={props.disabled}
      type={props.type}
      className={clsx({
        link: true,
        [props.disabled ? "disabled" : ""]: true,
        [props.className ?? ""]: true,
      })}
      onClick={props.onClick}
      href={props.href}
      tabIndex={props.tabIndex}
      target={props.target}
    />
  );
};

Link.defaultProps = defaultProps;

export default Link;
