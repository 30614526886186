import {
  CustomSection,
  getSectionTypeLabel,
  Schedule,
  Section,
  SectionType,
} from "../../model/Syllabus";
import React, { Fragment, useContext, useEffect } from "react";
import styled from "styled-components";
import { Sidebar } from "grommet";
import ModalNavbarItem from "components/ui-kit/ModalNavbarItem/ModalNavbarItem";
import _ from "lodash";
import SyllabusErrorBag from "../../model/SyllabusValidator/SyllabusErrorBag";
import { SyllabusEditorContext } from "./SyllabusEditorContextProvider";

interface ModalNavbarProps {
  sections: Section[];
  previousSections: Section[];
  selectedSection?: Section;
  onChangeSection: (section: Section) => void;
  syllabusErrorBag?: SyllabusErrorBag;
}

const ModalNavbarStyled = styled(Sidebar)`
  padding: 33px 0 33px 50px;
`;

const ModalNavbar: React.FC<ModalNavbarProps> = (props) => {
  const { setDisabledSaveButton } = useContext(SyllabusEditorContext);

  const compareSections = (firstSection: Section, secondSection: Section) => {
    if (firstSection.type === "Schedule") {
      return false;
    } else {
      return !_.isEqual(firstSection, secondSection);
    }
  };

  useEffect(() => {
    if (
      _.isEqual(props.sections, props.previousSections) ||
      (props.selectedSection?.type === "Schedule" &&
        props.selectedSection.modules.length === 0)
    ) {
      setDisabledSaveButton(true);
    } else {
      setDisabledSaveButton(false);
    }
  }, [
    props.selectedSection,
    props.previousSections,
    setDisabledSaveButton,
    props.sections,
  ]);

  return (
    <ModalNavbarStyled a11yTitle="Syllabus Editor Navbar">
      {props.sections
        .filter(
          (section) =>
            (props.selectedSection?.type === "Schedule" &&
              section.type === "Schedule" &&
              section.modules.length === 0) ||
            props.selectedSection?.type !== "Schedule" ||
            (props.selectedSection?.type === "Schedule" &&
              props.selectedSection?.modules.length > 0)
        )
        .filter(
          (section) =>
            section.type !== "Policies" && section.type !== "RequiredTexts"
        )
        .map((section, index) => {
          return (
            <Fragment key={`${section.type}-${index}`}>
              <ModalNavbarItem
                check={compareSections(section, props.previousSections[index])}
                key={index}
                enabled={props.selectedSection?.id === section.id}
                error={props.syllabusErrorBag?.sections?.some(
                  (s) => s.section.id === section.id
                )}
                text={
                  section.type === "CustomSection"
                    ? (section as CustomSection).title?.replace(
                        /(.{15})..+/,
                        "$1…"
                      ) || getSectionTypeLabel(section.type as SectionType)
                    : getSectionTypeLabel(section.type as SectionType)
                }
                onClick={() =>
                  props.onChangeSection(
                    props.sections.find((s) => s.id === section.id)!
                  )
                }
              />
              {section.type === "Schedule" &&
                section.modules.map((module, subIndex) => {
                  return (
                    <ModalNavbarItem
                      error={props.syllabusErrorBag?.sections?.some(
                        (s) => s.section.id === module.id
                      )}
                      check={compareSections(
                        module,
                        (props.previousSections[index] as Schedule)?.modules[
                          subIndex
                        ]
                      )}
                      key={`modal-navbar-item-${subIndex}`}
                      enabled={props.selectedSection?.id === module.id}
                      text={module.title}
                      onClick={() => props.onChangeSection(module)}
                    />
                  );
                })}
            </Fragment>
          );
        })}
    </ModalNavbarStyled>
  );
};

export default ModalNavbar;
