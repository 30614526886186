import { Syllabus } from "../../../model/Syllabus";
import {
  OnboardingActions,
  OnboardingActionType,
} from "../types/OnboardingTypes";

export const saveTempSyllabus = (syllabus: Syllabus): OnboardingActions => ({
  type: OnboardingActionType.SaveTempSyllabus,
  payload: syllabus,
});

export const deleteTempSyllabus = (): OnboardingActions => ({
  type: OnboardingActionType.DeleteTempSyllabus,
  payload: undefined,
});
