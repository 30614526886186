import { customSearchConfig } from "../../config/config";
import axios from "axios";
import { GoogleCustomSearchResponse } from "./GoogleCustomSearchResponse";
import { GoogleCustomSearchResultResource } from "../../model/Resource";
import { Result } from "../../utils/Result";

export default class GoogleOERSDK {
  async search(
    query: string
  ): Promise<Result<GoogleCustomSearchResultResource[]>> {
    try {
      const res = await axios.get(
        `https://customsearch.googleapis.com/customsearch/v1?key=${customSearchConfig.key}&cx=${customSearchConfig.cx}&q=${query}`
      );
      const json = (await res.data) as GoogleCustomSearchResponse;
      const items = json.items || [];

      return Result.ok(
        items.map(
          (item) =>
            ({
              id: item.cacheId,
              title: item.title,
              summary: item.snippet,
              url: item.link,
              type: "GoogleCustomSearchResult",
            } as GoogleCustomSearchResultResource)
        )
      );
    } catch (e) {
      return Result.fail(e.message);
    }
  }
}
