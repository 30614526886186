import React from "react";
import { TableBody as GrommetTableBody } from "grommet";

interface TableBodyProps {}

const TableBody: React.FC<TableBodyProps> = (props) => {
  return <GrommetTableBody>{props.children}</GrommetTableBody>;
};

export default TableBody;
