import React, { useEffect, useState } from "react";
import SyllabusEditor from "components/editor/SyllabusEditor";
import SyllabusRepository from "repositories/SyllabusRepository";
import { Syllabus } from "model/Syllabus";
import { useHistory, useParams } from "react-router-dom";
import SignUpModal from "components/one-week-plan/SignUpModal/SignUpModal";
import { AppState, useAppDispatch, useAppSelector } from "redux/configureStore";
import { saveTempSyllabus } from "redux/teacher-mode/actions/TeacherModeActions";
import SyllabusTeacherRepository from "repositories/SyllabusTeacherRepository";
import { useSelector } from "react-redux";

type RouteParams = {
  id: string;
};

const SyllabusEditorTeacherPage: React.FC = () => {
  const [syllabus, setSyllabus] = useState<Syllabus | undefined>();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const auth = useSelector((state: AppState) => state.firebase.auth);
  const dispatch = useAppDispatch();
  const history = useHistory();

  const teacherModeSyllabi = useAppSelector(
    (state) => state.teacherMode.tempSyllabus
  );

  const params = useParams<RouteParams>();

  useEffect(() => {
    (async () => {
      const syllabusRepository = new SyllabusTeacherRepository();

      const dbSyllabus = await syllabusRepository.findById(params.id!);

      const savedSyllabus = teacherModeSyllabi.find(
        (sb) => sb.firebaseId === dbSyllabus.firebaseId
      );

      if (savedSyllabus) {
        setSyllabus(savedSyllabus);
      } else {
        const newSyllabus = {
          ...dbSyllabus,
          sections: dbSyllabus.sections
            .filter((section) => section.type !== "Instructors")
            .map((section) => {
              if (section.type === "CourseTitle") {
                section.school = "";
              }
              return section;
            }),
        } as Syllabus;
        dispatch(saveTempSyllabus(newSyllabus));
        setSyllabus(newSyllabus);
      }
    })();
  }, [dispatch, params.id, teacherModeSyllabi]);

  const renderSignUpModal = () => (
    <SignUpModal
      opened={openModal}
      closeButton={true}
      handleClose={() => setOpenModal(false)}
      onNewLogin={async (userId) => {
        const syllabusTeacherRepository = new SyllabusTeacherRepository();
        const dbSyllabus = await syllabusTeacherRepository.findById(params.id!);
        const savedSyllabus = teacherModeSyllabi.find(
          (sb) => sb.firebaseId === dbSyllabus.firebaseId
        );
        const syllabusRepository = new SyllabusRepository();
        const newId = await syllabusRepository.create({
          ...syllabus,
          parentId: savedSyllabus?.firebaseId,
          createdBy: userId,
        } as Syllabus);
        window.analytics.track(`Successful signup`, { id: userId });
        window.analytics.identify(auth.uid, {
          email: auth.email,
        });
        window.analytics.track("Saved User Email");
        history.push(`/edit/${newId}`);
      }}
    />
  );

  return (
    <>
      {syllabus ? (
        <SyllabusEditor
          syllabus={syllabus}
          mode={{
            type: "shared",
            onClickRegister: () => setOpenModal(true),
          }}
          overridePrivateActions={() => {
            setOpenModal(true);
          }}
          onSavingSyllabus={(newSyllabus) => {
            setSyllabus(newSyllabus);
            dispatch(saveTempSyllabus(newSyllabus));
          }}
        />
      ) : (
        "Syllabus not found"
      )}
      {renderSignUpModal()}
    </>
  );
};

export default SyllabusEditorTeacherPage;
