import React from "react";
import { FileButtonMode } from "../FileButton/FileButton";

interface RichTextEditorContextProviderProps {
  mode: FileButtonMode;
  unlocked?: boolean;
  clickOnPrivateContent?: () => void;
}

export const RichTextEditorContext =
  React.createContext<RichTextEditorContextProviderProps>({
    mode: "student-view",
    unlocked: true
  });

/**
 * This context is used to modify the visual of the elements inside the RichTextEditor that live on this context.
 * @typedef Props
 * @prop FileButtonMode mode
 * @constructor
 */
const RichTextEditorContextProvider: React.FC<RichTextEditorContextProviderProps> = (props) => {
  return (
    <RichTextEditorContext.Provider
      value={{
        mode: props.mode,
        unlocked: props.unlocked ?? true,
        clickOnPrivateContent: props.clickOnPrivateContent
      }}
    >
      {props.children}
    </RichTextEditorContext.Provider>
  );
};

export default RichTextEditorContextProvider;
