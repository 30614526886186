import React, { useState } from "react";
import { Box, Image, Keyboard } from "grommet";
import { View } from "grommet-icons";
import { Syllabus } from "model/Syllabus";
import styled from "styled-components";
import DropItem from "components/DropItem";
import DropdownMenu from "components/DropdownMenu";
import SquaredButton from "components/ui-kit/SquaredButton/SquaredButton";
import SignUpModal from "components/one-week-plan/SignUpModal/SignUpModal";
import SyllabusRepository from "repositories/SyllabusRepository";
import { deleteTempSyllabus } from "redux/syllabus_templates/actions/SyllabusTemplateActions";
import { AppState, useAppDispatch } from "redux/configureStore";
import { useHistory } from "react-router-dom";
import useTemporalTemplate from "hooks/useTemporalTemplate";
import { useSelector } from "react-redux";

interface SyllabusEditorHeaderProps {
  syllabus: Syllabus;
  onClickPreview?: () => void;
  onClickPublish?: () => void;
  onClickStudentView?: () => void;
  onClickPasswordManager?: () => void;
  onClickCopyPassword?: (link: string) => void;
  onClickTeacherPublish?: () => void;
  onClickCopyTeacherLink?: (link: string) => void;
  onClickExportToPDF?: () => void;
  onClickExportToCanvas?: () => void;
  onClickGenerateSharingLink?: () => void;
  needsRegistration?: boolean;
}

const Header = styled.header`
  width: 100%;
  background: none;
  display: flex;
  justify-content: center;
`;

const Container = styled.div`
  display: flex;
  padding: 32px 0;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const ButtonContainer = styled.div`
  margin: 0 26px;
`;

const ImageContainer = styled(Box)`
  &:focus-visible {
    outline: none;
  }
`;

const SyllabusEditorPrivateHeader: React.FC<SyllabusEditorHeaderProps> = (
  props
) => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [copyCollaboratorLinkEnabled, setCopyCollaboratorLinkEnabled] =
    useState<boolean>(true);
  const reduxSyllabi: Syllabus[] = useTemporalTemplate();
  const auth = useSelector((state: AppState) => state.firebase.auth);
  const history = useHistory();
  const dispatch = useAppDispatch();

  const renderSignUpModal = () => (
    <SignUpModal
      opened={openModal}
      closeButton={true}
      handleClose={() => setOpenModal(false)}
      onNewLogin={(userId) => {
        reduxSyllabi.map(async (syllabus) => {
          const newSyllabus = {
            ...syllabus,
            createdBy: userId,
          } as Syllabus;
          window.analytics.track(`Successful signup`, { id: userId });
          window.analytics.identify(auth.uid, {
            email: auth.email,
          });
          window.analytics.track("Saved User Email");
          const syllabusRepository = new SyllabusRepository();
          await syllabusRepository.create(newSyllabus!);
          dispatch(deleteTempSyllabus(syllabus.parentId!));
        });
        history.replace("/dashboard");
      }}
    />
  );

  return (
    <Header>
      <Container>
        <ImageContainer gridArea="header">
          <a href="/" title="BlendED">
            <Image src="/images/logos/logo.svg" alt="BlendED" width="150px" />
          </a>
        </ImageContainer>
        <Box gridArea="nav" direction="row">
          <ButtonContainer>
            <SquaredButton
              icon={<View color="currentColor" />}
              reverseIcon={true}
              onClick={() => {
                if (props.needsRegistration) {
                  setOpenModal(true);
                } else {
                  props.onClickPreview?.();
                }
              }}
              type="secondary"
            >
              Preview
            </SquaredButton>
          </ButtonContainer>
          <div>
            <DropdownMenu
              label={
                <SquaredButton
                  type="primary"
                  onClick={() => props.needsRegistration && setOpenModal(true)}
                >
                  Publish
                </SquaredButton>
              }
              dropContent={
                !props.needsRegistration ? (
                  <>
                    <Keyboard onEnter={() => props.onClickPublish?.()}>
                      <DropItem
                        text={
                          !props.syllabus.shared
                            ? "Create Student Copy"
                            : "Update Student Copy"
                        }
                        onClick={() => props.onClickPublish?.()}
                      />
                    </Keyboard>
                    <Keyboard onEnter={() => props.onClickStudentView?.()}>
                      <DropItem
                        text="View Student Copy"
                        onClick={() => props.onClickStudentView?.()}
                        disabled={!props.syllabus.shared}
                      />
                    </Keyboard>
                    <Keyboard
                      onEnter={() =>
                        props.onClickCopyPassword?.(
                          `${window.location.protocol}//${window.location.host}/s/${props.syllabus.firebaseId}`
                        )
                      }
                    >
                      <DropItem
                        text="Share Student Link"
                        onClick={() =>
                          props.onClickCopyPassword?.(
                            `${window.location.protocol}//${window.location.host}/s/${props.syllabus.firebaseId}`
                          )
                        }
                        disabled={!props.syllabus.shared}
                      />
                    </Keyboard>
                    <Keyboard onEnter={() => props.onClickPasswordManager?.()}>
                      <DropItem
                        text="Management Student Password"
                        onClick={() => props.onClickPasswordManager?.()}
                      />
                    </Keyboard>
                    <Keyboard onEnter={() => props.onClickTeacherPublish?.()}>
                      <DropItem
                        text="Create Collaborator Link"
                        onClick={() => {
                          props.onClickTeacherPublish?.();
                          setCopyCollaboratorLinkEnabled(false);
                        }}
                      />
                    </Keyboard>
                    <Keyboard
                      onEnter={() =>
                        props.onClickCopyTeacherLink?.(
                          `${window.location.protocol}//${window.location.host}/t/${props.syllabus.firebaseId}`
                        )
                      }
                    >
                      <DropItem
                        text="Copy Collaborator Link"
                        onClick={() =>
                          props.onClickCopyTeacherLink?.(
                            `${window.location.protocol}//${window.location.host}/t/${props.syllabus.firebaseId}`
                          )
                        }
                        disabled={copyCollaboratorLinkEnabled}
                      />
                    </Keyboard>
                    {window.location.href.includes("test.blended") ||
                    window.location.href.includes("localhost") ? (
                      <Keyboard onEnter={() => props.onClickExportToCanvas?.()}>
                        <DropItem
                          text="Export to Canvas"
                          onClick={() => props.onClickExportToCanvas?.()}
                        />
                      </Keyboard>
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <div></div>
                )
              }
            />
          </div>
        </Box>
      </Container>
      {renderSignUpModal()}
    </Header>
  );
};

export default SyllabusEditorPrivateHeader;
