import React from "react";
import styled from "styled-components";

import type { OfficeHoursProps } from "../../../types";

const StyledOfficeHoursWrapper = styled.div``;

const OfficeHours: React.FC<OfficeHoursProps> = (props) => {
  return (
    <StyledOfficeHoursWrapper>
      <h4>Office Hours</h4>
      <p>{props.officeHours}</p>
    </StyledOfficeHoursWrapper>
  );
};

export default OfficeHours;
