import React from "react";
import { Blank, IconProps } from "grommet-icons";
import { ChildrenIconProps } from "./FileTypeIcon";
import { IconColors } from "../../theme/GlobalTheme";

const MPEG: React.FC<IconProps & ChildrenIconProps> = (props) => {
  return (
    <Blank viewBox="0 0 39 42" {...props} color="plain">
      <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
      >
        <path
          fill="white"
          d="M14.77.56H7.92a2.34,2.34,0,0,0-1.62.68,2.26,2.26,0,0,0-.67,1.61V21.14a2.3,2.3,0,0,0,2.29,2.28H20.5a2.29,2.29,0,0,0,2.29-2.28V8.55Z"
        />
        <path
          fill={
            props.disabled
              ? IconColors["icon-disabled"]
              : IconColors["icon-dark-blue"]
          }
          d="M14.54,1.13l7.67,7.66V21.13a1.72,1.72,0,0,1-1.72,1.71H7.93a1.7,1.7,0,0,1-1.21-.5,1.66,1.66,0,0,1-.5-1.21l0-18.29a1.67,1.67,0,0,1,.51-1.21,1.7,1.7,0,0,1,1.21-.5h6.62m.23-.57H7.92a2.34,2.34,0,0,0-1.62.68,2.26,2.26,0,0,0-.67,1.61V21.14a2.3,2.3,0,0,0,2.29,2.28H20.5a2.29,2.29,0,0,0,2.29-2.28V8.55l-8-8Z"
        />
        <path
          fill={
            props.disabled
              ? IconColors["icon-disabled"]
              : IconColors["icon-light-blue"]
          }
          d="M14.77.85V6.27a2.28,2.28,0,0,0,2.28,2.29h5.43Z"
        />
        <path
          fill={
            props.disabled
              ? IconColors["icon-disabled"]
              : IconColors["icon-dark-blue"]
          }
          d="M22.49,8.84H17.06a2.57,2.57,0,0,1-2.57-2.57V.85a.3.3,0,0,1,.17-.27A.31.31,0,0,1,15,.64L22.7,8.35a.29.29,0,0,1-.21.49Zm-7.43-7.3V6.27a2,2,0,0,0,2,2H21.8Z"
        />
        <rect
          fill={
            props.disabled
              ? IconColors["icon-disabled"]
              : IconColors["icon-blue"]
          }
          x="1.07"
          y="11.13"
          width="20"
          height="9.14"
          rx="0.86"
        />
        <path
          fill={
            props.disabled
              ? IconColors["icon-disabled"]
              : IconColors["icon-dark-blue"]
          }
          d="M20.21,11.7a.29.29,0,0,1,.29.29v7.42a.29.29,0,0,1-.29.29H1.93a.29.29,0,0,1-.29-.28V12a.29.29,0,0,1,.28-.29H20.21m0-.57H1.92a.85.85,0,0,0-.85.86v7.43a.86.86,0,0,0,.86.85H20.22a.85.85,0,0,0,.85-.86V12a.86.86,0,0,0-.86-.85Z"
        />
        <path
          fill="white"
          d="M7.35,13.53v4.59H6.44v-3l-1.22,3h-.7l-1.24-3v3H2.35V13.53h1l1.46,3.41,1.45-3.41Z"
        />
        <path
          fill="white"
          d="M11.43,15.64a1.36,1.36,0,0,1-.54.52,2,2,0,0,1-.94.19H9.18v1.77H8.26V13.53H10a2.11,2.11,0,0,1,.91.18,1.26,1.26,0,0,1,.56.51,1.47,1.47,0,0,1,.19.73A1.42,1.42,0,0,1,11.43,15.64Zm-.95-.2a.66.66,0,0,0,.18-.49c0-.45-.25-.67-.75-.67H9.18v1.33h.73A.86.86,0,0,0,10.48,15.44Z"
        />
        <path
          fill="white"
          d="M13.18,14.27v1.15h1.54v.73H13.18v1.21h1.74v.75H12.26V13.52h2.66v.75Z"
        />
        <path
          fill="white"
          d="M19,14.9a1.16,1.16,0,0,0-.44-.44,1.32,1.32,0,0,0-.64-.15,1.37,1.37,0,0,0-.73.19,1.23,1.23,0,0,0-.49.52,1.67,1.67,0,0,0-.18.79,1.78,1.78,0,0,0,.19.8,1.22,1.22,0,0,0,.5.53,1.49,1.49,0,0,0,.75.18,1.27,1.27,0,0,0,.86-.28,1.31,1.31,0,0,0,.44-.78H17.63v-.7h2.49v.8a2.31,2.31,0,0,1-.4.89,2.16,2.16,0,0,1-.78.65,2.18,2.18,0,0,1-1.06.25,2.38,2.38,0,0,1-1.2-.3,2.1,2.1,0,0,1-.84-.83,2.32,2.32,0,0,1-.31-1.21,2.44,2.44,0,0,1,.3-1.21,2.26,2.26,0,0,1,.84-.84,2.43,2.43,0,0,1,1.2-.3,2.39,2.39,0,0,1,1.34.38A1.88,1.88,0,0,1,20,14.9Z"
        />
      </svg>
    </Blank>
  );
};
export default MPEG;
