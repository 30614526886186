import {
  CommonActions,
  CommonActionType,
  ExportType,
} from "../types/CommonTypes";

export interface CommonState {
  export: ExportType;
}

const initialState: CommonState = {
  export: ExportType.NO,
};

export const CommonReducer = (
  state = initialState,
  action: CommonActions
): CommonState => {
  switch (action.type) {
    case CommonActionType.SetExport:
      return {
        ...state,
        export: action.payload,
      };
    default:
      return state;
  }
};
