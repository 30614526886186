import React from "react";
import CustomModal from "../../modals/CustomModal";
import styled from "styled-components";
import SignUp from "../SignUp/SignUp";

interface SignUpModalProps {
  opened: boolean;
  closeButton: boolean;
  handleClose: () => void;
  onNewLogin?: (userId: string) => void;
}

const StyledModal = styled(CustomModal)`
  width: 800px;
  max-height: none !important;
  height: 750px;
`;

const SignUpModal: React.FC<SignUpModalProps> = (props) => {
  return (
    <StyledModal
      opened={props.opened}
      closeButton={props.closeButton}
      handleClose={props.handleClose}
      size="hero"
    >
      <SignUp onNewLogin={props.onNewLogin} />
    </StyledModal>
  );
};

export default SignUpModal;
