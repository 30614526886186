import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Box } from "grommet";
import CustomModal from "components/modals/CustomModal";
import RoundedButton from "components/ui-kit/RoundedButton/RoundedButton";
import TextInput from "components/ui-kit/TextInput/TextInput";

interface EnrollInSyllabusModalProps {
  onClickCancel?: () => void;
  onClickAction?: (code: string) => void;
  handleClose?: () => void;
  isOpen: boolean;
}

const MainText = styled.p`
  font-family: Poppins, serif;
  font-weight: normal;
  font-size: 17px;
  text-align: center;
  line-height: 25px;
  margin-top: 0;
  color: #030b1d;

  & > b {
    font-weight: 600;
  }
`;

const SecondaryText = styled.p`
  font-family: Open Sans, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  text-align: center;
  color: #474e61;
  margin-top: -16px;
`;

const ConfirmDialogContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 147px;
`;

const StyledRoundedButton = styled(RoundedButton)`
  width: 170px !important;
  padding: 0 !important;
`;

const EnrollInSyllabusModal: React.FC<EnrollInSyllabusModalProps> = (props) => {
  const [code, setCode] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!props.isOpen) {
      setCode("");
    }
  }, [props.isOpen]);

  return (
    <CustomModal
      handleClose={props.handleClose}
      closeButton={true}
      zIndex="1400"
      opened={props.isOpen}
      size="small"
    >
      <ConfirmDialogContainer>
        <Box>
          <MainText>Link New Syllabus</MainText>
          <SecondaryText>
            Add the code the professor has shared with you to link the Syllabus
            to your BlendEd account
          </SecondaryText>
          <TextInput
            label="Code"
            value={code}
            onChange={(e) => setCode(e.target.value)}
            type="text"
          />
        </Box>
        <Box align="center" justify="center" gap="small" direction="row">
          <StyledRoundedButton
            onClick={() => props.onClickCancel?.()}
            type="secondary"
            variant="blue"
          >
            Cancel
          </StyledRoundedButton>
          <StyledRoundedButton
            onClick={async () => {
              setLoading(true);
              await props.onClickAction?.(code);
              setLoading(false);
            }}
            type="primary"
            variant="blue"
            loading={loading}
          >
            Link
          </StyledRoundedButton>
        </Box>
      </ConfirmDialogContainer>
    </CustomModal>
  );
};

export default EnrollInSyllabusModal;
