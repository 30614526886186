import React from "react";
import { Blank, IconProps } from "grommet-icons";
import { ChildrenIconProps } from "./FileTypeIcon";
import { IconColors } from "../../theme/GlobalTheme";

const MPG: React.FC<IconProps & ChildrenIconProps> = (props) => {
  return (
    <Blank viewBox="0 0 24 24" {...props} color="plain">
      <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
      >
        <path
          fill="white"
          d="M15.43.57H8.57A2.26,2.26,0,0,0,7,1.24a2.29,2.29,0,0,0-.67,1.62V21.14A2.29,2.29,0,0,0,7,22.76a2.26,2.26,0,0,0,1.61.67H21.14a2.29,2.29,0,0,0,2.29-2.29V8.57Z"
        />
        <path
          fill={
            props.disabled
              ? IconColors["icon-disabled"]
              : IconColors["icon-dark-blue"]
          }
          d="M15.19,1.14l7.67,7.67V21.14a1.72,1.72,0,0,1-1.72,1.72H8.57a1.67,1.67,0,0,1-1.21-.51,1.7,1.7,0,0,1-.5-1.21V2.86a1.74,1.74,0,0,1,.5-1.22,1.7,1.7,0,0,1,1.21-.5h6.62m.24-.57H8.57A2.26,2.26,0,0,0,7,1.24a2.29,2.29,0,0,0-.67,1.62V21.14A2.29,2.29,0,0,0,7,22.76a2.26,2.26,0,0,0,1.61.67H21.14a2.29,2.29,0,0,0,2.29-2.29V8.57l-8-8Z"
        />
        <path
          fill={
            props.disabled
              ? IconColors["icon-disabled"]
              : IconColors["icon-light-blue"]
          }
          d="M15.43.86V6.29a2.28,2.28,0,0,0,2.28,2.28h5.43Z"
        />
        <path
          fill={
            props.disabled
              ? IconColors["icon-disabled"]
              : IconColors["icon-dark-blue"]
          }
          d="M23.14,8.86H17.71a2.58,2.58,0,0,1-2.57-2.57V.86a.29.29,0,0,1,.18-.27.28.28,0,0,1,.31.07l7.71,7.71a.28.28,0,0,1,.07.31A.29.29,0,0,1,23.14,8.86ZM15.71,1.55V6.29a2,2,0,0,0,2,2h4.74Z"
        />
        <rect
          fill={
            props.disabled
              ? IconColors["icon-disabled"]
              : IconColors["icon-blue"]
          }
          x="1.71"
          y="11.14"
          width="20"
          height="9.14"
          rx="0.86"
        />
        <path
          fill={
            props.disabled
              ? IconColors["icon-disabled"]
              : IconColors["icon-dark-blue"]
          }
          d="M20.86,11.71a.29.29,0,0,1,.28.29v7.43a.28.28,0,0,1-.28.28H2.57a.27.27,0,0,1-.28-.28V12a.28.28,0,0,1,.28-.29H20.86m0-.57H2.57a.87.87,0,0,0-.86.86v7.43a.87.87,0,0,0,.86.86H20.86a.86.86,0,0,0,.85-.86V12a.86.86,0,0,0-.85-.86Z"
        />
        <path
          fill="white"
          d="M9.69,13.46v4.59H8.77v-3l-1.22,3h-.7l-1.24-3v3H4.69V13.46h1L7.2,16.88l1.46-3.42Z"
        />
        <path
          fill="white"
          d="M13.77,15.57a1.29,1.29,0,0,1-.54.52,2,2,0,0,1-.94.2h-.77v1.76H10.6V13.46h1.69a2.11,2.11,0,0,1,.91.19,1.26,1.26,0,0,1,.56.51,1.43,1.43,0,0,1,.19.72A1.37,1.37,0,0,1,13.77,15.57Zm-.95-.2a.64.64,0,0,0,.18-.49c0-.44-.25-.67-.75-.67h-.73v1.34h.73A.81.81,0,0,0,12.82,15.37Z"
        />
        <path
          fill="white"
          d="M17.79,14.84a1.21,1.21,0,0,0-.43-.44,1.36,1.36,0,0,0-.65-.15,1.34,1.34,0,0,0-.72.19,1.23,1.23,0,0,0-.49.52,1.85,1.85,0,0,0,0,1.59,1.28,1.28,0,0,0,.5.53,1.54,1.54,0,0,0,.75.18,1.24,1.24,0,0,0,1.3-1.05H16.47V15.5H19v.8a2,2,0,0,1-.4.89,2.21,2.21,0,0,1-.77.65,2.25,2.25,0,0,1-1.07.25,2.43,2.43,0,0,1-1.2-.3,2.1,2.1,0,0,1-.84-.83,2.32,2.32,0,0,1-.31-1.21,2.34,2.34,0,0,1,.31-1.21,2.12,2.12,0,0,1,.84-.84,2.49,2.49,0,0,1,1.19-.29,2.4,2.4,0,0,1,1.35.37,2,2,0,0,1,.79,1.06Z"
        />
      </svg>
    </Blank>
  );
};
export default MPG;
