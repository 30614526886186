import React from "react";
import { Blank, IconProps } from "grommet-icons";
import { ChildrenIconProps } from "./FileTypeIcon";
import { IconColors } from "../../theme/GlobalTheme";

const DOCX: React.FC<IconProps & ChildrenIconProps> = (props) => {
  return (
    <Blank viewBox="0 0 39 42" {...props} color="plain">
      <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
      >
        <path
          fill="white"
          d="M15.35.63H8.49A2.29,2.29,0,0,0,6.2,2.92l0,18.29a2.24,2.24,0,0,0,.67,1.61,2.28,2.28,0,0,0,1.61.67H21.07a2.29,2.29,0,0,0,1.62-.67,2.26,2.26,0,0,0,.67-1.61V8.63Z"
        />
        <path
          fill={
            props.disabled
              ? IconColors["icon-disabled"]
              : IconColors["icon-dark-blue"]
          }
          d="M15.11,1.2l7.67,7.66V21.2a1.7,1.7,0,0,1-.5,1.21,1.74,1.74,0,0,1-1.22.5H8.5a1.71,1.71,0,0,1-1.71-1.71V2.92a1.62,1.62,0,0,1,.5-1.21,1.7,1.7,0,0,1,1.21-.5h6.62m.24-.57H8.49A2.29,2.29,0,0,0,6.2,2.92l0,18.29a2.24,2.24,0,0,0,.67,1.61,2.28,2.28,0,0,0,1.61.67H21.07a2.29,2.29,0,0,0,1.62-.67,2.26,2.26,0,0,0,.67-1.61V8.63l-8-8Z"
        />
        <rect
          fill={
            props.disabled
              ? IconColors["icon-disabled"]
              : IconColors["icon-blue"]
          }
          x="0.5"
          y="11.21"
          width="21.14"
          height="9.14"
          rx="0.86"
        />
        <path
          fill={
            props.disabled
              ? IconColors["icon-disabled"]
              : IconColors["icon-dark-blue"]
          }
          d="M20.78,11.77a.29.29,0,0,1,.29.29v7.42a.29.29,0,0,1-.28.29H1.36a.28.28,0,0,1-.29-.28V12.07a.28.28,0,0,1,.28-.29H20.78m0-.57H1.35a.86.86,0,0,0-.85.86V19.5a.85.85,0,0,0,.86.85H20.79a.85.85,0,0,0,.85-.86V12.06a.86.86,0,0,0-.86-.86Z"
        />
        <path
          fill="white"
          d="M4.68,13.81a2,2,0,0,1,.85.81,2.59,2.59,0,0,1,0,2.42,2,2,0,0,1-.84.8,2.86,2.86,0,0,1-1.27.28H1.81V13.53h1.6A2.83,2.83,0,0,1,4.68,13.81ZM4.5,16.94a1.5,1.5,0,0,0,.39-1.11,1.54,1.54,0,0,0-.39-1.12,1.51,1.51,0,0,0-1.12-.4H2.73v3h.65A1.49,1.49,0,0,0,4.5,16.94Z"
        />
        <path
          fill="white"
          d="M7.47,17.86a2.27,2.27,0,0,1-1.17-2,2.34,2.34,0,0,1,.31-1.21,2.39,2.39,0,0,1,2-1.14,2.42,2.42,0,0,1,1.19.3A2.34,2.34,0,0,1,11,15.81,2.45,2.45,0,0,1,10.69,17a2.28,2.28,0,0,1-.85.84,2.42,2.42,0,0,1-1.19.3A2.38,2.38,0,0,1,7.47,17.86Zm1.91-.71a1.2,1.2,0,0,0,.49-.53,1.79,1.79,0,0,0,.18-.81A1.73,1.73,0,0,0,9.87,15a1.32,1.32,0,0,0-.49-.53,1.44,1.44,0,0,0-.73-.18,1.35,1.35,0,0,0-.73.19,1.26,1.26,0,0,0-.5.52,2,2,0,0,0,0,1.62,1.28,1.28,0,0,0,.5.53,1.37,1.37,0,0,0,.73.19A1.35,1.35,0,0,0,9.38,17.15Z"
        />
        <path
          fill="white"
          d="M11.76,14.6a2.16,2.16,0,0,1,.84-.83,2.33,2.33,0,0,1,1.18-.3,2.38,2.38,0,0,1,1.35.39A2.08,2.08,0,0,1,15.94,15H14.88a1.05,1.05,0,0,0-.44-.49,1.3,1.3,0,0,0-.66-.17,1.43,1.43,0,0,0-.72.19,1.23,1.23,0,0,0-.48.53,1.71,1.71,0,0,0-.18.8,1.69,1.69,0,0,0,.18.8,1.24,1.24,0,0,0,.48.53,1.34,1.34,0,0,0,.72.19,1.3,1.3,0,0,0,.66-.17,1.05,1.05,0,0,0,.44-.49h1.06a2.06,2.06,0,0,1-.8,1.09,2.52,2.52,0,0,1-2.54.09,2.23,2.23,0,0,1-.84-.83,2.39,2.39,0,0,1-.3-1.21A2.44,2.44,0,0,1,11.76,14.6Z"
        />
        <path
          fill="white"
          d="M19.48,18.11l-1-1.54-.9,1.54h-1L18,15.8l-1.47-2.28h1.06l1,1.54.9-1.54h1l-1.43,2.31,1.46,2.28Z"
        />
        <path
          fill={
            props.disabled
              ? IconColors["icon-disabled"]
              : IconColors["icon-light-blue"]
          }
          d="M15.35.92V6.35a2.28,2.28,0,0,0,2.29,2.28h5.42Z"
        />
        <path
          fill={
            props.disabled
              ? IconColors["icon-disabled"]
              : IconColors["icon-dark-blue"]
          }
          d="M23.06,8.91H17.64a2.58,2.58,0,0,1-2.58-2.57V.92a.29.29,0,0,1,.49-.21l7.72,7.71a.31.31,0,0,1,.06.32A.3.3,0,0,1,23.06,8.91Zm-7.43-7.3V6.35a2,2,0,0,0,2,2h4.73Z"
        />
      </svg>
    </Blank>
  );
};

export default DOCX;
