export interface FontConfigurationItem {
  size: number;
  weight: FontWeight;
}

export type FontFamily =
  | "Aleo"
  | "Merriweather"
  | "Ubuntu"
  | "Cormorant Garamond"
  | "Nunito"
  | "Rubik"
  | "Montserrat"
  | "Open Sans"
  | "Lato";

export enum FontWeight {
  Bold = 700,
  SemiBold = 600,
  Medium = 500,
  Regular = 400,
}

export type FontLevel = "1" | "2" | "3" | "4" | "paragraph";

export type FontConfiguration = {
  [key in FontLevel]: {
    [key in FontFamily]: FontConfigurationItem;
  };
};

export const resolveFontFamily = (
  fontFamily: FontFamily,
  level: FontLevel
): FontConfigurationItem => {
  const config: FontConfiguration = {
    "1": {
      Merriweather: {
        size: 34,
        weight: FontWeight.Bold,
      },
      Aleo: {
        size: 35,
        weight: FontWeight.Bold,
      },
      Ubuntu: {
        size: 34,
        weight: FontWeight.Bold,
      },
      "Cormorant Garamond": {
        size: 37,
        weight: FontWeight.Bold,
      },
      Nunito: {
        size: 35,
        weight: FontWeight.Bold,
      },
      Rubik: {
        size: 34,
        weight: FontWeight.Bold,
      },
      Montserrat: {
        size: 33,
        weight: FontWeight.Bold,
      },
      Lato: {
        size: 35,
        weight: FontWeight.Bold,
      },
      "Open Sans": {
        size: 34,
        weight: FontWeight.SemiBold,
      },
    },
    "2": {
      Merriweather: {
        size: 24,
        weight: FontWeight.Bold,
      },
      Aleo: {
        size: 25,
        weight: FontWeight.Bold,
      },
      Ubuntu: {
        size: 24,
        weight: FontWeight.Bold,
      },
      "Cormorant Garamond": {
        size: 27,
        weight: FontWeight.Bold,
      },
      Nunito: {
        size: 25,
        weight: FontWeight.SemiBold,
      },
      Rubik: {
        size: 24,
        weight: FontWeight.Medium,
      },
      Montserrat: {
        size: 23,
        weight: FontWeight.SemiBold,
      },
      Lato: {
        size: 25,
        weight: FontWeight.SemiBold,
      },
      "Open Sans": {
        size: 24,
        weight: FontWeight.SemiBold,
      },
    },
    "3": {
      Merriweather: {
        size: 20,
        weight: FontWeight.Bold,
      },
      Aleo: {
        size: 21,
        weight: FontWeight.Bold,
      },
      Ubuntu: {
        size: 20,
        weight: FontWeight.Medium,
      },
      "Cormorant Garamond": {
        size: 23,
        weight: FontWeight.Bold,
      },
      Nunito: {
        size: 21,
        weight: FontWeight.SemiBold,
      },
      Rubik: {
        size: 20,
        weight: FontWeight.Medium,
      },
      Montserrat: {
        size: 19,
        weight: FontWeight.SemiBold,
      },
      Lato: {
        size: 21,
        weight: FontWeight.SemiBold,
      },
      "Open Sans": {
        size: 20,
        weight: FontWeight.SemiBold,
      },
    },
    "4": {
      Merriweather: {
        size: 18,
        weight: FontWeight.Bold,
      },
      Aleo: {
        size: 19,
        weight: FontWeight.Bold,
      },
      Ubuntu: {
        size: 18,
        weight: FontWeight.Medium,
      },
      "Cormorant Garamond": {
        size: 21,
        weight: FontWeight.Bold,
      },
      Nunito: {
        size: 18,
        weight: FontWeight.SemiBold,
      },
      Rubik: {
        size: 18,
        weight: FontWeight.Medium,
      },
      Montserrat: {
        size: 17,
        weight: FontWeight.SemiBold,
      },
      Lato: {
        size: 19,
        weight: FontWeight.SemiBold,
      },
      "Open Sans": {
        size: 18,
        weight: FontWeight.SemiBold,
      },
    },
    paragraph: {
      Merriweather: {
        size: 16,
        weight: FontWeight.Regular,
      },
      Aleo: {
        size: 17,
        weight: FontWeight.Regular,
      },
      Ubuntu: {
        size: 18,
        weight: FontWeight.Regular,
      },
      "Cormorant Garamond": {
        size: 19,
        weight: FontWeight.Regular,
      },
      Nunito: {
        size: 17,
        weight: FontWeight.Regular,
      },
      Rubik: {
        size: 18,
        weight: FontWeight.Regular,
      },
      Montserrat: {
        size: 15,
        weight: FontWeight.Regular,
      },
      Lato: {
        size: 17,
        weight: FontWeight.Regular,
      },
      "Open Sans": {
        size: 16,
        weight: FontWeight.Regular,
      },
    },
  };

  return config[level][fontFamily];
};
