import React from "react";
import { ClassTimes } from "../../../model/Syllabus";
import SectionEditorProps from "./SectionEditorProps";
import useDelayedState from "../../../hooks/useDelayedState";
import TextInput from "components/ui-kit/TextInput/TextInput";

const ClassTimesEditor: React.FC<SectionEditorProps> = (props) => {
  const [classTimes, setClassTimes] = useDelayedState<ClassTimes>(
    { ...(props.section as ClassTimes) },
    props.onChange
  );
  const onChangeClassTimes = (body: string) => {
    setClassTimes({
      ...classTimes,
      classTimes: body,
    });
  };

  return (
    <TextInput
      required={true}
      label="Class Times"
      value={classTimes.classTimes}
      onChange={(event) => {
        if (event.target.value !== undefined) {
          onChangeClassTimes(event.target.value);
        }
      }}
      a11yTitle={"Class Times input"}
      placeholder={"When does your class meet?"}
      error={props.error?.errors.classTimes}
    />
  );
};

export default ClassTimesEditor;
