import React from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { useSelector } from "react-redux";
import { AppState } from "../redux/configureStore";

interface Props extends RouteProps {}

export const PrivateRoute: React.FC<Props> = (props) => {
  // const isAuthenticated: boolean = useSelector<any, any>((stores) => stores.auth.isAuthenticated);

  const auth = useSelector((state: AppState) => state.firebase.auth);
  let isAuthenticated = false;

  if (!auth.isLoaded) {
    return <div />;
  } else {
    isAuthenticated = !!auth.uid;
  }

  const { component: Component, ...restProps } = props;

  if (!Component) {
    return null;
  }

  return (
    <Route
      {...restProps}
      render={(routeRenderProps) =>
        isAuthenticated ? (
          <Component {...routeRenderProps} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: routeRenderProps.location },
            }}
          />
        )
      }
    />
  );
};
