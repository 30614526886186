import React, { useEffect, useState } from "react";
import { match, useHistory } from "react-router-dom";
import SyllabusViewer from "./SyllabusViewer";
import { Syllabus } from "../../model/Syllabus";
import { useFirestore } from "react-redux-firebase";
import { Identifiable } from "utils/Identifiable";
import styled from "styled-components";
import { useAppDispatch, useAppSelector } from "../../redux/configureStore";
import PublicSyllabusRepository from "../../repositories/PublicSyllabusRepository";
import { HidableComponent } from "../hoc/Hidable";
import AskPasswordModal from "./AskPasswordModal";
import { createHash } from "../../helpers/createHash";
import { addPassword } from "../../redux/students/actions/SavedPasswordsActions";
import UsersRepository from "repositories/UsersRepository";

interface SyllabusIdViewerProps {
  match: match<Identifiable>;
}

const PublicSyllabusIdViewerWrapper = styled.div`
  .blur {
    position: absolute;
    width: 100%;
    height: 100vh;
    backdrop-filter: blur(12px);
    z-index: 1;
  }
`;

const PublicSyllabusIdViewer: React.FC<SyllabusIdViewerProps> = (props) => {
  const firestore = useFirestore();
  const [syllabus, setSyllabus] = useState<Syllabus | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const [unlocked, setUnlocked] = useState<boolean>(true);
  const [showAskPasswordModal, setShowAskPasswordModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const dispatch = useAppDispatch();
  const id = props.match.params.id;
  const history = useHistory();
  const auth = useAppSelector((state) => state.firebase.auth);

  const savedPasswords = useAppSelector(
    (state) => state.students.savedPasswords.passwords
  );

  useEffect(() => {
    (async () => {
      try {
        const repository = new PublicSyllabusRepository();
        const syllabus = await repository.findById(id);
        setSyllabus(syllabus);
        window.analytics.track("Syllabus Requested", { syllabusId: id });
        setIsLoading(false);
      } catch (error) {
        window.analytics.track("Syllabus Not Found", { syllabusId: id });
        setIsLoading(false);
      }
    })();
  }, [firestore, id]);

  useEffect(() => {
    const password = savedPasswords.find(
      (password) => password.syllabusId === id
    );
    if (
      (password && password.password === syllabus?.password) ||
      syllabus?.password === ""
    ) {
      setUnlocked(true);
      window.analytics.track("Syllabus Viewed", { syllabusId: id });
    }
  }, [savedPasswords, syllabus, id]);

  const checkPassword = (password: string) => {
    const hash = createHash(password);
    if (syllabus?.password === hash) {
      setShowAskPasswordModal(false);
      dispatch(addPassword(syllabus.firebaseId!, hash));
      window.analytics.track("Password Accepted", {
        syllabusId: syllabus.firebaseId!,
      });
      window.analytics.track("Syllabus Viewed", {
        syllabusId: syllabus.firebaseId!,
      });
    } else {
      setErrorMessage("Password incorrect");
      window.analytics.track("Incorrect Password Entered", {
        syllabusId: syllabus?.firebaseId,
      });
    }
  };

  const renderShowModalPassword = () => {
    return (
      <HidableComponent isVisible={showAskPasswordModal}>
        <div className="blur" />
        <AskPasswordModal
          opened={showAskPasswordModal}
          onClickConfirm={(password) => checkPassword(password)}
          onClickCancel={() => setShowAskPasswordModal(false)}
          errorMessage={errorMessage}
          title="This is a private syllabus"
          description="Please, write the password to unlock the content of this syllabus"
          onFocus={() => setErrorMessage("")}
        />
      </HidableComponent>
    );
  };

  if (syllabus) {
    return (
      <PublicSyllabusIdViewerWrapper>
        {renderShowModalPassword()}
        <SyllabusViewer
          syllabus={syllabus}
          publicMode={unlocked}
          clickOnPrivateContent={() => setShowAskPasswordModal(true)}
          mode={{
            type: "normal",
            onClickLinkSyllabusToStudent: async () => {
              const userRepository = new UsersRepository();
              try {
                await userRepository.enrollInSyllabus(
                  auth.uid!,
                  syllabus?.enrollmentCode!
                );
                history.push("/dashboard");
              } catch (e) {
                alert(e.message);
              }
            },
          }}
        />
        )
      </PublicSyllabusIdViewerWrapper>
    );
  } else if (isLoading) {
    return <div />;
  } else {
    return <h1>No syllabus found with id {id}</h1>;
  }
};

export default PublicSyllabusIdViewer;
