import React from "react";
import { Blank, IconProps } from "grommet-icons";
import { IconColors } from "../../theme/GlobalTheme";
import { ChildrenIconProps } from "./FileTypeIcon";

const TXT: React.FC<IconProps & ChildrenIconProps> = (props) => {
  return (
    <Blank viewBox="0 0 39 42" {...props} color="plain">
      <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
      >
        <path
          fill="white"
          d="M14.81.56H8a2.33,2.33,0,0,0-1.61.68,2.26,2.26,0,0,0-.67,1.61V21.14a2.26,2.26,0,0,0,.67,1.61A2.29,2.29,0,0,0,8,23.42H20.54a2.29,2.29,0,0,0,1.62-.67,2.31,2.31,0,0,0,.66-1.61V8.55Z"
        />
        <path
          fill={
            props.disabled
              ? IconColors["icon-disabled"]
              : IconColors["icon-dark-blue"]
          }
          d="M14.57,1.13l7.67,7.66V21.13a1.72,1.72,0,0,1-.5,1.21,1.7,1.7,0,0,1-1.21.5H8a1.74,1.74,0,0,1-1.22-.5,1.7,1.7,0,0,1-.5-1.21V2.85a1.7,1.7,0,0,1,.5-1.21A1.72,1.72,0,0,1,8,1.14h6.62m.24-.57H8a2.33,2.33,0,0,0-1.61.68,2.26,2.26,0,0,0-.67,1.61V21.14a2.26,2.26,0,0,0,.67,1.61A2.29,2.29,0,0,0,8,23.42H20.54a2.29,2.29,0,0,0,1.62-.67,2.31,2.31,0,0,0,.66-1.61V8.55l-8-8Z"
        />
        <path
          fill={
            props.disabled
              ? IconColors["icon-disabled"]
              : IconColors["icon-light-blue"]
          }
          d="M14.81.85V6.27A2.29,2.29,0,0,0,17.1,8.56h5.43Z"
        />
        <path
          fill={
            props.disabled
              ? IconColors["icon-disabled"]
              : IconColors["icon-dark-blue"]
          }
          d="M22.53,8.84H17.1a2.56,2.56,0,0,1-2.57-2.57V.85A.29.29,0,0,1,15,.64l7.72,7.71a.28.28,0,0,1,.06.31A.27.27,0,0,1,22.53,8.84ZM15.1,1.54V6.27a2,2,0,0,0,2,2h4.74Z"
        />
        <rect
          fill={
            props.disabled
              ? IconColors["icon-disabled"]
              : IconColors["icon-blue"]
          }
          x="1.11"
          y="11.13"
          width="20"
          height="9.14"
          rx="0.86"
        />
        <path
          fill={
            props.disabled
              ? IconColors["icon-disabled"]
              : IconColors["icon-dark-blue"]
          }
          d="M20.25,11.7a.29.29,0,0,1,.28.29v7.42a.29.29,0,0,1-.29.29H2a.29.29,0,0,1-.29-.28V12A.29.29,0,0,1,2,11.71H20.25m0-.57H2A.86.86,0,0,0,1.1,12v7.43a.85.85,0,0,0,.86.85H20.25a.86.86,0,0,0,.86-.86V12a.85.85,0,0,0-.85-.85Z"
        />
        <path fill="white" d="M8.63,13.46v.74H7.41v3.85H6.49V14.2H5.27v-.74Z" />
        <path
          fill="white"
          d="M12.05,18l-1-1.54L10.16,18h-1l1.45-2.31L9.1,13.46h1.06l1,1.53L12,13.46h1l-1.44,2.31L13.11,18Z"
        />
        <path
          fill="white"
          d="M16.93,13.45v.75H15.71V18h-.92V14.2H13.57v-.75Z"
        />
      </svg>
    </Blank>
  );
};
export default TXT;
