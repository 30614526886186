import axios, { AxiosInstance, Method } from "axios";
import { LoginResponse } from "./dto/LoginResponse";
import GetUserByIdResponse from "./dto/GetUserByIdResponse";
import { courseMapConfig } from "../../config/configCommon";

class CourseMapAPI {
  private client: AxiosInstance;
  private clientId = courseMapConfig.clientId!;
  private clientSecret = courseMapConfig.clientSecret!;
  private redirectUri = courseMapConfig.redirectUri!;
  private baseURL = courseMapConfig.baseUrl!;

  constructor() {
    this.client = axios.create({
      baseURL: this.baseURL,
      timeout: 10000,
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  async call(method: Method, url: string, data: any = {}) {
    try {
      return await this.client.request({
        method,
        data,
        url,
      });
    } catch (e) {
      throw e;
    }
  }

  async authCall(method: Method, url: string, data: any = {}) {
    return await this.client.request({
      method,
      data,
      url,
      headers: {
        // firebaseId: configureStore().getState().firebase.auth.uid,
      },
    });
  }

  async login(code: string): Promise<LoginResponse> {
    const queryString = new URLSearchParams({});
    const result = await this.call(
      "POST",
      `api/1.1/oauth/access_token?${queryString}`,
      {
        code: code,
        client_id: this.clientId,
        client_secret: this.clientSecret,
        redirect_uri: this.redirectUri,
      }
    );

    return result.data as LoginResponse;
  }

  async getUserById(id: string): Promise<GetUserByIdResponse> {
    const result = await this.call("GET", `api/1.1/obj/user/${id}`);

    return result.data.response as GetUserByIdResponse;
  }

  getAuthURL() {
    const queryString = new URLSearchParams({
      client_id: this.clientId,
      redirect_uri: this.redirectUri,
    });
    return `${this.baseURL}/api/1.1/oauth/authorize?${queryString}`;
  }

  getRegisterUrl() {
    return `${this.baseURL}/signup`;
  }
}

export default CourseMapAPI;
