import React, { useContext } from "react";
import { TableCell as GrommetTableCell } from "grommet";
import styled from "styled-components";
import clsx from "clsx";
import { SyllabusThemeContext } from "../../viewer/customization/SyllabusTheme";

interface ThProps {
  className?: string;
  rowspan?: number;
}

const GrommetTableCellStyled = styled(GrommetTableCell)<{
  borderColor?: string;
}>`
  font-weight: 600;
  font-size: 14px;
  text-align: left;
  padding: 8px 0;
  color: #474e61;
  border-bottom: 1px solid ${(props) => props.borderColor ?? "#d0dcf1"};
  position: relative;

  .th-wrapper {
    border-right: 1px solid ${(props) => props.borderColor ?? "#d0dcf1"};
    padding: 0 10px;
    position: relative;
    left: 0.5px;
  }

  &:last-child .th-wrapper {
    border-right: none;
  }
`;

const Th: React.FC<ThProps> = (props) => {
  const { colorScheme } = useContext(SyllabusThemeContext);
  return (
    <GrommetTableCellStyled
      borderColor={colorScheme?.lines}
      rowSpan={props.rowspan}
      className={clsx({
        [`${props.className}`]: true,
      })}
      scope="col"
    >
      <div className="th-wrapper">{props.children}</div>
    </GrommetTableCellStyled>
  );
};

export default Th;
