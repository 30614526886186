import _ from "lodash";

export function errorMapper(object: any, schema: any) {
  const validations = schema.validate(object);

  const errors: any = {};
  _.map(
    validations.error?.details,
    (detail: any) => (errors[detail.context!.label!] = detail!.message)
  );

  return errors;
}
