export enum SavedPasswordsType {
  AddPasword = "AddPassword",
}

export interface AddPassword {
  type: typeof SavedPasswordsType.AddPasword;
  payload: {
    syllabusId: string;
    password: string;
  };
}

export type SavedPasswordsActions = AddPassword;
