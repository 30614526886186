import {
  CommonActions,
  CommonActionType,
  ExportType,
} from "../types/CommonTypes";

export const setExport = (exportType: ExportType): CommonActions => ({
  type: CommonActionType.SetExport,
  payload: exportType,
});
