import React, { useEffect, useState } from "react";
import { match } from "react-router-dom";
import { Syllabus } from "../../../model/Syllabus";
import { useFirestore } from "react-redux-firebase";
import { Identifiable } from "utils/Identifiable";
import ModulePage from "./ModulePage";
import { appConfig } from "config/config";
import SyllabusRepository from "repositories/SyllabusRepository";

interface ModulePageViewerProps {
  match: match<Identifiable>;
}

const ModulePageViewer: React.FC<ModulePageViewerProps> = ({ match }) => {
  const firestore = useFirestore();
  const [syllabus, setSyllabus] = useState<Syllabus | undefined>(undefined);
  const id = match.params.id;
  const index = match.params.index;

  useEffect(() => {
    (async () => {
      try {
        const repository = new SyllabusRepository();
        const syllabus = await repository.findById(id);
        if (syllabus.version === appConfig.syllabusVersion) {
          setSyllabus(syllabus);
        }
        window.analytics.track("Module Requested", {
          syllabusId: id,
          moduleIndex: index,
        });
      } catch (error) {
        window.analytics.track("Module Not Found", {
          syllabusId: id,
          moduleIndex: index,
        });
      }
    })();
  }, [firestore, id, index]);

  if (syllabus) {
    return (
      <>
        <ModulePage syllabus={syllabus} moduleIndex={index} unlocked={true} />
      </>
    );
  } else {
    return <div />;
  }
};

export default ModulePageViewer;
