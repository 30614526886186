import React from "react";
import Heading from "./Heading";

interface H1Props {
  className?: string;
  a11yTitle?: string;
}

const H1: React.FC<H1Props> = (props) => {
  return (
    <Heading level="1" className={props.className} a11yTitle={props.a11yTitle}>
      {props.children}
    </Heading>
  );
};

export default H1;
