import { CanvasActions, CanvasActionType } from "../types/CanvasTypes";

export interface CanvasState {
  accessToken: string | undefined;
  refreshToken: string | undefined;
  userId: string | undefined;
  scopes: string[] | undefined;
}

const initialState: CanvasState = {
  accessToken: undefined,
  refreshToken: undefined,
  userId: undefined,
  scopes: [],
};

export const CanvasReducer = (
  state = initialState,
  action: CanvasActions
): CanvasState => {
  switch (action.type) {
    case CanvasActionType.SetTokensCanvas:
      return {
        ...state,
        accessToken: action.payload.accessToken,
        refreshToken: action.payload.refreshToken,
        userId: action.payload.userId,
        scopes: action.payload.scopes,
      };
    case CanvasActionType.ClearTokens:
      return initialState;
    default:
      return state;
  }
};
