import React, { useState, useRef, useEffect, useCallback } from "react";
import styled from "styled-components";

import DropdownArrows from "../../../../assets/icons/DropdownArrows";

import type { Editor } from "@tiptap/react";

const StyledSelectWrapper = styled.div`
  width: 132px;
  height: 30px;
  z-index: 99;
`;

const StyledSelectHeader = styled.div`
  width: 132px;
  height: 30px;
  background: #ffffff;
  border: 1px solid #d0dcf1;
  box-sizing: border-box;
  box-shadow: 0px 1px 2px rgba(7, 26, 67, 0.1);
  border-radius: 4px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #030b1d;
  padding: 6px 6px;
  display: flex;
  justify-content: space-between;

  &:hover {
    background: #eff3ff;
    border: 1px solid #bfd1f0;
  }

  &.is-active {
    background: #ffffff;
    border: 1px solid #0d3af8;
  }
`;

const StyledDropdownArrows = styled(DropdownArrows)`
  margin-top: 3px;
`;

const StyledSelectList = styled.ul`
  padding: 0;
  margin: 6px 0 0 0;
  width: 211px;
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(7, 26, 67, 0.15);
  border-radius: 4px;
  border: 1px solid #e8f0fe;

  & > li {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    color: #030b1d;
  }

  li:nth-child(1) {
    font-size: 34px;
    line-height: 46px;
    height: 48px;
  }

  li:nth-child(2) {
    font-size: 24px;
    line-height: 33px;
    height: 46px;
  }

  li:nth-child(3) {
    font-size: 20px;
    line-height: 27px;
    height: 44px;
  }

  li:nth-child(4) {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    height: 36px;
  }
`;

const StyledOption = styled.li`
  list-style: none;
  display: flex;
  align-items: center;
  padding-left: 6px;

  &:hover {
    background: #dde6ff;
  }
`;

interface SelectProps {
  editor: Editor | null;
}

const Select: React.FC<SelectProps> = (props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState<string>("Normal");

  const ref = useRef(null);

  const toggling = useCallback(() => setIsOpen(!isOpen), [isOpen]);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (ref.current && !ref.current.contains(event.target)) {
        toggling();
      }
    };

    document.addEventListener("click", handleClickOutside, true);

    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [toggling]);

  return (
    <StyledSelectWrapper>
      <StyledSelectHeader
        onClick={toggling}
        className={isOpen ? "is-active" : ""}
      >
        {selectedOption}
        <StyledDropdownArrows />
      </StyledSelectHeader>
      {isOpen && (
        <StyledSelectList ref={ref}>
          <StyledOption
            onClick={() => {
              props.editor?.chain().focus().toggleHeading({ level: 1 }).run();
              setSelectedOption("Heading 1");
              setIsOpen(false);
            }}
            className={
              props.editor?.isActive("heading", { level: 1 }) ? "is-active" : ""
            }
          >
            Heading 1
          </StyledOption>
          <StyledOption
            onClick={() => {
              props.editor?.chain().focus().toggleHeading({ level: 2 }).run();
              setSelectedOption("Heading 2");
              setIsOpen(false);
            }}
            className={
              props.editor?.isActive("heading", { level: 2 }) ? "is-active" : ""
            }
          >
            Heading 2
          </StyledOption>
          <StyledOption
            onClick={() => {
              props.editor?.chain().focus().toggleHeading({ level: 3 }).run();
              setSelectedOption("Heading 3");
              setIsOpen(false);
            }}
            className={
              props.editor?.isActive("heading", { level: 3 }) ? "is-active" : ""
            }
          >
            Heading 3
          </StyledOption>
          <StyledOption
            onClick={() => {
              props.editor?.chain().focus().setParagraph().run();
              setSelectedOption("Normal");
              setIsOpen(false);
            }}
            className={props.editor?.isActive("paragraph") ? "is-active" : ""}
          >
            Normal
          </StyledOption>
        </StyledSelectList>
      )}
    </StyledSelectWrapper>
  );
};

export default Select;
