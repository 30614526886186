import React from "react";
import styled from "styled-components";

interface CourseMapButtonProps {
  onClick: () => void;
}

const StyledButton = styled.button`
  height: 50px;
  width: 240px;
  border: none;
  text-align: center;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
  font-size: 16px;
  line-height: 48px;
  display: block;
  border-radius: 1px;
  transition: background-color 0.218s, border-color 0.218s, box-shadow 0.218s;
  font-family: Roboto, arial, sans-serif;
  cursor: pointer;
  background: #4285f4;
  color: #fff;

  &:hover {
    transition: background-color 0.218s, border-color 0.218s, box-shadow 0.218s;
  }
`;

const CourseMapButton: React.FC<CourseMapButtonProps> = (props) => {
  return (
    <StyledButton onClick={props.onClick}>Sign in with Coursemap</StyledButton>
  );
};

export default CourseMapButton;
