import React from "react";
import "./App.css";
//import './shared/Analytics';
import { Router } from "react-router-dom";
import { Provider } from "react-redux";
import { configureStore } from "./redux/configureStore";
import Main from "./components/Main";
import { createBrowserHistory } from "history";
import { ReactReduxFirebaseProvider } from "react-redux-firebase";
import { createFirestoreInstance } from "redux-firestore";
import { rrfConfig } from "./config/config";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import ReactDOM from "react-dom";

if (process.env.NODE_ENV !== "production") {
  const axe = require("@axe-core/react");
  axe(React, ReactDOM, 1000);
}

// Integrating Segment per instructions here: https://medium.com/@jehanzaib_awan/how-to-integrate-segment-io-7f89575968fd
declare global {
  interface Window {
    analytics: any;
  }
}

const store = configureStore();
const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance: createFirestoreInstance,
};

const history = createBrowserHistory();
let persistor = persistStore(store);

const App: React.FC = () => {
  return (
    <Provider store={store}>
      <ReactReduxFirebaseProvider {...rrfProps}>
        <PersistGate loading={null} persistor={persistor}>
          <Router history={history}>
            <Main />
          </Router>
        </PersistGate>
      </ReactReduxFirebaseProvider>
    </Provider>
  );
};

export default App;
