import React from "react";
import Heading from "./Heading";
import styled from "styled-components";

interface H3Props {
  className?: string;
  a11yTitle?: string;
}

const StyledH3 = styled(Heading)`
  margin-bottom: 6px !important;
`;

const H3: React.FC<H3Props> = (props) => {
  return (
    <StyledH3 level="3" className={props.className} a11yTitle={props.a11yTitle}>
      {props.children}
    </StyledH3>
  );
};

export default H3;
