import {
  CommonActions,
  CommonActionType,
  SnackbarMessage,
} from "../types/CommonTypes";
import { PendingAssignment } from "../../../model/Syllabus";

export const setEditMode = (editionMode: boolean): CommonActions => ({
  type: CommonActionType.SetEditMode,
  editionMode,
});

export const addSnackbarMessage = (
  snackbarMessage: SnackbarMessage
): CommonActions => ({
  type: CommonActionType.AddSnackbarMessage,
  payload: snackbarMessage,
});

export const removeSnackbarMessage = (id: string): CommonActions => ({
  type: CommonActionType.RemoveSnackbarMessage,
  payload: id,
});

export const setLoadingContent = (loading: boolean): CommonActions => ({
  type: CommonActionType.SetLoadingContent,
  payload: loading,
});

export const addPendingAssignment = (
  pendingAssignment: PendingAssignment
): CommonActions => ({
  type: CommonActionType.AddAssignment,
  payload: pendingAssignment,
});

export const removePendingAssignment = (id: string): CommonActions => ({
  type: CommonActionType.RemoveAssignment,
  payload: id,
});

export const setRedirect = (redirectUrl: string, expiration: Date) => ({
  type: CommonActionType.SetRedirect,
  payload: {
    redirectUrl,
    expiration,
  },
});

export const deleteRedirect = () => ({
  type: CommonActionType.DeleteRedirect,
  payload: undefined,
});
