import React from "react";
import styled from "styled-components";

import type { ScheduleProps } from "../../../types";

const StyledScheduleWrapper = styled.div`
  table {
    border-collapse: collapse;
    width: 100%;
  }

  td {
    text-align: center;
  }
`;

const Schedule: React.FC<ScheduleProps> = (props) => {
  return (
    <StyledScheduleWrapper>
      <h4>Modules</h4>
      <table
        style={{
          borderCollapse: "collapse",
          width: "100%",
          borderStyle: "solid",
          borderColor: "#000000",
          borderWidth: "1px",
        }}
      >
        <tbody>
          <tr>
            {props.weeks.map((week, index) => {
              return (
                <td style={{ textAlign: "center" }} key={index}>
                  <a href={`weekUrl-${index}`}>{week.title}</a>
                </td>
              );
            })}
          </tr>
        </tbody>
      </table>
    </StyledScheduleWrapper>
  );
};

export default Schedule;
