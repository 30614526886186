import React from "react";
import { Button as GrommetButton } from "grommet";
import styled from "styled-components";

interface ButtonProps {
  label: React.ReactElement;
  onClick: () => void;
  className: string;
  a11yTitle: string;
}

const StyledLabelWrapper = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;
`;

const StyledButton = styled(GrommetButton)`
  width: 30px;
  height: 30px;
  background: #ffffff;
  border: 1px solid #d0dcf1;
  box-sizing: border-box;
  box-shadow: 0px 1px 2px rgba(7, 26, 67, 0.1);
  border-radius: 4px;
  margin-left: 5px;

  &:hover {
    background: #eff3ff;
    border: 1px solid #bfd1f0;
  }

  &:active {
    background: #dde6ff;
    border: 1px solid #bfd1f0;
    box-shadow: inset 0px 1px 2px rgba(7, 26, 67, 0.2);
  }

  &.is-active {
    background: #dde6ff;
    border: 1px solid #bfd1f0;
    box-shadow: inset 0px 1px 2px rgba(7, 26, 67, 0.2);
  }
`;

const Button: React.FC<ButtonProps> = (props) => {
  return (
    <StyledButton
      a11yTitle={props.a11yTitle}
      onClick={props.onClick}
      className={props.className}
    >
      <StyledLabelWrapper>{props.label}</StyledLabelWrapper>
    </StyledButton>
  );
};

export default Button;
