import { SectionValidator } from "./SectionValidator";
import { SectionType } from "../../Syllabus";
import CourseTitleValidator from "./CourseTitleValidator";
import CustomSectionValidator from "./CustomSectionValidator";
import ModuleValidator from "./ModuleValidator";
import InstructorsValidator from "model/SyllabusValidator/SectionValidators/InstructorsValidator";
import ClassTimeValidator from "model/SyllabusValidator/SectionValidators/ClassTimeValidator";
import CourseDescriptionValidator from "model/SyllabusValidator/SectionValidators/ClassDescriptionValidator";
import CreditHoursValidator from "model/SyllabusValidator/SectionValidators/CreditHoursValidator";
import GradingPolicyValidator from "model/SyllabusValidator/SectionValidators/GradingPolicyValidator";
import LocationValidator from "model/SyllabusValidator/SectionValidators/LocationValidator";
import MaterialsValidator from "model/SyllabusValidator/SectionValidators/MaterialsValidator";
import OfficeHoursValidator from "model/SyllabusValidator/SectionValidators/OfficeHoursValidator";
import PrerequisitesValidator from "model/SyllabusValidator/SectionValidators/PrerequisitesValidator";
import SkillsValidator from "model/SyllabusValidator/SectionValidators/SkillsValidator";
import CourseObjectivesValidator from "model/SyllabusValidator/SectionValidators/CourseObjectivesValidator";

const factory: { [key in SectionType]?: SectionValidator } = {
  CourseTitle: new CourseTitleValidator(),
  Instructors: new InstructorsValidator(),
  CustomSection: new CustomSectionValidator(),
  Module: new ModuleValidator(),
  ClassTimes: new ClassTimeValidator(),
  CourseDescription: new CourseDescriptionValidator(),
  CourseObjectives: new CourseObjectivesValidator(),
  CreditHours: new CreditHoursValidator(),
  GradingPolicy: new GradingPolicyValidator(),
  Location: new LocationValidator(),
  Materials: new MaterialsValidator(),
  OfficeHours: new OfficeHoursValidator(),
  Prerequisites: new PrerequisitesValidator(),
  Skills: new SkillsValidator(),
};

export default class SectionValidatorFactory {
  create(type: SectionType): SectionValidator | undefined {
    const validator = factory[type];
    // if (!validator) {
    //   throw new Error(`Type ${type} does not have a valid validator`);
    // }

    return validator;
  }
}
