import React from "react";
import { Keyboard, Layer } from "grommet";
import { Section, Syllabus, SyllabusComponent } from "../../model/Syllabus";
import styled from "styled-components";
import ModalNavbar from "./ModalNavbar";
import EditorSlide from "./carousel/EditorSlide";
import ExitIcon from "../../assets/images/exit.svg";
import RichTextEditorContextProvider from "../ui-kit/RichTextEditor/RichTextEditorContext";
import SyllabusErrorBag from "../../model/SyllabusValidator/SyllabusErrorBag";

interface SyllabusEditorModalProps {
  opened: boolean;
  sections: Section[];
  previousSections: Section[];
  onClose: () => void;
  selectedSection: Section;
  onChangeSection: (section: Section) => void;
  onChange: (section: Section) => void;
  previousSyllabus?: Syllabus;
  onSaveAll: () => void;
  onDeleteComponent?: (component: SyllabusComponent) => void;
  onCleanUpComponent?: (component: SyllabusComponent) => void;
  onCancelAndClose?: () => void;
  syllabusErrorBag?: SyllabusErrorBag;
  /**
   * Disables "Save Changes" and "Save All & Exit" buttons.
   */
  disableButtons?: boolean;
  needsRegistration?: boolean;
}

const SyllabusEditorModalStyled = styled(Layer)`
  width: 1140px;
  height: 720px;

  .container {
    display: flex;
    height: 100%;
    overflow: initial;
    border-radius: 10px;
  }

  .sidebar {
    width: 294px;
    background: #fff;
    height: 100%;
    border-right: 2px solid #eef4ff;
    overflow-y: scroll;
  }

  .content-wrapper {
    height: 100%;
    width: calc(100% - 294px);
    background: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .exit-icon {
    position: absolute;
    top: 13px;
    right: 26px;
    z-index: 9999;

    &:hover {
      cursor: pointer;
    }
  }
`;

const SyllabusEditorModal: React.FC<SyllabusEditorModalProps> = (props) => {
  return props.opened ? (
    <SyllabusEditorModalStyled
      onEsc={() => props.onClose()}
      onClickOutside={() => props.onClose()}
      className="layer"
    >
      <Keyboard onEnter={() => props.onClose?.()}>
        <div
          className="exit-icon"
          onClick={() => props.onClose?.()}
          tabIndex={2}
        >
          <img src={ExitIcon} alt="Exit Icon" />
        </div>
      </Keyboard>
      <div className="container">
        <div className="sidebar">
          <ModalNavbar // Sidebar
            selectedSection={props.selectedSection}
            sections={props.sections}
            previousSections={props.previousSections}
            onChangeSection={(section) => props.onChangeSection(section)}
            syllabusErrorBag={props.syllabusErrorBag}
          />
        </div>
        <div className="content-wrapper">
          <RichTextEditorContextProvider mode="teacher-editor">
            {props.selectedSection && (
              <EditorSlide
                index={0}
                section={props.selectedSection}
                onChange={(section: Section) => props.onChange(section)}
                disableDeleteSection={() => {}}
                disableSaveAll
                disabledSave
                onSaveAll={props.onSaveAll}
                sections={props.sections}
                disableButtons={props.disableButtons}
                onDeleteComponent={props.onDeleteComponent}
                onCleanUpComponent={props.onCleanUpComponent}
                needsRegistration={props.needsRegistration}
                onCancelAndClose={props.onCancelAndClose}
                error={props.syllabusErrorBag?.sections.find(
                  (errorItem) =>
                    errorItem.section.id === props.selectedSection.id
                )}
              />
            )}
          </RichTextEditorContextProvider>
        </div>
      </div>
    </SyllabusEditorModalStyled>
  ) : null;
};

export default SyllabusEditorModal;
