import React, { useEffect, useState } from "react";
import RoundedButton from "../ui-kit/RoundedButton/RoundedButton";
import TextInput from "../ui-kit/TextInput/TextInput";
import styled from "styled-components";
import { Box } from "grommet";
import CustomModal from "../modals/CustomModal";

interface AskPasswordModalProps {
  opened: boolean;
  onClickConfirm?: (password: string) => void;
  onClickCancel?: () => void;
  errorMessage?: string;
  title?: string;
  description?: string;
  onFocus?: () => void;
}

const ModalWrapper = styled.div`
  padding: 48px 64px;

  .primary-text {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 25px;
    text-align: center;
    margin: 4px;
  }

  .secondary-text {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 18px;
    text-align: center;
    color: #5b616f;
    margin: 8px;
  }

  .reset-password {
    font-size: 12px;
    color: #2256f2;
    text-align: right;

    & span:hover {
      cursor: pointer;
    }
  }

  .buttons {
    display: flex;
    align-items: center;
  }
`;

const StyledRoundedButton = styled(RoundedButton)`
  width: 180px;
`;

const AskPasswordModal: React.FC<AskPasswordModalProps> = (props) => {
  const [password, setPassword] = useState("");

  useEffect(() => {
    setPassword("");
  }, [props.opened]);

  return (
    <CustomModal
      handleClose={() => props.onClickCancel?.()}
      size="small"
      opened={props.opened}
      closeButton={true}
    >
      <ModalWrapper>
        <p className="primary-text">{props.title}</p>
        <p className="secondary-text">{props.description}</p>
        <TextInput
          value={password}
          onChange={(event: any) => {
            if (event.target.value !== undefined) {
              setPassword(event.target.value);
            }
          }}
          onFocus={() => props.onFocus?.()}
          type="password"
          a11yTitle="Password field"
          error={props.errorMessage}
        />
        <Box
          width="100%"
          margin={{
            top: "14px",
          }}
          className="buttons"
        >
          <StyledRoundedButton
            type={"primary"}
            onClick={() => props.onClickConfirm?.(password)}
          >
            Confirm
          </StyledRoundedButton>
        </Box>
      </ModalWrapper>
    </CustomModal>
  );
};

export default AskPasswordModal;
