import { SectionValidator } from "./SectionValidator";
import { Materials } from "model/Syllabus";
import Joi from "joi";
import { errorMapper } from "../../../helpers/errorMapper";
import _ from "lodash";

export default class MaterialsValidator extends SectionValidator {
  validate(section: Materials) {
    const schema = Joi.object({
      materials: Joi.array()
        .min(1)
        .items(
          Joi.object({
            body: Joi.string().required().messages({
              "string.base": "Material cannot be empty*",
              "string.empty": "Material cannot be empty",
            }),
          })
        ),
    }).options({ allowUnknown: true, abortEarly: false });

    const sectionErrorItem = {
      section: section,
      errors: {
        ...errorMapper(section, schema),
      },
    };

    return !_.isEmpty(sectionErrorItem.errors) ? sectionErrorItem : undefined;
  }
}
