import React, { useState } from "react";
import { GradingItem, GradingPolicy } from "../../../model/Syllabus";
import SectionEditorProps from "./SectionEditorProps";
import GradingPolicyItemEditor from "./GradingPolicyItemEditor";
import useDelayedState from "../../../hooks/useDelayedState";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
} from "grommet";
import IconButton from "../../IconButton";
import { Close } from "grommet-icons";
import RoundedButton from "../../ui-kit/RoundedButton/RoundedButton";
import TextInput from "components/ui-kit/TextInput/TextInput";

const GradingPolicyEditor: React.FC<SectionEditorProps> = ({
  section,
  onChange,
  error,
}) => {
  const [gradingPolicy, setGradingPolicy] = useDelayedState<GradingPolicy>(
    { ...(section as GradingPolicy) },
    onChange
  );
  const [indexOffset, setIndexOffset] = useState(0);
  const onChangeTitle = (title: string) => {
    setGradingPolicy({
      ...gradingPolicy,
      title: title,
    });
  };

  const onChangeDescription = (description: string) => {
    setGradingPolicy({
      ...gradingPolicy,
      description: description,
    });
  };

  const onChangeItem = (item: GradingItem, index: number) => {
    const newItems = [...gradingPolicy.items];
    newItems[index] = item;
    setGradingPolicy({
      ...gradingPolicy,
      items: newItems,
    });
  };

  const addItem = () => {
    setGradingPolicy({
      ...gradingPolicy,
      items: [...gradingPolicy.items, { title: "", weight: 0 }],
    } as GradingPolicy);
  };

  const deleteItem = (index: number) => {
    const newPolicy = gradingPolicy;
    newPolicy.items.splice(index, 1);
    setGradingPolicy(newPolicy);
    setIndexOffset(indexOffset + newPolicy.items.length + 1);
  };

  return (
    <>
      <Box>
        <TextInput
          required={true}
          label="Title"
          value={gradingPolicy.title}
          onChange={(event: any) => {
            if (event.target.value !== undefined) {
              onChangeTitle(event.target.value);
            }
          }}
          a11yTitle={"Title"}
          placeholder={"Add Title"}
          autoFocus={true}
          error={error?.errors.title}
        />
      </Box>
      <Box>
        <TextInput
          label="Description"
          a11yTitle={"Description"}
          placeholder={"Describe your grading policy"}
          value={gradingPolicy.description}
          onChange={(event: any) => {
            if (event.target.value !== undefined) {
              onChangeDescription(event.target.value);
            }
          }}
        />
      </Box>
      <Table aria-label="simple table" a11yTitle={"Grading Policy Items"}>
        <TableHeader>
          <TableRow>
            <TableCell>Item</TableCell>
            <TableCell align="right">Weight</TableCell>
            <TableCell align="right" />
          </TableRow>
        </TableHeader>
        <TableBody>
          {gradingPolicy.items.map((item, index) => (
            <TableRow key={"grading-item-" + index + indexOffset}>
              <GradingPolicyItemEditor
                item={item}
                onChange={(item) => onChangeItem(item, index)}
              />
              <TableCell align="right">
                <IconButton
                  tabIndex={0}
                  icon={<Close />}
                  onClick={() => {
                    deleteItem(index);
                  }}
                  a11yTitle={"Delete Item"}
                />
              </TableCell>
            </TableRow>
          ))}
          <TableRow>
            <TableCell>
              <RoundedButton type="primary" onClick={() => addItem()}>
                Add Grading Item
              </RoundedButton>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </>
  );
};

export default GradingPolicyEditor;
