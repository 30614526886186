import { SectionValidator } from "./SectionValidator";
import { CourseTitle } from "model/Syllabus";
import Joi from "joi";
import { errorMapper } from "../../../helpers/errorMapper";
import _ from "lodash";

export default class CourseTitleValidator extends SectionValidator {
  validate(section: CourseTitle) {
    const schema = Joi.object({
      title: Joi.string().required().min(3),
      courseNumber: Joi.string().required(),
      school: Joi.string().allow(""),
    }).options({ allowUnknown: true, abortEarly: false });

    const [semester, year] = section.semester.split(" ");
    const extraErrors: Record<string, string> = {};
    if (semester && !year) {
      extraErrors["year"] =
        "You have to select a year if you select a semester";
    }
    if (!semester && year) {
      extraErrors["semester"] =
        "You have to select a semester if you select a year";
    }

    const sectionErrorItem = {
      section: section,
      errors: {
        ...errorMapper(section, schema),
        ...extraErrors,
      },
    };

    return !_.isEmpty(sectionErrorItem.errors) ? sectionErrorItem : undefined;
  }
}
