import React from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";

interface Props extends RouteProps {}

export const PublicRoute: React.FC<Props> = (props) => {
  // const isAuthenticated: boolean = useSelector<any, any>((stores) => stores.auth.isAuthenticated);
  const isAuthenticated = false;
  const { component: Component, ...restProps } = props;

  if (!Component) {
    return null;
  }

  return (
    <Route
      {...restProps}
      render={(routeRenderProps) =>
        !isAuthenticated ? (
          <Component {...routeRenderProps} />
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: routeRenderProps.location },
            }}
          />
        )
      }
    />
  );
};
