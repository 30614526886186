import React from "react";
import { Blank, IconProps } from "grommet-icons";

const Minus: React.FC<IconProps> = (props) => {
  return (
    <Blank viewBox="0 0 24 24" {...props} color="plain">
      <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
      >
        <path
          fill="currentColor"
          d="M17,13H7a1,1,0,0,1,0-2H17a1,1,0,0,1,0,2Z"
        />
      </svg>
    </Blank>
  );
};
export default Minus;
