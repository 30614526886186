import { generateCanvasHtml } from "./generateCanvasHtml";
import { htmlDecode } from "./htmlDecode";

import type {
  CourseDescription,
  CourseObjectives,
  CourseTitle,
  Instructors,
  OfficeHours,
  Syllabus,
  Schedule,
} from "../model/Syllabus";

export const SyllabusToThemeInterface = (syllabus: Syllabus) => {
  const title = (
    syllabus.sections.find(
      (section) => section.type === "CourseTitle"
    ) as CourseTitle
  )?.title;

  const number = (
    syllabus.sections.find(
      (section) => section.type === "CourseTitle"
    ) as CourseTitle
  )?.courseNumber;

  const instructors = (
    syllabus.sections.find(
      (section) => section.type === "Instructors"
    ) as Instructors
  )?.instructors.map((instructor) => ({
    instructor: instructor.name,
    mail: instructor.email,
  }));

  const officeHours = (
    syllabus.sections.find(
      (section) => section.type === "OfficeHours"
    ) as OfficeHours
  )?.officeHours;

  const courseDescription = (
    syllabus.sections.find(
      (section) => section.type === "CourseDescription"
    ) as CourseDescription
  )?.description;
  let description = "";
  if (courseDescription && courseDescription !== {}) {
    description = generateCanvasHtml(courseDescription);
  }

  const courseObjectives = (
    syllabus.sections.find(
      (section) => section.type === "CourseObjectives"
    ) as CourseObjectives
  )?.objectives;
  let objectives = "";
  if (courseObjectives) {
    objectives += generateCanvasHtml(courseObjectives);
  }

  const scheduleSection = syllabus.sections.find(
    (section) => section.type === "Schedule"
  ) as Schedule;
  const weeks = [];
  if (scheduleSection) {
    for (const module of scheduleSection.modules) {
      weeks.push({
        title: module.title,
      });
    }
  }

  return {
    firebaseId: syllabus.firebaseId!,
    header: {
      title: title,
      number: number,
    },
    instructors: {
      instructors: instructors,
    },
    officeHours: {
      officeHours: officeHours,
    },
    description: {
      description: htmlDecode(description),
    },
    objectives: {
      objectives: htmlDecode(objectives),
    },
    schedule: {
      weeks: weeks,
    },
  };
};
