import React from "react";
import styled from "styled-components";
import { Box, Tab } from "grommet";
import clsx from "clsx";

interface MaterialsSearchTabProps {
  title: string;
  icon?: JSX.Element;
  active?: boolean;
  onClick?: () => void;
  quantity?: number;
  className?: string;
}

const TabTitle = styled.div`
  font-family: Poppins;
  font-size: 15px;
  font-weight: 600;
  line-height: 23px;
  color: #030b1d;
  &.active {
    color: #0d3af8;
  }
`;

const TabQuantity = styled.div`
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
`;

const TabIcon = styled.div`
  color: #030b1d;
  &.active {
    color: #0d3af8;
  }
`;

const MaterialsSearchTab: React.FC<MaterialsSearchTabProps> = (props) => {
  return (
    <Tab
      className={props.className}
      title={
        <Box direction="row" gap="small" justify="center" align="center">
          <TabIcon className={clsx({ active: props.active })}>
            {props.icon}
          </TabIcon>
          <TabTitle className={clsx({ active: props.active })}>
            {props.title}
          </TabTitle>
          <TabQuantity>({props.quantity})</TabQuantity>
        </Box>
      }
    >
      {props.children}
    </Tab>
  );
};

export default MaterialsSearchTab;
