import React from "react";
import { Box, Image } from "grommet";
import { Syllabus } from "model/Syllabus";
import styled from "styled-components";
import SquaredButton from "components/ui-kit/SquaredButton/SquaredButton";

interface SyllabusEditorSharedHeaderProps {
  syllabus: Syllabus;
  onClickRegister?: () => void;
}

const Header = styled.header`
  width: 100%;
  background: none;
  display: flex;
  justify-content: center;
`;

const Container = styled.div`
  display: flex;
  padding: 32px 0;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const ButtonContainer = styled.div`
  margin: 0 26px;
`;

const ImageContainer = styled(Box)`
  &:focus-visible {
    outline: none;
  }
`;

const SyllabusEditorSharedHeader: React.FC<SyllabusEditorSharedHeaderProps> = (
  props
) => {
  return (
    <Header>
      <Container>
        <ImageContainer gridArea="header">
          <a
            href="/home/lucky/Development/Braintly/blended/public"
            title="BlendED"
          >
            <Image src="/images/logos/logo.svg" alt="BlendED" width="150px" />
          </a>
        </ImageContainer>
        <Box gridArea="nav" direction="row">
          <ButtonContainer>
            <SquaredButton
              onClick={() => props.onClickRegister?.()}
              type="primary"
            >
              Register
            </SquaredButton>
          </ButtonContainer>
        </Box>
      </Container>
    </Header>
  );
};

export default SyllabusEditorSharedHeader;
