import React from "react";
import { OfficeHours } from "../../../model/Syllabus";
import Paragraph from "../../viewer/customization/Paragraph";
import styled from "styled-components";
import H4 from "../../viewer/customization/H4";

interface OfficeHoursSectionProps {
  section: OfficeHours;
}

const StyledParagraph = styled(Paragraph)`
  color: #030b1d;
`;

const OfficeHoursSection: React.FC<OfficeHoursSectionProps> = (props) => {
  return (
    <>
      <H4 a11yTitle={"Office Hours Section"}>Office Hours</H4>
      <StyledParagraph>{props.section.officeHours}</StyledParagraph>
    </>
  );
};

export default OfficeHoursSection;
