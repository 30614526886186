import React from "react";
import { Blank, IconProps } from "grommet-icons";
import { FileType } from "../../theme/GlobalTheme";
import DOC from "./DOC";
import DOCX from "./DOCX";
import GIF from "./GIF";
import MP4 from "./MP4";
import MP3 from "./MP3";
import JPG from "./JPG";
import MPG from "./MPG";
import PDF from "./PDF";
import PNG from "./PNG";
import PPT from "./PPT";
import PPTX from "./PPTX";
import XLS from "./XLS";
import AI from "./AI";
import AVI from "./AVI";
import BMP from "./BMP";
import CDR from "./CDR";
import DEFAULT from "./DEFAULT";
import EPS from "./EPS";
import EPUB from "./EPUB";
import JPEG from "./JPEG";
import MOV from "./MOV";
import MPEG from "./MPEG";
import PSD from "./PSD";
import RAR from "./RAR";
import SVG from "./SVG";
import TIF from "./TIF";
import TXT from "./TXT";
import VMA from "./VMA";
import WAV from "./WAV";
import ZIP from "./ZIP";
import BooksFileButton from "./BooksFileButton";
import VideosFileButton from "./VideosFileButton";
import OERFileButton from "./OERFileButton";

interface FileTypeIconProps {
  fileType: FileType;
  disabled?: boolean;
}

export interface ChildrenIconProps {
  disabled?: boolean;
}

const FileTypeIcon: React.FC<IconProps & FileTypeIconProps> = (props) => {
  const iconProps: ChildrenIconProps = {
    disabled: props.disabled,
  };
  const renderIcon = () => {
    switch (props.fileType) {
      case "DOC":
        return <DOC {...iconProps} />;
      case "DOCX":
        return <DOCX {...iconProps} />;
      case "GIF":
        return <GIF {...iconProps} />;
      case "JPG":
        return <JPG {...iconProps} />;
      case "MP3":
        return <MP3 {...iconProps} />;
      case "MP4":
        return <MP4 {...iconProps} />;
      case "MPG":
        return <MPG {...iconProps} />;
      case "PDF":
        return <PDF {...iconProps} />;
      case "PNG":
        return <PNG {...iconProps} />;
      case "PPT":
        return <PPT {...iconProps} />;
      case "PPTX":
        return <PPTX {...iconProps} />;
      case "XLS":
        return <XLS {...iconProps} />;
      case "AI":
        return <AI {...iconProps} />;
      case "AVI":
        return <AVI {...iconProps} />;
      case "BMP":
        return <BMP {...iconProps} />;
      case "CDR":
        return <CDR {...iconProps} />;
      case "DEFAULT":
        return <DEFAULT {...iconProps} />;
      case "EPS":
        return <EPS {...iconProps} />;
      case "EPUB":
        return <EPUB {...iconProps} />;
      case "JPEG":
        return <JPEG {...iconProps} />;
      case "MOV":
        return <MOV {...iconProps} />;
      case "MPEG":
        return <MPEG {...iconProps} />;
      case "PSD":
        return <PSD {...iconProps} />;
      case "RAR":
        return <RAR {...iconProps} />;
      case "SVG":
        return <SVG {...iconProps} />;
      case "TIF":
        return <TIF {...iconProps} />;
      case "TXT":
        return <TXT {...iconProps} />;
      case "VMA":
        return <VMA {...iconProps} />;
      case "WAV":
        return <WAV {...iconProps} />;
      case "ZIP":
        return <ZIP {...iconProps} />;
      case "GoogleBook":
        return <BooksFileButton {...iconProps} />;
      case "Video":
        return <VideosFileButton {...iconProps} />;
      case "GoogleCustomSearchResult":
        return <OERFileButton {...iconProps} />;
      default:
        return <DEFAULT {...iconProps} />;
    }
  };
  return (
    <Blank {...props} size="40px" color="plain">
      {renderIcon()}
    </Blank>
  );
};

export default FileTypeIcon;
