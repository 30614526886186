import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { AppState } from "../redux/configureStore";
import { Anchor, Avatar, Box, Form, Text } from "grommet";
import styled from "styled-components";
import { Lock } from "grommet-icons";
import SquaredButton from "./ui-kit/SquaredButton/SquaredButton";
import UsersRepository from "repositories/UsersRepository";
import { useFirebase } from "react-redux-firebase";
import TextInput from "./ui-kit/TextInput/TextInput";
import GoogleButton from "react-google-button";
import Copyright from "./Copyright";
import CourseMapAPI from "helpers/CourseMap/CourseMapAPI";
import TermsOfUseDialog from "./TermsOfUseDialog";
import CourseMapButton from "./CourseMapButton";

const Container = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 50px;
`;
const Login: React.FC = () => {
  const firebase = useFirebase();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState<{
    email?: string;
    password?: string;
  }>({});
  const profile = useSelector((state: AppState) => state.firebase.profile);
  const [isOpenDialog, setIsOpenDialog] = useState<boolean>(false);
  const [isLoginWithGoogle, setIsLoginWithGoogle] = useState<boolean>(false);
  const acceptedTermsOfUse = useState<boolean>(profile.acceptedTermsOfUse);
  const history = useHistory();
  const auth = useSelector((state: AppState) => state.firebase.auth);
  const courseMapAPI = new CourseMapAPI();

  useEffect(() => {
    if (auth.uid) {
      history.replace("/dashboard");
    }
  }, [auth, history]);

  useEffect(() => {
    if (auth.uid) {
      history.replace("/dashboard");
    }
  }, [auth, history]);

  const handleSubmit = (e?: any) => {
    e?.preventDefault();

    (async () => {
      try {
        const repository = new UsersRepository();
        const login = await repository.signInWithEmailAndPassword(
          email,
          password
        );
        const user = await repository.getById(login.user!.uid!);

        // If the user is already registered in CourseMap.io, I force them to log in using that account.
        if (user?.bubbleId) {
          await firebase.auth().signOut();
          history.replace("login/course-map");
          return;
        }
        await firebase.updateProfile({
          ...user,
          email: email,
          acceptedTermsOfUse: true,
        });

        window.analytics.identify(login.user?.uid, { email: email });
        window.analytics.track("Successful login");
        // @ts-ignore
        await createCanvasUser(email);
        history.replace("/dashboard");
      } catch (error) {
        window.analytics.track("Unsuccessful login", { email: email });
        if (
          error!.code?.includes("email") ||
          error!.code?.includes("not-found")
        ) {
          setErrorMessage({ ...errorMessage, email: error.message });
        } else {
          setErrorMessage({ ...errorMessage, password: error.message });
        }
      }
    })();
  };

  const loginWithGoogle = async () => {
    const repository = new UsersRepository();
    const login = repository.loginWithGoogle();
    return login
      .then(async (result) => {
        const email = result.user!.email!;
        window.analytics.identify(result.user?.uid, { email: email });
        window.analytics.track("Successful login");
        const user = await repository.getById(result.user!.uid!);

        // If the user is already registered in CourseMap.io, I force them to log in using that account.
        if (user?.bubbleId) {
          await firebase.auth().signOut();
          history.replace("login/course-map");
          return;
        }

        const fullName = result.user?.displayName?.split(" ");
        await firebase.updateProfile({
          ...user,
          email: email,
          firstName: fullName?.[0] || "",
          lastName: fullName?.[1] || "",
        });
        history.replace("/dashboard");
        setIsOpenDialog(true);
      })
      .catch((error) => {
        window.analytics.track("Unsuccessful login");
        console.log("Error with Login", error);
      });
  };

  const renderTermsAndConditions = (
    <TermsOfUseDialog
      open={isOpenDialog}
      title={"Terms of Use"}
      onClickClose={() => {
        setIsOpenDialog(false);
      }}
      onClickAccept={() => {
        setIsOpenDialog(false);
        isLoginWithGoogle ? loginWithGoogle() : handleSubmit();
      }}
    />
  );

  return (
    <Container>
      <Box width="medium">
        <Box margin={{ bottom: "medium" }} align="center" gap="xsmall">
          <Avatar size="medium" background="light-2">
            <Lock color="black" />
          </Avatar>
          <Text size="large" a11yTitle="Sign In">
            Sign In
          </Text>
        </Box>
        <div>
          <Form noValidate>
            <TextInput
              error={errorMessage.email}
              a11yTitle={"Email Address"}
              placeholder={"Email Address"}
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                setErrorMessage({ ...errorMessage, email: "" });
              }}
            />
            <TextInput
              error={errorMessage.password}
              a11yTitle={"Password"}
              placeholder={"Password"}
              type="password"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
                setErrorMessage({ ...errorMessage, password: "" });
              }}
            />
            <Box align="center" pad={{ vertical: "small" }}>
              <SquaredButton
                type="primary"
                onClick={() => {
                  setIsLoginWithGoogle(false);
                  !acceptedTermsOfUse ? setIsOpenDialog(true) : handleSubmit();
                }}
                size="regular"
              >
                Login
              </SquaredButton>
            </Box>
            <Box pad={{ vertical: "small" }} align="end">
              <Anchor
                href="/forgot-password"
                label={"Forgot password?"}
                color="#2256F2"
                weight="normal"
                a11yTitle={"Forgot password?"}
              />
              <Anchor
                href={courseMapAPI.getRegisterUrl()}
                label={"Don't have an account? Sign Up"}
                color="#2256F2"
                weight="normal"
                a11yTitle={"Don't have an account? Sign Up"}
              />
            </Box>
          </Form>
          <Box margin={{ vertical: "small" }} align="center" gap="small">
            <GoogleButton
              onClick={() => {
                setIsLoginWithGoogle(true);
                loginWithGoogle();
              }}
            />
            <CourseMapButton
              onClick={async () => {
                const canvasAPI = new CourseMapAPI();
                const authUrl = canvasAPI.getAuthURL();
                // @ts-ignore
                window.location = authUrl;
              }}
            />
          </Box>
        </div>
      </Box>
      <Box margin={{ vertical: "small" }}>
        <Copyright />
      </Box>
      {renderTermsAndConditions}
    </Container>
  );
};

export default Login;
