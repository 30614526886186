import React from "react";
import { CourseObjectives } from "../../../model/Syllabus";
import Container from "../../viewer/customization/Container";
import H4 from "../../viewer/customization/H4";
import RichTextEditorViewer from "../../ui-kit/RichTextEditor/Viewer/RichTextEditorViewer";
import { createHash } from "../../../helpers/createHash";

interface ObjectivesSectionProps {
  section: CourseObjectives;
}

// Course Objectives viewer component
const ObjectivesSection: React.FC<ObjectivesSectionProps> = (props) => {
  const section = props.section;

  return (
    <>
      <H4 a11yTitle={"Objectives Section"}>Course Objectives</H4>
      <Container>
        <RichTextEditorViewer
          value={section.objectives}
          key={createHash(JSON.stringify(section.objectives))}
        />
      </Container>
    </>
  );
};

export default ObjectivesSection;
