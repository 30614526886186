import React from "react";
import { Blank, IconProps } from "grommet-icons";
import { ChildrenIconProps } from "./FileTypeIcon";
import { IconColors } from "../../theme/GlobalTheme";

const AVI: React.FC<IconProps & ChildrenIconProps> = (props) => {
  return (
    <Blank viewBox="0 0 24 24" {...props} color="plain">
      <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
      >
        <path
          fill="white"
          d="M14.84.59H8A2.29,2.29,0,0,0,5.7,2.89V21.17a2.34,2.34,0,0,0,.68,1.62A2.26,2.26,0,0,0,8,23.46H20.57a2.29,2.29,0,0,0,2.29-2.29V8.59Z"
        />
        <path
          fill={
            props.disabled
              ? IconColors["icon-disabled"]
              : IconColors["icon-dark-blue"]
          }
          d="M14.61,1.17l7.67,7.66V21.16a1.74,1.74,0,0,1-1.72,1.72H8a1.62,1.62,0,0,1-1.21-.5,1.7,1.7,0,0,1-.5-1.21l0-18.28A1.72,1.72,0,0,1,8,1.17h6.62m.23-.58H8A2.29,2.29,0,0,0,5.7,2.89V21.17a2.34,2.34,0,0,0,.68,1.62A2.26,2.26,0,0,0,8,23.46H20.57a2.29,2.29,0,0,0,2.29-2.29V8.59l-8-8Z"
        />
        <path
          fill={
            props.disabled
              ? IconColors["icon-disabled"]
              : IconColors["icon-light-blue"]
          }
          d="M14.84.88V6.31a2.28,2.28,0,0,0,2.29,2.28h5.42Z"
        />
        <path
          fill={
            props.disabled
              ? IconColors["icon-disabled"]
              : IconColors["icon-dark-blue"]
          }
          d="M22.56,8.88H17.14a2.58,2.58,0,0,1-2.58-2.57V.88a.28.28,0,0,1,.17-.26.29.29,0,0,1,.32.06l7.72,7.71a.29.29,0,0,1,.06.31A.31.31,0,0,1,22.56,8.88ZM15.13,1.57V6.31a2,2,0,0,0,2,2h4.74Z"
        />
        <rect
          fill={
            props.disabled
              ? IconColors["icon-disabled"]
              : IconColors["icon-blue"]
          }
          x="1.14"
          y="11.17"
          width="20"
          height="9.14"
          rx="0.86"
        />
        <path
          fill={
            props.disabled
              ? IconColors["icon-disabled"]
              : IconColors["icon-dark-blue"]
          }
          d="M20.28,11.73a.29.29,0,0,1,.29.29v7.43a.28.28,0,0,1-.28.28L2,19.75a.29.29,0,0,1-.29-.29V12A.28.28,0,0,1,2,11.75l18.29,0m0-.57H2a.86.86,0,0,0-.85.86v7.43a.87.87,0,0,0,.86.86H20.29a.86.86,0,0,0,.85-.86V12a.87.87,0,0,0-.86-.86Z"
        />
        <path
          fill="white"
          d="M8.75,17.17H6.93l-.31.88h-1l1.64-4.6H8.37L10,18h-1Zm-.25-.73-.66-1.92-.67,1.92Z"
        />
        <path
          fill="white"
          d="M14.76,13.45,13.08,18H12l-1.69-4.58h1l1.26,3.64,1.25-3.65Z"
        />
        <path fill="white" d="M16.23,13.45V18h-.92V13.45Z" />
      </svg>
    </Blank>
  );
};

export default AVI;
