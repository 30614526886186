import React from "react";
import styled from "styled-components";

import type { InstructorsProps } from "../../../types";

const StyledInstructorsWrapper = styled.div``;

const Instructors: React.FC<InstructorsProps> = (props) => {
  return (
    <StyledInstructorsWrapper>
      {props.instructors.length > 1 ? (
        <h4>Instructors</h4>
      ) : (
        <h4>Instructor</h4>
      )}
      {props.instructors.map((instructor, index) => {
        return (
          <ul key={index}>
            <li>Facilitator: {instructor.instructor}</li>
            <li>
              Email: <a href={`mailto:${instructor.mail}`}>{instructor.mail}</a>
            </li>
          </ul>
        );
      })}
    </StyledInstructorsWrapper>
  );
};

export default Instructors;
