import React, { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import CreationNavButton from "../ui-kit/CreationNavButton/CreationNavButton";
import styled from "styled-components";

interface SyllabusEditorSidebarItemProps {
  text: string;
  added: boolean;
  onClick?: () => void;
  moveSidebarItemHandler: (dragIndex: number, hoverIndex: number) => void;
  index: number;
  id: string;
}

const DropTargetAboveStyle = styled.div`
  position: absolute;
  top: -5px;
  left: -5px;
  right: -5px;
  border: 1px solid #193da9;
`;

const DropTargetBelowStyle = styled.div`
  position: absolute;
  bottom: -5px;
  left: -5px;
  right: -5px;
  border: 1px solid #193da9;
`;

const SyllabusEditorSidebarItemDraggable: React.FC<
  SyllabusEditorSidebarItemProps
> = (props) => {
  const ref = useRef<HTMLDivElement>(null);

  interface DragItem {
    index: number;
    id: string;
    type: string;
  }

  const [{ isOver, isAbove, isBelow }, drop] = useDrop({
    accept: "syllabus-sidebar-item",
    collect(monitor) {
      const dragIndex = monitor.getItem<DragItem | undefined>()?.index ?? -1;
      return {
        isOver: monitor.isOver(),
        isAbove: dragIndex > props.index,
        isBelow: dragIndex < props.index,
      };
    },
    //@ts-ignore
    drop(item: DragItem) {
      props.moveSidebarItemHandler(item.index, props.index);
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: "syllabus-sidebar-item",
    item: { id: props.id, index: props.index },
    collect: (monitor: any) => {
      return {
        isDragging: monitor.isDragging(),
      };
    },
  });

  drag(drop(ref));
  return (
    <div
      ref={ref}
      style={{ opacity: `${isDragging ? 0 : 1}`, position: "relative" }}
    >
      {isOver && isAbove ? <DropTargetAboveStyle /> : null}

      <CreationNavButton
        onClick={props.onClick}
        text={props.text}
        added={props.added}
      />
      {isOver && isBelow ? <DropTargetBelowStyle /> : null}
    </div>
  );
};

export default SyllabusEditorSidebarItemDraggable;
