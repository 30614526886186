import React from "react";
import styled from "styled-components";
import clsx from "clsx";
import Elipse from "../../../assets/icons/Elipse";
import { Keyboard } from "grommet";

interface ModalNavbarItemProps {
  text: string;
  enabled: boolean;
  error?: boolean;
  check?: boolean;
  onClick?: () => void;
}

const ModalNavbarItemStyled = styled.div`
  font-family: Poppins;
  font-size: 15px;
  color: #030b1d;
  margin: 2px 0;
  display: flex;
  align-items: center;

  &:hover,
  :focus-visible {
    cursor: pointer;
    text-decoration: underline;
    & .error {
      text-decoration: underline;
    }

    & .bar {
      background: #c3d3ff;
    }
  }

  &:active {
    font-weight: 600;

    & .bar {
      background: #c3d3ff;
    }
  }

  .bar {
    width: 5px;
    height: 32px;
    background: #dde6ff;
    content: " ";
  }

  &.enabled {
    text-decoration-line: underline;
    color: #0d3af8;
    font-weight: 600;
  }
  &.enabled .bar {
    background: #0d3af8 !important;
  }
  &.enabled .error {
    text-decoration-line: underline;
    color: #e51144;
  }

  .text {
    margin-left: 14px;
  }

  .dot {
    color: #a5aec1;
    margin-left: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;

    &.error {
      color: #e51144;
    }
  }

  .check {
    margin-left: 5px;
  }
`;

const ModalNavbarItem: React.FC<ModalNavbarItemProps> = (props) => {
  return (
    <Keyboard onEnter={() => props.onClick?.()}>
      <ModalNavbarItemStyled
        className={clsx({
          enabled: props.enabled,
        })}
        onClick={() => props.onClick?.()}
        tabIndex={1}
      >
        <div className="bar" />
        <div className="text">{props.text}</div>
        {(props.check || props.error) && (
          <div
            className={clsx({
              dot: true,
              error: props.error,
            })}
          >
            <Elipse size="8px" />
            {props.error && <div className="check">Check</div>}
          </div>
        )}
      </ModalNavbarItemStyled>
    </Keyboard>
  );
};

export default ModalNavbarItem;
