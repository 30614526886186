import React from "react";
import { FormField, TextInput as TextInputGrommet } from "grommet";

interface TextInputProps {
  value: string | number;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: () => void;
  a11yTitle: string;
  placeholder?: string;
  type?: "text" | "password" | "number";
  error?: string;
  margin?: object | string;
  label?: string;
  autoFocus?: boolean;
}

const TextInput: React.FC<TextInputProps> = (props) => {
  return (
    <FormField
      margin={props.margin ? props.margin : { vertical: "none" }}
      error={props.error}
      label={props.label ? props.label : ""}
    >
      <TextInputGrommet
        autoFocus={props.autoFocus}
        placeholder={props.placeholder}
        value={props.value}
        a11yTitle={props.a11yTitle}
        onChange={props.onChange}
        type={props.type}
        focusIndicator={true}
        tabIndex={2}
        onFocus={() => props.onFocus?.()}
      />
    </FormField>
  );
};

export default TextInput;
