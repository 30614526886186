import { ExtendedFirestoreInstance } from "react-redux-firebase";
import _ from "lodash";
import { Syllabus } from "../model/Syllabus";
import firebase from "firebase/app";

export function transformValues(
  firebaseObject: any,
  condition: (key: string, value: any) => boolean,
  transformation: (value: any) => any
) {
  if (!firebaseObject) {
    return firebaseObject;
  }

  for (const [key, value] of Object.entries(firebaseObject)) {
    // covert items inside array
    if (value && Array.isArray(value)) {
      firebaseObject[key] = value.map((item) =>
        transformValues(item, condition, transformation)
      );
    }

    // convert inner objects
    if (value && typeof value === "object") {
      firebaseObject[key] = transformValues(value, condition, transformation);
    }

    // convert simple properties
    // @ts-ignore
    if (value && condition(key, value)) {
      firebaseObject[key] = transformation(value);
    }
  }
  return firebaseObject;
}

export function convertFbTimestampToDate(firebaseObject: any) {
  if (!firebaseObject) {
    return null;
  }

  for (const [key, value] of Object.entries(firebaseObject)) {
    // covert items inside array
    if (value && Array.isArray(value)) {
      firebaseObject[key] = value.map((item) => convertFbTimestampToDate(item));
    }

    // convert inner objects
    if (value && typeof value === "object") {
      firebaseObject[key] = convertFbTimestampToDate(value);
    }

    // convert simple properties
    // @ts-ignore
    if (value && value.hasOwnProperty("seconds")) {
      firebaseObject[key] = (value as firebase.firestore.Timestamp).toDate();
    }
  }
  return firebaseObject;
}

export function getValuesByKey(
  firebaseObject: any,
  keyToSearch: string
): string[] {
  if (!firebaseObject) {
    return [];
  }

  const array = [];
  for (const [key, value] of Object.entries(firebaseObject)) {
    // @ts-ignore
    if (value && key === keyToSearch) {
      // @ts-ignore
      return [value];
    }

    // covert items inside array
    if (value && Array.isArray(value)) {
      value.flatMap((item) => getValuesByKey(item, keyToSearch));
    }

    // convert inner objects
    if (value && typeof value === "object") {
      array.push(...getValuesByKey(value, keyToSearch));
    }
  }
  return array;
}

export function convertContentRef(firebaseObject: any, docRefList: any[]) {
  if (!firebaseObject) {
    return null;
  }

  for (const [key, value] of Object.entries(firebaseObject)) {
    // covert items inside array
    if (value && Array.isArray(value)) {
      firebaseObject[key] = value.map((item) =>
        convertContentRef(item, docRefList)
      );
    }

    // convert inner objects
    if (value && typeof value === "object") {
      firebaseObject[key] = convertContentRef(value, docRefList);
    }

    // convert simple properties
    // @ts-ignore
    if (value && value.hasOwnProperty("contentRef")) {
      const docRef = docRefList.find(
        // @ts-ignore
        (item) => item.docHash === value.contentRef
      );
      // @ts-ignore
      value.contentUrl = docRef!.docUrl;
    }
  }
  return firebaseObject;
}

export async function searchDocRefsByContestRefs(
  contentRefList: string[],
  firestore: ExtendedFirestoreInstance
): Promise<any[]> {
  if (contentRefList.length === 0) {
    return [];
  }

  const chunks = _.chunk(contentRefList, 10);
  const results: any[] = [];
  for (const chunk of chunks) {
    try {
      const result = await firestore
        .collection("docref")
        .where("docHash", "in", chunk)
        .get();

      results.push(...(result.docs.map((item) => item.data()) as any[]));
    } catch (e) {
      console.error(e);
    }
  }

  return results;
}

export async function getSyllabusById(
  id: string,
  firestore: ExtendedFirestoreInstance
): Promise<Syllabus> {
  let result: Syllabus;
  const syllabus = await firestore.collection("syllabi").doc(id).get();
  result = convertFbTimestampToDate(syllabus.data()) as Syllabus;
  return result;
}
