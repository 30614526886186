import React from "react";
import SnackbarLists from "./SnackbarLists";

export const LayoutNoTopbar: React.FC = (props) => {
  return (
    <div style={{ minHeight: "100%" }}>
      {props.children}
      <SnackbarLists />
    </div>
  );
};
