import React from "react";
import { Box, Card, CardBody, Text } from "grommet";
import styled from "styled-components";
import CheckBox from "../CheckBox/CheckBox";
import TextButton from "../TextButton/TextButton";
import Close from "../../../assets/icons/Close";

interface MaterialCardProps {
  id: string;
  title: string;
  author: string;
  image?: string;
  added: boolean;
  onAdd?: (added: boolean) => void;
  openedAt?: Date;
  onClick: () => void;
  className?: string;
}

const StyledCard = styled(Card)`
  box-shadow: none;
  border-bottom: 1px solid #d0dcf1;
  border-radius: 0;

  & .iframe {
    width: 495px;
  }
`;

const Title = styled(Text)`
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  color: #030b1d;
`;

const Subtitle = styled(Text)`
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  color: #474e61;
  padding: 10px 0 20px 0;
`;

const MaterialSearchVideoPreviewCard: React.FC<MaterialCardProps> = (props) => {
  return (
    <StyledCard className={props.className} background={{ color: "white" }}>
      <CardBody pad="small">
        <Box direction="row">
          <div className="iframe">
            <iframe
              title={`${props.title}`}
              width="495"
              height="380"
              src={`https://www.youtube.com/embed/${props.id}`}
              frameBorder="0"
              allowFullScreen
            />
          </div>
          <Box justify="between" width="100%" pad="0 27px">
            <Box>
              <Title>{props.title}</Title>
              <Subtitle>{props.author}</Subtitle>
              <CheckBox
                checked={props.added}
                onChange={(event) => props.onAdd?.(event.target.checked)}
                label="Add to Activity"
              />
            </Box>
            <div>
              <TextButton icon={<Close />} onClick={() => props.onClick()}>
                Close Video
              </TextButton>
            </div>
          </Box>
        </Box>
      </CardBody>
    </StyledCard>
  );
};

export default MaterialSearchVideoPreviewCard;
