import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";

const exportToPDF = async (fileName: string, element: HTMLElement) => {
  html2canvas(element).then(function (canvas) {
    const imgWidth = 210;
    const pageHeight = 290;
    const imgHeight = (canvas.height * imgWidth) / canvas.width;
    const doc = new jsPDF("p", "mm");
    const pageData = canvas.toDataURL("image/jpeg", 1.0);
    const imgData = encodeURIComponent(pageData);
    let heightLeft = imgHeight;
    let position = 0;

    doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
    doc.setLineWidth(5);
    doc.setDrawColor(255, 255, 255);
    doc.rect(0, 0, 210, 295);
    heightLeft -= pageHeight;

    while (heightLeft >= 0) {
      position = heightLeft - imgHeight;
      doc.addPage();
      doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
      doc.setLineWidth(5);
      doc.setDrawColor(255, 255, 255);
      doc.rect(0, 0, 210, 295);
      heightLeft -= pageHeight;
    }
    doc.save(fileName);
  });
};

export default exportToPDF;
