import React from "react";

interface SyllabusViewerContextProps {
  publicMode?: boolean;
}

export const SyllabusViewerContext =
  React.createContext<SyllabusViewerContextProps>({
    publicMode: false,
  });

const SyllabusViewerContextProvider: React.FC<SyllabusViewerContextProps> = ({
  children,
  publicMode,
}) => {
  return (
    <SyllabusViewerContext.Provider
      value={{
        publicMode: publicMode,
      }}
    >
      {children}
    </SyllabusViewerContext.Provider>
  );
};

export default SyllabusViewerContextProvider;
