import React from "react";
import styled from "styled-components";
import RoundedButton from "../../ui-kit/RoundedButton/RoundedButton";
import { Box } from "grommet";

interface OneWeekPlanHeaderProps {
  onClick: () => void;
}

const StyledOneWeekPlanHeader = styled.div`
  margin: 30px 35px 0 35px;
`;

const PrimaryText = styled.div`
  font-family: "Poppins";
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #030b1d;
`;

const SecondaryText = styled.div`
  font-family: "Open Sans";
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #474e61;
`;

const OneWeekPlanHeader: React.FC<OneWeekPlanHeaderProps> = (props) => {
  return (
    <StyledOneWeekPlanHeader>
      <Box justify="between" direction="row" align="center">
        <div>
          <PrimaryText>
            This is how your students will see your Syllabus.
          </PrimaryText>
          <SecondaryText>
            To continue editing and adding weeks to your schedule, select
            ‘Continue Editing Syllabus’
          </SecondaryText>
        </div>
        <div>
          <RoundedButton
            type="primary"
            variant="blue"
            onClick={() => props.onClick()}
          >
            Continue Editing
          </RoundedButton>
        </div>
      </Box>
    </StyledOneWeekPlanHeader>
  );
};

export default OneWeekPlanHeader;
