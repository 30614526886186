import React from "react";
import styled from "styled-components";

import type { DescriptionProps } from "../../../types";

const StyledDescriptionWrapper = styled.div``;

const Description: React.FC<DescriptionProps> = (props) => {
  return (
    <StyledDescriptionWrapper>
      <h4>Description</h4>
      <div>{props.description}</div>
    </StyledDescriptionWrapper>
  );
};

export default Description;
