import React, { Fragment } from "react";
import {
  CourseTitle,
  CustomSection,
  getSectionTypeLabel,
  Section,
  SectionType,
  Syllabus,
} from "../../model/Syllabus";
import { Anchor, Sidebar, Text, Image, Box } from "grommet";
import styled from "styled-components";
import truncate from "../../utils/truncate";

const StyledSidebar = styled(Sidebar)`
  height: 100%;
  width: 19em;
  z-index: 1;
  position: fixed;
  flex: auto;
  top: 0;
  left: 0;
  padding-left: 40px;
  padding-right: 40px;
  overflow-x: hidden;
  background: #f5f8fa;
  box-shadow: 6px 0 15px rgba(7, 26, 67, 0.1);
`;

const CourseTitleText = styled(Text)`
  font-family: Open Sans;
  font-size: 14px;
  color: #474e61;
  height: 19px;
  padding-bottom: 10px;
`;

const TocHeader = styled(Text)`
  font-family: Open Sans;
  font-size: 20px;
  font-weight: 700;
  color: #193da9;
  height: 27px;
  padding-top: 20px;
  flex: none;
`;

/**
 * The standard behavior for scroll on a mac is non-intuitive,
 * so we added css to make the scrollbar visible upon hover.
 */
const TocLinksContainer = styled.div`
  display: flex;
  height: 80%;
  flex-direction: column;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }
  :hover {
    ::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: rgba(0, 0, 0, 0.5);
      -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    }
  }
`;

const TocFooter = styled.div`
  border-top: 1px solid #d0dcf1;
  padding-top: 10px;
  position: absolute;
  bottom: 20px;
  flex: none;
`;

interface TocProps {
  syllabus: Syllabus;
  onClick: (path: number[]) => void;
}

const Toc: React.FC<TocProps> = ({ syllabus, onClick }) => {
  const courseTitle = syllabus.sections.find(
    (section) => section.type === "CourseTitle"
  ) as CourseTitle;
  const getTreeItem = (section: Section, path: number[]) => {
    switch (section.type) {
      case "CourseTitle":
        return;
      case "Policies":
        return;
      case "RequiredTexts":
        return;
      case "CustomSection":
        return (
          <>
            <Anchor
              label={truncate((section as CustomSection).title as string, 20)}
              color="#030B1D"
              size="16px"
              margin={{ left: "20px", vertical: "5px" }}
              href={"#section-" + path[0]}
            />
          </>
        );
      case "Schedule":
        return (
          <div>
            <Anchor
              label={getSectionTypeLabel(section.type)}
              color="#030B1D"
              size="16px"
              margin={{ left: "20px", vertical: "5px" }}
              onClick={() => onClick(path)}
              href={"#section-" + path[0]}
            />
            <br />
            {section.modules.map((module, index) => {
              const modulePath = [...path, index];
              return (
                <Fragment key={index}>
                  <Anchor
                    key={index}
                    color="#030B1D"
                    size="16px"
                    margin={{ left: "20px", vertical: "5px" }}
                    label={module.title}
                    onClick={() => onClick(modulePath)}
                    href={"#section-" + path[0]}
                  />
                  <br />
                </Fragment>
              );
            })}
          </div>
        );
      default:
        return (
          <>
            <Anchor
              label={getSectionTypeLabel(section.type as SectionType)}
              color="#030B1D"
              size="16px"
              margin={{ left: "20px", vertical: "5px" }}
              href={"#section-" + path[0]}
            />
          </>
        );
    }
  };

  return (
    <StyledSidebar>
      <CourseTitleText>
        {courseTitle.courseNumber + " - " + courseTitle.title}
      </CourseTitleText>
      <TocHeader>Syllabus Sections</TocHeader>
      <TocLinksContainer>
        {syllabus.sections.map((section, index) =>
          getTreeItem(section, [index])
        )}
      </TocLinksContainer>
      <Box align="center">
        <TocFooter>
          <Image src="/images/logos/logo.svg" alt="BlendED" width="125px" />
        </TocFooter>
      </Box>
    </StyledSidebar>
  );
};

export default Toc;
