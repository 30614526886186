import React from "react";
import { Skills } from "../../../model/Syllabus";
import H4 from "../../viewer/customization/H4";
import Paragraph from "../../viewer/customization/Paragraph";
import styled from "styled-components";

interface SkillsSectionProps {
  section: Skills;
}

const StyledParagraph = styled(Paragraph)`
  color: #030b1d;
`;

// Skills viewer component
const SkillsSection: React.FC<SkillsSectionProps> = (props) => {
  const section = props.section;
  return (
    <>
      <H4 a11yTitle={"Skills Section"}>Skill(s)</H4>
      {section.skills.map((skill, index) => {
        return (
          <div key={"skill-" + index}>
            <StyledParagraph>{skill.body}</StyledParagraph>
          </div>
        );
      })}
    </>
  );
};

export default SkillsSection;
