import { CanvasActions, CanvasActionType } from "../types/CanvasTypes";

export const setCanvasTokens = (
  accessToken?: string,
  refreshToken?: string,
  userId?: string | undefined,
  scopes?: string[]
): CanvasActions => ({
  type: CanvasActionType.SetTokensCanvas,
  payload: {
    accessToken,
    refreshToken,
    userId,
    scopes,
  },
});

export const clearTokens = (): CanvasActions => ({
  type: CanvasActionType.ClearTokens,
  payload: undefined,
});
