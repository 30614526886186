import React from "react";
import { Blank, IconProps } from "grommet-icons";
import { IconColors } from "../../theme/GlobalTheme";
import { ChildrenIconProps } from "./FileTypeIcon";

const JPG: React.FC<IconProps & ChildrenIconProps> = (props) => {
  return (
    <Blank viewBox="0 0 39 42" {...props} color="plain">
      <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
      >
        <path
          fill="white"
          d="M14.79.61H7.93A2.29,2.29,0,0,0,5.65,2.91V21.19A2.29,2.29,0,0,0,8,23.48H20.52a2.29,2.29,0,0,0,2.29-2.29V8.61Z"
        />
        <path
          fill={
            props.disabled
              ? IconColors["icon-disabled"]
              : IconColors["icon-dark-blue"]
          }
          d="M14.56,1.19l7.67,7.66V21.18a1.72,1.72,0,0,1-1.71,1.72H8a1.72,1.72,0,0,1-1.72-1.71V2.9A1.71,1.71,0,0,1,7.93,1.19h6.63m.23-.58H7.93A2.29,2.29,0,0,0,5.65,2.91V21.19A2.29,2.29,0,0,0,8,23.48H20.52a2.29,2.29,0,0,0,2.29-2.29V8.61l-8-8Z"
        />
        <path
          fill={
            props.disabled
              ? IconColors["icon-disabled"]
              : IconColors["icon-light-blue"]
          }
          d="M14.79.9V6.33a2.28,2.28,0,0,0,2.28,2.28h5.43Z"
        />
        <path
          fill={
            props.disabled
              ? IconColors["icon-disabled"]
              : IconColors["icon-dark-blue"]
          }
          d="M22.51,8.89H17.08a2.57,2.57,0,0,1-2.57-2.57V.9A.28.28,0,0,1,15,.7l7.72,7.71a.3.3,0,0,1,.07.31A.3.3,0,0,1,22.51,8.89Zm-7.43-7.3V6.33a2,2,0,0,0,2,2h4.74Z"
        />
        <rect
          fill={
            props.disabled
              ? IconColors["icon-disabled"]
              : IconColors["icon-blue"]
          }
          x="0.52"
          y="11.19"
          width="20"
          height="9.14"
          rx="0.86"
        />
        <path
          fill={
            props.disabled
              ? IconColors["icon-disabled"]
              : IconColors["icon-dark-blue"]
          }
          d="M19.66,11.75a.29.29,0,0,1,.28.29v7.43a.29.29,0,0,1-.29.28l-18.28,0a.29.29,0,0,1-.29-.29V12.05a.28.28,0,0,1,.29-.28l18.29,0m0-.57H1.37a.87.87,0,0,0-.86.86v7.43a.86.86,0,0,0,.86.86l18.28,0a.85.85,0,0,0,.86-.85V12a.85.85,0,0,0-.85-.86Z"
        />
        <path
          fill="white"
          d="M7.25,13.51v3.22a1.35,1.35,0,0,1-.39,1,1.41,1.41,0,0,1-1,.38,1.52,1.52,0,0,1-1.07-.38,1.4,1.4,0,0,1-.4-1.07h.92a.7.7,0,0,0,.14.46.47.47,0,0,0,.39.16.46.46,0,0,0,.38-.15.66.66,0,0,0,.13-.44V13.51Z"
        />
        <path
          fill="white"
          d="M11.49,15.62a1.29,1.29,0,0,1-.54.52,2,2,0,0,1-.94.2H9.24V18.1H8.32V13.51H10a2.11,2.11,0,0,1,.91.18,1.32,1.32,0,0,1,.56.51,1.47,1.47,0,0,1,.19.73A1.42,1.42,0,0,1,11.49,15.62Zm-1-.2a.66.66,0,0,0,.18-.49c0-.45-.25-.67-.75-.67H9.24v1.33H10A.86.86,0,0,0,10.54,15.42Z"
        />
        <path
          fill="white"
          d="M15.51,14.89a1.07,1.07,0,0,0-.43-.44,1.36,1.36,0,0,0-.65-.15,1.43,1.43,0,0,0-.72.18,1.2,1.2,0,0,0-.49.53,1.63,1.63,0,0,0-.18.78,1.73,1.73,0,0,0,.18.81,1.32,1.32,0,0,0,.5.52,1.55,1.55,0,0,0,.75.19,1.24,1.24,0,0,0,1.3-1.06H14.2v-.71h2.48v.8a2.15,2.15,0,0,1-.39.89,2.29,2.29,0,0,1-.78.66,2.37,2.37,0,0,1-1.07.24,2.53,2.53,0,0,1-1.2-.29A2.12,2.12,0,0,1,12.4,17a2.38,2.38,0,0,1-.31-1.2,2.36,2.36,0,0,1,.31-1.22,2.16,2.16,0,0,1,.84-.83,2.58,2.58,0,0,1,2.54.07,2,2,0,0,1,.79,1.07Z"
        />
      </svg>
    </Blank>
  );
};

export default JPG;
