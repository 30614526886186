import { PendingAssignment } from "../../../model/Syllabus";

export enum CommonActionType {
  SetEditMode = "SetEditMode",
  AddSnackbarMessage = "AddSnackbarMessage",
  RemoveSnackbarMessage = "RemoveSnackbarMessage",
  SetLoadingContent = "SetLoadingContent",
  AddAssignment = "AddAssignment",
  RemoveAssignment = "RemoveAssignment",
  SetRedirect = "SetRedirect",
  DeleteRedirect = "DeleteRedirect",
}

export interface SetEditMode {
  type: typeof CommonActionType.SetEditMode;
  editionMode: boolean;
}

export interface SnackbarMessage {
  id: string;
  message: string;
  type: "unknown" | "normal" | "warning" | "critical";
}

export interface AddSnackbarMessage {
  type: typeof CommonActionType.AddSnackbarMessage;
  payload: SnackbarMessage;
}

export interface RemoveSnackbarMessage {
  type: typeof CommonActionType.RemoveSnackbarMessage;
  payload: string;
}

export interface AddAssignment {
  type: typeof CommonActionType.AddAssignment;
  payload: PendingAssignment;
}

export interface RemoveAssignment {
  type: typeof CommonActionType.RemoveAssignment;
  payload: string;
}

export interface SetLoadingContent {
  type: typeof CommonActionType.SetLoadingContent;
  payload: boolean;
}

export interface SetRedirect {
  type: typeof CommonActionType.SetRedirect;
  payload: {
    redirectUrl: string;
    expiration: Date;
  };
}

export interface DeleteRedirect {
  type: typeof CommonActionType.DeleteRedirect;
  payload: undefined;
}

export type CommonActions =
  | SetEditMode
  | AddSnackbarMessage
  | RemoveSnackbarMessage
  | SetLoadingContent
  | AddAssignment
  | RemoveAssignment
  | SetRedirect
  | DeleteRedirect;
