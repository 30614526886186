export type ButtonSize = "small" | "regular" | "hero";

export type ButtonVariant = "blue" | "dark-blue" | "red";

export type ButtonType = "primary" | "secondary" | "tertiary";

export type FileType =
  | "PDF"
  | "JPG"
  | "PNG"
  | "DOC"
  | "XLS"
  | "MP3"
  | "MP4"
  | "MPG"
  | "GIF"
  | "PPT"
  | "PPTX"
  | "DOCX"
  | "AVI"
  | "AI"
  | "BMP"
  | "CDR"
  | "DEFAULT"
  | "EPS"
  | "EPUB"
  | "JPEG"
  | "MOV"
  | "MPEG"
  | "PSD"
  | "RAR"
  | "SVG"
  | "TIG"
  | "TXT"
  | "VMA"
  | "WAV"
  | "ZIP"
  | "TIF"
  | "GoogleBook"
  | "Video"
  | "GoogleCustomSearchResult";

export const IconColors = {
  "icon-disabled": "rgb(157, 162, 172)",
  "icon-dark-blue": "rgb(25, 61, 169)",
  "icon-light-blue": "rgb(165, 174, 193)",
  "icon-blue": "rgb(13, 58, 248)",
  "icon-white": "white",
};

export type LinkType = "default" | "activity" | "read-more";

export type TextEditButtonType =
  | "ql-bold"
  | "ql-italic"
  | "ql-underline"
  | "ql-strike"
  | "ql-list"
  | "ql-link"
  | "ql-clean";

export type TextEditButtonValue = "ordered" | "bullet";
