import { useCallback, useEffect, useState } from "react";
import _ from "lodash";

import SyllabusTemplateRepository from "../repositories/SyllabusTemplateRepository";
import { SyllabusTemplate } from "../model/SyllabusTemplate";

export const useGetTemplateList = (sourceName: string) => {
  const [syllabi, setSyllabi] = useState<SyllabusTemplate[] | undefined>(
    undefined
  );
  const [lastKey, setLastKey] = useState<Date | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [lastPage, setLastPage] = useState(false);

  const getMySyllabi = useCallback(
    async (searchTerm?: string) => {
      setIsLoading(true);
      try {
        const repository = new SyllabusTemplateRepository();
        const { lastKey, SyllabusTemplateList, isLastPage } =
          await repository.all(sourceName.toUpperCase(), searchTerm);

        setLastPage(isLastPage);
        setSyllabi(SyllabusTemplateList);
        setLastKey(lastKey);
      } catch (error) {
        console.error("Error", error);
        setErrorMessage(error.message);
      } finally {
        setIsLoading(false);
      }
    },
    [sourceName]
  );

  const getMySyllabiNextBatch = useCallback(
    async (lastKeyFromBatch: Date, searchTerm?: string) => {
      setIsLoading(true);
      try {
        const repository = new SyllabusTemplateRepository();
        const { lastKey, SyllabusTemplateList, isLastPage } =
          await repository.getNextBatch(
            lastKeyFromBatch,
            sourceName.toUpperCase(),
            searchTerm
          );

        setLastPage(isLastPage);
        setSyllabi((syllabi) => {
          const o = _.cloneDeep(syllabi) as SyllabusTemplate[];
          o.push(...SyllabusTemplateList);
          return o;
        });
        setLastKey(lastKey);
      } catch (error) {
        console.error("Error", error);
        setErrorMessage(error.message);
      } finally {
        setIsLoading(false);
      }
    },
    [sourceName]
  );

  useEffect(() => {
    getMySyllabi();
  }, [getMySyllabi]);

  return {
    syllabi,
    lastKey,
    isLoading,
    lastPage,
    errorMessage,
    getMySyllabi,
    getMySyllabiNextBatch,
  } as const;
};
