import React from "react";
import { Blank, IconProps } from "grommet-icons";

const OrderedList: React.FC<IconProps> = (props) => {
  return (
    <Blank viewBox="0 0 20 18" {...props} color="plain">
      <svg
        width="20"
        height="18"
        viewBox="0 0 20 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect x="5" y="2" width="15" height="2" rx="1" fill="#030B1D" />
        <rect x="5" y="8" width="15" height="2" rx="1" fill="#030B1D" />
        <rect x="5" y="14" width="15" height="2" rx="1" fill="#030B1D" />
        <path
          d="M3.07617 5H0.649414V4.36523H1.41602V2.44385H0.649414V1.85059C0.766602 1.85059 0.878906 1.84408 0.986328 1.83105C1.09375 1.81641 1.18327 1.79199 1.25488 1.75781C1.33952 1.71712 1.40299 1.66423 1.44531 1.59912C1.48763 1.53402 1.51204 1.45264 1.51855 1.35498H2.32666V4.36523H3.07617V5Z"
          fill="#030B1D"
        />
        <path
          d="M3.30566 11H0.45166V10.3994C0.669759 10.2415 0.887858 10.0739 1.10596 9.89648C1.32568 9.71908 1.50146 9.56608 1.6333 9.4375C1.83024 9.24707 1.97021 9.08105 2.05322 8.93945C2.13623 8.79785 2.17773 8.65788 2.17773 8.51953C2.17773 8.35352 2.12402 8.22575 2.0166 8.13623C1.91081 8.04508 1.75781 7.99951 1.55762 7.99951C1.40788 7.99951 1.25 8.03044 1.08398 8.09229C0.919596 8.15413 0.766602 8.23307 0.625 8.3291H0.546875V7.521C0.662435 7.47054 0.83252 7.42008 1.05713 7.36963C1.28337 7.31917 1.5096 7.29395 1.73584 7.29395C2.19157 7.29395 2.53906 7.38997 2.77832 7.58203C3.01758 7.77246 3.13721 8.04264 3.13721 8.39258C3.13721 8.62207 3.07943 8.84017 2.96387 9.04688C2.84993 9.25358 2.67497 9.4668 2.43896 9.68652C2.29085 9.82324 2.14193 9.94938 1.99219 10.0649C1.84245 10.1789 1.73584 10.2586 1.67236 10.3042H3.30566V11Z"
          fill="#030B1D"
        />
        <path
          d="M2.94922 15.2959C3.02897 15.3643 3.09245 15.444 3.13965 15.5352C3.18685 15.6263 3.21045 15.7476 3.21045 15.8989C3.21045 16.0698 3.17627 16.2293 3.10791 16.3774C3.04118 16.5256 2.93783 16.6525 2.79785 16.7583C2.66113 16.8608 2.5 16.9398 2.31445 16.9951C2.13053 17.0488 1.90674 17.0757 1.64307 17.0757C1.34196 17.0757 1.08317 17.0521 0.866699 17.0049C0.651855 16.9577 0.476888 16.9048 0.341797 16.8462V16.0479H0.437012C0.576986 16.1325 0.743815 16.2057 0.9375 16.2676C1.13281 16.3294 1.31104 16.3604 1.47217 16.3604C1.56657 16.3604 1.66911 16.353 1.77979 16.3384C1.89046 16.3221 1.98405 16.2879 2.06055 16.2358C2.12077 16.1951 2.16878 16.1463 2.20459 16.0894C2.2404 16.0308 2.2583 15.9469 2.2583 15.8379C2.2583 15.7321 2.23389 15.6507 2.18506 15.5938C2.13623 15.5352 2.07194 15.4937 1.99219 15.4692C1.91243 15.4432 1.81641 15.4294 1.7041 15.4277C1.5918 15.4245 1.48763 15.4229 1.3916 15.4229H1.19141V14.7734H1.39893C1.52588 14.7734 1.63818 14.7694 1.73584 14.7612C1.8335 14.7531 1.9165 14.7344 1.98486 14.7051C2.05648 14.6742 2.11019 14.6335 2.146 14.583C2.1818 14.5309 2.19971 14.4552 2.19971 14.356C2.19971 14.2827 2.18099 14.2241 2.14355 14.1802C2.10612 14.1346 2.05892 14.0988 2.00195 14.0728C1.93848 14.0435 1.86361 14.0239 1.77734 14.0142C1.69108 14.0044 1.61702 13.9995 1.55518 13.9995C1.40218 13.9995 1.23617 14.0264 1.05713 14.0801C0.878092 14.1322 0.704753 14.2078 0.537109 14.3071H0.446777V13.5186C0.580241 13.4648 0.761719 13.4136 0.991211 13.3647C1.2207 13.3143 1.45345 13.2891 1.68945 13.2891C1.91895 13.2891 2.11995 13.3094 2.29248 13.3501C2.46501 13.3892 2.60742 13.4421 2.71973 13.5088C2.85319 13.5885 2.95247 13.6854 3.01758 13.7993C3.08268 13.9132 3.11523 14.0467 3.11523 14.1997C3.11523 14.4015 3.05257 14.5822 2.92725 14.7417C2.80192 14.8996 2.63672 15.0005 2.43164 15.0444V15.0786C2.51465 15.09 2.60254 15.1128 2.69531 15.147C2.78809 15.1812 2.87272 15.2308 2.94922 15.2959Z"
          fill="#030B1D"
        />
      </svg>
    </Blank>
  );
};
export default OrderedList;
