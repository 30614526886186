import React from "react";
import { Blank, IconProps } from "grommet-icons";

const DropdownArrow: React.FC<IconProps> = (props) => {
  return (
    <Blank viewBox="0 0 24 24" {...props} color="plain">
      <svg
        width="13"
        height="8"
        viewBox="0 0 13 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.54688 1.5L6.54688 6.5L11.5469 1.5"
          stroke="#2256F2"
          strokeWidth="2"
          strokeLinecap="round"
        />
      </svg>
    </Blank>
  );
};
export default DropdownArrow;
