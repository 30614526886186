import { Syllabus } from "../../../model/Syllabus";
import {
  OnboardingActions,
  OnboardingActionType,
} from "../types/OnboardingTypes";

export interface OnboardingState {
  tempSyllabus?: Syllabus;
}

const initialState: OnboardingState = {
  tempSyllabus: undefined,
};

export const OnboardingReducer = (
  state = initialState,
  action: OnboardingActions
): OnboardingState => {
  switch (action.type) {
    case OnboardingActionType.SaveTempSyllabus:
      return {
        ...state,
        tempSyllabus: action.payload,
      };
    case OnboardingActionType.DeleteTempSyllabus:
      return {
        ...state,
        tempSyllabus: undefined,
      };
    default:
      return state;
  }
};
