import React, { useRef } from "react";
import { Box, Grid } from "grommet";
import TextInput from "../../ui-kit/TextInput/TextInput";
import RichTextEditorWrapper from "../../ui-kit/RichTextEditor/RichTextEditorWrapper";
import styled from "styled-components";
import { Activity, FileResource } from "../../../model/Resource";
import _ from "lodash";
import useDelayedState from "../../../hooks/useDelayedState";
import Select, { SelectOption } from "../../ui-kit/Select/Select";
import { addDays } from "date-fns";
import RoundedButton from "../../ui-kit/RoundedButton/RoundedButton";
import Delete from "../../../assets/icons/Delete";
import { formatInTimeZone } from "../../../helpers/formatInTimeZone";
import { JSONContent } from "@tiptap/react";
import formatBytes from "../../../utils/bytesToMb";
import { RichTextEditorRef } from "../../ui-kit/RichTextEditor/Editor/RichTextEditor";
import useNoRenderRef from "../../../hooks/useNoRenderRef";

interface ActivityEditorProps {
  activity: Activity;
  startOfWeek?: Date;
  onChange?: (activity: Activity) => void;
  onClickSearchForMaterials?: (activity: Activity, index: number) => void;
  onDeleteActivity?: (activity: Activity) => void;
  index: number;
  className?: string;
  needsRegistration?: boolean;
  errors?: Record<string, string> | undefined;
}

const ActivityPill = styled.span`
  padding: 0 23px;
  background: #0d3af8;
  border-radius: 10px;
  font-family: "Poppins";
  font-weight: 500;
  font-size: 12px;
  text-align: center;
  color: #ffffff;
  width: fit-content;
`;

const ActivityEditor: React.FC<ActivityEditorProps> = (props) => {
  const [_activity, _setActivity, setUndelayedActivity] =
    useDelayedState<Activity>(
      { ...(props.activity as Activity) },
      props.onChange!
    );
  const editorRef = useRef<RichTextEditorRef>();
  const activityRef = useNoRenderRef(_activity);

  const generateAvailableDays = (): SelectOption[] => {
    const startDay = new Date(props.startOfWeek ?? new Date());
    const selectOptions = [];
    for (let i = 0; i < 7; i++) {
      const currentDay = addDays(startDay, i);
      selectOptions.push({
        key: formatInTimeZone(currentDay, "yyyy/MM/dd", "UTC"),
        label: formatInTimeZone(currentDay, "EEEE M/d", "UTC"),
      });
    }

    return selectOptions;
  };

  return (
    <div
      className={props.className}
      key={`activity-editor-index-${props.index}`}
    >
      <ActivityPill>Assignment #{props.index + 1}</ActivityPill>
      <Grid columns={["420px", "1/3"]} gap="small">
        <TextInput
          label="Assignment Name"
          required={true}
          value={_activity.title}
          placeholder={"Assignment Name"}
          error={props.errors?.[`activities[${props.index}].title`]}
          onChange={(event) => {
            if (event.target.value !== undefined) {
              _setActivity({
                ...activityRef.current,
                title: event.target.value,
              });
            }
          }}
        />
        <Select
          options={generateAvailableDays()}
          label="Due Date"
          onChange={(selected) => {
            const selectedDate = new Date(selected.key);
            _setActivity({ ...activityRef.current, dueDate: selectedDate });
          }}
          a11yTitle="Select Due Date"
          value={
            {
              key: formatInTimeZone(_activity.dueDate, "yyyy/MM/dd", "UTC"),
            } || generateAvailableDays()[0].label
          }
        />
      </Grid>
      <RichTextEditorWrapper
        id={`activity-index-${props.index}`}
        key={props.activity.id}
        value={_activity.body}
        onChange={(content) => {
          const newContent = content as JSONContent;
          _setActivity({
            ...activityRef.current,
            body: newContent,
          });
        }}
        editorRef={editorRef}
        placeholder="Describe this Assignment."
        uploadButton={{
          visible: true,
          disabled: props.needsRegistration,
          onUploadFile: (resource: FileResource) => {
            setUndelayedActivity((activity) => {
              const newActivity = _.cloneDeep(activity);
              const type = resource.contentType.split("/");
              const filetype = type?.[1].toUpperCase();
              const fileSize = formatBytes(resource.size);
              newActivity?.body?.content?.push({
                type: "file-button",
                attrs: {
                  id: resource.id,
                  name: resource.title,
                  type: filetype,
                  url: resource.url,
                  size: fileSize,
                },
              });
              editorRef.current?.forceRerender?.(newActivity.body);
              props.onChange?.(newActivity);
              return newActivity;
            });
          },
        }}
        searchMaterialsButton={{
          visible: true,
          onClick: () => {
            props.onClickSearchForMaterials?.(props.activity, props.index);
          },
        }}
      />
      <Box justify="end" align="end" pad="10px 0">
        <RoundedButton
          type="tertiary"
          variant="red"
          icon={<Delete />}
          onClick={() => props.onDeleteActivity?.(_activity)}
        >
          Delete Assignment
        </RoundedButton>
      </Box>
    </div>
  );
};

export default ActivityEditor;
