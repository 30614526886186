import React, { useContext } from "react";
import { TableRow as GrommetTableRow } from "grommet";
import styled from "styled-components";
import { SyllabusThemeContext } from "../../viewer/customization/SyllabusTheme";
import clsx from "clsx";

interface TableRowProps {
  className?: string;
  colspan?: number;
  tabIndex?: number;
  index?: number;
}

const StyledGrommetTableRow = styled(GrommetTableRow)<{
  oddColor?: string;
  evenColor?: string;
}>`
  &.odd-row .week-wrapper {
    background: ${(props) => props.oddColor ?? "#eff3ff"};
  }

  &.even-row .week-wrapper {
    background: ${(props) => props.evenColor ?? "#dde6ff"};
  }
`;

const TableRow: React.FC<TableRowProps> = (props) => {
  const { colorScheme } = useContext(SyllabusThemeContext);
  return (
    <StyledGrommetTableRow
      className={clsx({
        [props.className || ""]: true,
        "even-row": props.index! % 2 === 0,
        "odd-row": props.index! % 2 !== 0,
      })}
      tabIndex={props.tabIndex}
      oddColor={colorScheme?.tableCell1}
      evenColor={colorScheme?.tableCell2}
    >
      {props.children}
    </StyledGrommetTableRow>
  );
};

export default TableRow;
