import React from "react";
import { Box, Card, CardBody, CardFooter, Text } from "grommet";
import styled from "styled-components";
import CheckBox from "../CheckBox/CheckBox";
import TextButton from "../TextButton/TextButton";
import truncate from "../../../utils/truncate";
import OpenedPill from "../OpenedPill/OpenedPill";
import Open from "../../../assets/icons/Open";

interface MaterialCardProps {
  title: string;
  author: string;
  image?: string;
  added: boolean;
  onAdd?: (added: boolean) => void;
  openedAt?: Date;
  className?: string;
  onClick?: () => void;
}

const StyledCard = styled(Card)`
  box-shadow: none;
  border-bottom: 1px solid #d0dcf1;
  border-radius: 0;
`;

const Title = styled(Text)`
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  color: #030b1d;
`;

const Subtitle = styled(Text)`
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  color: #474e61;
`;

const BookCover = styled.img`
  height: 135px;
  max-width: 100px;
`;

const MaterialsSearchBookCard: React.FC<MaterialCardProps> = (props) => {
  return (
    <StyledCard className={props.className} background={{ color: "white" }}>
      <CardBody pad="small">
        <Box gap="small" align="left" direction="row">
          <BookCover src={props.image} alt="" />
          <Box justify="between">
            <Box>
              <Title>{truncate(props.title, 70)}</Title>
              <Subtitle>{props.author}</Subtitle>
            </Box>
            {props.openedAt && <OpenedPill date={props.openedAt} />}
          </Box>
        </Box>
      </CardBody>
      <CardFooter pad={{ horizontal: "medium", vertical: "small" }}>
        <Box direction="row" justify="between" width="100%">
          <CheckBox
            checked={props.added}
            onChange={(event) => props.onAdd?.(event.target.checked)}
            label="Add to Activity"
          />
          <TextButton icon={<Open />} onClick={() => props.onClick?.()}>
            Open Book
          </TextButton>
        </Box>
      </CardFooter>
    </StyledCard>
  );
};

export default MaterialsSearchBookCard;
