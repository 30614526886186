import { SectionValidator } from "./SectionValidator";
import { CourseDescription, isRichTextEditorEmpty } from "model/Syllabus";
import _ from "lodash";

export default class CourseDescriptionValidator extends SectionValidator {
  validate(section: CourseDescription) {
    const extraErrors: Record<string, string> = {};
    if (isRichTextEditorEmpty(section.description)) {
      extraErrors.description = "'Course Description' cannot be empty";
    }

    const sectionErrorItem = {
      section: section,
      errors: extraErrors,
    };

    return !_.isEmpty(sectionErrorItem.errors) ? sectionErrorItem : undefined;
  }
}
