import React from "react";
import { CourseDescription } from "../../../model/Syllabus";
import Container from "../../viewer/customization/Container";
import H4 from "../../viewer/customization/H4";
import RichTextEditorViewer from "../../ui-kit/RichTextEditor/Viewer/RichTextEditorViewer";
import { createHash } from "../../../helpers/createHash";

interface CourseDescriptionSectionProps {
  section: CourseDescription;
}

const CourseDescriptionSection: React.FC<CourseDescriptionSectionProps> = ({
  section,
}) => {
  return (
    <>
      <H4 a11yTitle={"Course Description"}>Course Description</H4>
      <Container>
        <RichTextEditorViewer
          id="course-description-viewer"
          value={section.description}
          key={createHash(JSON.stringify(section.description))}
        />
      </Container>
    </>
  );
};

export default CourseDescriptionSection;
