import React from "react";
import { Module, Schedule } from "../model/Syllabus";
import ScheduleTableWrapper from "./ui-kit/ScheduleTable/ScheduleTableWrapper";
import { Activity } from "../model/Resource";
import Container from "./viewer/customization/Container";
import H4 from "./viewer/customization/H4";

interface ScheduleViewerProps {
  /**
   * The array of modules which make up the schedule
   */
  schedule: Schedule;
  onClickWeek?: (week: Module) => void;
  onClickActivity?: (activity: Activity) => void;
}

/**
 * A component for viewing a syllabus schedule.  When a schedule section is present but empty, displays text instructing the user to click on the section to open the editor modal and begin creating their schedule.
 */
const ScheduleViewer: React.FC<ScheduleViewerProps> = (props) => {
  const schedule = props.schedule;
  return (
    <>
      <H4>Course Schedule</H4>
      <Container>
        <ScheduleTableWrapper
          schedule={schedule}
          onClick={(event) => event.stopPropagation()}
          onClickActivity={props.onClickActivity}
          onClickWeek={props.onClickWeek}
        />
      </Container>
    </>
  );
};

export default ScheduleViewer;
