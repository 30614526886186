import React from "react";
import CustomModal from "../modals/CustomModal";
import RoundedButton from "../ui-kit/RoundedButton/RoundedButton";
import { Box } from "grommet";
import styled from "styled-components";

interface ConfirmModalProps {
  opened: boolean;
  onClickConfirm?: () => void;
  onClickCancel?: () => void;
}

const MainText = styled.p`
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 25px;
  text-align: center;
  margin: 4px;
`;

const SecondaryText = styled.p`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  text-align: center;
  color: #5b616f;
  margin: 8px;
`;

const StyledRoundedButton = styled(RoundedButton)`
  width: 200px;
  padding: 0 !important;
`;

const ConfirmModal: React.FC<ConfirmModalProps> = (props) => {
  return (
    <CustomModal opened={props.opened} size="small">
      <div>
        <MainText>
          You left a syllabus in progress. <br />
          <b>
            Do you want to continue editing your syllabus or create a new one?
          </b>
        </MainText>
        <SecondaryText>
          If you create a new syllabus, your previous progress will be lost.
        </SecondaryText>
        <Box
          margin={{ top: "20px" }}
          align="center"
          gap="medium"
          direction="row"
        >
          <StyledRoundedButton
            type="secondary"
            onClick={() => props.onClickCancel?.()}
          >
            Continue Editing
          </StyledRoundedButton>

          <StyledRoundedButton
            type="primary"
            onClick={() => props.onClickConfirm?.()}
          >
            Create New
          </StyledRoundedButton>
        </Box>
      </div>
    </CustomModal>
  );
};

export default ConfirmModal;
