import React from "react";
import { CustomSection } from "../../../model/Syllabus";
import H4 from "../../viewer/customization/H4";
import Container from "../../viewer/customization/Container";
import RichTextEditorViewer from "../../ui-kit/RichTextEditor/Viewer/RichTextEditorViewer";
import { createHash } from "../../../helpers/createHash";

interface CustomSectionProps {
  section: CustomSection;
}

const CustomSectionSection: React.FC<CustomSectionProps> = ({ section }) => {
  return (
    <>
      <H4 a11yTitle={"Custom Section"}>{section.title}</H4>
      <Container>
        <RichTextEditorViewer
          value={section.body}
          key={createHash(JSON.stringify(section.body))}
        />
      </Container>
    </>
  );
};

export default CustomSectionSection;
