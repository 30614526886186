import React from "react";
import { Blank, IconProps } from "grommet-icons";

const ClearStyle: React.FC<IconProps> = (props) => {
  return (
    <Blank viewBox="0 0 24 24" {...props} color="plain">
      <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
      >
        <defs>
          <style>
            {
              ".cls-1{fill:none;stroke:#000417;stroke-linecap:round}.cls-2{fill:#000417}"
            }
          </style>
        </defs>
        <title>clear-style</title>
        <path className="cls-1" d="M13.5,13.5l4,4" />
        <path className="cls-1" d="M13.5,17.5l4-4" />
        <path className="cls-2" d="M14,6V7H10.64v9H9.37V7H6V6Z" />
      </svg>
    </Blank>
  );
};
export default ClearStyle;
