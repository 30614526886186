import React from "react";
import SquaredButton from "../ui-kit/SquaredButton/SquaredButton";
import CustomModal from "../modals/CustomModal";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Box } from "grommet";

interface SignUpForBlendedModalProps {
  opened: boolean;
  handleClose: () => void;
  onClickOnSignup: () => void;
  onClickOnLogin: () => void;
}

const Content = styled.div`
  text-align: center;
  font-family: Poppins;
  margin-top: -20px;
  padding: 30px 10px 0;
`;

const Title = styled.h2`
  color: #193da9;
  font-family: Poppins;
  font-style: normal;
  font-weight: 700;
  font-size: 2em;
  line-height: 42px;
  text-align: center;
`;

const MainText = styled.p`
  color: #030b1d;
  font-size: 1.3em;
  line-height: 30px;
`;

const SecondaryText = styled.p`
  color: #5b616f;
  font-size: 1em;
  line-height: 30px;
  font-family: "Open Sans";
  font-weight: 600;
  margin-top: 35px;
  & a {
    color: #5b616f;
    text-decoration: underline;
  }
`;

const SignUpForBlendedModal: React.FC<SignUpForBlendedModalProps> = (props) => {
  return (
    <CustomModal
      opened={props.opened}
      handleClose={props.handleClose}
      zIndex="2000"
      size="small"
    >
      <Content>
        <Title>
          Sign Up for BlendEd <br />
          to continue.
        </Title>
        <MainText>
          Don’t worry, it’s super easy and <b>it’s free!</b> <br />
          All your progress will be saved to your account.
        </MainText>
        <Box gap="medium" align="center">
          <SquaredButton
            width="206px"
            type={"outline"}
            onClick={() => props.handleClose()}
          >
            Go Back to Trial
          </SquaredButton>
          <SquaredButton
            width="206px"
            type={"primary"}
            onClick={() => props.onClickOnSignup()}
          >
            Sign Up Now!
          </SquaredButton>
        </Box>
        <SecondaryText>
          Already have an account?{" "}
          <Link to="#" onClick={() => props.onClickOnLogin()}>
            Log in
          </Link>
        </SecondaryText>
      </Content>
    </CustomModal>
  );
};

export default SignUpForBlendedModal;
