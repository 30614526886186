export enum CanvasActionType {
  SetTokensCanvas = "SetTokensCanvas",
  ClearTokens = "ClearTokens",
}

export interface SetTokens {
  type: typeof CanvasActionType.SetTokensCanvas;
  payload: {
    refreshToken?: string;
    accessToken?: string;
    userId?: string;
    scopes?: string[];
  };
}

export interface ClearTokens {
  type: typeof CanvasActionType.ClearTokens;
  payload: undefined;
}

export type CanvasActions = SetTokens | ClearTokens;
