import { transformValues } from "../utils/fbUtil";
import { Syllabus } from "../model/Syllabus";
import { useAppSelector } from "redux/configureStore";

const useTemporalTemplate = () => {
  const tempSyllabus: Syllabus[] = useAppSelector(
    (state) => state.syllabusTemplate.tempSyllabus
  );
  const keyList = [
    "startDate",
    "endDate",
    "start_at",
    "end_at",
    "dueDate",
    "createdAt",
  ];

  return transformValues(
    tempSyllabus,
    (key, value) => keyList.some((val) => val === key) && !!Date.parse(value),
    (value) => new Date(value)
  ) as Syllabus[];
};

export default useTemporalTemplate;
