import { Syllabus } from "../../../model/Syllabus";

export enum SyllabusTemplateActionType {
  SaveTempSyllabus = "SaveTempSyllabus",
  DeleteTempSyllabus = "DeleteTempSyllabus",
  SaveSchool = "SaveSchool",
}

export interface SaveTempSyllabus {
  type: typeof SyllabusTemplateActionType.SaveTempSyllabus;
  payload: Syllabus;
}

export interface DeleteTempSyllabus {
  type: typeof SyllabusTemplateActionType.DeleteTempSyllabus;
  payload: string;
}

export interface SaveSchool {
  type: typeof SyllabusTemplateActionType.SaveSchool;
  payload: string;
}

export type SyllabusTemplateActions =
  | SaveTempSyllabus
  | DeleteTempSyllabus
  | SaveSchool;
