import { Syllabus } from "../../../model/Syllabus";
import {
  OneWeekPlanActions,
  OneWeekPlanActionType,
} from "../types/OneWeekPlanTypes";
import { Activity } from "../../../model/Resource";

export const saveTempSyllabus = (syllabus: Syllabus): OneWeekPlanActions => ({
  type: OneWeekPlanActionType.SaveTempSyllabus,
  payload: syllabus,
});

export const deleteTempSyllabus = (): OneWeekPlanActions => ({
  type: OneWeekPlanActionType.DeleteTempSyllabus,
  payload: undefined,
});

export const saveObjectives = (objectives: string): OneWeekPlanActions => ({
  type: OneWeekPlanActionType.SaveObjectives,
  payload: objectives,
});

export const saveActivities = (activities: Activity[]): OneWeekPlanActions => ({
  type: OneWeekPlanActionType.SaveActivities,
  payload: activities,
});

export const saveCourseTitle = (title: string): OneWeekPlanActions => ({
  type: OneWeekPlanActionType.SaveCourseTitle,
  payload: title,
});

export const saveInstructor = (instructor: string): OneWeekPlanActions => ({
  type: OneWeekPlanActionType.SaveInstructor,
  payload: instructor,
});

export const saveSchool = (school: string): OneWeekPlanActions => ({
  type: OneWeekPlanActionType.SaveInstructor,
  payload: school,
});
