import React, { useState } from "react";
import styled from "styled-components";
import RegistrationImage from "../../../assets/images/register.png";
import { Box, Button, Form, Image, Text } from "grommet";
import Title from "../../ui-kit/OneWeekPlanTitle/Title";
import Subtitle from "../../ui-kit/OneWeekPlanSubtitle/Subtitle";
import GoogleButton from "react-google-button";
import RoundedButton from "../../ui-kit/RoundedButton/RoundedButton";
import TextInput from "../../ui-kit/TextInput/TextInput";
import UsersRepository from "../../../repositories/UsersRepository";
import { useFirebase } from "react-redux-firebase";
import CheckBox from "../../ui-kit/CheckBox/CheckBox";
import TermsOfUseDialog from "../../TermsOfUseDialog";
import StepBar from "../StepBar/StepBar";

interface SignUpProps {
  onNewLogin?: (userId: string) => void;
}

const Content = styled.div`
  width: 100%;
  display: grid;
  justify-items: center;
`;

const StyledSubtitle = styled(Subtitle)`
  padding: 10px 0 0 20px;
`;

const SeparatorLine = styled.div`
  width: 70%;
  height: 15px;
  text-align: center;
  border-bottom: 1px solid #d0dcf1;
`;

const SeparatorText = styled.span`
  padding: 10px;
  background-color: white;
  font-family: "Open Sans";
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #474e61;
`;

const RegisterButton = styled(RoundedButton)`
  width: 250px;
`;

const TermsOfUseText = styled(Text)`
  font-family: "Open Sans";
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #030b1d;
`;

const TermsOfUseButton = styled(Button)`
  font-family: "Open Sans";
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #2196f3;
  margin-left: 5px;
  "&:hover": {
    background-color: #f4f6f8;
  }
`;

const SignUp: React.FC<SignUpProps> = (props) => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [checked, setChecked] = useState<boolean>(false);
  const [isOpenDialog, setIsOpenDialog] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<{
    email?: string;
    password?: string;
  }>({});

  const firebase = useFirebase();

  const handleSubmit = async () => {
    const repository = new UsersRepository();
    const result = await repository.create(email, password, true);

    if (result.error) {
      if (result.error?.message.includes("email")) {
        setErrorMessage({ ...errorMessage, email: result.error?.message });
      } else {
        setErrorMessage({
          ...errorMessage,
          password: result.error?.message,
        });
      }
      return;
    }

    props.onNewLogin?.(result.user!);
  };

  const loginWithGoogle = async () => {
    const repository = new UsersRepository();
    const login = repository.loginWithGoogle();
    return login
      .then(async (result) => {
        const email = result.user!.email!;
        window.analytics.identify(result.user?.uid, { email: email });
        window.analytics.track("Successful login");
        const user = await repository.getById(result.user!.uid!);
        const fullName = result.user?.displayName?.split(" ");
        await firebase.updateProfile({
          ...user,
          email: email,
          firstName: fullName?.[0] || "",
          lastName: fullName?.[1] || "",
        });
        props.onNewLogin?.(result.user?.uid!);
      })
      .catch((error) => {
        window.analytics.track("Unsuccessful login");
        console.log("Error with Login", error);
      });
  };

  const renderTermsAndConditions = (
    <TermsOfUseDialog
      open={isOpenDialog}
      title={"Terms of Use"}
      onClickClose={() => {
        setIsOpenDialog(false);
      }}
      cancelLabel={"Go Back"}
      acceptLabel={"Agreed"}
      onClickAccept={() => {
        setChecked(true);
        setIsOpenDialog(false);
      }}
    />
  );

  return (
    <Content>
      <StepBar percentage={97} text="Last Step!" />
      <Image
        src={RegistrationImage}
        alt={"Create an account"}
        width="293px"
        height="174px"
        margin={{ bottom: "20px", top: "40px" }}
      />
      <Title text="Create an account to continue editing your Syllabus" />
      <StyledSubtitle text="We’ll save your work so far and take you to the BlendED Syllabus Editor. It’s completely free!" />
      <Box pad={{ bottom: "40px", top: "20px" }}>
        <GoogleButton onClick={() => loginWithGoogle()} />
      </Box>
      <SeparatorLine>
        <SeparatorText>Or register using your Email Account</SeparatorText>
      </SeparatorLine>
      <Box pad={{ vertical: "20px" }} width="70%">
        <Form noValidate>
          <TextInput
            value={email}
            error={errorMessage.email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            onFocus={() => {
              setErrorMessage({ ...errorMessage, email: "" });
            }}
            a11yTitle={"Email Address"}
            label={"Email Address"}
          />
          <TextInput
            label="Password"
            error={errorMessage.password}
            type={"password"}
            value={password}
            onFocus={() => {
              setErrorMessage({ ...errorMessage, password: "" });
            }}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />
          <Box pad={{ top: "24px" }}>
            <CheckBox
              checked={checked}
              onChange={(event) => setChecked(event.target.checked)}
              label={
                <Box direction="row">
                  <TermsOfUseText>I have read and agree to</TermsOfUseText>
                  <TermsOfUseButton onClick={() => setIsOpenDialog(true)}>
                    Terms of use
                  </TermsOfUseButton>
                </Box>
              }
            />
          </Box>
          <Box align="center" justify="center" pad={{ top: "20px" }}>
            <RegisterButton
              type="primary"
              variant="blue"
              onClick={() => handleSubmit()}
              disabled={!checked}
            >
              Register
            </RegisterButton>
          </Box>
        </Form>
      </Box>
      {renderTermsAndConditions}
    </Content>
  );
};

export default SignUp;
