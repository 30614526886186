import React from "react";
import { Blank, IconProps } from "grommet-icons";
import { ChildrenIconProps } from "./FileTypeIcon";
import { IconColors } from "../../theme/GlobalTheme";

const MOV: React.FC<IconProps & ChildrenIconProps> = (props) => {
  return (
    <Blank viewBox="0 0 39 42" {...props} color="plain">
      <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
      >
        <path
          fill="white"
          d="M14.79.58H7.93A2.29,2.29,0,0,0,5.65,2.87V21.15A2.29,2.29,0,0,0,8,23.44H20.52a2.29,2.29,0,0,0,2.29-2.29V8.57Z"
        />
        <path
          fill={
            props.disabled
              ? IconColors["icon-disabled"]
              : IconColors["icon-dark-blue"]
          }
          d="M14.56,1.15l7.67,7.66V21.14a1.71,1.71,0,0,1-1.71,1.72H8a1.72,1.72,0,0,1-1.72-1.72V2.87A1.72,1.72,0,0,1,7.93,1.15h6.63m.23-.57H7.93A2.29,2.29,0,0,0,5.65,2.87V21.15A2.29,2.29,0,0,0,8,23.44H20.52a2.29,2.29,0,0,0,2.29-2.29V8.57l-8-8Z"
        />
        <path
          fill={
            props.disabled
              ? IconColors["icon-disabled"]
              : IconColors["icon-light-blue"]
          }
          d="M14.79.86V6.29a2.29,2.29,0,0,0,2.28,2.29h5.43Z"
        />
        <path
          fill={
            props.disabled
              ? IconColors["icon-disabled"]
              : IconColors["icon-dark-blue"]
          }
          d="M22.51,8.86H17.08a2.57,2.57,0,0,1-2.57-2.57V.86A.29.29,0,0,1,14.68.6.29.29,0,0,1,15,.66l7.72,7.71a.28.28,0,0,1-.2.49ZM15.08,1.55V6.29a2,2,0,0,0,2,2h4.74Z"
        />
        <rect
          fill={
            props.disabled
              ? IconColors["icon-disabled"]
              : IconColors["icon-blue"]
          }
          x="1.09"
          y="11.15"
          width="20"
          height="9.14"
          rx="0.86"
        />
        <path
          fill={
            props.disabled
              ? IconColors["icon-disabled"]
              : IconColors["icon-dark-blue"]
          }
          d="M20.23,11.72a.27.27,0,0,1,.28.28v7.43a.29.29,0,0,1-.29.29H2a.29.29,0,0,1-.29-.29V12a.28.28,0,0,1,.28-.28H20.23m0-.57H1.94a.86.86,0,0,0-.86.86v7.42A.85.85,0,0,0,2,20.3H20.23a.86.86,0,0,0,.86-.86V12a.84.84,0,0,0-.85-.85Z"
        />
        <path
          fill="white"
          d="M8.67,13.47v4.59H7.75v-3l-1.22,3h-.7l-1.24-3v3H3.67V13.48h1l1.47,3.41,1.45-3.41Z"
        />
        <path
          fill="white"
          d="M10.53,17.8A2.28,2.28,0,0,1,9.67,17a2.52,2.52,0,0,1,0-2.42,2.21,2.21,0,0,1,.85-.84,2.42,2.42,0,0,1,1.19-.3,2.35,2.35,0,0,1,1.18.3,2.21,2.21,0,0,1,.85.84,2.5,2.5,0,0,1,0,2.41,2.15,2.15,0,0,1-.85.84,2.39,2.39,0,0,1-1.18.31A2.29,2.29,0,0,1,10.53,17.8Zm1.91-.7a1.35,1.35,0,0,0,.49-.54,1.89,1.89,0,0,0,0-1.61,1.26,1.26,0,0,0-.49-.53,1.37,1.37,0,0,0-.73-.18,1.5,1.5,0,0,0-.74.18,1.26,1.26,0,0,0-.49.53,1.89,1.89,0,0,0,0,1.61,1.3,1.3,0,0,0,.5.54,1.57,1.57,0,0,0,1.46,0Z"
        />
        <path
          fill="white"
          d="M18.86,13.47l-1.68,4.59H16.06l-1.69-4.59h1l1.26,3.65,1.26-3.65Z"
        />
      </svg>
    </Blank>
  );
};

export default MOV;
