import React, { useState } from "react";
import RoundedButton from "../ui-kit/RoundedButton/RoundedButton";
import Delete from "../../assets/icons/Delete";
import { Box } from "grommet";
import { Schedule, Section, SyllabusComponent } from "../../model/Syllabus";
import styled from "styled-components";

interface SectionFooterProps {
  onDeleteComponent?: (component: SyllabusComponent) => void;
  selectedSection: Section;
  sections: Section[];
  disableButtons?: boolean;
  onSaveAll: () => void;
  onCancelAndClose?: () => void;
}

enum DeleteButtonStates {
  SHOW,
  CLEANUP,
  HIDDEN,
}

const SaveRoundedButton = styled(RoundedButton)`
  margin: 0 4px;
  width: 240px;
`;

const SectionFooter: React.FC<SectionFooterProps> = (props) => {
  const [isLoadingTotalSave, setIsLoadingTotalSave] = useState<boolean>(false);
  /**
   * Returns true if the currentSection is a section and if it is a module, it returns true only if that module is the last one.
   */
  const checkPositionOfSection = (): DeleteButtonStates => {
    if (!props.selectedSection) return DeleteButtonStates.HIDDEN;
    if (props.selectedSection.type !== "Module") {
      if (props.selectedSection.type === "CourseTitle") {
        return DeleteButtonStates.HIDDEN;
      } else {
        return DeleteButtonStates.SHOW;
      }
    }
    const schedule = props.sections.find(
      (s) => s.type === "Schedule"
    ) as Schedule;
    if (!schedule) return DeleteButtonStates.HIDDEN;
    if (schedule.modules.length === 0) return DeleteButtonStates.HIDDEN;

    if (
      schedule.modules[schedule.modules.length - 1].id ===
      props.selectedSection.id
    ) {
      return DeleteButtonStates.SHOW;
    } else {
      return DeleteButtonStates.CLEANUP;
    }
  };

  const handleSaveAll = async () => {
    setIsLoadingTotalSave(true);
    await props.onSaveAll();
    setIsLoadingTotalSave(false);
  };

  return (
    <>
      <div className="footer-left">
        {checkPositionOfSection() === DeleteButtonStates.SHOW && (
          <RoundedButton
            icon={<Delete />}
            type="tertiary"
            variant="red"
            onClick={async () => {
              await props.onDeleteComponent?.(props.selectedSection);
            }}
          >
            Delete Section
          </RoundedButton>
        )}
        {
          checkPositionOfSection() === DeleteButtonStates.CLEANUP && null
          // <RoundedButton
          //   icon={<Delete />}
          //   type="tertiary"
          //   variant="red"
          //   onClick={async () => {
          //     await props.onCleanUpComponent?.(props.selectedSection);
          //   }}
          // >
          //   Clean Section
          // </RoundedButton>
        }
      </div>
      <div className="footer-right">
        <Box
          direction="row"
          align="end"
          justify="end"
          pad={{ vertical: "small" }}
        >
          <SaveRoundedButton
            type="secondary"
            onClick={() => props.onCancelAndClose?.()}
            disabled={isLoadingTotalSave || props.disableButtons}
          >
            Cancel & Close
          </SaveRoundedButton>
          <SaveRoundedButton
            type="primary"
            onClick={() => handleSaveAll()}
            loading={isLoadingTotalSave || isLoadingTotalSave}
            disabled={props.disableButtons}
          >
            Save All & Close
          </SaveRoundedButton>
        </Box>
      </div>
    </>
  );
};

export default SectionFooter;
