import React from "react";
import styled from "styled-components";
import { Box, Card, CardBody, CardFooter } from "grommet";
import CheckBox from "../CheckBox/CheckBox";
import TextButton from "../TextButton/TextButton";
import OpenedPill from "../OpenedPill/OpenedPill";
import Open from "assets/icons/Open";

interface MaterialsSearchOERCardProps {
  title: string;
  summary: string;
  added?: boolean;
  onClick?: () => void;
  onAdd?: (checked: boolean) => void;
  openedAt?: Date;
}

const StyledCard = styled(Card)`
  border-bottom: 1px solid #d0dcf1;
  background: white;
  padding: 20px 5px;
  width: 100%;
  border-radius: 0;

  & .title {
    font-family: "Open Sans";
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #030b1d;
    margin: 5px 0;
  }

  & .summary {
    font-family: "Open Sans";
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #474e61;
  }
`;

const MaterialsSearchOERCard: React.FC<MaterialsSearchOERCardProps> = ({
  onClick,
  summary,
  title,
  openedAt,
  onAdd,
  added,
}) => {
  return (
    <StyledCard>
      <CardBody>
        {openedAt && <OpenedPill date={openedAt} />}
        <div className="title">{title}</div>
        <div className="summary">{summary}</div>
      </CardBody>
      <CardFooter pad={{ top: "20px" }}>
        <Box direction="row" justify="between" width="100%">
          <CheckBox
            checked={!!added}
            onChange={(event) => onAdd?.(event.target.checked)}
            label="Add to Activity"
          />
          <TextButton icon={<Open />} onClick={() => onClick?.()}>
            Open Link
          </TextButton>
        </Box>
      </CardFooter>
    </StyledCard>
  );
};

export default MaterialsSearchOERCard;
