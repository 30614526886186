import React, { useCallback, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import firebase from "firebase";
import Loading from "components/Loading";
import styled from "styled-components";
import UsersRepository from "../repositories/UsersRepository";
import { useFirebase } from "react-redux-firebase";

interface BubbleAuthProps {}

const StyledDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const BubbleAuth: React.FC<BubbleAuthProps> = (props) => {
  const location = useLocation();
  const history = useHistory();
  const localFirebase = useFirebase();
  const code = new URLSearchParams(location.search).get("code") as string;

  const loginWithCode = useCallback(
    async (code: string) => {
      console.log("Comienzo exec de funcion 33", code);

      const callableCreate = firebase
        .functions()
        .httpsCallable("loginWithCode");
      const response = await callableCreate({
        code,
      });

      if (response.data.status === "ok") {
        const repository = new UsersRepository();
        const auth = await firebase
          ?.auth()
          .signInWithCustomToken?.(response.data.data.token);

        const user = await repository.getById(auth.user!.uid!);
        await localFirebase.updateProfile({
          ...user,
        });

        history.replace("/");
      } else {
        alert(response.data.message);
        history.replace("/login");
      }
    },
    [localFirebase, history]
  );

  useEffect(() => {
    loginWithCode(code);
  }, [loginWithCode, code]);

  return (
    <StyledDiv>
      <Loading />
    </StyledDiv>
  );
};

export default BubbleAuth;
