import React, { forwardRef, useImperativeHandle, useState } from "react";
import { Button } from "grommet";
import styled from "styled-components";
import clsx from "clsx";
import Check from "assets/icons/Check";
import { Blank } from "grommet-icons";
import Loading from "../../../assets/icons/Loading";

export interface TextButtonRef {
  startSuccess: () => void;
}

interface TextButtonProps {
  onClick?: () => void;
  /**
   * Disabled buttons can't be pressed
   */
  disabled?: boolean;
  icon?: JSX.Element;
  /**
   * If the loading state is true a loading icon will appear next to the label and the button will be disabled
   */
  loading?: boolean;
  /**
   * The active state shows the user the section in which he is
   */
  active?: boolean;
  className?: string;
}

const StyledButton = styled(Button)`
  &.button {
    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    color: #030b1d;
    & .iconColor {
      color: #0d3af8;
    }
    &:hover {
      text-decoration-line: underline;
    }
    &.disabled {
      color: #9da2ac;
      .iconColor {
        color: #9da2ac;
      }
      &:hover {
        text-decoration-line: none;
        color: #9da2ac;
        cursor: not-allowed;
      }
    }
    &:active {
      color: #0d3af8;
      text-decoration-line: underline;
    }
    &.loading {
      color: #9da2ac;
      &:hover {
        text-decoration-line: none;
      }
    }
    &.active {
      color: #0d3af8;
    }
  }
`;

const StyledDiv = styled.div<TextButtonProps>`
  display: flex;
  justify-content: center;
  flex-direction: ${(props) => (!props.icon ? "row-reverse" : "row")};
  min-height: 18px;

  .icon,
  .text {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: ${(props) => (!props.icon ? "0" : "0 5px")};
  }

  .icon {
    width: 18px;
  }
`;

const TextButton: React.FC<TextButtonProps> = forwardRef((props, ref) => {
  const [success, setSuccess] = useState<boolean | undefined>(false);

  const renderIcon = () => {
    if (props.loading) {
      return <Loading size="18px" color="currentColor" />;
    } else if (success) {
      return <Check size="18px" color="currentColor" />;
    } else if (props.icon) {
      return (
        <Blank size="18px" color="currentColor">
          {props.icon}
        </Blank>
      );
    } else {
      return undefined;
    }
  };

  useImperativeHandle(ref, () => ({ startSuccess }));

  function startSuccess() {
    setSuccess(true);
    setTimeout(() => {
      setSuccess(false);
    }, 3000);
  }

  return (
    <StyledButton
      disabled={props.disabled}
      onClick={() => !props.disabled && !props.loading && props.onClick?.()}
      className={clsx({
        [`${props.className}`]: true,
        button: true,
        disabled: props.disabled,
        active: props.active,
        loading: props.loading,
      })}
    >
      <StyledDiv loading={props.loading} icon={props.icon}>
        <span className="icon iconColor">{renderIcon()}</span>
        <span className="text">{props.children}</span>
      </StyledDiv>
    </StyledButton>
  );
});

export default TextButton;
