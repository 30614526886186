import {
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from "react";
import _ from "lodash";

const useDelayedState = <T>(
  defaultValue: T,
  onChange: (newObj: T) => void,
  milliseconds: number = 500
): readonly [
  T,
  (obj: T, execOnChange?: boolean) => void,
  Dispatch<SetStateAction<T>>
] => {
  const [_object, _setObject] = useState<T>(defaultValue);

  const delayedOnChange = useMemo(
    () =>
      _.debounce((newObject: T) => {
        onChange(newObject);
      }, milliseconds),
    [onChange, milliseconds]
  );

  const setObject = useCallback(
    (newObject: T, execOnChange: boolean = true) => {
      _setObject(newObject);
      if (execOnChange) {
        delayedOnChange(newObject);
      }
    },
    [delayedOnChange]
  );

  return [_object, setObject, _setObject];
};

export default useDelayedState;
