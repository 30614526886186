import { Syllabus } from "../../../model/Syllabus";

export enum TeacherModeActionType {
  SaveTempSyllabus = "SaveTempSyllabus",
  DeleteTempSyllabus = "DeleteTempSyllabus",
  DeleteAllSyllabi = "DeleteAllSyllabi",
}

export interface SaveTempSyllabus {
  type: typeof TeacherModeActionType.SaveTempSyllabus;
  payload: Syllabus;
}

export interface DeleteTempSyllabus {
  type: typeof TeacherModeActionType.DeleteTempSyllabus;
  payload: string;
}

export interface DeleteAllSyllabi {
  type: typeof TeacherModeActionType.DeleteAllSyllabi;
  payload: undefined;
}

export type TeacherModeActions =
  | SaveTempSyllabus
  | DeleteTempSyllabus
  | DeleteAllSyllabi;
