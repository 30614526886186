import React from "react";
import { Activity, Resource } from "../../../model/Resource";

interface EditorSlideContextProviderProps {
  resourcesToBeAdded: Resource[];
  setResourcesToBeAdded: (resources: Resource[]) => void;
  selectedActivity?: Activity;
  setSelectedActivity: (activity: Activity) => void;
  selectedActivityIndex?: number;
  setSelectedActivityIndex: (index: number) => void;
}

export const EditorSlideContext =
  React.createContext<EditorSlideContextProviderProps>({
    resourcesToBeAdded: [] as Resource[],
    setResourcesToBeAdded: (resources: Resource[]) => {},
    selectedActivity: undefined,
    setSelectedActivity: (activity: Activity) => {},
    selectedActivityIndex: undefined,
    setSelectedActivityIndex: (index: number) => {},
  });

const EditorSlideContextProvider: React.FC<EditorSlideContextProviderProps> = (
  props
) => {
  return (
    <EditorSlideContext.Provider
      value={{
        resourcesToBeAdded: props.resourcesToBeAdded,
        setResourcesToBeAdded: props.setResourcesToBeAdded,
        selectedActivity: props.selectedActivity,
        setSelectedActivity: props.setSelectedActivity,
        selectedActivityIndex: props.selectedActivityIndex,
        setSelectedActivityIndex: props.setSelectedActivityIndex,
      }}
    >
      {props.children}
    </EditorSlideContext.Provider>
  );
};

export default EditorSlideContextProvider;
