import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Box } from "grommet";
import CustomModal from "components/modals/CustomModal";
import SquaredButton from "components/ui-kit/SquaredButton/SquaredButton";
import { HidableComponent } from "components/hoc/Hidable";

interface ShareStudentLinkModalProps {
  handleClose?: () => void;
  isOpen: boolean;
  link: string;
  code: string;
}

const Title = styled.p`
  font-family: "Poppins", serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #193da9;
`;
const ConfirmDialogContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 147px;
`;

const RoundedBox = styled.div`
  border: 1px solid #d0dcf1;
  border-radius: 10px;
  padding: 27px 34px;
  margin-bottom: 25px;

  font-family: "Open Sans", serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;

  color: #474e61;

  & .title {
    font-weight: 600;
  }
`;

const LinkBox = styled.div`
  background: #f5f8fa;
  border-radius: 4px;
  margin-right: 16px;
  padding: 10px;
  display: flex;
  align-items: center;

  font-family: "Open Sans", serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #030b1d;
`;

const CopyFeedback = styled.div`
  margin-top: 4px;
  color: #193da9;
`;

const ShareStudentLinkModal: React.FC<ShareStudentLinkModalProps> = (props) => {
  const [linkCopied, setLinkCopied] = useState<boolean>(false);
  const [codeCopied, setCodeCopied] = useState<boolean>(false);

  useEffect(() => {
    if (linkCopied) {
      setTimeout(() => {
        setLinkCopied(false);
      }, 2000);
    }
  }, [linkCopied]);

  useEffect(() => {
    if (codeCopied) {
      setTimeout(() => {
        setCodeCopied(false);
      }, 2000);
    }
  }, [codeCopied]);

  return (
    <CustomModal
      handleClose={props.handleClose}
      closeButton={true}
      zIndex="1400"
      opened={props.isOpen}
      size="regular"
    >
      <ConfirmDialogContainer>
        <Box>
          <Title>Share Student Link</Title>
          <RoundedBox>
            <div className="title">Student Link</div>
            <Box margin={{ top: "5px" }}>
              Share this link with your students so they can view the last
              published version of the syllabus. They will be able to link it to
              their BlendED account and have all their BlendED Syllabus in one
              place.
            </Box>
            <Box direction="row" margin={{ top: "23px" }}>
              <LinkBox>{props.link}</LinkBox>
              <SquaredButton
                variant="dark-blue"
                onClick={() => {
                  navigator.clipboard.writeText(props.link);
                  setLinkCopied(true);
                }}
              >
                Copy Link
              </SquaredButton>
            </Box>
            <HidableComponent isVisible={linkCopied}>
              <CopyFeedback>Link was copied to your clipboard!</CopyFeedback>
            </HidableComponent>
          </RoundedBox>
          <RoundedBox>
            <div className="title">Student Code</div>
            <Box margin={{ top: "5px" }}>
              Share this code with your students so they can directly link the
              syllabus through their BlendED account. They will be able have all
              their BlendED Syllabus in one place and view its the last
              published version.{" "}
            </Box>
            <Box direction="row" margin={{ top: "23px" }}>
              <LinkBox>{props.code}</LinkBox>
              <SquaredButton
                variant="dark-blue"
                onClick={() => {
                  navigator.clipboard.writeText(props.code);
                  setCodeCopied(true);
                }}
              >
                Copy Code
              </SquaredButton>
            </Box>
            <HidableComponent isVisible={codeCopied}>
              <CopyFeedback>Code was copied to your clipboard!</CopyFeedback>
            </HidableComponent>
          </RoundedBox>
        </Box>
      </ConfirmDialogContainer>
    </CustomModal>
  );
};

export default ShareStudentLinkModal;
