import React from "react";
import styled from "styled-components";
import { Text } from "grommet";

interface SubtitleProps {
  text: string;
  className?: string;
}

const StyledSubtitle = styled(Text)`
  font-family: "Open Sans";
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #474e61;
`;

const Subtitle: React.FC<SubtitleProps> = (props) => {
  return (
    <StyledSubtitle className={props.className}>{props.text}</StyledSubtitle>
  );
};

export default Subtitle;
