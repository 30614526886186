import React, { useContext } from "react";
import TableRow from "./TableRow";
import Td from "./Td";
import Link from "../Link/Link";
import { Module } from "../../../model/Syllabus";
import styled from "styled-components";
import clsx from "clsx";
import _ from "lodash";
import { Activity } from "../../../model/Resource";
import { Box, Keyboard } from "grommet";
import { formatInTimeZone } from "../../../helpers/formatInTimeZone";
import { SyllabusThemeContext } from "../../viewer/customization/SyllabusTheme";
import RichTextEditorViewer from "../RichTextEditor/Viewer/RichTextEditorViewer";
import { createHash } from "../../../helpers/createHash";

interface WeekRowProps {
  module: Module;
  onClickWeek?: (week: Module) => void;
  onClickActivity?: (activity: Activity) => void;
  index?: number;
}

const StyledTableRow = styled(TableRow)`
  size: 16px;
  color: #030b1d;

  & .no-activity-description {
    font-style: italic;
    font-weight: 400;
  }

  & .regular-text {
    font-weight: 400;
    max-width: 300px;
    div > div {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  & .read-more {
    padding: 10px 0 0 0;
  }
`;

const WeekRow: React.FC<WeekRowProps> = (props) => {
  const activities = props.module.activities;
  const groupedActivities = _.groupBy(
    activities,
    (activity) => activity.dueDate
  );
  const activitiesArray = Object.values(groupedActivities);

  const firstActivity = _.first(activitiesArray);
  const restOfActivities = activitiesArray.slice(1);

  const objectives = props.module.objectives;
  const weekTitle = props.module.title;

  const { colorScheme } = useContext(SyllabusThemeContext);

  return (
    <>
      <StyledTableRow index={props.index}>
        <Td
          week={true}
          className="vertical"
          rowspan={activitiesArray.length || 1}
          onClick={() => props.onClickWeek?.(props.module)}
          tabIndex={1}
        >
          {weekTitle}
        </Td>
        {firstActivity ? (
          <>
            <Td onClick={() => props.onClickWeek?.(props.module)} tabIndex={1}>
              {formatInTimeZone(firstActivity[0].dueDate, "EEEE MMM d", "UTC")}
            </Td>
            <Td>
              {firstActivity.map((first) => (
                <Keyboard
                  onEnter={() => props.onClickActivity?.(first)}
                  key={first.id}
                >
                  <Box key={first.id} tabIndex={1}>
                    <Link
                      color={colorScheme!.mainSyllabusColor}
                      activeColor={colorScheme!.pressedSyllabusColor}
                      onClick={() => props.onClickActivity?.(first)}
                      type="activity"
                    >
                      {first.title}
                    </Link>
                  </Box>
                </Keyboard>
              ))}
            </Td>
          </>
        ) : (
          <>
            <Td
              colspan={2}
              className="no-activity-description"
              onClick={() => props.onClickWeek?.(props.module)}
            >
              There are no activities this week.
            </Td>
          </>
        )}
        <Td
          rowspan={activitiesArray.length || 1}
          className="regular-text"
          onClick={() => props.onClickWeek?.(props.module)}
          tabIndex={1}
        >
          <RichTextEditorViewer
            value={objectives}
            key={createHash(JSON.stringify(objectives))}
          />
          {objectives && (
            <div className="read-more">
              <Link
                type="read-more"
                color={colorScheme?.primaryColor}
                activeColor={colorScheme?.pressedSyllabusColor}
              >
                Read More
              </Link>
            </div>
          )}
        </Td>
      </StyledTableRow>
      {restOfActivities.map((activity, index) => (
        <StyledTableRow key={index}>
          <Td
            className={clsx({
              "regular-text":
                activities?.length === 0 || activities === undefined,
            })}
            onClick={() => props.onClickWeek?.(props.module)}
            tabIndex={1}
          >
            {formatInTimeZone(activity[0].dueDate, "EEEE MMM d", "UTC")}
          </Td>
          <Td onClick={() => props.onClickActivity?.(activity[0])} tabIndex={0}>
            {activity.map((act, index) => (
              <Box key={index}>
                <Link
                  onClick={() => props.onClickActivity?.(act)}
                  type="activity"
                  color={colorScheme!.mainSyllabusColor}
                  activeColor={colorScheme!.pressedSyllabusColor}
                >
                  {act.title}
                </Link>
              </Box>
            ))}
          </Td>
        </StyledTableRow>
      ))}
    </>
  );
};

export default WeekRow;
