import React from "react";
import { Box, Keyboard, Layer, ThemeContext, ThemeType } from "grommet";
import styled from "styled-components";
import clsx from "clsx";

import ExitIcon from "../../assets/images/exit.svg";

interface CustomModalProps {
  opened: boolean;
  handleClose?: () => void;
  size: "small" | "regular" | "hero" | "mini";
  closeButton?: boolean;
  zIndex?: string;
  className?: string;
}

const StyledLayer = styled(Layer)`
  &.layer-mini {
    min-height: 200px;
    border-radius: 20px;
    width: 525px;
  }

  &.layer-small {
    min-height: 305px;
    border-radius: 20px;
    width: 525px;
  }

  &.layer-regular {
    border-radius: 14px;
    min-height: 525px;
  }

  &.layer-hero {
    border-radius: 10px;
    min-height: 525px;
    max-height: 650px;
    display: block;
    overflow: auto;
  }

  .exit-icon {
    position: absolute;
    top: 19px;
    right: 18px;

    &:hover,
    :focus-visible {
      cursor: pointer;
    }
  }
`;

const LayerContent = styled(Box)`
  display: flex;
  justify-content: center;
  padding: 18px 10% 18px;
  z-index: 5000 !important;

  &.layer-small {
    min-height: 300px;
    width: 600px;
  }

  &.layer-regular {
    max-height: 600px;
    width: 900px;
  }

  &.layer-hero {
    max-height: 650px;
    width: 900px;
  }
`;

const CustomModal: React.FC<CustomModalProps> = (props) => {
  const customTheme: ThemeType = {
    layer: {
      zIndex: props.zIndex || "20",
    },
  };
  return (
    <>
      {props.opened && (
        <ThemeContext.Extend value={customTheme}>
          <StyledLayer
            modal={true}
            responsive={true}
            onEsc={() => props.handleClose?.()}
            onClickOutside={() => props.handleClose?.()}
            animation="fadeIn"
            className={clsx({
              [props.className ?? ""]: true,
              [`layer-${props.size}`]: true,
            })}
          >
            <LayerContent
              className={clsx({
                [`layer-${props.size}`]: true,
              })}
            >
              {props.closeButton && (
                <Keyboard onEnter={() => props.handleClose?.()}>
                  <div
                    className="exit-icon"
                    onClick={() => props.handleClose?.()}
                    tabIndex={1}
                  >
                    <img src={ExitIcon} alt="Exit Icon" />
                  </div>
                </Keyboard>
              )}
              {props.children}
            </LayerContent>
          </StyledLayer>
        </ThemeContext.Extend>
      )}
    </>
  );
};

export default CustomModal;
