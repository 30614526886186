import React from "react";
import RoundedButton from "../ui-kit/RoundedButton/RoundedButton";
import CustomModal from "./CustomModal";
import { Box } from "grommet";
import styled from "styled-components";
import { ButtonSize, ButtonType, ButtonVariant } from "../../theme/GlobalTheme";

interface ConfirmDialogProps {
  isOpen: boolean;
  handleClose?: () => void;
  closeButton?: boolean;
  titleLabel: React.ReactNode;
  description?: React.ReactNode;
  disableButtons?: boolean;
  confirmButtonProps?: {
    label?: string;
    type?: ButtonType;
    variant?: ButtonVariant;
    size?: ButtonSize;
    onClick?: () => void;
  };
  cancelButtonProps?: {
    label?: string;
    type?: ButtonType;
    variant?: ButtonVariant;
    size?: ButtonSize;
    onClick?: () => void;
  };
}

const MainText = styled.p`
  font-family: Poppins;
  font-weight: normal;
  font-size: 17px;
  text-align: center;
  line-height: 25px;
  margin-top: 0;
  color: #030b1d;

  & > b {
    font-weight: 600;
  }
`;

const SecondaryText = styled.p`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  text-align: center;
  color: #474e61;
  margin-top: -16px;
`;

const ConfirmDialogContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 147px;
`;

const StyledRoundedButton = styled(RoundedButton)`
  width: 170px !important;
  padding: 0 !important;
`;

const ConfirmDialog: React.FC<ConfirmDialogProps> = (props) => {
  return (
    <CustomModal
      handleClose={props.handleClose}
      closeButton={props.closeButton}
      zIndex="1400"
      opened={props.isOpen}
      size="small"
    >
      <ConfirmDialogContainer>
        <Box>
          <MainText>{props.titleLabel}</MainText>
          <SecondaryText>{props.description}</SecondaryText>
        </Box>
        <Box align="center" justify="center" gap="small" direction="row">
          {props.confirmButtonProps && (
            <StyledRoundedButton
              disabled={props.disableButtons}
              onClick={() => {
                props.cancelButtonProps?.onClick?.();
              }}
              type={props.cancelButtonProps?.type}
              variant={props.cancelButtonProps?.variant}
              size={props.cancelButtonProps?.size}
            >
              {props.cancelButtonProps?.label ?? "Cancel"}
            </StyledRoundedButton>
          )}
          {props.cancelButtonProps && (
            <StyledRoundedButton
              disabled={props.disableButtons}
              onClick={() => {
                props.confirmButtonProps?.onClick?.();
              }}
              type={props.confirmButtonProps?.type}
              variant={props.confirmButtonProps?.variant}
              size={props.confirmButtonProps?.size}
            >
              {props.confirmButtonProps?.label}
            </StyledRoundedButton>
          )}
        </Box>
      </ConfirmDialogContainer>
    </CustomModal>
  );
};

export default ConfirmDialog;
