import { Syllabus } from "../Syllabus";
import SyllabusErrorBag from "./SyllabusErrorBag";
import SectionValidatorFactory from "./SectionValidators/SectionValidatorFactory";

export class SyllabusValidator {
  validate(syllabus: Syllabus): SyllabusErrorBag {
    const errorBag: SyllabusErrorBag = {
      success: false,
      sections: [],
    };

    const validatorFactory = new SectionValidatorFactory();
    for (const section of syllabus.sections) {
      const validator = validatorFactory.create(section.type);
      const result = validator?.validate(section);
      if (result !== undefined) {
        errorBag.sections.push(result);
      }

      if (section.type === "Schedule") {
        for (const module of section.modules) {
          const validator = validatorFactory.create(module.type);
          const result = validator?.validate(module);
          if (result !== undefined) {
            errorBag.sections.push(result);
          }
        }
      }
    }

    errorBag.success = errorBag.sections.length === 0;

    return errorBag;
  }
}
