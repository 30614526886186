import { useAppSelector } from "../redux/configureStore";
import { transformValues } from "../utils/fbUtil";
import { Activity } from "../model/Resource";

export const useOneWeekPlanActivities = (): Activity[] => {
  const activities = useAppSelector((state) => state.oneWeekPlan.activities);

  return transformValues(
    activities,
    (key, value) => key === "dueDate" && !!Date.parse(value),
    (value) => new Date(value)
  );
};
