import React from "react";
import ReactDOMServer from "react-dom/server";
import { ServerStyleSheet, StyleSheetManager } from "styled-components";

import BaseTemplate from "../components/canvasTemplates/BaseTemplate/BaseTemplate";

import type { ThemeType } from "../components/canvasTemplates/types";
import type { Syllabus } from "../model/Syllabus";

export const generateHTML = (theme: ThemeType, syllabus: Syllabus) => {
  let styledHTML = "";

  const sheet = new ServerStyleSheet();
  try {
    ReactDOMServer.renderToString(
      <StyleSheetManager sheet={sheet.instance}>
        <BaseTemplate theme={theme} syllabus={syllabus} />
      </StyleSheetManager>
    );
    const styleTags = sheet.getStyleTags();
    const HTML = ReactDOMServer.renderToStaticMarkup(
      <BaseTemplate theme={theme} syllabus={syllabus} />
    ).toString();

    styledHTML = `${styleTags}${HTML}`;
  } catch (error) {
    console.error(error);
  } finally {
    sheet.seal();
  }

  return styledHTML;
};
