import React from "react";
import { Blank } from "grommet-icons";

interface EyeOffProps {}

const EyeOff: React.FC<EyeOffProps> = (props) => {
  return (
    <Blank {...props} color="plain">
      <svg version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 24 24">
        <path
          fill="currentColor"
          d="M22.9,11.5C22.7,11.2,18.9,4,12,4c-0.7,0-1.4,0.1-2.1,0.2C9.3,4.4,9,4.9,9.1,5.4C9.2,6,9.8,6.3,10.3,6.2
		C10.9,6.1,11.4,6,12,6c4.8,0,7.9,4.5,8.8,6c-0.5,0.8-1,1.5-1.6,2.1c-0.4,0.4-0.3,1,0.1,1.4c0.2,0.2,0.4,0.3,0.7,0.3
		c0.3,0,0.5-0.1,0.7-0.3c0.8-0.9,1.5-1.9,2.1-3C23,12.2,23,11.8,22.9,11.5z"
        />
        <path
          fill="currentColor"
          d="M3.7,2.3c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4l2.9,2.9c-1.7,1.4-3,3-4.1,4.9c-0.2,0.3-0.2,0.7,0,1
		C1.3,12.8,5.1,20,12,20c1.8,0,3.6-0.5,5.1-1.5l3.2,3.2c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3c0.4-0.4,0.4-1,0-1.4L3.7,2.3z
		 M10.1,11.5l2.5,2.5c-0.6,0.2-1.2,0.1-1.8-0.4C10.1,13.1,9.9,12.2,10.1,11.5z M12,18c-4.8,0-7.9-4.5-8.8-6c0.9-1.5,2.1-2.8,3.5-3.9
		L8.6,10c-1,1.7-0.6,3.9,1,5.2c0.7,0.6,1.6,0.9,2.5,0.9c0.7,0,1.4-0.2,2-0.6l1.6,1.6C14.5,17.7,13.3,18,12,18z"
        />
      </svg>
    </Blank>
  );
};

export default EyeOff;
