import React, { useState } from "react";
import { Activity } from "../../../model/Resource";
import { Box, Text } from "grommet";
import styled from "styled-components";
import SquaredButton from "../../ui-kit/SquaredButton/SquaredButton";
import ActivityEditor from "./ActivityEditor";
import _ from "lodash";
import DeleteAssignmentConfirmModal from "./DeleteAssignmentConfirmModall";
import format from "date-fns/format";
import ActivityFactory from "../../../model/ActivityFactory";

interface ActivitiesEditorProps {
  activities: Activity[];
  onChange: (activities: Activity[]) => void;
  onClickSearchForMaterials?: (activity: Activity, index: number) => void;
  needsRegistration?: boolean;
  startOfWeek: Date;
  errors: Record<string, string> | undefined;
}

const StyledTitle = styled(Text)`
  margin-bottom: 0.5em;
  margin-top: 0.5em;
  font-family: "Poppins";
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #030b1d;
`;

const StyledSubtitle = styled(Text)`
  font-family: "Open Sans";
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 25px;

  color: #474e61;
`;

const StyledBox = styled(Box)`
  padding: 8px 8px 55px 8px;
  margin: 8px;
`;

const StyledButton = styled(SquaredButton)`
  width: 280px;
  padding: 0 10px !important;
`;

const ActivitiesEditor: React.FC<ActivitiesEditorProps> = ({
  onChange,
  activities,
  onClickSearchForMaterials,
  needsRegistration,
  startOfWeek,
  errors,
}) => {
  const [activityToDelete, setActivityToDelete] = useState<
    Activity | undefined
  >(undefined);

  const renderDeleteConfirmModal = () => {
    return (
      <DeleteAssignmentConfirmModal
        opened={!!activityToDelete}
        onClickCancel={() => setActivityToDelete(undefined)}
        onClickConfirm={() => {
          const newActivities2 = _.cloneDeep(activities).filter(
            (a) => a.id !== activityToDelete!.id
          );
          onChange(newActivities2);
          setActivityToDelete(undefined);
        }}
      />
    );
  };

  return (
    <StyledBox>
      {renderDeleteConfirmModal()}
      <StyledTitle>Assignments</StyledTitle>
      <StyledSubtitle>
        Drag and drop files into the textarea to add materials. You can also
        select the ‘Upload Material’ button or search for materials on our
        database. Make sure to select a day of the week to associate each
        assignment to.
      </StyledSubtitle>
      <div>
        {activities.map((activity, index) => (
          <ActivityEditor
            errors={errors}
            startOfWeek={startOfWeek}
            key={`activity-editor-${index}-${activity.id}`}
            index={index}
            activity={activity}
            onChange={(activity) => {
              const newActivities = activities.map((oldActivity) =>
                oldActivity.id === activity.id ? activity : oldActivity
              );
              onChange(newActivities);
            }}
            onClickSearchForMaterials={onClickSearchForMaterials}
            needsRegistration={needsRegistration}
            onDeleteActivity={(activityToDelete) => {
              const activityFactory = new ActivityFactory();
              const blankActivity = activityFactory.create();
              if (
                !_.isEqual(
                  {
                    ...blankActivity,
                    id: undefined, //Set to undefined to always have the same id to compare
                    dueDate: format(blankActivity.dueDate, "yyyy-MM-dd"), // DueDate is a DateTime, We have to extract only the part that refers to Date.
                  },
                  {
                    ...activityToDelete,
                    id: undefined,
                    dueDate: format(activityToDelete.dueDate, "yyyy-MM-dd"), // DueDate is a DateTime, We have to extract only the part that refers to Date.
                  }
                )
              ) {
                setActivityToDelete({ ...activityToDelete });
              } else {
                const newActivities2 = _.cloneDeep(activities).filter(
                  (a) => a.id !== activityToDelete.id
                );
                onChange(newActivities2);
              }
            }}
          />
        ))}
      </div>
      <Box margin={{ top: "50px" }}>
        <StyledButton
          type="secondary"
          variant="blue"
          onClick={() => {
            const activityFactory = new ActivityFactory();
            const newActivities = [
              ...activities,
              activityFactory.create(startOfWeek),
            ];
            onChange(newActivities);
          }}
        >
          + Add New Assignment
        </StyledButton>
      </Box>
    </StyledBox>
  );
};

export default ActivitiesEditor;
