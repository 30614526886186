import React from "react";
import { TableHeader as GrommetTableHeader } from "grommet/components/TableHeader";

interface TableHeaderProps {}

const TableHeader: React.FC<TableHeaderProps> = (props) => {
  return <GrommetTableHeader>{props.children}</GrommetTableHeader>;
};

export default TableHeader;
