import React, { useState } from "react";
import Books from "../assets/icons/Books";
import { Box } from "grommet/components/Box";
import Videos from "../assets/icons/Videos";
import OER from "../assets/icons/OER";
import MaterialsSearchOERCard from "./ui-kit/MaterialsSearchOERCard/MaterialsSearchOERCard";
import styled from "styled-components";
import { Tabs } from "grommet";
import MaterialsSearchTab from "./ui-kit/MaterialsSearchTab/MaterialsSearchTab";
import MaterialsSearchBookCard from "./ui-kit/MaterialsSearchBookCard/MaterialsSearchBookCard";
import MaterialSearchVideoCard from "./ui-kit/MaterialsSearchVideoCard/MaterialsSearchVideoCard";
import MaterialSearchVideoPreviewCard from "./ui-kit/MaterialsSearchVideoCard/MaterialsSearchVideoPreviewCard";
import Loading from "./Loading";
import {
  GoogleBookResource,
  GoogleCustomSearchResultResource,
  OpenedResource,
  Resource,
  VideoResource,
} from "../model/Resource";

interface MaterialsListProps {
  books: {
    bookList: GoogleBookResource[];
    onOpen: (volume: GoogleBookResource) => void;
  };
  videos: {
    videoList: VideoResource[];
    onOpen: (video: VideoResource) => void;
  };
  OER: {
    OERList: GoogleCustomSearchResultResource[];
    onOpen: (OERResource: GoogleCustomSearchResultResource) => void;
  };
  onAdd: (resource: Resource, checked: boolean) => void;
  recentlyOpened: OpenedResource[];
  onlyShowRecentlyOpened?: boolean;
  loading?: boolean;
  resourcesToBeAdded: Resource[];
}

const TabsContainer = styled(Tabs)`
  width: 100%;
`;

const TabItem = styled(MaterialsSearchTab)`
  width: 30%;
`;

const StyledMaterialSearchBookCard = styled(MaterialsSearchBookCard)`
  width: 48% !important;
  margin: 4px;
`;

const StyledMaterialSearchVideoCard = styled(MaterialSearchVideoCard)`
  width: 48% !important;
  margin: 4px;
`;

const StyledMaterialSearchVideoPreviewCard = styled(
  MaterialSearchVideoPreviewCard
)`
  width: 100% !important;
  margin: 4px;
`;

const ScrollBox = styled.div`
  overflow-y: scroll;
  max-height: 410px;
`;

const MaterialsList: React.FC<MaterialsListProps> = (props) => {
  const [videoToPreview, setVideoToPreview] = useState<
    VideoResource | undefined
  >();

  return (
    <TabsContainer>
      <TabItem
        icon={<Books />}
        title="Books"
        quantity={props.books.bookList.length}
        active={true}
      >
        {props.loading && (
          <Box height="100px" justify="center" align="center">
            <Loading />
          </Box>
        )}
        <ScrollBox>
          <Box direction="row" wrap={true}>
            {props.loading === false &&
              props.books.bookList.map((book, index) => (
                <StyledMaterialSearchBookCard
                  key={`google-book-id-${index}`}
                  title={book.title}
                  author={book.volumeInfo?.authors?.[0] || "Unknown Author"}
                  image={
                    book.image ||
                    "https://biblioteca.ciat.org/opac/public/img/jpg/no_book_cover.jpg"
                  }
                  added={props.resourcesToBeAdded.some(
                    (resource) => resource.id === book.id
                  )}
                  openedAt={
                    props.recentlyOpened.find(
                      (openedResource) => openedResource.resource.id === book.id
                    )?.openedAt
                  }
                  onAdd={(val) => props.onAdd(book, val)}
                  onClick={() => props.books.onOpen(book)}
                />
              ))}
          </Box>
        </ScrollBox>
      </TabItem>
      <TabItem
        icon={<Videos />}
        title="Videos"
        quantity={props.videos.videoList.length}
      >
        {props.loading && (
          <Box height="100px" justify="center" align="center">
            <Loading />
          </Box>
        )}
        <ScrollBox>
          <Box direction="row" wrap={true}>
            {props.loading === false &&
              props.videos.videoList.map((video, index) =>
                videoToPreview?.id === video.id ? (
                  <StyledMaterialSearchVideoPreviewCard
                    key={`youtube-video-id-${index}`}
                    id={video.id}
                    title={video.title}
                    author={video.snippet.channelTitle}
                    image={video.image}
                    onClick={() => setVideoToPreview(undefined)}
                    added={props.resourcesToBeAdded.some(
                      (resource) => resource.id === video.id
                    )}
                    onAdd={(val) => props.onAdd(video, val)}
                  />
                ) : (
                  <StyledMaterialSearchVideoCard
                    key={`youtube-video-id-${index}`}
                    title={video.title}
                    author={video.snippet.channelTitle}
                    image={video.image}
                    onClick={() => {
                      props.videos.onOpen(video);
                      setVideoToPreview(video);
                    }}
                    added={props.resourcesToBeAdded.some(
                      (resource) => resource.id === video.id
                    )}
                    onAdd={(val) => props.onAdd(video, val)}
                    openedAt={
                      props.recentlyOpened.find(
                        (openedResource) =>
                          openedResource.resource.id === video.id
                      )?.openedAt
                    }
                  />
                )
              )}
          </Box>
        </ScrollBox>
      </TabItem>
      <TabItem
        icon={<OER />}
        title="Open Ed. Resources"
        quantity={props.OER.OERList.length}
      >
        {props.loading && (
          <Box height="100px" justify="center" align="center">
            <Loading />
          </Box>
        )}
        <ScrollBox>
          <Box direction="row" wrap={true}>
            {props.loading === false &&
              props.OER.OERList.map((item, index) => (
                <MaterialsSearchOERCard
                  title={item.title}
                  summary={item.summary!}
                  key={`oer-id-${index}`}
                  onClick={() => props.OER.onOpen(item)}
                  openedAt={
                    props.recentlyOpened.find(
                      (openedResource) => openedResource.resource.id === item.id
                    )?.openedAt
                  }
                  added={props.resourcesToBeAdded.some(
                    (resource) => resource.id === item.id
                  )}
                  onAdd={(val) => props.onAdd(item, val)}
                />
              ))}
          </Box>
        </ScrollBox>
      </TabItem>
    </TabsContainer>
  );
};

export default MaterialsList;
