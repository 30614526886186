import { useAppSelector } from "../redux/configureStore";
import { transformValues } from "../utils/fbUtil";

const useOneWeekPlanTempSyllabus = () => {
  const tempSyllabus = useAppSelector(
    (state) => state.oneWeekPlan.tempSyllabus
  );
  const keyList = ["startDate", "endDate", "start_at", "end_at", "dueDate"];

  return transformValues(
    tempSyllabus,
    (key, value) => keyList.some((val) => val === key) && !!Date.parse(value),
    (value) => new Date(value)
  );
};

export default useOneWeekPlanTempSyllabus;
