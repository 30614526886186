import { Module } from "./Syllabus";
import { v4 as uuidv4 } from "uuid";
import { add, endOfWeek, isBefore, startOfWeek } from "date-fns";
import { Activity } from "./Resource";
import { JSONContent } from "@tiptap/react";

class ScheduleModuleFactory {
  createMany(start: Date, end: Date): Module[] {
    const modules: Module[] = [];

    if (isBefore(end, start)) {
      throw new Error(`start_at must be before end_at`);
    }
    let startMoment = startOfWeek(start);
    const endMoment = endOfWeek(end);

    while (isBefore(startMoment, endMoment)) {
      const startOfWeekDate = startOfWeek(startMoment);
      const endOfWeekDate = endOfWeek(startMoment);

      const newModule: Module = {
        id: uuidv4(),
        title: "Week " + (modules.length + 1),
        type: "Module",
        objectives: {
          type: "doc",
          content: [
            {
              type: "paragraph",
            },
          ],
        },
        start_at: startOfWeekDate,
        end_at: endOfWeekDate,
        activities: [],
      };

      modules.push(newModule);

      startMoment = add(startMoment, {
        weeks: 1,
      });
    }

    return modules;
  }

  createFromQuantity(
    start: Date,
    quantity: number,
    weekNumber: number,
    objectives?: JSONContent,
    activities?: Activity[]
  ): Module[] {
    const modules: Module[] = [];
    const startMoment = startOfWeek(start);

    for (let x = 1; x <= quantity; x++) {
      const startOfWeekDate = startOfWeek(add(startMoment, { weeks: x }));
      const endOfWeekDate = endOfWeek(startOfWeekDate);

      const newModule: Module = {
        id: uuidv4(),
        title: "Week " + (weekNumber + x),
        type: "Module",
        objectives: objectives ?? {
          type: "doc",
          content: [
            {
              type: "paragraph",
            },
          ],
        },
        start_at: startOfWeekDate,
        end_at: endOfWeekDate,
        activities: activities ?? [],
      };

      modules.push(newModule);
    }

    return modules;
  }
}

export default ScheduleModuleFactory;
