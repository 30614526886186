import React from "react";
import { Blank, IconProps } from "grommet-icons";

const BulletList: React.FC<IconProps> = (props) => {
  return (
    <Blank viewBox="0 0 20 18" {...props} color="plain">
      <svg
        width="20"
        height="18"
        viewBox="0 0 20 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect x="4" width="15" height="2" rx="1" fill="#030B1D" />
        <rect x="4" y="5" width="15" height="2" rx="1" fill="#030B1D" />
        <rect x="4" y="10" width="15" height="2" rx="1" fill="#030B1D" />
        <rect width="2" height="2" rx="1" fill="#030B1D" />
        <rect y="5" width="2" height="2" rx="1" fill="#030B1D" />
        <rect y="10" width="2" height="2" rx="1" fill="#030B1D" />
      </svg>
    </Blank>
  );
};
export default BulletList;
