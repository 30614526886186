import React from "react";
import { Blank, IconProps } from "grommet-icons";

const ArrowPrevious: React.FC<IconProps> = (props) => {
  return (
    <Blank viewBox="0 0 24 24" {...props} color="plain">
      <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
      >
        <path
          fill="currentColor"
          d="M18,11H8.81l3.3-3.29a1,1,0,0,0-1.42-1.42L5,12l5.7,5.71a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42L8.81,13H18a1,1,0,0,0,0-2Z"
        />
      </svg>
    </Blank>
  );
};
export default ArrowPrevious;
