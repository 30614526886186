import React, { useState } from "react";
import { Instructor, Instructors, Office } from "../../../model/Syllabus";
import SectionEditorProps from "./SectionEditorProps";
import useDelayedState from "../../../hooks/useDelayedState";
import RoundedButton from "../../ui-kit/RoundedButton/RoundedButton";
import { Box, Keyboard } from "grommet";
import IconButton from "components/IconButton";
import { Close } from "grommet-icons";
import Alert from "components/Alert";
import { HidableComponent } from "components/hoc/Hidable";
import TextInput from "components/ui-kit/TextInput/TextInput";

interface InstructorEditorProps {
  instructor: Instructor;
  onChange: (instructor: Instructor) => void;
  onDelete: () => void;
  index?: number;
  error?: any;
}

const InstructorEditor: React.FC<InstructorEditorProps> = (props) => {
  const [instructor, setInstructor] = useDelayedState<Instructor>(
    { ...props.instructor },
    props.onChange
  );

  return (
    <Box margin="xxsmall" round="small" pad="xsmall" elevation="small">
      <Box margin="xxsmall">
        <Keyboard onEnter={() => props.onDelete()}>
          <IconButton
            autoFocus={true}
            styles={{ textAlign: "right" }}
            icon={<Close size="small" />}
            onClick={() => {
              props.onDelete();
            }}
            a11yTitle="Delete Instructor"
          />
        </Keyboard>
        <Box margin="xxsmall">
          <TextInput
            required={true}
            label="Name"
            value={instructor.name}
            onChange={(event) => {
              if (event.target.value !== undefined) {
                setInstructor({
                  ...instructor,
                  name: event.target.value,
                });
              }
            }}
            a11yTitle="Name"
            placeholder="Add instructor’s name"
            error={props.error?.errors[`instructors[${props.index}].name`]}
          />
        </Box>
        <Box margin="xxsmall">
          <TextInput
            label="Title"
            value={instructor.title ? instructor.title : ""}
            onChange={(event) => {
              if (event.target.value !== undefined) {
                setInstructor({
                  ...instructor,
                  title: event.target.value,
                });
              }
            }}
            a11yTitle="Title"
          />
        </Box>
        <Box margin="xxsmall">
          <TextInput
            label="Email"
            value={instructor.email}
            onChange={(event) => {
              if (event.target.value !== undefined) {
                setInstructor({
                  ...instructor,
                  email: event.target.value,
                });
              }
            }}
            a11yTitle="Email"
          />
        </Box>
        <Box margin="xxsmall">
          <TextInput
            label="Phone Number"
            value={instructor.phone}
            onChange={(event) => {
              if (event.target.value !== undefined) {
                setInstructor({
                  ...instructor,
                  phone: event.target.value,
                });
              }
            }}
            a11yTitle="Phone number"
            placeholder="How can students contact this instructor?"
          />
        </Box>
        <Box margin="xxsmall">
          <TextInput
            label="Office Room"
            value={instructor.office?.room ? instructor.office?.room : ""}
            onChange={(event) => {
              if (event.target.value !== undefined) {
                const office: Office =
                  instructor.office === undefined
                    ? {
                        room: "",
                        building: "",
                        mailingAddress: "",
                      }
                    : { ...instructor.office };
                office.room = event.target.value;
                setInstructor({
                  ...instructor,
                  office: office,
                });
              }
            }}
            a11yTitle="Office Room"
          />
        </Box>

        <Box margin="xxsmall">
          <TextInput
            label="Office Building"
            value={
              instructor.office?.building ? instructor.office?.building : ""
            }
            onChange={(event) => {
              if (event.target.value !== undefined) {
                const office: Office =
                  instructor.office === undefined
                    ? {
                        room: "",
                        building: "",
                        mailingAddress: "",
                      }
                    : { ...instructor.office };
                office.building = event.target.value;
                setInstructor({
                  ...instructor,
                  office: office,
                });
              }
            }}
            a11yTitle="Office Building"
          />
        </Box>

        <Box margin="xxsmall">
          <TextInput
            label="Mailing Address"
            value={
              instructor.office?.mailingAddress
                ? instructor.office?.mailingAddress
                : ""
            }
            onChange={(event: any) => {
              if (event.target.value !== undefined) {
                const office: Office =
                  instructor.office === undefined
                    ? {
                        room: "",
                        building: "",
                        mailingAddress: "",
                      }
                    : { ...instructor.office };
                office.mailingAddress = event.target.value;
                setInstructor({
                  ...instructor,
                  office: office,
                });
              }
            }}
            a11yTitle="Mailing Address"
          />
        </Box>
      </Box>
    </Box>
  );
};

const InstructorsEditor: React.FC<SectionEditorProps> = ({
  section,
  onChange,
  error,
}) => {
  const [_section, _setSection] = useState({ ...(section as Instructors) });
  const [indexOffset, setIndexOffset] = useState(0);

  // Propagate changes to parent
  const setSection = (newSection: Instructors) => {
    onChange(newSection);
    _setSection(newSection);
  };

  const onChangeInstructor = (instructor: Instructor, index: number) => {
    const newInstructors = [..._section.instructors];
    newInstructors[index] = instructor;
    setSection({
      ..._section,
      instructors: newInstructors,
    });
  };

  const addInstructor = () => {
    setSection({
      ..._section,
      instructors: [
        ..._section.instructors,
        { name: "", phone: "", email: "" },
      ],
    });
  };

  const deleteInstructor = (index: number) => {
    const newInstructorsSection = _section;
    newInstructorsSection.instructors.splice(index, 1);
    setSection(newInstructorsSection);
    setIndexOffset(indexOffset + newInstructorsSection.instructors.length + 1);
  };

  return (
    <>
      {_section.instructors.map((instructor, index) => (
        <InstructorEditor
          key={"instructor-" + index + indexOffset}
          instructor={instructor}
          onChange={(instructor) => onChangeInstructor(instructor, index)}
          onDelete={() => {
            deleteInstructor(index);
          }}
          index={index}
          error={error}
        />
      ))}
      <Box margin={{ vertical: "small" }} width="small">
        <RoundedButton type={"primary"} onClick={() => addInstructor()}>
          + Add
        </RoundedButton>
      </Box>
      <HidableComponent isVisible={!!error?.errors.instructors}>
        <Alert>{error?.errors.instructors}</Alert>
      </HidableComponent>
    </>
  );
};

export default InstructorsEditor;
