import {
  StatisticsActions,
  StatisticsActionType,
} from "../types/StatisticsTypes";

export interface StatisticsState {
  /**
   * how many times has a user visited this page
   */
  nVisits: number;
  /**
   * has this user already viewed the feedback dialog?
   */
  didShowFeedbackDialog: boolean;
}

const initialState: StatisticsState = {
  nVisits: 0,
  didShowFeedbackDialog: false,
};

export const StatisticsReducer = (
  state = initialState,
  action: StatisticsActions
): StatisticsState => {
  switch (action.type) {
    case StatisticsActionType.AddVisit:
      return {
        ...state,
        nVisits: state.nVisits + 1,
      };
    case StatisticsActionType.SetVisits:
      return {
        ...state,
        nVisits: action.nVisits,
      };
    case StatisticsActionType.DidShowFeedbackDialog:
      return {
        ...state,
        didShowFeedbackDialog: true,
      };
    default:
      return state;
  }
};
