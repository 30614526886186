import {
  CourseTitle,
  Instructor,
  Instructors,
  Schedule,
  Syllabus,
} from "./Syllabus";
import ScheduleModuleFactory from "./ScheduleModuleFactory";
import createEmptySection from "../components/editor/SectionFactory";
import { v4 as uuidv4 } from "uuid";
import { appConfig } from "../config/configCommon";

interface SyllabusFactoryOptions {
  createdBy: string;
  title: string;
  courseNumber: string;
  semester: string;
  from?: string;
  to?: string;
  days?: boolean[];
  school: string;
  instructor?: string;
}

class SyllabusFactory {
  create(options: SyllabusFactoryOptions): Syllabus {
    const newSyllabus: Syllabus = {
      createdBy: options.createdBy,
      createdAt: new Date(),
      version: appConfig.syllabusVersion as unknown as number,
      layout: {
        theme: "Plain Jane",
      },
      sections: [],
      shared: false,
      sharedWithTeacher: false,
      parentId: uuidv4(),
      customization: {
        fontFamily: "Open Sans",
        primaryColor: "#193DA9",
      },
    };

    const courseTitle = createEmptySection("CourseTitle") as CourseTitle;
    courseTitle.title = options.title;
    courseTitle.courseNumber = options.courseNumber;
    courseTitle.semester = options.semester;
    courseTitle.school = options.school;

    newSyllabus.sections.push(courseTitle);

    if (options.instructor) {
      const instructor: Instructor = {
        name: options.instructor,
        email: "",
        phone: "",
      };

      const instructors = createEmptySection("Instructors") as Instructors;
      instructors.instructors.push(instructor);
      newSyllabus.sections.push(instructors);
    }

    if (options.from && options.to && options.days) {
      const modulesFactory = new ScheduleModuleFactory();
      const schedule = createEmptySection("Schedule") as Schedule;
      schedule.modules = modulesFactory.createMany(
        new Date(options.from),
        new Date(options.to)
      );

      newSyllabus.sections = [...newSyllabus.sections, schedule];
    }

    return newSyllabus;
  }
}

export default SyllabusFactory;
