import React from "react";
import { EditorContent, JSONContent, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import FileComponent from "../CustomComponents/FileComponent";
import { Link } from "@tiptap/extension-link";
import { Image } from "@tiptap/extension-image";
import { Underline } from "@tiptap/extension-underline";
import styled from "styled-components";

interface RichTextEditorViewerProps {
  value: JSONContent;
  id?: string;
}

const StyledEditorContent = styled(EditorContent)`
  & .ProseMirror blockquote {
    background: #eee;
    border-radius: 4px;
    padding: 10px 18px;
    margin: 0;
  }

  & .ProseMirror pre {
    background: #eee;
    border-radius: 4px;
    padding: 10px 18px;
    margin: 8px 0;
  }

  & > div p {
    margin: 4px 0;
  }
`;

const RichTextEditorViewer: React.FC<RichTextEditorViewerProps> = (props) => {
  const editor = useEditor({
    extensions: [StarterKit, FileComponent, Link, Image, Underline],
    editable: false,
    content: props.value,
  });
  return <StyledEditorContent editor={editor} />;
};

export default RichTextEditorViewer;
