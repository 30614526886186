/*******************************
 *     Common Development Config
 ******************************/
import { canvasScopes } from "./canvasScopes";

// Need to add scopes to the requests in order for it to work once scopes have been defined.
// Source: https://community.canvaslms.com/t5/Canvas-Developers-Group/Enforcing-scope-on-developer-key-breaks-OAuth-flow/td-p/142854
export const canvasConfig = {
  clientId: process.env.REACT_APP_CANVAS_CLIENT_ID,
  clientSecret: process.env.REACT_APP_CANVAS_CLIENT_SECRET,
  canvasURL: process.env.REACT_APP_CANVAS_URL,
  canvasRedirectUrl: process.env.REACT_APP_CANVAS_REDIRECT_URL,
  scopes: canvasScopes,
};

export const stripeConfig = {
  publicKey: process.env.REACT_APP_STRIPE_PUBLIC_KEY,
};

export const stripeConfigPrivate = {
  ...stripeConfig,
  ...{
    secretKey: process.env.REACT_APP_STRIPE_PRIVATE_KEY,
  },
};

export const rrfConfig = {
  useFirestoreForProfile: process.env.REACT_APP_RFF_USE_FIRESTORE_FOR_PROFILE,
  userProfile: process.env.REACT_APP_RFF_USER_PROFILE,
};

export const youtubeConfig = {
  key: process.env.REACT_APP_YOUTUBE_KEY,
};

export const googleBooksConfig = {
  key: process.env.REACT_APP_GOOGLE_BOOKS_KEY,
};
export const customSearchConfig = {
  key: process.env.REACT_APP_GOOGLE_CUSTOM_SEARCH_KEY,
  cx: process.env.REACT_APP_GOOGLE_CUSTOM_SEARCH_CX,
};

export const firebaseConfig = {
  useSimulator: process.env.REACT_APP_FIREBASE_USE_SIMULATOR,
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
  serviceAccountId: process.env.REACT_APP_FIREBASE_SERVICE_ACCOUNT_ID,
};

export const bubbleConfig = {
  accessToken: process.env.REACT_APP_BUBBLE_KEY,
  apiBase: process.env.REACT_APP_BUBBLE_URL,
};

export const mixpanelConfig = {
  token: process.env.REACT_APP_MIXPANEL_KEY,
};

export const reflectConfig = {
  avoidRecaptchaUsingReflect: process.env.REACT_APP_REFLECT_NO_CAPTCHA,
};

export const blackboardConfig = {
  clientId: process.env.REACT_APP_BLACKBOARD_CLIENT_ID,
  blackboardUrl: process.env.REACT_APP_BLACKBOARD_URL,
  blackboardRedirect: process.env.REACT_APP_BLACKBOARD_REDIRECT_URL,
};

export const appConfig = {
  syllabusVersion: process.env.REACT_APP_SYLLABUS_VERSION,
  authorizeDocUrl: process.env.REACT_APP_AUTHORIZE_DOC_URL,
  authorisationDone: process.env.REACT_APP_AUTHORIZATION_DONE,
  canvasLtiUrl: process.env.REACT_APP_CANVAS_LTI_URL,
  presentDocUrl: process.env.REACT_APP_PRESENT_DOC_URL,
  completeUrl: process.env.REACT_APP_COMPLETE_URL,
};

export const webexConfig = {
  redirectUrl: process.env.REACT_APP_WEBEX_REDIRECT_URL,
  clientId: process.env.REACT_APP_WEBEX_CLIENT_ID,
  clientSecret: process.env.REACT_APP_WEBEX_CLIENT_SECRET,
};

export const teamsConfig = {
  redirectUrl: process.env.REACT_APP_TEAMS_REDIRECT_URL,
  clientId: process.env.REACT_APP_TEAMS_CLIENT_ID,
  clientSecret: process.env.REACT_APP_TEAMS_CLIENT_SECRET,
};

export const courseMapConfig = {
  clientId: process.env.REACT_APP_COURSEMAP_CLIENT_ID,
  clientSecret: process.env.REACT_APP_COURSEMAP_CLIENT_SECRET,
  redirectUri: process.env.REACT_APP_COURSEMAP_REDIRECT_URI,
  baseUrl: process.env.REACT_APP_COURSEMAP_BASE_URL,
};
