import { useAppSelector } from "../redux/configureStore";
import { transformValues } from "../utils/fbUtil";

const useTempSyllabus = () => {
  const tempSyllabus = useAppSelector((state) => state.onboarding.tempSyllabus);

  return transformValues(
    tempSyllabus,
    (key, value) => key === "startDate" && !!Date.parse(value),
    (value) => new Date(value)
  );
};

export default useTempSyllabus;
