import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Avatar, Box, Header, Image, Menu, Nav } from "grommet";
import { useHistory } from "react-router-dom";
import RoundedButton from "../components/ui-kit/RoundedButton/RoundedButton";
import SnackbarLists from "./SnackbarLists";
import SignUpModal from "../components/one-week-plan/SignUpModal/SignUpModal";

export const LayoutTopbar: React.FC = ({ children }) => {
  // @ts-ignore - TODO: can we add firebase to the default state somehow?
  const auth = useSelector((state) => state.firebase.auth);
  const menuItems = [
    {
      label: "Account",
      onClick: () => {
        history.push("/account");
      },
    },
    {
      label: "Dashboard",
      onClick: () => {
        history.push("/dashboard");
      },
    },
    {
      label: "Logout",
      onClick: () => {
        history.push("/logout");
      },
    },
  ];
  const history = useHistory();

  const [signUpModalOpened, setSignUpModalOpened] = useState(false);

  return (
    <>
      <SignUpModal
        closeButton={true}
        handleClose={() => setSignUpModalOpened(false)}
        opened={signUpModalOpened}
      />
      <Header
        pad={{ vertical: "none", horizontal: "small" }}
        elevation="small"
        background="white"
      >
        <Box>
          <a href="/" title="BlendED">
            <Image src="/images/logos/logo.svg" alt="BlendED" width="125px" />
          </a>
        </Box>
        <Nav direction="row">
          {auth.uid && (
            <>
              <Menu
                icon={false}
                size="medium"
                a11yTitle="User's menu"
                messages={{
                  openMenu: "Open Menu",
                  closeMenu: "Close Menu",
                }}
                label={
                  <Avatar
                    src={"/images/placeholders/sample-avatar.png"}
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                  />
                }
                items={menuItems}
              />
            </>
          )}
          {!auth.uid && (
            <Box
              direction="row-reverse"
              pad={{ vertical: "small" }}
              tabIndex={1}
            >
              <RoundedButton
                type="primary"
                onClick={() => setSignUpModalOpened(true)}
              >
                Create an Account
              </RoundedButton>
            </Box>
          )}
        </Nav>
      </Header>
      <main>
        <div />
        {children}
        <SnackbarLists />
      </main>
    </>
  );
};
