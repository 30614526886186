import React from "react";
import { Location } from "../../../model/Syllabus";
import styled from "styled-components";
import Container from "../../viewer/customization/Container";
import H4 from "../../viewer/customization/H4";
import RichTextEditorViewer from "../../ui-kit/RichTextEditor/Viewer/RichTextEditorViewer";
import { createHash } from "../../../helpers/createHash";

interface LocationSectionProps {
  section: Location;
}

const StyledContainer = styled(Container)`
  padding: 4px;
`;

const LocationSection: React.FC<LocationSectionProps> = (props) => {
  const section = props.section;

  return (
    <>
      <H4 a11yTitle={"Location Section"}>Location</H4>
      <StyledContainer>{section.kind ?? ""}</StyledContainer>
      <StyledContainer>
        <RichTextEditorViewer
          value={section.description!}
          key={createHash(JSON.stringify(section.description))}
        />
      </StyledContainer>
    </>
  );
};

export default LocationSection;
