import React from "react";
import styled from "styled-components";

interface AlertProps {}

const StyledDiv = styled.div`
  background: #f7d7d8;
  border-radius: 4px;
  border: 1px solid #f6d4d7;
  color: #8c434a;
  padding: 8px 16px;
  font-size: 14px;
  margin: 4px 0;
`;

const Alert: React.FC<AlertProps> = (props) => {
  return <StyledDiv>{props.children}</StyledDiv>;
};

export default Alert;
