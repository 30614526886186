import React from "react";
import { Blank, IconProps } from "grommet-icons";
import { ChildrenIconProps } from "./FileTypeIcon";

const Open: React.FC<IconProps & ChildrenIconProps> = (props) => {
  return (
    <Blank viewBox="0 0 24 24" {...props} color="plain">
      <svg
        version="1.1"
        id="Layer_1"
        x="0px"
        y="0px"
        viewBox="0 0 24 24"
        xmlSpace="preserve"
      >
        <path
          fill="currentColor"
          d="M21.9,2.6c-0.1-0.2-0.3-0.4-0.5-0.5C21.3,2,21.1,2,21,2h-6c-0.6,0-1,0.4-1,1s0.4,1,1,1h3.6l-7.3,7.3
		c-0.4,0.4-0.4,1,0,1.4c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3L20,5.4V9c0,0.6,0.4,1,1,1s1-0.4,1-1V3C22,2.9,22,2.7,21.9,2.6z"
        />
        <path
          fill="currentColor"
          d="M21,12c-0.6,0-1,0.4-1,1v6c0,0.6-0.4,1-1,1H5c-0.6,0-1-0.4-1-1V5c0-0.6,0.4-1,1-1h6c0.6,0,1-0.4,1-1
		s-0.4-1-1-1H5C3.3,2,2,3.3,2,5v14c0,1.7,1.3,3,3,3h14c1.7,0,3-1.3,3-3v-6C22,12.4,21.6,12,21,12z"
        />
      </svg>
    </Blank>
  );
};

export default Open;
