import React, { useRef } from "react";
import styled from "styled-components";
import { Keyboard, Sidebar } from "grommet";
import { Section, SectionType, Syllabus } from "../../model/Syllabus";
import ArrowLeftIcon from "../../assets/images/arrow-left.svg";
import ClockIcon from "../../assets/images/clock.svg";
import { HTML5Backend } from "react-dnd-html5-backend";
import SyllabusEditorSidebarContainer from "./SyllabusEditorSidebarContainer";
import {
  createTransition,
  MouseTransition,
  DndProvider,
} from "react-dnd-multi-backend";
import KeyboardBackend, {
  isKeyboardDragTrigger,
} from "react-dnd-accessible-backend";
import CustomStyleWrapper from "../CustomStyleWrapper/CustomStyleWrapper";
import { FontFamily } from "../viewer/customization/FontConfiguration";

interface SyllabusEditorSidebarProps {
  syllabus: Syllabus;
  onSectionOrderChange: (sections: Section[]) => void;
  onSectionAdd?: (type: SectionType) => void;
  onSectionEdit?: (section: Section) => void;
  onModuleAdd?: () => void;
  overridePrivateActions?: () => void;
  needsRegistration?: boolean;
  /**
   * On click in "Back to dashboard" button
   */
  onClickBackButton?: () => void;
  onSelectedFont?: (font: FontFamily) => void;
  selectedFont?: FontFamily;
  onSelectedColor?: (color: string) => void;
  selectedColor?: string;
}

const StyledSidebar = styled(Sidebar)`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 19em;
  background: #f5f8fa;
  box-shadow: 6px 0 15px rgba(7, 26, 67, 0.1);
  z-index: 1;
  padding-right: 40px;
  padding-left: 40px;
  padding-bottom: 40px;
`;

const SectionTitleStyled = styled.h2`
  color: #193da9;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24.51px;
  margin-top: 22px;
`;

const SectionDescriptionStyled = styled.p`
  color: #030b1d;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16.34px;
  margin-top: -10px;
`;

const SidebarHeaderBackText = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-size: 16px;
  font-weight: 600;
  line-height: 21.79px;
  margin-top: 8px;
  display: flex;
  color: #030b1d;

  &:hover {
    cursor: pointer;
  }

  p {
    padding-left: 22px;
  }
`;

const SidebarHeaderBackSubtitle = styled.div`
  font-family: Open Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0;
  color: #474e61;
  text-align: left;
  margin-top: -8px;
  display: flex;

  p {
    padding-left: 5px;
  }
`;

const ScrollableDiv = styled.div`
  top: 0;
  left: 0;
  height: 82vh;
  overflow-y: scroll;
  margin-bottom: 50px;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }

  :hover {
    ::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: rgba(0, 0, 0, 0.5);
      -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    }
  }
`;

const BorderGrey = styled.div`
  border-bottom: 1px solid #d0dcf1;
  margin-top: 5px;
`;

const SyllabysEditorSidebar: React.FC<SyllabusEditorSidebarProps> = ({
  syllabus,
  onSectionAdd,
  onSectionEdit,
  onModuleAdd,
  onSectionOrderChange,
  overridePrivateActions,
  onClickBackButton,
  onSelectedColor,
  onSelectedFont,
  selectedColor,
  selectedFont,
}) => {
  const KeyboardTransition = createTransition("keydown", (event) => {
    if (!isKeyboardDragTrigger(event as KeyboardEvent)) return false;
    event.preventDefault();
    return true;
  });

  const sidebarRef = useRef<HTMLDivElement | null>(null);

  const elementIsAPill = (event: DragEvent) => {
    const target = event.target! as HTMLElement;
    const children = target.children;
    if (!children) return false;
    if (target.dataset.type === "pill") return true;
    // @ts-ignore
    for (let child of children) {
      if (child.dataset.type === "pill") {
        return true;
      }
    }
    return false;
  };

  const ModifiedBackend = (args: any) => {
    const instance = HTML5Backend(args);

    const listeners = [
      "handleTopDragStart",
      "handleTopDragStartCapture",
      "handleTopDragEndCapture",
      "handleTopDragEnter",
      "handleTopDragEnterCapture",
      "handleTopDragLeaveCapture",
      "handleTopDragOver",
      "handleTopDragOverCapture",
      "handleTopDrop",
      "handleTopDropCapture",
    ];
    listeners.forEach((name: string) => {
      // @ts-ignore
      const original = instance[name];
      // @ts-ignore
      instance[name] = (e: DragEvent, ...extraArgs) => {
        if (elementIsAPill(e)) {
          original(e, ...extraArgs);
        }
      };
    });

    return instance;
  };

  const DND_OPTIONS = {
    backends: [
      {
        id: "html5",
        backend: ModifiedBackend,
        transition: MouseTransition,
        options: {
          rootElement: sidebarRef.current,
        },
      },
      {
        id: "keyboard",
        backend: KeyboardBackend,
        context: { window, document },
        preview: true,
        transition: KeyboardTransition,
      },
    ],
  };

  return (
    <StyledSidebar a11yTitle="Section Sidebar">
      <div ref={sidebarRef}>
        <div>
          <Keyboard
            onEnter={() => {
              if (overridePrivateActions) {
                overridePrivateActions();
                return;
              }
              onClickBackButton?.();
            }}
          >
            <SidebarHeaderBackText
              tabIndex={1}
              onClick={() => {
                if (overridePrivateActions) {
                  overridePrivateActions();
                  return;
                }

                onClickBackButton?.();
              }}
            >
              <img src={ArrowLeftIcon} alt="Arrow Left Icon" />
              <p>Back to Dashboard</p>
            </SidebarHeaderBackText>
          </Keyboard>
          <SidebarHeaderBackSubtitle>
            <img src={ClockIcon} alt="Clock Icon" />
            <p>Your progress is automatically saved</p>
          </SidebarHeaderBackSubtitle>
          <BorderGrey />
        </div>
        <ScrollableDiv>
          <div>
            <SectionTitleStyled>Style</SectionTitleStyled>
            <CustomStyleWrapper
              onSelectedFont={onSelectedFont}
              onSelectedColor={onSelectedColor}
              selectedFont={selectedFont}
              selectedColor={selectedColor}
            />
          </div>
          <div>
            <SectionTitleStyled>Sections</SectionTitleStyled>
            <SectionDescriptionStyled>
              Drag and drop created sections to reorder them.
            </SectionDescriptionStyled>
          </div>
          <div>
            {/*@ts-ignore*/}
            <DndProvider options={DND_OPTIONS}>
              <SyllabusEditorSidebarContainer
                syllabus={syllabus}
                onSectionsChange={onSectionOrderChange}
                onSectionAdd={onSectionAdd}
                onSectionEdit={onSectionEdit}
                onModuleAdd={onModuleAdd}
              />
            </DndProvider>
          </div>
        </ScrollableDiv>
      </div>
    </StyledSidebar>
  );
};

export default SyllabysEditorSidebar;
