import React from "react";
import styled from "styled-components";

import type { HeaderProps } from "../../../types";

const StyledHeaderWrapper = styled.div`
  tr {
    height: 29px;

    td {
      width: 100%;
      height: 29px;

      h2 {
        text-align: center;
      }

      span {
        color: #ffffff;
      }
    }
  }
`;

const Header: React.FC<HeaderProps> = (props) => {
  return (
    <StyledHeaderWrapper>
      <tbody>
        <tr style={{ height: "29px" }}>
          <td style={{ width: "100%", height: "29px" }}>
            <h2 style={{ textAlign: "center" }}>
              <span style={{ color: "#ffffff" }}>
                {props.title} ({props.number})
              </span>
            </h2>
          </td>
        </tr>
      </tbody>
    </StyledHeaderWrapper>
  );
};

export default Header;
