import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  AppState,
  useAppDispatch,
  useAppSelector,
} from "../redux/configureStore";
import { useHistory } from "react-router-dom";
import { Syllabus } from "../model/Syllabus";
import { useFirebase, useFirestore } from "react-redux-firebase";
import { useGetMySyllabi } from "../hooks/useGetSyllabus";
import TermsOfUseDialog from "./TermsOfUseDialog";
import { setEditMode } from "../redux/common/actions/CommonActions";
import SyllabusListViewer from "./SyllabusListViewer";

/**
 * The dashboard component for listing a users available common.  These are pulled from firebase using the useGetMySyllabi hook.  Clicking the 'Add Syllabus' button opens a modal to either direct users to the template gallery or input basic information for creating a new syllabus.
 */
const SyllabusList: React.FC = () => {
  const history = useHistory();
  const firebase = useFirebase();
  const firestore = useFirestore();
  const auth = useSelector((state: AppState) => state.firebase.auth);
  const profile = useAppSelector((state) => state.firebase.profile);
  const [syllabi, , , getAllSyllabi] = useGetMySyllabi();
  const [, setOpenDialog] = useState(false);
  const [isOpenDialog, setIsOpenDialog] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!profile.acceptedTermsOfUse) {
      setIsOpenDialog(true);
    }
  }, [profile]);

  useEffect(() => {
    if (profile.role === "Student") {
      history.push("/students");
    }
  }, [profile, history]);

  const handleSave = async (emptySyllabus: Syllabus) => {
    try {
      const docRef = await firestore.collection("syllabi").add({
        ...emptySyllabus,
        createdAt: firestore.FieldValue.serverTimestamp(),
      });
      const doc = await docRef.get();
      window.analytics.track("Successfully created a Syllabus", {
        syllabusId: doc.id,
      });
      history.push("/edit/" + doc.id);
    } catch (error) {
      window.analytics.track("Creating a Syllabus Failed");
      console.log(error.message);
    }
    dispatch(setEditMode(true));
    setOpenDialog(false);
  };

  const updateProfile = async () => {
    const email = profile.email !== undefined ? profile.email : auth.email;
    if (email !== undefined) {
      await firebase.updateProfile({
        email: email,
        acceptedTermsOfUse: true,
      });
    }
  };

  const renderTermsAndConditions = (
    <TermsOfUseDialog
      open={isOpenDialog}
      title={"Terms of Use"}
      onClickClose={() => {
        history.push("/logout");
        setIsOpenDialog(false);
      }}
      onClickAccept={() => {
        setIsOpenDialog(false);
        updateProfile();
      }}
    />
  );

  return (
    <>
      <SyllabusListViewer
        syllabi={syllabi}
        handleSave={(emptySyllabus) => handleSave(emptySyllabus)}
        onDeleteSyllabus={async (syllabusId) => {
          await firestore.collection("syllabi").doc(syllabusId).delete();
          window.analytics.track("Deleted Syllabus", {
            syllabusId: syllabusId,
          });
          await getAllSyllabi();
        }}
      />
      {renderTermsAndConditions}
    </>
  );
};

export default SyllabusList;
