export function getMimeType(fileType: string): string {
  switch (fileType.toUpperCase()) {
    case "MSWORD": //DOC
      return "application/msword"; //DOCX
    case "VND.OPENXMLFORMATS-OFFICEDOCUMENT.WORDPROCESSINGML.DOCUMENT":
      return "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
    case "VND.MS-POWERPOINT": //PPT
      return "application/vnd.ms-powerpoint";
    case "VND.OPENXMLFORMATS-OFFICEDOCUMENT.PRESENTATIONML.PRESENTATION": //PPTX
      return "application/vnd.openxmlformats-officedocument.presentationml.presentation";
    case "VND.MS-EXCEL": //XLS
      return "application/vnd.ms-excel";
    case "VND.OPENXMLFORMATS-OFFICEDOCUMENT.SPREADSHEETML.SHEET": //XLSX
      return "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    case "PDF":
      return "application/pdf";
    case "RTF":
      return "application/rtf";
    case "CSV":
      return "text/csv";
    case "TSV":
      return "text/tab-separated-values";
    case "GIF":
      return "image/gif";
    case "PNG":
      return "image/png";
    case "JPEG":
      return "image/jpeg";
    case "SVG+XML":
      return "image/svg+xml";
    case "WEBP":
      return "image/webp";
    case "MP4":
      return "video/mp4";
    case "WEBM":
      return "video/webm";
    case "OGG":
      return "video/ogg";
    case "QUICKTIME": //MOV
      return "video/quicktime";
    case "MPEG": //MP3
      return "audio/mp3";
    case "WAV":
      return "audio/wav";
    case "X-M4A": //M4A
      return "audio/m4a";
    case "VIDEO": //We are saving youtube resources with type "video" in firestore
      return "custom/youtube";
    default:
      return "Mime type not found";
  }
}
