import React from "react";
import { CreditHours } from "../../../model/Syllabus";
import SectionEditorProps from "./SectionEditorProps";
import useDelayedState from "../../../hooks/useDelayedState";
import TextInput from "components/ui-kit/TextInput/TextInput";

const CreditHoursEditor: React.FC<SectionEditorProps> = ({
  section,
  onChange,
  error,
}) => {
  const [creditHours, setCreditHours] = useDelayedState<CreditHours>(
    { ...(section as CreditHours) },
    onChange
  );

  const onChangeCreditHours = (hours: string) => {
    setCreditHours({
      ...creditHours,
      creditHours: hours,
    });
  };

  return (
    <TextInput
      required={true}
      label="Credit Hours"
      value={creditHours.creditHours}
      onChange={(event) => {
        const regExp = /^[0-9]*$/;
        if (regExp.test(event.target.value)) {
          onChangeCreditHours(event.target.value);
        }
      }}
      a11yTitle={"Credit Hours Input"}
      placeholder={"Credit Hours"}
      error={error?.errors.creditHours}
    />
  );
};

export default CreditHoursEditor;
