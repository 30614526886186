import React from "react";
import { Blank, IconProps } from "grommet-icons";

const Delete: React.FC<IconProps> = (props) => {
  return (
    <Blank viewBox="0 0 24 24" {...props} color="plain">
      <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
      >
        <path
          fill="currentColor"
          d="M20,6H17V5a3,3,0,0,0-3-3H10A3,3,0,0,0,7,5V6H4A1,1,0,0,0,4,8H5V19a3,3,0,0,0,3,3h8a3,3,0,0,0,3-3V8h1a1,1,0,0,0,0-2ZM9,5a1,1,0,0,1,1-1h4a1,1,0,0,1,1,1V6H9Zm8,14a1,1,0,0,1-1,1H8a1,1,0,0,1-1-1V8H17Z"
        />
        <path
          fill="currentColor"
          d="M10,18a1,1,0,0,0,1-1V12a1,1,0,0,0-2,0v5A1,1,0,0,0,10,18Z"
        />
        <path
          fill="currentColor"
          d="M14,18a1,1,0,0,0,1-1V12a1,1,0,0,0-2,0v5A1,1,0,0,0,14,18Z"
        />
      </svg>
    </Blank>
  );
};
export default Delete;
