import React from "react";
import { Box } from "grommet";
import IconButton from "./IconButton";
import TextInput from "./ui-kit/TextInput/TextInput";
import Delete from "assets/icons/Delete";
import styled from "styled-components";

interface TextInputListItemProps {
  gridA11yTitle: string;
  value: string;
  boxA11yTitle: string;
  inputA11yTitle: string;
  inputPlaceholder: string;
  onInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  buttonA11yTitle: string;
  onButtonClick: () => void;
  autoFocus?: boolean;
  error?: string;
  label?: string;
  required?: boolean;
}

const StyledTextInput = styled(TextInput)`
  width: 90%;
`;

const TextInputListItem: React.FC<TextInputListItemProps> = (props) => {
  return (
    <Box direction="row" justify="between" a11yTitle={props.boxA11yTitle}>
      <StyledTextInput
        autoFocus={props.autoFocus}
        value={props.value}
        a11yTitle={props.inputA11yTitle}
        placeholder={props.inputPlaceholder}
        onChange={props.onInputChange}
        error={props.error}
        label={props.label}
        required={props.required}
      />
      <div>
        <IconButton
          styles={{ position: "relative", top: "29px" }}
          icon={<Delete size="18px" />}
          a11yTitle={props.buttonA11yTitle}
          onClick={() => {
            props.onButtonClick();
          }}
          tabIndex={0}
        />
      </div>
    </Box>
  );
};

export default TextInputListItem;
