import React from "react";
import { Blank, IconProps } from "grommet-icons";

const Reorder: React.FC<IconProps> = (props) => {
  return (
    <Blank viewBox="0 0 24 24" {...props} color="plain">
      <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
      >
        <circle fill="currentColor" cx="16" cy="12" r="2" />
        <circle fill="currentColor" cx="8" cy="12" r="2" />
        <circle fill="currentColor" cx="16" cy="5" r="2" />
        <circle fill="currentColor" cx="8" cy="5" r="2" />
        <circle fill="currentColor" cx="16" cy="19" r="2" />
        <circle fill="currentColor" cx="8" cy="19" r="2" />
      </svg>
    </Blank>
  );
};

export default Reorder;
