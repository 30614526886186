import React from "react";
import styled from "styled-components";

import DropdownMenu from "../DropdownMenu";
import DropItem from "../DropItem";
import DropdownArrow from "../../assets/icons/DropdownArrow";
import { FontFamily } from "../viewer/customization/FontConfiguration";
import { Keyboard, Box } from "grommet";

interface FontSelectorProps {
  onSelectedFont?: (font: FontFamily) => void;
  selectedFont?: FontFamily;
}

const availableFonts: FontFamily[] = [
  "Aleo",
  "Cormorant Garamond",
  "Lato",
  "Merriweather",
  "Nunito",
  "Open Sans",
  "Rubik",
  "Ubuntu",
];

const defaultProps: Partial<FontSelectorProps> = {
  selectedFont: "Open Sans",
};

const StyledTitle = styled.h2`
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #474e61;
  margin-bottom: 6px;
`;

const StyledLabelWrapper = styled.div`
  width: 220px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #e8f0fe;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgba(13, 34, 90, 0.15);
  border-radius: 4px;
  margin-bottom: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 8px;

  &:active {
    border: 1px solid #0d3af8;
  }
`;

const StyledLabel = styled.p`
  font-family: "${(props) => props.theme.fontFamily}", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #474e61;
`;

const StyledDropdownArrow = styled(DropdownArrow)`
  margin-top: 17.5px;
`;

const StyledDropItemWrapper = styled.div`
  max-height: 150px;
`;

const StyledDropItem = styled(DropItem)`
  height: 22px;
  font-family: "${(props) => props.theme.fontFamily}", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #030b1d;
`;

const FontSelector: React.FC<FontSelectorProps> = (props) => {
  return (
    <div>
      <StyledTitle>Font</StyledTitle>
      <DropdownMenu
        label={
          <StyledLabelWrapper>
            <StyledLabel theme={{ fontFamily: props.selectedFont }}>
              {props.selectedFont}
            </StyledLabel>
            <StyledDropdownArrow />
          </StyledLabelWrapper>
        }
        dropContent={
          <StyledDropItemWrapper>
            {availableFonts.map((font, index) => (
              <Keyboard
                key={`font-${index}`}
                onEnter={() => {
                  props.onSelectedFont?.(font);
                }}
              >
                <Box a11yTitle={availableFonts[index]}>
                  <StyledDropItem
                    key={`font-${index}`}
                    text={font}
                    theme={{ fontFamily: font }}
                    onClick={() => props.onSelectedFont?.(font)}
                  />
                </Box>
              </Keyboard>
            ))}
          </StyledDropItemWrapper>
        }
      />
    </div>
  );
};

FontSelector.defaultProps = defaultProps;

export default FontSelector;
