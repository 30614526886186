import React from "react";
import { Blank, IconProps } from "grommet-icons";
import { IconColors } from "../../theme/GlobalTheme";
import { ChildrenIconProps } from "./FileTypeIcon";

const OERFileButton: React.FC<IconProps & ChildrenIconProps> = (props) => {
  return (
    <Blank viewBox="0 0 128 128" {...props} color="plOERFileButtonn">
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 42 42"
        xmlSpace="preserve"
      >
        <title>oer-filebtn</title>
        <path
          fill="white"
          d="M5,1h32c2.2,0,4,1.8,4,4v32c0,2.2-1.8,4-4,4H5c-2.2,0-4-1.8-4-4V5C1,2.8,2.8,1,5,1z"
        />
        <g>
          <path
            fill={
              props.disabled
                ? IconColors["icon-disabled"]
                : IconColors["icon-blue"]
            }
            d="M14,33h-1c-1.1,0-2-0.9-2-2V9c0-1.1,0.9-2,2-2h9v4c0,2.2,1.8,4,4,4h4v9c0,0.6,0.4,1,1,1s1-0.4,1-1V13.8
		c0-0.8-0.3-1.6-0.9-2.1l-5.8-5.8C24.7,5.3,24,5,23.2,5c0,0,0,0,0,0H13c-2.2,0-4,1.8-4,4v22c0,2.2,1.8,4,4,4h1c0.6,0,1-0.4,1-1
		S14.6,33,14,33z M29.6,13H26c-1.1,0-2-0.9-2-2V7.4L29.6,13z"
          />
          <path
            fill={
              props.disabled
                ? IconColors["icon-disabled"]
                : IconColors["icon-blue"]
            }
            d="M24,35h-2c-1.7,0-3-1.3-3-3s1.3-3,3-3h2c0.6,0,1-0.4,1-1s-0.4-1-1-1h-2c-2.8,0-5,2.2-5,5s2.2,5,5,5h2
		c0.6,0,1-0.4,1-1S24.6,35,24,35z"
          />
          <path
            fill={
              props.disabled
                ? IconColors["icon-disabled"]
                : IconColors["icon-blue"]
            }
            d="M30,27h-2c-0.6,0-1,0.4-1,1s0.4,1,1,1h2c1.7,0,3,1.3,3,3s-1.3,3-3,3h-2c-0.6,0-1,0.4-1,1s0.4,1,1,1h2
		c2.8,0,5-2.2,5-5S32.8,27,30,27z"
          />
          <path
            fill={
              props.disabled
                ? IconColors["icon-disabled"]
                : IconColors["icon-blue"]
            }
            d="M23.1,32c0,0.6,0.4,1,1,1h3.7c0.6,0,1-0.4,1-1s-0.4-1-1-1h-3.7C23.6,31,23.1,31.5,23.1,32z"
          />
        </g>
      </svg>
    </Blank>
  );
};

export default OERFileButton;
