import React from "react";
import { addDays, format, nextDay, previousDay, startOfWeek } from "date-fns";
import CalendarItem from "./CalendarItem";
import styled from "styled-components";
import clsx from "clsx";

const StyledToday = styled.span`
  font-family: "Poppins", serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: #ffffff;
  background: #a5aec1;
  height: 28px;
  width: 64px;
  padding: 4px;
  border-radius: 15px;
`;

const Today: React.FC = () => {
  return <StyledToday>Today</StyledToday>;
};

const ScheduleCalendarTableWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;

  & .column {
    text-align: center;
    width: 172px;
    border-right: 2px solid #d0dcf1;
    padding: 6px;
    min-height: 600px;

    &:nth-child(1) {
      border-left: 2px solid #d0dcf1;
    }

    @media (min-width: 1440px) {
      width: 200px;
    }
    @media (max-width: 1440px) {
      width: 172px;
    }
    @media (max-width: 1250px) {
      width: 110px;
    }
    @media (max-width: 850px) {
      width: 80px;
    }

    .name {
      font-family: "Poppins", serif;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 21px;
      text-align: center;
      color: #474e61;
    }

    .number {
      font-family: "Poppins", serif;
      font-style: normal;
      font-weight: 400;
      font-size: 28px;
      line-height: 42px;
      text-align: center;
      color: #030b1d;

      & .today {
        background: #193da9;
        color: white;
        border-radius: 50%;
        padding: 0 8px;
      }
    }

    .fixedColumn {
      color: #9da2ac;
    }
  }
`;

interface ScheduleCalendarTableProps {
  data: Array<{
    date: Date | number;
    label: string;
    subject: string;
  }>;
  initialDate: Date;
}

const ScheduleCalendarTable: React.FC<ScheduleCalendarTableProps> = (props) => {
  const dateStartOfWeek = startOfWeek(props.initialDate);
  const week = [];
  for (let x = 0; x < 7; x++) {
    week.push(addDays(dateStartOfWeek, x));
  }

  return (
    <ScheduleCalendarTableWrapper>
      <div className="column">
        {format(Date.now(), "d/M/Y") ===
          format(previousDay(dateStartOfWeek, 6), "d/M/Y") && <Today />}
        <div className="date">
          <div className="name fixedColumn">
            {format(previousDay(dateStartOfWeek, 6), "ccc").toUpperCase()}
          </div>
          <div className="number fixedColumn">
            {format(previousDay(dateStartOfWeek, 6), "dd")}
          </div>
        </div>
      </div>
      {week.map((date, index) => (
        <div key={`date-index-${index}`} className="column">
          <div className="date">
            <div className="name">{format(date, "ccc").toUpperCase()}</div>
            <div className="number">
              <span
                className={clsx({
                  today: format(Date.now(), "d/M/Y") === format(date, "d/M/Y"),
                })}
              >
                {format(date, "dd")}
              </span>
            </div>
          </div>
          <div className="content">
            {props.data
              .filter(
                (item) => format(date, "d/M/Y") === format(item.date, "d/M/Y")
              )
              .map((item) => (
                <CalendarItem label={item.label} subject={item.subject} />
              ))}
          </div>
        </div>
      ))}
      <div className="column">
        {format(Date.now(), "d/M/Y") ===
          format(nextDay(dateStartOfWeek, 0), "d/M/Y") && <Today />}
        <div className="date">
          <div className="name fixedColumn">
            {format(nextDay(dateStartOfWeek, 0), "ccc").toUpperCase()}
          </div>
          <div className="number fixedColumn">
            {format(nextDay(dateStartOfWeek, 0), "dd")}
          </div>
        </div>
      </div>
    </ScheduleCalendarTableWrapper>
  );
};

export default ScheduleCalendarTable;
