import { SectionValidator } from "./SectionValidator";
import { isRichTextEditorEmpty, Prerequisites } from "model/Syllabus";
import Joi from "joi";
import { errorMapper } from "../../../helpers/errorMapper";
import _ from "lodash";

export default class PrerequisitesValidator extends SectionValidator {
  validate(section: Prerequisites) {
    const schema = Joi.object({
      prerequisites: Joi.array()
        .min(1)
        .items(
          Joi.object({
            body: Joi.custom((value, helper) => {
              if (isRichTextEditorEmpty(value)) {
                throw new Error("cannot be empty");
              } else {
                return true;
              }
            }).messages({ "any.custom": "Prerequisite cannot be empty" }),
          })
        ),
    }).options({ allowUnknown: true, abortEarly: false });

    const sectionErrorItem = {
      section: section,
      errors: {
        ...errorMapper(section, schema),
      },
    };

    return !_.isEmpty(sectionErrorItem.errors) ? sectionErrorItem : undefined;
  }
}
