import React from "react";
import { Box, ResponsiveContext } from "grommet";
import RoundedButton from "./ui-kit/RoundedButton/RoundedButton";
import styled from "styled-components";

interface TextInputListProps {
  gridA11yTitle: string;
  boxA11yTitle: string;
  buttonA11yTitle: string;
  addContent: () => void;
  buttonText: string;
}

const StyledButton = styled(RoundedButton)`
  width: 220px;
  padding: 0 10px !important;
`;

const TextInputList: React.FC<TextInputListProps> = (props) => {
  return (
    <ResponsiveContext.Consumer>
      {(size) => (
        <Box
          direction="column"
          align="rigth"
          gap={"none"}
          width={size === "medium" ? "200px" : "700px"}
        >
          <Box
            gridArea="input"
            a11yTitle={props.boxA11yTitle}
            width={size === "medium" ? "200px" : "700px"}
          >
            {props.children}
          </Box>
          <Box
            gridArea="button"
            a11yTitle={props.buttonA11yTitle}
            width={"small"}
          >
            <StyledButton
              onClick={() => props.addContent()}
              type="primary"
              variant="blue"
            >
              {props.buttonText}
            </StyledButton>
          </Box>
        </Box>
      )}
    </ResponsiveContext.Consumer>
  );
};
export default TextInputList;
