export enum WebexActionType {
  SetTokensWebex = "SetTokensWebex",
}

export interface SetTokens {
  type: typeof WebexActionType.SetTokensWebex;
  payload: {
    refreshToken: string;
    accessToken: string;
  };
}

export type WebexActions = SetTokens;
