import React from "react";
import {
  CheckBox as GrommetCheckBox,
  Text,
  ThemeContext,
  ThemeType,
} from "grommet";
import { normalizeColor } from "grommet/utils";
import styled from "styled-components";

interface CheckBoxProps {
  disabled?: boolean;
  a11yTitle?: string;
  checked: boolean;
  onMouseLeave?: () => void;
  onMouseOver?: () => void;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  label: string | JSX.Element;
}

const StyledLabel = styled(Text)`
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  color: #030b1d;
  margin-left: 10px;

  &.disabled {
    color: #9da2ac;
  }
`;

const CheckBox: React.FC<CheckBoxProps> = (props) => {
  const customTheme: ThemeType = {
    global: {
      focus: {
        border: {
          color: "transparent",
        },
        outline: {
          color: "transparent",
          size: "0",
        },
        shadow: {
          color: "transparent",
          size: "0",
        },
      },
    },
    checkBox: {
      size: "22px",
      extend: ({ theme }) => `
          ${
            !props.disabled
              ? `color: ${normalizeColor("#030B1D", theme)}`
              : `color: ${normalizeColor("#9DA2AC", theme)}; opacity: 1;`
          }
      `,
      border: {
        color: !props.disabled ? "#0D3AF8" : "#9DA2AC",
      },
      icon: {
        extend: "stroke: white",
      },
      hover: {
        border: {
          color: undefined,
        },
      },
      check: {
        radius: "4px",
        extend: ({ theme }) => `
          ${
            props.checked &&
            `background-color: ${normalizeColor("#0D3AF8", theme)};`
          }
          ${
            props.checked &&
            props.disabled &&
            `background-color: ${normalizeColor(
              "#9DA2AC",
              theme
            )}; border-color: ${normalizeColor("#9DA2AC", theme)};`
          }
        `,
      },
    },
  };
  return (
    <ThemeContext.Extend value={customTheme}>
      <GrommetCheckBox
        a11yTitle={props.a11yTitle}
        label={
          <StyledLabel className={props.disabled ? "disabled" : ""}>
            {props.label}
          </StyledLabel>
        }
        disabled={props.disabled}
        checked={props.checked}
        onChange={props.onChange}
        onMouseLeave={props.onMouseLeave}
        onMouseOver={props.onMouseOver}
      />
    </ThemeContext.Extend>
  );
};

export default CheckBox;
