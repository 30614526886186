import React, { Fragment } from "react";
import { CourseTitle, Module } from "../../../model/Syllabus";
import { Anchor, Sidebar, Text, Image, Box, Keyboard } from "grommet";
import styled from "styled-components";
import truncate from "../../../utils/truncate";
import { Activity } from "model/Resource";
import ArrowLeftIcon from "../../../assets/images/arrow-left.svg";
import { useHistory, useLocation } from "react-router-dom";
import { format } from "date-fns";

const StyledSidebar = styled(Sidebar)`
  height: 100%;
  width: 19em;
  z-index: 1;
  position: fixed;
  flex: auto;
  top: 0;
  left: 0;
  padding-left: 40px;
  padding-right: 40px;
  overflow-x: hidden;
  background: #f5f8fa;
  box-shadow: 6px 0 15px rgba(7, 26, 67, 0.1);
`;

const CourseTitleText = styled(Text)`
  font-size: 14px;
  color: #474e61;
  height: 19px;
  padding-top: 26px;
  padding-bottom: 35px;
`;

const SidebarHeader = styled(Text)`
  font-size: 20px;
  font-weight: 700;
  color: #193da9;
  height: 27px;
  flex: none;
`;

const SidebarSubHeader = styled(Text)`
  padding-bottom: 20px;
  font-size: 16px;
  font-weight: 600;
  color: #193da9;
  height: 22px;
  flex: none;
`;

/**
 * The standard behavior for scroll on a mac is non-intuitive,
 * so we added css to make the scrollbar visible upon hover.
 */
const SidebarLinksContainer = styled.div`
  display: flex;
  height: 80%;
  flex-direction: column;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }
  :hover {
    ::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: rgba(0, 0, 0, 0.5);
      -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    }
  }
`;

const SidebarFooter = styled.div`
  border-top: 1px solid #d0dcf1;
  padding-top 10px;
  position: absolute;
  bottom: 20px;
  flex: none;
`;

const SidebarHeaderBackText = styled.div`
  font-style: normal;
  font-size: 16px;
  font-weight: 600;
  line-height: 21.79px;
  margin-top: 8px;
  display: flex;
  color: #030b1d;

  &:hover {
    cursor: pointer;
  }

  p {
    padding-left: 22px;
  }
`;

const BorderGrey = styled.div`
  border-bottom: 1px solid #d0dcf1;
  margin-top: 5px;
`;

interface ModuleSidebarProps {
  module: Module;
  courseTitle: CourseTitle;
}

const ModuleSidebar: React.FC<ModuleSidebarProps> = ({
  module,
  courseTitle,
}) => {
  const history = useHistory();
  const location = useLocation().pathname;
  const getLink = (activity: Activity, path: number) => {
    return (
      <Fragment key={activity.id}>
        <Anchor
          label={truncate(activity.title, 20)}
          color="#030B1D"
          size="16px"
          margin={{ vertical: "8px" }}
          href={"#activity-" + path}
        />
      </Fragment>
    );
  };

  return (
    <StyledSidebar role="navigation">
      <div>
        <Keyboard
          onEnter={() => {
            history.push(location.substring(0, location.lastIndexOf("/m")));
          }}
        >
          <SidebarHeaderBackText
            tabIndex={1}
            onClick={() => {
              history.push(location.substring(0, location.lastIndexOf("/m")));
            }}
          >
            <img src={ArrowLeftIcon} alt="Arrow Left Icon" />
            <p>Back to Syllabus</p>
          </SidebarHeaderBackText>
        </Keyboard>
        <BorderGrey />
      </div>
      <CourseTitleText>
        {courseTitle.courseNumber + " - " + courseTitle.title}
      </CourseTitleText>
      <SidebarHeader>Assignments</SidebarHeader>
      <SidebarSubHeader>
        {format(new Date(module.start_at), "MM/dd/yyyy")}
      </SidebarSubHeader>
      <SidebarLinksContainer>
        {module.activities?.map((section, index) => getLink(section, index))}
      </SidebarLinksContainer>
      <Box align="center">
        <SidebarFooter>
          <Image src="/images/logos/logo.svg" alt="BlendED" width="125px" />
        </SidebarFooter>
      </Box>
    </StyledSidebar>
  );
};

export default ModuleSidebar;
