import React from "react";
import styled from "styled-components";
import RichTextEditorWrapper from "../../ui-kit/RichTextEditor/RichTextEditorWrapper";
import Title from "../../ui-kit/OneWeekPlanTitle/Title";
import Subtitle from "../../ui-kit/OneWeekPlanSubtitle/Subtitle";
import StepBar from "../StepBar/StepBar";
import RoundedButton from "../../ui-kit/RoundedButton/RoundedButton";
import { Box } from "grommet";
import useDelayedState from "../../../hooks/useDelayedState";
import { JSONContent } from "@tiptap/react";

interface ObjectivesEditorProps {
  onClickContinue: () => void;
  onChange?: (newObjectives: JSONContent) => void;
  objectives: JSONContent;
}

const Content = styled(Box)`
  width: 100%;

  & .buttons {
    padding-top: 20px;
    text-align: center;
  }
`;

const StyledTitle = styled(Title)`
  padding: 0 0 42px 0;
`;

const StyledSubTitle = styled(Subtitle)`
  padding-bottom: 12px;
`;

const ObjectivesEditor: React.FC<ObjectivesEditorProps> = (props) => {
  const [_objectives, _setObjectives] = useDelayedState<JSONContent>(
    props.objectives,
    props.onChange!
  );

  return (
    <Content direction="column" justify="between">
      <Box direction="column">
        <StepBar percentage={40} text="Step 2 out of 5" />
        <StyledTitle text="What will your students learn during the first week?" />
        <StyledSubTitle text="Plan your weekly course objectives here. Mention in detail the requirements, goals, and targets you plan to achieve." />
        <RichTextEditorWrapper
          id="one-week-plan-objectives-editor"
          key="one-week-plan-objectives-editor"
          value={_objectives}
          onChange={(content) => {
            _setObjectives(content);
          }}
        />
      </Box>
      <div className="buttons">
        <RoundedButton
          type="primary"
          variant="blue"
          onClick={() => props.onClickContinue()}
        >
          Continue
        </RoundedButton>
      </div>
    </Content>
  );
};

export default ObjectivesEditor;
