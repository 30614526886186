import React from "react";
import { Blank, IconProps } from "grommet-icons";

const CloseRed: React.FC<IconProps> = (props) => {
  return (
    <Blank {...props} color="plain">
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        aria-hidden="true"
        fill="#F65A5A"
      >
        <circle cx="12" cy="12" r="12" fill="rgb(246, 90, 90)" />
        <path
          d="M8.4646 8.46448L15.5357 15.5355"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path
          d="M8.4646 15.5355L15.5357 8.46445"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
        />
      </svg>
    </Blank>
  );
};
export default CloseRed;
