import React, { useEffect, useRef, useState } from "react";
import {
  CourseTitle,
  deleteComponentOnSyllabus,
  Module,
  resetModule,
  Schedule,
  Section,
  SectionType,
  Syllabus,
  SyllabusComponent,
} from "../../model/Syllabus";
import createEmptySection from "../editor/SectionFactory";
import SectionComponent from "../sections/view/SectionComponent";
import { useAppDispatch, useAppSelector } from "redux/configureStore";
import { setEditMode } from "../../redux/common/actions/CommonActions";
import SyllabusEditorContextProvider from "./SyllabusEditorContextProvider";
import SyllabusEditorSidebar from "./SyllabusEditorSidebar";
import { HidableComponent } from "../hoc/Hidable";
import clsx from "clsx";
import { Box, Keyboard } from "grommet";
import styled from "styled-components";
import IconButton from "components/IconButton";
import PublishSyllabusModal from "./PublishSyllabusModal";
import { createHash } from "helpers/createHash";
import _ from "lodash";
import DeleteConfirmationConfirmDialog from "../modals/DeleteConfirmationConfirmDialog";
import CancelConfirmationConfirmDialog from "../modals/CancelConfirmationConfirmDialog";
import CloseRed from "../../assets/icons/CloseRed";
import SyllabusEditorModal from "./SyllabusEditorModal";
import AuxiliarPixel from "./AuxiliarPixel";
import ScheduleModuleFactory from "../../model/ScheduleModuleFactory";
import AddModuleDialog from "../modals/AddModuleDialog";
import SyllabusThemeContextProvider from "../viewer/customization/SyllabusTheme";
import SquaredButton from "../ui-kit/SquaredButton/SquaredButton";
import RichTextEditorContextProvider from "../ui-kit/RichTextEditor/RichTextEditorContext";
import ConfirmDialog from "../modals/ConfirmDialog";
import SyllabusErrorBag from "../../model/SyllabusValidator/SyllabusErrorBag";
import { SyllabusValidator } from "../../model/SyllabusValidator/SyllabusValidator";
import Exit from "../../assets/icons/Exit";
import SyllabusEditorSharedHeader from "components/editor/headers/SyllabusEditorSharedHeader";
import SyllabusEditorPrivateHeader from "components/editor/headers/SyllabusEditorPrivateHeader";
import CanvasExportModal from "./CanvasExportModal";
import exportToPDF from "helpers/exportToPDF";
import ShareStudentLinkModal from "components/modals/ShareStudentLinkModal";
import { makeid } from "../../helpers/Makeid";

const SectionWrapper = styled.div`
  &.hover {
    border: 1px dashed #a5aec1;
    box-sizing: border-box;
    border-radius: 4px;
    cursor: pointer;
  }

  &.sectionWrapper {
    position: relative;
    display: flex;
  }
`;
const SectionComponentWrapper = styled.div`
  width: 98%;
  padding: 15px 47px;
`;
const CloseButton = styled.div`
  position: absolute;
  top: -9.5px;
  right: -9.5px;
`;

const Main = styled.main`
  display: flex;
  background: #fbfdff;
  min-height: 100vh !important;
`;

const ContainerBox = styled(Box)`
  @media (min-width: 600px) {
    width: calc(100% - 73px);
  }
  @media (min-width: 1280px) {
    max-width: 1280px;
  }
  margin-left: 19em;
  width: 100%;
`;

const Content = styled.div`
  width: 100%;
  padding-bottom: 40px;
  display: flex;
  justify-content: center;
`;

const SyllabusBox = styled(Box)`
  background: #ffffff;
  border: 1px solid #f1f6ff;
  box-sizing: border-box;
  box-shadow: 0 0 8px 1px rgba(7, 26, 67, 0.05);
  border-radius: 6px;
  height: 100%;
  padding: 35px;

  & a {
    color: #2256f2;
  }
`;

const StyledExit = styled(Exit)`
  color: #f1f6ff;
`;

const Alert = styled.div`
  position: fixed;
  display: flex;
  bottom: 25px;
  background-color: rgb(4, 170, 109);
  color: white;
  opacity: 1;
  transition: opacity 0.6s ease 0s;
  padding: 20px;
  right: 25%;
`;

interface PrivateMode {
  type: "private";
  onClickPreview?: () => void;
}

interface SharedMode {
  type: "shared";
  onClickRegister?: () => void;
}

export type SyllabusEditorMode = PrivateMode | SharedMode;

interface SyllabusEditorProps {
  /**
   * The syllabus object for editing
   */
  syllabus: Syllabus;
  /**
   * Handler for saving a specific section of the syllabus
   */
  /**
   * Handler for saving the syllabus
   */
  onSavingSyllabus?: (syllabus: Syllabus) => void;
  /**
   * Handler for changing the password
   */
  onChangePassword?: (hashedPassword: string) => void;

  onSyllabusIsShared?: (syllabus: Syllabus) => void;

  onSyllabusIsSharedWithTeachers?: (syllabus: Syllabus) => void;
  /**
   * Handler for clicking the delete button to remove a syllabus
   */
  onClickDelete?: (syllabus: Syllabus) => void;
  /**
   * On click in Preview Button
   */
  onClickPreview?: () => void;

  /**
   * On click in "Back to dashboard" button
   */
  onClickBackButton?: () => void;

  onDeleteComponent?: (component: SyllabusComponent) => void;

  /**
   * Private actions include see preview of syllabus and upload a new file
   */
  overridePrivateActions?: () => void;

  onReorderSyllabus?: (syllabus: Syllabus) => void;

  warningMessage?: React.ReactNode;

  /**
   * Condition to know if the user needs to Sign Up in order to share the syllabus or upload files.
   */

  needsRegistration?: boolean;

  /**
   * Condition to know if we need to render the "Build my Schedule" button
   */

  showTemplateButtons?: boolean;

  /**
   * We are going to use 'private' mode when the owner of the syllabus is editing it.
   * We are going to use 'shared' mode when a professor's colleague wants to preview and edit his own version of a syllabus.
   */
  mode?: SyllabusEditorMode;
}

interface AddModuleDialogProps {
  open: boolean;
  startDate?: Date;
  endDate?: Date;
  newSyllabus?: Syllabus;
  newModule?: Module;
}

const defaultProps: Partial<SyllabusEditorProps> = {
  mode: {
    type: "private",
  },
};

/**
 * A component for handling higher level editing of syllabi structure. Section level edits are made within the Editor Carousel.
 */
const SyllabusEditor: React.FC<SyllabusEditorProps> = (props) => {
  const auth = useAppSelector((state) => state.firebase.auth);
  const [syllabusEditorModalOpened, setSyllabusEditorModalOpened] =
    useState<boolean>(false);
  const [syllabus, _setSyllabus] = useState<Syllabus>({ ...props.syllabus });
  const [syllabusErrors, setSyllabusErrors] = useState<SyllabusErrorBag>({
    success: true,
    sections: [],
  });
  const [previousSyllabus, setPreviousSyllabus] = useState<Syllabus>({
    ...props.syllabus,
  });
  const [componentToDelete, setComponentToDelete] = useState<
    SyllabusComponent | undefined
  >(undefined);
  const [currentSection, setCurrentSection] = useState<
    SyllabusComponent | undefined
  >(undefined);
  const [askForPasswordModalOpened, setAskForPasswordModalOpened] =
    useState(false);
  const [canvasExportModalOpened, setCanvasExportModalOpened] = useState(false);
  const [currentURL, setCurrentURL] = useState("");
  const [isOpenCancelDialog, setIsOpenCancelDialog] = useState<boolean>(false);
  const [showShareStudentLinkModal, setShowShareStudentLinkModal] =
    useState<boolean>(false);
  const [addModuleDialogProps, setAddModuleDialogProps] =
    useState<AddModuleDialogProps>({
      open: false,
      startDate: undefined,
      endDate: undefined,
      newSyllabus: undefined,
      newModule: undefined,
    });
  const [mouseOverId, setMouseOverId] = useState<string | null>(null);
  const [disableCancelDialogButtons, setDisableCancelDialogButtons] =
    useState(false);
  const [disabledSaveButton, setDisabledSaveButton] = useState<boolean>(false);
  const [passwordError, setPasswordError] = useState<string>("");
  const [showAuxiliarPixel, setShowAuxiliarPixel] = useState<boolean>(false);
  const [passwordModified, setPasswordModified] = useState<boolean>(false);
  const [copiedTeacherLink, setCopiedTeacherLink] = useState<boolean>(false);

  const addedSchedule = syllabus.sections.some(
    (section) => section.type === "Schedule"
  );
  const [showDeletePasswordModal, setShowDeletePasswordModal] =
    useState<boolean>(false);
  const dispatch = useAppDispatch();

  const sections = syllabus.sections.filter(
    (section) => section.type !== "Policies" && section.type !== "RequiredTexts"
  );

  /**
   * Each time the modal is opened or closed we have to reset the errors bag to avoid showing past errors.
   */
  useEffect(() => {
    setSyllabusErrors({
      success: true,
      sections: [],
    });
  }, [syllabusEditorModalOpened]);

  useEffect(() => {
    if (currentSection?.type === "Module") {
      const schedule = syllabus.sections.find(
        (s) => s.type === "Schedule"
      ) as Schedule;
      const module = schedule?.modules?.find((m) => m.id === currentSection.id);
      setCurrentSection(module);
    } else {
      const updatedSection = syllabus.sections.find(
        (s) => s.id === currentSection?.id
      );
      setCurrentSection(updatedSection);
    }
  }, [syllabus.sections, currentSection]);

  useEffect(() => {
    window.analytics.track("Editing Syllabus", {
      syllabusId: syllabus.firebaseId,
    });
  }, [auth.uid, syllabus.firebaseId]);

  useEffect(() => {
    dispatch(setEditMode(true));
    if (currentURL === "") {
      let URL = window.location.href.split("edit");
      setCurrentURL(URL[0]);
    }
  }, [currentURL, dispatch]);

  const setSyllabus = (newSyllabus: Syllabus) => {
    _setSyllabus(newSyllabus);
  };

  const saveSection = (section: Section) => {
    window.analytics.track("Section Saved", {
      sectionType: section.type,
      syllabusId: syllabus.firebaseId,
    });
    _setSyllabus((oldSyllabus) => {
      const newSections = [...oldSyllabus.sections];
      const index = newSections.map((s) => s.id).indexOf(section.id);
      newSections[index] = section;
      return {
        ...oldSyllabus,
        sections: newSections,
      };
    });
  };

  const saveModule = (module: Module) => {
    _setSyllabus((oldSyllabus) => {
      return {
        ...oldSyllabus,
        sections: oldSyllabus.sections.map((s) => {
          const s2 = { ...s };
          if (s2.type === "Schedule") {
            s2.modules = s2.modules.map((m) =>
              m.id === module.id ? module : m
            );
          }
          return s2;
        }),
      };
    });
  };

  const renderDeleteSectionDialog = (
    <DeleteConfirmationConfirmDialog
      isOpen={componentToDelete !== undefined}
      onClickCancel={() => {
        setComponentToDelete(undefined);
      }}
      onClickAction={() => {
        if (componentToDelete !== undefined) {
          _setSyllabus((oldSyllabus) => {
            const newSyllabus = deleteComponentOnSyllabus(
              componentToDelete,
              oldSyllabus
            );
            const firstSection = _.first(newSyllabus.sections);
            setCurrentSection(firstSection);
            if (!firstSection) {
              setSyllabusEditorModalOpened(false);
            }
            return newSyllabus;
          });
          setPreviousSyllabus((oldSyllabus) =>
            deleteComponentOnSyllabus(componentToDelete, oldSyllabus)
          );
          props.onDeleteComponent?.(componentToDelete);
          setComponentToDelete(undefined);
        }
      }}
      handleClose={() => setComponentToDelete(undefined)}
    />
  );

  //Dialog that pops up when the user clicks the cancel button on editor, outside of the modal or the arrows without saving the changes
  const renderCancelDialog = (
    <CancelConfirmationConfirmDialog
      isOpen={isOpenCancelDialog}
      disableButtons={disableCancelDialogButtons}
      handleClose={() => setIsOpenCancelDialog(false)}
      onClickAction={async () => {
        const syllabusValidator = new SyllabusValidator();
        const newErrorBag = syllabusValidator.validate(syllabus);
        setSyllabusErrors(newErrorBag);
        if (!newErrorBag.success) {
          setCurrentSection(newErrorBag.sections[0].section);
          setIsOpenCancelDialog(false);
          return;
        }
        setDisableCancelDialogButtons(true);
        await props.onSavingSyllabus?.(syllabus);
        setPreviousSyllabus(syllabus);
        setIsOpenCancelDialog(false);
        setDisableCancelDialogButtons(false);
        setSyllabusEditorModalOpened(false);
      }}
      onClickCancel={() => {
        setSyllabus(previousSyllabus);
        setSyllabusEditorModalOpened(false);
        setIsOpenCancelDialog(false);
      }}
    />
  );

  const renderAddModuleDialog = (
    <AddModuleDialog
      isOpen={addModuleDialogProps.open}
      onClickCancel={() => setAddModuleDialogProps({ open: false })}
      onClickAction={async () => {
        _setSyllabus(addModuleDialogProps.newSyllabus!);
        setPreviousSyllabus(addModuleDialogProps.newSyllabus!);
        await props.onSavingSyllabus?.(addModuleDialogProps.newSyllabus!);
        setSyllabusEditorModalOpened(true);
        setCurrentSection(addModuleDialogProps.newModule!);
        setAddModuleDialogProps({ open: false });
      }}
      startDate={addModuleDialogProps.startDate}
      endDate={addModuleDialogProps.endDate}
    />
  );

  const renderShareStudentLinkModal = (
    <ShareStudentLinkModal
      handleClose={() => setShowShareStudentLinkModal(false)}
      isOpen={showShareStudentLinkModal}
      link={`${window.location.protocol}//${window.location.host}/s/${props.syllabus.firebaseId}`}
      code={syllabus.enrollmentCode}
    />
  );

  const onClickAddModule = async () => {
    const schedule = syllabus.sections.find(
      (section) => section.type === "Schedule"
    ) as Schedule;
    if (!schedule) return;
    const moduleFactory = new ScheduleModuleFactory();
    const lastModule = _.last(schedule.modules);
    const newModules = moduleFactory.createFromQuantity(
      lastModule!.end_at,
      1,
      schedule.modules.length
    );

    const newSyllabus = _.cloneDeep(syllabus);
    newSyllabus.sections = newSyllabus.sections.map((section) => {
      if (section.type === "Schedule") {
        section.modules = [...section.modules, ...newModules];
      }
      return section;
    });

    setAddModuleDialogProps({
      open: true,
      startDate: schedule.start_at,
      endDate: newModules[0].end_at,
      newSyllabus: newSyllabus,
      newModule: newModules[0],
    });
  };

  const onClickAddSection = (type: SectionType) => {
    //create a new section with the desired type and add it to the syllabus
    const newSection = createEmptySection(type);
    setSyllabus({
      ...syllabus,
      sections: [...syllabus.sections, newSection],
    });
    setPreviousSyllabus(_.cloneDeep(syllabus));
    setSyllabusEditorModalOpened(true);
    setCurrentSection(newSection);
  };

  const onClickEditSection = (section: Section) => {
    setPreviousSyllabus(_.cloneDeep(syllabus));
    setSyllabusEditorModalOpened(true);
    setCurrentSection(section);
  };

  const isEqual = (...objects: any) =>
    objects.every(
      (obj: any) => JSON.stringify(obj) === JSON.stringify(objects[0])
    );

  const handleCarouselClose = () => {
    if (
      isEqual(syllabus.sections, previousSyllabus.sections) ||
      (currentSection?.type === "Schedule" &&
        currentSection.modules.length === 0)
    ) {
      // We dont show the confirm modal
      setSyllabus(previousSyllabus);
      setSyllabusEditorModalOpened(false);
    } else {
      // We show the confirm modal
      setIsOpenCancelDialog(true);
      return;
    }
  };

  const renderedSyllabus = (
    <div>
      <Box>
        <RichTextEditorContextProvider mode="teacher-view">
          {sections.map((section, index) => {
            const o = section as any;
            const id = o.id ? "section-" + o.id : "section-id-" + index;
            return (
              <div key={index} id={"section" + index}>
                <SectionWrapper
                  className={clsx({
                    sectionWrapper: true,
                    hover: id === mouseOverId,
                  })}
                  onMouseEnter={() => setMouseOverId(id)}
                  onMouseLeave={() => setMouseOverId(null)}
                  onFocus={() => setMouseOverId(id)}
                  key={id}
                >
                  <Keyboard
                    onEnter={() => {
                      if (section.type !== "Schedule") {
                        onClickEditSection(section);
                      }
                    }}
                  >
                    <SectionComponentWrapper
                      onClick={() => onClickEditSection(section)}
                    >
                      <SectionComponent
                        id={"section-" + id}
                        section={section}
                        onClickWeek={(week) => onClickEditSection(week)}
                        onClickActivity={(activity) => {
                          const schedule = syllabus.sections.find(
                            (s) => s.type === "Schedule"
                          ) as Schedule;
                          const module = schedule.modules.find((module) =>
                            module.activities.some((a) => a.id === activity.id)
                          );
                          onClickEditSection(module!);
                        }}
                      />
                    </SectionComponentWrapper>
                  </Keyboard>
                  <CloseButton>
                    <HidableComponent isVisible={id === mouseOverId}>
                      <IconButton
                        a11yTitle={"Delete Section"}
                        icon={<CloseRed />}
                        onClick={() => {
                          setComponentToDelete(section);
                        }}
                      />
                    </HidableComponent>
                  </CloseButton>
                </SectionWrapper>
                {section.type === "CreditHours" &&
                  props.showTemplateButtons &&
                  !addedSchedule && (
                    <Box
                      align="center"
                      justify="center"
                      pad={{ vertical: "medium" }}
                    >
                      <SquaredButton
                        onClick={() => {
                          onClickAddSection("Schedule");
                        }}
                      >
                        Build My Schedule
                      </SquaredButton>
                    </Box>
                  )}
              </div>
            );
          })}
          <HidableComponent isVisible={passwordModified}>
            <Alert>
              Your password has been modified
              <span onClick={() => setPasswordModified(false)}>
                <StyledExit />
              </span>
            </Alert>
          </HidableComponent>
          <HidableComponent isVisible={copiedTeacherLink}>
            <Alert>
              The link was copied to your clipboard
              <span onClick={() => setCopiedTeacherLink(false)}>
                <StyledExit />
              </span>
            </Alert>
          </HidableComponent>
        </RichTextEditorContextProvider>
      </Box>
      <SyllabusEditorContextProvider
        disabledSaveButton={disabledSaveButton}
        setDisabledSaveButton={setDisabledSaveButton}
        overridePrivateActions={props.overridePrivateActions}
        setShowAuxiliarPixel={setShowAuxiliarPixel}
      >
        <SyllabusEditorModal
          needsRegistration={props.needsRegistration}
          previousSyllabus={previousSyllabus}
          opened={syllabusEditorModalOpened}
          onClose={() => handleCarouselClose()}
          sections={syllabus.sections}
          previousSections={previousSyllabus.sections}
          onChangeSection={(section) => {
            setCurrentSection(section);
          }}
          selectedSection={currentSection!}
          disableButtons={disabledSaveButton}
          onChange={(section) => {
            if (section.type === "Module") {
              saveModule(section);
            } else {
              saveSection(section);
            }
          }}
          syllabusErrorBag={syllabusErrors}
          onSaveAll={async () => {
            const newSyllabus = _.cloneDeep(syllabus);

            const syllabusValidator = new SyllabusValidator();
            const newErrorBag = syllabusValidator.validate(newSyllabus);
            setSyllabusErrors(newErrorBag);
            if (!newErrorBag.success) {
              setCurrentSection(newErrorBag.sections[0].section);
              return;
            }

            await props.onSavingSyllabus?.(newSyllabus);
            setPreviousSyllabus(newSyllabus);
            setSyllabusEditorModalOpened(false);
          }}
          onDeleteComponent={(component) => {
            setComponentToDelete(component);
          }}
          onCleanUpComponent={(component) => {
            _setSyllabus((oldSyllabus) => {
              const o = _.cloneDeep(oldSyllabus);
              o.sections = o.sections.map((s) => {
                if (s.type === "Schedule") {
                  s.modules = s.modules.map((m) => {
                    if (m.id === component.id) {
                      return resetModule(m);
                    }

                    return m;
                  });
                }
                return s;
              });

              return o;
            });

            const oldSection = currentSection;
            setCurrentSection(undefined);
            setCurrentSection(oldSection);
          }}
          onCancelAndClose={() => {
            setSyllabus(previousSyllabus);
            setSyllabusEditorModalOpened(false);
          }}
        />
      </SyllabusEditorContextProvider>
    </div>
  );

  const ref = useRef();

  return (
    <Main>
      <SyllabusThemeContextProvider
        fontFamily={syllabus.customization?.fontFamily!}
        primaryColor={syllabus.customization?.primaryColor!}
      >
        <AuxiliarPixel visible={showAuxiliarPixel} />
        <SyllabusEditorSidebar
          needsRegistration={props.needsRegistration}
          syllabus={syllabus}
          onSectionOrderChange={(sections) => {
            _setSyllabus((oldSyllabus: Syllabus) => {
              const newSyllabus = {
                ...oldSyllabus,
                sections: sections,
              };
              props.onReorderSyllabus?.(newSyllabus);

              return newSyllabus;
            });
          }}
          onSectionAdd={(type) => onClickAddSection(type)}
          onSectionEdit={(section) => onClickEditSection(section)}
          onModuleAdd={() => onClickAddModule()}
          overridePrivateActions={props.overridePrivateActions}
          onClickBackButton={props.onClickBackButton}
          selectedFont={syllabus.customization?.fontFamily}
          selectedColor={syllabus.customization?.primaryColor}
          onSelectedFont={async (font) => {
            const oldSyllabus = { ...syllabus };
            oldSyllabus.customization.fontFamily = font;

            setSyllabus(oldSyllabus);
            await props.onSavingSyllabus?.(oldSyllabus);
          }}
          onSelectedColor={async (color) => {
            const oldSyllabus = { ...syllabus };
            oldSyllabus.customization.primaryColor = color;

            setSyllabus(oldSyllabus);
            await props.onSavingSyllabus?.(oldSyllabus);
          }}
        />
        <Content>
          <ContainerBox
            pad={{ horizontal: "large" }}
            margin={{ horizontal: "30px" }}
          >
            {(() => {
              const mode = props.mode!;
              switch (mode.type) {
                case "private":
                  return (
                    <SyllabusEditorPrivateHeader
                      needsRegistration={props.needsRegistration}
                      syllabus={syllabus}
                      onClickPreview={() => {
                        if (props.overridePrivateActions) {
                          props.overridePrivateActions();
                          return;
                        }
                        window
                          .open(
                            `${window.location.protocol}//${window.location.host}/syllabus/${props.syllabus.firebaseId}`,
                            "_blank"
                          )!
                          .focus();
                      }}
                      onClickPublish={async () => {
                        if (props.overridePrivateActions) {
                          props.overridePrivateActions();
                          return;
                        }
                        const newSyllabus = {
                          ...syllabus,
                          password: "",
                          shared: true,
                          enrollmentCode: syllabus.enrollmentCode ?? makeid(8),
                        };
                        _setSyllabus(newSyllabus);
                        await props.onSavingSyllabus?.(newSyllabus);
                        await props.onSyllabusIsShared?.(newSyllabus);
                      }}
                      onClickPasswordManager={() => {
                        if (props.overridePrivateActions) {
                          props.overridePrivateActions();
                          return;
                        }
                        setAskForPasswordModalOpened(true);
                      }}
                      onClickStudentView={() => {
                        if (props.overridePrivateActions) {
                          props.overridePrivateActions();
                          return;
                        }
                        //Redirect to student view
                        window
                          .open(
                            `${window.location.protocol}//${window.location.host}/s/${props.syllabus.firebaseId}`,
                            "_blank"
                          )!
                          .focus();
                      }}
                      onClickCopyPassword={(link: string) => {
                        setShowShareStudentLinkModal(true);
                      }}
                      onClickTeacherPublish={() =>
                        props.onSyllabusIsSharedWithTeachers?.(syllabus)
                      }
                      onClickCopyTeacherLink={(link: string) => {
                        navigator.clipboard.writeText(link);
                        setCopiedTeacherLink(true);
                      }}
                      onClickExportToCanvas={() => {
                        if (props.overridePrivateActions) {
                          props.overridePrivateActions();
                          return;
                        }
                        setCanvasExportModalOpened(true);
                      }}
                      onClickExportToPDF={async () => {
                        const fileName = syllabus.sections.find(
                          (section) => section.type === "CourseTitle"
                        ) as CourseTitle;
                        await exportToPDF(
                          `${fileName.courseNumber} - ${fileName.title}`,
                          ref.current!
                        );
                      }}
                    />
                  );
                case "shared":
                  return (
                    <SyllabusEditorSharedHeader
                      syllabus={syllabus}
                      onClickRegister={() => mode.onClickRegister?.()}
                    />
                  );
              }
            })()}
            {props.warningMessage}
            <SyllabusBox ref={ref}>{renderedSyllabus}</SyllabusBox>
            {renderDeleteSectionDialog}
            {renderCancelDialog}
            {renderAddModuleDialog}
            {renderShareStudentLinkModal}
            <HidableComponent isVisible={askForPasswordModalOpened}>
              <PublishSyllabusModal
                onClickConfirm={async (password) => {
                  const hashedPassword = createHash(password);
                  const newSyllabus = {
                    ...syllabus,
                    password: hashedPassword,
                    shared: true,
                  };
                  _setSyllabus(newSyllabus);
                  setAskForPasswordModalOpened(false);
                  await props.onChangePassword?.(hashedPassword);
                  await props.onSyllabusIsShared?.(newSyllabus);
                  setPasswordModified(true);
                }}
                onClickCancel={() => setAskForPasswordModalOpened(false)}
                onClickDelete={() => {
                  setShowDeletePasswordModal(true);
                }}
                opened={askForPasswordModalOpened}
                errorMessage={passwordError}
                password={syllabus.password}
                onFocus={() => setPasswordError("")}
                isNewPassword={!syllabus.shared}
              />
            </HidableComponent>
            <HidableComponent isVisible={canvasExportModalOpened}>
              <CanvasExportModal
                syllabus={syllabus}
                opened={canvasExportModalOpened}
                onClickCancel={() => setCanvasExportModalOpened(false)}
                onClose={() => setCanvasExportModalOpened(false)}
                onExport={(canvasId) => {
                  const newSyllabus = { ...syllabus, canvasId: canvasId };
                  _setSyllabus(newSyllabus);
                }}
              />
            </HidableComponent>
            <HidableComponent isVisible={showDeletePasswordModal}>
              <ConfirmDialog
                isOpen={showDeletePasswordModal}
                titleLabel={
                  <p>
                    Are you sure you want to <strong>delete</strong> <br></br>
                    your syllabus password?
                  </p>
                }
                description="Anyone with a link to your syllabus will be able to view its contents without entering a password."
                cancelButtonProps={{
                  label: "Keep",
                  type: "secondary",
                  onClick: () => setShowDeletePasswordModal(false),
                }}
                confirmButtonProps={{
                  label: "Delete",
                  type: "primary",
                  variant: "red",
                  onClick: async () => {
                    if (props.overridePrivateActions) {
                      props.overridePrivateActions();
                      return;
                    }
                    const newSyllabus = {
                      ...syllabus,
                      password: "",
                      shared: true,
                    };
                    _setSyllabus(newSyllabus);
                    await props.onSyllabusIsShared?.(newSyllabus);
                    setShowDeletePasswordModal(false);
                    setAskForPasswordModalOpened(false);
                  },
                }}
              />
            </HidableComponent>
          </ContainerBox>
        </Content>
      </SyllabusThemeContextProvider>
    </Main>
  );
};

SyllabusEditor.defaultProps = defaultProps;

export default SyllabusEditor;
