export enum StatisticsActionType {
  AddVisit = "AddVisit",
  SetVisits = "SetVisits",
  DidShowFeedbackDialog = "DidShowFeedbackDialog",
}

export interface AddVisitAction {
  type: typeof StatisticsActionType.AddVisit;
}

export interface SetVisitsAction {
  type: typeof StatisticsActionType.SetVisits;
  nVisits: number;
}

export interface DidShowFeedbackDialogAction {
  type: typeof StatisticsActionType.DidShowFeedbackDialog;
}

export type StatisticsActions =
  | AddVisitAction
  | SetVisitsAction
  | DidShowFeedbackDialogAction;
