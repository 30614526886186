import React from "react";
import { Blank, IconProps } from "grommet-icons";
import { ChildrenIconProps } from "./FileTypeIcon";
import { IconColors } from "../../theme/GlobalTheme";

const PSD: React.FC<IconProps & ChildrenIconProps> = (props) => {
  return (
    <Blank viewBox="0 0 24 24" {...props} color="plain">
      <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
      >
        <path
          fill="white"
          d="M14.84.61H8A2.29,2.29,0,0,0,5.7,2.91V21.19a2.34,2.34,0,0,0,.68,1.62A2.26,2.26,0,0,0,8,23.48H20.57a2.29,2.29,0,0,0,2.29-2.29V8.61Z"
        />
        <path
          fill={
            props.disabled
              ? IconColors["icon-disabled"]
              : IconColors["icon-dark-blue"]
          }
          d="M14.61,1.19l7.67,7.66V21.18a1.74,1.74,0,0,1-1.72,1.72H8a1.66,1.66,0,0,1-1.21-.5,1.7,1.7,0,0,1-.5-1.21l0-18.29A1.72,1.72,0,0,1,8,1.19h6.62m.23-.58H8A2.29,2.29,0,0,0,5.7,2.91V21.19a2.34,2.34,0,0,0,.68,1.62A2.26,2.26,0,0,0,8,23.48H20.57a2.29,2.29,0,0,0,2.29-2.29V8.61l-8-8Z"
        />
        <path
          fill={
            props.disabled
              ? IconColors["icon-disabled"]
              : IconColors["icon-light-blue"]
          }
          d="M14.84.9V6.33a2.28,2.28,0,0,0,2.29,2.28h5.42Z"
        />
        <path
          fill={
            props.disabled
              ? IconColors["icon-disabled"]
              : IconColors["icon-dark-blue"]
          }
          d="M22.56,8.89H17.14a2.58,2.58,0,0,1-2.58-2.57V.9a.28.28,0,0,1,.17-.26.29.29,0,0,1,.32.06l7.72,7.71a.29.29,0,0,1,.06.31A.3.3,0,0,1,22.56,8.89Zm-7.43-7.3V6.33a2,2,0,0,0,2,2h4.74Z"
        />
        <rect
          fill={
            props.disabled
              ? IconColors["icon-disabled"]
              : IconColors["icon-blue"]
          }
          x="1.14"
          y="11.19"
          width="20"
          height="9.14"
          rx="0.86"
        />
        <path
          fill={
            props.disabled
              ? IconColors["icon-disabled"]
              : IconColors["icon-dark-blue"]
          }
          d="M20.28,11.75a.29.29,0,0,1,.29.29v7.43a.29.29,0,0,1-.28.28H2a.28.28,0,0,1-.29-.28V12.05A.3.3,0,0,1,2,11.76H20.28m0-.57H2a.86.86,0,0,0-.85.86v7.43a.87.87,0,0,0,.86.86l18.29,0a.85.85,0,0,0,.85-.85V12a.86.86,0,0,0-.86-.86Z"
        />
        <path
          fill="white"
          d="M8.41,15.62a1.32,1.32,0,0,1-.55.52,2,2,0,0,1-.93.2H6.16V18.1H5.24V13.51H6.93a1.93,1.93,0,0,1,.9.19,1.33,1.33,0,0,1,.57.5,1.49,1.49,0,0,1,.18.73A1.36,1.36,0,0,1,8.41,15.62Zm-1-.2a.63.63,0,0,0,.19-.49c0-.45-.25-.67-.75-.67H6.16V15.6h.73A.77.77,0,0,0,7.45,15.42Z"
        />
        <path
          fill="white"
          d="M10,18a1.45,1.45,0,0,1-.61-.47,1.22,1.22,0,0,1-.23-.73h1a.67.67,0,0,0,.2.44.72.72,0,0,0,.5.17.82.82,0,0,0,.51-.16.5.5,0,0,0,.18-.4.45.45,0,0,0-.13-.34.76.76,0,0,0-.31-.2,5,5,0,0,0-.51-.17,7.21,7.21,0,0,1-.73-.26,1.29,1.29,0,0,1-.48-.39,1.11,1.11,0,0,1-.2-.69,1.28,1.28,0,0,1,.2-.71,1.3,1.3,0,0,1,.57-.46,2.07,2.07,0,0,1,.84-.17,1.84,1.84,0,0,1,1.16.35,1.29,1.29,0,0,1,.49,1h-1a.53.53,0,0,0-.2-.39.8.8,0,0,0-.5-.15.65.65,0,0,0-.43.13.51.51,0,0,0-.16.41.41.41,0,0,0,.12.3.93.93,0,0,0,.3.2,4.27,4.27,0,0,0,.51.17,7.21,7.21,0,0,1,.73.26,1.33,1.33,0,0,1,.49.4,1.11,1.11,0,0,1,.2.69,1.27,1.27,0,0,1-.19.68,1.3,1.3,0,0,1-.56.5,1.8,1.8,0,0,1-.87.19A2.13,2.13,0,0,1,10,18Z"
        />
        <path
          fill="white"
          d="M16.11,13.79a2,2,0,0,1,.85.8,2.41,2.41,0,0,1,.3,1.21A2.36,2.36,0,0,1,17,17a2.05,2.05,0,0,1-.85.8,2.7,2.7,0,0,1-1.27.28h-1.6V13.51h1.61A2.73,2.73,0,0,1,16.11,13.79Zm-.19,3.13a1.5,1.5,0,0,0,.4-1.11,1.49,1.49,0,0,0-.4-1.12,1.47,1.47,0,0,0-1.11-.41h-.66v3h.65A1.5,1.5,0,0,0,15.92,16.92Z"
        />
      </svg>
    </Blank>
  );
};

export default PSD;
