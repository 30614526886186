import { Extension } from "@tiptap/core";
import { Plugin, PluginKey } from "prosemirror-state";
import formatBytes from "../../../../utils/bytesToMb";
import { v4 as uuidv4 } from "uuid";
import uploadFile from "../../../../helpers/uploadFile";

export const FileUploaderPlugin = Extension.create({
  name: "FileUploaderPlugin",

  addProseMirrorPlugins() {
    const self = this;
    return [
      new Plugin({
        key: new PluginKey("FileUploaderPlugin"),
        props: {
          //@ts-ignore
          handleDrop(view, e: DragEvent) {
            const editor = self.editor;

            const file = e.dataTransfer?.files[0];

            if (file) {
              const fileName = file.name;
              const fileSize = formatBytes(file.size ?? 1);
              const type = file.type.split("/");
              const fileType = type?.[1].toUpperCase();

              const coordinates = view.posAtCoords({
                left: e.clientX,
                top: e.clientY,
              });
              const id = uuidv4();
              editor.commands.insertContentAt(coordinates!.pos, `<p></p>`);
              editor.commands.insertContentAt(
                coordinates!.pos + 1,
                `<file-button name="${fileName}" size="${fileSize}" type="${fileType}" loading="${true}" id="${id}"></file-button>`
              );

              (async () => {
                const fileResources = await uploadFile(e.dataTransfer!.files);
                const uploadedFile = fileResources[0];

                const json = editor?.getJSON();

                const newJson = {
                  ...json,
                  content: json.content!.map((content) => {
                    if (
                      content.type === "file-button" &&
                      content.attrs?.id === id
                    ) {
                      return {
                        ...content,
                        attrs: {
                          ...content.attrs,
                          url: uploadedFile.url,
                          loading: false,
                        },
                      };
                    }
                    return content;
                  }),
                };

                if (!editor.isDestroyed) {
                  editor?.commands.setContent(newJson, true);
                }
              })();
              return true;
            } else {
              return false;
            }
          },
        },
      }),
    ];
  },
});
