import React from "react";
import { Blank, IconProps } from "grommet-icons";

const Create: React.FC<IconProps> = (props) => {
  return (
    <Blank {...props} viewBox="0 0 24 24" color="plain">
      <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
      >
        <path
          fill="currentColor"
          d="M12,18a1,1,0,0,0,1-1V13h4a1,1,0,0,0,0-2H13V7a1,1,0,0,0-2,0v4H7a1,1,0,0,0,0,2h4v4A1,1,0,0,0,12,18Z"
        />
      </svg>
    </Blank>
  );
};

export default Create;
