import React from "react";
import styled from "styled-components";

interface StepBarProps {
  text: string;
  percentage: number;
}

const StyledStepBar = styled.div.attrs((props: StepBarProps) => ({
  percentage: props.percentage,
}))`
  width: 100%;
  margin-bottom: 30px;

  & .wrapper {
    background: #e8f0fe;
    border-radius: 3px;
    height: 6px;
  }

  & .bar {
    background: #0d3af8;
    width: ${(props) => props.percentage}%;
    border-radius: 3px;
    height: 100%;
  }

  & .text {
    font-style: normal;
    font-size: 12px;
    text-align: center;
    font-weight: 500;
    margin-bottom: 9px;

    color: #000000;
  }
`;

const StepBar: React.FC<StepBarProps> = (props) => {
  return (
    <StyledStepBar percentage={props.percentage}>
      <div className="text">{props.text}</div>
      <div className="wrapper">
        <div className="bar" />
      </div>
    </StyledStepBar>
  );
};

export default StepBar;
