import {
  AddOpenedResourceAction,
  ClearOpenedResourcesAction,
  SyllabusTypes,
} from "../types/SyllabusTypes";
import { OpenedResource } from "../../../model/Resource";

export const setOpenedResources = (
  openedResources: OpenedResource[]
): AddOpenedResourceAction => {
  return {
    type: SyllabusTypes.AddOpenedResource,
    payload: {
      openedResources: openedResources,
    },
  };
};

export const clearOpenedResources = (): ClearOpenedResourcesAction => {
  return {
    type: SyllabusTypes.ClearOpenedResources,
    payload: undefined,
  };
};
