import React from "react";

import HelpQuestionMarkIcon from "../../assets/images/question-mark.png";
import { openPopupWidget } from "react-calendly";
import { Box, Keyboard, Tip } from "grommet";
import styled from "styled-components";

const StyledDiv = styled.div`
  position: fixed;
  bottom: 16px;
  right: 16px;
  z-index: 10;
`;
const StyledImage = styled.img`
  width: 50%;
`;

interface FloatingHelpButtonProps {}

const FloatingHelpButton: React.FC<FloatingHelpButtonProps> = () => {
  const onClick = () => {
    openPopupWidget({
      url: "https://calendly.com/dboone-216/15min",
    });
  };

  return (
    <Keyboard onEnter={onClick}>
      <StyledDiv onClick={onClick} tabIndex={0}>
        <Box
          width={"40px"}
          height={"40px"}
          round={"full"}
          border={true}
          justify={"center"}
          align={"center"}
        >
          <Tip
            content="Schedule a meeting with us"
            dropProps={{ margin: { vertical: "small" } }}
          >
            <StyledImage src={HelpQuestionMarkIcon} alt="Question Mark Icon" />
          </Tip>
        </Box>
      </StyledDiv>
    </Keyboard>
  );
};

export default FloatingHelpButton;
