import React, { useEffect, useRef, useState } from "react";
import TextButton from "../../ui-kit/TextButton/TextButton";
import ArrowPrevious from "../../../assets/icons/ArrowPrevious";
import styled from "styled-components";
import { Box } from "grommet/components/Box";
import Search from "../../../assets/icons/Search";
import CheckBox from "../../ui-kit/CheckBox/CheckBox";
import TextInput from "../../ui-kit/TextInput/TextInput";
import GoogleBookSDK from "../../../third-party/GoogleBook/GoogleBookSDK";
import _ from "lodash";
import YoutubeSDK from "../../../third-party/Youtube/YoutubeSDK";
import GoogleOERSDK from "../../../third-party/GoogleOER/GoogleOERSDK";
import MaterialsList from "../../MaterialsList";
import {
  Activity,
  GoogleBookResource,
  GoogleCustomSearchResultResource,
  OpenedResource,
  Resource,
  VideoResource,
} from "../../../model/Resource";
import { useAppDispatch } from "../../../redux/configureStore";
import { setOpenedResources } from "../../../redux/syllabus/actions/SyllabusActions";
import { differenceInWeeks } from "date-fns";
import { useOpenedResources } from "../../../hooks/useOpenedResources";

interface AddMaterialSubPageProps {
  onBack?: () => void;
  selectedActivity?: Activity;
  resourcesToBeAdded: Resource[];
  setResourcesToBeAdded: (resources: Resource[]) => void;
  selectedActivityIndex?: number;
}

const GoBackButton = styled(TextButton)`
  padding-top: 34px;
`;

const SearchInput = styled(TextInput)`
  width: 100%;
`;
const Title = styled.div`
  padding: 18px 0;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  color: #030b1d;
`;

export interface RecentlyOpenResource {
  id: string;
  openedAt: Date;
}

const AddMaterialSubPage: React.FC<AddMaterialSubPageProps> = (props) => {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [bookList, setBookList] = useState<GoogleBookResource[]>([]);
  const [videoList, setVideoList] = useState<VideoResource[]>([]);
  const [OERList, setOERList] = useState<GoogleCustomSearchResultResource[]>(
    []
  );

  const openedResources = useOpenedResources();
  const dispatch = useAppDispatch();

  const [loadingResources, setLoadingResources] = useState<boolean>(false);
  const [onlyShowRecentlyOpened, setOnlyShowRecentlyOpened] =
    useState<boolean>(false);

  const searchResources = async (query: string) => {
    const googleBookSdk = new GoogleBookSDK();
    const youtubeSdk = new YoutubeSDK();
    const googleOERSDK = new GoogleOERSDK();
    if (!query) return;
    try {
      setLoadingResources(true);
      const [responseBooks, responseYoutube, responseGoogleOER] =
        await Promise.all([
          googleBookSdk.search(query),
          youtubeSdk.search(query),
          googleOERSDK.search(query),
        ]);
      if (responseBooks.isSuccess) {
        setBookList(responseBooks.getValue()!);
      }
      if (responseYoutube.isSuccess) {
        setVideoList(responseYoutube.getValue()!);
      }
      if (responseGoogleOER.isSuccess) {
        setOERList(responseGoogleOER.getValue()!);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoadingResources(false);
    }
  };

  const delayedQuery = useRef(
    _.debounce((q) => searchResources(q), 500)
  ).current;

  useEffect(() => {
    delayedQuery(searchTerm);
  }, [searchTerm, delayedQuery]);

  useEffect(() => {
    const newResources = openedResources.filter((openedResource) => {
      return differenceInWeeks(new Date(), openedResource.openedAt) < 2;
    });

    if (newResources.length < openedResources.length) {
      dispatch(setOpenedResources(newResources));
      console.log(
        `We remove ${
          openedResources.length - newResources.length
        } because they already expired.`
      );
    }
  }, [dispatch, openedResources]);

  return (
    <>
      <GoBackButton onClick={() => props.onBack?.()} icon={<ArrowPrevious />}>
        Back to Activity #{(props.selectedActivityIndex || 0) + 1}
      </GoBackButton>
      <div>
        <Title>Material Search</Title>
        <Box direction="row" align="center" gap="medium">
          <SearchInput
            placeholder="Search by name or author"
            reverse={false}
            icon={<Search />}
            value={searchTerm}
            onChange={(event) => setSearchTerm(event.target.value)}
          />
          <CheckBox
            checked={onlyShowRecentlyOpened}
            onChange={(event) =>
              setOnlyShowRecentlyOpened(event.target.checked)
            }
            label={"Only show recently opened."}
          />
        </Box>
        <MaterialsList
          loading={loadingResources}
          resourcesToBeAdded={props.resourcesToBeAdded}
          onAdd={(book, checked) => {
            const newResources = props.resourcesToBeAdded.filter(
              (resource) => resource.id !== book.id
            );
            if (checked) {
              newResources.push(book);
            }
            props.setResourcesToBeAdded(newResources);
          }}
          books={{
            bookList: bookList.filter(
              (resource) =>
                !onlyShowRecentlyOpened ||
                openedResources.some(
                  (openedResource) => openedResource.resource.id === resource.id
                )
            ),
            onOpen: (book) => {
              if (
                !openedResources.some(
                  (openedResource) => openedResource.resource.id === book.id
                )
              ) {
                const newOpenedResources: OpenedResource[] = [
                  ...openedResources,
                  {
                    resource: book,
                    openedAt: new Date(),
                  },
                ];
                dispatch(setOpenedResources(newOpenedResources));
              }
              window.open(book.url, "_blank");
            },
          }}
          videos={{
            videoList: videoList.filter(
              (resource) =>
                !onlyShowRecentlyOpened ||
                openedResources.some(
                  (openedResource) => openedResource.resource.id === resource.id
                )
            ),
            onOpen: (video) => {
              if (
                openedResources.some(
                  (openedResource) => openedResource.resource.id === video.id
                ) === false
              ) {
                const newOpenedResources: OpenedResource[] = [
                  ...openedResources,
                  {
                    resource: video,
                    openedAt: new Date(),
                  },
                ];
                dispatch(setOpenedResources(newOpenedResources));
              }
            },
          }}
          OER={{
            OERList: OERList.filter(
              (resource) =>
                !onlyShowRecentlyOpened ||
                openedResources.some(
                  (openedResource) => openedResource.resource.id === resource.id
                )
            ),
            onOpen: (item) => {
              if (
                openedResources.some(
                  (openedResource) => openedResource.resource.id === item.id
                ) === false
              ) {
                const newOpenedResources: OpenedResource[] = [
                  ...openedResources,
                  {
                    resource: item,
                    openedAt: new Date(),
                  },
                ];
                dispatch(setOpenedResources(newOpenedResources));
              }
              window.open(item.url, "_blank");
            },
          }}
          recentlyOpened={openedResources}
          onlyShowRecentlyOpened={onlyShowRecentlyOpened}
        />
      </div>
    </>
  );
};

export default AddMaterialSubPage;
