import React from "react";
import { Blank, IconProps } from "grommet-icons";
import { IconColors } from "../../theme/GlobalTheme";
import { ChildrenIconProps } from "./FileTypeIcon";

const VideosFileButton: React.FC<IconProps & ChildrenIconProps> = (props) => {
  return (
    <Blank
      viewBox="0 0 128 128"
      {...props}
      color="plVideosFileButton
  n"
    >
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 42 42"
        xmlSpace="preserve"
      >
        <title>videos-filebtn</title>
        <path
          fill="white"
          d="M5,1h32c2.2,0,4,1.8,4,4v32c0,2.2-1.8,4-4,4H5c-2.2,0-4-1.8-4-4V5C1,2.8,2.8,1,5,1z"
        />
        <g>
          <path
            fill={
              props.disabled
                ? IconColors["icon-disabled"]
                : IconColors["icon-blue"]
            }
            d="M32,6H10c-2.2,0-4,1.8-4,4v15c0,2.2,1.8,4,4,4h22c2.2,0,4-1.8,4-4V10C36,7.8,34.2,6,32,6z M34,25c0,1.1-0.9,2-2,2H10c-1.1,0-2-0.9-2-2V10c0-1.1,0.9-2,2-2h22c1.1,0,2,0.9,2,2V25z"
          />
          <path
            fill={
              props.disabled
                ? IconColors["icon-disabled"]
                : IconColors["icon-blue"]
            }
            d="M25,30c-1.7,0-3,1.3-3,3s1.3,3,3,3s3-1.3,3-3S26.7,30,25,30z M25,34c-0.6,0-1-0.4-1-1s0.4-1,1-1s1,0.4,1,1
        S25.6,34,25,34z"
          />
          <path
            fill={
              props.disabled
                ? IconColors["icon-disabled"]
                : IconColors["icon-blue"]
            }
            d="M35,32h-4c-0.6,0-1,0.4-1,1s0.4,1,1,1h4c0.6,0,1-0.4,1-1S35.6,32,35,32z"
          />
          <path
            fill={
              props.disabled
                ? IconColors["icon-disabled"]
                : IconColors["icon-blue"]
            }
            d="M19,32H7c-0.6,0-1,0.4-1,1s0.4,1,1,1h12c0.6,0,1-0.4,1-1S19.6,32,19,32z"
          />
          <path
            fill={
              props.disabled
                ? IconColors["icon-disabled"]
                : IconColors["icon-blue"]
            }
            d="M24.3,15.5l-3.9-2.2c0,0,0,0,0,0c-0.3-0.2-0.7-0.3-1.1-0.3c-0.6,0-1.2,0.2-1.6,0.7c-0.4,0.4-0.7,1-0.7,1.6v4.5
        c0,0.4,0.1,0.8,0.3,1.1c0.3,0.5,0.8,0.9,1.4,1.1c0.2,0.1,0.4,0.1,0.6,0.1c0.4,0,0.8-0.1,1.1-0.3l3.8-2.2c0.3-0.2,0.6-0.5,0.8-0.8
        c0.3-0.5,0.4-1.1,0.2-1.7C25.2,16.3,24.8,15.8,24.3,15.5z M23.4,17.6c0,0-0.1,0.1-0.1,0.1L19.4,20c-0.1,0.1-0.3,0-0.4-0.1
        c0,0,0-0.1,0-0.1v-4.5c0-0.1,0-0.2,0.1-0.2c0,0,0.1-0.1,0.2-0.1c0.1,0,0.1,0,0.1,0l3.9,2.3c0.1,0,0.1,0.1,0.1,0.2
        C23.4,17.5,23.4,17.6,23.4,17.6z"
          />
        </g>
      </svg>
    </Blank>
  );
};

export default VideosFileButton;
