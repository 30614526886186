import React from "react";
import { Blank, IconProps } from "grommet-icons";
import { IconColors } from "../../theme/GlobalTheme";
import { ChildrenIconProps } from "./FileTypeIcon";

const JPEG: React.FC<IconProps & ChildrenIconProps> = (props) => {
  return (
    <Blank viewBox="0 0 39 42" {...props} color="plain">
      <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
      >
        <path
          fill="white"
          d="M15.35.58H8.49A2.29,2.29,0,0,0,6.2,2.87l0,18.28A2.29,2.29,0,0,0,8.5,23.44H21.07a2.29,2.29,0,0,0,2.29-2.29V8.57Z"
        />
        <path
          fill={
            props.disabled
              ? IconColors["icon-disabled"]
              : IconColors["icon-dark-blue"]
          }
          d="M15.11,1.15l7.67,7.66V21.14a1.71,1.71,0,0,1-1.72,1.72H8.5a1.7,1.7,0,0,1-1.21-.5,1.74,1.74,0,0,1-.5-1.22V2.87a1.62,1.62,0,0,1,.5-1.21,1.67,1.67,0,0,1,1.21-.51h6.62m.24-.57H8.49A2.29,2.29,0,0,0,6.2,2.87l0,18.28A2.29,2.29,0,0,0,8.5,23.44H21.07a2.29,2.29,0,0,0,2.29-2.29V8.57l-8-8Z"
        />
        <path
          fill={
            props.disabled
              ? IconColors["icon-disabled"]
              : IconColors["icon-light-blue"]
          }
          d="M15.35.86V6.29a2.29,2.29,0,0,0,2.29,2.29h5.42Z"
        />
        <path
          fill={
            props.disabled
              ? IconColors["icon-disabled"]
              : IconColors["icon-dark-blue"]
          }
          d="M23.06,8.86H17.64a2.58,2.58,0,0,1-2.58-2.57V.86A.28.28,0,0,1,15.24.6a.3.3,0,0,1,.31.06l7.72,7.71a.29.29,0,0,1-.21.49ZM15.63,1.55V6.29a2,2,0,0,0,2,2h4.73Z"
        />
        <rect
          fill={
            props.disabled
              ? IconColors["icon-disabled"]
              : IconColors["icon-blue"]
          }
          x="0.5"
          y="11.15"
          width="21.14"
          height="9.14"
          rx="0.86"
        />
        <path
          fill={
            props.disabled
              ? IconColors["icon-disabled"]
              : IconColors["icon-dark-blue"]
          }
          d="M20.78,11.72a.28.28,0,0,1,.29.28v7.43a.29.29,0,0,1-.28.29H1.36a.29.29,0,0,1-.29-.29V12a.28.28,0,0,1,.28-.29H20.78m0-.58-19.43,0A.85.85,0,0,0,.5,12v7.42a.85.85,0,0,0,.86.86H20.79a.86.86,0,0,0,.85-.86V12a.87.87,0,0,0-.86-.86Z"
        />
        <path
          fill="white"
          d="M6.05,13.48V16.7a1.38,1.38,0,0,1-.39,1,1.44,1.44,0,0,1-1,.37,1.5,1.5,0,0,1-1.06-.38,1.39,1.39,0,0,1-.41-1.06h.92a.74.74,0,0,0,.15.46.48.48,0,0,0,.39.17A.46.46,0,0,0,5,17.13a.63.63,0,0,0,.13-.43V13.48Z"
        />
        <path
          fill="white"
          d="M10.3,15.58a1.26,1.26,0,0,1-.55.52,2,2,0,0,1-.93.2H8.05v1.76H7.13V13.48H8.81a2.08,2.08,0,0,1,.91.19,1.25,1.25,0,0,1,.56.5,1.4,1.4,0,0,1,.19.73A1.47,1.47,0,0,1,10.3,15.58Zm-1-.2a.64.64,0,0,0,.18-.49c0-.44-.25-.67-.75-.67H8v1.34h.73A.77.77,0,0,0,9.34,15.38Z"
        />
        <path
          fill="white"
          d="M12,14.21v1.15h1.55v.73H12v1.22h1.74v.75H11.12V13.47h2.66v.75Z"
        />
        <path
          fill="white"
          d="M17.81,14.85a1.07,1.07,0,0,0-.43-.44,1.36,1.36,0,0,0-.65-.15,1.43,1.43,0,0,0-.72.18,1.26,1.26,0,0,0-.49.53,1.67,1.67,0,0,0-.18.79,1.71,1.71,0,0,0,.18.8,1.22,1.22,0,0,0,.51.52A1.51,1.51,0,0,0,17.64,17a1.36,1.36,0,0,0,.43-.78H16.5v-.7H19v.81a2.11,2.11,0,0,1-.39.88,2.18,2.18,0,0,1-.78.66,2.34,2.34,0,0,1-1.07.25,2.43,2.43,0,0,1-1.2-.3A2.23,2.23,0,0,1,14.7,17a2.45,2.45,0,0,1-.31-1.21,2.42,2.42,0,0,1,.31-1.21,2.12,2.12,0,0,1,.84-.84,2.55,2.55,0,0,1,2.54.08,1.94,1.94,0,0,1,.79,1.06Z"
        />
      </svg>
    </Blank>
  );
};

export default JPEG;
