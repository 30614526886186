import {
  StatisticsActions,
  StatisticsActionType,
} from "../types/StatisticsTypes";

export const addVisit = (): StatisticsActions => ({
  type: StatisticsActionType.AddVisit,
});

export const setVisits = (nVisits: number): StatisticsActions => ({
  type: StatisticsActionType.SetVisits,
  nVisits: nVisits,
});

export const didShowFeedbackDialog = (): StatisticsActions => ({
  type: StatisticsActionType.DidShowFeedbackDialog,
});
