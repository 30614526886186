import React from "react";
import { DropButton } from "grommet";
import styled from "styled-components";

interface DropdownMenuProps {
  label: JSX.Element;
  dropContent: JSX.Element;
  open?: boolean;
}

const StyledDropButton = styled(DropButton)`
  border: 0;
  margin: 0;
  padding: 0;

  &:hover {
    border: 0;
    padding: 0;
    margin: 0;
    outline: 0;
    box-shadow: none;
  }
`;

const DropdownMenu: React.FC<DropdownMenuProps> = (props) => {
  return (
    <StyledDropButton
      open={props.open}
      label={props.label}
      dropAlign={{ top: "bottom", right: "right" }}
      dropContent={props.dropContent}
    />
  );
};

export default DropdownMenu;
