import React from "react";
import styled from "styled-components";
import { Box, Image, Tab, Tabs, ThemeContext, ThemeType } from "grommet";
import TextInput from "../ui-kit/TextInput/TextInput";
import { useGetTemplateList } from "../../hooks/useGetTemplateList";
import TemplateListTable from "./TemplateListTable";
import useDelayedState from "../../hooks/useDelayedState";
import { useHistory, useParams } from "react-router-dom";
import { useAppDispatch } from "../../redux/configureStore";
import { saveTempSyllabus } from "../../redux/syllabus_templates/actions/SyllabusTemplateActions";
import Loading from "../Loading";
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";
import { CourseTitle } from "../../model/Syllabus";
import Search from "../../assets/icons/Search";
import RoundedButton from "../ui-kit/RoundedButton/RoundedButton";
import TemporalTemplatesListTable from "../TemporalTemplatesListTable";
import useTemporalTemplate from "../../hooks/useTemporalTemplate";
import LorainLogo from "../../assets/images/LCCC-Logo.png";

const StyledTextInput = styled(TextInput)`
  width: 350px;
`;

const StyledButtonWrapper = styled.div`
  margin-top: 33px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface TemplateListPageProps {}

const customTheme: ThemeType = {
  global: {
    font: {
      family: "Poppins",
    },
  },
  tabs: {
    header: {
      extend: `
        font-family: 'Poppins';
        font-weight: 600;
      `,
    },
  },
  tab: {
    color: "#4c5460",
    active: {
      color: "#0d3af8",
    },
    margin: "0 20px 0 0",
    extend: `
      width: 150px;
      text-align: center;
    `,
  },
};

/**
 * The dashboard component for listing a users available common.  These are pulled from firebase using the useGetMySyllabi hook.  Clicking the 'Add Syllabus' button opens a modal to either direct users to the template gallery or input basic information for creating a new syllabus.
 */
const TemplateListPage: React.FC<TemplateListPageProps> = (props) => {
  const params = useParams();
  const {
    syllabi,
    getMySyllabi,
    getMySyllabiNextBatch,
    isLoading,
    lastKey,
    lastPage,
  } = useGetTemplateList(params.source!);
  const mySyllabi = useTemporalTemplate();
  const [searchTerm, setSearchTerm] = useDelayedState<string>(
    "",
    getMySyllabi,
    1000
  );
  const history = useHistory();
  const dispatch = useAppDispatch();

  return (
    <>
      <Box align="center" justify="center" pad={{ top: "20px" }}>
        <Image src={LorainLogo} alt="Lorain Logo" width="250px" />
      </Box>
      <ThemeContext.Extend value={customTheme}>
        <Tabs justify="start" alignControls="start" margin="33px 40px">
          <Tab title="Template List">
            <Box direction="row" justify="end" width="100%">
              <StyledTextInput
                type="text"
                value={searchTerm}
                onChange={(event) => setSearchTerm(event.target.value)}
                placeholder="Search for your course"
                icon={<Search />}
                reverse={false}
              />
            </Box>
            {isLoading ? (
              <Loading />
            ) : (
              <>
                <TemplateListTable
                  onClickOnItem={(templateId) => {
                    const template = syllabi!.find(
                      (t) => t.syllabus.parentId === templateId
                    );
                    const syllabus = template!.syllabus;
                    const newSyllabus = _.cloneDeep(syllabus);
                    newSyllabus.parentId = uuidv4();
                    newSyllabus.customization = {
                      fontFamily: "Open Sans",
                      primaryColor: "#193DA9",
                    };
                    dispatch(saveTempSyllabus(newSyllabus));
                    const courseTitle = newSyllabus.sections.find(
                      (section) => section.type === "CourseTitle"
                    ) as CourseTitle;
                    window.analytics.track("Opened template:", {
                      title: courseTitle.title,
                      number: courseTitle.courseNumber,
                      school: courseTitle.school,
                    });
                    history.push(`/edit-template/${newSyllabus.parentId}`);
                  }}
                  syllabus={syllabi?.map((template) => template.syllabus) || []}
                />
                <StyledButtonWrapper>
                  <RoundedButton
                    onClick={() => getMySyllabiNextBatch(lastKey!, searchTerm)}
                    disabled={lastPage}
                  >
                    Get more templates
                  </RoundedButton>
                </StyledButtonWrapper>
              </>
            )}
          </Tab>
          <Tab title="My Syllabi">
            <Box margin="20px 0">
              <TemporalTemplatesListTable
                onClickOnItem={(syllabusId) => {
                  history.push(`/edit-template/${syllabusId}`);
                }}
                syllabi={mySyllabi}
              />
            </Box>
          </Tab>
        </Tabs>
      </ThemeContext.Extend>
    </>
  );
};

export default TemplateListPage;
