export type Colors =
  | "Dark Blue"
  | "Dark Green"
  | "Red"
  | "Purple"
  | "Turquoise";

export interface ColorScheme {
  primaryColor: string;
  tableCell1: string;
  tableCell2: string;
  fieldHover: string;
  fieldPressed: string;
  mainSyllabusColor: string;
  pressedSyllabusColor: string;
  bright: string;
  pressedBright: string;
  lines: string;
  name: string;
}

export const TableColors: { [key in Colors]?: ColorScheme } = {
  "Dark Blue": {
    primaryColor: "#193DA9",
    tableCell1: "#EFF3FF",
    tableCell2: "#DDE6FF",
    fieldHover: "#F5F8FF",
    fieldPressed: "#E9EEFF",
    mainSyllabusColor: "#193DA9",
    pressedSyllabusColor: "#08298A",
    bright: "#0D3AF8",
    pressedBright: "#1141D3",
    lines: "#D0DCF1",
    name: "Dark Blue",
  },
  "Dark Green": {
    primaryColor: "#329F57",
    tableCell1: "#E6FBED",
    tableCell2: "#D8F1E1",
    fieldHover: "#F9FFFB",
    fieldPressed: "#E7F2EB",
    mainSyllabusColor: "#329F57",
    pressedSyllabusColor: "#158D3E",
    bright: "#0FAB1A",
    pressedBright: "#0C9216",
    lines: "#BBD0C2",
    name: "Dark Green",
  },
  Red: {
    primaryColor: "#E72e2e",
    tableCell1: "#ffe2e2",
    tableCell2: "#ffcaca",
    fieldHover: "#fff8f8",
    fieldPressed: "#ffecec",
    mainSyllabusColor: "#e72e2e",
    pressedSyllabusColor: "#b41010",
    bright: "#ff2f2f",
    pressedBright: "#df0000",
    lines: "#decfcf",
    name: "Red",
  },
  Purple: {
    primaryColor: "#a234e4",
    tableCell1: "#f2ddff",
    tableCell2: "#e7cbf8",
    fieldHover: "#fcf6ff",
    fieldPressed: "#f8eaff",
    mainSyllabusColor: "#a234e4",
    pressedSyllabusColor: "#7611b2",
    bright: "#c360ff",
    pressedBright: "#b244f4",
    lines: "#ded3e4",
    name: "Purple",
  },
  Turquoise: {
    primaryColor: "#31a1b4",
    tableCell1: "#d3f5fb",
    tableCell2: "#bceaf2",
    fieldHover: "#f3fdff",
    fieldPressed: "#e0faff",
    mainSyllabusColor: "#31a1b4",
    pressedSyllabusColor: "#198193",
    bright: "#18bdd9",
    pressedBright: "#09acc8",
    lines: "#ccdfe2",
    name: "Turquoise",
  },
};

export const searchColorScheme = (primaryColor: string) => {
  const colorSchemes = Object.entries(TableColors);
  const colorScheme = colorSchemes.find(([key, scheme]) => {
    return scheme!.primaryColor.toUpperCase() === primaryColor.toUpperCase();
  });

  if (!colorScheme) {
    throw new Error(`Color ${primaryColor} not found in ColorSchemes`);
  }

  return colorScheme[1];
};
