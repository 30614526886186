import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/functions";
import "firebase/analytics";

import { firebaseConfig as config } from "./config";

firebase.initializeApp(config);
firebase.analytics();
const db = firebase.firestore();
if (config.useSimulator) {
  console.log("loading emulation settings!");
  firebase.functions().useFunctionsEmulator("http://localhost:5001");
  db.settings({
    host: "localhost:8080",
    ssl: false,
  });
}

export default firebase;
