import { Syllabus } from "../../../model/Syllabus";
import {
  SyllabusTemplateActions,
  SyllabusTemplateActionType,
} from "../types/SyllabusTemplateTypes";

export const saveTempSyllabus = (
  syllabus: Syllabus
): SyllabusTemplateActions => ({
  type: SyllabusTemplateActionType.SaveTempSyllabus,
  payload: syllabus,
});

export const deleteTempSyllabus = (id: string): SyllabusTemplateActions => ({
  type: SyllabusTemplateActionType.DeleteTempSyllabus,
  payload: id,
});

export const saveSchool = (school: string): SyllabusTemplateActions => ({
  type: SyllabusTemplateActionType.SaveSchool,
  payload: school,
});
