import React from "react";
import Heading from "./Heading";
import styled from "styled-components";

interface H4Props {
  className?: string;
  a11yTitle?: string;
}

const StyledH4 = styled(Heading)`
  margin-bottom: 6px !important;
`;

const H4: React.FC<H4Props> = (props) => {
  return (
    <StyledH4 level="4" className={props.className} a11yTitle={props.a11yTitle}>
      {props.children}
    </StyledH4>
  );
};

export default H4;
