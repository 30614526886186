import React from "react";
import ConfirmDialog from "./ConfirmDialog";
import styled from "styled-components";
import { format } from "date-fns";

interface AddModuleDialogProps {
  onClickCancel?: () => void;
  onClickAction?: () => void;
  handleClose?: () => void;
  isOpen: boolean;
  disableButtons?: boolean;
  startDate?: Date;
  endDate?: Date;
}

const StyledNote = styled.div`
  .mini {
    font-size: 13px;
    line-height: 18px;
    color: #474e61;
    margin-top: 5px;
  }
`;

const AddModuleDialog: React.FC<AddModuleDialogProps> = (props) => {
  return (
    <ConfirmDialog
      isOpen={props.isOpen}
      titleLabel={
        <StyledNote>
          You are about to add a week to your schedule <br />
          <b>Do you want to continue?</b>
        </StyledNote>
      }
      description={
        props.startDate &&
        props.endDate &&
        `This will change the end date from ${format(
          props.startDate,
          "MM/dd/yyyy"
        )} to ${format(props.endDate, "MM/dd/yyyy")}`
      }
      confirmButtonProps={{
        label: "Create Week",
        type: "primary",
        variant: "blue",
        onClick: () => props.onClickAction?.(),
      }}
      cancelButtonProps={{
        label: "Cancel",
        type: "secondary",
        variant: "blue",
        onClick: () => props.onClickCancel?.(),
      }}
      handleClose={props.handleClose}
      closeButton={false}
      // description={"Saved changes won't be lost"}
      disableButtons={props.disableButtons}
    />
  );
};

export default AddModuleDialog;
