import firebase from "firebase/app";
import { Profile } from "model/User";
import { v4 as uuidv4 } from "uuid";
import { RepositoryError } from "repositories/RepositoryError";

const firestore = firebase.firestore();

export interface Result {
  user?: string;
  email?: string;
  error?: {
    a: null;
    code: string;
    message: string;
  };
}

class UsersRepository {
  async signInWithEmailAndPassword(email: string, password: string) {
    return await firebase.auth().signInWithEmailAndPassword(email, password);
  }

  async create(
    email: string,
    password: string,
    termsOfUse: boolean
  ): Promise<Result> {
    try {
      const resp = await firebase
        .auth()
        .createUserWithEmailAndPassword(email, password);
      if (resp?.user?.uid) {
        const user = firestore.collection("users").doc(resp.user.uid);
        await user.set({
          firstName: "",
          lastName: "",
          school: "",
          role: "",
          email: email,
          acceptedTermsOfUse: termsOfUse,
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        });
      }
      return {
        user: resp.user?.uid,
        email: email,
      } as Result;
    } catch (error) {
      return { error: error } as Result;
    }
  }

  async save(id: string, user: any) {
    try {
      firestore.collection("users").doc(id).update(user);
    } catch (e) {
      const id = uuidv4();
      const callableCreate = firebase.functions().httpsCallable("saveLog");
      callableCreate({
        message: e.message,
        payload: user,
        id: id,
      });
      throw new RepositoryError(e.message, id);
    }
  }

  async loginWithGoogle() {
    return firebase.auth().signInWithPopup(
      // @ts-ignore
      new firebase.auth.GoogleAuthProvider()
    );
  }

  async getById(id: string): Promise<Profile | undefined> {
    const result = await firestore.collection("users").doc(id).get();
    return result.data() as Profile;
  }

  async enrollInSyllabus(userId: string, code: string): Promise<void> {
    const callable = await firebase
      .functions()
      .httpsCallable("enrollStudentIntoSyllabus");
    const result = await callable({
      userId,
      code,
    });

    if (result.data.status === "error") {
      throw new Error(result.data.message);
    }
  }
}

export default UsersRepository;
