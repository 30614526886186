import React from "react";
import styled from "styled-components";
import clsx from "clsx";
import {
  FormField,
  Select as SelectGrommet,
  Text,
  ThemeContext,
  ThemeType,
} from "grommet";
import Error from "../../../assets/icons/Error";

export interface SelectOption {
  key: string;
  label: string;
}

interface SelectProps {
  className?: string;
  options: SelectOption[];
  onChange: (value: SelectOption) => void;
  onFocus?: () => void;
  a11yTitle: string;
  error?: string;
  label?: string;
  disabled?: boolean;
  value?: string | JSX.Element | object | (string | number | object)[];
  required?: boolean;
}

const StyledFormField = styled(FormField)`
  .text-error {
    margin: 3px 0;
    font-family: Open Sans;
    font-size: 12px;
    line-height: 16px;

    color: #e51144;

    .text {
      margin-left: 6px;
    }
  }

  .label {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #474e61;
    margin: 8px 0;

    &.disabled {
      color: #9da2ac;
    }
  }

  .required {
    color: #e51144;
    margin-left: 5px;
  }

  .select {
    padding: 0 16px;
    font-family: Open Sans;
    font-weight: normal;
    height: 38px;
    font-size: 16px;
  }
`;

const Placeholder = styled(Text)`
  font-family: Open Sans;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #474e61;
`;

const Select: React.FC<SelectProps> = (props) => {
  const customTheme: ThemeType = {
    global: {
      edgeSize: {
        small: "7px",
      },
      selected: {
        background: "#EFF3FF",
        color: "black",
      },
      active: {
        background: "#DDE6FF",
      },
      hover: {
        background: "#EFF3FF",
      },
    },
    text: {
      extend: `
      font-size: 16px;
    `,
    },
    button: {
      disabled: {
        extend: `
          opacity: 1;
          border: 1px solid #9DA2AC !important;
          color: #9DA2AC !important;
        `,
      },
    },
    select: {
      icons: {
        color: !props.disabled ? "#2256F2" : "#9DA2AC",
      },
      control: {
        extend: `
        border: 1px solid ${!!props.error ? `#E51144` : "#E8F0FE"};
        box-shadow: 0px 2px 4px rgba(13, 34, 90, 0.15);
        border-radius: 4px;
        &:active {
            border: 1px solid #0d3af8 !important;
            box-shadow: 0 2px 4px rgba(13, 34, 90, 0.15);
        }
        &:disabled {
            box-shadow: none;
            color: #9DA2AC !important;
            border: 1px solid #9DA2AC !important;
        }
        &:hover {
          border: 1px solid #BFD1F0;
        }
      `,
      },
      container: {
        extend: `
        border: 1px solid #E8F0FE;
        border-radius: 4px;
        font-family: Open Sans;
        font-size: 16px !important;
        color: #030B1D;
      `,
      },
    },
  };

  return (
    <ThemeContext.Extend value={customTheme}>
      <StyledFormField className={props.className}>
        <span
          className={clsx({
            label: true,
            disabled: props.disabled,
          })}
        >
          {props.label}
          {props.required && <span className="required">*</span>}
        </span>
        <SelectGrommet
          className={clsx({
            select: true,
            error: !!props.error,
          })}
          disabled={props.disabled}
          options={props.options}
          value={props.value}
          a11yTitle={props.a11yTitle}
          labelKey="label"
          valueKey="key"
          onChange={(event) => props.onChange(event.value)}
          focusIndicator={true}
          onFocus={() => props.onFocus?.()}
          plain={true}
          placeholder={<Placeholder>Select</Placeholder>}
        />
        {props.error && (
          <div className="text-error">
            <Error size="8px" />
            <span className="text">{props.error}</span>
          </div>
        )}
      </StyledFormField>
    </ThemeContext.Extend>
  );
};

export default Select;
