export enum BlackboardActionType {
  SetTokensBlackboard = "SetTokensBlackboard",
}

export interface SetTokens {
  type: typeof BlackboardActionType.SetTokensBlackboard;
  payload: {
    refreshToken: string;
    accessToken: string;
    userId: string | undefined;
  };
}

export type BlackboardActions = SetTokens;
