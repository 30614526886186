import React, { useEffect, useState } from "react";
import { match, useHistory } from "react-router-dom";
import {
  deleteComponentOnSyllabus,
  Syllabus,
  SyllabusComponent,
} from "../../model/Syllabus";
import { Identifiable } from "utils/Identifiable";
import SyllabusEditor from "./SyllabusEditor";
import { useAppDispatch } from "../../redux/configureStore";
import { saveTempSyllabus } from "../../redux/syllabus_templates/actions/SyllabusTemplateActions";
import useTemporalTemplate from "../../hooks/useTemporalTemplate";

interface SyllabusTemplateEditorProps {
  match: match<Identifiable>;
}

const SyllabusTemplateEditor: React.FC<SyllabusTemplateEditorProps> = (
  props
) => {
  const id = props.match.params.id;
  const syllabi: Syllabus[] = useTemporalTemplate();
  const [syllabus, setSyllabus] = useState<Syllabus | undefined>(undefined);
  const dispatch = useAppDispatch();
  const history = useHistory();

  useEffect(() => {
    const syllabus = syllabi?.find((s) => s.parentId === id);
    setSyllabus(syllabus);
  }, [id, syllabi]);

  const deleteSection = async (componentToDelete: SyllabusComponent) => {
    const o = deleteComponentOnSyllabus(componentToDelete, syllabus!);
    setSyllabus(o);
    dispatch(saveTempSyllabus(o));
  };

  if (syllabus) {
    return (
      <>
        <SyllabusEditor
          showTemplateButtons={true}
          needsRegistration={true}
          syllabus={syllabus}
          onReorderSyllabus={(syllabus: Syllabus) => {
            dispatch(saveTempSyllabus(syllabus));
          }}
          onSavingSyllabus={async (newSyllabus) => {
            dispatch(saveTempSyllabus(newSyllabus));
          }}
          onDeleteComponent={(component) => deleteSection(component)}
          onClickBackButton={() => {
            history.push("/templates/LCCC");
          }}
        />
      </>
    );
  } else {
    return <h1>No syllabus found with id {id}</h1>;
  }
};

export default SyllabusTemplateEditor;
