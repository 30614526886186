import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Header,
  Image,
  Menu,
  Nav,
  ThemeContext,
  ThemeType,
} from "grommet";
import styled from "styled-components";
import { useAppSelector } from "redux/configureStore";
import SyllabusRepository from "repositories/SyllabusRepository";
import { Profile } from "model/User";
import UsersRepository from "repositories/UsersRepository";
import { Syllabus } from "model/Syllabus";
import EnrollInSyllabusModal from "components/modals/EnrollInSyllabusModal";
import { useHistory } from "react-router-dom";
import RoundedButton from "components/ui-kit/RoundedButton/RoundedButton";
import TableStudents from "../ui-kit/TableStudents/TableStudents";
import ScheduleCalendar from "./ScheduleCalendar/ScheduleCalendar";
import clsx from "clsx";

interface SyllabiListPageProps {}

const customTheme: ThemeType = {
  global: {
    font: {
      family: "Poppins",
    },
  },
  tabs: {
    header: {
      extend: `
        font-family: Poppins;
        font-weight: 600;
      `,
    },
  },
  tab: {
    color: "#7886a7",
    active: {
      color: "#030b1d",
    },
    margin: "0 20px 0 0",
    extend: `
      width: 150px;
      text-align: center;
      border-bottom: none;
      
      & span {
        font-size: 24px !important;
      }
    `,
  },
};

const StyledSyllabiListPage = styled.div`
  & .no-syllabi {
    font-size: 16px;
    text-align: center;
    color: #888;
    font-weight: 600;
    width: 100%;
  }
`;

const StyledButtonWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  margin-left: 56px;

  & button {
    background-color: transparent;
    outline: none;
    border: none;
    font-family: "Poppins", serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    text-align: center;
    color: #000000;
    cursor: pointer;
    height: 66px;
    box-sizing: content-box;
    padding: 0 24px;

    &.is-active {
      border-bottom: 5px solid #0d3af8;
      color: #0d3af8;
    }
  }
`;

const SectionTitle = styled.h2`
  font-family: "Poppins", serif;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 33px;
  color: #030b1d;
  margin-bottom: 22.75px;
`;

const SyllabiListPage: React.FC<SyllabiListPageProps> = () => {
  const [enrollInSyllabusModal, setEnrollInSyllabusModal] =
    useState<boolean>(false);
  const profile = useAppSelector(
    (state) => state.firebase.profile
  ) as any as Profile;
  const auth = useAppSelector((state) => state.firebase.auth);
  const [syllabi, setSyllabi] = useState<Syllabus[]>([]);
  const [activeView, setActiveView] = useState("mySyllabi");

  useEffect(() => {
    (async () => {
      const repository = new SyllabusRepository();
      const syllabi = await repository.findManyBiIds(profile.syllabi!);
      setSyllabi(syllabi);
    })();
  }, [profile]);

  const renderEnrollSyllabusModal = (
    <EnrollInSyllabusModal
      isOpen={enrollInSyllabusModal}
      onClickCancel={() => setEnrollInSyllabusModal(false)}
      handleClose={() => setEnrollInSyllabusModal(false)}
      onClickAction={async (code) => {
        const userRepository = new UsersRepository();
        try {
          await userRepository.enrollInSyllabus(auth.uid!, code);
          setEnrollInSyllabusModal(false);
        } catch (e) {
          alert(e.message);
        }
      }}
    />
  );

  const history = useHistory();

  const menuItems = [
    {
      label: "Account",
      onClick: () => {
        history.push("/account");
      },
    },
    {
      label: "Dashboard",
      onClick: () => {
        history.push("/dashboard");
      },
    },
    {
      label: "Logout",
      onClick: () => {
        history.push("/logout");
      },
    },
  ];

  return (
    <ThemeContext.Extend value={customTheme}>
      <Header pad={{ vertical: "none", horizontal: "small" }} elevation="small">
        <Box direction="row">
          <a
            href="/"
            title="BlendED"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Image src="/images/logos/logo.svg" alt="BlendED" width="125px" />
          </a>
          <StyledButtonWrapper>
            <button
              className={clsx({
                "is-active": activeView === "mySyllabi",
              })}
              onClick={() => setActiveView("mySyllabi")}
            >
              My Syllabi
            </button>
            <button
              className={clsx({
                "is-active": activeView === "calendar",
              })}
              onClick={() => setActiveView("calendar")}
            >
              Calendar
            </button>
          </StyledButtonWrapper>
        </Box>
        <Nav direction="row" justify={"between"}>
          <Menu
            icon={false}
            size="medium"
            a11yTitle="User's menu"
            messages={{
              openMenu: "Open Menu",
              closeMenu: "Close Menu",
            }}
            label={
              <Avatar
                src={"/images/placeholders/sample-avatar.png"}
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
              />
            }
            items={menuItems}
          />
        </Nav>
      </Header>
      <StyledSyllabiListPage>
        <Box justify="start" margin="33px 40px">
          {activeView === "mySyllabi" && (
            <Box className="no-syllabi">
              <Box direction="row">
                <SectionTitle className="title">My Sillabi</SectionTitle>
                <Box
                  margin={{ left: "30px" }}
                  width="250px"
                  direction="row"
                  align="center"
                >
                  <RoundedButton onClick={() => setEnrollInSyllabusModal(true)}>
                    Link New Syllabus
                  </RoundedButton>
                </Box>
              </Box>
              <TableStudents syllabus={syllabi} />
            </Box>
          )}
          {activeView === "calendar" && (
            <Box margin="" justify="center">
              <ScheduleCalendar syllabi={syllabi} />
            </Box>
          )}
        </Box>
        {renderEnrollSyllabusModal}
      </StyledSyllabiListPage>
    </ThemeContext.Extend>
  );
};

export default SyllabiListPage;
