import React from "react";
import { Blank, IconProps } from "grommet-icons";
import { ChildrenIconProps } from "./FileTypeIcon";

const Videos: React.FC<IconProps & ChildrenIconProps> = (props) => {
  return (
    <Blank {...props} viewBox="0 0 24 24" color="plain">
      <svg
        version="1.1"
        id="Layer_1"
        x="0px"
        y="0px"
        viewBox="0 0 24 24"
        xmlSpace="preserve"
      >
        <path
          fill="currentColor"
          d="M19,18H5c-1.7,0-3-1.3-3-3V5c0-1.7,1.3-3,3-3h14c1.7,0,3,1.3,3,3v10C22,16.7,20.7,18,19,18z M5,4
	C4.4,4,4,4.4,4,5v10c0,0.6,0.4,1,1,1h14c0.6,0,1-0.4,1-1V5c0-0.6-0.4-1-1-1H5z"
        />
        <circle fill="currentColor" cx="15.5" cy="20.5" r="1.5" />
        <path
          fill="currentColor"
          d="M21.2,21.3h-2.5c-0.4,0-0.8-0.3-0.8-0.8s0.3-0.8,0.8-0.8h2.5c0.4,0,0.8,0.3,0.8,0.8S21.7,21.3,21.2,21.3z"
        />
        <path
          fill="currentColor"
          d="M12.3,21.3H2.8c-0.4,0-0.8-0.3-0.8-0.8s0.3-0.8,0.8-0.8h9.5c0.4,0,0.7,0.3,0.7,0.8S12.7,21.3,12.3,21.3z"
        />
        <path
          fill="currentColor"
          d="M10.9,13.1c-0.5,0-1.1-0.3-1.4-0.8c-0.1-0.2-0.2-0.5-0.2-0.8v-3c0-0.4,0.2-0.8,0.5-1.1c0.3-0.3,0.7-0.5,1.1-0.5
	c0,0,0,0,0,0c0.3,0,0.6,0.1,0.8,0.2l2.6,1.5c0.4,0.2,0.6,0.6,0.7,1c0.1,0.4,0,0.8-0.2,1.2c-0.1,0.2-0.3,0.4-0.6,0.6l-2.6,1.5
	C11.4,13,11.1,13.1,10.9,13.1z M10.9,8.4c0,0-0.1,0.1-0.1,0.1v3c0,0.1,0.1,0.1,0.2,0.1l2.6-1.5c0.1-0.1,0-0.2,0-0.2L10.9,8.4
	C10.9,8.4,10.9,8.4,10.9,8.4C10.9,8.4,10.9,8.4,10.9,8.4C10.9,8.4,10.9,8.4,10.9,8.4z"
        />
      </svg>
    </Blank>
  );
};
export default Videos;
