import React, { useEffect, useState } from "react";
import SyllabusViewer from "../../viewer/SyllabusViewer";
import { Syllabus } from "../../../model/Syllabus";
import SignUpModal from "../SignUpModal/SignUpModal";
import {
  AppState,
  useAppDispatch,
  useAppSelector,
} from "../../../redux/configureStore";
import SyllabusRepository from "../../../repositories/SyllabusRepository";
import {
  deleteTempSyllabus,
  saveActivities,
  saveObjectives,
} from "../../../redux/one-week-plan/actions/OneWeekPlanActions";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

interface OneWeekPlanPreviewPageProps {}

const OneWeekPlanPreviewPage: React.FC<OneWeekPlanPreviewPageProps> = (
  props
) => {
  const [signUpModalOpened, setSignUpModalOpened] = useState(false);
  const syllabus = useAppSelector((state) => state.oneWeekPlan.tempSyllabus) as
    | Syllabus
    | undefined;
  const dispatch = useAppDispatch();
  const auth = useSelector((state: AppState) => state.firebase.auth);
  const history = useHistory();

  useEffect(() => {
    window.analytics.track(`Previewed Student View`);
  }, []);

  return (
    <>
      <SignUpModal
        closeButton={true}
        handleClose={() => setSignUpModalOpened(false)}
        opened={signUpModalOpened}
        onNewLogin={async (userId) => {
          const newSyllabus = {
            ...syllabus,
            createdBy: userId,
          } as Syllabus;
          window.analytics.track(`Successful signup`, { id: userId });
          window.analytics.identify(auth.uid, {
            email: auth.email,
          });
          window.analytics.track("Saved User Email");
          const syllabusRepository = new SyllabusRepository();
          const syllabusId = await syllabusRepository.create(newSyllabus!);
          dispatch(deleteTempSyllabus());
          dispatch(saveObjectives(""));
          dispatch(saveActivities([]));

          history.replace("/edit/" + syllabusId);
        }}
      />
      {syllabus && (
        <SyllabusViewer
          mode={{
            type: "one-week-plan",
            onClickContinueEditing: () => {
              setSignUpModalOpened(true);
            },
          }}
          syllabus={syllabus}
        />
      )}
    </>
  );
};

export default OneWeekPlanPreviewPage;
