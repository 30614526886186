import React, { useContext, useEffect, useState } from "react";
import { Module } from "../../../../model/Syllabus";
import ObjectivesEditor from "../ObjectivesEditor";
import ActivitiesEditor from "../ActivitiesEditor";
import { Box } from "grommet";
import styled from "styled-components";
import { formatInTimeZone } from "../../../../helpers/formatInTimeZone";
import { SubPageType } from "../../../editor/carousel/EditorSlide";
import { EditorSlideContext } from "../../../editor/carousel/EditorSlideContextProvider";
import { SectionErrorItem } from "../../../../model/SyllabusValidator/SyllabusErrorBag";

const ObjectivesBox = styled(Box)`
  border-radius: 5px;
  padding: 8px 8px 8px 8px;
  margin: 8px;
`;

interface ModuleEditorProps {
  module: Module;
  onChange: (module: Module) => void;
  onSubPageSelected?: (subPageType: SubPageType) => void;
  needsRegistration?: boolean;
  error?: SectionErrorItem;
}

const ScheduleDates = styled.p`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  padding: 0 12px;
  margin: 0;
  color: #474e61;
`;

const ModuleEditor: React.FC<ModuleEditorProps> = ({
  module,
  onChange,
  onSubPageSelected,
  needsRegistration,
  error,
}) => {
  const [_module, _setModule] = useState({ ...module });
  const { setSelectedActivity, setSelectedActivityIndex } =
    useContext(EditorSlideContext);

  useEffect(() => {
    _setModule((oldModule) => ({
      ...oldModule,
      ...module,
    }));
  }, [module]);

  // Propagate changes to parent
  const setModule = (newModule: Module) => {
    onChange(newModule);
    _setModule(newModule);
  };

  return (
    <Box>
      <ScheduleDates>
        Starts {formatInTimeZone(module.start_at, "MM/dd/yyyy", "UTC")} - Ends{" "}
        {formatInTimeZone(module.end_at, "MM/dd/yyyy", "UTC")}
      </ScheduleDates>
      <ObjectivesBox a11yTitle="Objectives">
        <ObjectivesEditor
          objectives={_module.objectives}
          onChange={(objectives) => {
            setModule({
              ..._module,
              objectives: objectives,
            });
          }}
        />
      </ObjectivesBox>
      <Box a11yTitle="Assignments">
        <ActivitiesEditor
          errors={error?.errors}
          startOfWeek={_module.start_at}
          activities={_module.activities ?? []}
          onChange={(activities) => {
            setModule({
              ..._module,
              activities: activities,
            });
          }}
          onClickSearchForMaterials={(activity, index) => {
            setSelectedActivity(activity);
            setSelectedActivityIndex(index);
            onSubPageSelected?.("activity");
          }}
          needsRegistration={needsRegistration}
        />
      </Box>
    </Box>
  );
};

export default ModuleEditor;
