import React, { useCallback, useEffect, useState } from "react";
import { useFirebase } from "react-redux-firebase";
import { useSelector } from "react-redux";
import { AppState, useAppDispatch } from "../redux/configureStore";
import Copyright from "./Copyright";
import institutions from "../data/us_institutions";
import { HidableComponent } from "./hoc/Hidable";
import { useHistory } from "react-router-dom";
import Joi from "joi";
import { addSnackbarMessage } from "../redux/common/actions/CommonActions";
import { v4 as uuidv4 } from "uuid";
import { Box, Image, Select, Text } from "grommet";
import TextInput from "./TextInput";
import styled from "styled-components";
import SquaredButton from "./ui-kit/SquaredButton/SquaredButton";

interface ProfileCompleteProps {
  hideOrientation?: boolean;
  disableRole?: boolean;
}

type Role = "Teacher" | "Student";

const Container = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ProfileComplete: React.FC<ProfileCompleteProps> = (props) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [school, setSchool] = useState("");
  const firebase = useFirebase();
  const profile = useSelector((state: AppState) => state.firebase.profile);
  const auth = useSelector((state: AppState) => state.firebase.auth);
  const [searching, setSearching] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [schools, setSchools] = useState(institutions);
  const [role] = useState<Role>("Teacher");
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const filterSchools = institutions.filter(
      (institution) =>
        institution.name.toLowerCase().indexOf(searchQuery.toLowerCase()) >= 0
    );

    setTimeout(() => {
      setSearching(false);
      setSchools(filterSchools);
    }, 500);
  }, [searching, searchQuery]);

  const pushDataToSegment = useCallback(
    (firstName: string, lastName: string, school: string, role: Role) => {
      //Identify user by uid on Segment
      window.analytics.identify(auth.uid, {
        name: firstName,
        firstName: firstName,
        lastName: lastName,
        school: school,
        role: role,
      });

      window.analytics.track("Saved User Data");
    },
    [auth.uid]
  );

  useEffect(() => {
    if (profile.isLoaded && !profile.isEmpty) {
      pushDataToSegment(
        profile.firstName,
        profile.lastName,
        profile.school,
        profile.role
      );
      if (profile.firstName?.length > 0) {
        setFirstName(profile.firstName);
      }
      if (profile.lastName?.length > 0) {
        setLastName(profile.lastName);
      }
      if (profile.school?.length > 0) {
        setSchool(profile.school);
      }
    }
  }, [profile, pushDataToSegment]);

  const handleSubmit = async () => {
    const schema = Joi.object({
      firstName: Joi.string().required().messages({
        "string.empty": "Firstname is required",
      }),
      lastName: Joi.string().required().messages({
        "string.empty": "Lastname is required",
      }),
      school: Joi.string().required().messages({
        "string.empty": "Please, select a school from the dropdown",
      }),
      role: Joi.string().required().messages({
        "string.empty": "Please, select a role from the dropdown",
      }),
    }).options({
      abortEarly: false,
    });
    const result = schema.validate({ firstName, lastName, school, role });
    if (result.error) {
      dispatch(
        addSnackbarMessage({
          type: "warning",
          message: result.error.message,
          id: uuidv4(),
        })
      );
      return;
    }

    setLoading(true);
    const callableCreate = firebase.functions().httpsCallable("setRole");
    await callableCreate({ role: role });

    await (async () => {
      try {
        await firebase.updateProfile({
          firstName: firstName,
          lastName: lastName,
          school: school,
          role: role,
        });
        await pushDataToSegment(firstName, lastName, school, role);

        if (role === "Student") {
          history.push("/students");
        } else if (role === "Teacher") {
          history.push("/");
        }
      } catch (error) {
        console.log(JSON.stringify(error));
      } finally {
        setLoading(false);
      }
    })();
  };

  return (
    <Container>
      <Box width="medium">
        <HidableComponent isVisible={!props.hideOrientation}>
          <Image
            src="/images/illustrations/profile-complete.svg"
            alt="BlendEd teaching image"
          />
          <Text
            size="large"
            textAlign="center"
            margin={{ vertical: "xxsmall" }}
          >
            Complete your Profile
          </Text>
          <Text
            size="medium"
            textAlign="center"
            margin={{ vertical: "xxsmall" }}
          >
            Tell us a bit more about yourself.
          </Text>
        </HidableComponent>
        <Box margin={{ top: "medium" }}>
          <Text margin={{ bottom: "xsmall" }}>First Name</Text>
          <TextInput
            placeholder="First Name"
            a11yTitle="First Name"
            value={firstName}
            onChange={(e) => {
              setFirstName(e.target.value);
            }}
          />
        </Box>
        <Box margin={{ top: "medium" }}>
          <Text margin={{ bottom: "xsmall" }}>Last Name</Text>
          <TextInput
            placeholder="Last Name"
            a11yTitle="Last Name"
            value={lastName}
            onChange={(e) => {
              setLastName(e.target.value);
            }}
          />
        </Box>
        <Box margin={{ top: "medium" }}>
          <Text margin={{ bottom: "xsmall" }}>School</Text>
          <Select
            placeholder="University"
            a11yTitle="Select for schools"
            color="black"
            value={school}
            options={schools.map((option) => option.name)}
            onChange={(e) => {
              setSchool(e.value);
              setSearching(false);
              setSearchQuery("");
            }}
            onSearch={(query) => {
              setSearching(true);
              setSearchQuery(query);
            }}
          />
        </Box>
        <Box align="start" pad={{ vertical: "medium" }}>
          <SquaredButton
            type="primary"
            onClick={() => handleSubmit()}
            size="regular"
            variant="blue"
            loading={loading}
          >
            Save
          </SquaredButton>
        </Box>
        <Box pad={{ vertical: "large" }} align="center">
          <Copyright />
        </Box>
      </Box>
    </Container>
  );
};

export default ProfileComplete;
