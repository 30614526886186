import React from "react";
import {
  Box,
  Calendar as GrommetCalendar,
  DropButton,
  ThemeContext,
  ThemeType,
} from "grommet";
import styled from "styled-components";
import { format } from "date-fns-tz";
import CalendarIcon from "../../../assets/icons/Calendar";
import TextInput from "../TextInput/TextInput";
import { formatInTimeZone } from "../../../helpers/formatInTimeZone";

interface CalendarProps {
  open: boolean;
  onClose: () => void;
  onOpen: () => void;
  date: Date;
  onSelect: (value: string) => void;
  a11yTitle: string;
  label: string;
  error?: string;
  disabled?: boolean;
}

const Label = styled.p`
  color: #aaaaaa;
  font-size: 16px;
  font-family: "Lato";
  font-weight: 600;
  border: 0 !important;
  border-radius: 0 !important;
`;

const StyledDropButton = styled(DropButton)`
  width: 100%;
`;

const StyledCalendar = styled(GrommetCalendar)`
  align-content: center;
  box-shadow: 0 6px 12px rgba(13, 34, 90, 0.2);

  h1 {
    font-family: Poppins !important;
    color: #333333;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    margin-left: 10px;
  }

  div[role="row"] > div[role="gridcell"] {
    margin: 4px 6px;
    font-family: Open Sans;
    font-size: 14px;
    font-weight: 600;
    line-height: 19px;
    color: #474e61;
  }

  button {
    font-weight: 700 !important;
    color: #030b1d !important;
  }

  button > div {
    border-radius: 4px;
  }
`;

const Calendar: React.FC<CalendarProps> = (props) => {
  const customTheme: ThemeType = {
    global: {
      size: {
        small: "282px",
      },
      colors: {
        icon: "#0D3AF8",
      },
      drop: {
        // @ts-ignore
        extend: `
          width: 280px !important;
      `,
      },
    },
    calendar: {
      extend: `
        height: 318px !important;
      `,
      heading: {
        level: "1",
      },
    },
  };
  return (
    <ThemeContext.Extend value={customTheme}>
      <StyledDropButton
        open={props.open}
        onClose={props.onClose}
        onOpen={props.onOpen}
        dropAlign={{ top: "bottom", left: "left" }}
        disabled={props.disabled}
        dropContent={
          <StyledCalendar
            date={formatInTimeZone(props.date, "MM/dd/yyyy", "UTC")}
            onSelect={(value) => {
              props.onSelect(value as string);
            }}
            showAdjacentDays={true}
            size="small"
            daysOfWeek
            a11yTitle={props.a11yTitle}
            messages={{
              previous: `Moved to previous month ${format(
                props.date,
                "MM/dd/yyyy"
              )}`,
              next: `Moved to next month ${formatInTimeZone(
                props.date,
                "MM/dd/yyyy",
                "UTC"
              )}`,
            }}
            fill={true}
          />
        }
      >
        <Box direction="row" align="center">
          {props.date ? (
            <TextInput
              active={props.open}
              value={formatInTimeZone(props.date, "MM/dd/yyyy", "UTC")}
              icon={<CalendarIcon />}
              onChange={() => {}}
              placeholder="Select"
              label={props.label}
              error={props.error}
              disabled={props.disabled}
            />
          ) : (
            <Label>{props.label}</Label>
          )}
        </Box>
      </StyledDropButton>
    </ThemeContext.Extend>
  );
};

export default Calendar;
