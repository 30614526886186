import React from "react";
import { OfficeHours } from "../../../model/Syllabus";
import SectionEditorProps from "./SectionEditorProps";
import useDelayedState from "../../../hooks/useDelayedState";
import TextInput from "components/ui-kit/TextInput/TextInput";

const OfficeHoursEditor: React.FC<SectionEditorProps> = (props) => {
  const [officeHours, setOfficeHours] = useDelayedState<OfficeHours>(
    { ...(props.section as OfficeHours) },
    props.onChange
  );

  const onChangeOfficeHours = (body: string) => {
    setOfficeHours({
      ...officeHours,
      officeHours: body,
    });
  };

  return (
    <TextInput
      label="Office Hours"
      required={true}
      value={officeHours.officeHours}
      a11yTitle={"Office Hours input"}
      placeholder={"When and where are your office hours?"}
      onChange={(event) => {
        if (event.target.value !== undefined) {
          onChangeOfficeHours(event.target.value);
        }
      }}
      error={props.error?.errors.officeHours}
    />
  );
};

export default OfficeHoursEditor;
