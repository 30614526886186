import React from "react";
import { Box } from "grommet";
import RoundedButton from "./ui-kit/RoundedButton/RoundedButton";
import styled from "styled-components";
import TextButton from "./ui-kit/TextButton/TextButton";
import CustomModal from "./modals/CustomModal";

interface TermsOfUseDialogProps {
  open: boolean;
  onClickClose: () => void;
  title: string;
  acceptLabel?: string;
  cancelLabel?: string;
  onClickAccept?: () => void;
}

const Title = styled.p`
  font-family: Poppins;
  font-weight: 400;
  font-size: 22px;
  line-height: 25px;
`;

const List = styled.div`
  &.primary {
    padding-left: 10px;
  }
  ,
  &.secondary {
    padding-left: 20px;
  }
`;

const StyledLayer = styled(CustomModal)`
  max-width: 800px;
  max-height: 90%;
`;

const Content = styled(Box)`
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #000000;
`;

const TermsOfUseDialog: React.FC<TermsOfUseDialogProps> = (props) => {
  return (
    <StyledLayer opened={props.open} size="regular">
      <Box fill>
        <Box
          direction="row"
          align="center"
          as="header"
          justify="center"
          pad={{ top: "30px", bottom: "20px" }}
        >
          <Title>Terms of Use</Title>
        </Box>
        <Content flex overflow="auto">
          Thank you for your interest in What’s Next ED Corporation (“BlendEd”)
          (the “Platform”). The following terms and conditions (“Terms of Use”)
          govern your use of this Platform’s website and applications and their
          features and services related to the Platform, whether appearing on
          The Platform’s website, a mobile device, or any other device or
          technology (the “Platform Technology”). The Platform Technology is
          administered and made available by the Platform’s online manager,
          BlendEd (or “We” or “Us”).
          <br />
          <br />
          ACCEPTANCE
          <br />
          These Terms of Use are a legally binding contract between you and
          BlendEd. By accessing, contributing to, or otherwise using the
          Platform Technology, you acknowledge that you have read and understand
          and agree to these Terms of Use, have read and understand BlendEd’s
          Privacy Policy. BlendEd’s Privacy Policy is available here. The
          BlendEd’s Privacy Policy explains how information from and about you
          are used when you use the Platform Technology. If you do not accept or
          agree with any part of these Terms of Use or the Privacy Policy,
          please leave the website immediately and do not use or access the
          Platform Technology.
          <br />
          <br />
          MODIFICATIONS TO THE TERMS OF USE
          <br />
          We reserve the right to modify or change these Terms of Use at any
          time, and each such modification will be effective upon posting on
          this website. Your use of the Platform Technology following changes to
          these Terms of Use will constitute your acceptance to the changes;
          provided, however, any change to these Terms of Use shall not apply
          retroactively to any claim or dispute between you and us in connection
          with these Terms of Use and your use of the Platform Technology that
          arose prior to the date any changes became effective.
          <br />
          <br />
          YOUR PLATFORM SERVICES ACCOUNT
          <br />
          When you use the Platform Technology, you may have the opportunity to
          create an account. To create an account, you may be required to select
          a username, password, and provide an email address as well as certain
          other information that we may request. You may not use some features
          of the Platform Technology without an account. All registration
          information you submit in connection with your use of the Platform
          Technology must be truthful, complete, accurate and up-to-date,
          including but not limited to the information that you submit with your
          application to the Platform. You must maintain the accuracy of that
          information. You must not enter or use a false name or birthdate, use
          an email address that is owned or controlled by another person, use a
          name subject to any rights of a person other than yourself, or use
          another user’s account, without appropriate authorization. You must
          not create accounts by automated means or under false or fraudulent
          pretenses. Your account is for your personal, educational, and
          non-commercial use only. You are responsible for maintaining the
          confidentiality of the password for your account and you are fully
          responsible for all activities that occur under your account. You
          agree to immediately notify BlendEd at support@blendedcourse.com of
          any unauthorized use of your account. Please include “Unauthorized
          Access” in the subject line of any such email.
          <br />
          <br />
          APPLICATION DATA
          <br />
          Should you use the Platform, the information may be collected by
          BlendEd. BlendEd’s Privacy Policy applies to the collection of
          application data and their Privacy Policy may be viewed here.
          <br />
          <br />
          USING THE PLATFORMS SERVICE
          <br />
          You may use the Platform Technology only for lawful purposes and in
          accordance with these Terms of Use. By using the Platform Technology,
          you agree to the following:
          <br />
          <List className="primary">
            1. You agree not to use the Platform Technology, intentionally or
            unintentionally, to violate any applicable local, state, federal or
            international law or regulation; 2. You agree not to defame, abuse,
            harass, intimidate, stalk, threaten or otherwise violate the legal
            rights of others, including without limitation, others’ privacy
            rights or rights of publicity. 3. You agree not to collect any
            information about other users (including usernames and/or email
            addresses) for any purpose unrelated to your participation in the
            Platform. 4. You agree not to create or transmit advertising or
            unwanted electronic communications such as “spam” to other users of
            the Platform Technology. 5. You agree not to use the Platform
            Technology to violate the security of any computer network, crack
            passwords or security encryption codes, transfer or store illegal
            material including materials that are deemed threatening or obscene;
            or gain unauthorized access to other computer systems, materials, or
            information made available or through the Platform Technology. 6.
            You agree not to take any action that could, disable, overburden,
            damage, or impair the Platform Technology or BlendEd’s information
            technology infrastructure, or interfere with any other use of the
            Platform Technology, including but not limited to, any user’s
            ability to engage in real-time activities. You agree not to
            otherwise use any device, software or routine that interferes or
            attempts to interfere with the proper administration of the Platform
            Technology. 7. You agree not to use any robot, spider or other
            automatic device, process or means to access the Platform Technology
            for any purpose, including collecting, monitoring, or copying any of
            the material made available by or through the Platform Technology,
            including without limitation, any information residing on any server
            or database connected to the Platform Technology. You agree not to
            reverse engineer any portion of the Platform Technology and not to
            modify or adapt any portion of the Platform Technology, unless
            expressly authorized by BlendEd. 8. You agree not to remove any
            copyright, trademark, or other proprietary rights notices contained
            in or on the Platform Technology. You agree not to reformat, frame
            or deep-link any portion of the Platform Technology. 9. You agree
            not to publish, upload, display, transmit or otherwise make publicly
            available on the Platform Technology any protected health
            information or personally identifiable information of a third
            party(s) without their authorization, including but not limited to,
            addresses, phone numbers, email addresses, social security numbers,
            or credit card numbers. 10. You agree not to impersonate any person
            or entity or misrepresent your affiliation with any other person or
            entity, including BlendEd, and any of its affiliates, students,
            faculty, or staff. 11. You agree that you will not allow any third
            party to violate these Terms of Use. 12. You acknowledge and agree
            that BlendEd may communicate with you and send you administrative
            messages and service announcements. You are not permitted to opt out
            of receiving them while enrolled in the Platform.
            <br />
            We may terminate your use and access of the Platform Technology and
            delete your Platform Technology account at any time, without prior
            notice, for your breach of these Terms of Use or for any conduct
            that we consider inappropriate.
          </List>
          <br />
          <br />
          PROPRIETARY PLATFORM CONTENT
          <br />
          The Platform Technology contain information, text, files, images,
          photos, videos, sounds, musical works, works of authorship,
          applications, associated trademarks, service marks, and trade dress
          and other such materials and content (“Content”) of BlendEd or its
          licensors (“Platform Content”). Platform Content is protected by
          copyright, trademark, patent, trade secret and other laws, and as
          between you and BlendEd, BlendEd owns and retains all rights in the
          Platform Content and Platform Technology, subject to your underlying
          ownership in any User Content (defined below) that you post on the
          Platform Technology. Except as expressly authorized by BlendEd in
          writing, you agree to not reproduce, duplicate, publicly display,
          distribute, archive, modify, publish, broadcast, transmit, retransmit,
          or sell the Platform Content, (whether in whole or in part).
          <br />
          <br />
          USER CONTENT
          <br />
          Certain features of the Platform Technology may permit users to
          submit, publish, upload, transmit, or otherwise make available on the
          Platform Technology (hereinafter “Post”) Content, including, but not
          limited to academic material, text, photographs, videos, articles,
          music, creative works, ideas, or other materials (the “User Content”).
          You retain all ownership rights that you have in User Content that you
          post on the Platform Technology, subject only to the limited and
          revocable license you grant to BlendEd herein solely for the purpose
          of providing you the services identified on the Platform.
          <br />
          By posting User Content on the Platform Technology, you hereby agree
          to the following without BlendEd owing any further obligation to you,
          financial or otherwise:
          <br />
          <List className="primary">
            1. Warranties. You represent and warrant that you solely own, or
            otherwise have the full right and permission to exploit all of the
            rights to the User Content and to grant the rights and licenses set
            forth herein. You further represent and warrant that such User
            Content does not violate these Terms of Use or violate or infringe
            upon the rights of BlendEd, or any third party, including, without
            limitation, rights in copyright, trademark, trade secret,
            confidentiality, contract, patent; rights of privacy or publicity;
            or any other proprietary right. 2. Content License. You own any
            content you post on the Platform, including any educational content.
            You hereby grant to BlendEd and any affiliates, subcontractors,
            licensees, successors and assigns a non-exclusive paid-up,
            royalty-free, and transferable right and license to use, adapt,
            archive, copy, delete, disclose, display, distribute, enhance,
            import, modify, post, publish, reproduce, reformat any User Content
            that you Post on the Platform Technology for the purposes providing
            you the services set forth on the Platform Technology, as well as
            any rights necessary to prevent User Content from being “lifted” or
            used without attribution by third-party sites, subject, however, to
            the Privacy Policy. 3. Attribution. You grant BlendEd the right to
            associate your name, image, voice, photograph, likeness, or any
            other personally identifiable information in connection with User
            Content that you post on the Platform Technology in accordance with
            the terms of the Privacy Policy. 4. Prohibited Content. You are
            responsible for complying with all laws applicable to the User
            Content that you Post on the Platform Technology. You may not Post,
            distribute, or reproduce in any way any copyrighted material,
            trademarks, or other proprietary information without obtaining the
            prior written consent of the owner of such proprietary rights.
            <br />
            You further agree not to Post any User Content that:
            <List className="secondary">
              * Contains or implies false or misleading indications of origin,
              or endorsement; * falsely expresses or implies that such User
              Content is sponsored or endorsed by BlendEd; * anything that would
              constitute plagiarism; * contains vulgar, profane, abusive or
              hateful language, epithets or slurs, inflammatory attacks of a
              personal, racial or religious nature, or expressions of bigotry,
              racism, discrimination or hate; * transmits any viruses, worms,
              defects, Trojan horses, spyware, or other computer code that is
              harmful or invasive or is intended to hijack the operation of, or
              to monitor the use of, any hardware, or software, or equipment; *
              unfairly interferes with any third party’s uninterrupted use and
              enjoyment of the Platform; * includes images, photos, articles or
              other content that constitutes, promote or encourage illegal acts,
              physical harm, violation of any right of any individual or entity,
              violation of any local, state, federal or international law, rule,
              guideline or regulation, or otherwise creates liability; *
              “inflames” any individual or entity (e.g., by sending repeated
              messages related to another user and/or makes derogatory or
              offensive comments about another individual), or repeats prior
              posting of the same message under multiple threads or subjects; or
              * otherwise violates these Terms of Use, as determined by us in
              our sole discretion.
            </List>
            <br />
            BlendEd reserves the right to edit, modify, reject, refuse to Post
            or remove any User Content (including private messages) you or
            third-parties have Posted. A violation of certain of these Terms of
            Use may be referred to law enforcement authorities.
          </List>
          <br />
          <br />
          TRADEMARKS
          <br />
          Certain trademarks, logos, and service marks (collectively the
          "Trademarks") displayed on the Platform Technology are trademarks of
          BlendEd, and its licensors. Nothing contained in the Platform
          Technology should be construed as granting, by implication, estoppel,
          or otherwise, any license or right to use any Trademark displayed on
          the Platform Technology without the written permission of BlendEd, or
          such third party that may own other trademarks displayed in connection
          with the Platform Technology. Your misuse of the Trademarks or any
          other content displayed on the Platform Technology is strictly
          prohibited. You are also advised that BlendEd will aggressively
          enforce its intellectual property rights to the fullest extent of the
          law.
          <br />
          <br />
          INFRINGEMENT
          <br />
          If you believe that Content on the Platform Technology infringes your
          intellectual property rights, please send a notice of claimed
          infringement to support@blendedcourse.com with all of the following
          information:
          <br />
          <List className="primary">
            * identification of the rights you believe to have been infringed,
            such as a copy or description of a copyrighted work or the specific
            word, symbol or logo in which you have trademark rights, along with
            how you came to hold those rights; * identification of the material
            you believe to be infringing in a sufficiently precise manner to
            allow us to locate that material, such as the precise URL (web
            address) on which it appeared, along with any copies you have of
            that web page; * adequate information by which we can contact you
            (including your name, postal address, telephone number and email
            address); * a statement by you that you have a good faith belief
            that the disputed use of the material is not authorized by the
            intellectual property owner, its agent or the law; * a statement by
            you that the information in the written notice is accurate; * a
            statement by you, under penalty of perjury, that the information in
            the notice is accurate and that you are authorized to act on behalf
            of the intellectual property owner; and * your physical or
            electronic signature. * BlendEd’s designated copyright agent for
            notification of claimed copyright infringement can be reached at 25
            Main Street Suite #200, Westlake, OH 44145, or at
            support@blendedcourse.com.
          </List>
          <br />
          <br />
          ACCURACY OF CONTENT
          <br />
          You acknowledge that BlendEd does not guarantee the accuracy,
          integrity or quality of the Content that appears on or through the
          Platform Technology, including without limitation, any other users,
          students, faculty, or staff. You further acknowledge that BlendEd does
          not control the information submitted by any other users and that any
          opinions, advice, services, offers, other information or User Content
          that are disseminated through the Platform Technology are of their
          respective authors, and not BlendEd’s. BlendEd reserves the right, but
          is not obligated, to monitor or verify any third-party content.
          BlendEd recommends that you independently verify all information prior
          to making any educational decisions.
          <br />
          <br />
          THIRD PARTY LINKS
          <br />
          The Platform Technology may contain hyperlinks to other websites and
          webpages (“Third-Party Pages”) as well as to text, graphics, videos,
          images, music, sounds, information, and software applications
          belonging to or originating from other third-parties (collectively,
          “Third-Party Applications”). BlendEd does not control Third-Party
          Pages or Third-Party Applications. You acknowledge that BlendEd does
          not review or monitor any Third-Party Pages or Third-Party
          Applications and that we do not endorse and are not responsible or
          liable for the accuracy, quality, advertising, content, products or
          other materials on or available from such services. If you choose to
          leave the Platform Technology to access any Third-Party Pages or
          Third-Party Applications, you do so at your own risk.
          <br />
          <br />
          TERM
          <br />
          These Terms of Use shall remain in full force and effect while you use
          the Platform Technology or have an account for the Platform
          Technology. You may request that BlendEd terminate your account at any
          time, for any reason, by emailing your request to
          support@blendedcourse.com. We will process your request within a
          reasonable time after receipt. Even after your account is terminated
          and you stop using the Platform Technology, these Terms of Use will
          remain in effect.
          <br />
          <br />
          DISCLAIMERS AND WARRANTIES
          <br />
          YOU EXPRESSLY ACKNOWLEDGE AND AGREE THAT YOUR USE OF THE PLATFORM
          TECHNOLOGY IS AT YOUR SOLE RISK AND THAT THE FUNCTIONS OF THE WEBSITE
          ARE PROVIDED "AS IS" AND "AS AVAILABLE."
          <br />
          BlendEd and its subsidiaries, agents, affiliates, subcontractors,
          licensees, successors and assigns do not represent or warrant:
          <br />
          <List className="primary">
            * your use of the website will meet your requirements; * your use of
            the website will be uninterrupted, available at any time, timely,
            secure or free from error; * the accuracy or completeness of the
            information, text, graphics, links, or other items contained in the
            Platform Technology; * defects in the operation or functionality of
            any software provided to you as part of the website will be
            corrected; or * that the website is free of viruses or other
            potentially harmful components.
          </List>
          <br />
          Any material downloaded or otherwise obtained by you through the use
          of the Platform Technology is done at your own discretion and risk,
          and you will be solely responsible for any damage to your computer
          system or other device, or loss of data that results from the download
          of any such material.
          <br />
          TO THE FULLEST EXTENT PERMISSIBLE BY APPLICABLE LAW, BLENDED FURTHER
          DISCLAIMS ALL WARRANTIES AND CONDITIONS OF ANY KIND, WHETHER EXPRESS,
          STATUTORY OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE IMPLIED
          WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND
          NON-INFRINGEMENT. SOME JURISDICTIONS DO NOT ALLOW LIMITATIONS ON
          CERTAIN WARRANTIES, SO THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU.
          BlendEd IS NOT RESPONSIBLE FOR THE CONDUCT OF ANY USER OF THE PLATFORM
          TECHNOLOGY OR ANY OTHER THIRD PARTY. NO ADVICE OR INFORMATION, WHETHER
          ORAL OR WRITTEN, OBTAINED BY ANY USER FROM BlendEd, OR THE PLATFORM
          TECHNOLOGY SHALL CREATE ANY WARRANTY NOT EXPRESSLY STATED HEREIN.
          <br />
          <br />
          LIMITATION OF LIABILITY
          <br />
          You expressly understand and agree that BlendEd shall not be liable
          for any failure to perform its obligations hereunder where such
          failure results from any cause beyond BlendEd’s reasonable control,
          including without limitation, mechanical, electronic, or
          communications failure or degradation (including “line-noise”
          interference). WITHOUT LIMITING THE FOREGOING, BlendEd, AND ITS
          SUBSIDIARIES, AGENTS, AFFILIATES, SUBCONTRACTORS, LICENSEES,
          SUCCESSORS AND ASSIGNS AND ITS RESPECTIVE, OWNERS, OFFICERS,
          DIRECTORS, EMPLOYEES, OR AGENTS SHALL NOT BE LIABLE TO YOU FOR:
          <br />
          <List className="primary">
            A. ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, PUNITIVE, OR
            EXEMPLARY DAMAGES WHICH MAY BE INCURRED BY YOU, HOWEVER CAUSED AND
            UNDER ANY THEORY OF LIABILITY. THIS SHALL INCLUDE, BUT NOT BE
            LIMITED TO, ANY LOSS OF PROFIT (WHETHER INCURRED DIRECTLY OR
            INDIRECTLY), ANY LOSS OF DATA SUFFERED, COST OF PROCUREMENT OF
            SUBSTITUTE GOODS OR SERVICES, OR OTHER INTANGIBLE LOSS; B. ANY LOSS
            OR DAMAGE WHICH MAY BE INCURRED BY YOU, INCLUDING BUT NOT LIMITED TO
            LOSS OR DAMAGE AS A RESULT OF:
            <>
              <List className="secondary">
                * ANY CHANGES WHICH BlendEd MAY MAKE TO THE WEBSITE, OR FOR ANY
                PERMANENT OR TEMPORARY CESSATION IN THE PROVISION OF THE WEBSITE
                (OR ANY FEATURES WITHIN THE WEBSITE); * THE DELETION OF,
                CORRUPTION OF, OR FAILURE TO STORE, ANY CONTENT AND OTHER
                COMMUNICATIONS DATA MAINTAINED OR TRANSMITTED BY OR THROUGH YOUR
                USE OF THE WEBSITE; * YOUR FAILURE TO PROVIDE Blended WITH
                ACCURATE PERSONAL INFORMATION; * YOUR FAILURE TO KEEP YOUR
                PASSWORD OR ACCOUNT DETAILS SECURE AND CONFIDENTIAL; OR * THIRD
                PARTY CONTENT, INCLUDING ANY ERRORS OR OMISSIONS IN ANY CONTENT.
              </List>
            </>
          </List>
          The limitations on BlendEd's liability shall apply whether or not
          BlendEd has been advised of or should have been aware of the
          possibility of any such losses arising. NOTHING IN THESE TERMS OF USE
          SHALL EXCLUDE OR LIMIT BlendEd's WARRANTY OR LIABILITY FOR LOSSES
          WHICH MAY NOT BE LAWFULLY EXCLUDED OR LIMITED BY APPLICABLE LAW. SOME
          JURISDICTIONS DO NOT ALLOW CONDITIONS OR THE LIMITATION OR EXCLUSION
          OF LIABILITY FOR LOSS OR DAMAGE CAUSED BY NEGLIGENCE, BREACH OF
          CONTRACT OR BREACH OF IMPLIED TERMS, OR INCIDENTAL OR CONSEQUENTIAL
          DAMAGES. ACCORDINGLY, ONLY THE LIMITATIONS WHICH ARE LAWFUL IN YOUR
          JURISDICTION WILL APPLY TO YOU AND BlendEd's OR LIABILITY WILL BE
          LIMITED TO THE MAXIMUM EXTENT PERMITTED BY LAW. NOTWITHSTANDING
          ANYTHING TO THE CONTRARY CONTAINED HEREIN, BlendEd's COMBINED MAXIMUM
          AGGREGATE LIABILITY TO YOU FOR ANY CAUSE WHATSOEVER, AND REGARDLESS OF
          THE FORM OF ACTION, WILL AT ALL TIMES BE LIMITED TO THE GREATER OF (I)
          THE FEES YOU PAID BlendEd FOR USE OF THE PLATFORM, OR (II) $50.00.
          <br />
          <br />
          APPLICABLE LAWS
          <br />
          You acknowledge and agree that these Terms of Use shall be governed by
          and construed in accordance with the laws of the State of New York and
          the United States of America. Any controversy or claim arising out of
          or related to these Terms of Use shall be settled solely and finally
          by arbitration administered in New York, New York before a single
          arbitrator selected by the American Arbitration Association. The
          arbitration shall be conducted in accordance with the commercial rules
          of the American Arbitration Association, unless the parties agree to
          different rules of arbitration. Judgment upon the award rendered may
          be entered in any court with proper jurisdiction. The predominantly
          prevailing party will bear all fees and expenses payable to the
          arbitrators.
          <br />
          Notwithstanding the foregoing, in the event of unauthorized or
          unapproved use of the Platform Content and any other intellectual
          property of, BlendEd and its affiliates, subcontractors, licensees and
          successors, in addition to such other legal and equitable rights and
          remedies as shall be available, BlendEd and its affiliates,
          subcontractors, licensees and successors shall be entitled to seek
          injunctive and other equitable relief, without the necessity of
          proving damages or furnishing a bond or other security.
          <br />
          <br />
          INDEMNIFICATION
          <br />
          You agree to indemnify, defend and hold harmless BlendEd, and its
          affiliates, and its respective employees, faculty, students, officers,
          directors, shareholders, agents, representatives, licensors, and
          subcontractors from and against any and all claims, liabilities, costs
          and expenses, including attorney’s fees, made by any third party to
          the extent arising out of (a) your use of or activities in connection
          with the Platform Technology, (b) the violation of these Terms of Use
          by you (including all other agreements, policies, and rules contained
          or referenced herein) or (c) the infringement by you, or any third
          party using your account, of any intellectual property or other right
          of a third party.
          <br />
          Your obligation to defend and indemnify BlendEd survives the
          termination of these Terms of Use and your use of the Platform
          Technology. BlendEd reserves the right, at its own expense, to assume
          the exclusive defense and control of any claim subject to
          indemnification by you hereunder, and in such case, you agree to
          cooperate with BlendEd’s defense of such a claim.
          <br />
          <br />
          MISCELLANEOUS
          <br />
          The failure of BlendEd to exercise or enforce any right or provision
          of these Terms of Use shall not operate as a waiver of such right or
          provision. The section titles in these Terms of Use are for
          convenience only and have no legal or contractual effect. BlendEd is a
          trademark of BlendEd. The Terms of Use operate to the fullest extent
          permissible by law. If any provision of these Terms of Use is
          unlawful, void or unenforceable, that provision is (i) deemed amended
          to the extent necessary to conform to applicable law, or (ii)
          severable from these Terms of Use, and does not affect the validity
          and enforceability of any remaining provisions. These Terms of Use
          (including all other agreements, policies, rules, and guidelines
          referred to herein) constitute the entire agreement between you and
          BlendEd regarding the use of the Platform Technology.
          <br />
          <br />
          EFFECTIVE DATE:
          <br />
          This Terms of Use was last revised on October 29, 2021 and is
          effective as of that date.
        </Content>
        <Box
          as="footer"
          pad={{ top: "30px", bottom: "30px" }}
          justify="center"
          direction="row"
          align="center"
          gap="90px"
        >
          <TextButton onClick={() => props.onClickClose()} active={true}>
            {props.cancelLabel ?? "I do not accept"}
          </TextButton>
          {props.onClickAccept && (
            <RoundedButton
              onClick={() => props.onClickAccept?.()}
              type="primary"
              variant="blue"
            >
              {props.acceptLabel ?? "Accept"}
            </RoundedButton>
          )}
        </Box>
      </Box>
    </StyledLayer>
  );
};

export default TermsOfUseDialog;
