import React from "react";
import { Blank, IconProps } from "grommet-icons";

const Error: React.FC<IconProps> = (props) => {
  return (
    <Blank {...props} color="plain">
      <svg
        width="24"
        height="24"
        viewBox="0 0 8 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1 1L7 7"
          stroke="currentColor"
          stroke-width="1.5"
          stroke-linecap="round"
        />
        <path
          d="M1 7L7 1"
          stroke="currentColor"
          stroke-width="1.5"
          stroke-linecap="round"
        />
      </svg>
    </Blank>
  );
};

export default Error;
