import React from "react";

import Classic from "../TemplateGallery/Classic/Classic";
import { SyllabusToThemeInterface } from "../../../helpers/syllabusToThemeInterface";

import type { BaseTemplateProps } from "../types";

const BaseTemplate: React.FC<BaseTemplateProps> = (props) => {
  const data = SyllabusToThemeInterface(props.syllabus);

  return (
    <>
      {(() => {
        switch (props.theme) {
          case "Classic":
            return (
              <Classic
                header={{
                  title: data.header.title,
                  number: data.header.number,
                }}
                instructors={{
                  instructors: data.instructors.instructors,
                }}
                officeHours={{
                  officeHours: data.officeHours.officeHours,
                }}
                description={{
                  description: data.description.description,
                }}
                objectives={{ objectives: data.objectives.objectives }}
                schedule={{ weeks: data.schedule.weeks }}
                firebaseId={data.firebaseId}
              />
            );
          default:
            return;
        }
      })()}
    </>
  );
};

export default BaseTemplate;
