import React from "react";
import ConfirmDialog from "./ConfirmDialog";

interface DeleteConfirmationConfirmDialogProps {
  onClickCancel?: () => void;
  onClickAction?: () => void;
  handleClose?: () => void;
  isOpen: boolean;
  disableButtons?: boolean;
}

const DeleteConfirmationConfirmDialog: React.FC<
  DeleteConfirmationConfirmDialogProps
> = (props) => {
  return (
    <ConfirmDialog
      isOpen={props.isOpen}
      titleLabel={
        <div role="note">
          Are you sure you want to <b>delete</b> <br />
          this section?
        </div>
      }
      confirmButtonProps={{
        label: "Delete",
        type: "primary",
        variant: "red",
        onClick: () => props.onClickAction?.(),
      }}
      cancelButtonProps={{
        label: "Keep",
        type: "secondary",
        variant: "blue",
        onClick: () => props.onClickCancel?.(),
      }}
      handleClose={props.handleClose}
    />
  );
};

export default DeleteConfirmationConfirmDialog;
