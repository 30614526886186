import React from "react";
import styled from "styled-components";

import type { ObjectivesProps } from "../../../types";

const StyledObjectivesWrapper = styled.div``;

const Objectives: React.FC<ObjectivesProps> = (props) => {
  return (
    <StyledObjectivesWrapper>
      <h4>Objectives</h4>
      <div>{props.objectives}</div>
    </StyledObjectivesWrapper>
  );
};

export default Objectives;
