import React from "react";
import { Blank, IconProps } from "grommet-icons";
import { ChildrenIconProps } from "./FileTypeIcon";
import { IconColors } from "../../theme/GlobalTheme";

const SVG: React.FC<IconProps & ChildrenIconProps> = (props) => {
  return (
    <Blank viewBox="0 0 39 42" {...props} color="plain">
      <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
      >
        <path
          fill="white"
          d="M14.86.63H8A2.29,2.29,0,0,0,5.72,2.92V21.21A2.27,2.27,0,0,0,8,23.49H20.59a2.34,2.34,0,0,0,1.62-.68,2.26,2.26,0,0,0,.67-1.61V8.63Z"
        />
        <path
          fill={
            props.disabled
              ? IconColors["icon-disabled"]
              : IconColors["icon-dark-blue"]
          }
          d="M14.63,1.2,22.3,8.86V21.2a1.67,1.67,0,0,1-.51,1.21,1.7,1.7,0,0,1-1.21.5H8a1.7,1.7,0,0,1-1.21-.5,1.66,1.66,0,0,1-.5-1.21l0-18.29a1.72,1.72,0,0,1,.5-1.21A1.74,1.74,0,0,1,8,1.21h6.62m.23-.57H8A2.29,2.29,0,0,0,5.72,2.92V21.21A2.27,2.27,0,0,0,8,23.49H20.59a2.34,2.34,0,0,0,1.62-.68,2.26,2.26,0,0,0,.67-1.61V8.63l-8-8Z"
        />
        <path
          fill={
            props.disabled
              ? IconColors["icon-disabled"]
              : IconColors["icon-light-blue"]
          }
          d="M14.86.92V6.35a2.28,2.28,0,0,0,2.28,2.28h5.43Z"
        />
        <path
          fill={
            props.disabled
              ? IconColors["icon-disabled"]
              : IconColors["icon-dark-blue"]
          }
          d="M22.58,8.91H17.15a2.58,2.58,0,0,1-2.57-2.57V.92a.3.3,0,0,1,.17-.27.3.3,0,0,1,.32.07l7.71,7.7a.3.3,0,0,1,.07.32A.3.3,0,0,1,22.58,8.91Zm-7.43-7.3V6.35a2,2,0,0,0,2,2h4.74Z"
        />
        <rect
          fill={
            props.disabled
              ? IconColors["icon-disabled"]
              : IconColors["icon-blue"]
          }
          x="1.16"
          y="11.21"
          width="20"
          height="9.14"
          rx="0.86"
        />
        <path
          fill={
            props.disabled
              ? IconColors["icon-disabled"]
              : IconColors["icon-dark-blue"]
          }
          d="M20.3,11.77a.28.28,0,0,1,.28.29v7.43a.29.29,0,0,1-.29.28H2a.28.28,0,0,1-.29-.28V12.07A.29.29,0,0,1,2,11.78H20.3m0-.57H2a.86.86,0,0,0-.85.86V19.5a.86.86,0,0,0,.86.85H20.3a.85.85,0,0,0,.86-.86V12.06a.86.86,0,0,0-.86-.86Z"
        />
        <path
          fill="white"
          d="M5.47,18a1.52,1.52,0,0,1-.61-.47,1.37,1.37,0,0,1-.23-.73h1a.61.61,0,0,0,.2.44.72.72,0,0,0,.5.17.72.72,0,0,0,.5-.16.47.47,0,0,0,.19-.4.49.49,0,0,0-.13-.34,1,1,0,0,0-.31-.2,5.33,5.33,0,0,0-.52-.17,4.74,4.74,0,0,1-.72-.26,1.13,1.13,0,0,1-.48-.39,1.05,1.05,0,0,1-.2-.69,1.21,1.21,0,0,1,.2-.71,1.3,1.3,0,0,1,.57-.46,2.06,2.06,0,0,1,.84-.16,1.8,1.8,0,0,1,1.16.34,1.33,1.33,0,0,1,.49,1h-1a.5.5,0,0,0-.2-.39.75.75,0,0,0-.49-.15.59.59,0,0,0-.43.14.48.48,0,0,0-.16.4.41.41,0,0,0,.12.3.93.93,0,0,0,.3.2,4.27,4.27,0,0,0,.51.17,7.21,7.21,0,0,1,.73.26,1.23,1.23,0,0,1,.48.4,1.05,1.05,0,0,1,.21.69,1.27,1.27,0,0,1-.19.68,1.39,1.39,0,0,1-.56.51,2,2,0,0,1-.87.18A2.21,2.21,0,0,1,5.47,18Z"
        />
        <path
          fill="white"
          d="M12.87,13.53l-1.68,4.58H10.08L8.39,13.53h1l1.26,3.64,1.25-3.64Z"
        />
        <path
          fill="white"
          d="M16.62,14.9a1.1,1.1,0,0,0-.44-.44,1.29,1.29,0,0,0-.64-.15,1.34,1.34,0,0,0-.72.19,1.23,1.23,0,0,0-.49.52,1.85,1.85,0,0,0,0,1.59,1.28,1.28,0,0,0,.5.53,1.54,1.54,0,0,0,.75.18,1.24,1.24,0,0,0,1.3-1.06H15.31v-.7h2.49v.8a2.15,2.15,0,0,1-.39.89,2.29,2.29,0,0,1-1.85.9,2.43,2.43,0,0,1-1.2-.3,2.1,2.1,0,0,1-.84-.83,2.42,2.42,0,0,1-.31-1.21,2.34,2.34,0,0,1,.31-1.21,2.12,2.12,0,0,1,.84-.84,2.59,2.59,0,0,1,1.19-.3,2.4,2.4,0,0,1,1.35.38,2,2,0,0,1,.79,1.06Z"
        />
      </svg>
    </Blank>
  );
};

export default SVG;
