import React from "react";
import { Blank, IconProps } from "grommet-icons";
import { ChildrenIconProps } from "./FileTypeIcon";
import { IconColors } from "../../theme/GlobalTheme";

const DOC: React.FC<IconProps & ChildrenIconProps> = (props) => {
  return (
    <Blank viewBox="0 0 24 24" {...props} color="plain">
      <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
      >
        <path
          fill="white"
          d="M14.81.63H8A2.29,2.29,0,0,0,5.67,2.92V21.21a2.29,2.29,0,0,0,.67,1.62A2.32,2.32,0,0,0,8,23.49H20.54a2.34,2.34,0,0,0,1.62-.68,2.29,2.29,0,0,0,.66-1.61V8.63Z"
        />
        <path
          fill={
            props.disabled
              ? IconColors["icon-disabled"]
              : IconColors["icon-dark-blue"]
          }
          d="M14.57,1.2l7.67,7.66V21.2a1.71,1.71,0,0,1-1.71,1.71H8a1.74,1.74,0,0,1-1.22-.5,1.7,1.7,0,0,1-.5-1.21V2.92A1.71,1.71,0,0,1,8,1.21h6.62m.24-.57H8A2.29,2.29,0,0,0,5.67,2.92V21.21a2.29,2.29,0,0,0,.67,1.62A2.32,2.32,0,0,0,8,23.49H20.54a2.34,2.34,0,0,0,1.62-.68,2.29,2.29,0,0,0,.66-1.61V8.63l-8-8Z"
        />
        <path
          fill={
            props.disabled
              ? IconColors["icon-disabled"]
              : IconColors["icon-light-blue"]
          }
          d="M14.81.92V6.35A2.29,2.29,0,0,0,17.1,8.63h5.43Z"
        />
        <path
          fill={
            props.disabled
              ? IconColors["icon-disabled"]
              : IconColors["icon-dark-blue"]
          }
          d="M22.53,8.91H17.1a2.58,2.58,0,0,1-2.57-2.57V.92A.29.29,0,0,1,14.7.65.28.28,0,0,1,15,.72l7.72,7.7a.29.29,0,0,1,.06.32A.28.28,0,0,1,22.53,8.91ZM15.1,1.61V6.35a2,2,0,0,0,2,2h4.74Z"
        />
        <rect
          fill={
            props.disabled
              ? IconColors["icon-disabled"]
              : IconColors["icon-blue"]
          }
          x="1.11"
          y="11.21"
          width="20"
          height="9.14"
          rx="0.86"
        />
        <path
          fill={
            props.disabled
              ? IconColors["icon-disabled"]
              : IconColors["icon-dark-blue"]
          }
          d="M20.25,11.77a.29.29,0,0,1,.28.29v7.43a.29.29,0,0,1-.29.28H2a.28.28,0,0,1-.29-.28V12.07A.29.29,0,0,1,2,11.78H20.25m0-.57H2a.87.87,0,0,0-.86.86V19.5a.85.85,0,0,0,.86.85H20.25a.86.86,0,0,0,.86-.86V12.06a.85.85,0,0,0-.85-.86Z"
        />
        <path
          fill="white"
          d="M7,13.81a2,2,0,0,1,.85.81,2.59,2.59,0,0,1,0,2.42,2,2,0,0,1-.84.8,2.86,2.86,0,0,1-1.27.28H4.1V13.53H5.7A2.7,2.7,0,0,1,7,13.81Zm-.18,3.13a1.5,1.5,0,0,0,.39-1.11,1.54,1.54,0,0,0-.39-1.12,1.51,1.51,0,0,0-1.12-.4H5v3h.65A1.53,1.53,0,0,0,6.79,16.94Z"
        />
        <path
          fill="white"
          d="M9.76,17.86a2.27,2.27,0,0,1-1.17-2A2.34,2.34,0,0,1,8.9,14.6a2.39,2.39,0,0,1,2-1.14,2.42,2.42,0,0,1,1.19.3,2.19,2.19,0,0,1,.84.84,2.35,2.35,0,0,1,.32,1.21A2.45,2.45,0,0,1,13,17a2.28,2.28,0,0,1-.85.84,2.42,2.42,0,0,1-1.19.3A2.38,2.38,0,0,1,9.76,17.86Zm1.91-.71a1.2,1.2,0,0,0,.49-.53,1.79,1.79,0,0,0,.18-.81,1.73,1.73,0,0,0-.18-.81,1.32,1.32,0,0,0-.49-.53,1.57,1.57,0,0,0-1.46,0,1.34,1.34,0,0,0-.5.53,2,2,0,0,0,0,1.62,1.28,1.28,0,0,0,.5.53,1.37,1.37,0,0,0,.73.19A1.35,1.35,0,0,0,11.67,17.15Z"
        />
        <path
          fill="white"
          d="M14.05,14.6a2.16,2.16,0,0,1,.84-.83,2.33,2.33,0,0,1,1.18-.3,2.3,2.3,0,0,1,1.35.39A2.08,2.08,0,0,1,18.23,15H17.17a1.05,1.05,0,0,0-.44-.49,1.3,1.3,0,0,0-.66-.17,1.43,1.43,0,0,0-.72.19,1.23,1.23,0,0,0-.48.53,1.87,1.87,0,0,0,0,1.6,1.24,1.24,0,0,0,.48.53,1.34,1.34,0,0,0,.72.19,1.3,1.3,0,0,0,.66-.17,1.08,1.08,0,0,0,.44-.5h1.06a2,2,0,0,1-.8,1.1,2.52,2.52,0,0,1-2.54.09,2.23,2.23,0,0,1-.84-.83,2.41,2.41,0,0,1-.3-1.21A2.44,2.44,0,0,1,14.05,14.6Z"
        />
      </svg>
    </Blank>
  );
};

export default DOC;
