import React from "react";
import { FontFamily } from "./FontConfiguration";
import { ColorScheme, searchColorScheme } from "../../ui-kit/Table/TableColors";

interface SyllabusThemeContextProps {
  fontFamily: FontFamily;
  primaryColor: string;
  colorScheme?: ColorScheme;
}

export const SyllabusThemeContext =
  React.createContext<SyllabusThemeContextProps>({
    fontFamily: "Aleo",
    primaryColor: "#000000",
    colorScheme: undefined,
  });

const SyllabusThemeContextProvider: React.FC<SyllabusThemeContextProps> = (
  props
) => {
  return (
    <SyllabusThemeContext.Provider
      value={{
        fontFamily: props.fontFamily,
        primaryColor: props.primaryColor,
        colorScheme: searchColorScheme(props.primaryColor),
      }}
    >
      {props.children}
    </SyllabusThemeContext.Provider>
  );
};

export default SyllabusThemeContextProvider;
