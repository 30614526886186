import React from "react";
import { Identifiable } from "../../../utils/Identifiable";
import ModulePage from "../../viewer/Module/ModulePage";
import { match } from "react-router-dom";
import useOneWeekPlanTempSyllabus from "../../../hooks/useOneWeekPlanTempSyllabus";

interface OneWeekPlanModulePageProps {
  match: match<Identifiable>;
}

const OneWeekPlanModulePage: React.FC<OneWeekPlanModulePageProps> = (props) => {
  const syllabus = useOneWeekPlanTempSyllabus();
  const index = props.match.params.index;

  if (syllabus) {
    return (
      <>
        <ModulePage syllabus={syllabus} moduleIndex={index} />
      </>
    );
  } else {
    return <div />;
  }
};

export default OneWeekPlanModulePage;
