import { SyllabusActions, SyllabusTypes } from "../types/SyllabusTypes";
import { OpenedResource } from "../../../model/Resource";

export interface SyllabusState {
  openedResources: OpenedResource[];
}

const initialState: SyllabusState = {
  openedResources: [],
};

export const SyllabusReducer = (
  state = initialState,
  action: SyllabusActions
): SyllabusState => {
  switch (action.type) {
    case SyllabusTypes.AddOpenedResource:
      return {
        ...state,
        openedResources: action.payload.openedResources,
      };
    case SyllabusTypes.ClearOpenedResources:
      return {
        ...state,
        openedResources: initialState.openedResources,
      };
    default:
      return state;
  }
};
