import React, { Fragment, useContext } from "react";
import { Module, Syllabus } from "../../../model/Syllabus";
import styled from "styled-components";
import { Box, Text } from "grommet";
import { SyllabusThemeContext } from "../customization/SyllabusTheme";
import RichTextEditorViewer from "../../ui-kit/RichTextEditor/Viewer/RichTextEditorViewer";

const Content = styled(Box)<{ fontFamily: string }>`
  background: #ffffff;
  border: 1px solid #f1f6ff;
  box-sizing: border-box;
  box-shadow: 0 0 8px 1px rgba(7, 26, 67, 0.05);
  border-radius: 6px;
  margin: 35px;
  padding: 67px 75px;
  font-family: ${(props) => props.fontFamily};
`;

const BorderGrey = styled.div`
  border-bottom: 1px solid #d0dcf1;
  margin-top: 40px;
  margin-bottom: 40px;
`;

const ObjectiveTitle = styled.h3`
  line-height: 27px;
  font-size: 20px;
  font-weight: 600;
  color: #030b1d;
`;

const ObjectivesText = styled.div`
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
`;

const ActivityHeader = styled(Text)<{ fontFamily: string }>`
  font-family: ${(props) => props.fontFamily};
  font-size: 20px;
  font-weight: 700;
  color: ${(props) => props.color};
  height: 27px;
  padding-top: 20px;
  flex: none;
`;

interface ModuleViewerProps {
  /**
   * the module to view
   */
  module: Module;
  syllabus?: Syllabus;
  locked?: boolean;
}

/**
 * The module viewer for students/users without edit access to a syllabus.
 */
const ModuleViewer: React.FC<ModuleViewerProps> = ({
  module,
  syllabus,
  locked,
}) => {
  const { primaryColor, fontFamily } = useContext(SyllabusThemeContext);

  const renderedModule = (
    <Content role="contentinfo" fontFamily={fontFamily}>
      <div>
        <ObjectiveTitle color={primaryColor}>
          Week Learning Objectives
        </ObjectiveTitle>
        <ObjectivesText>
          <RichTextEditorViewer value={module.objectives} />
        </ObjectivesText>
      </div>
      <BorderGrey />
      {module.activities && module.activities?.length > 0 && (
        <Box a11yTitle={"Assignments"}>
          {module.activities.map((activity, index) => {
            return (
              <Fragment key={`activity-${index}`}>
                <Box background="white">
                  <ActivityHeader
                    id={"activity-" + index}
                    color={primaryColor}
                    fontFamily={fontFamily}
                  >
                    {activity.title}
                  </ActivityHeader>
                  <div>
                    <RichTextEditorViewer value={activity.body} />
                  </div>
                </Box>
                <BorderGrey />
              </Fragment>
            );
          })}
        </Box>
      )}
    </Content>
  );

  return (
    <Box
      margin={{ left: "19em" }}
      a11yTitle={`Module Viewer, move to your left to navigate from the sidebar`}
    >
      {renderedModule}
    </Box>
  );
};

export default ModuleViewer;
