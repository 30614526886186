import React from "react";
import { Blank, IconProps } from "grommet-icons";
import { ChildrenIconProps } from "./FileTypeIcon";

const Books: React.FC<IconProps & ChildrenIconProps> = (props) => {
  return (
    <Blank viewBox="0 0 24 24" {...props} color="plain">
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 24 24"
        xmlSpace="preserve"
      >
        <path
          fill="currentColor"
          d="M8.8,9.8h6.5C15.7,9.8,16,9.4,16,9s-0.3-0.8-0.8-0.8H8.8C8.3,8.3,8,8.6,8,9S8.3,9.8,8.8,9.8z"
        />
        <path
          fill="currentColor"
          d="M19,5H7C6.4,5,6,4.6,6,4s0.4-1,1-1h12c0.6,0,1-0.4,1-1s-0.4-1-1-1H7C5.3,1,4,2.3,4,4c0,0.1,0,0.1,0,0.2
		c0,0,0,0.1,0,0.1V19c0,2.9,1.5,4,3,4h10c1.7,0,3-1.3,3-3V6.5c0-0.1,0-0.2,0-0.2c0-0.1,0-0.2,0-0.2C20,5.4,19.6,5,19,5z M18,20
		c0,0.6-0.4,1-1,1H7c-0.9,0-1-1.4-1-2V6.8C6.3,6.9,6.6,7,7,7h11V20z"
        />
        <path
          fill="currentColor"
          d="M8.8,20h6.5c0.4,0,0.8-0.3,0.8-0.8v-7.5c0-0.4-0.3-0.8-0.8-0.8H8.8C8.3,11,8,11.3,8,11.7v7.5
		C8,19.7,8.3,20,8.8,20z M9.5,12.5h5v6h-5V12.5z"
        />
      </svg>
    </Blank>
  );
};
export default Books;
