import React, { useEffect } from "react";
import PropTypes from "prop-types";
import queryString from "query-string";
import firebase from "firebase";
import { canvasConfig } from "config/config";
import { useLocation } from "react-router-dom";
import { useFirestore } from "react-redux-firebase";
import { useAppDispatch } from "../../../../redux/configureStore";
import { setBlackboardTokens } from "../../../../redux/integrations/actions/BlackboardActions";
import { setCanvasTokens } from "../../../../redux/integrations/actions/CanvasActions";
import { setExport } from "../../../../redux/integrations/actions/CommonActions";
import { ExportType } from "../../../../redux/integrations/types/CommonTypes";
import Loading from "components/Loading";

const LoaderRedirect = (props: any) => {
  const location = useLocation();
  const firestore = useFirestore();
  const dispatch = useAppDispatch();

  useEffect(() => {
    (async () => {
      const parsed = queryString.parse(location.search);
      if ((parsed.code as string) !== undefined) {
        const code = parsed.code as string;
        if ((parsed.state as string) !== undefined) {
          const state = parsed.state as string;
          if (state.includes("canvas")) {
            const callCanvasAuthenticate = firebase
              .functions()
              .httpsCallable("canvasAuthenticate");
            try {
              const result = await callCanvasAuthenticate({ code: code });
              const token = result.data.access_token as string;
              const refreshToken = result.data.refresh_token as string;
              const userId = result.data.user_id as string;
              dispatch(
                setCanvasTokens(
                  token,
                  refreshToken,
                  userId,
                  canvasConfig.scopes
                )
              );
            } catch (error) {
              console.log(error);
              alert(error);
            }
            let syllabus = state.split("syllabusId=");
            let syllabusId = syllabus[1];
            dispatch(setExport(ExportType.CANVAS));
            let URL = window.location.href.split("loaderRedirect");
            const url = `${URL[0]}edit/${syllabusId}`;
            //@ts-ignore
            window.location = url;
          } else if (state.includes("blackboard")) {
            const callBlackboardAuthenticate = firebase
              .functions()
              .httpsCallable("blackboardAuthenticate");
            try {
              const result = await callBlackboardAuthenticate({ code: code });
              const token = result.data.access_token as string;
              const refreshToken = result.data.refresh_token as string;
              const userId = result.data.user_id as string;
              dispatch(setBlackboardTokens(token, refreshToken, userId));
            } catch (error) {
              console.log(error);
            }
            let syllabus = state.split("syllabusId=");
            let syllabusId = syllabus[1];
            dispatch(setExport(ExportType.BLACKBOARD));
            let URL = window.location.href.split("loaderRedirect");
            const url = `${URL[0]}edit/${syllabusId}`;
            //@ts-ignore
            window.location = url;
          }
        }
      }
    })();
  }, [location.search, firestore, dispatch]);

  return <Loading />;
};

LoaderRedirect.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default LoaderRedirect;
