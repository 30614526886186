import { FileResource } from "../model/Resource";
import firebase from "firebase";
import { default as axios } from "axios";
import { v4 as uuidv4 } from "uuid";
import { addSnackbarMessage } from "../redux/common/actions/CommonActions";
import { configureStore } from "../redux/configureStore";

export const uploadFile = async (files: FileList) => {
  const fileAttachments: FileResource[] = [];
  const dispatch = configureStore().dispatch;

  const storageRef = firebase.storage().ref();
  for (let i = 0; i < files.length; i++) {
    let file = files.item(i);
    try {
      // try converting to PDF except if already PDF or video
      if (
        file?.type !== "application/pdf" &&
        !file?.type.startsWith("video/")
      ) {
        // curl -o result.pdf -F format=pdf -F 'file=@myfile.ppt' https://transformer.blendedcourse.com/convert
        const form = new FormData();
        form.append("format", "pdf");
        form.append("file", file!);
        try {
          const resp = await axios.post(
            "https://transformer.blendedcourse.com/convert",
            form,
            {
              responseType: "blob",
            }
          );
          if (resp.data) {
            file = new File(
              [resp.data],
              file?.name.replace(/(.*)\.[^.]+$/, "$1") + ".pdf",
              { type: "application/pdf" }
            );
          }
        } catch (e) {
          console.log("Couldn't convert file: " + e);
        }
      }

      // upload to firebase storage
      const path = `file-uploads/${uuidv4()}`;
      const fileRef = storageRef.child(path);
      await fileRef.put(file!);
      const url = await fileRef.getDownloadURL();
      // create a document reference for the uploaded file
      // public url = https://content.blendedcourse.com/doc/<docRef>
      const contentCreateRef = firebase
        .functions()
        .httpsCallable("contentCreateRef");
      const result = await contentCreateRef({
        url: url,
        fileName: file?.name,
        fileType: file?.type,
      });
      const docRef = result.data.docId as string;
      const docUrl = result.data.docUrl as string;
      const fileAttachment: FileResource = {
        id: uuidv4(),
        type: "File",
        url: docUrl,
        size: file?.size ?? 1,
        title: file?.name ?? "Untitled",
        contentRef: docRef,
        contentType: file?.type ?? "Type",
      };

      fileAttachments.push(fileAttachment);

      return fileAttachments;
    } catch (error) {
      console.error("upload failed: ", error);
      const id = uuidv4();
      dispatch(
        addSnackbarMessage({
          id: id,
          message: "There was an error uploading your file.",
          type: "critical",
        })
      );
      const callableCreate = firebase.functions().httpsCallable("saveLog");
      callableCreate({
        message: error.message,
        payload: {
          file: files,
        },
        id: id,
      });
      throw error;
    } finally {
    }
  }

  return fileAttachments;
};

export default uploadFile;
