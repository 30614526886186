import React from "react";
import { Blank, IconProps } from "grommet-icons";
import { ChildrenIconProps } from "./FileTypeIcon";
import { IconColors } from "../../theme/GlobalTheme";

const GIF: React.FC<IconProps & ChildrenIconProps> = (props) => {
  return (
    <Blank viewBox="0 0 24 24" {...props} color="plain">
      <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
      >
        <path
          fill="white"
          d="M14.86.59H8A2.29,2.29,0,0,0,5.72,2.89V21.17a2.33,2.33,0,0,0,.67,1.62A2.29,2.29,0,0,0,8,23.46H20.59a2.29,2.29,0,0,0,2.29-2.29V8.59Z"
        />
        <path
          fill={
            props.disabled
              ? IconColors["icon-disabled"]
              : IconColors["icon-dark-blue"]
          }
          d="M14.63,1.17,22.3,8.83V21.16a1.74,1.74,0,0,1-1.72,1.72H8a1.62,1.62,0,0,1-1.21-.5,1.66,1.66,0,0,1-.5-1.21l0-18.28A1.71,1.71,0,0,1,8,1.17h6.62m.23-.58H8A2.29,2.29,0,0,0,5.72,2.89V21.17a2.33,2.33,0,0,0,.67,1.62A2.29,2.29,0,0,0,8,23.46H20.59a2.29,2.29,0,0,0,2.29-2.29V8.59l-8-8Z"
        />
        <path
          fill={
            props.disabled
              ? IconColors["icon-disabled"]
              : IconColors["icon-light-blue"]
          }
          d="M14.86.88V6.31a2.28,2.28,0,0,0,2.28,2.28h5.43Z"
        />
        <path
          fill={
            props.disabled
              ? IconColors["icon-disabled"]
              : IconColors["icon-dark-blue"]
          }
          d="M22.58,8.88H17.15a2.58,2.58,0,0,1-2.57-2.57V.88a.28.28,0,0,1,.17-.26.29.29,0,0,1,.32.06l7.71,7.71a.28.28,0,0,1,.07.31A.31.31,0,0,1,22.58,8.88ZM15.15,1.57V6.31a2,2,0,0,0,2,2h4.74Z"
        />
        <rect
          fill={
            props.disabled
              ? IconColors["icon-disabled"]
              : IconColors["icon-blue"]
          }
          x="1.16"
          y="11.17"
          width="20"
          height="9.14"
          rx="0.86"
        />
        <path
          fill={
            props.disabled
              ? IconColors["icon-disabled"]
              : IconColors["icon-dark-blue"]
          }
          d="M20.3,11.73a.28.28,0,0,1,.28.29v7.43a.28.28,0,0,1-.29.28L2,19.75a.29.29,0,0,1-.29-.29V12A.28.28,0,0,1,2,11.75l18.29,0m0-.57H2a.86.86,0,0,0-.85.86v7.43a.87.87,0,0,0,.86.86H20.3a.87.87,0,0,0,.86-.86V12a.87.87,0,0,0-.86-.86Z"
        />
        <path
          fill="white"
          d="M9.63,14.87a1.1,1.1,0,0,0-.44-.44,1.32,1.32,0,0,0-.64-.15,1.43,1.43,0,0,0-.72.18,1.34,1.34,0,0,0-.5.53,1.8,1.8,0,0,0-.17.79,1.69,1.69,0,0,0,.18.8,1.34,1.34,0,0,0,.5.53,1.54,1.54,0,0,0,.75.18A1.27,1.27,0,0,0,9.45,17a1.38,1.38,0,0,0,.44-.78H8.31v-.7H10.8v.8a2.1,2.1,0,0,1-.4.89,2.21,2.21,0,0,1-.77.65,2.25,2.25,0,0,1-1.07.25,2.43,2.43,0,0,1-1.2-.3A2.16,2.16,0,0,1,6.52,17a2.34,2.34,0,0,1-.31-1.21,2.32,2.32,0,0,1,.31-1.21,2.12,2.12,0,0,1,.84-.84,2.36,2.36,0,0,1,1.19-.3,2.43,2.43,0,0,1,1.35.38,1.88,1.88,0,0,1,.78,1.06Z"
        />
        <path fill="white" d="M12.4,13.49v4.59h-.92V13.49Z" />
        <path
          fill="white"
          d="M16.14,13.49v.74H14.23v1.18h1.46v.73H14.23v1.93h-.92V13.49Z"
        />
      </svg>
    </Blank>
  );
};
export default GIF;
