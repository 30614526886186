import React, { useState } from "react";
import {
  getSectionTypeLabel,
  Schedule,
  Section,
  SectionType,
  SyllabusComponent,
} from "../../../model/Syllabus";
import SectionEditor from "../SectionEditor";
import styled from "styled-components";
import AddMaterialSubPage from "../../sections/subpages/AddMaterialSubPage";
import EditorSlideFooter from "../EditorSlideFooter";
import EditorSlideContextProvider from "./EditorSlideContextProvider";
import { Activity, Resource } from "../../../model/Resource";
import _ from "lodash";
import { SectionErrorItem } from "../../../model/SyllabusValidator/SyllabusErrorBag";

interface EditorSlideProps {
  section: Section;
  sections: Section[];
  onChange: (section: Section) => void;
  isNewSection?: boolean;
  index: number;
  disableDeleteSection: () => void;
  disabledSave: boolean;
  disableSaveAll: boolean;
  onSaveAll: () => void;
  onCancelAndClose?: () => void;
  onDeleteComponent?: (component: SyllabusComponent) => void;
  onCleanUpComponent?: (component: SyllabusComponent) => void;
  /**
   * Disables "Save Changes" and "Save All & Exit" buttons.
   */
  disableButtons?: boolean;
  needsRegistration?: boolean;
  error?: SectionErrorItem;
}

const Root = styled.div`
  &.content {
    height: 100%;
    padding: 14px;
    overflow-y: scroll;
    max-height: 656px;
  }

  & .left {
    width: 100%;
    height: auto;
    background: white;
    padding: 0 20px;
  }

  &.editorSlideRightPane {
    overflow: auto;
    border-left: 2px solid #eef4ff;
    padding: 0.5rem;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: none;
    }

    width: 25%;
    height: 100%;
  }

  & .section-name {
    font-family: "Poppins", serif;
    font-weight: 500;
    font-size: 16px;

    color: #193da9;
  }

  & .container {
    display: flex;
    min-height: 600px;
    @media (min-width: 600px) {
      min-height: 300px;
    }
    @media (min-width: 1300px) {
      min-height: 525px;
    }
    position: relative;
    width: 100%;
    backface-visibility: hidden;
    will-change: overflow;

    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

export type SubPageType = "activity";

const EditorSlide: React.FC<EditorSlideProps> = (props) => {
  const [selectedSubPage, setSelectedSubPage] = useState<
    SubPageType | undefined
  >(undefined);
  const [resourcesToBeAdded, setResourcesToBeAdded] = useState<Resource[]>([]);
  const [selectedActivity, setSelectedActivity] = useState<
    Activity | undefined
  >(undefined);

  const [selectedActivityIndex, setSelectedActivityIndex] = useState<
    number | undefined
  >(undefined);

  const renderPage = () => {
    if (selectedSubPage === "activity") {
      return (
        <AddMaterialSubPage
          selectedActivity={selectedActivity}
          selectedActivityIndex={selectedActivityIndex}
          onBack={() => setSelectedSubPage(undefined)}
          resourcesToBeAdded={resourcesToBeAdded}
          setResourcesToBeAdded={setResourcesToBeAdded}
        />
      );
    } else {
      return (
        <>
          <h4 className="section-name">
            {getSectionTypeLabel(props.section.type as SectionType)}
          </h4>

          <SectionEditor
            key={`section-editor-${props.section.id}`}
            index={props.index}
            section={props.section}
            onChange={props.onChange}
            onSubPageSelected={(subPage) => setSelectedSubPage(subPage)}
            needsRegistration={props.needsRegistration}
            error={props.error}
          />
        </>
      );
    }
  };

  return (
    <EditorSlideContextProvider
      resourcesToBeAdded={resourcesToBeAdded}
      setResourcesToBeAdded={setResourcesToBeAdded}
      selectedActivity={selectedActivity}
      setSelectedActivity={setSelectedActivity}
      selectedActivityIndex={selectedActivityIndex}
      setSelectedActivityIndex={setSelectedActivityIndex}
    >
      <Root className="content">
        <div className="container">
          <div className="left">{renderPage()}</div>
        </div>
      </Root>
      <EditorSlideFooter
        selectedSection={props.section}
        sections={props.sections}
        onSaveAll={props.onSaveAll}
        subPageSelected={selectedSubPage}
        onAddSelection={() => {
          const schedule = props.sections.find(
            (s) => s.type === "Schedule"
          ) as Schedule;
          if (!schedule) return;
          const newSchedule = _.cloneDeep(schedule);
          newSchedule.modules = newSchedule.modules.map((module) => {
            if (module.id === props.section.id) {
              module.activities = module.activities.map((activity) => {
                if (activity.id === selectedActivity?.id) {
                  resourcesToBeAdded.forEach((resourceToBeAdded) =>
                    activity?.body?.content?.push({
                      type: "file-button",
                      attrs: {
                        id: resourceToBeAdded.id,
                        name: resourceToBeAdded.title,
                        type: resourceToBeAdded.type,
                        url: resourceToBeAdded.url,
                      },
                    })
                  );
                }
                return activity;
              });
            }

            return module;
          });

          props.onChange(newSchedule);

          // We had to implement this setTimeout because there are cases in which the props.onChange takes a few
          // more miliseconds to update the object in memory.
          // So we are trying to set the children components with an state that is not fully updated.
          // With the timeout we wait until the object is fully updated before setting any states.
          setTimeout(() => {
            setResourcesToBeAdded([]);
            setSelectedSubPage(undefined);
            setSelectedActivity(undefined);
            setSelectedActivityIndex(undefined);
          }, 200);
        }}
        onCancelAndClose={props.onCancelAndClose}
        onClearSelection={() => {
          setResourcesToBeAdded([]);
        }}
        onDeleteComponent={props.onDeleteComponent}
        disableButtons={props.disableButtons}
      />
    </EditorSlideContextProvider>
  );
};

export default EditorSlide;
