import React from "react";
import ModuleEditor from "./schedule/ModuleEditor";
import { Module } from "../../../model/Syllabus";
import SectionEditorProps from "./SectionEditorProps";

const WeekEditor: React.FC<SectionEditorProps> = (props) => {
  return (
    <ModuleEditor
      module={props.section as Module}
      onChange={(module) => props.onChange(module)}
      onSubPageSelected={props.onSubPageSelected}
      needsRegistration={props.needsRegistration}
      error={props.error}
    />
  );
};

export default WeekEditor;
