import React from "react";
import { Module } from "../../../model/Syllabus";

interface ModuleSectionProps {
  section: Module;
}

const ModuleSection: React.FC<ModuleSectionProps> = (props) => {
  return (
    <div>
      <h1>Work in Progress</h1>
    </div>
  );
};

export default ModuleSection;
