import { SectionValidator } from "./SectionValidator";
import { Instructors } from "model/Syllabus";
import Joi from "joi";
import { errorMapper } from "../../../helpers/errorMapper";
import _ from "lodash";

export default class InstructorsValidator extends SectionValidator {
  validate(section: Instructors) {
    const schema = Joi.object({
      instructors: Joi.array()
        .required()
        .min(1)
        .message("You have to add at least 1 instructor")
        .items(
          Joi.object({
            name: Joi.string().min(3).messages({
              "string.min": "Name must have at least {#limit} characters",
              "string.empty": "Name Is not allowed to be empty",
            }),
          })
        ),
    }).options({ allowUnknown: true, abortEarly: false });

    const sectionErrorItem = {
      section: section,
      errors: {
        ...errorMapper(section, schema),
      },
    };

    return !_.isEmpty(sectionErrorItem.errors) ? sectionErrorItem : undefined;
  }
}
