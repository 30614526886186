import React from "react";
import { Blank } from "grommet-icons";
import { ChildrenIconProps } from "./FileTypeIcon";
import { IconColors } from "../../theme/GlobalTheme";

interface EyeOnProps {}

const EyeOn: React.FC<EyeOnProps & ChildrenIconProps> = (props) => {
  return (
    <Blank {...props} color="plain">
      <svg version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 24 24">
        <path
          fill={
            props.disabled
              ? IconColors["icon-disabled"]
              : IconColors["icon-dark-blue"]
          }
          d="M12,20c-6.9,0-10.7-7.2-10.9-7.5c-0.2-0.3-0.2-0.6,0-0.9C1.3,11.2,5.1,4,12,4c6.9,0,10.7,7.2,10.9,7.5
	c0.1,0.3,0.1,0.6,0,0.9C22.7,12.8,18.9,20,12,20z M3.2,12c0.9,1.5,4.1,6,8.8,6c4.8,0,7.9-4.5,8.8-6c-0.9-1.5-4.1-6-8.8-6
	C7.2,6,4.1,10.5,3.2,12z"
        />
        <path
          fill={
            props.disabled
              ? IconColors["icon-disabled"]
              : IconColors["icon-dark-blue"]
          }
          d="M12,16c-2.2,0-4-1.8-4-4c0-2.2,1.8-4,4-4c2.2,0,4,1.8,4,4C16,14.2,14.2,16,12,16z M12,10c-1.1,0-2,0.9-2,2
	c0,1.1,0.9,2,2,2c1.1,0,2-0.9,2-2C14,10.9,13.1,10,12,10z"
        />
      </svg>
    </Blank>
  );
};

export default EyeOn;
