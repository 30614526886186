import { SectionValidator } from "./SectionValidator";
import { CourseObjectives, isRichTextEditorEmpty } from "model/Syllabus";
import _ from "lodash";

export default class CourseObjectivesValidator extends SectionValidator {
  validate(section: CourseObjectives) {
    const extraErrors: Record<string, string> = {};
    if (isRichTextEditorEmpty(section.objectives)) {
      extraErrors.objectives = "'Course Objectives' cannot be empty";
    }

    const sectionErrorItem = {
      section: section,
      errors: extraErrors,
    };

    return !_.isEmpty(sectionErrorItem.errors) ? sectionErrorItem : undefined;
  }
}
