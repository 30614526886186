import {
  BlackboardActions,
  BlackboardActionType,
} from "../types/BlackboardTypes";

export const setBlackboardTokens = (
  accessToken: string,
  refreshToken: string,
  userId: string | undefined
): BlackboardActions => ({
  type: BlackboardActionType.SetTokensBlackboard,
  payload: {
    accessToken,
    refreshToken,
    userId,
  },
});
