import React from "react";
import { Button } from "grommet";
import styled from "styled-components";

interface CloseIconButtonProps {
  onClick: () => void;
  a11yTitle: string;
  styles?: React.CSSProperties;
  icon: React.ReactElement;
  mediaQuery?: string;
  autoFocus?: boolean;
  tabIndex?: number;
}

const StyledButton = styled(Button)`
  ${(p: CloseIconButtonProps) => p.mediaQuery}
`;

const IconButton: React.FC<CloseIconButtonProps> = (props) => {
  return (
    <StyledButton
      autoFocus={props.autoFocus}
      mediaQuery={props.mediaQuery}
      style={props.styles}
      icon={props.icon}
      a11yTitle={props.a11yTitle}
      onClick={props.onClick}
      tabIndex={props.tabIndex ?? 1}
      focusIndicator={true}
    />
  );
};

export default IconButton;
