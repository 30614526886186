import React from "react";
import { Policy } from "../../../model/Syllabus";
import useDelayedState from "../../../hooks/useDelayedState";
import RichTextEditorWrapper from "../../ui-kit/RichTextEditor/RichTextEditorWrapper";

interface PolicyEditorProps {
  policy: Policy;
  onChange: (policy: Policy) => void;
  index: number;
}

const PolicyEditor: React.FC<PolicyEditorProps> = (props) => {
  const [policy, setPolicy] = useDelayedState(
    { ...props.policy },
    props.onChange
  );

  return (
    <RichTextEditorWrapper
      id="policy-text-editor"
      key={"policy-" + props.index}
      value={policy.body}
      onChange={(content) => {
        setPolicy({
          ...policy,
          body: content,
        });
      }}
      placeholder="Describe course policies. "
    />
  );
};

export default PolicyEditor;
