import React from "react";
import { FileResource } from "../../../model/Resource";
import { JSONContent } from "@tiptap/react";
import RichTextEditor from "./Editor/RichTextEditor";

interface RichTextEditorProps {
  id: string;
  value: JSONContent;
  uploadButton?: {
    visible: boolean;
    onUploadFile: (file: FileResource) => void;
    disabled?: boolean;
  };
  searchMaterialsButton?: {
    visible: boolean;
    onClick: () => void;
  };
  onChange: (content: JSONContent) => void;
  placeholder?: string;
  editorRef?: any;
  error?: string;
  label?: string;
  sublabel?: string;
  required?: boolean;
}

const RichTextEditorWrapper: React.FC<RichTextEditorProps> = (props) => {
  return (
    <>
      <RichTextEditor
        uploadButton={props.uploadButton}
        searchMaterialsButton={props.searchMaterialsButton}
        id={props.id}
        value={props.value}
        onChange={props.onChange}
        placeholder={props.placeholder}
        ref={props.editorRef}
        error={props.error}
        label={props.label}
        sublabel={props.sublabel}
        required={props.required}
      />
    </>
  );
};

export default RichTextEditorWrapper;
