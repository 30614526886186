import { Activity, FileResource, Resource } from "./Resource";
import _ from "lodash";
import ScheduleModuleFactory from "./ScheduleModuleFactory";
import { FontFamily } from "../components/viewer/customization/FontConfiguration";
import { JSONContent } from "@tiptap/react";

export type SectionType =
  | "Instructors"
  | "CourseDescription"
  | "CourseObjectives"
  | "CourseTitle"
  | "CreditHours"
  | "ClassTimes"
  | "OfficeHours"
  | "RequiredTexts"
  | "GradingPolicy"
  | "CustomSection"
  | "Schedule"
  | "Policies"
  | "Skills"
  | "Prerequisites"
  | "Location"
  | "Materials"
  | "Module";

export const allSectionTypes: SectionType[] = [
  "Instructors",
  "CourseDescription",
  "CourseObjectives",
  "CourseTitle",
  "CreditHours",
  "ClassTimes",
  "OfficeHours",
  //"RequiredTexts",
  "GradingPolicy",
  "CustomSection",
  "Schedule",
  //"Policies",
  "Skills",
  "Prerequisites",
  "Location",
  "Materials",
];

export function getSectionTypeLabel(sectionType: SectionType): string {
  switch (sectionType) {
    case "Instructors":
      return "Instructors";
    case "CourseDescription":
      return "Course Description";
    case "CourseObjectives":
      return "Course Objectives";
    case "CourseTitle":
      return "Course Title";
    case "CreditHours":
      return "Credit Hours";
    case "ClassTimes":
      return "Class Times";
    case "OfficeHours":
      return "Office Hours";
    /*case "RequiredTexts":
      return "Required Texts";*/
    case "GradingPolicy":
      return "Grading Policy";
    case "CustomSection":
      return "Custom Section";
    case "Schedule":
      return "Schedule";
    /*case "Policies":
      return "Policies";*/
    case "Skills":
      return "Skills";
    case "Prerequisites":
      return "Prerequisites";
    case "Location":
      return "Location";
    case "Materials":
      return "Materials";
    case "Module":
      return "Week";
    default:
      return "Section not found";
  }
}

export function isUniqueSection(sectionType: SectionType): boolean {
  const uniqueTypes = [
    "CourseDescription",
    "CourseObjectives",
    "CourseTitle",
    "CreditHours",
    "ClassTimes",
    "Location",
    "OfficeHours",
    //"RequiredTexts",
    "Schedule",
    //"Policies",
    "GradingPolicy",
    "Materials",
    "Instructors",
    "Skills",
    "Prerequisites",
  ];
  return uniqueTypes.includes(sectionType);
}

export interface BaseSection {
  id: string;
  attachments?: Resource[];
  type: string;
}

//TODO: include prerequisites section.  Prerequisites could be strings or links to other syllabi/outside URLs

export interface Instructor {
  name: string;
  title?: string;
  email: string;
  phone: string;
  office?: Office;
  //TODO: add department - for now make this a string
}

export interface Instructors extends BaseSection {
  type: "Instructors";
  instructors: Instructor[];
}

export interface Office {
  room: string;
  building: string;
  mailingAddress: string;
}

export interface CourseDescription extends BaseSection {
  type: "CourseDescription";
  description: JSONContent;
}

export interface CourseObjectives extends BaseSection {
  type: "CourseObjectives";
  objectives: JSONContent;
}

export interface CourseTitle extends BaseSection {
  type: "CourseTitle";
  title: string;
  courseNumber: string;
  semester: string;
  school: string;
}

export interface CreditHours extends BaseSection {
  type: "CreditHours";
  creditHours: string;
}

export interface ClassTimes extends BaseSection {
  type: "ClassTimes";
  classTimes: string;
}

export interface OfficeHours extends BaseSection {
  type: "OfficeHours";
  officeHours: string;
}

export type LocationKind = "Online" | "In Person" | "Blend Ed" | "";

export interface Location extends BaseSection {
  type: "Location";
  kind: LocationKind;
  description?: JSONContent;
}

export interface Assignment {
  title: string;
  dueDate: Date;
  resource?: FileResource;
  description?: string;
}

export interface PendingAssignment {
  moduleId: string;
  assignment: Assignment;
}

//TODO: consider condensing this with GoogleBookResource
export interface Reading {
  title: string;
  url: string;
  //TODO: should authors be an array of author objects?
  author?: string;
  //TODO: should publishYear be a date?
  publishYear?: number;
  publishLocation?: string;
  publisher?: string;
  isbn10?: number;
  isbn13?: number;
  edition?: string;
  notes?: string;
  source?: string;
}

export interface Policies extends BaseSection {
  type: "Policies";
  policies: Policy[];
}

//TODO: GradingPolicy should probably inherit from this
export interface Policy {
  type: "Policy";
  title: string;
  body: JSONContent;
}

export interface Objective extends BaseSection {
  type: "Objective";
  title: string;
  body: JSONContent;
}

export interface Prerequisites extends BaseSection {
  type: "Prerequisites";
  prerequisites: Prerequisite[];
}

export interface Prerequisite extends BaseSection {
  type: "Prerequisite";
  body: JSONContent;
}

export interface Materials extends BaseSection {
  type: "Materials";
  materials: Material[];
}

export interface Material extends BaseSection {
  body: string;
}

export interface Skills extends BaseSection {
  type: "Skills";
  skills: Skill[];
}

export interface Skill extends BaseSection {
  body: string;
}

export interface RequiredTexts extends BaseSection {
  type: "RequiredTexts";
  readings: Reading[];
}

export interface GradingPolicy extends BaseSection {
  type: "GradingPolicy";
  title: string;
  description: string;
  items: GradingItem[];
}

export interface GradingItem extends BaseSection {
  title: string;
  weight: number;
}

export interface CustomSection extends BaseSection {
  type: "CustomSection";
  title?: string;
  body: JSONContent;
}

export interface Session {
  startDate: Date;
  endDate?: Date;
  webex?: string;
  //TODO: add location. Optionally includes a physical location as a string, url for joining a virtual session, type for in person, virtual, or both
  title: string;
  teams?: string;
}

export interface Schedule extends BaseSection {
  type: "Schedule";
  modules: Module[];
  start_at: Date;
  end_at: Date;
  /**
   * We must delete this variable before saving the syllabus into the DB.
   */
  pendingModules?: number;
}

export interface Module extends BaseSection {
  type: "Module";
  title: string;
  objectives: JSONContent;
  activities: Activity[];
  start_at: Date;
  end_at: Date;
}

export type Section =
  | Instructors
  | CourseDescription
  | CourseObjectives
  | CourseTitle
  | CreditHours
  | ClassTimes
  | OfficeHours
  | RequiredTexts
  | GradingPolicy
  | CustomSection
  | Schedule
  | Policies
  | Skills
  | Prerequisites
  | Location
  | Materials
  | Module;

export type SyllabusComponent = Section | Module;

// Todo: add all component types
// export type SyllabusComponent = Section | Session | Policy;

export function getSupportedIntegrations(section: Section) {
  switch (section.type) {
    case "CourseDescription":
      return ["youtube", "googlebooks", "googlecustomsearchresult"];
    case "CustomSection":
      return ["youtube", "googlebooks", "googlecustomsearchresult", "files"];
    case "Instructors":
      return ["youtube", "files"];
    /*case "Policies":
      return ["policies"];
    case "RequiredTexts":
      return ["googlebooks"];*/
    default:
      return [];
  }
}

export type ThemeName =
  | "Red"
  | "Ugly"
  | "Documentation"
  | "Steffen"
  | "Plain Jane"
  | "Columbus State";

export interface Layout {
  theme?: ThemeName;
}

export interface Syllabus {
  parentId?: string; // Id of the syllabus template
  firebaseId?: string;
  password?: string;
  shared: boolean;
  sharedWithTeacher: boolean;
  createdAt?: Date;
  createdBy?: string;
  layout?: Layout;
  canvasId?: string; //optional ID in canvas if course has been exported
  version: number;
  sections: Section[];
  blackboardId?: string; //optional ID in Blackboard if course has been exported
  customization: SyllabusCustomization;
  students?: string[];
  enrollmentCode?: string;
}

export interface SyllabusCustomization {
  fontFamily: FontFamily;
  primaryColor: string; // Hex code
}

export const addSectionToSyllabus = (syllabus: Syllabus, section: Section) => {
  syllabus.sections = [...syllabus.sections, section];
};

export const updateSectionOnSyllabus = (
  syllabus: Syllabus,
  section: Section
) => {
  syllabus.sections = syllabus.sections.map((s) => {
    if (s.id !== section.id) {
      return s;
    }

    return section;
  });
};

export const deleteComponentOnSyllabus = (
  component: SyllabusComponent,
  syllabus: Syllabus
) => {
  const o = _.cloneDeep(syllabus);

  if (component.type === "Module") {
    o.sections = o.sections
      .map((section) => {
        if (section.type === "Schedule") {
          section.modules = section.modules.filter(
            (m) => m.id !== component.id
          );
        }
        return section;
      })
      .filter((s) => !(s.type === "Schedule" && s.modules.length === 0));
  } else {
    o.sections = o.sections.filter((section) => section.id !== component.id);
  }

  return o;
};

export const resetModule = (module: Module) => {
  const newModule = _.cloneDeep(module);
  newModule.objectives = {};
  newModule.activities = [];
  newModule.attachments = [];

  return newModule;
};

export const addMoreModulesToSchedule = (
  section: Schedule,
  start: Date,
  quantity: number
): Section => {
  const newSection = _.cloneDeep(section);
  const modulesFactory = new ScheduleModuleFactory();
  const newModules = modulesFactory.createFromQuantity(
    start,
    quantity,
    section.modules.length
  );

  newSection.modules = [...newSection.modules, ...newModules];
  delete newSection.pendingModules;

  return newSection;
};

export const isRichTextEditorEmpty = (content: JSONContent): boolean => {
  return (
    _.isEmpty(content) ||
    (content.content?.length === 1 &&
      content.content[0].type === "paragraph" &&
      content.content[0].content === undefined)
  );
};
