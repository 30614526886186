import React, { useEffect, useState } from "react";
import styled from "styled-components";

import BlendedLogo from "../../assets/images/blended.png";
import ObjectivesEditor from "./ObjectivesEditor/ObjectivesEditor";
import CongratulationsViewer from "./CongratulationsViewer/CongratulationsViewer";
import BackgroundOneWeekPlanImage from "../../assets/images/background-one-week-plan-min.png";
import ActivitiesEditor from "./ActivitiesEditor/ActivitiesEditor";
import SignUp from "./SignUp/SignUp";
import { useAppDispatch, useAppSelector } from "../../redux/configureStore";
import {
  deleteTempSyllabus,
  saveActivities,
  saveCourseTitle,
  saveInstructor,
  saveObjectives,
} from "../../redux/one-week-plan/actions/OneWeekPlanActions";
import SyllabusRepository from "../../repositories/SyllabusRepository";
import { useHistory } from "react-router-dom";
import { Syllabus } from "../../model/Syllabus";
import { useOneWeekPlanActivities } from "../../hooks/useOneWeekPlanActivities";
import { useQuery } from "../../hooks/useQuery";
import CourseInfoEditor from "./CourseInfoEditor/CourseInfoEditor";
import { saveSchool } from "redux/syllabus_templates/actions/SyllabusTemplateActions";

interface OneWeekPlanProps {}

const StyledOneWeekPlan = styled.div`
  // PLACEHOLDER UNTIL MERI's DESIGN IS FINISHED
  background: url(${BackgroundOneWeekPlanImage}) fixed;
  background-size: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .logo {
    width: 188px;
    height: 70px;
    box-shadow: 0 6px 10px rgba(54, 16, 18, 0.25);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;

    & img {
      width: 122px;
    }
  }

  & .content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    margin-top: 74px;
    margin-bottom: 80px;
    background: white;
    max-width: 900px;
    min-width: 700px;
    min-height: 800px;
    box-shadow: 0 6px 10px rgba(54, 16, 18, 0.25);
    border-radius: 10px;
    padding: 21px 42px;

    & .bottom {
      text-align: center;
    }
  }
`;

const OneWeekPlan: React.FC<OneWeekPlanProps> = (props) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const query = useQuery();
  const currentStep = query.get("step") || "1";
  const [step, setStep] = useState<number>(parseInt(currentStep));
  const { objectives, instructor, courseTitle, school, tempSyllabus } =
    useAppSelector((state) => state.oneWeekPlan);
  const activities = useOneWeekPlanActivities();

  useEffect(() => {
    window.analytics.track(`One Week Plan Step ${currentStep}`);
    setStep(parseInt(currentStep));
  }, [currentStep]);

  const StepList = {
    "step-1": (
      <CourseInfoEditor
        title={courseTitle}
        instructor={instructor}
        school={school}
        onClickContinue={(newTitle, newInstructor, newSchool) => {
          dispatch(saveCourseTitle(newTitle));
          dispatch(saveInstructor(newInstructor));
          console.log(`School updated to ${newSchool}`);
          dispatch(saveSchool(newSchool));
          history.push("/one-week-plan?step=2");
        }}
      />
    ),
    "step-2": (
      <ObjectivesEditor
        objectives={objectives}
        onChange={(newObjectives) => {
          dispatch(saveObjectives(newObjectives));
        }}
        onClickContinue={() => {
          history.push("/one-week-plan?step=3");
        }}
      />
    ),
    "step-3": (
      <ActivitiesEditor
        onChange={(newActivities) => {
          dispatch(saveActivities(newActivities));
        }}
        onClickContinue={() => {
          history.push("/one-week-plan?step=4");
        }}
        activities={activities}
      />
    ),
    "step-4": (
      <CongratulationsViewer
        onClickContinue={() => {
          history.push("/one-week-plan?step=5");
        }}
      />
    ),
    "step-5": (
      <SignUp
        onNewLogin={async (userId) => {
          const newSyllabus = {
            ...tempSyllabus,
            createdBy: userId,
          } as Syllabus;
          window.analytics.track(`Successful signup`, { id: userId });
          const syllabusRepository = new SyllabusRepository();
          const syllabusId = await syllabusRepository.create(newSyllabus!);
          dispatch(deleteTempSyllabus());
          dispatch(saveObjectives(""));
          dispatch(saveActivities([]));

          history.replace("/edit/" + syllabusId);
        }}
      />
    ),
  };

  return (
    <StyledOneWeekPlan>
      <div className="logo">
        <img src={BlendedLogo} alt="BlendEd Logo" />
      </div>
      <div className="content">
        {/*@ts-ignore*/}
        {StepList[`step-${step}`]}
      </div>
    </StyledOneWeekPlan>
  );
};

export default OneWeekPlan;
