import React from "react";
import { ClassTimes } from "../../../model/Syllabus";
import styled from "styled-components";
import Paragraph from "../../viewer/customization/Paragraph";
import H4 from "../../viewer/customization/H4";

interface ClassTimesSectionProps {
  section: ClassTimes;
}

const StyledParagraph = styled(Paragraph)`
  color: #030b1d;
`;

const ClassTimesSection: React.FC<ClassTimesSectionProps> = (props) => {
  const section = props.section;
  return (
    <>
      <H4 a11yTitle={"Class Times Section"}>Class Times</H4>
      <StyledParagraph>{section.classTimes}</StyledParagraph>
    </>
  );
};

export default ClassTimesSection;
