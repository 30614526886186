import React from "react";
import { Blank, IconProps } from "grommet-icons";
import { ChildrenIconProps } from "./FileTypeIcon";

const Alert: React.FC<IconProps & ChildrenIconProps> = (props) => {
  return (
    <Blank viewBox="0 0 128 128" {...props} color="plain">
      <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
      >
        <path
          fill="currentColor"
          d="M19.2,22H4.8c-0.7,0-1.4-0.2-2-0.6C1.8,20.9,1.2,20,0.9,19c-0.3-1-0.1-2.1,0.4-3L8.6,3.8
	C8.9,3.2,9.4,2.8,10,2.4c1.9-1.1,4.4-0.5,5.5,1.4L22.7,16c0.4,0.6,0.6,1.3,0.6,2C23.2,20.2,21.4,22,19.2,22z M12,3.9
	c-0.3,0-0.7,0.1-1,0.3c-0.3,0.2-0.5,0.4-0.7,0.7c0,0,0,0,0,0L3.1,17c-0.3,0.5-0.3,1-0.2,1.5s0.5,1,0.9,1.2c0.3,0.2,0.7,0.3,1,0.3
	h14.4c1.1,0,2-0.9,2-2c0-0.4-0.1-0.7-0.3-1L13.7,4.8C13.3,4.2,12.7,3.9,12,3.9z M9.4,4.3L9.4,4.3L9.4,4.3z"
        />
        <path
          fill="currentColor"
          d="M12,15c-0.6,0-1-0.4-1-1V8c0-0.6,0.4-1,1-1s1,0.4,1,1v6C13,14.6,12.6,15,12,15z"
        />
        <circle fill="currentColor" cx="12" cy="17" r="1" />
      </svg>
    </Blank>
  );
};

export default Alert;
