import React from "react";
import { CourseTitle } from "../../../model/Syllabus";
import SectionEditorProps from "./SectionEditorProps";
import useDelayedState from "../../../hooks/useDelayedState";
import { addYears } from "date-fns";
import { Box } from "grommet";
import TextInput from "components/ui-kit/TextInput/TextInput";
import Select from "components/ui-kit/Select/Select";

const semestersList = [
  { key: "", label: "None" },
  {
    key: "Spring",
    label: "Spring",
  },
  { key: "Summer", label: "Summer" },
  { key: "Fall", label: "Fall" },
  { key: "Winter", label: "Winter" },
];
const yearsList = [
  new Date().getFullYear().toString(),
  addYears(new Date(), 1).getFullYear().toString(),
  addYears(new Date(), 2).getFullYear().toString(),
];
const yearListOptions = [
  { key: "", label: "None" },
  { key: yearsList[0], label: yearsList[0] },
  { key: yearsList[1], label: yearsList[1] },
  { key: yearsList[2], label: yearsList[2] },
];

const CourseTitleEditor: React.FC<SectionEditorProps> = ({
  section,
  onChange,
  index,
  error,
}) => {
  const [courseTitle, setCourseTitle] = useDelayedState<CourseTitle>(
    { ...(section as CourseTitle) },
    onChange
  );

  const [semester, year] = courseTitle?.semester?.split(" ") || ["", ""];

  const onChangeSchool = (school: string) => {
    setCourseTitle({
      ...courseTitle,
      school: school,
    });
  };

  const onChangeTitle = (title: string) => {
    setCourseTitle({
      ...courseTitle,
      title: title,
    });
  };

  const onChangeCourseNumber = (courseNumber: string) => {
    setCourseTitle({
      ...courseTitle,
      courseNumber: courseNumber,
    });
  };

  const onChangeSemester = (s: string) => {
    const [, year] = courseTitle.semester?.split(" ") || ["", ""];
    setCourseTitle({
      ...courseTitle,
      semester: `${s} ${year}`,
    });
  };

  const onChangeYear = (y: string) => {
    const [semester] = courseTitle.semester?.split(" ") || ["", ""];
    setCourseTitle({
      ...courseTitle,
      semester: `${semester} ${y}`,
    });
  };

  return (
    <>
      <Box margin="small" a11yTitle="Edit Course Title">
        <TextInput
          required={true}
          label="Course Name"
          placeholder="Course Name"
          value={courseTitle.title}
          onChange={(event: any) => {
            if (event.target.value !== undefined) {
              onChangeTitle(event.target.value);
            }
          }}
          a11yTitle="Course Name"
          error={error?.errors.title}
        />
      </Box>
      <Box margin="small">
        <TextInput
          required={true}
          label="Course Number"
          placeholder="Course Number"
          a11yTitle="Course Number"
          value={courseTitle.courseNumber}
          onChange={(event: any) => {
            if (event.target.value !== undefined) {
              onChangeCourseNumber(event.target.value);
            }
          }}
          error={error?.errors.courseNumber}
        />
      </Box>
      <Box margin="small">
        <TextInput
          label="School"
          placeholder="What school is this being taught at? (Optional)"
          a11yTitle="School (Optional)"
          value={courseTitle.school}
          onChange={(event: any) => {
            if (event.target.value !== undefined) {
              onChangeSchool(event.target.value);
            }
          }}
        />
      </Box>
      <Box
        pad={{ vertical: "xxsmall", horizontal: "small" }}
        gap="medium"
        direction="row"
      >
        <Box>
          <Select
            label="Year"
            a11yTitle="Year"
            value={{
              key: year,
            }}
            onChange={(option) => {
              onChangeYear(option.key);
            }}
            options={yearListOptions}
            error={error?.errors.year}
          />
        </Box>
        <Box>
          <Select
            label="Semester"
            a11yTitle="Semester"
            value={{
              key: semester,
            }}
            onChange={(option) => {
              onChangeSemester(option.key);
            }}
            options={semestersList}
            error={error?.errors.semester}
          />
        </Box>
      </Box>
    </>
  );
};

export default CourseTitleEditor;
