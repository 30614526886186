import { School } from "../model/Syllabus";

const institutions: School[] = [
  {
    type: "School",
    name: "Alabama A & M University",
  },
  {
    type: "School",
    name: "University of Alabama at Birmingham",
  },
  {
    type: "School",
    name: "Amridge University",
  },
  {
    type: "School",
    name: "University of Alabama in Huntsville",
  },
  {
    type: "School",
    name: "Alabama State University",
  },
  {
    type: "School",
    name: "University of Alabama System Office",
  },
  {
    type: "School",
    name: "The University of Alabama",
  },
  {
    type: "School",
    name: "Central Alabama Community College",
  },
  {
    type: "School",
    name: "Athens State University",
  },
  {
    type: "School",
    name: "Auburn University at Montgomery",
  },
  {
    type: "School",
    name: "Auburn University",
  },
  {
    type: "School",
    name: "Birmingham Southern College",
  },
  {
    type: "School",
    name: "Chattahoochee Valley Community College",
  },
  {
    type: "School",
    name: "Concordia College Alabama",
  },
  {
    type: "School",
    name: "South University-Montgomery",
  },
  {
    type: "School",
    name: "Enterprise State Community College",
  },
  {
    type: "School",
    name: "James H Faulkner State Community College",
  },
  {
    type: "School",
    name: "Faulkner University",
  },
  {
    type: "School",
    name: "Gadsden State Community College",
  },
  {
    type: "School",
    name: "New Beginning College of Cosmetology",
  },
  {
    type: "School",
    name: "George C Wallace State Community College-Dothan",
  },
  {
    type: "School",
    name: "George C Wallace State Community College-Hanceville",
  },
  {
    type: "School",
    name: "George C Wallace State Community College-Selma",
  },
  {
    type: "School",
    name: "Herzing University-Birmingham",
  },
  {
    type: "School",
    name: "Huntingdon College",
  },
  {
    type: "School",
    name: "Heritage Christian University",
  },
  {
    type: "School",
    name: "J F Drake State Community and Technical College",
  },
  {
    type: "School",
    name: "J F Ingram State Technical College",
  },
  {
    type: "School",
    name: "Jacksonville State University",
  },
  {
    type: "School",
    name: "Jefferson Davis Community College",
  },
  {
    type: "School",
    name: "Jefferson State Community College",
  },
  {
    type: "School",
    name: "John C Calhoun State Community College",
  },
  {
    type: "School",
    name: "Judson College",
  },
  {
    type: "School",
    name: "Lawson State Community College-Birmingham Campus",
  },
  {
    type: "School",
    name: "University of West Alabama",
  },
  {
    type: "School",
    name: "Lurleen B Wallace Community College",
  },
  {
    type: "School",
    name: "Marion Military Institute",
  },
  {
    type: "School",
    name: "Miles College",
  },
  {
    type: "School",
    name: "University of Mobile",
  },
  {
    type: "School",
    name: "University of Montevallo",
  },
  {
    type: "School",
    name: "Northwest-Shoals Community College",
  },
  {
    type: "School",
    name: "University of North Alabama",
  },
  {
    type: "School",
    name: "Northeast Alabama Community College",
  },
  {
    type: "School",
    name: "Oakwood University",
  },
  {
    type: "School",
    name: "Alabama Southern Community College",
  },
  {
    type: "School",
    name: "Prince Institute-Southeast",
  },
  {
    type: "School",
    name: "Reid State Technical College",
  },
  {
    type: "School",
    name: "Bishop State Community College",
  },
  {
    type: "School",
    name: "Samford University",
  },
  {
    type: "School",
    name: "Selma University",
  },
  {
    type: "School",
    name: "Shelton State Community College",
  },
  {
    type: "School",
    name: "Snead State Community College",
  },
  {
    type: "School",
    name: "University of South Alabama",
  },
  {
    type: "School",
    name: "Spring Hill College",
  },
  {
    type: "School",
    name: "Southeastern Bible College",
  },
  {
    type: "School",
    name: "Stillman College",
  },
  {
    type: "School",
    name: "Talladega College",
  },
  {
    type: "School",
    name: "H Councill Trenholm State Technical College",
  },
  {
    type: "School",
    name: "Troy University",
  },
  {
    type: "School",
    name: "Tuskegee University",
  },
  {
    type: "School",
    name: "United States Sports Academy",
  },
  {
    type: "School",
    name: "Bevill State Community College",
  },
  {
    type: "School",
    name: "University of Alaska Anchorage",
  },
  {
    type: "School",
    name: "Alaska Bible College",
  },
  {
    type: "School",
    name: "University of Alaska Fairbanks",
  },
  {
    type: "School",
    name: "University of Alaska Southeast",
  },
  {
    type: "School",
    name: "Alaska Pacific University",
  },
  {
    type: "School",
    name: "AVTEC-Alaska's Institute of Technology",
  },
  {
    type: "School",
    name: "Charter College-Anchorage",
  },
  {
    type: "School",
    name: "Prince William Sound Community College",
  },
  {
    type: "School",
    name: "Alaska Career College",
  },
  {
    type: "School",
    name: "University of Alaska System of Higher Education",
  },
  {
    type: "School",
    name: "Everest College-Phoenix",
  },
  {
    type: "School",
    name: "Collins College",
  },
  {
    type: "School",
    name: "Empire Beauty School-Paradise Valley",
  },
  {
    type: "School",
    name: "Empire Beauty School-Tucson",
  },
  {
    type: "School",
    name: "Thunderbird School of Global Management",
  },
  {
    type: "School",
    name: "American Indian College of the Assemblies of God Inc",
  },
  {
    type: "School",
    name: "American Institute of Technology",
  },
  {
    type: "School",
    name: "Carrington College-Phoenix",
  },
  {
    type: "School",
    name: "Carrington College-Mesa",
  },
  {
    type: "School",
    name: "Carrington College-Tucson",
  },
  {
    type: "School",
    name: "College America-Flagstaff",
  },
  {
    type: "School",
    name: "Arizona Academy of Beauty-East",
  },
  {
    type: "School",
    name: "Arizona Automotive Institute",
  },
  {
    type: "School",
    name: "Brookline College-Phoenix",
  },
  {
    type: "School",
    name: "Arizona State University-Tempe",
  },
  {
    type: "School",
    name: "Arizona Western College",
  },
  {
    type: "School",
    name: "University of Arizona",
  },
  {
    type: "School",
    name: "Southwest University of Visual Arts-Tucson",
  },
  {
    type: "School",
    name: "Brillare Hairdressing Academy",
  },
  {
    type: "School",
    name: "Central Arizona College",
  },
  {
    type: "School",
    name: "Brown Mackie College-Tucson",
  },
  {
    type: "School",
    name: "Charles of Italy Beauty College",
  },
  {
    type: "School",
    name: "Cochise College",
  },
  {
    type: "School",
    name: "Empire Beauty School-Flagstaff",
  },
  {
    type: "School",
    name: "Empire Beauty School-Chandler",
  },
  {
    type: "School",
    name: "Cortiva Institute-Tucson",
  },
  {
    type: "School",
    name: "DeVry University-Arizona",
  },
  {
    type: "School",
    name: "Avalon School of Cosmetology-Mesa",
  },
  {
    type: "School",
    name: "Eastern Arizona College",
  },
  {
    type: "School",
    name: "Embry-Riddle Aeronautical University-Prescott",
  },
  {
    type: "School",
    name: "Frank Lloyd Wright School of Architecture",
  },
  {
    type: "School",
    name: "Glendale Community College",
  },
  {
    type: "School",
    name: "Grand Canyon University",
  },
  {
    type: "School",
    name: "Anthem College-Phoenix",
  },
  {
    type: "School",
    name: "International Academy of Hair Design",
  },
  {
    type: "School",
    name: "Kaplan College-Phoenix",
  },
  {
    type: "School",
    name: "Maricopa Community College System Office",
  },
  {
    type: "School",
    name: "GateWay Community College",
  },
  {
    type: "School",
    name: "Mesa Community College",
  },
  {
    type: "School",
    name: "ITT Technical Institute-Tucson",
  },
  {
    type: "School",
    name: "ITT Technical Institute-Tempe",
  },
  {
    type: "School",
    name: "Mohave Community College",
  },
  {
    type: "School",
    name: "Universal Technical Institute of Arizona Inc-Motorcycle Mechanics Institute Division",
  },
  {
    type: "School",
    name: "Dine College",
  },
  {
    type: "School",
    name: "Northern Arizona University",
  },
  {
    type: "School",
    name: "Northland Pioneer College",
  },
  {
    type: "School",
    name: "Ottawa University-Phoenix",
  },
  {
    type: "School",
    name: "Phoenix College",
  },
  {
    type: "School",
    name: "Cortiva Institute-Scottsdale",
  },
  {
    type: "School",
    name: "University of Phoenix-Phoenix Campus",
  },
  {
    type: "School",
    name: "Pima Community College",
  },
  {
    type: "School",
    name: "Pima Medical Institute-Tucson",
  },
  {
    type: "School",
    name: "Pima Medical Institute-Albuquerque",
  },
  {
    type: "School",
    name: "Prescott College",
  },
  {
    type: "School",
    name: "Refrigeration School Inc",
  },
  {
    type: "School",
    name: "Rio Salado College",
  },
  {
    type: "School",
    name: "Roberto-Venn School of Luthiery",
  },
  {
    type: "School",
    name: "Hair Academy of Safford",
  },
  {
    type: "School",
    name: "Scottsdale Community College",
  },
  {
    type: "School",
    name: "South Mountain Community College",
  },
  {
    type: "School",
    name: "Arizona Christian University",
  },
  {
    type: "School",
    name: "Tucson College",
  },
  {
    type: "School",
    name: "Universal Technical Institute of Arizona Inc",
  },
  {
    type: "School",
    name: "Western International University",
  },
  {
    type: "School",
    name: "Empire Beauty School-NW Phoenix",
  },
  {
    type: "School",
    name: "Yavapai College",
  },
  {
    type: "School",
    name: "University of Arkansas at Little Rock",
  },
  {
    type: "School",
    name: "University of Arkansas for Medical Sciences",
  },
  {
    type: "School",
    name: "ABC Beauty College Inc",
  },
  {
    type: "School",
    name: "Arkansas Baptist College",
  },
  {
    type: "School",
    name: "Arkansas Beauty School",
  },
  {
    type: "School",
    name: "Arkansas Beauty College",
  },
  {
    type: "School",
    name: "Lyon College",
  },
  {
    type: "School",
    name: "Arkansas College of Barbering and Hair Design",
  },
  {
    type: "School",
    name: "Arthur's Beauty College Inc-Fort Smith",
  },
  {
    type: "School",
    name: "University of Arkansas",
  },
  {
    type: "School",
    name: "University of Arkansas at Pine Bluff",
  },
  {
    type: "School",
    name: "Arkansas State University-Beebe",
  },
  {
    type: "School",
    name: "Arkansas State University-Main Campus",
  },
  {
    type: "School",
    name: "Arkansas Tech University",
  },
  {
    type: "School",
    name: "University of Arkansas at Monticello",
  },
  {
    type: "School",
    name: "Arthur's Beauty College Inc-Jacksonville",
  },
  {
    type: "School",
    name: "Baptist Health Schools-Little Rock",
  },
  {
    type: "School",
    name: "Black River Technical College",
  },
  {
    type: "School",
    name: "University of Central Arkansas",
  },
  {
    type: "School",
    name: "Central Baptist College",
  },
  {
    type: "School",
    name: "Cossatot Community College of the University of Arkansas",
  },
  {
    type: "School",
    name: "Crowley's Ridge College",
  },
  {
    type: "School",
    name: "Crowley's Ridge Technical Institute",
  },
  {
    type: "School",
    name: "East Arkansas Community College",
  },
  {
    type: "School",
    name: "Eastern College of Health Vocations-Little Rock",
  },
  {
    type: "School",
    name: "Imagine-Paul Mitchell Partner School",
  },
  {
    type: "School",
    name: "National Park Community College",
  },
  {
    type: "School",
    name: "University of Arkansas Community College-Batesville",
  },
  {
    type: "School",
    name: "Harding University",
  },
  {
    type: "School",
    name: "Henderson State University",
  },
  {
    type: "School",
    name: "Hendrix College",
  },
  {
    type: "School",
    name: "Hot Springs Beauty College",
  },
  {
    type: "School",
    name: "Jefferson Regional Medical Center School of Nursing",
  },
  {
    type: "School",
    name: "John Brown University",
  },
  {
    type: "School",
    name: "Lees School of Cosmetology",
  },
  {
    type: "School",
    name: "Career Academy of Hair Design",
  },
  {
    type: "School",
    name: "Marsha Kay Beauty College",
  },
  {
    type: "School",
    name: "Academy of Salon and Spa",
  },
  {
    type: "School",
    name: "Mid-South Community College",
  },
  {
    type: "School",
    name: "Arkansas Northeastern College",
  },
  {
    type: "School",
    name: "Margaret's Hair Academy Inc",
  },
  {
    type: "School",
    name: "New Tyler Barber College Inc",
  },
  {
    type: "School",
    name: "North Arkansas College",
  },
  {
    type: "School",
    name: "Northwest Technical Institute",
  },
  {
    type: "School",
    name: "Ouachita Baptist University",
  },
  {
    type: "School",
    name: "College of the Ouachitas",
  },
  {
    type: "School",
    name: "Ozarka College",
  },
  {
    type: "School",
    name: "University of the Ozarks",
  },
  {
    type: "School",
    name: "University of Arkansas Community College-Morrilton",
  },
  {
    type: "School",
    name: "Philander Smith College",
  },
  {
    type: "School",
    name: "Phillips Community College of the University of Arkansas",
  },
  {
    type: "School",
    name: "Southeast Arkansas College",
  },
  {
    type: "School",
    name: "Professional Cosmetology Education Center",
  },
  {
    type: "School",
    name: "Pulaski Technical College",
  },
  {
    type: "School",
    name: "University of Arkansas Community College-Hope",
  },
  {
    type: "School",
    name: "Rich Mountain Community College",
  },
  {
    type: "School",
    name: "Paul Mitchell the School-Arkansas",
  },
  {
    type: "School",
    name: "Searcy Beauty College Inc",
  },
  {
    type: "School",
    name: "Shorter College",
  },
  {
    type: "School",
    name: "Williams Baptist College",
  },
  {
    type: "School",
    name: "South Arkansas Community College",
  },
  {
    type: "School",
    name: "Southern Arkansas University Main Campus",
  },
  {
    type: "School",
    name: "Southern Arkansas University Tech",
  },
  {
    type: "School",
    name: "University of Arkansas System Office",
  },
  {
    type: "School",
    name: "Velvatex College of Beauty Culture",
  },
  {
    type: "School",
    name: "University of Arkansas-Fort Smith",
  },
  {
    type: "School",
    name: "Academy of Art University",
  },
  {
    type: "School",
    name: "ITT Technical Institute-Rancho Cordova",
  },
  {
    type: "School",
    name: "Academy of Chinese Culture and Health Sciences",
  },
  {
    type: "School",
    name: "The Academy of Radio and TV Broadcasting",
  },
  {
    type: "School",
    name: "Avalon School of Cosmetology-Alameda",
  },
  {
    type: "School",
    name: "College of Alameda",
  },
  {
    type: "School",
    name: "Allan Hancock College",
  },
  {
    type: "School",
    name: "American Academy of Dramatic Arts-Los Angeles",
  },
  {
    type: "School",
    name: "American Baptist Seminary of the West",
  },
  {
    type: "School",
    name: "American Film Institute Conservatory",
  },
  {
    type: "School",
    name: "American Beauty College",
  },
  {
    type: "School",
    name: "American Career College-Los Angeles",
  },
  {
    type: "School",
    name: "American Conservatory Theater",
  },
  {
    type: "School",
    name: "American River College",
  },
  {
    type: "School",
    name: "Everest College-Hayward",
  },
  {
    type: "School",
    name: "Antelope Valley College",
  },
  {
    type: "School",
    name: "Art Center College of Design",
  },
  {
    type: "School",
    name: "Associated Technical College-Los Angeles",
  },
  {
    type: "School",
    name: "Associated Technical College-San Diego",
  },
  {
    type: "School",
    name: "Azusa Pacific University",
  },
  {
    type: "School",
    name: "Bakersfield College",
  },
  {
    type: "School",
    name: "Barstow Community College",
  },
  {
    type: "School",
    name: "Bellus Academy-National City",
  },
  {
    type: "School",
    name: "Bethesda University of California",
  },
  {
    type: "School",
    name: "Biola University",
  },
  {
    type: "School",
    name: "Brooks Institute",
  },
  {
    type: "School",
    name: "Brownson Technical School",
  },
  {
    type: "School",
    name: "Bryan University",
  },
  {
    type: "School",
    name: "Butte College",
  },
  {
    type: "School",
    name: "Phillips Graduate Institute",
  },
  {
    type: "School",
    name: "California Institute of Integral Studies",
  },
  {
    type: "School",
    name: "Cabrillo College",
  },
  {
    type: "School",
    name: "California Baptist University",
  },
  {
    type: "School",
    name: "California College of the Arts",
  },
  {
    type: "School",
    name: "University of California-Hastings College of Law",
  },
  {
    type: "School",
    name: "California Institute of Technology",
  },
  {
    type: "School",
    name: "California Lutheran University",
  },
  {
    type: "School",
    name: "California Polytechnic State University-San Luis Obispo",
  },
  {
    type: "School",
    name: "Alliant International University",
  },
  {
    type: "School",
    name: "California State University-Bakersfield",
  },
  {
    type: "School",
    name: "California State University-Stanislaus",
  },
  {
    type: "School",
    name: "California State University-Chancellors Office",
  },
  {
    type: "School",
    name: "California State University-San Bernardino",
  },
  {
    type: "School",
    name: "California State Polytechnic University-Pomona",
  },
  {
    type: "School",
    name: "California State University-Chico",
  },
  {
    type: "School",
    name: "California State University-Dominguez Hills",
  },
  {
    type: "School",
    name: "California State University-Fresno",
  },
  {
    type: "School",
    name: "California State University-Fullerton",
  },
  {
    type: "School",
    name: "California State University-East Bay",
  },
  {
    type: "School",
    name: "California State University-Long Beach",
  },
  {
    type: "School",
    name: "California State University-Los Angeles",
  },
  {
    type: "School",
    name: "California State University-Northridge",
  },
  {
    type: "School",
    name: "California State University-Sacramento",
  },
  {
    type: "School",
    name: "University of California-Berkeley",
  },
  {
    type: "School",
    name: "University of California-Davis",
  },
  {
    type: "School",
    name: "University of California-Irvine",
  },
  {
    type: "School",
    name: "University of California-Los Angeles",
  },
  {
    type: "School",
    name: "University of California-Riverside",
  },
  {
    type: "School",
    name: "University of California-San Diego",
  },
  {
    type: "School",
    name: "University of California-San Francisco",
  },
  {
    type: "School",
    name: "University of California-Santa Barbara",
  },
  {
    type: "School",
    name: "University of California-Santa Cruz",
  },
  {
    type: "School",
    name: "Sofia University",
  },
  {
    type: "School",
    name: "California Beauty School",
  },
  {
    type: "School",
    name: "California Christian College",
  },
  {
    type: "School",
    name: "California College San Diego",
  },
  {
    type: "School",
    name: "Le Cordon Bleu College of Culinary Arts-San Francisco",
  },
  {
    type: "School",
    name: "California Hair Design Academy",
  },
  {
    type: "School",
    name: "California Institute of the Arts",
  },
  {
    type: "School",
    name: "California Maritime Academy",
  },
  {
    type: "School",
    name: "California Western School of Law",
  },
  {
    type: "School",
    name: "Canada College",
  },
  {
    type: "School",
    name: "College of the Canyons",
  },
  {
    type: "School",
    name: "Career Academy of Beauty",
  },
  {
    type: "School",
    name: "Casa Loma College-Van Nuys",
  },
  {
    type: "School",
    name: "CET-San Diego",
  },
  {
    type: "School",
    name: "CET-Sobrato",
  },
  {
    type: "School",
    name: "CET-El Centro",
  },
  {
    type: "School",
    name: "CET-San Bernardino",
  },
  {
    type: "School",
    name: "CET-Watsonville",
  },
  {
    type: "School",
    name: "CET-Gilroy",
  },
  {
    type: "School",
    name: "CET-Salinas",
  },
  {
    type: "School",
    name: "Cerritos College",
  },
  {
    type: "School",
    name: "Cerro Coso Community College",
  },
  {
    type: "School",
    name: "Chabot College",
  },
  {
    type: "School",
    name: "Chaffey College",
  },
  {
    type: "School",
    name: "Chapman University",
  },
  {
    type: "School",
    name: "Charles R Drew University of Medicine and Science",
  },
  {
    type: "School",
    name: "Concordia University-Irvine",
  },
  {
    type: "School",
    name: "San Diego Christian College",
  },
  {
    type: "School",
    name: "Church Divinity School of the Pacific",
  },
  {
    type: "School",
    name: "Citrus College",
  },
  {
    type: "School",
    name: "Citrus Heights Beauty College",
  },
  {
    type: "School",
    name: "City College of San Francisco",
  },
  {
    type: "School",
    name: "Claremont Graduate University",
  },
  {
    type: "School",
    name: "Claremont McKenna College",
  },
  {
    type: "School",
    name: "Milan Institute-Visalia",
  },
  {
    type: "School",
    name: "Clovis Adult Education",
  },
  {
    type: "School",
    name: "Coast Community College District Office",
  },
  {
    type: "School",
    name: "Coastline Community College",
  },
  {
    type: "School",
    name: "Cogswell College",
  },
  {
    type: "School",
    name: "Coleman University",
  },
  {
    type: "School",
    name: "COBA Academy",
  },
  {
    type: "School",
    name: "Western University of Health Sciences",
  },
  {
    type: "School",
    name: "Columbia College",
  },
  {
    type: "School",
    name: "Columbia College-Hollywood",
  },
  {
    type: "School",
    name: "El Camino College-Compton Center",
  },
  {
    type: "School",
    name: "Western Beauty Institute",
  },
  {
    type: "School",
    name: "Contra Costa Community College District Office",
  },
  {
    type: "School",
    name: "Contra Costa College",
  },
  {
    type: "School",
    name: "Cosumnes River College",
  },
  {
    type: "School",
    name: "Crafton Hills College",
  },
  {
    type: "School",
    name: "Cuesta College",
  },
  {
    type: "School",
    name: "Cuyamaca College",
  },
  {
    type: "School",
    name: "Cypress College",
  },
  {
    type: "School",
    name: "De Anza College",
  },
  {
    type: "School",
    name: "Marinello Schools of Beauty-Hemet",
  },
  {
    type: "School",
    name: "International School of Beauty Inc",
  },
  {
    type: "School",
    name: "Dell'Arte International School of Physical Theatre",
  },
  {
    type: "School",
    name: "College of the Desert",
  },
  {
    type: "School",
    name: "Design Institute of San Diego",
  },
  {
    type: "School",
    name: "DeVry University-California",
  },
  {
    type: "School",
    name: "Diablo Valley College",
  },
  {
    type: "School",
    name: "Dominican University of California",
  },
  {
    type: "School",
    name: "Dominican School of Philosophy & Theology",
  },
  {
    type: "School",
    name: "East Los Angeles College",
  },
  {
    type: "School",
    name: "Marinello Schools of Beauty-Santa Clara",
  },
  {
    type: "School",
    name: "San Diego Continuing Education",
  },
  {
    type: "School",
    name: "El Camino Community College District",
  },
  {
    type: "School",
    name: "Elegance International",
  },
  {
    type: "School",
    name: "Marinello Schools of Beauty-Burbank",
  },
  {
    type: "School",
    name: "Marinello Schools of Beauty-Moreno Valley",
  },
  {
    type: "School",
    name: "Emperor's College of Traditional Oriental Medicine",
  },
  {
    type: "School",
    name: "Empire College School of Business",
  },
  {
    type: "School",
    name: "Evergreen Valley College",
  },
  {
    type: "School",
    name: "Fashion Institute of Design & Merchandising-Los Angeles",
  },
  {
    type: "School",
    name: "Fashion Careers College",
  },
  {
    type: "School",
    name: "Fashion Institute of Design & Merchandising-San Francisco",
  },
  {
    type: "School",
    name: "Fashion Institute of Design & Merchandising-Orange County",
  },
  {
    type: "School",
    name: "Feather River Community College District",
  },
  {
    type: "School",
    name: "Federico Beauty Institute",
  },
  {
    type: "School",
    name: "Fielding Graduate University",
  },
  {
    type: "School",
    name: "Five Branches University",
  },
  {
    type: "School",
    name: "Hair California Beauty Academy",
  },
  {
    type: "School",
    name: "Foothill College",
  },
  {
    type: "School",
    name: "Franciscan School of Theology",
  },
  {
    type: "School",
    name: "Fredrick and Charles Beauty College",
  },
  {
    type: "School",
    name: "Fresno City College",
  },
  {
    type: "School",
    name: "Fresno Pacific University",
  },
  {
    type: "School",
    name: "Foothill-De Anza Community College District",
  },
  {
    type: "School",
    name: "Fuller Theological Seminary in California",
  },
  {
    type: "School",
    name: "Fullerton College",
  },
  {
    type: "School",
    name: "Galen College of Medical and Dental Assistants",
  },
  {
    type: "School",
    name: "Gavilan College",
  },
  {
    type: "School",
    name: "Gemological Institute of America-Carlsbad",
  },
  {
    type: "School",
    name: "Glendale Community College",
  },
  {
    type: "School",
    name: "Glendale Career College",
  },
  {
    type: "School",
    name: "Golden Gate University-San Francisco",
  },
  {
    type: "School",
    name: "Golden West College",
  },
  {
    type: "School",
    name: "Graduate Theological Union",
  },
  {
    type: "School",
    name: "Grossmont-Cuyamaca Community College District",
  },
  {
    type: "School",
    name: "Grossmont College",
  },
  {
    type: "School",
    name: "Salon Success Academy-San Bernardino",
  },
  {
    type: "School",
    name: "Hartnell College",
  },
  {
    type: "School",
    name: "Harvey Mudd College",
  },
  {
    type: "School",
    name: "Heald College-Rancho Cordova",
  },
  {
    type: "School",
    name: "Heald College-Fresno",
  },
  {
    type: "School",
    name: "Heald College-San Jose",
  },
  {
    type: "School",
    name: "Heald College-San Francisco",
  },
  {
    type: "School",
    name: "Heald College-Concord",
  },
  {
    type: "School",
    name: "Hilltop Beauty School",
  },
  {
    type: "School",
    name: "Holy Names University",
  },
  {
    type: "School",
    name: "Pacifica Graduate Institute",
  },
  {
    type: "School",
    name: "Humboldt State University",
  },
  {
    type: "School",
    name: "Humphreys College-Stockton and Modesto Campuses",
  },
  {
    type: "School",
    name: "Imperial Valley College",
  },
  {
    type: "School",
    name: "Institute for Business and Technology",
  },
  {
    type: "School",
    name: "LA College International",
  },
  {
    type: "School",
    name: "Interior Designers Institute",
  },
  {
    type: "School",
    name: "Toni & Guy Hairdressing Academy-Santa Monica",
  },
  {
    type: "School",
    name: "Irvine Valley College",
  },
  {
    type: "School",
    name: "ITT Technical Institute-National City",
  },
  {
    type: "School",
    name: "ITT Technical Institute-San Dimas",
  },
  {
    type: "School",
    name: "ITT Technical Institute-Orange",
  },
  {
    type: "School",
    name: "Bellus Academy-El Cajon",
  },
  {
    type: "School",
    name: "Joe Blasco Makeup Artist Training Center",
  },
  {
    type: "School",
    name: "John F Kennedy University",
  },
  {
    type: "School",
    name: "American Jewish University",
  },
  {
    type: "School",
    name: "Reedley College",
  },
  {
    type: "School",
    name: "Life Pacific College",
  },
  {
    type: "School",
    name: "The Art Institute of California-Argosy University San Diego",
  },
  {
    type: "School",
    name: "University of La Verne",
  },
  {
    type: "School",
    name: "Laguna College of Art and Design",
  },
  {
    type: "School",
    name: "Lake Tahoe Community College",
  },
  {
    type: "School",
    name: "Lancaster Beauty School",
  },
  {
    type: "School",
    name: "Laney College",
  },
  {
    type: "School",
    name: "Lassen Community College",
  },
  {
    type: "School",
    name: "Life Chiropractic College West",
  },
  {
    type: "School",
    name: "Lincoln University",
  },
  {
    type: "School",
    name: "Southern California Seminary",
  },
  {
    type: "School",
    name: "La Sierra University",
  },
  {
    type: "School",
    name: "Loma Linda University",
  },
  {
    type: "School",
    name: "Long Beach City College",
  },
  {
    type: "School",
    name: "Southern California University of Health Sciences",
  },
  {
    type: "School",
    name: "Los Angeles Community College District Office",
  },
  {
    type: "School",
    name: "Los Angeles Harbor College",
  },
  {
    type: "School",
    name: "Los Angeles Pierce College",
  },
  {
    type: "School",
    name: "Los Angeles Southwest College",
  },
  {
    type: "School",
    name: "Los Angeles Trade Technical College",
  },
  {
    type: "School",
    name: "Los Angeles Valley College",
  },
  {
    type: "School",
    name: "The Master's College and Seminary",
  },
  {
    type: "School",
    name: "Los Angeles City College",
  },
  {
    type: "School",
    name: "Los Angeles County College of Nursing and Allied Health",
  },
  {
    type: "School",
    name: "Los Angeles Mission College",
  },
  {
    type: "School",
    name: "Los Medanos College",
  },
  {
    type: "School",
    name: "Los Rios Community College District Office",
  },
  {
    type: "School",
    name: "The Art Institute of California-Argosy University San Francisco",
  },
  {
    type: "School",
    name: "Loyola Marymount University",
  },
  {
    type: "School",
    name: "Lu Ross Academy",
  },
  {
    type: "School",
    name: "Manchester Beauty College",
  },
  {
    type: "School",
    name: "Tulare Beauty College",
  },
  {
    type: "School",
    name: "Lyles Bakersfield College of Beauty",
  },
  {
    type: "School",
    name: "Lyles Fresno College of Beauty",
  },
  {
    type: "School",
    name: "Lytles Redwood Empire Beauty College Inc",
  },
  {
    type: "School",
    name: "MTI Business College Inc",
  },
  {
    type: "School",
    name: "MTI College",
  },
  {
    type: "School",
    name: "Kaplan College-Sacramento",
  },
  {
    type: "School",
    name: "Kaplan College-San Diego",
  },
  {
    type: "School",
    name: "Kaplan College-Vista",
  },
  {
    type: "School",
    name: "College of Marin",
  },
  {
    type: "School",
    name: "Marinello Schools of Beauty-Los Angeles",
  },
  {
    type: "School",
    name: "Marymount California University",
  },
  {
    type: "School",
    name: "Mendocino College",
  },
  {
    type: "School",
    name: "Menlo College",
  },
  {
    type: "School",
    name: "Merced College",
  },
  {
    type: "School",
    name: "Merritt College",
  },
  {
    type: "School",
    name: "Mills College",
  },
  {
    type: "School",
    name: "MiraCosta College",
  },
  {
    type: "School",
    name: "Marinello Schools of Beauty-San Francisco",
  },
  {
    type: "School",
    name: "Mission College",
  },
  {
    type: "School",
    name: "Modern Beauty Academy",
  },
  {
    type: "School",
    name: "Kaplan College-North Hollywood",
  },
  {
    type: "School",
    name: "Modesto Junior College",
  },
  {
    type: "School",
    name: "Moler Barber College",
  },
  {
    type: "School",
    name: "Monterey Institute of International Studies",
  },
  {
    type: "School",
    name: "Monterey Peninsula College",
  },
  {
    type: "School",
    name: "Moorpark College",
  },
  {
    type: "School",
    name: "Mt San Antonio College",
  },
  {
    type: "School",
    name: "Mount St Mary's College",
  },
  {
    type: "School",
    name: "Mt San Jacinto Community College District",
  },
  {
    type: "School",
    name: "Mueller College",
  },
  {
    type: "School",
    name: "Musicians Institute",
  },
  {
    type: "School",
    name: "Napa Valley College",
  },
  {
    type: "School",
    name: "Everest College-San Francisco",
  },
  {
    type: "School",
    name: "Everest College-Reseda",
  },
  {
    type: "School",
    name: "Everest College-LA Wilshire",
  },
  {
    type: "School",
    name: "Everest College-San Jose",
  },
  {
    type: "School",
    name: "Everest College-Gardena",
  },
  {
    type: "School",
    name: "Everest College-Alhambra",
  },
  {
    type: "School",
    name: "Everest College-San Bernardino",
  },
  {
    type: "School",
    name: "The National Hispanic University",
  },
  {
    type: "School",
    name: "National Holistic Institute",
  },
  {
    type: "School",
    name: "National University",
  },
  {
    type: "School",
    name: "Naval Postgraduate School",
  },
  {
    type: "School",
    name: "Newschool of Architecture and Design",
  },
  {
    type: "School",
    name: "Newberry School of Beauty",
  },
  {
    type: "School",
    name: "Adrian's College of Beauty Modesto",
  },
  {
    type: "School",
    name: "North Orange County Community College District",
  },
  {
    type: "School",
    name: "North-West College-Pomona",
  },
  {
    type: "School",
    name: "North-West College-West Covina",
  },
  {
    type: "School",
    name: "North-West College-Pasadena",
  },
  {
    type: "School",
    name: "Northwestern Polytechnic University",
  },
  {
    type: "School",
    name: "Notre Dame de Namur University",
  },
  {
    type: "School",
    name: "Occidental College",
  },
  {
    type: "School",
    name: "Oceanside College of Beauty",
  },
  {
    type: "School",
    name: "Ohlone College",
  },
  {
    type: "School",
    name: "Orange Coast College",
  },
  {
    type: "School",
    name: "Otis College of Art and Design",
  },
  {
    type: "School",
    name: "Oxnard College",
  },
  {
    type: "School",
    name: "Hope International University",
  },
  {
    type: "School",
    name: "Concorde Career College-San Diego",
  },
  {
    type: "School",
    name: "Palo Alto University",
  },
  {
    type: "School",
    name: "Pacific Lutheran Theological Seminary",
  },
  {
    type: "School",
    name: "Pacific Oaks College",
  },
  {
    type: "School",
    name: "Pacific School of Religion",
  },
  {
    type: "School",
    name: "Pacific States University",
  },
  {
    type: "School",
    name: "Pacific Union College",
  },
  {
    type: "School",
    name: "University of the Pacific",
  },
  {
    type: "School",
    name: "Palo Verde College",
  },
  {
    type: "School",
    name: "Palomar College",
  },
  {
    type: "School",
    name: "Paris Beauty College",
  },
  {
    type: "School",
    name: "Pasadena City College",
  },
  {
    type: "School",
    name: "Pepperdine University",
  },
  {
    type: "School",
    name: "Peralta Community College System Office",
  },
  {
    type: "School",
    name: "Pitzer College",
  },
  {
    type: "School",
    name: "Platt College-San Diego",
  },
  {
    type: "School",
    name: "Point Loma Nazarene University",
  },
  {
    type: "School",
    name: "Pomona College",
  },
  {
    type: "School",
    name: "Porterville College",
  },
  {
    type: "School",
    name: "Westwood College-South Bay",
  },
  {
    type: "School",
    name: "Professional Institute of Beauty",
  },
  {
    type: "School",
    name: "Santa Ana College",
  },
  {
    type: "School",
    name: "Pardee RAND Graduate School",
  },
  {
    type: "School",
    name: "Shasta School of Cosmetology",
  },
  {
    type: "School",
    name: "University of Redlands",
  },
  {
    type: "School",
    name: "College of the Redwoods",
  },
  {
    type: "School",
    name: "Salon Success Academy-Upland",
  },
  {
    type: "School",
    name: "Salon Success Academy-Corona",
  },
  {
    type: "School",
    name: "Rio Hondo College",
  },
  {
    type: "School",
    name: "Riverside City College",
  },
  {
    type: "School",
    name: "Argosy University-San Francisco Bay Area",
  },
  {
    type: "School",
    name: "Rosemead Beauty School",
  },
  {
    type: "School",
    name: "Dongguk University-Los Angeles",
  },
  {
    type: "School",
    name: "Sacramento City College",
  },
  {
    type: "School",
    name: "Saddleback College",
  },
  {
    type: "School",
    name: "Saint Patrick's Seminary and University",
  },
  {
    type: "School",
    name: "Samuel Merritt University",
  },
  {
    type: "School",
    name: "San Diego Community College District-District Office",
  },
  {
    type: "School",
    name: "San Diego City College",
  },
  {
    type: "School",
    name: "Golf Academy of America-Carlsbad",
  },
  {
    type: "School",
    name: "San Diego Mesa College",
  },
  {
    type: "School",
    name: "San Diego Miramar College",
  },
  {
    type: "School",
    name: "San Diego State University",
  },
  {
    type: "School",
    name: "University of San Diego",
  },
  {
    type: "School",
    name: "San Francisco Art Institute",
  },
  {
    type: "School",
    name: "San Francisco Conservatory of Music",
  },
  {
    type: "School",
    name: "San Francisco State University",
  },
  {
    type: "School",
    name: "San Francisco Theological Seminary",
  },
  {
    type: "School",
    name: "University of San Francisco",
  },
  {
    type: "School",
    name: "San Joaquin College of Law",
  },
  {
    type: "School",
    name: "San Joaquin Delta College",
  },
  {
    type: "School",
    name: "San Joaquin Valley College-Visalia",
  },
  {
    type: "School",
    name: "San Joaquin Valley College-Bakersfield",
  },
  {
    type: "School",
    name: "William Jessup University",
  },
  {
    type: "School",
    name: "San Jose-Evergreen Community College District",
  },
  {
    type: "School",
    name: "San Jose City College",
  },
  {
    type: "School",
    name: "San Jose State University",
  },
  {
    type: "School",
    name: "San Mateo County Community College District Office",
  },
  {
    type: "School",
    name: "College of San Mateo",
  },
  {
    type: "School",
    name: "Santa Barbara Business College-Bakersfield",
  },
  {
    type: "School",
    name: "Westwood College-Los Angeles",
  },
  {
    type: "School",
    name: "Santa Barbara Business College-Santa Maria",
  },
  {
    type: "School",
    name: "Santa Barbara City College",
  },
  {
    type: "School",
    name: "Santa Clara University",
  },
  {
    type: "School",
    name: "Santa Monica College",
  },
  {
    type: "School",
    name: "Santa Rosa Junior College",
  },
  {
    type: "School",
    name: "Saybrook University",
  },
  {
    type: "School",
    name: "Scripps College",
  },
  {
    type: "School",
    name: "Wyotech-Fremont",
  },
  {
    type: "School",
    name: "College of the Sequoias",
  },
  {
    type: "School",
    name: "Shasta Bible College and Graduate School",
  },
  {
    type: "School",
    name: "Shasta College",
  },
  {
    type: "School",
    name: "Sierra College",
  },
  {
    type: "School",
    name: "Sierra College of Beauty",
  },
  {
    type: "School",
    name: "Sierra Valley College of Court Reporting",
  },
  {
    type: "School",
    name: "Trinity Law School",
  },
  {
    type: "School",
    name: "Simpson University",
  },
  {
    type: "School",
    name: "College of the Siskiyous",
  },
  {
    type: "School",
    name: "Charles A Jones Career and Education Center",
  },
  {
    type: "School",
    name: "Skyline College",
  },
  {
    type: "School",
    name: "San Bernardino Valley College",
  },
  {
    type: "School",
    name: "Saint Mary's College of California",
  },
  {
    type: "School",
    name: "Solano Community College",
  },
  {
    type: "School",
    name: "Sonoma State University",
  },
  {
    type: "School",
    name: "Pinnacle College",
  },
  {
    type: "School",
    name: "South Baylo University",
  },
  {
    type: "School",
    name: "South Coast College",
  },
  {
    type: "School",
    name: "Vanguard University of Southern California",
  },
  {
    type: "School",
    name: "Concorde Career College-Garden Grove",
  },
  {
    type: "School",
    name: "Southwestern College",
  },
  {
    type: "School",
    name: "St John's Seminary",
  },
  {
    type: "School",
    name: "Starr King School for Ministry",
  },
  {
    type: "School",
    name: "State Center Community College District",
  },
  {
    type: "School",
    name: "Marshall B Ketchum University",
  },
  {
    type: "School",
    name: "Southern California Institute of Architecture",
  },
  {
    type: "School",
    name: "University of Southern California",
  },
  {
    type: "School",
    name: "Southwestern Law School",
  },
  {
    type: "School",
    name: "Taft College",
  },
  {
    type: "School",
    name: "Claremont School of Theology",
  },
  {
    type: "School",
    name: "Thomas Aquinas College",
  },
  {
    type: "School",
    name: "Marinello Schools of Beauty-Lake Forest",
  },
  {
    type: "School",
    name: "Epic Bible College",
  },
  {
    type: "School",
    name: "University of California-System Administration Central Office",
  },
  {
    type: "School",
    name: "United Education Institute-Huntington Park Campus",
  },
  {
    type: "School",
    name: "Concorde Career College-San Bernardino",
  },
  {
    type: "School",
    name: "Universal College of Beauty Inc-Los Angeles 1",
  },
  {
    type: "School",
    name: "Concorde Career College-North Hollywood",
  },
  {
    type: "School",
    name: "Ventura County Community College System Office",
  },
  {
    type: "School",
    name: "Ventura College",
  },
  {
    type: "School",
    name: "The Santa Barbara and Ventura Colleges of Law-Ventura",
  },
  {
    type: "School",
    name: "Victor Valley Beauty College Inc",
  },
  {
    type: "School",
    name: "Victor Valley College",
  },
  {
    type: "School",
    name: "Berkeley City College",
  },
  {
    type: "School",
    name: "West Valley Mission Community College District Office",
  },
  {
    type: "School",
    name: "Walden University",
  },
  {
    type: "School",
    name: "Waynes College of Beauty",
  },
  {
    type: "School",
    name: "West Hills College-Coalinga",
  },
  {
    type: "School",
    name: "West Los Angeles College",
  },
  {
    type: "School",
    name: "West Valley College",
  },
  {
    type: "School",
    name: "Carrington College California-Sacramento",
  },
  {
    type: "School",
    name: "Westminster Theological Seminary in California",
  },
  {
    type: "School",
    name: "Westmont College",
  },
  {
    type: "School",
    name: "Whittier College",
  },
  {
    type: "School",
    name: "Woodbury University",
  },
  {
    type: "School",
    name: "The Wright Institute",
  },
  {
    type: "School",
    name: "Western State College of Law at Argosy University",
  },
  {
    type: "School",
    name: "Thomas Jefferson School of Law",
  },
  {
    type: "School",
    name: "Yeshiva Ohr Elchonon Chabad West Coast Talmudical Seminary",
  },
  {
    type: "School",
    name: "Yosemite Community College District Office",
  },
  {
    type: "School",
    name: "Yuba College",
  },
  {
    type: "School",
    name: "The Salon Professional Academy-Grand Junction",
  },
  {
    type: "School",
    name: "Adams State University",
  },
  {
    type: "School",
    name: "Aims Community College",
  },
  {
    type: "School",
    name: "Arapahoe Community College",
  },
  {
    type: "School",
    name: "Bel-Rea Institute of Animal Technology",
  },
  {
    type: "School",
    name: "Everest College-Colorado Springs",
  },
  {
    type: "School",
    name: "Boulder College of Massage Therapy",
  },
  {
    type: "School",
    name: "University of Colorado Denver",
  },
  {
    type: "School",
    name: "University of Colorado Colorado Springs",
  },
  {
    type: "School",
    name: "Redstone College",
  },
  {
    type: "School",
    name: "University of Colorado Boulder",
  },
  {
    type: "School",
    name: "Colorado Christian University",
  },
  {
    type: "School",
    name: "Colorado College",
  },
  {
    type: "School",
    name: "Concorde Career College-Aurora",
  },
  {
    type: "School",
    name: "The Art Institute of Colorado",
  },
  {
    type: "School",
    name: "Colorado Mountain College",
  },
  {
    type: "School",
    name: "Colorado Northwestern Community College",
  },
  {
    type: "School",
    name: "Colorado School of Mines",
  },
  {
    type: "School",
    name: "Colorado School of Trades",
  },
  {
    type: "School",
    name: "Colorado State University-Fort Collins",
  },
  {
    type: "School",
    name: "Colorado Technical University-Colorado Springs",
  },
  {
    type: "School",
    name: "Empire Beauty School-Lakewood",
  },
  {
    type: "School",
    name: "Community College of Aurora",
  },
  {
    type: "School",
    name: "College America-Denver",
  },
  {
    type: "School",
    name: "Delta Montrose Technical College",
  },
  {
    type: "School",
    name: "Prince Institute-Rocky Mountains",
  },
  {
    type: "School",
    name: "Community College of Denver",
  },
  {
    type: "School",
    name: "Lincoln College of Technology-Denver",
  },
  {
    type: "School",
    name: "Denver Seminary",
  },
  {
    type: "School",
    name: "Westwood College-Denver North",
  },
  {
    type: "School",
    name: "University of Denver",
  },
  {
    type: "School",
    name: "Emily Griffith Technical College",
  },
  {
    type: "School",
    name: "Fort Lewis College",
  },
  {
    type: "School",
    name: "Front Range Community College",
  },
  {
    type: "School",
    name: "Glenwood Beauty Academy",
  },
  {
    type: "School",
    name: "Hair Dynamics Education Center",
  },
  {
    type: "School",
    name: "Iliff School of Theology",
  },
  {
    type: "School",
    name: "International Salon and Spa Academy",
  },
  {
    type: "School",
    name: "Lamar Community College",
  },
  {
    type: "School",
    name: "Colorado Mesa University",
  },
  {
    type: "School",
    name: "Metropolitan State University of Denver",
  },
  {
    type: "School",
    name: "Morgan Community College",
  },
  {
    type: "School",
    name: "Naropa University",
  },
  {
    type: "School",
    name: "National American University-Colorado Springs",
  },
  {
    type: "School",
    name: "National American University-Denver",
  },
  {
    type: "School",
    name: "Nazarene Bible College",
  },
  {
    type: "School",
    name: "Northeastern Junior College",
  },
  {
    type: "School",
    name: "University of Northern Colorado",
  },
  {
    type: "School",
    name: "Otero Junior College",
  },
  {
    type: "School",
    name: "Everest College-Thornton",
  },
  {
    type: "School",
    name: "Pikes Peak Community College",
  },
  {
    type: "School",
    name: "Intellitec Medical Institute",
  },
  {
    type: "School",
    name: "Pueblo Community College",
  },
  {
    type: "School",
    name: "Red Rocks Community College",
  },
  {
    type: "School",
    name: "Regis University",
  },
  {
    type: "School",
    name: "Rocky Mountain College of Art and Design",
  },
  {
    type: "School",
    name: "Montessori Education Center of the Rockies",
  },
  {
    type: "School",
    name: "Colorado State University-Pueblo",
  },
  {
    type: "School",
    name: "Pickens Technical College",
  },
  {
    type: "School",
    name: "Intellitec College-Colorado Springs",
  },
  {
    type: "School",
    name: "Intellitec College-Grand Junction",
  },
  {
    type: "School",
    name: "Trinidad State Junior College",
  },
  {
    type: "School",
    name: "University of Colorado System Office",
  },
  {
    type: "School",
    name: "United States Air Force Academy",
  },
  {
    type: "School",
    name: "Empire Beauty School-Thornton",
  },
  {
    type: "School",
    name: "Western State Colorado University",
  },
  {
    type: "School",
    name: "Yeshiva Toras Chaim Talmudical Seminary",
  },
  {
    type: "School",
    name: "Albertus Magnus College",
  },
  {
    type: "School",
    name: "Paul Mitchell the School-Danbury",
  },
  {
    type: "School",
    name: "Asnuntuck Community College",
  },
  {
    type: "School",
    name: "Bais Binyomin Academy",
  },
  {
    type: "School",
    name: "Branford Hall Career Institute-Branford Campus",
  },
  {
    type: "School",
    name: "Lincoln College of New England-Southington",
  },
  {
    type: "School",
    name: "Bridgeport Hospital School of Nursing",
  },
  {
    type: "School",
    name: "University of Bridgeport",
  },
  {
    type: "School",
    name: "Central Connecticut State University",
  },
  {
    type: "School",
    name: "Charter Oak State College",
  },
  {
    type: "School",
    name: "Connecticut Center for Massage Therapy-Newington",
  },
  {
    type: "School",
    name: "Connecticut College",
  },
  {
    type: "School",
    name: "Marinello Schools of Beauty-East Hartford",
  },
  {
    type: "School",
    name: "Porter and Chester Institute of Branford",
  },
  {
    type: "School",
    name: "University of Connecticut",
  },
  {
    type: "School",
    name: "Goodwin College",
  },
  {
    type: "School",
    name: "Lincoln Technical Institute-East Windsor",
  },
  {
    type: "School",
    name: "Eastern Connecticut State University",
  },
  {
    type: "School",
    name: "Fairfield University",
  },
  {
    type: "School",
    name: "Northhaven Academy",
  },
  {
    type: "School",
    name: "Capital Community College",
  },
  {
    type: "School",
    name: "Rensselaer Hartford Graduate Center Inc",
  },
  {
    type: "School",
    name: "Fox Institute of Business-West Hartford",
  },
  {
    type: "School",
    name: "Hartford Seminary",
  },
  {
    type: "School",
    name: "University of Hartford",
  },
  {
    type: "School",
    name: "Holy Apostles College and Seminary",
  },
  {
    type: "School",
    name: "Housatonic Community College",
  },
  {
    type: "School",
    name: "Sanford-Brown College-Farmington",
  },
  {
    type: "School",
    name: "The Leon Institute of Hair Design",
  },
  {
    type: "School",
    name: "Lyme Academy College of Fine Arts",
  },
  {
    type: "School",
    name: "Manchester Community College",
  },
  {
    type: "School",
    name: "Naugatuck Valley Community College",
  },
  {
    type: "School",
    name: "Middlesex Community College",
  },
  {
    type: "School",
    name: "Mitchell College",
  },
  {
    type: "School",
    name: "Three Rivers Community College",
  },
  {
    type: "School",
    name: "Lincoln Technical Institute-New Britain",
  },
  {
    type: "School",
    name: "New England Tractor Trailer Training School of Connecticut",
  },
  {
    type: "School",
    name: "University of New Haven",
  },
  {
    type: "School",
    name: "Ridley-Lowell Business & Technical Institute-New London",
  },
  {
    type: "School",
    name: "Norwalk Community College",
  },
  {
    type: "School",
    name: "Northwestern Connecticut Community College",
  },
  {
    type: "School",
    name: "Paier College of Art Inc",
  },
  {
    type: "School",
    name: "Marinello Schools of Beauty-Meriden",
  },
  {
    type: "School",
    name: "Porter and Chester Institute of Stratford",
  },
  {
    type: "School",
    name: "Post University",
  },
  {
    type: "School",
    name: "Quinebaug Valley Community College",
  },
  {
    type: "School",
    name: "Quinnipiac University",
  },
  {
    type: "School",
    name: "Sacred Heart University",
  },
  {
    type: "School",
    name: "University of Saint Joseph",
  },
  {
    type: "School",
    name: "Gateway Community College",
  },
  {
    type: "School",
    name: "St Vincent's College",
  },
  {
    type: "School",
    name: "Southern Connecticut State University",
  },
  {
    type: "School",
    name: "Stone Academy-Hamden",
  },
  {
    type: "School",
    name: "Tri-State College of Acupuncture",
  },
  {
    type: "School",
    name: "Trinity College",
  },
  {
    type: "School",
    name: "Tunxis Community College",
  },
  {
    type: "School",
    name: "United States Coast Guard Academy",
  },
  {
    type: "School",
    name: "Wesleyan University",
  },
  {
    type: "School",
    name: "Western Connecticut State University",
  },
  {
    type: "School",
    name: "Yale-New Haven Hospital Dietetic Internship",
  },
  {
    type: "School",
    name: "Yale University",
  },
  {
    type: "School",
    name: "Beebe Medical Center Margaret H Rollins School of Nursing",
  },
  {
    type: "School",
    name: "Dawn Career Institute Inc",
  },
  {
    type: "School",
    name: "Delaware Technical Community College-Central Office",
  },
  {
    type: "School",
    name: "Delaware Technical Community College-Owens",
  },
  {
    type: "School",
    name: "Delaware Technical Community College-Terry",
  },
  {
    type: "School",
    name: "Delaware Technical Community College-Stanton/Wilmington",
  },
  {
    type: "School",
    name: "Delaware State University",
  },
  {
    type: "School",
    name: "University of Delaware",
  },
  {
    type: "School",
    name: "Goldey-Beacom College",
  },
  {
    type: "School",
    name: "Schilling-Douglas School of Hair Design",
  },
  {
    type: "School",
    name: "Wesley College",
  },
  {
    type: "School",
    name: "Wilmington University",
  },
  {
    type: "School",
    name: "American University",
  },
  {
    type: "School",
    name: "Catholic University of America",
  },
  {
    type: "School",
    name: "Corcoran College of Art and Design",
  },
  {
    type: "School",
    name: "University of the District of Columbia",
  },
  {
    type: "School",
    name: "Pontifical Faculty of the Immaculate Conception at the Dominican House of Studies",
  },
  {
    type: "School",
    name: "Gallaudet University",
  },
  {
    type: "School",
    name: "George Washington University",
  },
  {
    type: "School",
    name: "Georgetown University",
  },
  {
    type: "School",
    name: "Howard University",
  },
  {
    type: "School",
    name: "Medtech Institute",
  },
  {
    type: "School",
    name: "Strayer University-District of Columbia",
  },
  {
    type: "School",
    name: "National Conservatory of Dramatic Arts",
  },
  {
    type: "School",
    name: "Trinity Washington University",
  },
  {
    type: "School",
    name: "Wesley Theological Seminary",
  },
  {
    type: "School",
    name: "Wyotech-Daytona",
  },
  {
    type: "School",
    name: "The Art Institute of Fort Lauderdale",
  },
  {
    type: "School",
    name: "Atlantic Technical Center",
  },
  {
    type: "School",
    name: "The Baptist College of Florida",
  },
  {
    type: "School",
    name: "Barry University",
  },
  {
    type: "School",
    name: "Gooding Institute of Nurse Anesthesia",
  },
  {
    type: "School",
    name: "Bethune-Cookman University",
  },
  {
    type: "School",
    name: "Lynn University",
  },
  {
    type: "School",
    name: "GUTI The Premier Beauty & Wellness Academy",
  },
  {
    type: "School",
    name: "Bradford-Union Technical Center",
  },
  {
    type: "School",
    name: "Eastern Florida State College",
  },
  {
    type: "School",
    name: "Broward College",
  },
  {
    type: "School",
    name: "Fortis College-Winter Park",
  },
  {
    type: "School",
    name: "Carlos Albizu University-Miami",
  },
  {
    type: "School",
    name: "College of Central Florida",
  },
  {
    type: "School",
    name: "Johnson University Florida",
  },
  {
    type: "School",
    name: "University of Central Florida",
  },
  {
    type: "School",
    name: "Charlotte Technical Center",
  },
  {
    type: "School",
    name: "Chipola College",
  },
  {
    type: "School",
    name: "Clearwater Christian College",
  },
  {
    type: "School",
    name: "Lorenzo Walker Institute of Technology",
  },
  {
    type: "School",
    name: "Daytona State College",
  },
  {
    type: "School",
    name: "South University-West Palm Beach",
  },
  {
    type: "School",
    name: "Eckerd College",
  },
  {
    type: "School",
    name: "Edison State College",
  },
  {
    type: "School",
    name: "Edward Waters College",
  },
  {
    type: "School",
    name: "Embry-Riddle Aeronautical University-Daytona Beach",
  },
  {
    type: "School",
    name: "Florida Agricultural and Mechanical University",
  },
  {
    type: "School",
    name: "Florida Atlantic University",
  },
  {
    type: "School",
    name: "Florida State College at Jacksonville",
  },
  {
    type: "School",
    name: "Flagler College-St Augustine",
  },
  {
    type: "School",
    name: "Florida College",
  },
  {
    type: "School",
    name: "Concorde Career Institute-Jacksonville",
  },
  {
    type: "School",
    name: "Concorde Career Institute-Miramar",
  },
  {
    type: "School",
    name: "Concorde Career Institute-Tampa",
  },
  {
    type: "School",
    name: "Adventist University of Health Sciences",
  },
  {
    type: "School",
    name: "Florida Institute of Technology",
  },
  {
    type: "School",
    name: "Florida Institute of Ultrasound Inc",
  },
  {
    type: "School",
    name: "Florida International University",
  },
  {
    type: "School",
    name: "Florida Keys Community College",
  },
  {
    type: "School",
    name: "Florida Memorial University",
  },
  {
    type: "School",
    name: "Florida Career College-Miami",
  },
  {
    type: "School",
    name: "Florida School of Massage",
  },
  {
    type: "School",
    name: "Florida Southern College",
  },
  {
    type: "School",
    name: "Florida State University",
  },
  {
    type: "School",
    name: "Florida Technical College",
  },
  {
    type: "School",
    name: "University of Southernmost Florida",
  },
  {
    type: "School",
    name: "University of Florida",
  },
  {
    type: "School",
    name: "Everest University-Pompano Beach",
  },
  {
    type: "School",
    name: "Fort Pierce Beauty Academy",
  },
  {
    type: "School",
    name: "Full Sail University",
  },
  {
    type: "School",
    name: "George Stone Technical Center",
  },
  {
    type: "School",
    name: "George T Baker Aviation School",
  },
  {
    type: "School",
    name: "Gulf Coast State College",
  },
  {
    type: "School",
    name: "Key College",
  },
  {
    type: "School",
    name: "Hillsborough Community College",
  },
  {
    type: "School",
    name: "Hobe Sound Bible College",
  },
  {
    type: "School",
    name: "Hollywood Institute of Beauty Careers",
  },
  {
    type: "School",
    name: "Cortiva Institute-Florida",
  },
  {
    type: "School",
    name: "Indian River State College",
  },
  {
    type: "School",
    name: "International Academy of Design and Technology-Tampa",
  },
  {
    type: "School",
    name: "International Academy",
  },
  {
    type: "School",
    name: "AI Miami International University of Art and Design",
  },
  {
    type: "School",
    name: "ITT Technical Institute-Tampa",
  },
  {
    type: "School",
    name: "Jacksonville University",
  },
  {
    type: "School",
    name: "Jones College-Jacksonville",
  },
  {
    type: "School",
    name: "Keiser University-Ft Lauderdale",
  },
  {
    type: "School",
    name: "Heritage Institute-Ft Myers",
  },
  {
    type: "School",
    name: "La Belle Beauty School",
  },
  {
    type: "School",
    name: "Florida Gateway College",
  },
  {
    type: "School",
    name: "Lake Technical Center",
  },
  {
    type: "School",
    name: "Lake-Sumter State College",
  },
  {
    type: "School",
    name: "Fort Myers Institute of Technology",
  },
  {
    type: "School",
    name: "Lively Technical Center",
  },
  {
    type: "School",
    name: "Lindsey Hopkins Technical Education Center",
  },
  {
    type: "School",
    name: "Loraines Academy Inc",
  },
  {
    type: "School",
    name: "Luther Rice University & Seminary",
  },
  {
    type: "School",
    name: "State College of Florida-Manatee-Sarasota",
  },
  {
    type: "School",
    name: "Manatee Technical Institute",
  },
  {
    type: "School",
    name: "Manhattan Hairstyling Academy",
  },
  {
    type: "School",
    name: "Traviss Career Center",
  },
  {
    type: "School",
    name: "Trinity International University-Florida",
  },
  {
    type: "School",
    name: "Miami Lakes Educational Center",
  },
  {
    type: "School",
    name: "Miami Dade College",
  },
  {
    type: "School",
    name: "University of Miami",
  },
  {
    type: "School",
    name: "Mid Florida Tech",
  },
  {
    type: "School",
    name: "Beauty Schools of America-Miami",
  },
  {
    type: "School",
    name: "Remington College-Tampa Campus",
  },
  {
    type: "School",
    name: "Everest Institute-North Miami",
  },
  {
    type: "School",
    name: "Everest Institute-Hialeah",
  },
  {
    type: "School",
    name: "Academy of Healing Arts Massage & Facial Skin Care",
  },
  {
    type: "School",
    name: "Lincoln College of Technology-West Palm Beach",
  },
  {
    type: "School",
    name: "Fortis College-Tampa",
  },
  {
    type: "School",
    name: "Paul Mitchell the School-Jacksonville",
  },
  {
    type: "School",
    name: "North Florida Community College",
  },
  {
    type: "School",
    name: "University of North Florida",
  },
  {
    type: "School",
    name: "Northwood University-Florida",
  },
  {
    type: "School",
    name: "Nova Southeastern University",
  },
  {
    type: "School",
    name: "Northwest Florida State College",
  },
  {
    type: "School",
    name: "Orange Park International School of Beauty",
  },
  {
    type: "School",
    name: "Everest University-North Orlando",
  },
  {
    type: "School",
    name: "Orlando Tech",
  },
  {
    type: "School",
    name: "Palm Beach Atlantic University",
  },
  {
    type: "School",
    name: "Palm Beach State College",
  },
  {
    type: "School",
    name: "Pasco-Hernando Community College",
  },
  {
    type: "School",
    name: "Pensacola State College",
  },
  {
    type: "School",
    name: "Pinellas Technical Education Center-Clearwater",
  },
  {
    type: "School",
    name: "Polk State College",
  },
  {
    type: "School",
    name: "Radford M Locklin Technical Center",
  },
  {
    type: "School",
    name: "Saint Vincent de Paul Regional Seminary",
  },
  {
    type: "School",
    name: "Ridge Career Center",
  },
  {
    type: "School",
    name: "Ringling College of Art and Design",
  },
  {
    type: "School",
    name: "Toni & Guy Hairdressing Academy-Jacksonville",
  },
  {
    type: "School",
    name: "Robert Morgan Educational Center",
  },
  {
    type: "School",
    name: "Rollins College",
  },
  {
    type: "School",
    name: "First Coast Technical College",
  },
  {
    type: "School",
    name: "Saint Leo University",
  },
  {
    type: "School",
    name: "St Petersburg College",
  },
  {
    type: "School",
    name: "Pinellas Technical Education Center-St Petersburg",
  },
  {
    type: "School",
    name: "Santa Fe College",
  },
  {
    type: "School",
    name: "Sarasota County Technical Institute",
  },
  {
    type: "School",
    name: "Argosy University-Sarasota",
  },
  {
    type: "School",
    name: "Seminole State College of Florida",
  },
  {
    type: "School",
    name: "Sheridan Technical Center",
  },
  {
    type: "School",
    name: "Saint John Vianney College Seminary",
  },
  {
    type: "School",
    name: "Saint Johns River State College",
  },
  {
    type: "School",
    name: "South Florida State College",
  },
  {
    type: "School",
    name: "University of South Florida-Main Campus",
  },
  {
    type: "School",
    name: "St Thomas University",
  },
  {
    type: "School",
    name: "Stenotype Institute of Jacksonville Inc-Jacksonville",
  },
  {
    type: "School",
    name: "Stetson University",
  },
  {
    type: "School",
    name: "Southeastern University",
  },
  {
    type: "School",
    name: "Benes International School of Beauty",
  },
  {
    type: "School",
    name: "Suwannee-Hamilton Technical Center",
  },
  {
    type: "School",
    name: "Tallahassee Community College",
  },
  {
    type: "School",
    name: "Talmudic College of Florida",
  },
  {
    type: "School",
    name: "Everest University-Tampa",
  },
  {
    type: "School",
    name: "Everest University-Largo",
  },
  {
    type: "School",
    name: "The University of Tampa",
  },
  {
    type: "School",
    name: "Taylor Technical Institute",
  },
  {
    type: "School",
    name: "Tom P Haney Technical Center",
  },
  {
    type: "School",
    name: "Trinity Baptist College",
  },
  {
    type: "School",
    name: "Trinity College of Florida",
  },
  {
    type: "School",
    name: "Fashion Focus Hair Academy",
  },
  {
    type: "School",
    name: "Valencia College",
  },
  {
    type: "School",
    name: "Warner University",
  },
  {
    type: "School",
    name: "Washington-Holmes Technical Center",
  },
  {
    type: "School",
    name: "Webber International University",
  },
  {
    type: "School",
    name: "Rasmussen College-Florida",
  },
  {
    type: "School",
    name: "The University of West Florida",
  },
  {
    type: "School",
    name: "Westside Tech",
  },
  {
    type: "School",
    name: "William T McFatter Technical Center",
  },
  {
    type: "School",
    name: "Winter Park Tech",
  },
  {
    type: "School",
    name: "Withlacoochee Technical Institute",
  },
  {
    type: "School",
    name: "Abraham Baldwin Agricultural College",
  },
  {
    type: "School",
    name: "Agnes Scott College",
  },
  {
    type: "School",
    name: "Interactive College of Technology-Chamblee",
  },
  {
    type: "School",
    name: "Interactive College of Technology-Morrow",
  },
  {
    type: "School",
    name: "Albany Technical College",
  },
  {
    type: "School",
    name: "Darton State College",
  },
  {
    type: "School",
    name: "Albany State University",
  },
  {
    type: "School",
    name: "Andrew College",
  },
  {
    type: "School",
    name: "Armstrong Atlantic State University",
  },
  {
    type: "School",
    name: "The Art Institute of Atlanta",
  },
  {
    type: "School",
    name: "Georgia Career Institute",
  },
  {
    type: "School",
    name: "Atlanta Technical College",
  },
  {
    type: "School",
    name: "Point University",
  },
  {
    type: "School",
    name: "Atlanta Metropolitan State College",
  },
  {
    type: "School",
    name: "Atlanta's John Marshall Law School",
  },
  {
    type: "School",
    name: "Atlanta School of Massage",
  },
  {
    type: "School",
    name: "Clark Atlanta University",
  },
  {
    type: "School",
    name: "Augusta Technical College",
  },
  {
    type: "School",
    name: "Augusta State University",
  },
  {
    type: "School",
    name: "Bainbridge State College",
  },
  {
    type: "School",
    name: "Bauder College",
  },
  {
    type: "School",
    name: "Beauty College of America",
  },
  {
    type: "School",
    name: "Berry College",
  },
  {
    type: "School",
    name: "Beulah Heights University",
  },
  {
    type: "School",
    name: "Brenau University",
  },
  {
    type: "School",
    name: "Brewton-Parker College",
  },
  {
    type: "School",
    name: "Brown College of Court Reporting",
  },
  {
    type: "School",
    name: "College of Coastal Georgia",
  },
  {
    type: "School",
    name: "West Georgia Technical College",
  },
  {
    type: "School",
    name: "Carver Bible College",
  },
  {
    type: "School",
    name: "Clayton  State University",
  },
  {
    type: "School",
    name: "Columbia Theological Seminary",
  },
  {
    type: "School",
    name: "Columbus Technical College",
  },
  {
    type: "School",
    name: "Columbus State University",
  },
  {
    type: "School",
    name: "Georgia Northwestern Technical College",
  },
  {
    type: "School",
    name: "Covenant College",
  },
  {
    type: "School",
    name: "Dalton State College",
  },
  {
    type: "School",
    name: "Pro Way Hair School",
  },
  {
    type: "School",
    name: "DeVry University-Georgia",
  },
  {
    type: "School",
    name: "South University-Savannah",
  },
  {
    type: "School",
    name: "East Georgia State College",
  },
  {
    type: "School",
    name: "Emmanuel College",
  },
  {
    type: "School",
    name: "Emory University",
  },
  {
    type: "School",
    name: "Georgia Highlands College",
  },
  {
    type: "School",
    name: "Fort Valley State University",
  },
  {
    type: "School",
    name: "Grady Health System Professional Schools",
  },
  {
    type: "School",
    name: "Georgia Institute of Technology-Main Campus",
  },
  {
    type: "School",
    name: "Georgia Southwestern State University",
  },
  {
    type: "School",
    name: "Gainesville State College",
  },
  {
    type: "School",
    name: "Georgia College and State University",
  },
  {
    type: "School",
    name: "Georgia Military College",
  },
  {
    type: "School",
    name: "Georgia Southern University",
  },
  {
    type: "School",
    name: "Georgia State University",
  },
  {
    type: "School",
    name: "University of Georgia",
  },
  {
    type: "School",
    name: "Gordon State College",
  },
  {
    type: "School",
    name: "Southern Crescent Technical College",
  },
  {
    type: "School",
    name: "Gupton Jones College of Funeral Service",
  },
  {
    type: "School",
    name: "Gwinnett College-Lilburn",
  },
  {
    type: "School",
    name: "Gwinnett Technical College",
  },
  {
    type: "School",
    name: "Middle Georgia Technical College",
  },
  {
    type: "School",
    name: "Interdenominational Theological Center",
  },
  {
    type: "School",
    name: "Kennesaw State University",
  },
  {
    type: "School",
    name: "LaGrange College",
  },
  {
    type: "School",
    name: "Lanier Technical College",
  },
  {
    type: "School",
    name: "Life University",
  },
  {
    type: "School",
    name: "Central Georgia Technical College",
  },
  {
    type: "School",
    name: "Macon State College",
  },
  {
    type: "School",
    name: "Chattahoochee Technical College",
  },
  {
    type: "School",
    name: "Herzing University-Atlanta",
  },
  {
    type: "School",
    name: "Georgia Health Sciences University",
  },
  {
    type: "School",
    name: "Mercer University",
  },
  {
    type: "School",
    name: "Middle Georgia College",
  },
  {
    type: "School",
    name: "Morehouse College",
  },
  {
    type: "School",
    name: "Morehouse School of Medicine",
  },
  {
    type: "School",
    name: "Moultrie Technical College",
  },
  {
    type: "School",
    name: "University of North Georgia",
  },
  {
    type: "School",
    name: "North Georgia Technical College",
  },
  {
    type: "School",
    name: "Oglethorpe University",
  },
  {
    type: "School",
    name: "Paine College",
  },
  {
    type: "School",
    name: "Piedmont College",
  },
  {
    type: "School",
    name: "Portfolio Center",
  },
  {
    type: "School",
    name: "Reinhardt University",
  },
  {
    type: "School",
    name: "Savannah Technical College",
  },
  {
    type: "School",
    name: "Savannah College of Art and Design",
  },
  {
    type: "School",
    name: "Savannah State University",
  },
  {
    type: "School",
    name: "Shorter University",
  },
  {
    type: "School",
    name: "South Georgia College",
  },
  {
    type: "School",
    name: "South Georgia Technical College",
  },
  {
    type: "School",
    name: "The Creative Circus",
  },
  {
    type: "School",
    name: "Spelman College",
  },
  {
    type: "School",
    name: "Southern Polytechnic State University",
  },
  {
    type: "School",
    name: "Southwest Georgia Technical College",
  },
  {
    type: "School",
    name: "Thomas University",
  },
  {
    type: "School",
    name: "Toccoa Falls College",
  },
  {
    type: "School",
    name: "Truett-McConnell College",
  },
  {
    type: "School",
    name: "Wiregrass Georgia Technical College",
  },
  {
    type: "School",
    name: "Valdosta State University",
  },
  {
    type: "School",
    name: "Waycross College",
  },
  {
    type: "School",
    name: "Wesleyan College",
  },
  {
    type: "School",
    name: "University of West Georgia",
  },
  {
    type: "School",
    name: "Young Harris College",
  },
  {
    type: "School",
    name: "Heald College-Honolulu",
  },
  {
    type: "School",
    name: "Chaminade University of Honolulu",
  },
  {
    type: "School",
    name: "University of Hawaii at Hilo",
  },
  {
    type: "School",
    name: "University of Hawaii at Manoa",
  },
  {
    type: "School",
    name: "Hawaii Institute of Hair Design",
  },
  {
    type: "School",
    name: "Hawaii Pacific University",
  },
  {
    type: "School",
    name: "Honolulu Community College",
  },
  {
    type: "School",
    name: "Kapiolani Community College",
  },
  {
    type: "School",
    name: "Kauai Community College",
  },
  {
    type: "School",
    name: "Leeward Community College",
  },
  {
    type: "School",
    name: "University of Hawaii Maui College",
  },
  {
    type: "School",
    name: "Med-Assist School of Hawaii Inc",
  },
  {
    type: "School",
    name: "World Medicine Institute",
  },
  {
    type: "School",
    name: "Travel Institute of the Pacific",
  },
  {
    type: "School",
    name: "University of Hawaii System Office",
  },
  {
    type: "School",
    name: "University of Hawaii-West Oahu",
  },
  {
    type: "School",
    name: "Windward Community College",
  },
  {
    type: "School",
    name: "Carrington College-Boise",
  },
  {
    type: "School",
    name: "Boise Bible College",
  },
  {
    type: "School",
    name: "Boise State University",
  },
  {
    type: "School",
    name: "Eastern Idaho Technical College",
  },
  {
    type: "School",
    name: "Idaho State University",
  },
  {
    type: "School",
    name: "University of Idaho",
  },
  {
    type: "School",
    name: "The College of Idaho",
  },
  {
    type: "School",
    name: "Lewis-Clark State College",
  },
  {
    type: "School",
    name: "ITT Technical Institute-Boise",
  },
  {
    type: "School",
    name: "Joseph Charles Institute of Cosmetology",
  },
  {
    type: "School",
    name: "Mr Leon's School of Hair Design-Moscow",
  },
  {
    type: "School",
    name: "North Idaho College",
  },
  {
    type: "School",
    name: "Northwest Nazarene University",
  },
  {
    type: "School",
    name: "The School of Hairstyling",
  },
  {
    type: "School",
    name: "Brigham Young University-Idaho",
  },
  {
    type: "School",
    name: "College of Southern Idaho",
  },
  {
    type: "School",
    name: "Adler School of Professional Psychology",
  },
  {
    type: "School",
    name: "Alvareitas College of Cosmetology-Edwardsville",
  },
  {
    type: "School",
    name: "Alvareitas College of Cosmetology-Godfrey",
  },
  {
    type: "School",
    name: "American Academy of Art",
  },
  {
    type: "School",
    name: "School of the Art Institute of Chicago",
  },
  {
    type: "School",
    name: "The College of Office Technology",
  },
  {
    type: "School",
    name: "Augustana College",
  },
  {
    type: "School",
    name: "Aurora University",
  },
  {
    type: "School",
    name: "Concept College of Cosmetology",
  },
  {
    type: "School",
    name: "Southwestern Illinois College",
  },
  {
    type: "School",
    name: "Bethany Theological Seminary",
  },
  {
    type: "School",
    name: "Black Hawk College",
  },
  {
    type: "School",
    name: "Blackburn College",
  },
  {
    type: "School",
    name: "Blessing Rieman College of Nursing",
  },
  {
    type: "School",
    name: "Blessing Hospital School of Radiologic Technology",
  },
  {
    type: "School",
    name: "Bradley University",
  },
  {
    type: "School",
    name: "Paul Mitchell the School-Bradley",
  },
  {
    type: "School",
    name: "Cameo Beauty Academy",
  },
  {
    type: "School",
    name: "Cannella School of Hair Design-Villa Park",
  },
  {
    type: "School",
    name: "Cannella School of Hair Design-Chicago",
  },
  {
    type: "School",
    name: "Cannella School of Hair Design-Chicago",
  },
  {
    type: "School",
    name: "Cannella School of Hair Design-Elgin",
  },
  {
    type: "School",
    name: "Capri Beauty College",
  },
  {
    type: "School",
    name: "Empire Beauty School-Hanover Park",
  },
  {
    type: "School",
    name: "Capri Beauty College",
  },
  {
    type: "School",
    name: "Carl Sandburg College",
  },
  {
    type: "School",
    name: "Catholic Theological Union at Chicago",
  },
  {
    type: "School",
    name: "La James International College-East Moline",
  },
  {
    type: "School",
    name: "Midwestern University-Downers Grove",
  },
  {
    type: "School",
    name: "The Chicago School of Professional Psychology at Chicago",
  },
  {
    type: "School",
    name: "Chicago State University",
  },
  {
    type: "School",
    name: "Chicago Theological Seminary",
  },
  {
    type: "School",
    name: "University of Chicago",
  },
  {
    type: "School",
    name: "City Colleges of Chicago-Kennedy-King College",
  },
  {
    type: "School",
    name: "City Colleges of Chicago-Malcolm X College",
  },
  {
    type: "School",
    name: "City Colleges of Chicago-Olive-Harvey College",
  },
  {
    type: "School",
    name: "City Colleges of Chicago-Harry S Truman College",
  },
  {
    type: "School",
    name: "City Colleges of Chicago-Richard J Daley College",
  },
  {
    type: "School",
    name: "City Colleges of Chicago-Harold Washington College",
  },
  {
    type: "School",
    name: "City Colleges of Chicago-Wilbur Wright College",
  },
  {
    type: "School",
    name: "Columbia College-Chicago",
  },
  {
    type: "School",
    name: "Concordia University-Chicago",
  },
  {
    type: "School",
    name: "Le Cordon Bleu College of Culinary Arts-Chicago",
  },
  {
    type: "School",
    name: "Coyne College",
  },
  {
    type: "School",
    name: "City Colleges of Chicago-District Office",
  },
  {
    type: "School",
    name: "Danville Area Community College",
  },
  {
    type: "School",
    name: "Concept College of Cosmetology",
  },
  {
    type: "School",
    name: "DePaul University",
  },
  {
    type: "School",
    name: "DeVry University-Illinois",
  },
  {
    type: "School",
    name: "DeVry University-Administrative Office",
  },
  {
    type: "School",
    name: "Cosmetology & Spa Academy",
  },
  {
    type: "School",
    name: "College of DuPage",
  },
  {
    type: "School",
    name: "East-West University",
  },
  {
    type: "School",
    name: "Eastern Illinois University",
  },
  {
    type: "School",
    name: "Elgin Community College",
  },
  {
    type: "School",
    name: "Elmhurst College",
  },
  {
    type: "School",
    name: "Eureka College",
  },
  {
    type: "School",
    name: "First Institute Inc",
  },
  {
    type: "School",
    name: "Fox College",
  },
  {
    type: "School",
    name: "Garrett-Evangelical Theological Seminary",
  },
  {
    type: "School",
    name: "Gem City College",
  },
  {
    type: "School",
    name: "Governors State University",
  },
  {
    type: "School",
    name: "Graham Hospital School of Nursing",
  },
  {
    type: "School",
    name: "Greenville College",
  },
  {
    type: "School",
    name: "Hair Professionals Academy of Cosmetology",
  },
  {
    type: "School",
    name: "Hair Professionals Career College",
  },
  {
    type: "School",
    name: "Hair Professionals School of Cosmetology",
  },
  {
    type: "School",
    name: "Harrington College of Design",
  },
  {
    type: "School",
    name: "Hebrew Theological College",
  },
  {
    type: "School",
    name: "Highland Community College",
  },
  {
    type: "School",
    name: "Rosalind Franklin University of Medicine and Science",
  },
  {
    type: "School",
    name: "University of Illinois at Chicago",
  },
  {
    type: "School",
    name: "Benedictine University",
  },
  {
    type: "School",
    name: "Illinois College of Optometry",
  },
  {
    type: "School",
    name: "University of Illinois at Urbana-Champaign",
  },
  {
    type: "School",
    name: "Illinois Wesleyan University",
  },
  {
    type: "School",
    name: "Illinois Central College",
  },
  {
    type: "School",
    name: "Illinois College",
  },
  {
    type: "School",
    name: "Olney Central College",
  },
  {
    type: "School",
    name: "Illinois Institute of Technology",
  },
  {
    type: "School",
    name: "Argosy University-Chicago",
  },
  {
    type: "School",
    name: "Illinois State University",
  },
  {
    type: "School",
    name: "Illinois Valley Community College",
  },
  {
    type: "School",
    name: "Institute for Clinical Social Work",
  },
  {
    type: "School",
    name: "International Academy of Design and Technology-Chicago",
  },
  {
    type: "School",
    name: "John A Logan College",
  },
  {
    type: "School",
    name: "The John Marshall Law School",
  },
  {
    type: "School",
    name: "John Wood Community College",
  },
  {
    type: "School",
    name: "Joliet Junior College",
  },
  {
    type: "School",
    name: "Judson University",
  },
  {
    type: "School",
    name: "Kankakee Community College",
  },
  {
    type: "School",
    name: "Kaskaskia College",
  },
  {
    type: "School",
    name: "DeVry University's Keller Graduate School of Management-Illinois",
  },
  {
    type: "School",
    name: "Kendall College",
  },
  {
    type: "School",
    name: "Kishwaukee College",
  },
  {
    type: "School",
    name: "Knox College",
  },
  {
    type: "School",
    name: "Educators of Beauty College of Cosmetology-La Salle",
  },
  {
    type: "School",
    name: "College of Lake County",
  },
  {
    type: "School",
    name: "Lake Forest College",
  },
  {
    type: "School",
    name: "Lake Forest Graduate School of Management",
  },
  {
    type: "School",
    name: "Lake Land College",
  },
  {
    type: "School",
    name: "Lakeview College of Nursing",
  },
  {
    type: "School",
    name: "Lewis and Clark Community College",
  },
  {
    type: "School",
    name: "Lewis University",
  },
  {
    type: "School",
    name: "Lexington College",
  },
  {
    type: "School",
    name: "Lincoln Christian University",
  },
  {
    type: "School",
    name: "Lincoln College",
  },
  {
    type: "School",
    name: "Lincoln Land Community College",
  },
  {
    type: "School",
    name: "Lincoln College of Technology-Melrose Park",
  },
  {
    type: "School",
    name: "Loyola University Chicago",
  },
  {
    type: "School",
    name: "Lutheran School of Theology at Chicago",
  },
  {
    type: "School",
    name: "Trinity College of Nursing & Health Sciences",
  },
  {
    type: "School",
    name: "CET-Chicago",
  },
  {
    type: "School",
    name: "Mac Daniels Beauty School",
  },
  {
    type: "School",
    name: "MacCormac College",
  },
  {
    type: "School",
    name: "MacMurray College",
  },
  {
    type: "School",
    name: "McCormick Theological Seminary",
  },
  {
    type: "School",
    name: "McHenry County College",
  },
  {
    type: "School",
    name: "McKendree University",
  },
  {
    type: "School",
    name: "Meadville Lombard Theological School",
  },
  {
    type: "School",
    name: "Methodist College",
  },
  {
    type: "School",
    name: "Midstate College",
  },
  {
    type: "School",
    name: "Millikin University",
  },
  {
    type: "School",
    name: "Monmouth College",
  },
  {
    type: "School",
    name: "Moody Bible Institute",
  },
  {
    type: "School",
    name: "Moraine Valley Community College",
  },
  {
    type: "School",
    name: "Morrison Institute of Technology",
  },
  {
    type: "School",
    name: "Morton College",
  },
  {
    type: "School",
    name: "Mr John's School of Cosmetology Esthetics & Nails-Decatur",
  },
  {
    type: "School",
    name: "National Louis University",
  },
  {
    type: "School",
    name: "National University of Health Sciences",
  },
  {
    type: "School",
    name: "Niles School of Cosmetology",
  },
  {
    type: "School",
    name: "North Central College",
  },
  {
    type: "School",
    name: "North Park University",
  },
  {
    type: "School",
    name: "Northern Baptist Theological Seminary",
  },
  {
    type: "School",
    name: "Northern Illinois University",
  },
  {
    type: "School",
    name: "Northwestern College-Chicago Campus",
  },
  {
    type: "School",
    name: "Northwestern University",
  },
  {
    type: "School",
    name: "Northeastern Illinois University",
  },
  {
    type: "School",
    name: "Oakton Community College",
  },
  {
    type: "School",
    name: "Oehrlein School of Cosmetology",
  },
  {
    type: "School",
    name: "Olivet Nazarene University",
  },
  {
    type: "School",
    name: "Parkland College",
  },
  {
    type: "School",
    name: "Pivot Point Academy-Evanston",
  },
  {
    type: "School",
    name: "Prairie State College",
  },
  {
    type: "School",
    name: "Principia College",
  },
  {
    type: "School",
    name: "Professional's Choice Hair Design Academy",
  },
  {
    type: "School",
    name: "Quincy University",
  },
  {
    type: "School",
    name: "Vatterott College-Quincy",
  },
  {
    type: "School",
    name: "The Illinois Institute of Art-Chicago",
  },
  {
    type: "School",
    name: "Rend Lake College",
  },
  {
    type: "School",
    name: "Richland Community College",
  },
  {
    type: "School",
    name: "Robert Morris University Illinois",
  },
  {
    type: "School",
    name: "Rock Valley College",
  },
  {
    type: "School",
    name: "Madison Media Institute - Rockford Career College",
  },
  {
    type: "School",
    name: "Rockford University",
  },
  {
    type: "School",
    name: "Roosevelt University",
  },
  {
    type: "School",
    name: "Dominican University",
  },
  {
    type: "School",
    name: "Rush University",
  },
  {
    type: "School",
    name: "Saint Francis Medical Center College of Nursing",
  },
  {
    type: "School",
    name: "University of St Francis",
  },
  {
    type: "School",
    name: "St John's College of Nursing",
  },
  {
    type: "School",
    name: "Saint Xavier University",
  },
  {
    type: "School",
    name: "University of Illinois at Springfield",
  },
  {
    type: "School",
    name: "Sauk Valley Community College",
  },
  {
    type: "School",
    name: "Seabury-Western Theological Seminary",
  },
  {
    type: "School",
    name: "Shawnee Community College",
  },
  {
    type: "School",
    name: "Shimer College",
  },
  {
    type: "School",
    name: "Saint Augustine College",
  },
  {
    type: "School",
    name: "University of Saint Mary of the Lake",
  },
  {
    type: "School",
    name: "Southeastern Illinois College",
  },
  {
    type: "School",
    name: "National Latino Education Institute",
  },
  {
    type: "School",
    name: "Spertus College",
  },
  {
    type: "School",
    name: "Spoon River College",
  },
  {
    type: "School",
    name: "Saint Anthony College of Nursing",
  },
  {
    type: "School",
    name: "Educators of Beauty College of Cosmetology-Sterling",
  },
  {
    type: "School",
    name: "Southern Illinois University-Carbondale",
  },
  {
    type: "School",
    name: "Southern Illinois University-Edwardsville",
  },
  {
    type: "School",
    name: "Southern Illinois University-System Office",
  },
  {
    type: "School",
    name: "Taylor Business Institute",
  },
  {
    type: "School",
    name: "Telshe Yeshiva-Chicago",
  },
  {
    type: "School",
    name: "South Suburban College",
  },
  {
    type: "School",
    name: "Tri-County Beauty Academy",
  },
  {
    type: "School",
    name: "Trinity Christian College",
  },
  {
    type: "School",
    name: "Trinity International University-Illinois",
  },
  {
    type: "School",
    name: "Triton College",
  },
  {
    type: "School",
    name: "University of Spa & Cosmetology Arts",
  },
  {
    type: "School",
    name: "University of Illinois University Administration",
  },
  {
    type: "School",
    name: "VanderCook College of Music",
  },
  {
    type: "School",
    name: "Waubonsee Community College",
  },
  {
    type: "School",
    name: "Resurrection University",
  },
  {
    type: "School",
    name: "Western Illinois University",
  },
  {
    type: "School",
    name: "Wheaton College",
  },
  {
    type: "School",
    name: "Harper College",
  },
  {
    type: "School",
    name: "Ancilla College",
  },
  {
    type: "School",
    name: "Anderson University",
  },
  {
    type: "School",
    name: "Apex Academy of Hair Design Inc",
  },
  {
    type: "School",
    name: "Ball State University",
  },
  {
    type: "School",
    name: "Bethel College-Indiana",
  },
  {
    type: "School",
    name: "Butler University",
  },
  {
    type: "School",
    name: "Calumet College of Saint Joseph",
  },
  {
    type: "School",
    name: "Christian Theological Seminary",
  },
  {
    type: "School",
    name: "College of Court Reporting Inc",
  },
  {
    type: "School",
    name: "Concordia Theological Seminary",
  },
  {
    type: "School",
    name: "Tricoci University of Beauty Culture-Highland",
  },
  {
    type: "School",
    name: "DePauw University",
  },
  {
    type: "School",
    name: "Don Roberts School of Hair Design",
  },
  {
    type: "School",
    name: "Don Roberts Beauty School",
  },
  {
    type: "School",
    name: "Earlham College",
  },
  {
    type: "School",
    name: "University of Evansville",
  },
  {
    type: "School",
    name: "Franklin College",
  },
  {
    type: "School",
    name: "Good Samaritan Hospital School of Radiologic Technology",
  },
  {
    type: "School",
    name: "Goshen College",
  },
  {
    type: "School",
    name: "Grace College and Theological Seminary",
  },
  {
    type: "School",
    name: "Hair Fashions By Kaye Beauty College-Noblesville",
  },
  {
    type: "School",
    name: "Hair Fashions By Kaye Beauty College-Indianapolis",
  },
  {
    type: "School",
    name: "Hanover College",
  },
  {
    type: "School",
    name: "J Michael Harrold Beauty Academy",
  },
  {
    type: "School",
    name: "Holy Cross College",
  },
  {
    type: "School",
    name: "PJ's College of Cosmetology-Clarksville",
  },
  {
    type: "School",
    name: "Huntington University",
  },
  {
    type: "School",
    name: "Ivy Tech Community College",
  },
  {
    type: "School",
    name: "Indiana University-Purdue University-Fort Wayne",
  },
  {
    type: "School",
    name: "Indiana University-Purdue University-Indianapolis",
  },
  {
    type: "School",
    name: "Harrison College-Indianapolis",
  },
  {
    type: "School",
    name: "University of Indianapolis",
  },
  {
    type: "School",
    name: "Indiana Institute of Technology",
  },
  {
    type: "School",
    name: "University of Southern Indiana",
  },
  {
    type: "School",
    name: "Indiana State University",
  },
  {
    type: "School",
    name: "Indiana University-Kokomo",
  },
  {
    type: "School",
    name: "Indiana University-South Bend",
  },
  {
    type: "School",
    name: "Indiana University-Bloomington",
  },
  {
    type: "School",
    name: "Indiana University-Northwest",
  },
  {
    type: "School",
    name: "Indiana University-Southeast",
  },
  {
    type: "School",
    name: "Indiana University-East",
  },
  {
    type: "School",
    name: "International Business College-Fort Wayne",
  },
  {
    type: "School",
    name: "International Business College-Indianapolis",
  },
  {
    type: "School",
    name: "ITT Educational Services Inc-System Office",
  },
  {
    type: "School",
    name: "ITT Technical Institute-Nashville",
  },
  {
    type: "School",
    name: "ITT Technical Institute-Fort Wayne",
  },
  {
    type: "School",
    name: "ITT Technical Institute-Indianapolis",
  },
  {
    type: "School",
    name: "J Everett Light Career Center",
  },
  {
    type: "School",
    name: "Knox Beauty College",
  },
  {
    type: "School",
    name: "Lafayette Beauty Academy",
  },
  {
    type: "School",
    name: "Brown Mackie College-Merrillville",
  },
  {
    type: "School",
    name: "Brown Mackie College-Michigan City",
  },
  {
    type: "School",
    name: "Empire Beauty School-Indianapolis",
  },
  {
    type: "School",
    name: "Lincoln College of Technology-Indianapolis",
  },
  {
    type: "School",
    name: "Manchester University",
  },
  {
    type: "School",
    name: "Marian University",
  },
  {
    type: "School",
    name: "Indiana Wesleyan University",
  },
  {
    type: "School",
    name: "Martin University",
  },
  {
    type: "School",
    name: "Masters of Cosmetology College",
  },
  {
    type: "School",
    name: "Anabaptist Mennonite Biblical Seminary",
  },
  {
    type: "School",
    name: "Merrillville Beauty College",
  },
  {
    type: "School",
    name: "Brown Mackie College-South Bend",
  },
  {
    type: "School",
    name: "Mid-America College of Funeral Service",
  },
  {
    type: "School",
    name: "West Michigan College of Barbering and Beauty",
  },
  {
    type: "School",
    name: "University of Notre Dame",
  },
  {
    type: "School",
    name: "Oakland City University",
  },
  {
    type: "School",
    name: "PJ's College of Cosmetology-Richmond",
  },
  {
    type: "School",
    name: "Kaplan College-Indianapolis",
  },
  {
    type: "School",
    name: "Purdue University-Calumet Campus",
  },
  {
    type: "School",
    name: "Purdue University-North Central Campus",
  },
  {
    type: "School",
    name: "Ravenscroft Beauty College",
  },
  {
    type: "School",
    name: "Rogers Academy of Hair Design",
  },
  {
    type: "School",
    name: "Rose-Hulman Institute of Technology",
  },
  {
    type: "School",
    name: "Rudae's School of Beauty Culture-Kokomo",
  },
  {
    type: "School",
    name: "University of Saint Francis-Fort Wayne",
  },
  {
    type: "School",
    name: "Saint Josephs College",
  },
  {
    type: "School",
    name: "Saint Mary-of-the-Woods College",
  },
  {
    type: "School",
    name: "Saint Mary's College",
  },
  {
    type: "School",
    name: "Kaplan College-Hammond",
  },
  {
    type: "School",
    name: "Kaplan College-Merrillville",
  },
  {
    type: "School",
    name: "Saint Meinrad School of Theology",
  },
  {
    type: "School",
    name: "Saint Elizabeth School of Nursing",
  },
  {
    type: "School",
    name: "Taylor University",
  },
  {
    type: "School",
    name: "Trine University",
  },
  {
    type: "School",
    name: "Valparaiso University",
  },
  {
    type: "School",
    name: "Vincennes Beauty College",
  },
  {
    type: "School",
    name: "Vincennes University",
  },
  {
    type: "School",
    name: "Wabash College",
  },
  {
    type: "School",
    name: "Allen College",
  },
  {
    type: "School",
    name: "AIB College of Business",
  },
  {
    type: "School",
    name: "Briar Cliff University",
  },
  {
    type: "School",
    name: "Buena Vista University",
  },
  {
    type: "School",
    name: "Capri College-Dubuque",
  },
  {
    type: "School",
    name: "Capri College-Cedar Rapids",
  },
  {
    type: "School",
    name: "American College of Hairstyling-Cedar Rapids",
  },
  {
    type: "School",
    name: "Central College",
  },
  {
    type: "School",
    name: "Clarke University",
  },
  {
    type: "School",
    name: "Coe College",
  },
  {
    type: "School",
    name: "Cornell College",
  },
  {
    type: "School",
    name: "Iowa School of Beauty-Marshalltown",
  },
  {
    type: "School",
    name: "Des Moines Area Community College",
  },
  {
    type: "School",
    name: "Divine Word College",
  },
  {
    type: "School",
    name: "Dordt College",
  },
  {
    type: "School",
    name: "Drake University",
  },
  {
    type: "School",
    name: "University of Dubuque",
  },
  {
    type: "School",
    name: "Ellsworth Community College",
  },
  {
    type: "School",
    name: "Emmaus Bible College",
  },
  {
    type: "School",
    name: "Eastern Iowa Community College District",
  },
  {
    type: "School",
    name: "Faith Baptist Bible College and Theological Seminary",
  },
  {
    type: "School",
    name: "Faust Institute of Cosmetology-Storm Lake",
  },
  {
    type: "School",
    name: "Graceland University-Lamoni",
  },
  {
    type: "School",
    name: "Grand View University",
  },
  {
    type: "School",
    name: "Grinnell College",
  },
  {
    type: "School",
    name: "Kaplan University-Mason City Campus",
  },
  {
    type: "School",
    name: "Kaplan University-Cedar Rapids Campus",
  },
  {
    type: "School",
    name: "Hamilton Technical College",
  },
  {
    type: "School",
    name: "Hawkeye Community College",
  },
  {
    type: "School",
    name: "American Hair Academy",
  },
  {
    type: "School",
    name: "Indian Hills Community College",
  },
  {
    type: "School",
    name: "Iowa Central Community College",
  },
  {
    type: "School",
    name: "Iowa Lakes Community College",
  },
  {
    type: "School",
    name: "UnityPoint Health-Des Moines School of Radiologic Technology",
  },
  {
    type: "School",
    name: "American College of Hairstyling-Des Moines",
  },
  {
    type: "School",
    name: "Iowa School of Beauty-Des Moines",
  },
  {
    type: "School",
    name: "Iowa State University",
  },
  {
    type: "School",
    name: "Iowa Wesleyan College",
  },
  {
    type: "School",
    name: "Iowa Western Community College",
  },
  {
    type: "School",
    name: "University of Iowa",
  },
  {
    type: "School",
    name: "Kirkwood Community College",
  },
  {
    type: "School",
    name: "La James International College-Cedar Falls",
  },
  {
    type: "School",
    name: "La James International College-Johnston",
  },
  {
    type: "School",
    name: "La James College of Hairstyling and Cosmetology",
  },
  {
    type: "School",
    name: "La James International College-Ft Dodge",
  },
  {
    type: "School",
    name: "Loras College",
  },
  {
    type: "School",
    name: "Luther College",
  },
  {
    type: "School",
    name: "Maharishi University of Management",
  },
  {
    type: "School",
    name: "Marshalltown Community College",
  },
  {
    type: "School",
    name: "Mercy College of Health Sciences",
  },
  {
    type: "School",
    name: "Morningside College",
  },
  {
    type: "School",
    name: "Mount Mercy University",
  },
  {
    type: "School",
    name: "Ashford University",
  },
  {
    type: "School",
    name: "North Iowa Area Community College",
  },
  {
    type: "School",
    name: "University of Northern Iowa",
  },
  {
    type: "School",
    name: "Northwestern College",
  },
  {
    type: "School",
    name: "Northeast Iowa Community College-Calmar",
  },
  {
    type: "School",
    name: "Northwest Iowa Community College",
  },
  {
    type: "School",
    name: "Des Moines University-Osteopathic Medical Center",
  },
  {
    type: "School",
    name: "Iowa School of Beauty-Ottumwa",
  },
  {
    type: "School",
    name: "Palmer College of Chiropractic-Davenport",
  },
  {
    type: "School",
    name: "PCI Academy-Ames",
  },
  {
    type: "School",
    name: "Saint Ambrose University",
  },
  {
    type: "School",
    name: "St Luke's College",
  },
  {
    type: "School",
    name: "La James International College-Davenport",
  },
  {
    type: "School",
    name: "La James International College-Iowa City",
  },
  {
    type: "School",
    name: "Simpson College",
  },
  {
    type: "School",
    name: "Southeastern Community College",
  },
  {
    type: "School",
    name: "Southwestern Community College",
  },
  {
    type: "School",
    name: "Capri College-Davenport",
  },
  {
    type: "School",
    name: "Iowa School of Beauty-Sioux City",
  },
  {
    type: "School",
    name: "E Q School of Hair Design",
  },
  {
    type: "School",
    name: "Upper Iowa University",
  },
  {
    type: "School",
    name: "Waldorf College",
  },
  {
    type: "School",
    name: "Wartburg College",
  },
  {
    type: "School",
    name: "Wartburg Theological Seminary",
  },
  {
    type: "School",
    name: "College of Hair Design",
  },
  {
    type: "School",
    name: "Western Iowa Tech Community College",
  },
  {
    type: "School",
    name: "William Penn University",
  },
  {
    type: "School",
    name: "Allen County Community College",
  },
  {
    type: "School",
    name: "Baker University",
  },
  {
    type: "School",
    name: "Barton County Community College",
  },
  {
    type: "School",
    name: "Benedictine College",
  },
  {
    type: "School",
    name: "Bethany College",
  },
  {
    type: "School",
    name: "Bethel College-North Newton",
  },
  {
    type: "School",
    name: "Brown Mackie College-Kansas City",
  },
  {
    type: "School",
    name: "Brown Mackie College-Salina",
  },
  {
    type: "School",
    name: "Bryan University",
  },
  {
    type: "School",
    name: "Butler Community College",
  },
  {
    type: "School",
    name: "Central Baptist Theological Seminary",
  },
  {
    type: "School",
    name: "Central Christian College of Kansas",
  },
  {
    type: "School",
    name: "Cloud County Community College",
  },
  {
    type: "School",
    name: "Coffeyville Community College",
  },
  {
    type: "School",
    name: "Colby Community College",
  },
  {
    type: "School",
    name: "Cowley County Community College",
  },
  {
    type: "School",
    name: "Marinello Schools of Beauty-Manhattan",
  },
  {
    type: "School",
    name: "Dodge City Community College",
  },
  {
    type: "School",
    name: "Donnelly College",
  },
  {
    type: "School",
    name: "Emporia State University",
  },
  {
    type: "School",
    name: "Flint Hills Technical College",
  },
  {
    type: "School",
    name: "Fort Hays State University",
  },
  {
    type: "School",
    name: "Barclay College",
  },
  {
    type: "School",
    name: "Friends University",
  },
  {
    type: "School",
    name: "Fort Scott Community College",
  },
  {
    type: "School",
    name: "Garden City Community College",
  },
  {
    type: "School",
    name: "Haskell Indian Nations University",
  },
  {
    type: "School",
    name: "Hays Academy of Hair Design",
  },
  {
    type: "School",
    name: "Hesston College",
  },
  {
    type: "School",
    name: "Highland Community College",
  },
  {
    type: "School",
    name: "Hutchinson Community College",
  },
  {
    type: "School",
    name: "Independence Community College",
  },
  {
    type: "School",
    name: "Johnson County Community College",
  },
  {
    type: "School",
    name: "Concorde Career Colleges",
  },
  {
    type: "School",
    name: "Kansas City Kansas Community College",
  },
  {
    type: "School",
    name: "University of Kansas",
  },
  {
    type: "School",
    name: "Newman University",
  },
  {
    type: "School",
    name: "Old Town Barber College-Wichita",
  },
  {
    type: "School",
    name: "Kansas State University",
  },
  {
    type: "School",
    name: "Kansas Wesleyan University",
  },
  {
    type: "School",
    name: "Washburn Institute of Technology",
  },
  {
    type: "School",
    name: "La Baron Hairdressing Academy-Overland Park",
  },
  {
    type: "School",
    name: "Labette Community College",
  },
  {
    type: "School",
    name: "Manhattan Area Technical College",
  },
  {
    type: "School",
    name: "Manhattan Christian College",
  },
  {
    type: "School",
    name: "McPherson College",
  },
  {
    type: "School",
    name: "MidAmerica Nazarene University",
  },
  {
    type: "School",
    name: "Neosho County Community College",
  },
  {
    type: "School",
    name: "North Central Kansas Technical College",
  },
  {
    type: "School",
    name: "Northwest Kansas Technical College",
  },
  {
    type: "School",
    name: "Ottawa University-Ottawa",
  },
  {
    type: "School",
    name: "Ottawa University-Kansas City",
  },
  {
    type: "School",
    name: "Pittsburg State University",
  },
  {
    type: "School",
    name: "Pratt Community College",
  },
  {
    type: "School",
    name: "University of Saint Mary",
  },
  {
    type: "School",
    name: "Salina Area Technical College",
  },
  {
    type: "School",
    name: "Seward County Community College and Area Technical School",
  },
  {
    type: "School",
    name: "Sidneys Hair Dressing College",
  },
  {
    type: "School",
    name: "Southwestern College",
  },
  {
    type: "School",
    name: "Sterling College",
  },
  {
    type: "School",
    name: "Tabor College",
  },
  {
    type: "School",
    name: "Washburn University",
  },
  {
    type: "School",
    name: "Wichita Area Technical College",
  },
  {
    type: "School",
    name: "Wichita State University",
  },
  {
    type: "School",
    name: "Wichita Technical Institute",
  },
  {
    type: "School",
    name: "Alice Lloyd College",
  },
  {
    type: "School",
    name: "Asbury University",
  },
  {
    type: "School",
    name: "Asbury Theological Seminary",
  },
  {
    type: "School",
    name: "Ashland Community and Technical College",
  },
  {
    type: "School",
    name: "Barrett and Company School of Hair Design",
  },
  {
    type: "School",
    name: "Bellarmine University",
  },
  {
    type: "School",
    name: "Berea College",
  },
  {
    type: "School",
    name: "PJ's College of Cosmetology-Bowling Green",
  },
  {
    type: "School",
    name: "Southcentral Kentucky Community and Technical College",
  },
  {
    type: "School",
    name: "Brescia University",
  },
  {
    type: "School",
    name: "Campbellsville University",
  },
  {
    type: "School",
    name: "Bluegrass Community and Technical College",
  },
  {
    type: "School",
    name: "Centre College",
  },
  {
    type: "School",
    name: "Clear Creek Baptist Bible College",
  },
  {
    type: "School",
    name: "Paul Mitchell the School-Lexington",
  },
  {
    type: "School",
    name: "Collins School of Cosmetology",
  },
  {
    type: "School",
    name: "Galen College of Nursing-Louisville",
  },
  {
    type: "School",
    name: "University of the Cumberlands",
  },
  {
    type: "School",
    name: "Eastern Kentucky University",
  },
  {
    type: "School",
    name: "Elizabethtown Community and Technical College",
  },
  {
    type: "School",
    name: "Ezell's Cosmetology School",
  },
  {
    type: "School",
    name: "Frontier Nursing University",
  },
  {
    type: "School",
    name: "Georgetown College",
  },
  {
    type: "School",
    name: "PJ's College of Cosmetology-Glasgow",
  },
  {
    type: "School",
    name: "Hazard Community and Technical College",
  },
  {
    type: "School",
    name: "Paul Mitchell the School-Louisville",
  },
  {
    type: "School",
    name: "Henderson Community College",
  },
  {
    type: "School",
    name: "Hopkinsville Community College",
  },
  {
    type: "School",
    name: "Daymar College-Paducah Main",
  },
  {
    type: "School",
    name: "Jefferson Community and Technical College",
  },
  {
    type: "School",
    name: "Jenny Lea Academy of Cosmetology",
  },
  {
    type: "School",
    name: "The Salon Professional Academy-Lexington",
  },
  {
    type: "School",
    name: "National College-Lexington",
  },
  {
    type: "School",
    name: "Kentucky Mountain Bible College",
  },
  {
    type: "School",
    name: "Kentucky State University",
  },
  {
    type: "School",
    name: "Kentucky Wesleyan College",
  },
  {
    type: "School",
    name: "University of Kentucky",
  },
  {
    type: "School",
    name: "Kentucky Christian University",
  },
  {
    type: "School",
    name: "Lexington Theological Seminary",
  },
  {
    type: "School",
    name: "Lindsey Wilson College",
  },
  {
    type: "School",
    name: "Sullivan College of Technology and Design",
  },
  {
    type: "School",
    name: "University of Louisville",
  },
  {
    type: "School",
    name: "Louisville Presbyterian Theological Seminary",
  },
  {
    type: "School",
    name: "Madisonville Community College",
  },
  {
    type: "School",
    name: "Maysville Community and Technical College",
  },
  {
    type: "School",
    name: "Mid-Continent University",
  },
  {
    type: "School",
    name: "Midway College",
  },
  {
    type: "School",
    name: "Morehead State University",
  },
  {
    type: "School",
    name: "Murray State University",
  },
  {
    type: "School",
    name: "Gateway Community and Technical College",
  },
  {
    type: "School",
    name: "Northern Kentucky University",
  },
  {
    type: "School",
    name: "Nutek Academy of Beauty Inc",
  },
  {
    type: "School",
    name: "Daymar College-Owensboro",
  },
  {
    type: "School",
    name: "West Kentucky Community and Technical College",
  },
  {
    type: "School",
    name: "Pat Wilson's Beauty College",
  },
  {
    type: "School",
    name: "University of Pikeville",
  },
  {
    type: "School",
    name: "Big Sandy Community and Technical College",
  },
  {
    type: "School",
    name: "Brown Mackie College-Louisville",
  },
  {
    type: "School",
    name: "Empire Beauty School-Chenoweth",
  },
  {
    type: "School",
    name: "Saint Catharine College",
  },
  {
    type: "School",
    name: "Empire Beauty School-Elizabethtown",
  },
  {
    type: "School",
    name: "Empire Beauty School-Dixie",
  },
  {
    type: "School",
    name: "Empire Beauty School-Florence",
  },
  {
    type: "School",
    name: "Brown Mackie College-Northern Kentucky",
  },
  {
    type: "School",
    name: "Somerset Community College",
  },
  {
    type: "School",
    name: "Southeast Kentucky Community and Technical College",
  },
  {
    type: "School",
    name: "The Southern Baptist Theological Seminary",
  },
  {
    type: "School",
    name: "Spalding University",
  },
  {
    type: "School",
    name: "Spencerian College-Louisville",
  },
  {
    type: "School",
    name: "Sullivan University",
  },
  {
    type: "School",
    name: "Thomas More College",
  },
  {
    type: "School",
    name: "Transylvania University",
  },
  {
    type: "School",
    name: "Trend Setters' Academy of Beauty Culture-Louisville",
  },
  {
    type: "School",
    name: "Kentucky Community and Technical College System",
  },
  {
    type: "School",
    name: "Union College",
  },
  {
    type: "School",
    name: "Western Kentucky University",
  },
  {
    type: "School",
    name: "Central Louisiana Technical Community College",
  },
  {
    type: "School",
    name: "American School of Business",
  },
  {
    type: "School",
    name: "Ayers Career College",
  },
  {
    type: "School",
    name: "Baton Rouge General Medical Center-School of Nursing",
  },
  {
    type: "School",
    name: "Baton Rouge General Medical Center-Radiologic Technology",
  },
  {
    type: "School",
    name: "Baton Rouge School of Computers",
  },
  {
    type: "School",
    name: "Capital Area Technical College",
  },
  {
    type: "School",
    name: "Bossier Parish Community College",
  },
  {
    type: "School",
    name: "Cameron College",
  },
  {
    type: "School",
    name: "Centenary College of Louisiana",
  },
  {
    type: "School",
    name: "Cloyd's Beauty School 1 Inc",
  },
  {
    type: "School",
    name: "Crescent City Bartending School",
  },
  {
    type: "School",
    name: "D'Jay's School of Beauty Arts and Sciences",
  },
  {
    type: "School",
    name: "Delgado Community College",
  },
  {
    type: "School",
    name: "Delta School of Business and Technology",
  },
  {
    type: "School",
    name: "Northeast Louisiana Technical College",
  },
  {
    type: "School",
    name: "Demmons School of Beauty",
  },
  {
    type: "School",
    name: "Denham Springs Beauty School",
  },
  {
    type: "School",
    name: "Dillard University",
  },
  {
    type: "School",
    name: "Nunez Community College",
  },
  {
    type: "School",
    name: "Grambling State University",
  },
  {
    type: "School",
    name: "Guy's Shreveport Academy of Cosmetology Inc",
  },
  {
    type: "School",
    name: "ITI Technical College",
  },
  {
    type: "School",
    name: "John Jay Beauty College",
  },
  {
    type: "School",
    name: "John Jay Beauty College",
  },
  {
    type: "School",
    name: "Louisiana State University Health Sciences Center-New Orleans",
  },
  {
    type: "School",
    name: "Louisiana State University-Alexandria",
  },
  {
    type: "School",
    name: "Louisiana State University and Agricultural & Mechanical College",
  },
  {
    type: "School",
    name: "Louisiana State University-Eunice",
  },
  {
    type: "School",
    name: "Louisiana State University-Shreveport",
  },
  {
    type: "School",
    name: "Acadiana Technical College-Lafayette Campus",
  },
  {
    type: "School",
    name: "Aveda Institute-Baton Rouge",
  },
  {
    type: "School",
    name: "Aveda Institute-Lafayette",
  },
  {
    type: "School",
    name: "Louisiana Academy of Beauty",
  },
  {
    type: "School",
    name: "Louisiana College",
  },
  {
    type: "School",
    name: "Opelousas School of Cosmetology Inc",
  },
  {
    type: "School",
    name: "Louisiana State University-System Office",
  },
  {
    type: "School",
    name: "Louisiana Tech University",
  },
  {
    type: "School",
    name: "Loyola University New Orleans",
  },
  {
    type: "School",
    name: "McNeese State University",
  },
  {
    type: "School",
    name: "University of New Orleans",
  },
  {
    type: "School",
    name: "New Orleans Baptist Theological Seminary",
  },
  {
    type: "School",
    name: "Nicholls State University",
  },
  {
    type: "School",
    name: "University of Louisiana at Monroe",
  },
  {
    type: "School",
    name: "Northwest Louisiana Technical College",
  },
  {
    type: "School",
    name: "Notre Dame Seminary Graduate School of Theology",
  },
  {
    type: "School",
    name: "Northwestern State University of Louisiana",
  },
  {
    type: "School",
    name: "Our Lady of Holy Cross College",
  },
  {
    type: "School",
    name: "Our Lady of the Lake College",
  },
  {
    type: "School",
    name: "Pat Goins Benton Road Beauty School",
  },
  {
    type: "School",
    name: "Pat Goins Monroe Beauty School",
  },
  {
    type: "School",
    name: "Pat Goins Ruston Beauty School",
  },
  {
    type: "School",
    name: "Pineville Beauty School",
  },
  {
    type: "School",
    name: "Aveda Institute-Covington",
  },
  {
    type: "School",
    name: "Saint Joseph Seminary College",
  },
  {
    type: "School",
    name: "Vanguard College of Cosmetology-Slidell",
  },
  {
    type: "School",
    name: "South Louisiana Beauty College",
  },
  {
    type: "School",
    name: "L E Fletcher Technical Community College",
  },
  {
    type: "School",
    name: "Remington College-Lafayette Campus",
  },
  {
    type: "School",
    name: "Southern University-Board and System",
  },
  {
    type: "School",
    name: "SOWELA Technical Community College",
  },
  {
    type: "School",
    name: "Stevensons Academy of Hair Design",
  },
  {
    type: "School",
    name: "Southeastern Louisiana University",
  },
  {
    type: "School",
    name: "Southern University and A & M College",
  },
  {
    type: "School",
    name: "Southern University at New Orleans",
  },
  {
    type: "School",
    name: "Southern University at Shreveport",
  },
  {
    type: "School",
    name: "University of Louisiana at Lafayette",
  },
  {
    type: "School",
    name: "Northshore Technical Community College",
  },
  {
    type: "School",
    name: "Tulane University of Louisiana",
  },
  {
    type: "School",
    name: "Xavier University of Louisiana",
  },
  {
    type: "School",
    name: "South Central Louisiana Technical College-Young Memorial Campus",
  },
  {
    type: "School",
    name: "Kaplan University-Maine Campus",
  },
  {
    type: "School",
    name: "College of the Atlantic",
  },
  {
    type: "School",
    name: "Bangor Theological Seminary",
  },
  {
    type: "School",
    name: "Bates College",
  },
  {
    type: "School",
    name: "Beal College",
  },
  {
    type: "School",
    name: "Bowdoin College",
  },
  {
    type: "School",
    name: "Central Maine Medical Center College of Nursing and Health Professions",
  },
  {
    type: "School",
    name: "Central Maine Community College",
  },
  {
    type: "School",
    name: "Colby College",
  },
  {
    type: "School",
    name: "Eastern Maine Community College",
  },
  {
    type: "School",
    name: "Husson University",
  },
  {
    type: "School",
    name: "Kennebec Valley Community College",
  },
  {
    type: "School",
    name: "The Landing School",
  },
  {
    type: "School",
    name: "University of Maine at Augusta",
  },
  {
    type: "School",
    name: "University of Maine at Farmington",
  },
  {
    type: "School",
    name: "University of Maine at Fort Kent",
  },
  {
    type: "School",
    name: "University of Maine at Machias",
  },
  {
    type: "School",
    name: "University of Maine",
  },
  {
    type: "School",
    name: "University of Maine-System Central Office",
  },
  {
    type: "School",
    name: "Maine Maritime Academy",
  },
  {
    type: "School",
    name: "University of Maine at Presque Isle",
  },
  {
    type: "School",
    name: "University of New England",
  },
  {
    type: "School",
    name: "Northern Maine Community College",
  },
  {
    type: "School",
    name: "Empire Beauty School-Maine",
  },
  {
    type: "School",
    name: "Maine College of Art",
  },
  {
    type: "School",
    name: "Saint Joseph's College of Maine",
  },
  {
    type: "School",
    name: "Spa Tech Institute-Westbrook",
  },
  {
    type: "School",
    name: "Southern Maine Community College",
  },
  {
    type: "School",
    name: "University of Southern Maine",
  },
  {
    type: "School",
    name: "Thomas College",
  },
  {
    type: "School",
    name: "Unity College",
  },
  {
    type: "School",
    name: "Washington County Community College",
  },
  {
    type: "School",
    name: "Aaron's Academy of Beauty",
  },
  {
    type: "School",
    name: "Aesthetics Institute of Cosmetology",
  },
  {
    type: "School",
    name: "Allegany College of Maryland",
  },
  {
    type: "School",
    name: "Anne Arundel Community College",
  },
  {
    type: "School",
    name: "TESST College of Technology-Towson",
  },
  {
    type: "School",
    name: "Award Beauty School",
  },
  {
    type: "School",
    name: "Baltimore Studio of Hair Design",
  },
  {
    type: "School",
    name: "Baltimore City Community College",
  },
  {
    type: "School",
    name: "University of Baltimore",
  },
  {
    type: "School",
    name: "Bowie State University",
  },
  {
    type: "School",
    name: "Capitol College",
  },
  {
    type: "School",
    name: "Cecil College",
  },
  {
    type: "School",
    name: "College of Southern Maryland",
  },
  {
    type: "School",
    name: "Chesapeake College",
  },
  {
    type: "School",
    name: "Washington Adventist University",
  },
  {
    type: "School",
    name: "Coppin State University",
  },
  {
    type: "School",
    name: "Delmarva Beauty Academy",
  },
  {
    type: "School",
    name: "North American Trade Schools",
  },
  {
    type: "School",
    name: "Frederick Community College",
  },
  {
    type: "School",
    name: "Frostburg State University",
  },
  {
    type: "School",
    name: "Garrett College",
  },
  {
    type: "School",
    name: "Goucher College",
  },
  {
    type: "School",
    name: "Kaplan University-Hagerstown Campus",
  },
  {
    type: "School",
    name: "Hagerstown Community College",
  },
  {
    type: "School",
    name: "Harford Community College",
  },
  {
    type: "School",
    name: "Hood College",
  },
  {
    type: "School",
    name: "Howard Community College",
  },
  {
    type: "School",
    name: "The Colorlab Academy of Hair",
  },
  {
    type: "School",
    name: "Johns Hopkins University",
  },
  {
    type: "School",
    name: "L'Academie de Cuisine",
  },
  {
    type: "School",
    name: "Lincoln College of Technology-Columbia",
  },
  {
    type: "School",
    name: "Loyola University Maryland",
  },
  {
    type: "School",
    name: "Maryland Beauty Academy of Reisterstown",
  },
  {
    type: "School",
    name: "University of Maryland-University College",
  },
  {
    type: "School",
    name: "University of Maryland-Baltimore",
  },
  {
    type: "School",
    name: "University of Maryland-Baltimore County",
  },
  {
    type: "School",
    name: "University of Maryland-College Park",
  },
  {
    type: "School",
    name: "Maryland Institute College of Art",
  },
  {
    type: "School",
    name: "University of Maryland Eastern Shore",
  },
  {
    type: "School",
    name: "Fortis Institute-Towson",
  },
  {
    type: "School",
    name: "Montgomery Beauty School",
  },
  {
    type: "School",
    name: "Montgomery College",
  },
  {
    type: "School",
    name: "Morgan State University",
  },
  {
    type: "School",
    name: "Mount St Mary's University",
  },
  {
    type: "School",
    name: "Ner Israel Rabbinical College",
  },
  {
    type: "School",
    name: "Hair Academy Inc-New Carrollton",
  },
  {
    type: "School",
    name: "Notre Dame of Maryland University",
  },
  {
    type: "School",
    name: "Prince George's Community College",
  },
  {
    type: "School",
    name: "TESST College of Technology-Baltimore",
  },
  {
    type: "School",
    name: "Robert Paul Academy of Cosmetology Arts & Sciences",
  },
  {
    type: "School",
    name: "Maryland Beauty Academy of Essex",
  },
  {
    type: "School",
    name: "St Mary's Seminary & University",
  },
  {
    type: "School",
    name: "Salisbury University",
  },
  {
    type: "School",
    name: "St Mary's College of Maryland",
  },
  {
    type: "School",
    name: "Sojourner-Douglass College",
  },
  {
    type: "School",
    name: "St John's College",
  },
  {
    type: "School",
    name: "TESST College of Technology-Beltsville",
  },
  {
    type: "School",
    name: "Towson University",
  },
  {
    type: "School",
    name: "Maryland University of Integrative Health",
  },
  {
    type: "School",
    name: "International Beauty School",
  },
  {
    type: "School",
    name: "University System of Maryland",
  },
  {
    type: "School",
    name: "United States Naval Academy",
  },
  {
    type: "School",
    name: "Stevenson University",
  },
  {
    type: "School",
    name: "Washington Bible College-Capital Bible Seminary",
  },
  {
    type: "School",
    name: "Washington College",
  },
  {
    type: "School",
    name: "Washington Theological Union",
  },
  {
    type: "School",
    name: "McDaniel College",
  },
  {
    type: "School",
    name: "Wor-Wic Community College",
  },
  {
    type: "School",
    name: "Hult International Business School",
  },
  {
    type: "School",
    name: "New England College of Business and Finance",
  },
  {
    type: "School",
    name: "American International College",
  },
  {
    type: "School",
    name: "Amherst College",
  },
  {
    type: "School",
    name: "Andover Newton Theological School",
  },
  {
    type: "School",
    name: "Anna Maria College",
  },
  {
    type: "School",
    name: "Assabet Valley Regional Technical School",
  },
  {
    type: "School",
    name: "Assumption College",
  },
  {
    type: "School",
    name: "Babson College",
  },
  {
    type: "School",
    name: "Bancroft School of Massage Therapy",
  },
  {
    type: "School",
    name: "Boston Baptist College",
  },
  {
    type: "School",
    name: "Bay Path College",
  },
  {
    type: "School",
    name: "Bay State College",
  },
  {
    type: "School",
    name: "Bay State School of Technology",
  },
  {
    type: "School",
    name: "Becker College",
  },
  {
    type: "School",
    name: "Bentley University",
  },
  {
    type: "School",
    name: "Berklee College of Music",
  },
  {
    type: "School",
    name: "Berkshire Community College",
  },
  {
    type: "School",
    name: "Empire Beauty School-Boston",
  },
  {
    type: "School",
    name: "Boston Architectural College",
  },
  {
    type: "School",
    name: "Boston Graduate School of Psychoanalysis Inc",
  },
  {
    type: "School",
    name: "Boston College",
  },
  {
    type: "School",
    name: "The Boston Conservatory",
  },
  {
    type: "School",
    name: "Boston University",
  },
  {
    type: "School",
    name: "Brandeis University",
  },
  {
    type: "School",
    name: "Bridgewater State University",
  },
  {
    type: "School",
    name: "Bristol Community College",
  },
  {
    type: "School",
    name: "Signature Healthcare Brockton Hospital School of Nursing",
  },
  {
    type: "School",
    name: "Bunker Hill Community College",
  },
  {
    type: "School",
    name: "Cambridge College",
  },
  {
    type: "School",
    name: "Cape Cod Community College",
  },
  {
    type: "School",
    name: "Catherine Hinds Institute of Esthetics",
  },
  {
    type: "School",
    name: "Laboure College",
  },
  {
    type: "School",
    name: "Clark University",
  },
  {
    type: "School",
    name: "Conway School of Landscape Design",
  },
  {
    type: "School",
    name: "Curry College",
  },
  {
    type: "School",
    name: "Lincoln Technical Institute-Somerville",
  },
  {
    type: "School",
    name: "Dean College",
  },
  {
    type: "School",
    name: "Diman Regional Technical Institute",
  },
  {
    type: "School",
    name: "National Aviation Academy of New England",
  },
  {
    type: "School",
    name: "Eastern Nazarene College",
  },
  {
    type: "School",
    name: "Emerson College",
  },
  {
    type: "School",
    name: "Emmanuel College",
  },
  {
    type: "School",
    name: "Endicott College",
  },
  {
    type: "School",
    name: "Episcopal Divinity School",
  },
  {
    type: "School",
    name: "Rob Roy Academy-Fall River",
  },
  {
    type: "School",
    name: "Fisher College",
  },
  {
    type: "School",
    name: "Fitchburg State University",
  },
  {
    type: "School",
    name: "Framingham State University",
  },
  {
    type: "School",
    name: "Benjamin Franklin Institute of Technology",
  },
  {
    type: "School",
    name: "Gordon College",
  },
  {
    type: "School",
    name: "Gordon-Conwell Theological Seminary",
  },
  {
    type: "School",
    name: "Greenfield Community College",
  },
  {
    type: "School",
    name: "Hallmark Institute of Photography",
  },
  {
    type: "School",
    name: "Hampshire College",
  },
  {
    type: "School",
    name: "Harvard University",
  },
  {
    type: "School",
    name: "Hebrew College",
  },
  {
    type: "School",
    name: "Hellenic College-Holy Cross Greek Orthodox School of Theology",
  },
  {
    type: "School",
    name: "Henris School of Hair Design",
  },
  {
    type: "School",
    name: "College of the Holy Cross",
  },
  {
    type: "School",
    name: "Holyoke Community College",
  },
  {
    type: "School",
    name: "Sanford-Brown College-Boston",
  },
  {
    type: "School",
    name: "Kay Harvey Academy of Hair Design",
  },
  {
    type: "School",
    name: "La Baron Hairdressing Academy-Brockton",
  },
  {
    type: "School",
    name: "La Baron Hairdressing Academy-New Bedford",
  },
  {
    type: "School",
    name: "Lasell College",
  },
  {
    type: "School",
    name: "Lawrence Memorial Hospital School of Nursing",
  },
  {
    type: "School",
    name: "Lesley University",
  },
  {
    type: "School",
    name: "Longy School of Music of Bard College",
  },
  {
    type: "School",
    name: "Lowell Academy Hairstyling Institute",
  },
  {
    type: "School",
    name: "University of Massachusetts-Lowell",
  },
  {
    type: "School",
    name: "Mansfield Beauty Schools-Springfield",
  },
  {
    type: "School",
    name: "Mansfield Beauty Schools-Quincy",
  },
  {
    type: "School",
    name: "Marian Court College",
  },
  {
    type: "School",
    name: "University of Massachusetts-Amherst",
  },
  {
    type: "School",
    name: "University of Massachusetts-Boston",
  },
  {
    type: "School",
    name: "Massachusetts Bay Community College",
  },
  {
    type: "School",
    name: "MCPHS University",
  },
  {
    type: "School",
    name: "University of Massachusetts-Central Office",
  },
  {
    type: "School",
    name: "Massachusetts College of Art and Design",
  },
  {
    type: "School",
    name: "Massachusetts Institute of Technology",
  },
  {
    type: "School",
    name: "Massachusetts Maritime Academy",
  },
  {
    type: "School",
    name: "University of Massachusetts Medical School Worcester",
  },
  {
    type: "School",
    name: "Massachusetts School of Professional Psychology",
  },
  {
    type: "School",
    name: "Massachusetts School of Barbering",
  },
  {
    type: "School",
    name: "Massasoit Community College",
  },
  {
    type: "School",
    name: "Merrimack College",
  },
  {
    type: "School",
    name: "MGH Institute of Health Professions",
  },
  {
    type: "School",
    name: "Middlesex Community College",
  },
  {
    type: "School",
    name: "Montserrat College of Art",
  },
  {
    type: "School",
    name: "Mount Holyoke College",
  },
  {
    type: "School",
    name: "Mount Ida College",
  },
  {
    type: "School",
    name: "Mount Wachusett Community College",
  },
  {
    type: "School",
    name: "Cortiva Institute-Boston",
  },
  {
    type: "School",
    name: "School of the Museum of Fine Arts-Boston",
  },
  {
    type: "School",
    name: "Everest Institute-Brighton",
  },
  {
    type: "School",
    name: "Rob Roy Academy-New Bedford",
  },
  {
    type: "School",
    name: "The New England Conservatory of Music",
  },
  {
    type: "School",
    name: "New England College of Optometry",
  },
  {
    type: "School",
    name: "New England School of Acupuncture",
  },
  {
    type: "School",
    name: "New England School of Law",
  },
  {
    type: "School",
    name: "New England School of Photography",
  },
  {
    type: "School",
    name: "Newbury College",
  },
  {
    type: "School",
    name: "Nichols College",
  },
  {
    type: "School",
    name: "Massachusetts College of Liberal Arts",
  },
  {
    type: "School",
    name: "North Bennet Street School",
  },
  {
    type: "School",
    name: "North Shore Community College",
  },
  {
    type: "School",
    name: "The New England Institute of Art",
  },
  {
    type: "School",
    name: "Northeastern University",
  },
  {
    type: "School",
    name: "Northern Essex Community College",
  },
  {
    type: "School",
    name: "College of Our Lady of the Elms",
  },
  {
    type: "School",
    name: "Pine Manor College",
  },
  {
    type: "School",
    name: "Blessed John XXIII National Seminary",
  },
  {
    type: "School",
    name: "Quincy College",
  },
  {
    type: "School",
    name: "Quinsigamond Community College",
  },
  {
    type: "School",
    name: "Kaplan Career Institute-Charlestown",
  },
  {
    type: "School",
    name: "Regis College",
  },
  {
    type: "School",
    name: "Roxbury Community College",
  },
  {
    type: "School",
    name: "Saint John's Seminary",
  },
  {
    type: "School",
    name: "Salem State University",
  },
  {
    type: "School",
    name: "Salter College-West Boylston",
  },
  {
    type: "School",
    name: "Simmons College",
  },
  {
    type: "School",
    name: "Bard College at Simon's Rock",
  },
  {
    type: "School",
    name: "Smith College",
  },
  {
    type: "School",
    name: "Southeastern Technical Institute",
  },
  {
    type: "School",
    name: "Springfield College",
  },
  {
    type: "School",
    name: "Springfield Technical Community College",
  },
  {
    type: "School",
    name: "University of Massachusetts-Dartmouth",
  },
  {
    type: "School",
    name: "Stonehill College",
  },
  {
    type: "School",
    name: "Suffolk University",
  },
  {
    type: "School",
    name: "Rob Roy Academy-Taunton",
  },
  {
    type: "School",
    name: "Tufts University",
  },
  {
    type: "School",
    name: "Wellesley College",
  },
  {
    type: "School",
    name: "Wentworth Institute of Technology",
  },
  {
    type: "School",
    name: "Western New England University",
  },
  {
    type: "School",
    name: "Westfield State University",
  },
  {
    type: "School",
    name: "Wheaton College",
  },
  {
    type: "School",
    name: "Wheelock College",
  },
  {
    type: "School",
    name: "Williams College",
  },
  {
    type: "School",
    name: "Worcester Polytechnic Institute",
  },
  {
    type: "School",
    name: "Worcester State University",
  },
  {
    type: "School",
    name: "Adrian College",
  },
  {
    type: "School",
    name: "Albion College",
  },
  {
    type: "School",
    name: "Hillsdale Beauty College",
  },
  {
    type: "School",
    name: "Northwestern Technological Institute",
  },
  {
    type: "School",
    name: "Alma College",
  },
  {
    type: "School",
    name: "Alpena Community College",
  },
  {
    type: "School",
    name: "Andrews University",
  },
  {
    type: "School",
    name: "Aquinas College",
  },
  {
    type: "School",
    name: "Baker College of Owosso",
  },
  {
    type: "School",
    name: "Baker College of Flint",
  },
  {
    type: "School",
    name: "Bay de Noc Community College",
  },
  {
    type: "School",
    name: "Bayshire Academy of Beauty Craft Inc",
  },
  {
    type: "School",
    name: "Cadillac Institute of Cosmetology",
  },
  {
    type: "School",
    name: "Calvin College",
  },
  {
    type: "School",
    name: "Calvin Theological Seminary",
  },
  {
    type: "School",
    name: "Carnegie Institute",
  },
  {
    type: "School",
    name: "Michigan School of Professional Psychology",
  },
  {
    type: "School",
    name: "Central Michigan University",
  },
  {
    type: "School",
    name: "Mott Community College",
  },
  {
    type: "School",
    name: "Empire Beauty School-Michigan",
  },
  {
    type: "School",
    name: "Cleary University",
  },
  {
    type: "School",
    name: "Concordia University-Ann Arbor",
  },
  {
    type: "School",
    name: "Cranbrook Academy of Art",
  },
  {
    type: "School",
    name: "College for Creative Studies",
  },
  {
    type: "School",
    name: "Davenport University",
  },
  {
    type: "School",
    name: "David Pressley School of Cosmetology",
  },
  {
    type: "School",
    name: "Delta College",
  },
  {
    type: "School",
    name: "Detroit Business Institute-Downriver",
  },
  {
    type: "School",
    name: "Michigan State University-College of Law",
  },
  {
    type: "School",
    name: "MIAT College of Technology",
  },
  {
    type: "School",
    name: "University of Detroit Mercy",
  },
  {
    type: "School",
    name: "Dorsey Business Schools-Madison Heights",
  },
  {
    type: "School",
    name: "Dorsey Business Schools-Southgate",
  },
  {
    type: "School",
    name: "Eastern Michigan University",
  },
  {
    type: "School",
    name: "Ferris State University",
  },
  {
    type: "School",
    name: "Flint Institute of Barbering Inc",
  },
  {
    type: "School",
    name: "Glen Oaks Community College",
  },
  {
    type: "School",
    name: "Kettering University",
  },
  {
    type: "School",
    name: "Gogebic Community College",
  },
  {
    type: "School",
    name: "Grace Bible College",
  },
  {
    type: "School",
    name: "Cornerstone University",
  },
  {
    type: "School",
    name: "Everest Institute-Grand Rapids",
  },
  {
    type: "School",
    name: "Grand Rapids Community College",
  },
  {
    type: "School",
    name: "Grand Valley State University",
  },
  {
    type: "School",
    name: "Great Lakes Christian College",
  },
  {
    type: "School",
    name: "Henry Ford Community College",
  },
  {
    type: "School",
    name: "Hope College",
  },
  {
    type: "School",
    name: "ITT Technical Institute-Wyoming",
  },
  {
    type: "School",
    name: "Jackson College",
  },
  {
    type: "School",
    name: "Wright Beauty Academy",
  },
  {
    type: "School",
    name: "Kalamazoo College",
  },
  {
    type: "School",
    name: "Kalamazoo Valley Community College",
  },
  {
    type: "School",
    name: "Kellogg Community College",
  },
  {
    type: "School",
    name: "Kirtland Community College",
  },
  {
    type: "School",
    name: "Michigan College of Beauty-Monroe",
  },
  {
    type: "School",
    name: "Lake Michigan College",
  },
  {
    type: "School",
    name: "Lake Superior State University",
  },
  {
    type: "School",
    name: "Lansing Community College",
  },
  {
    type: "School",
    name: "Lawrence Technological University",
  },
  {
    type: "School",
    name: "M J Murphy Beauty College of Mount Pleasant",
  },
  {
    type: "School",
    name: "Macomb Community College",
  },
  {
    type: "School",
    name: "Madonna University",
  },
  {
    type: "School",
    name: "Marygrove College",
  },
  {
    type: "School",
    name: "Rochester College",
  },
  {
    type: "School",
    name: "University of Michigan-Ann Arbor",
  },
  {
    type: "School",
    name: "Michigan Barber School Inc",
  },
  {
    type: "School",
    name: "Michigan State University",
  },
  {
    type: "School",
    name: "Michigan Technological University",
  },
  {
    type: "School",
    name: "University of Michigan-Dearborn",
  },
  {
    type: "School",
    name: "University of Michigan-Flint",
  },
  {
    type: "School",
    name: "Mid Michigan Community College",
  },
  {
    type: "School",
    name: "Monroe County Community College",
  },
  {
    type: "School",
    name: "Montcalm Community College",
  },
  {
    type: "School",
    name: "Mr Bela's School of Cosmetology Inc",
  },
  {
    type: "School",
    name: "Baker College of Muskegon",
  },
  {
    type: "School",
    name: "Muskegon Community College",
  },
  {
    type: "School",
    name: "Everest College-Skokie",
  },
  {
    type: "School",
    name: "Everest Institute-Southfield",
  },
  {
    type: "School",
    name: "North Central Michigan College",
  },
  {
    type: "School",
    name: "Northern Michigan University",
  },
  {
    type: "School",
    name: "Paul Mitchell the School-Escanaba",
  },
  {
    type: "School",
    name: "Northwestern Michigan College",
  },
  {
    type: "School",
    name: "Northwood University-Michigan",
  },
  {
    type: "School",
    name: "Oakland Community College",
  },
  {
    type: "School",
    name: "Oakland University",
  },
  {
    type: "School",
    name: "Olivet College",
  },
  {
    type: "School",
    name: "Port Huron Cosmetology College",
  },
  {
    type: "School",
    name: "Kuyper College",
  },
  {
    type: "School",
    name: "Ross Medical Education Center-Lansing",
  },
  {
    type: "School",
    name: "Ross Medical Education Center-Madison Heights",
  },
  {
    type: "School",
    name: "Ross Medical Education Center-Flint",
  },
  {
    type: "School",
    name: "Sacred Heart Major Seminary",
  },
  {
    type: "School",
    name: "Saginaw Valley State University",
  },
  {
    type: "School",
    name: "Schoolcraft College",
  },
  {
    type: "School",
    name: "Siena Heights University",
  },
  {
    type: "School",
    name: "St Clair County Community College",
  },
  {
    type: "School",
    name: "Southwestern Michigan College",
  },
  {
    type: "School",
    name: "Specs Howard School of Media Arts",
  },
  {
    type: "School",
    name: "Spring Arbor University",
  },
  {
    type: "School",
    name: "Michigan Career and Technical Institute",
  },
  {
    type: "School",
    name: "Finlandia University",
  },
  {
    type: "School",
    name: "Thomas M Cooley Law Schoo",
  },
  {
    type: "School",
    name: "Twin City Beauty College",
  },
  {
    type: "School",
    name: "Walsh College of Accountancy and Business Administration",
  },
  {
    type: "School",
    name: "Washtenaw Community College",
  },
  {
    type: "School",
    name: "Wayne County Community College District",
  },
  {
    type: "School",
    name: "Wayne State University",
  },
  {
    type: "School",
    name: "West Shore Community College",
  },
  {
    type: "School",
    name: "Western Michigan University",
  },
  {
    type: "School",
    name: "Western Theological Seminary",
  },
  {
    type: "School",
    name: "Wright Beauty Academy",
  },
  {
    type: "School",
    name: "Academy College",
  },
  {
    type: "School",
    name: "Alexandria Technical & Community College",
  },
  {
    type: "School",
    name: "American Indian OIC Inc",
  },
  {
    type: "School",
    name: "Anoka Technical College",
  },
  {
    type: "School",
    name: "Anoka-Ramsey Community College",
  },
  {
    type: "School",
    name: "Apostolic Bible Institute Inc",
  },
  {
    type: "School",
    name: "Augsburg College",
  },
  {
    type: "School",
    name: "Riverland Community College",
  },
  {
    type: "School",
    name: "Northwest Technical College",
  },
  {
    type: "School",
    name: "Bemidji State University",
  },
  {
    type: "School",
    name: "Bethany Lutheran College",
  },
  {
    type: "School",
    name: "Bethel University",
  },
  {
    type: "School",
    name: "Bethel Seminary-St Paul",
  },
  {
    type: "School",
    name: "Central Lakes College-Brainerd",
  },
  {
    type: "School",
    name: "Carleton College",
  },
  {
    type: "School",
    name: "Concordia College at Moorhead",
  },
  {
    type: "School",
    name: "Concordia University-Saint Paul",
  },
  {
    type: "School",
    name: "Dakota County Technical College",
  },
  {
    type: "School",
    name: "Martin Luther College",
  },
  {
    type: "School",
    name: "Lake Superior College",
  },
  {
    type: "School",
    name: "Cosmetology Careers Unlimited College of Hair Skin and Nails",
  },
  {
    type: "School",
    name: "Duluth Business University",
  },
  {
    type: "School",
    name: "Minnesota State Community and Technical College",
  },
  {
    type: "School",
    name: "Globe University-Woodbury",
  },
  {
    type: "School",
    name: "Minnesota West Community and Technical College",
  },
  {
    type: "School",
    name: "Gustavus Adolphus College",
  },
  {
    type: "School",
    name: "Hamline University",
  },
  {
    type: "School",
    name: "Hazelden Graduate School of Addiction Studies",
  },
  {
    type: "School",
    name: "Hennepin Technical College",
  },
  {
    type: "School",
    name: "Cosmetology Careers Unlimited College of Hair Skin and Nails",
  },
  {
    type: "School",
    name: "Hibbing Community College",
  },
  {
    type: "School",
    name: "Aveda Institute-Minneapolis",
  },
  {
    type: "School",
    name: "Inver Hills Community College",
  },
  {
    type: "School",
    name: "Itasca Community College",
  },
  {
    type: "School",
    name: "The Art Institutes International-Minnesota",
  },
  {
    type: "School",
    name: "Luther Seminary",
  },
  {
    type: "School",
    name: "Macalester College",
  },
  {
    type: "School",
    name: "South Central College",
  },
  {
    type: "School",
    name: "Minnesota State University-Mankato",
  },
  {
    type: "School",
    name: "Regency Beauty Institute-Blaine",
  },
  {
    type: "School",
    name: "Mayo Medical School",
  },
  {
    type: "School",
    name: "Mayo School of Health Sciences",
  },
  {
    type: "School",
    name: "Argosy University-Twin Cities",
  },
  {
    type: "School",
    name: "Mesabi Range Community and Technical College",
  },
  {
    type: "School",
    name: "Metropolitan State University",
  },
  {
    type: "School",
    name: "University of Minnesota-Twin Cities",
  },
  {
    type: "School",
    name: "University of Minnesota-Crookston",
  },
  {
    type: "School",
    name: "Minneapolis Business College",
  },
  {
    type: "School",
    name: "Minneapolis College of Art and Design",
  },
  {
    type: "School",
    name: "Minneapolis Community and Technical College",
  },
  {
    type: "School",
    name: "Herzing University-Minneapolis",
  },
  {
    type: "School",
    name: "Minnesota School of Cosmetology-Woodbury Campus",
  },
  {
    type: "School",
    name: "Crossroads College",
  },
  {
    type: "School",
    name: "University of Minnesota-Duluth",
  },
  {
    type: "School",
    name: "University of Minnesota-Morris",
  },
  {
    type: "School",
    name: "Minnesota School of Business-Richfield",
  },
  {
    type: "School",
    name: "Model College of Hair Design",
  },
  {
    type: "School",
    name: "Minnesota State University-Moorhead",
  },
  {
    type: "School",
    name: "North Hennepin Community College",
  },
  {
    type: "School",
    name: "National American University-Roseville",
  },
  {
    type: "School",
    name: "Brown College-Mendota Heights",
  },
  {
    type: "School",
    name: "Normandale Community College",
  },
  {
    type: "School",
    name: "North Central University",
  },
  {
    type: "School",
    name: "Northland Community and Technical College",
  },
  {
    type: "School",
    name: "Northwest Technical Institute",
  },
  {
    type: "School",
    name: "University of Northwestern-St Paul",
  },
  {
    type: "School",
    name: "Northwestern Health Sciences University",
  },
  {
    type: "School",
    name: "Oak Hills Christian College",
  },
  {
    type: "School",
    name: "Regency Beauty Institute-Burnsville",
  },
  {
    type: "School",
    name: "Pine Technical College",
  },
  {
    type: "School",
    name: "Rainy River Community College",
  },
  {
    type: "School",
    name: "Rochester Community and Technical College",
  },
  {
    type: "School",
    name: "College of Saint Benedict",
  },
  {
    type: "School",
    name: "St Cloud Technical and Community College",
  },
  {
    type: "School",
    name: "Regency Beauty Institute-St Cloud",
  },
  {
    type: "School",
    name: "Saint Cloud State University",
  },
  {
    type: "School",
    name: "Saint Johns University",
  },
  {
    type: "School",
    name: "Saint Mary's University of Minnesota",
  },
  {
    type: "School",
    name: "St Olaf College",
  },
  {
    type: "School",
    name: "Crown College",
  },
  {
    type: "School",
    name: "The College of Saint Scholastica",
  },
  {
    type: "School",
    name: "University of St Thomas",
  },
  {
    type: "School",
    name: "College of Visual Arts",
  },
  {
    type: "School",
    name: "Empire Beauty School-Bloomington",
  },
  {
    type: "School",
    name: "St Catherine University",
  },
  {
    type: "School",
    name: "Rasmussen College-Minnesota",
  },
  {
    type: "School",
    name: "Saint Paul College",
  },
  {
    type: "School",
    name: "Southwest Minnesota State University",
  },
  {
    type: "School",
    name: "Summit Academy Opportunities Industrialization Center",
  },
  {
    type: "School",
    name: "United Theological Seminary of the Twin Cities",
  },
  {
    type: "School",
    name: "Vermilion Community College",
  },
  {
    type: "School",
    name: "Dunwoody College of Technology",
  },
  {
    type: "School",
    name: "Ridgewater College",
  },
  {
    type: "School",
    name: "Minnesota State College-Southeast Technical",
  },
  {
    type: "School",
    name: "Winona State University",
  },
  {
    type: "School",
    name: "William Mitchell College of Law",
  },
  {
    type: "School",
    name: "Century College",
  },
  {
    type: "School",
    name: "Alcorn State University",
  },
  {
    type: "School",
    name: "Belhaven University",
  },
  {
    type: "School",
    name: "Blue Mountain College",
  },
  {
    type: "School",
    name: "Chris Beauty College",
  },
  {
    type: "School",
    name: "Coahoma Community College",
  },
  {
    type: "School",
    name: "Antonelli College-Jackson",
  },
  {
    type: "School",
    name: "Copiah-Lincoln Community College",
  },
  {
    type: "School",
    name: "Creations College of Cosmetology",
  },
  {
    type: "School",
    name: "Delta Beauty College",
  },
  {
    type: "School",
    name: "Delta State University",
  },
  {
    type: "School",
    name: "East Central Community College",
  },
  {
    type: "School",
    name: "East Mississippi Community College",
  },
  {
    type: "School",
    name: "Fosters Cosmetology College",
  },
  {
    type: "School",
    name: "Academy of Hair Design-Grenada",
  },
  {
    type: "School",
    name: "Hinds Community College",
  },
  {
    type: "School",
    name: "Holmes Community College",
  },
  {
    type: "School",
    name: "Itawamba Community College",
  },
  {
    type: "School",
    name: "Jackson State University",
  },
  {
    type: "School",
    name: "Jones County Junior College",
  },
  {
    type: "School",
    name: "Meridian Community College",
  },
  {
    type: "School",
    name: "Millsaps College",
  },
  {
    type: "School",
    name: "Board of Trustees-Mississippi State Institutions of Higher Learning",
  },
  {
    type: "School",
    name: "Mississippi Delta Community College",
  },
  {
    type: "School",
    name: "University of Mississippi",
  },
  {
    type: "School",
    name: "University of Mississippi Medical Center",
  },
  {
    type: "School",
    name: "Mississippi University for Women",
  },
  {
    type: "School",
    name: "Mississippi Valley State University",
  },
  {
    type: "School",
    name: "Mississippi College",
  },
  {
    type: "School",
    name: "Mississippi College of Beauty Culture",
  },
  {
    type: "School",
    name: "Mississippi Gulf Coast Community College",
  },
  {
    type: "School",
    name: "Mississippi State University",
  },
  {
    type: "School",
    name: "Northeast Mississippi Community College",
  },
  {
    type: "School",
    name: "Northwest Mississippi Community College",
  },
  {
    type: "School",
    name: "Pearl River Community College",
  },
  {
    type: "School",
    name: "Rust College",
  },
  {
    type: "School",
    name: "Southeastern Baptist College",
  },
  {
    type: "School",
    name: "Southwest Mississippi Community College",
  },
  {
    type: "School",
    name: "University of Southern Mississippi",
  },
  {
    type: "School",
    name: "Magnolia College of Cosmetology",
  },
  {
    type: "School",
    name: "Tougaloo College",
  },
  {
    type: "School",
    name: "Wesley Biblical Seminary",
  },
  {
    type: "School",
    name: "William Carey University",
  },
  {
    type: "School",
    name: "Anthem College-Maryland Heights",
  },
  {
    type: "School",
    name: "Aquinas Institute of Theology",
  },
  {
    type: "School",
    name: "Assemblies of God Theological Seminary",
  },
  {
    type: "School",
    name: "Avila University",
  },
  {
    type: "School",
    name: "ITT Technical Institute-Earth City",
  },
  {
    type: "School",
    name: "Baptist Bible College",
  },
  {
    type: "School",
    name: "House of Heavilin Beauty College-Blue Springs",
  },
  {
    type: "School",
    name: "Boonslick Technical Education Center",
  },
  {
    type: "School",
    name: "Cox College",
  },
  {
    type: "School",
    name: "Calvary Bible College and Theological Seminary",
  },
  {
    type: "School",
    name: "Cape Girardeau Career and Technology Center",
  },
  {
    type: "School",
    name: "Central Christian College of the Bible",
  },
  {
    type: "School",
    name: "Central Bible College",
  },
  {
    type: "School",
    name: "Central Methodist University-College of Liberal Arts and Sciences",
  },
  {
    type: "School",
    name: "University of Central Missouri",
  },
  {
    type: "School",
    name: "Grand River Technical School",
  },
  {
    type: "School",
    name: "Chillicothe Beauty Academy Inc",
  },
  {
    type: "School",
    name: "Cleveland University-Kansas City",
  },
  {
    type: "School",
    name: "Columbia Area Career Center",
  },
  {
    type: "School",
    name: "Columbia College",
  },
  {
    type: "School",
    name: "Conception Seminary College",
  },
  {
    type: "School",
    name: "Concordia Seminary",
  },
  {
    type: "School",
    name: "Cottey College",
  },
  {
    type: "School",
    name: "Covenant Theological Seminary",
  },
  {
    type: "School",
    name: "Crowder College",
  },
  {
    type: "School",
    name: "Culver-Stockton College",
  },
  {
    type: "School",
    name: "DeVry University-Missouri",
  },
  {
    type: "School",
    name: "Drury University",
  },
  {
    type: "School",
    name: "East Central College",
  },
  {
    type: "School",
    name: "Eden Theological Seminary",
  },
  {
    type: "School",
    name: "Elaine Steven Beauty College",
  },
  {
    type: "School",
    name: "Pinnacle Career Institute-South Kansas City",
  },
  {
    type: "School",
    name: "Evangel University",
  },
  {
    type: "School",
    name: "Fontbonne University",
  },
  {
    type: "School",
    name: "Forest Institute of Professional Psychology",
  },
  {
    type: "School",
    name: "Four Rivers Career Center",
  },
  {
    type: "School",
    name: "Ozarks Technical Community College",
  },
  {
    type: "School",
    name: "Hannibal-LaGrange University",
  },
  {
    type: "School",
    name: "Harris-Stowe State University",
  },
  {
    type: "School",
    name: "Hickey College",
  },
  {
    type: "School",
    name: "House of Heavilin Beauty College-Kansas City",
  },
  {
    type: "School",
    name: "Independence College of Cosmetology",
  },
  {
    type: "School",
    name: "Jefferson College",
  },
  {
    type: "School",
    name: "Cosmetology Concepts Institute",
  },
  {
    type: "School",
    name: "Barnes-Jewish College Goldfarb School of Nursing",
  },
  {
    type: "School",
    name: "Kansas City Art Institute",
  },
  {
    type: "School",
    name: "Kennett Career and Technology Center",
  },
  {
    type: "School",
    name: "Kenrick Glennon Seminary",
  },
  {
    type: "School",
    name: "A T Still University of Health Sciences",
  },
  {
    type: "School",
    name: "Lake Career and Technical Center",
  },
  {
    type: "School",
    name: "Lex La-Ray Technical Center",
  },
  {
    type: "School",
    name: "Lincoln University",
  },
  {
    type: "School",
    name: "Lindenwood University",
  },
  {
    type: "School",
    name: "Linn State Technical College",
  },
  {
    type: "School",
    name: "Logan University",
  },
  {
    type: "School",
    name: "Metropolitan Community College-Kansas City",
  },
  {
    type: "School",
    name: "Lutheran School of Nursing",
  },
  {
    type: "School",
    name: "Maryville University of Saint Louis",
  },
  {
    type: "School",
    name: "Merrell University of Beauty Arts and Science",
  },
  {
    type: "School",
    name: "Metro Business College-Cape Girardeau",
  },
  {
    type: "School",
    name: "Metropolitan Community College-Kansas City",
  },
  {
    type: "School",
    name: "Midwest Institute",
  },
  {
    type: "School",
    name: "Midwestern Baptist Theological Seminary",
  },
  {
    type: "School",
    name: "Mineral Area College",
  },
  {
    type: "School",
    name: "Westwood College-O'Hare Airport",
  },
  {
    type: "School",
    name: "Missouri Baptist University",
  },
  {
    type: "School",
    name: "National Academy of Beauty Arts-Farmington",
  },
  {
    type: "School",
    name: "Missouri College",
  },
  {
    type: "School",
    name: "Missouri School of Barbering & Hairstyling",
  },
  {
    type: "School",
    name: "Missouri Southern State University",
  },
  {
    type: "School",
    name: "Missouri Tech",
  },
  {
    type: "School",
    name: "Missouri Valley College",
  },
  {
    type: "School",
    name: "Missouri Western State University",
  },
  {
    type: "School",
    name: "University of Missouri-Columbia",
  },
  {
    type: "School",
    name: "University of Missouri-Kansas City",
  },
  {
    type: "School",
    name: "Missouri University of Science and Technology",
  },
  {
    type: "School",
    name: "University of Missouri-St Louis",
  },
  {
    type: "School",
    name: "University of Missouri-System Office",
  },
  {
    type: "School",
    name: "Moberly Area Community College",
  },
  {
    type: "School",
    name: "Hillyard Technical Center",
  },
  {
    type: "School",
    name: "National Academy of Beauty Arts-St Louis",
  },
  {
    type: "School",
    name: "Nazarene Theological Seminary",
  },
  {
    type: "School",
    name: "Neosho Beauty College",
  },
  {
    type: "School",
    name: "Nichols Career Center",
  },
  {
    type: "School",
    name: "Northwest Technical School",
  },
  {
    type: "School",
    name: "Truman State University",
  },
  {
    type: "School",
    name: "Northwest Missouri State University",
  },
  {
    type: "School",
    name: "Ozark Christian College",
  },
  {
    type: "School",
    name: "College of the Ozarks",
  },
  {
    type: "School",
    name: "Park University",
  },
  {
    type: "School",
    name: "Stevens Institute of Business & Arts",
  },
  {
    type: "School",
    name: "Poplar Bluff Technical Career Center",
  },
  {
    type: "School",
    name: "Ranken Technical College",
  },
  {
    type: "School",
    name: "Research College of Nursing",
  },
  {
    type: "School",
    name: "Rockhurst University",
  },
  {
    type: "School",
    name: "Rolla Technical Institute",
  },
  {
    type: "School",
    name: "Everest College-Springfield",
  },
  {
    type: "School",
    name: "Saint Louis University",
  },
  {
    type: "School",
    name: "Salem College of Hairstyling",
  },
  {
    type: "School",
    name: "Sanford-Brown College-Fenton",
  },
  {
    type: "School",
    name: "American College of Hair Design Inc",
  },
  {
    type: "School",
    name: "Sikeston Career and Technology Center",
  },
  {
    type: "School",
    name: "Saint Louis Christian College",
  },
  {
    type: "School",
    name: "St Louis College of Pharmacy",
  },
  {
    type: "School",
    name: "Saint Louis Community College",
  },
  {
    type: "School",
    name: "Saint Paul School of Theology",
  },
  {
    type: "School",
    name: "Southwest Baptist University",
  },
  {
    type: "School",
    name: "Missouri State University-West Plains",
  },
  {
    type: "School",
    name: "Mercy Hospital Springfield-School of Radiologic Technology",
  },
  {
    type: "School",
    name: "Saint Luke's College of Health Sciences",
  },
  {
    type: "School",
    name: "St Louis College of Health Careers-St Louis",
  },
  {
    type: "School",
    name: "State Fair Community College",
  },
  {
    type: "School",
    name: "Stephens College",
  },
  {
    type: "School",
    name: "Southeast Missouri State University",
  },
  {
    type: "School",
    name: "Missouri State University-Springfield",
  },
  {
    type: "School",
    name: "Three Rivers Community College",
  },
  {
    type: "School",
    name: "North Central Missouri College",
  },
  {
    type: "School",
    name: "Eldon Career Center",
  },
  {
    type: "School",
    name: "Truman Medical Center School of Nurse Anesthesia",
  },
  {
    type: "School",
    name: "Kansas City University of Medicine and Biosciences",
  },
  {
    type: "School",
    name: "Washington University in St Louis",
  },
  {
    type: "School",
    name: "Webster University",
  },
  {
    type: "School",
    name: "Wentworth Military Academy & Junior College",
  },
  {
    type: "School",
    name: "Westminster College",
  },
  {
    type: "School",
    name: "William Jewell College",
  },
  {
    type: "School",
    name: "William Woods University",
  },
  {
    type: "School",
    name: "Academy of Cosmetology",
  },
  {
    type: "School",
    name: "Blackfeet Community College",
  },
  {
    type: "School",
    name: "Butte Academy of Beauty Culture",
  },
  {
    type: "School",
    name: "Highlands College of Montana Tech",
  },
  {
    type: "School",
    name: "Crevier's School of Cosmetology",
  },
  {
    type: "School",
    name: "Carroll College",
  },
  {
    type: "School",
    name: "Dawson Community College",
  },
  {
    type: "School",
    name: "Chief Dull Knife College",
  },
  {
    type: "School",
    name: "Montana State University-Billings",
  },
  {
    type: "School",
    name: "Flathead Valley Community College",
  },
  {
    type: "School",
    name: "Aaniiih Nakoda College",
  },
  {
    type: "School",
    name: "Fort Peck Community College",
  },
  {
    type: "School",
    name: "Great Falls College Montana State University",
  },
  {
    type: "School",
    name: "University of Great Falls",
  },
  {
    type: "School",
    name: "Helena College University of Montana",
  },
  {
    type: "School",
    name: "Little Big Horn College",
  },
  {
    type: "School",
    name: "Miles Community College",
  },
  {
    type: "School",
    name: "Modern Beauty School Inc",
  },
  {
    type: "School",
    name: "Montana Tech of the University of Montana",
  },
  {
    type: "School",
    name: "Montana State University",
  },
  {
    type: "School",
    name: "The University of Montana",
  },
  {
    type: "School",
    name: "Montana State University-Northern",
  },
  {
    type: "School",
    name: "Rocky Mountain College",
  },
  {
    type: "School",
    name: "Salish Kootenai College",
  },
  {
    type: "School",
    name: "The University of Montana-Western",
  },
  {
    type: "School",
    name: "La'James International College",
  },
  {
    type: "School",
    name: "Bellevue University",
  },
  {
    type: "School",
    name: "Clarkson College",
  },
  {
    type: "School",
    name: "Bryan College of Health Sciences",
  },
  {
    type: "School",
    name: "Central Community College",
  },
  {
    type: "School",
    name: "Chadron State College",
  },
  {
    type: "School",
    name: "College of Hair Design-Downtown",
  },
  {
    type: "School",
    name: "Concordia University-Nebraska",
  },
  {
    type: "School",
    name: "Creighton University",
  },
  {
    type: "School",
    name: "Doane College-Crete",
  },
  {
    type: "School",
    name: "Grace University",
  },
  {
    type: "School",
    name: "Hastings College",
  },
  {
    type: "School",
    name: "Fullen School of Hair Design",
  },
  {
    type: "School",
    name: "Alegent Creighton Health School of Radiologic Technology",
  },
  {
    type: "School",
    name: "Joseph's College",
  },
  {
    type: "School",
    name: "University of Nebraska at Kearney",
  },
  {
    type: "School",
    name: "Kaplan University-Lincoln Campus",
  },
  {
    type: "School",
    name: "Nebraska Methodist College of Nursing & Allied Health",
  },
  {
    type: "School",
    name: "Metropolitan Community College Area",
  },
  {
    type: "School",
    name: "Mid-Plains Community College",
  },
  {
    type: "School",
    name: "Midland University",
  },
  {
    type: "School",
    name: "Nebraska Christian College",
  },
  {
    type: "School",
    name: "University of Nebraska at Omaha",
  },
  {
    type: "School",
    name: "Kaplan University-Omaha Campus",
  },
  {
    type: "School",
    name: "Nebraska Indian Community College",
  },
  {
    type: "School",
    name: "University of Nebraska Medical Center",
  },
  {
    type: "School",
    name: "Nebraska Wesleyan University",
  },
  {
    type: "School",
    name: "University of Nebraska-Lincoln",
  },
  {
    type: "School",
    name: "Northeast Community College",
  },
  {
    type: "School",
    name: "Peru State College",
  },
  {
    type: "School",
    name: "Summit Christian College",
  },
  {
    type: "School",
    name: "College of Saint Mary",
  },
  {
    type: "School",
    name: "Southeast Community College Area",
  },
  {
    type: "School",
    name: "Union College",
  },
  {
    type: "School",
    name: "University of Nebraska-Central Administration System Office",
  },
  {
    type: "School",
    name: "Vatterott College-Spring Valley",
  },
  {
    type: "School",
    name: "Nebraska College of Technical Agriculture",
  },
  {
    type: "School",
    name: "Wayne State College",
  },
  {
    type: "School",
    name: "Regional West Medical Center School of Radiologic Technology",
  },
  {
    type: "School",
    name: "Western Nebraska Community College",
  },
  {
    type: "School",
    name: "York College",
  },
  {
    type: "School",
    name: "Academy of Hair Design-Las Vegas",
  },
  {
    type: "School",
    name: "Career College of Northern Nevada",
  },
  {
    type: "School",
    name: "College of Southern Nevada",
  },
  {
    type: "School",
    name: "The Art Institute of Las Vegas",
  },
  {
    type: "School",
    name: "Everest College-Henderson",
  },
  {
    type: "School",
    name: "Marinello Schools of Beauty-Las Vegas",
  },
  {
    type: "School",
    name: "University of Nevada-Las Vegas",
  },
  {
    type: "School",
    name: "University of Nevada-Reno",
  },
  {
    type: "School",
    name: "Great Basin College",
  },
  {
    type: "School",
    name: "Marinello Schools of Beauty-Reno",
  },
  {
    type: "School",
    name: "Morrison University",
  },
  {
    type: "School",
    name: "Sierra Nevada College",
  },
  {
    type: "School",
    name: "Truckee Meadows Community College",
  },
  {
    type: "School",
    name: "Nevada System of Higher Education-System Office",
  },
  {
    type: "School",
    name: "Western Nevada College",
  },
  {
    type: "School",
    name: "Colby-Sawyer College",
  },
  {
    type: "School",
    name: "Continental Academie of Hair Design-Hudson",
  },
  {
    type: "School",
    name: "Daniel Webster College",
  },
  {
    type: "School",
    name: "Dartmouth College",
  },
  {
    type: "School",
    name: "Empire Beauty School-Laconia",
  },
  {
    type: "School",
    name: "Empire Beauty School-Somersworth",
  },
  {
    type: "School",
    name: "Franklin Pierce University",
  },
  {
    type: "School",
    name: "University of New Hampshire-School of Law",
  },
  {
    type: "School",
    name: "Mount Washington College",
  },
  {
    type: "School",
    name: "Keene Beauty Academy",
  },
  {
    type: "School",
    name: "Lebanon College",
  },
  {
    type: "School",
    name: "College of St Mary Magdalen",
  },
  {
    type: "School",
    name: "Michaels School of Hair Design and Esthetics-Paul Mitchell Partner School",
  },
  {
    type: "School",
    name: "New England College",
  },
  {
    type: "School",
    name: "New England School of Hair Design Inc",
  },
  {
    type: "School",
    name: "Southern New Hampshire University",
  },
  {
    type: "School",
    name: "New Hampshire Institute for Therapeutic Arts",
  },
  {
    type: "School",
    name: "University of New Hampshire-Main Campus",
  },
  {
    type: "School",
    name: "Keene State College",
  },
  {
    type: "School",
    name: "University of New Hampshire at Manchester",
  },
  {
    type: "School",
    name: "Plymouth State University",
  },
  {
    type: "School",
    name: "NHTI-Concord's Community College",
  },
  {
    type: "School",
    name: "White Mountains Community College",
  },
  {
    type: "School",
    name: "River Valley Community College",
  },
  {
    type: "School",
    name: "Lakes Region Community College",
  },
  {
    type: "School",
    name: "Manchester Community College",
  },
  {
    type: "School",
    name: "Nashua Community College",
  },
  {
    type: "School",
    name: "Great Bay Community College",
  },
  {
    type: "School",
    name: "Paul Mitchell the School-Portsmouth",
  },
  {
    type: "School",
    name: "Rivier University",
  },
  {
    type: "School",
    name: "Saint Anselm College",
  },
  {
    type: "School",
    name: "St Joseph School of Nursing",
  },
  {
    type: "School",
    name: "Granite State College",
  },
  {
    type: "School",
    name: "Thomas More College of Liberal Arts",
  },
  {
    type: "School",
    name: "University System of New Hampshire System Office",
  },
  {
    type: "School",
    name: "Eastwick College-Hackensack",
  },
  {
    type: "School",
    name: "Assumption College for Sisters",
  },
  {
    type: "School",
    name: "Atlantic Cape Community College",
  },
  {
    type: "School",
    name: "Fortis Institute-Wayne",
  },
  {
    type: "School",
    name: "Bergen Community College",
  },
  {
    type: "School",
    name: "Berkeley College-Woodland Park",
  },
  {
    type: "School",
    name: "Beth Medrash Govoha",
  },
  {
    type: "School",
    name: "Bloomfield College",
  },
  {
    type: "School",
    name: "Brookdale Community College",
  },
  {
    type: "School",
    name: "Burlington County College",
  },
  {
    type: "School",
    name: "Caldwell College",
  },
  {
    type: "School",
    name: "Camden County College",
  },
  {
    type: "School",
    name: "Centenary College",
  },
  {
    type: "School",
    name: "Adult and Continuing Education-BCTS",
  },
  {
    type: "School",
    name: "Anthem Institute-Parsippany",
  },
  {
    type: "School",
    name: "Lincoln Technical Institute-Edison",
  },
  {
    type: "School",
    name: "Fox Institute of Business-Clifton",
  },
  {
    type: "School",
    name: "Dover Business College",
  },
  {
    type: "School",
    name: "Empire Beauty School-Ocean",
  },
  {
    type: "School",
    name: "Cooper Health System Center for Allied Health Education",
  },
  {
    type: "School",
    name: "County College of Morris",
  },
  {
    type: "School",
    name: "Cumberland County College",
  },
  {
    type: "School",
    name: "DeVry University-New Jersey",
  },
  {
    type: "School",
    name: "Divers Academy International",
  },
  {
    type: "School",
    name: "Drake College of Business-Elizabeth",
  },
  {
    type: "School",
    name: "Drew University",
  },
  {
    type: "School",
    name: "duCret School of Arts",
  },
  {
    type: "School",
    name: "Lincoln Technical Institute-South Plainfield",
  },
  {
    type: "School",
    name: "Essex County College",
  },
  {
    type: "School",
    name: "Empire Beauty School-Union",
  },
  {
    type: "School",
    name: "Fairleigh Dickinson University-Metropolitan Campus",
  },
  {
    type: "School",
    name: "Felician College",
  },
  {
    type: "School",
    name: "Fairleigh Dickinson University-College at Florham",
  },
  {
    type: "School",
    name: "Hohokus School of Trade and Technical Sciences",
  },
  {
    type: "School",
    name: "Georgian Court University",
  },
  {
    type: "School",
    name: "Rowan University",
  },
  {
    type: "School",
    name: "Gloucester County College",
  },
  {
    type: "School",
    name: "Empire Beauty School-Cherry Hill",
  },
  {
    type: "School",
    name: "Harris School of Business-Cherry Hill Campus",
  },
  {
    type: "School",
    name: "Eastwick College-Ramsey",
  },
  {
    type: "School",
    name: "Holy Name Medical Center School of Nursing",
  },
  {
    type: "School",
    name: "Hudson County Community College",
  },
  {
    type: "School",
    name: "New Jersey City University",
  },
  {
    type: "School",
    name: "Joe Kubert School of Cartoon and Graphic Art",
  },
  {
    type: "School",
    name: "Kean University",
  },
  {
    type: "School",
    name: "Harris School of Business-Voorhees Campus",
  },
  {
    type: "School",
    name: "Lincoln Technical Institute-Union",
  },
  {
    type: "School",
    name: "Rizzieri Aveda School for Beauty and Wellness",
  },
  {
    type: "School",
    name: "Mercer County Community College",
  },
  {
    type: "School",
    name: "Middlesex County College",
  },
  {
    type: "School",
    name: "Monmouth University",
  },
  {
    type: "School",
    name: "Montclair State University",
  },
  {
    type: "School",
    name: "Mountainside School of Nursing",
  },
  {
    type: "School",
    name: "Muhlenberg Harold B and Dorothy A Snyder Schools-School of Imaging",
  },
  {
    type: "School",
    name: "Muhlenberg Harold B and Dorothy A Snyder Schools-School of Nursing",
  },
  {
    type: "School",
    name: "Hohokus School-RETS Nutley",
  },
  {
    type: "School",
    name: "Empire Beauty School-Jersey City",
  },
  {
    type: "School",
    name: "New Brunswick Theological Seminary",
  },
  {
    type: "School",
    name: "Robert Fiance Beauty Schools-West New York",
  },
  {
    type: "School",
    name: "New Jersey Institute of Technology",
  },
  {
    type: "School",
    name: "Ocean County College",
  },
  {
    type: "School",
    name: "Omega Institute",
  },
  {
    type: "School",
    name: "P B Cosmetology Education Center",
  },
  {
    type: "School",
    name: "Parisian Beauty School",
  },
  {
    type: "School",
    name: "Passaic County Community College",
  },
  {
    type: "School",
    name: "Pennco Tech-Blackwood",
  },
  {
    type: "School",
    name: "Princeton Theological Seminary",
  },
  {
    type: "School",
    name: "Princeton University",
  },
  {
    type: "School",
    name: "Rabbinical College of America",
  },
  {
    type: "School",
    name: "Ramapo College of New Jersey",
  },
  {
    type: "School",
    name: "Robert Fiance Beauty Schools-Perth Amboy",
  },
  {
    type: "School",
    name: "Rider University",
  },
  {
    type: "School",
    name: "Roman Academy of Beauty Culture",
  },
  {
    type: "School",
    name: "Rutgers University-Camden",
  },
  {
    type: "School",
    name: "Rutgers University-New Brunswick",
  },
  {
    type: "School",
    name: "Rutgers University-Newark",
  },
  {
    type: "School",
    name: "St Francis Medical Center-School of Radiologic Technology",
  },
  {
    type: "School",
    name: "Saint Francis Medical Center School of Nursing",
  },
  {
    type: "School",
    name: "Saint Peter's University",
  },
  {
    type: "School",
    name: "Salem Community College",
  },
  {
    type: "School",
    name: "Seton Hall University",
  },
  {
    type: "School",
    name: "Atlantic Beauty & Spa Academy LLC",
  },
  {
    type: "School",
    name: "College of Saint Elizabeth",
  },
  {
    type: "School",
    name: "Raritan Valley Community College",
  },
  {
    type: "School",
    name: "Stevens Institute of Technology",
  },
  {
    type: "School",
    name: "The Richard Stockton College of New Jersey",
  },
  {
    type: "School",
    name: "Talmudical Academy-New Jersey",
  },
  {
    type: "School",
    name: "Teterboro School of Aeronautics",
  },
  {
    type: "School",
    name: "Thomas Edison State College",
  },
  {
    type: "School",
    name: "The College of New Jersey",
  },
  {
    type: "School",
    name: "Sanford-Brown Institute-Iselin",
  },
  {
    type: "School",
    name: "Union County College",
  },
  {
    type: "School",
    name: "University of Medicine and Dentistry of New Jersey",
  },
  {
    type: "School",
    name: "William Paterson University of New Jersey",
  },
  {
    type: "School",
    name: "Olympian University of Cosmetology",
  },
  {
    type: "School",
    name: "Central New Mexico Community College",
  },
  {
    type: "School",
    name: "Navajo Technical University",
  },
  {
    type: "School",
    name: "De Wolff College Hair Styling and Cosmetology",
  },
  {
    type: "School",
    name: "New Mexico State University-Dona Ana",
  },
  {
    type: "School",
    name: "Clovis Community College",
  },
  {
    type: "School",
    name: "Eastern New Mexico University-Main Campus",
  },
  {
    type: "School",
    name: "Eastern New Mexico University-Roswell Campus",
  },
  {
    type: "School",
    name: "Institute of American Indian and Alaska Native Culture",
  },
  {
    type: "School",
    name: "New Mexico Highlands University",
  },
  {
    type: "School",
    name: "New Mexico Junior College",
  },
  {
    type: "School",
    name: "New Mexico Military Institute",
  },
  {
    type: "School",
    name: "University of New Mexico-Gallup Campus",
  },
  {
    type: "School",
    name: "New Mexico Institute of Mining and Technology",
  },
  {
    type: "School",
    name: "University of New Mexico-Los Alamos Campus",
  },
  {
    type: "School",
    name: "University of New Mexico-Main Campus",
  },
  {
    type: "School",
    name: "New Mexico State University-Alamogordo",
  },
  {
    type: "School",
    name: "New Mexico State University-Carlsbad",
  },
  {
    type: "School",
    name: "New Mexico State University-Grants",
  },
  {
    type: "School",
    name: "New Mexico State University-Main Campus",
  },
  {
    type: "School",
    name: "University of New Mexico-Valencia County Campus",
  },
  {
    type: "School",
    name: "Northern New Mexico College",
  },
  {
    type: "School",
    name: "San Juan College",
  },
  {
    type: "School",
    name: "Santa Fe Community College",
  },
  {
    type: "School",
    name: "Santa Fe University of Art and Design",
  },
  {
    type: "School",
    name: "University of the Southwest",
  },
  {
    type: "School",
    name: "Southwestern College",
  },
  {
    type: "School",
    name: "Southwestern Indian Polytechnic Institute",
  },
  {
    type: "School",
    name: "University of New Mexico-Taos Campus",
  },
  {
    type: "School",
    name: "Mesalands Community College",
  },
  {
    type: "School",
    name: "Vogue College of Cosmetology-Santa Fe",
  },
  {
    type: "School",
    name: "Western New Mexico University",
  },
  {
    type: "School",
    name: "Vaughn College of Aeronautics and Technology",
  },
  {
    type: "School",
    name: "Adelphi University",
  },
  {
    type: "School",
    name: "Adirondack Community College",
  },
  {
    type: "School",
    name: "Globe Institute of Technology",
  },
  {
    type: "School",
    name: "Bryant & Stratton College-Albany",
  },
  {
    type: "School",
    name: "Albany College of Pharmacy and Health Sciences",
  },
  {
    type: "School",
    name: "Albany Law School",
  },
  {
    type: "School",
    name: "Albany Medical College",
  },
  {
    type: "School",
    name: "Alfred University",
  },
  {
    type: "School",
    name: "Allen School-Brooklyn",
  },
  {
    type: "School",
    name: "The Ailey School",
  },
  {
    type: "School",
    name: "American Academy of Dramatic Arts-New York",
  },
  {
    type: "School",
    name: "American Academy McAllister Institute of Funeral Service",
  },
  {
    type: "School",
    name: "Joffrey Ballet School",
  },
  {
    type: "School",
    name: "American Musical and Dramatic Academy",
  },
  {
    type: "School",
    name: "Apex Technical School",
  },
  {
    type: "School",
    name: "Arnot Ogden Medical Center",
  },
  {
    type: "School",
    name: "Associated Beth Rivkah Schools",
  },
  {
    type: "School",
    name: "Austin's School of Spa Technology",
  },
  {
    type: "School",
    name: "Bank Street College of Education",
  },
  {
    type: "School",
    name: "Bard College",
  },
  {
    type: "School",
    name: "Barnard College",
  },
  {
    type: "School",
    name: "Berk Trade and Business School",
  },
  {
    type: "School",
    name: "Berkeley College-New York",
  },
  {
    type: "School",
    name: "Beth Hatalmud Rabbinical College",
  },
  {
    type: "School",
    name: "Beth Hamedrash Shaarei Yosher Institute",
  },
  {
    type: "School",
    name: "Phillips Beth Israel School of Nursing",
  },
  {
    type: "School",
    name: "Hair Design Institute at Fifth Avenue-Brooklyn",
  },
  {
    type: "School",
    name: "Boricua College",
  },
  {
    type: "School",
    name: "Bramson ORT College",
  },
  {
    type: "School",
    name: "Briarcliffe College",
  },
  {
    type: "School",
    name: "Brittany Beauty School",
  },
  {
    type: "School",
    name: "American Beauty School",
  },
  {
    type: "School",
    name: "Brooklyn Law School",
  },
  {
    type: "School",
    name: "SUNY Broome Community College",
  },
  {
    type: "School",
    name: "Bryant & Stratton College-Amherst",
  },
  {
    type: "School",
    name: "Bryant & Stratton College-Syracuse North",
  },
  {
    type: "School",
    name: "Bryant & Stratton College-Syracuse",
  },
  {
    type: "School",
    name: "Bryant & Stratton College-Buffalo",
  },
  {
    type: "School",
    name: "Bryant & Stratton College-Greece",
  },
  {
    type: "School",
    name: "Business Informatics Center Inc",
  },
  {
    type: "School",
    name: "Canisius College",
  },
  {
    type: "School",
    name: "Capri School of Hair Design",
  },
  {
    type: "School",
    name: "St Paul's School of Nursing-Queens",
  },
  {
    type: "School",
    name: "Cayuga County Community College",
  },
  {
    type: "School",
    name: "Cazenovia College",
  },
  {
    type: "School",
    name: "Central Yeshiva Tomchei Tmimim Lubavitz",
  },
  {
    type: "School",
    name: "Cheryl Fells School of Business",
  },
  {
    type: "School",
    name: "Christ the King Seminary",
  },
  {
    type: "School",
    name: "Circle in the Square Theater School",
  },
  {
    type: "School",
    name: "CUNY System Office",
  },
  {
    type: "School",
    name: "Clarkson University",
  },
  {
    type: "School",
    name: "Clinton Community College",
  },
  {
    type: "School",
    name: "Cochran School of Nursing",
  },
  {
    type: "School",
    name: "Colgate Rochester Crozer Divinity School",
  },
  {
    type: "School",
    name: "Colgate University",
  },
  {
    type: "School",
    name: "Metropolitan College of New York",
  },
  {
    type: "School",
    name: "Columbia University in the City of New York",
  },
  {
    type: "School",
    name: "Columbia-Greene Community College",
  },
  {
    type: "School",
    name: "Empire Beauty School-Queens",
  },
  {
    type: "School",
    name: "Concordia College-New York",
  },
  {
    type: "School",
    name: "Continental School of Beauty Culture-Buffalo",
  },
  {
    type: "School",
    name: "Continental School of Beauty Culture-Batavia",
  },
  {
    type: "School",
    name: "Continental School of Beauty Culture-Rochester",
  },
  {
    type: "School",
    name: "Cooper Union for the Advancement of Science and Art",
  },
  {
    type: "School",
    name: "Cornell University",
  },
  {
    type: "School",
    name: "Weill Cornell Medical College",
  },
  {
    type: "School",
    name: "Corning Community College",
  },
  {
    type: "School",
    name: "Crouse Hospital College of Nursing",
  },
  {
    type: "School",
    name: "Culinary Institute of America",
  },
  {
    type: "School",
    name: "CUNY Bernard M Baruch College",
  },
  {
    type: "School",
    name: "CUNY Borough of Manhattan Community College",
  },
  {
    type: "School",
    name: "CUNY Bronx Community College",
  },
  {
    type: "School",
    name: "CUNY Brooklyn College",
  },
  {
    type: "School",
    name: "College of Staten Island CUNY",
  },
  {
    type: "School",
    name: "CUNY City College",
  },
  {
    type: "School",
    name: "CUNY Graduate School and University Center",
  },
  {
    type: "School",
    name: "CUNY Hostos Community College",
  },
  {
    type: "School",
    name: "CUNY Hunter College",
  },
  {
    type: "School",
    name: "CUNY John Jay College of Criminal Justice",
  },
  {
    type: "School",
    name: "CUNY Kingsborough Community College",
  },
  {
    type: "School",
    name: "CUNY LaGuardia Community College",
  },
  {
    type: "School",
    name: "CUNY Lehman College",
  },
  {
    type: "School",
    name: "CUNY Medgar Evers College",
  },
  {
    type: "School",
    name: "CUNY New York City College of Technology",
  },
  {
    type: "School",
    name: "CUNY Queens College",
  },
  {
    type: "School",
    name: "CUNY Queensborough Community College",
  },
  {
    type: "School",
    name: "CUNY School of Law at Queens College",
  },
  {
    type: "School",
    name: "CUNY York College",
  },
  {
    type: "School",
    name: "CVPH Medical Center School of Radiologic Technology",
  },
  {
    type: "School",
    name: "D'Youville College",
  },
  {
    type: "School",
    name: "Daemen College",
  },
  {
    type: "School",
    name: "Yeshiva of Far Rockaway Derech Ayson Rabbinical Seminary",
  },
  {
    type: "School",
    name: "Dominican College of Blauvelt",
  },
  {
    type: "School",
    name: "Dowling College",
  },
  {
    type: "School",
    name: "Dutchess Community College",
  },
  {
    type: "School",
    name: "Belanger School of Nursing",
  },
  {
    type: "School",
    name: "Elmira Business Institute",
  },
  {
    type: "School",
    name: "Elmira College",
  },
  {
    type: "School",
    name: "Erie Community College",
  },
  {
    type: "School",
    name: "Fashion Institute of Technology",
  },
  {
    type: "School",
    name: "Finger Lakes Community College",
  },
  {
    type: "School",
    name: "Five Towns College",
  },
  {
    type: "School",
    name: "Fordham University",
  },
  {
    type: "School",
    name: "The International Culinary Center",
  },
  {
    type: "School",
    name: "Fulton-Montgomery Community College",
  },
  {
    type: "School",
    name: "Gemological Institute of America-New York",
  },
  {
    type: "School",
    name: "The General Theological Seminary",
  },
  {
    type: "School",
    name: "Genesee Community College",
  },
  {
    type: "School",
    name: "Global Business Institute",
  },
  {
    type: "School",
    name: "Global Business Institute",
  },
  {
    type: "School",
    name: "Hamilton College",
  },
  {
    type: "School",
    name: "Hartwick College",
  },
  {
    type: "School",
    name: "Helene Fuld College of Nursing",
  },
  {
    type: "School",
    name: "Herkimer County Community College",
  },
  {
    type: "School",
    name: "Hilbert College",
  },
  {
    type: "School",
    name: "Hobart William Smith Colleges",
  },
  {
    type: "School",
    name: "Hofstra University",
  },
  {
    type: "School",
    name: "Houghton College",
  },
  {
    type: "School",
    name: "Hudson Valley Community College",
  },
  {
    type: "School",
    name: "Hunter Business School",
  },
  {
    type: "School",
    name: "Institute of Design and Construction",
  },
  {
    type: "School",
    name: "Institute of Audio Research",
  },
  {
    type: "School",
    name: "Iona College",
  },
  {
    type: "School",
    name: "Isabella Graham Hart School of Practical Nursing",
  },
  {
    type: "School",
    name: "Island Drafting and Technical Institute",
  },
  {
    type: "School",
    name: "Ithaca College",
  },
  {
    type: "School",
    name: "Jamestown Community College",
  },
  {
    type: "School",
    name: "Jamestown Business College",
  },
  {
    type: "School",
    name: "Jefferson Community College",
  },
  {
    type: "School",
    name: "Jewish Theological Seminary of America",
  },
  {
    type: "School",
    name: "The Juilliard School",
  },
  {
    type: "School",
    name: "SBI Campus-An Affiliate of Sanford-Brown",
  },
  {
    type: "School",
    name: "Kehilath Yakov Rabbinical Seminary",
  },
  {
    type: "School",
    name: "Keuka College",
  },
  {
    type: "School",
    name: "LIM College",
  },
  {
    type: "School",
    name: "Le Moyne College",
  },
  {
    type: "School",
    name: "LIU Brooklyn",
  },
  {
    type: "School",
    name: "LIU Post",
  },
  {
    type: "School",
    name: "LIU-University Center Campus",
  },
  {
    type: "School",
    name: "Long Island Beauty School-Hempstead",
  },
  {
    type: "School",
    name: "Long Island Business Institute",
  },
  {
    type: "School",
    name: "LIU Hudson at Rockland",
  },
  {
    type: "School",
    name: "LIU Brentwood",
  },
  {
    type: "School",
    name: "Machzikei Hadath Rabbinical College",
  },
  {
    type: "School",
    name: "Mandl School-The College of Allied Health",
  },
  {
    type: "School",
    name: "Manhattan College",
  },
  {
    type: "School",
    name: "Manhattan School of Music",
  },
  {
    type: "School",
    name: "Manhattanville College",
  },
  {
    type: "School",
    name: "Maria College of Albany",
  },
  {
    type: "School",
    name: "Marion S Whelan School of Nursing of Geneva General Hospital",
  },
  {
    type: "School",
    name: "Marist College",
  },
  {
    type: "School",
    name: "MarJon School of Beauty ltd-Tonawanda",
  },
  {
    type: "School",
    name: "Marymount Manhattan College",
  },
  {
    type: "School",
    name: "Medaille College",
  },
  {
    type: "School",
    name: "Memorial School of Nursing",
  },
  {
    type: "School",
    name: "Memorial Hospital School of Radiation Therapy Technology",
  },
  {
    type: "School",
    name: "Mercy College",
  },
  {
    type: "School",
    name: "Mesivta Torah Vodaath Rabbinical Seminary",
  },
  {
    type: "School",
    name: "Mesivta of Eastern Parkway-Yeshiva Zichron Meilech",
  },
  {
    type: "School",
    name: "Mesivtha Tifereth Jerusalem of America",
  },
  {
    type: "School",
    name: "Southern Westchester BOCES-Practical Nursing Program",
  },
  {
    type: "School",
    name: "Midway Paris Beauty School",
  },
  {
    type: "School",
    name: "Mildred Elley School-Albany Campus",
  },
  {
    type: "School",
    name: "Mirrer Yeshiva Cent Institute",
  },
  {
    type: "School",
    name: "Modern Welding School",
  },
  {
    type: "School",
    name: "Mohawk Valley Community College",
  },
  {
    type: "School",
    name: "Molloy College",
  },
  {
    type: "School",
    name: "Monroe College",
  },
  {
    type: "School",
    name: "Monroe Community College",
  },
  {
    type: "School",
    name: "Mount Saint Mary College",
  },
  {
    type: "School",
    name: "Dorothea Hopfer School of Nursing-Mt Vernon Hospital",
  },
  {
    type: "School",
    name: "College of Mount Saint Vincent",
  },
  {
    type: "School",
    name: "Icahn School of Medicine at Mount Sinai",
  },
  {
    type: "School",
    name: "Nassau Community College",
  },
  {
    type: "School",
    name: "Nazareth College",
  },
  {
    type: "School",
    name: "Neighborhood Playhouse School of the Theater",
  },
  {
    type: "School",
    name: "The College of New Rochelle",
  },
  {
    type: "School",
    name: "The New School",
  },
  {
    type: "School",
    name: "New School of Radio and Television",
  },
  {
    type: "School",
    name: "New York Chiropractic College",
  },
  {
    type: "School",
    name: "Star Career Academy-New York",
  },
  {
    type: "School",
    name: "New York Institute of English and Business",
  },
  {
    type: "School",
    name: "New York Law School",
  },
  {
    type: "School",
    name: "New York Medical College",
  },
  {
    type: "School",
    name: "New York School for Medical and Dental Assistants",
  },
  {
    type: "School",
    name: "New York Theological Seminary",
  },
  {
    type: "School",
    name: "New York University",
  },
  {
    type: "School",
    name: "Niagara County Community College",
  },
  {
    type: "School",
    name: "Niagara University",
  },
  {
    type: "School",
    name: "Empire Beauty School-Peekskill",
  },
  {
    type: "School",
    name: "North Country Community College",
  },
  {
    type: "School",
    name: "New York College of Podiatric Medicine",
  },
  {
    type: "School",
    name: "New York Institute of Technology",
  },
  {
    type: "School",
    name: "New York School of Interior Design",
  },
  {
    type: "School",
    name: "Nyack College",
  },
  {
    type: "School",
    name: "Ohr Hameir Theological Seminary",
  },
  {
    type: "School",
    name: "Olean Business Institute",
  },
  {
    type: "School",
    name: "Onondaga Community College",
  },
  {
    type: "School",
    name: "Orange County Community College",
  },
  {
    type: "School",
    name: "Otsego Area BOCES-Practical Nursing Program",
  },
  {
    type: "School",
    name: "Pace University-New York",
  },
  {
    type: "School",
    name: "Paul Smiths College of Arts and Science",
  },
  {
    type: "School",
    name: "Continental School of Beauty Culture-West Seneca",
  },
  {
    type: "School",
    name: "Plaza College",
  },
  {
    type: "School",
    name: "Polytechnic Institute of New York University",
  },
  {
    type: "School",
    name: "Davis College",
  },
  {
    type: "School",
    name: "Pratt Institute-Main",
  },
  {
    type: "School",
    name: "Professional Business College",
  },
  {
    type: "School",
    name: "Rabbinical Academy Mesivta Rabbi Chaim Berlin",
  },
  {
    type: "School",
    name: "Rabbinical College Bobover Yeshiva Bnei Zion",
  },
  {
    type: "School",
    name: "Rabbinical College of Ch'san Sofer New York",
  },
  {
    type: "School",
    name: "Rabbinical College Beth Shraga",
  },
  {
    type: "School",
    name: "Rabbinical Seminary M'kor Chaim",
  },
  {
    type: "School",
    name: "Rabbinical College of Long Island",
  },
  {
    type: "School",
    name: "Rabbinical Seminary of America",
  },
  {
    type: "School",
    name: "Rensselaer Polytechnic Institute",
  },
  {
    type: "School",
    name: "Ridley-Lowell Business & Technical Institute-Binghamton",
  },
  {
    type: "School",
    name: "Roberts Wesleyan College",
  },
  {
    type: "School",
    name: "Everest Institute-Rochester",
  },
  {
    type: "School",
    name: "Rochester Institute of Technology",
  },
  {
    type: "School",
    name: "University of Rochester",
  },
  {
    type: "School",
    name: "Rockefeller University",
  },
  {
    type: "School",
    name: "Rockland Community College",
  },
  {
    type: "School",
    name: "Empire Beauty School-Manhattan",
  },
  {
    type: "School",
    name: "The Sage Colleges",
  },
  {
    type: "School",
    name: "St Bernard's School of Theology and Ministry",
  },
  {
    type: "School",
    name: "St Bonaventure University",
  },
  {
    type: "School",
    name: "St Francis College",
  },
  {
    type: "School",
    name: "St Joseph's College of Nursing at St Joseph's Hospital Health Center",
  },
  {
    type: "School",
    name: "St Lawrence University",
  },
  {
    type: "School",
    name: "The College of Saint Rose",
  },
  {
    type: "School",
    name: "St Thomas Aquinas College",
  },
  {
    type: "School",
    name: "Samaritan Hospital School of Nursing",
  },
  {
    type: "School",
    name: "Sarah Lawrence College",
  },
  {
    type: "School",
    name: "Schenectady County Community College",
  },
  {
    type: "School",
    name: "Sh'or Yoshuv Rabbinical College",
  },
  {
    type: "School",
    name: "Siena College",
  },
  {
    type: "School",
    name: "Simmons Institute of Funeral Service Inc",
  },
  {
    type: "School",
    name: "Skidmore College",
  },
  {
    type: "School",
    name: "Saint Joseph's College-New York",
  },
  {
    type: "School",
    name: "Saint Vladimirs Orthodox Theological Seminary",
  },
  {
    type: "School",
    name: "Spanish-American Institute",
  },
  {
    type: "School",
    name: "Faxton-St Luke's Healthcare School of Radiologic Technology",
  },
  {
    type: "School",
    name: "Saint Elizabeth College of Nursing",
  },
  {
    type: "School",
    name: "Saint Elizabeth Medical Center School of Radiography",
  },
  {
    type: "School",
    name: "Saint John Fisher College",
  },
  {
    type: "School",
    name: "St Paul's School of Nursing-Staten Island",
  },
  {
    type: "School",
    name: "St James Mercy Hospital School of Radiologic Science",
  },
  {
    type: "School",
    name: "St John's University-New York",
  },
  {
    type: "School",
    name: "SUNY-System Office",
  },
  {
    type: "School",
    name: "New York Career Institute",
  },
  {
    type: "School",
    name: "Suburban Technical School",
  },
  {
    type: "School",
    name: "Branford Hall Career Institute-Bohemia Campus",
  },
  {
    type: "School",
    name: "Sullivan County Community College",
  },
  {
    type: "School",
    name: "SUNY College of Technology at Alfred",
  },
  {
    type: "School",
    name: "SUNY College of Technology at Canton",
  },
  {
    type: "School",
    name: "SUNY College of Technology at Delhi",
  },
  {
    type: "School",
    name: "SUNY College of Agriculture and Technology at Cobleskill",
  },
  {
    type: "School",
    name: "Farmingdale State College",
  },
  {
    type: "School",
    name: "Morrisville State College",
  },
  {
    type: "School",
    name: "SUNY at Albany",
  },
  {
    type: "School",
    name: "SUNY at Binghamton",
  },
  {
    type: "School",
    name: "University at Buffalo",
  },
  {
    type: "School",
    name: "Stony Brook University",
  },
  {
    type: "School",
    name: "SUNY College of Environmental Science and Forestry",
  },
  {
    type: "School",
    name: "SUNY Institute of Technology at Utica-Rome",
  },
  {
    type: "School",
    name: "SUNY College at Brockport",
  },
  {
    type: "School",
    name: "Buffalo State SUNY",
  },
  {
    type: "School",
    name: "SUNY College at Cortland",
  },
  {
    type: "School",
    name: "SUNY at Fredonia",
  },
  {
    type: "School",
    name: "SUNY College at Geneseo",
  },
  {
    type: "School",
    name: "State University of New York at New Paltz",
  },
  {
    type: "School",
    name: "SUNY Oneonta",
  },
  {
    type: "School",
    name: "SUNY College at Oswego",
  },
  {
    type: "School",
    name: "SUNY College at Potsdam",
  },
  {
    type: "School",
    name: "SUNY at Purchase College",
  },
  {
    type: "School",
    name: "SUNY College of Optometry",
  },
  {
    type: "School",
    name: "SUNY College at Old Westbury",
  },
  {
    type: "School",
    name: "SUNY College at Plattsburgh",
  },
  {
    type: "School",
    name: "SUNY Downstate Medical Center",
  },
  {
    type: "School",
    name: "SUNY Empire State College",
  },
  {
    type: "School",
    name: "SUNY Maritime College",
  },
  {
    type: "School",
    name: "Upstate Medical University",
  },
  {
    type: "School",
    name: "Swedish Institute a College of Health Sciences",
  },
  {
    type: "School",
    name: "Syracuse University",
  },
  {
    type: "School",
    name: "Talmudical Seminary Oholei Torah",
  },
  {
    type: "School",
    name: "Talmudical Institute of Upstate New York",
  },
  {
    type: "School",
    name: "Teachers College at Columbia University",
  },
  {
    type: "School",
    name: "Technical Career Institutes",
  },
  {
    type: "School",
    name: "Tompkins Cortland Community College",
  },
  {
    type: "School",
    name: "Torah Temimah Talmudical Seminary",
  },
  {
    type: "School",
    name: "Touro College",
  },
  {
    type: "School",
    name: "Trocaire College",
  },
  {
    type: "School",
    name: "Excelsior College",
  },
  {
    type: "School",
    name: "Ulster County Community College",
  },
  {
    type: "School",
    name: "Sanford-Brown Institute-New York",
  },
  {
    type: "School",
    name: "Union College",
  },
  {
    type: "School",
    name: "Union Theological Seminary in the City of New York",
  },
  {
    type: "School",
    name: "United Talmudical Seminary",
  },
  {
    type: "School",
    name: "United States Merchant Marine Academy",
  },
  {
    type: "School",
    name: "United States Military Academy",
  },
  {
    type: "School",
    name: "Utica College",
  },
  {
    type: "School",
    name: "Utica School of Commerce",
  },
  {
    type: "School",
    name: "Vassar College",
  },
  {
    type: "School",
    name: "Villa Maria College",
  },
  {
    type: "School",
    name: "School of Visual Arts",
  },
  {
    type: "School",
    name: "Wagner College",
  },
  {
    type: "School",
    name: "Webb Institute",
  },
  {
    type: "School",
    name: "Wells College",
  },
  {
    type: "School",
    name: "The College of Westchester",
  },
  {
    type: "School",
    name: "SUNY Westchester Community College",
  },
  {
    type: "School",
    name: "Westchester School of Beauty Culture",
  },
  {
    type: "School",
    name: "Wood Tobe-Coburn School",
  },
  {
    type: "School",
    name: "Word of Life Bible Institute",
  },
  {
    type: "School",
    name: "Yeshiva Karlin Stolin",
  },
  {
    type: "School",
    name: "Yeshivat Mikdash Melech",
  },
  {
    type: "School",
    name: "Yeshiva Derech Chaim",
  },
  {
    type: "School",
    name: "Yeshiva of Nitra Rabbinical College",
  },
  {
    type: "School",
    name: "Yeshiva Shaar Hatorah",
  },
  {
    type: "School",
    name: "Yeshiva University",
  },
  {
    type: "School",
    name: "Yeshivath Viznitz",
  },
  {
    type: "School",
    name: "Yeshivath Zichron Moshe",
  },
  {
    type: "School",
    name: "College of the Albemarle",
  },
  {
    type: "School",
    name: "The Art Institute of Charlotte",
  },
  {
    type: "School",
    name: "South Piedmont Community College",
  },
  {
    type: "School",
    name: "Appalachian State University",
  },
  {
    type: "School",
    name: "Asheville-Buncombe Technical Community College",
  },
  {
    type: "School",
    name: "Barton College",
  },
  {
    type: "School",
    name: "Beaufort County Community College",
  },
  {
    type: "School",
    name: "Belmont Abbey College",
  },
  {
    type: "School",
    name: "Bennett College",
  },
  {
    type: "School",
    name: "Bladen Community College",
  },
  {
    type: "School",
    name: "Blue Ridge Community College",
  },
  {
    type: "School",
    name: "Brevard College",
  },
  {
    type: "School",
    name: "Brookstone College-Charlotte",
  },
  {
    type: "School",
    name: "Brunswick Community College",
  },
  {
    type: "School",
    name: "Cabarrus College of Health Sciences",
  },
  {
    type: "School",
    name: "Caldwell Community College and Technical Institute",
  },
  {
    type: "School",
    name: "Campbell University",
  },
  {
    type: "School",
    name: "Cape Fear Community College",
  },
  {
    type: "School",
    name: "Carolina School of Broadcasting",
  },
  {
    type: "School",
    name: "Carteret Community College",
  },
  {
    type: "School",
    name: "Catawba College",
  },
  {
    type: "School",
    name: "Catawba Valley Community College",
  },
  {
    type: "School",
    name: "South College-Asheville",
  },
  {
    type: "School",
    name: "Central Carolina Community College",
  },
  {
    type: "School",
    name: "Central Piedmont Community College",
  },
  {
    type: "School",
    name: "Chowan University",
  },
  {
    type: "School",
    name: "Cleveland Community College",
  },
  {
    type: "School",
    name: "Coastal Carolina Community College",
  },
  {
    type: "School",
    name: "Craven Community College",
  },
  {
    type: "School",
    name: "Davidson County Community College",
  },
  {
    type: "School",
    name: "Davidson College",
  },
  {
    type: "School",
    name: "Duke University",
  },
  {
    type: "School",
    name: "Durham Technical Community College",
  },
  {
    type: "School",
    name: "East Carolina University",
  },
  {
    type: "School",
    name: "Edgecombe Community College",
  },
  {
    type: "School",
    name: "Elizabeth City State University",
  },
  {
    type: "School",
    name: "Elon University",
  },
  {
    type: "School",
    name: "Fayetteville Beauty College",
  },
  {
    type: "School",
    name: "Fayetteville Technical Community College",
  },
  {
    type: "School",
    name: "Fayetteville State University",
  },
  {
    type: "School",
    name: "Forsyth Technical Community College",
  },
  {
    type: "School",
    name: "Gardner-Webb University",
  },
  {
    type: "School",
    name: "Gaston College",
  },
  {
    type: "School",
    name: "Greensboro College",
  },
  {
    type: "School",
    name: "Guilford College",
  },
  {
    type: "School",
    name: "Guilford Technical Community College",
  },
  {
    type: "School",
    name: "Halifax Community College",
  },
  {
    type: "School",
    name: "Haywood Community College",
  },
  {
    type: "School",
    name: "Heritage Bible College",
  },
  {
    type: "School",
    name: "High Point University",
  },
  {
    type: "School",
    name: "Isothermal Community College",
  },
  {
    type: "School",
    name: "James Sprunt Community College",
  },
  {
    type: "School",
    name: "Laurel University",
  },
  {
    type: "School",
    name: "Johnson C Smith University",
  },
  {
    type: "School",
    name: "Johnston Community College",
  },
  {
    type: "School",
    name: "Lees-McRae College",
  },
  {
    type: "School",
    name: "Lenoir Community College",
  },
  {
    type: "School",
    name: "Lenoir-Rhyne University",
  },
  {
    type: "School",
    name: "Leons Beauty School Inc",
  },
  {
    type: "School",
    name: "Livingstone College",
  },
  {
    type: "School",
    name: "Louisburg College",
  },
  {
    type: "School",
    name: "Mars Hill University",
  },
  {
    type: "School",
    name: "Martin Community College",
  },
  {
    type: "School",
    name: "Mayland Community College",
  },
  {
    type: "School",
    name: "McDowell Technical Community College",
  },
  {
    type: "School",
    name: "Mercy School of Nursing",
  },
  {
    type: "School",
    name: "Meredith College",
  },
  {
    type: "School",
    name: "Methodist University",
  },
  {
    type: "School",
    name: "Miller-Motte College-Wilmington",
  },
  {
    type: "School",
    name: "Mitchell Community College",
  },
  {
    type: "School",
    name: "Mitchells Hairstyling Academy-Greenville",
  },
  {
    type: "School",
    name: "Mitchells Hairstyling Academy-Wilson",
  },
  {
    type: "School",
    name: "Regina's College of Beauty-Monroe",
  },
  {
    type: "School",
    name: "Montgomery Community College",
  },
  {
    type: "School",
    name: "Montreat College",
  },
  {
    type: "School",
    name: "Mount Olive College",
  },
  {
    type: "School",
    name: "College of Wilmington",
  },
  {
    type: "School",
    name: "Nash Community College",
  },
  {
    type: "School",
    name: "North Carolina A & T State University",
  },
  {
    type: "School",
    name: "University of North Carolina at Asheville",
  },
  {
    type: "School",
    name: "University of North Carolina at Chapel Hill",
  },
  {
    type: "School",
    name: "University of North Carolina at Charlotte",
  },
  {
    type: "School",
    name: "University of North Carolina at Greensboro",
  },
  {
    type: "School",
    name: "North Carolina Central University",
  },
  {
    type: "School",
    name: "University of North Carolina System Office",
  },
  {
    type: "School",
    name: "University of North Carolina School of the Arts",
  },
  {
    type: "School",
    name: "North Carolina State University at Raleigh",
  },
  {
    type: "School",
    name: "North Carolina Wesleyan College",
  },
  {
    type: "School",
    name: "University of North Carolina Wilmington",
  },
  {
    type: "School",
    name: "Pamlico Community College",
  },
  {
    type: "School",
    name: "William Peace University",
  },
  {
    type: "School",
    name: "University of North Carolina at Pembroke",
  },
  {
    type: "School",
    name: "Pfeiffer University",
  },
  {
    type: "School",
    name: "Piedmont International University",
  },
  {
    type: "School",
    name: "Piedmont Community College",
  },
  {
    type: "School",
    name: "Pitt Community College",
  },
  {
    type: "School",
    name: "Queens University of Charlotte",
  },
  {
    type: "School",
    name: "Randolph Community College",
  },
  {
    type: "School",
    name: "Richmond Community College",
  },
  {
    type: "School",
    name: "Mid-Atlantic Christian University",
  },
  {
    type: "School",
    name: "Roanoke-Chowan Community College",
  },
  {
    type: "School",
    name: "Robeson Community College",
  },
  {
    type: "School",
    name: "Rockingham Community College",
  },
  {
    type: "School",
    name: "Rowan-Cabarrus Community College",
  },
  {
    type: "School",
    name: "Saint Augustine's University",
  },
  {
    type: "School",
    name: "Salem College",
  },
  {
    type: "School",
    name: "Sampson Community College",
  },
  {
    type: "School",
    name: "Sandhills Community College",
  },
  {
    type: "School",
    name: "Shaw University",
  },
  {
    type: "School",
    name: "Paul Mitchell the School-Fayetteville",
  },
  {
    type: "School",
    name: "St Andrews University",
  },
  {
    type: "School",
    name: "Empire Beauty School-Matthews",
  },
  {
    type: "School",
    name: "Southeastern Community College",
  },
  {
    type: "School",
    name: "Southwestern Community College",
  },
  {
    type: "School",
    name: "Stanly Community College",
  },
  {
    type: "School",
    name: "Southeastern Baptist Theological Seminary",
  },
  {
    type: "School",
    name: "Surry Community College",
  },
  {
    type: "School",
    name: "Alamance Community College",
  },
  {
    type: "School",
    name: "Tri-County Community College",
  },
  {
    type: "School",
    name: "Vance-Granville Community College",
  },
  {
    type: "School",
    name: "Wake Forest University",
  },
  {
    type: "School",
    name: "Wake Technical Community College",
  },
  {
    type: "School",
    name: "Warren Wilson College",
  },
  {
    type: "School",
    name: "Watts School of Nursing",
  },
  {
    type: "School",
    name: "Wayne Community College",
  },
  {
    type: "School",
    name: "Western Piedmont Community College",
  },
  {
    type: "School",
    name: "Wilkes Community College",
  },
  {
    type: "School",
    name: "Wilson Community College",
  },
  {
    type: "School",
    name: "Wingate University",
  },
  {
    type: "School",
    name: "Carolina Christian College",
  },
  {
    type: "School",
    name: "Winston Salem Barber School",
  },
  {
    type: "School",
    name: "Winston-Salem State University",
  },
  {
    type: "School",
    name: "Western Carolina University",
  },
  {
    type: "School",
    name: "Rasmussen College-North Dakota",
  },
  {
    type: "School",
    name: "Bismarck State College",
  },
  {
    type: "School",
    name: "Dickinson State University",
  },
  {
    type: "School",
    name: "Fort Berthold Community College",
  },
  {
    type: "School",
    name: "Josef's School of Hair Design Inc-Grand Forks",
  },
  {
    type: "School",
    name: "Headquarters Academy of Hair Design Inc",
  },
  {
    type: "School",
    name: "University of Jamestown",
  },
  {
    type: "School",
    name: "Josef's School of Hair Design Inc-Fargo Downtown",
  },
  {
    type: "School",
    name: "Lake Region State College",
  },
  {
    type: "School",
    name: "Cankdeska Cikana Community College",
  },
  {
    type: "School",
    name: "University of Mary",
  },
  {
    type: "School",
    name: "Mayville State University",
  },
  {
    type: "School",
    name: "Sanford College of Nursing",
  },
  {
    type: "School",
    name: "Minot State University",
  },
  {
    type: "School",
    name: "Moler Barber College",
  },
  {
    type: "School",
    name: "University of North Dakota",
  },
  {
    type: "School",
    name: "North Dakota State College of Science",
  },
  {
    type: "School",
    name: "Dakota College at Bottineau",
  },
  {
    type: "School",
    name: "North Dakota State University-Main Campus",
  },
  {
    type: "School",
    name: "Williston State College",
  },
  {
    type: "School",
    name: "JZ Trend Academy Paul Mitchell Partner School",
  },
  {
    type: "School",
    name: "Sitting Bull College",
  },
  {
    type: "School",
    name: "Trinity Bible College",
  },
  {
    type: "School",
    name: "Turtle Mountain Community College",
  },
  {
    type: "School",
    name: "United Tribes Technical College",
  },
  {
    type: "School",
    name: "Valley City State University",
  },
  {
    type: "School",
    name: "ETI Technical College",
  },
  {
    type: "School",
    name: "The Art Institute of Cincinnati",
  },
  {
    type: "School",
    name: "Miami-Jacobs Career College-Independence",
  },
  {
    type: "School",
    name: "Air Force Institute of Technology-Graduate School of Engineering & Management",
  },
  {
    type: "School",
    name: "Akron Institute of Herzing University",
  },
  {
    type: "School",
    name: "University of Akron Main Campus",
  },
  {
    type: "School",
    name: "Akron School of Practical Nursing",
  },
  {
    type: "School",
    name: "University of Akron Wayne College",
  },
  {
    type: "School",
    name: "Allegheny Wesleyan College",
  },
  {
    type: "School",
    name: "Antonelli College-Cincinnati",
  },
  {
    type: "School",
    name: "Apollo Career Center",
  },
  {
    type: "School",
    name: "Art Academy of Cincinnati",
  },
  {
    type: "School",
    name: "Ashland County-West Holmes Career Center",
  },
  {
    type: "School",
    name: "Ashland University",
  },
  {
    type: "School",
    name: "Ashtabula County Technical and Career Campus",
  },
  {
    type: "School",
    name: "Athenaeum of Ohio",
  },
  {
    type: "School",
    name: "Auburn Career Center",
  },
  {
    type: "School",
    name: "Aultman College of Nursing and Health Sciences",
  },
  {
    type: "School",
    name: "Baldwin Wallace University",
  },
  {
    type: "School",
    name: "Belmont College",
  },
  {
    type: "School",
    name: "Bluffton University",
  },
  {
    type: "School",
    name: "Fortis College-Ravenna",
  },
  {
    type: "School",
    name: "Bowling Green State University-Firelands",
  },
  {
    type: "School",
    name: "Bowling Green State University-Main Campus",
  },
  {
    type: "School",
    name: "Bryant & Stratton College-Parma",
  },
  {
    type: "School",
    name: "Buckeye Hills Career Center",
  },
  {
    type: "School",
    name: "Butler Tech-D Russel Lee Career Center",
  },
  {
    type: "School",
    name: "Canton City Schools Adult Career and Technical Education",
  },
  {
    type: "School",
    name: "Capital University",
  },
  {
    type: "School",
    name: "Valley Beauty School",
  },
  {
    type: "School",
    name: "Carousel Beauty College-Middletown",
  },
  {
    type: "School",
    name: "Carousel Beauty College-Dayton",
  },
  {
    type: "School",
    name: "Carousel of Miami Valley Beauty College",
  },
  {
    type: "School",
    name: "Case Western Reserve University",
  },
  {
    type: "School",
    name: "Cedarville University",
  },
  {
    type: "School",
    name: "Central Ohio Technical College",
  },
  {
    type: "School",
    name: "Central School of Practical Nursing",
  },
  {
    type: "School",
    name: "Central State University",
  },
  {
    type: "School",
    name: "Chatfield College",
  },
  {
    type: "School",
    name: "Choffin Career  and Technical Center",
  },
  {
    type: "School",
    name: "The Christ College of Nursing and Health Sciences",
  },
  {
    type: "School",
    name: "Cincinnati Christian University",
  },
  {
    type: "School",
    name: "Cincinnati College of Mortuary Science",
  },
  {
    type: "School",
    name: "University of Cincinnati-Main Campus",
  },
  {
    type: "School",
    name: "Cincinnati State Technical and Community College",
  },
  {
    type: "School",
    name: "University of Cincinnati-Clermont College",
  },
  {
    type: "School",
    name: "University of Cincinnati-Blue Ash College",
  },
  {
    type: "School",
    name: "Ohio Christian University",
  },
  {
    type: "School",
    name: "Clark State Community College",
  },
  {
    type: "School",
    name: "Scott College of Cosmetology",
  },
  {
    type: "School",
    name: "Cleveland Institute of Art",
  },
  {
    type: "School",
    name: "Cleveland Institute of Dental-Medical Assistants-Cleveland",
  },
  {
    type: "School",
    name: "Cleveland Institute of Music",
  },
  {
    type: "School",
    name: "Cleveland State University",
  },
  {
    type: "School",
    name: "Columbiana County Career and Technical Center",
  },
  {
    type: "School",
    name: "Bradford School",
  },
  {
    type: "School",
    name: "Columbus College of Art and Design",
  },
  {
    type: "School",
    name: "Kaplan College-Columbus",
  },
  {
    type: "School",
    name: "Columbus State Community College",
  },
  {
    type: "School",
    name: "Cuyahoga Community College District",
  },
  {
    type: "School",
    name: "Davis College",
  },
  {
    type: "School",
    name: "Dayton Barber College",
  },
  {
    type: "School",
    name: "University of Dayton",
  },
  {
    type: "School",
    name: "Defiance College",
  },
  {
    type: "School",
    name: "Denison University",
  },
  {
    type: "School",
    name: "DeVry University-Ohio",
  },
  {
    type: "School",
    name: "Chancellor University",
  },
  {
    type: "School",
    name: "Eastern Hills Academy of Hair Design",
  },
  {
    type: "School",
    name: "Edison State Community College",
  },
  {
    type: "School",
    name: "EHOVE Career Center",
  },
  {
    type: "School",
    name: "Bryant & Stratton College-Cleveland",
  },
  {
    type: "School",
    name: "Regency Beauty Institute-Cleveland",
  },
  {
    type: "School",
    name: "The University of Findlay",
  },
  {
    type: "School",
    name: "Franklin University",
  },
  {
    type: "School",
    name: "Gerbers Akron Beauty School",
  },
  {
    type: "School",
    name: "Gods Bible School and College",
  },
  {
    type: "School",
    name: "Good Samaritan College of Nursing and Health Science",
  },
  {
    type: "School",
    name: "Great Oaks Institute of Technology and Career Development",
  },
  {
    type: "School",
    name: "Hannah E Mullins School of Practical Nursing",
  },
  {
    type: "School",
    name: "Adult and Community Education-Hudson",
  },
  {
    type: "School",
    name: "Hebrew Union College-Jewish Institute of Religion",
  },
  {
    type: "School",
    name: "Heidelberg University",
  },
  {
    type: "School",
    name: "Hiram College",
  },
  {
    type: "School",
    name: "Hobart Institute of Welding Technology",
  },
  {
    type: "School",
    name: "Hocking College",
  },
  {
    type: "School",
    name: "American School of Technology",
  },
  {
    type: "School",
    name: "Ross Medical Education Center-Cincinnati",
  },
  {
    type: "School",
    name: "International College of Broadcasting",
  },
  {
    type: "School",
    name: "ITT Technical Institute-Dayton",
  },
  {
    type: "School",
    name: "Eastern Gateway Community College",
  },
  {
    type: "School",
    name: "John Carroll University",
  },
  {
    type: "School",
    name: "Hondros College",
  },
  {
    type: "School",
    name: "Casal Aveda Institute",
  },
  {
    type: "School",
    name: "Kent State University at Ashtabula",
  },
  {
    type: "School",
    name: "Kent State University at East Liverpool",
  },
  {
    type: "School",
    name: "Kent State University at Stark",
  },
  {
    type: "School",
    name: "Kent State University at Trumbull",
  },
  {
    type: "School",
    name: "Kent State University at Tuscarawas",
  },
  {
    type: "School",
    name: "Kent State University at Salem",
  },
  {
    type: "School",
    name: "Kent State University at Kent",
  },
  {
    type: "School",
    name: "Kent State University at Geauga",
  },
  {
    type: "School",
    name: "Kenyon College",
  },
  {
    type: "School",
    name: "Kettering College",
  },
  {
    type: "School",
    name: "Lake Erie College",
  },
  {
    type: "School",
    name: "Lakeland Community College",
  },
  {
    type: "School",
    name: "O C Collins Career Center",
  },
  {
    type: "School",
    name: "James A Rhodes State College",
  },
  {
    type: "School",
    name: "Ohio Business College-Sheffield",
  },
  {
    type: "School",
    name: "Ohio Business College-Sandusky",
  },
  {
    type: "School",
    name: "Lorain County Community College",
  },
  {
    type: "School",
    name: "Lourdes University",
  },
  {
    type: "School",
    name: "Malone University",
  },
  {
    type: "School",
    name: "Marietta College",
  },
  {
    type: "School",
    name: "Marion Technical College",
  },
  {
    type: "School",
    name: "Medina County Career Center",
  },
  {
    type: "School",
    name: "Mercy College of Ohio",
  },
  {
    type: "School",
    name: "Methodist Theological School in Ohio",
  },
  {
    type: "School",
    name: "Miami University-Hamilton",
  },
  {
    type: "School",
    name: "Miami University-Middletown",
  },
  {
    type: "School",
    name: "Miami University-Oxford",
  },
  {
    type: "School",
    name: "Miami-Jacobs Career College-Dayton",
  },
  {
    type: "School",
    name: "Moler Hollywood Beauty Academy",
  },
  {
    type: "School",
    name: "Miami Valley Career Technology Center",
  },
  {
    type: "School",
    name: "Mount Carmel College of Nursing",
  },
  {
    type: "School",
    name: "University of Mount Union",
  },
  {
    type: "School",
    name: "Mount Vernon Nazarene University",
  },
  {
    type: "School",
    name: "College of Mount St Joseph",
  },
  {
    type: "School",
    name: "Zane State College",
  },
  {
    type: "School",
    name: "Muskingum University",
  },
  {
    type: "School",
    name: "Fortis College-Cuyahoga Falls",
  },
  {
    type: "School",
    name: "Brown Mackie College-North Canton",
  },
  {
    type: "School",
    name: "National Beauty College",
  },
  {
    type: "School",
    name: "North Central State College",
  },
  {
    type: "School",
    name: "Northern Institute of Cosmetology",
  },
  {
    type: "School",
    name: "Northwest State Community College",
  },
  {
    type: "School",
    name: "Toledo Public Schools Adult and Continuing Education",
  },
  {
    type: "School",
    name: "Notre Dame College",
  },
  {
    type: "School",
    name: "Northeast Ohio Medical University",
  },
  {
    type: "School",
    name: "University of Northwestern Ohio",
  },
  {
    type: "School",
    name: "Oberlin College",
  },
  {
    type: "School",
    name: "Ohio College of Massotherapy Inc",
  },
  {
    type: "School",
    name: "Ohio Technical College",
  },
  {
    type: "School",
    name: "Ohio Dominican University",
  },
  {
    type: "School",
    name: "Kaplan College-Dayton",
  },
  {
    type: "School",
    name: "Ohio Northern University",
  },
  {
    type: "School",
    name: "Ohio State University Agricultural Technical Institute",
  },
  {
    type: "School",
    name: "Ohio State University-Lima Campus",
  },
  {
    type: "School",
    name: "Ohio State University-Mansfield Campus",
  },
  {
    type: "School",
    name: "Ohio State University-Marion Campus",
  },
  {
    type: "School",
    name: "Ohio State University-Newark Campus",
  },
  {
    type: "School",
    name: "Ohio State Beauty Academy",
  },
  {
    type: "School",
    name: "Ohio State College of Barber Styling",
  },
  {
    type: "School",
    name: "Ohio State School of Cosmetology & Experts Barber School",
  },
  {
    type: "School",
    name: "The Spa School",
  },
  {
    type: "School",
    name: "Ohio State School of Cosmetology-Canal Winchester",
  },
  {
    type: "School",
    name: "Ohio State University-Main Campus",
  },
  {
    type: "School",
    name: "Ohio University-Eastern Campus",
  },
  {
    type: "School",
    name: "Ohio University-Chillicothe Campus",
  },
  {
    type: "School",
    name: "Ohio University-Southern Campus",
  },
  {
    type: "School",
    name: "Ohio University-Lancaster Campus",
  },
  {
    type: "School",
    name: "Ohio University-Main Campus",
  },
  {
    type: "School",
    name: "Ohio University-Zanesville Campus",
  },
  {
    type: "School",
    name: "Ohio Valley College of Technology",
  },
  {
    type: "School",
    name: "Trinity Health System School of Nursing",
  },
  {
    type: "School",
    name: "Ohio Wesleyan University",
  },
  {
    type: "School",
    name: "Otterbein University",
  },
  {
    type: "School",
    name: "Owens Community College",
  },
  {
    type: "School",
    name: "Paramount Beauty Academy",
  },
  {
    type: "School",
    name: "Cuyahoga Valley Career Center",
  },
  {
    type: "School",
    name: "Payne Theological Seminary",
  },
  {
    type: "School",
    name: "Pontifical College Josephinum",
  },
  {
    type: "School",
    name: "Professional Skills Institute",
  },
  {
    type: "School",
    name: "Firelands Regional Medical Center School of Nursing",
  },
  {
    type: "School",
    name: "Rabbinical College Telshe",
  },
  {
    type: "School",
    name: "Raphael's School of Beauty Culture Inc-Niles",
  },
  {
    type: "School",
    name: "Fortis College-Centerville",
  },
  {
    type: "School",
    name: "University of Rio Grande",
  },
  {
    type: "School",
    name: "Advertising Art Educational Services DBA School of Advertising Art",
  },
  {
    type: "School",
    name: "Shawnee State University",
  },
  {
    type: "School",
    name: "Sinclair Community College",
  },
  {
    type: "School",
    name: "Gallipolis Career College",
  },
  {
    type: "School",
    name: "Daymar College-New Boston",
  },
  {
    type: "School",
    name: "Daymar College-Jackson",
  },
  {
    type: "School",
    name: "Daymar College-Lancaster",
  },
  {
    type: "School",
    name: "Daymar College-Chillicothe",
  },
  {
    type: "School",
    name: "Brown Mackie College-Cincinnati",
  },
  {
    type: "School",
    name: "Brown Mackie College-Akron",
  },
  {
    type: "School",
    name: "Lincoln College of Technology-Franklin LCT",
  },
  {
    type: "School",
    name: "Lincoln College of Technology-Tri-County",
  },
  {
    type: "School",
    name: "Lincoln College of Technology-Vine Street",
  },
  {
    type: "School",
    name: "Lincoln College of Technology-Dayton",
  },
  {
    type: "School",
    name: "Stark State College",
  },
  {
    type: "School",
    name: "Stautzenberger College-Maumee",
  },
  {
    type: "School",
    name: "Franciscan University of Steubenville",
  },
  {
    type: "School",
    name: "Southern State Community College",
  },
  {
    type: "School",
    name: "Ohio Mid-Western College",
  },
  {
    type: "School",
    name: "Terra State Community College",
  },
  {
    type: "School",
    name: "Tiffin Academy of Hair Design",
  },
  {
    type: "School",
    name: "Tiffin University",
  },
  {
    type: "School",
    name: "University of Toledo",
  },
  {
    type: "School",
    name: "Kaplan Career Institute-Cleveland",
  },
  {
    type: "School",
    name: "Tri-State Bible College",
  },
  {
    type: "School",
    name: "Tri-County Adult Career Center",
  },
  {
    type: "School",
    name: "Tri-Rivers Career Center",
  },
  {
    type: "School",
    name: "Trinity Lutheran Seminary",
  },
  {
    type: "School",
    name: "Trumbull Business College",
  },
  {
    type: "School",
    name: "Union Institute & University",
  },
  {
    type: "School",
    name: "United Theological Seminary",
  },
  {
    type: "School",
    name: "Upper Valley Career Center",
  },
  {
    type: "School",
    name: "Urbana University",
  },
  {
    type: "School",
    name: "Ursuline College",
  },
  {
    type: "School",
    name: "Valley Beauty School",
  },
  {
    type: "School",
    name: "Virginia Marti College of Art and Design",
  },
  {
    type: "School",
    name: "Cut Beauty School",
  },
  {
    type: "School",
    name: "W Howard Nicol School of Practical Nursing",
  },
  {
    type: "School",
    name: "Walsh University",
  },
  {
    type: "School",
    name: "Washington State Community College",
  },
  {
    type: "School",
    name: "Western Hills School of Beauty and Hair Design",
  },
  {
    type: "School",
    name: "Wilberforce University",
  },
  {
    type: "School",
    name: "Wilmington College",
  },
  {
    type: "School",
    name: "Winebrenner Theological Seminary",
  },
  {
    type: "School",
    name: "Wittenberg University",
  },
  {
    type: "School",
    name: "The College of Wooster",
  },
  {
    type: "School",
    name: "Wright State University-Main Campus",
  },
  {
    type: "School",
    name: "Wright State University-Lake Campus",
  },
  {
    type: "School",
    name: "Xavier University",
  },
  {
    type: "School",
    name: "ITT Technical Institute-Youngstown",
  },
  {
    type: "School",
    name: "Youngstown State University",
  },
  {
    type: "School",
    name: "American Broadcasting School-Oklahoma City",
  },
  {
    type: "School",
    name: "Bacone College",
  },
  {
    type: "School",
    name: "Oklahoma Wesleyan University",
  },
  {
    type: "School",
    name: "Southern Nazarene University",
  },
  {
    type: "School",
    name: "Broken Arrow Beauty College-Broken Arrow",
  },
  {
    type: "School",
    name: "Pontotoc Technology Center",
  },
  {
    type: "School",
    name: "Cameron University",
  },
  {
    type: "School",
    name: "Carl Albert State College",
  },
  {
    type: "School",
    name: "Central State Beauty Academy",
  },
  {
    type: "School",
    name: "University of Central Oklahoma",
  },
  {
    type: "School",
    name: "Claremore Beauty College",
  },
  {
    type: "School",
    name: "Connors State College",
  },
  {
    type: "School",
    name: "East Central University",
  },
  {
    type: "School",
    name: "Eastern Oklahoma State College",
  },
  {
    type: "School",
    name: "Redlands Community College",
  },
  {
    type: "School",
    name: "Enid Beauty College",
  },
  {
    type: "School",
    name: "Eves College of Hairstyling",
  },
  {
    type: "School",
    name: "Hillsdale Free Will Baptist College",
  },
  {
    type: "School",
    name: "Hollywood Cosmetology Center",
  },
  {
    type: "School",
    name: "Langston University",
  },
  {
    type: "School",
    name: "Murray State College",
  },
  {
    type: "School",
    name: "Spartan College of Aeronautics and Technology",
  },
  {
    type: "School",
    name: "Northeastern State University",
  },
  {
    type: "School",
    name: "Beauty Technical College Inc",
  },
  {
    type: "School",
    name: "Northern Oklahoma College",
  },
  {
    type: "School",
    name: "Northeastern Oklahoma A&M College",
  },
  {
    type: "School",
    name: "Northwestern Oklahoma State University",
  },
  {
    type: "School",
    name: "Oklahoma State University Center for Health Sciences",
  },
  {
    type: "School",
    name: "Oklahoma Christian University",
  },
  {
    type: "School",
    name: "University of Oklahoma-Health Sciences Center",
  },
  {
    type: "School",
    name: "Oklahoma Panhandle State University",
  },
  {
    type: "School",
    name: "Oklahoma State University-Main Campus",
  },
  {
    type: "School",
    name: "Oklahoma State University-Oklahoma City",
  },
  {
    type: "School",
    name: "Oklahoma Baptist University",
  },
  {
    type: "School",
    name: "Oklahoma City Community College",
  },
  {
    type: "School",
    name: "Oklahoma City University",
  },
  {
    type: "School",
    name: "University of Oklahoma-Norman Campus",
  },
  {
    type: "School",
    name: "Oklahoma School of Photography",
  },
  {
    type: "School",
    name: "Oklahoma State University Institute of Technology",
  },
  {
    type: "School",
    name: "Oral Roberts University",
  },
  {
    type: "School",
    name: "Tulsa Technology Center-Peoria Campus",
  },
  {
    type: "School",
    name: "Platt College-Central OKC",
  },
  {
    type: "School",
    name: "Rogers State University",
  },
  {
    type: "School",
    name: "Rose State College",
  },
  {
    type: "School",
    name: "Saint Gregory's University",
  },
  {
    type: "School",
    name: "Standard Beauty College of Oklahoma",
  },
  {
    type: "School",
    name: "University of Science and Arts of Oklahoma",
  },
  {
    type: "School",
    name: "Seminole State College",
  },
  {
    type: "School",
    name: "Southern School of Beauty Inc",
  },
  {
    type: "School",
    name: "Southeastern Oklahoma State University",
  },
  {
    type: "School",
    name: "Southwestern Christian University",
  },
  {
    type: "School",
    name: "Southwestern Oklahoma State University",
  },
  {
    type: "School",
    name: "Tulsa Community College",
  },
  {
    type: "School",
    name: "Tulsa Welding School-Tulsa",
  },
  {
    type: "School",
    name: "University of Tulsa",
  },
  {
    type: "School",
    name: "Virgil's Beauty College",
  },
  {
    type: "School",
    name: "Indian Capital Technology Center-Tahlequah",
  },
  {
    type: "School",
    name: "Western Oklahoma State College",
  },
  {
    type: "School",
    name: "Woodward Beauty College",
  },
  {
    type: "School",
    name: "High Plains Technology Center",
  },
  {
    type: "School",
    name: "Academy of Hair Design-Salem",
  },
  {
    type: "School",
    name: "Abdill Career College Inc",
  },
  {
    type: "School",
    name: "Paul Mitchell the School-Portland",
  },
  {
    type: "School",
    name: "The Art Institute of Portland",
  },
  {
    type: "School",
    name: "Beau Monde College of Hair Design",
  },
  {
    type: "School",
    name: "Blue Mountain Community College",
  },
  {
    type: "School",
    name: "Central Oregon Community College",
  },
  {
    type: "School",
    name: "Chemeketa Community College",
  },
  {
    type: "School",
    name: "Clackamas Community College",
  },
  {
    type: "School",
    name: "Clatsop Community College",
  },
  {
    type: "School",
    name: "College of Hair Design Careers",
  },
  {
    type: "School",
    name: "Concorde Career College-Portland",
  },
  {
    type: "School",
    name: "Concordia University-Portland",
  },
  {
    type: "School",
    name: "Sumner College",
  },
  {
    type: "School",
    name: "East West College of the Healing Arts",
  },
  {
    type: "School",
    name: "Eastern Oregon University",
  },
  {
    type: "School",
    name: "Phagans School of Hair Design-Portland",
  },
  {
    type: "School",
    name: "New Hope Christian College-Eugene",
  },
  {
    type: "School",
    name: "George Fox University",
  },
  {
    type: "School",
    name: "Phagans Grants Pass College of Beauty",
  },
  {
    type: "School",
    name: "ITT Technical Institute-Portland",
  },
  {
    type: "School",
    name: "Lane Community College",
  },
  {
    type: "School",
    name: "Lewis & Clark College",
  },
  {
    type: "School",
    name: "Linfield College-McMinnville Campus",
  },
  {
    type: "School",
    name: "Linn-Benton Community College",
  },
  {
    type: "School",
    name: "Northwest College-Beaverton",
  },
  {
    type: "School",
    name: "Marylhurst University",
  },
  {
    type: "School",
    name: "Phagans Medford Beauty School",
  },
  {
    type: "School",
    name: "Northwest College-Clackamas",
  },
  {
    type: "School",
    name: "Mount Angel Seminary",
  },
  {
    type: "School",
    name: "Mt Hood Community College",
  },
  {
    type: "School",
    name: "Multnomah University",
  },
  {
    type: "School",
    name: "National College of Natural Medicine",
  },
  {
    type: "School",
    name: "Northwest Nannies Institute",
  },
  {
    type: "School",
    name: "Northwest Christian University",
  },
  {
    type: "School",
    name: "Oregon University System",
  },
  {
    type: "School",
    name: "Oregon Health & Science University",
  },
  {
    type: "School",
    name: "Oregon Institute of Technology",
  },
  {
    type: "School",
    name: "Oregon College of Art and Craft",
  },
  {
    type: "School",
    name: "Oregon State University",
  },
  {
    type: "School",
    name: "University of Oregon",
  },
  {
    type: "School",
    name: "Pacific Northwest College of Art",
  },
  {
    type: "School",
    name: "Pacific University",
  },
  {
    type: "School",
    name: "Phagans Beauty College",
  },
  {
    type: "School",
    name: "Phagans Central Oregon Beauty College",
  },
  {
    type: "School",
    name: "Phagans School of Beauty",
  },
  {
    type: "School",
    name: "Phagans School of Hair Design",
  },
  {
    type: "School",
    name: "Phagans Tigard Beauty School",
  },
  {
    type: "School",
    name: "Portland Community College",
  },
  {
    type: "School",
    name: "Portland State University",
  },
  {
    type: "School",
    name: "University of Portland",
  },
  {
    type: "School",
    name: "Reed College",
  },
  {
    type: "School",
    name: "Rogue Community College",
  },
  {
    type: "School",
    name: "Roseburg Beauty College",
  },
  {
    type: "School",
    name: "Pioneer Pacific College",
  },
  {
    type: "School",
    name: "Springfield College of Beauty",
  },
  {
    type: "School",
    name: "Southern Oregon University",
  },
  {
    type: "School",
    name: "Southwestern Oregon Community College",
  },
  {
    type: "School",
    name: "Treasure Valley Community College",
  },
  {
    type: "School",
    name: "Umpqua Community College",
  },
  {
    type: "School",
    name: "Warner Pacific College",
  },
  {
    type: "School",
    name: "Corban University",
  },
  {
    type: "School",
    name: "College of Cosmetology",
  },
  {
    type: "School",
    name: "Everest College-Portland",
  },
  {
    type: "School",
    name: "Western Seminary",
  },
  {
    type: "School",
    name: "Willamette University",
  },
  {
    type: "School",
    name: "Western Oregon University",
  },
  {
    type: "School",
    name: "University of Western States",
  },
  {
    type: "School",
    name: "Abington Memorial Hospital Dixon School of Nursing",
  },
  {
    type: "School",
    name: "Jolie Hair and Beauty Academy-Hazleton",
  },
  {
    type: "School",
    name: "Keystone Technical Institute",
  },
  {
    type: "School",
    name: "Bryn Athyn College of the New Church",
  },
  {
    type: "School",
    name: "Academy of Vocal Arts",
  },
  {
    type: "School",
    name: "Albright College",
  },
  {
    type: "School",
    name: "All-State Career School-Lester",
  },
  {
    type: "School",
    name: "Community College of Allegheny County",
  },
  {
    type: "School",
    name: "Allegheny College",
  },
  {
    type: "School",
    name: "The Vision Academy",
  },
  {
    type: "School",
    name: "DeSales University",
  },
  {
    type: "School",
    name: "Altoona Beauty School Inc",
  },
  {
    type: "School",
    name: "Alvernia University",
  },
  {
    type: "School",
    name: "Magnolia School",
  },
  {
    type: "School",
    name: "American College of Financial Services",
  },
  {
    type: "School",
    name: "Antonelli Institute",
  },
  {
    type: "School",
    name: "The Art Institutes of York-PA",
  },
  {
    type: "School",
    name: "The Art Institute of Philadelphia",
  },
  {
    type: "School",
    name: "Career Training Academy-New Kensington",
  },
  {
    type: "School",
    name: "The Art Institute of Pittsburgh",
  },
  {
    type: "School",
    name: "Automotive Training Center-Exton",
  },
  {
    type: "School",
    name: "Baptist Bible College & Seminary of Pennsylvania",
  },
  {
    type: "School",
    name: "Community College of Beaver County",
  },
  {
    type: "School",
    name: "Arcadia University",
  },
  {
    type: "School",
    name: "Biblical Theological Seminary",
  },
  {
    type: "School",
    name: "Bidwell Training Center Inc",
  },
  {
    type: "School",
    name: "Bloomsburg University of Pennsylvania",
  },
  {
    type: "School",
    name: "Bradford School",
  },
  {
    type: "School",
    name: "Bryn Mawr College",
  },
  {
    type: "School",
    name: "Bucknell University",
  },
  {
    type: "School",
    name: "Bucks County Community College",
  },
  {
    type: "School",
    name: "Buck's County School of Beauty Culture Inc",
  },
  {
    type: "School",
    name: "Butler Beauty Academy",
  },
  {
    type: "School",
    name: "Butler County Community College",
  },
  {
    type: "School",
    name: "Cabrini College",
  },
  {
    type: "School",
    name: "California University of Pennsylvania",
  },
  {
    type: "School",
    name: "Calvary Baptist Theological Seminary",
  },
  {
    type: "School",
    name: "Cambria-Rowe Business College-Johnstown",
  },
  {
    type: "School",
    name: "Carlow University",
  },
  {
    type: "School",
    name: "Carnegie Mellon University",
  },
  {
    type: "School",
    name: "Cedar Crest College",
  },
  {
    type: "School",
    name: "Central Penn College",
  },
  {
    type: "School",
    name: "Chatham University",
  },
  {
    type: "School",
    name: "Chestnut Hill College",
  },
  {
    type: "School",
    name: "Cheyney University of Pennsylvania",
  },
  {
    type: "School",
    name: "Kaplan Career Institute-Franklin Mills",
  },
  {
    type: "School",
    name: "Citizens School of Nursing",
  },
  {
    type: "School",
    name: "Clarion University of Pennsylvania",
  },
  {
    type: "School",
    name: "YTI Career Institute-Capital Region",
  },
  {
    type: "School",
    name: "Conemaugh Valley Memorial Hospital",
  },
  {
    type: "School",
    name: "Consolidated School of Business-York",
  },
  {
    type: "School",
    name: "Curtis Institute of Music",
  },
  {
    type: "School",
    name: "Dean Institute of Technology",
  },
  {
    type: "School",
    name: "Delaware County Community College",
  },
  {
    type: "School",
    name: "Delaware Valley College",
  },
  {
    type: "School",
    name: "Dickinson College",
  },
  {
    type: "School",
    name: "The Dickinson School of Law of the Pennsylvania State University",
  },
  {
    type: "School",
    name: "Douglas Education Center",
  },
  {
    type: "School",
    name: "Drexel University",
  },
  {
    type: "School",
    name: "Du Bois Business College-Du Bois",
  },
  {
    type: "School",
    name: "Everest Institute-Pittsburgh",
  },
  {
    type: "School",
    name: "Duquesne University",
  },
  {
    type: "School",
    name: "East Stroudsburg University of Pennsylvania",
  },
  {
    type: "School",
    name: "Eastern University",
  },
  {
    type: "School",
    name: "Edinboro University of Pennsylvania",
  },
  {
    type: "School",
    name: "Elizabethtown College",
  },
  {
    type: "School",
    name: "Empire Beauty School-State College",
  },
  {
    type: "School",
    name: "Empire Beauty School-Lebanon",
  },
  {
    type: "School",
    name: "Empire Beauty School-York",
  },
  {
    type: "School",
    name: "Empire Beauty School-Lancaster",
  },
  {
    type: "School",
    name: "Empire Beauty School-Williamsport",
  },
  {
    type: "School",
    name: "Empire Beauty School-Shamokin Dam",
  },
  {
    type: "School",
    name: "Empire Beauty School-Pottsville",
  },
  {
    type: "School",
    name: "Empire Beauty  School-Lehigh Valley",
  },
  {
    type: "School",
    name: "Empire Beauty School-Gwinnett",
  },
  {
    type: "School",
    name: "Empire Beauty School-Wyoming Valley",
  },
  {
    type: "School",
    name: "Empire Beauty School-Warminster",
  },
  {
    type: "School",
    name: "Empire Beauty School-Harrisburg",
  },
  {
    type: "School",
    name: "Empire Beauty School-Reading",
  },
  {
    type: "School",
    name: "Erie Business Center-Erie",
  },
  {
    type: "School",
    name: "Erie Institute of Technology Inc",
  },
  {
    type: "School",
    name: "Evangelical Theological Seminary",
  },
  {
    type: "School",
    name: "Faith Theological Seminary",
  },
  {
    type: "School",
    name: "Aria Health School of Nursing",
  },
  {
    type: "School",
    name: "Franklin and Marshall College",
  },
  {
    type: "School",
    name: "Gannon University",
  },
  {
    type: "School",
    name: "Geneva College",
  },
  {
    type: "School",
    name: "Gettysburg College",
  },
  {
    type: "School",
    name: "Empire Beauty School-Exton",
  },
  {
    type: "School",
    name: "Empire Beauty School-Laurel Springs",
  },
  {
    type: "School",
    name: "Empire Beauty School-NE Philadelphia",
  },
  {
    type: "School",
    name: "Gratz College",
  },
  {
    type: "School",
    name: "Grove City College",
  },
  {
    type: "School",
    name: "Gwynedd Mercy University",
  },
  {
    type: "School",
    name: "Harcum College",
  },
  {
    type: "School",
    name: "Harrisburg Area Community College-Harrisburg",
  },
  {
    type: "School",
    name: "Haverford College",
  },
  {
    type: "School",
    name: "Commonwealth Technical Institute",
  },
  {
    type: "School",
    name: "Holy Family University",
  },
  {
    type: "School",
    name: "Hussian School of Art",
  },
  {
    type: "School",
    name: "Kaplan Career Institute-Pittsburgh",
  },
  {
    type: "School",
    name: "Immaculata University",
  },
  {
    type: "School",
    name: "Indiana University of Pennsylvania-Main Campus",
  },
  {
    type: "School",
    name: "Pennsylvania School of Business",
  },
  {
    type: "School",
    name: "Jameson Health System",
  },
  {
    type: "School",
    name: "Great Lakes Institute of Technology",
  },
  {
    type: "School",
    name: "Jolie Hair and Beauty Academy-Wilkes-Barre",
  },
  {
    type: "School",
    name: "Johnson College",
  },
  {
    type: "School",
    name: "Juniata College",
  },
  {
    type: "School",
    name: "Keystone College",
  },
  {
    type: "School",
    name: "Anthem Institute-Springfield",
  },
  {
    type: "School",
    name: "King's College",
  },
  {
    type: "School",
    name: "Kittanning Beauty School",
  },
  {
    type: "School",
    name: "Kutztown University of Pennsylvania",
  },
  {
    type: "School",
    name: "La Roche College",
  },
  {
    type: "School",
    name: "La Salle University",
  },
  {
    type: "School",
    name: "Lackawanna College",
  },
  {
    type: "School",
    name: "Lafayette College",
  },
  {
    type: "School",
    name: "Lancaster Bible College",
  },
  {
    type: "School",
    name: "Lancaster Theological Seminary",
  },
  {
    type: "School",
    name: "Lancaster School of Cosmetology",
  },
  {
    type: "School",
    name: "Lansdale School of Business",
  },
  {
    type: "School",
    name: "Lansdale School of Cosmetology Inc",
  },
  {
    type: "School",
    name: "Lebanon Valley College",
  },
  {
    type: "School",
    name: "Lehigh Carbon Community College",
  },
  {
    type: "School",
    name: "Berks Technical Institute",
  },
  {
    type: "School",
    name: "Lehigh University",
  },
  {
    type: "School",
    name: "Levittown Beauty Academy",
  },
  {
    type: "School",
    name: "Lincoln Technical Institute-Allentown",
  },
  {
    type: "School",
    name: "Lincoln Technical Institute-Philadelphia",
  },
  {
    type: "School",
    name: "Lincoln University of Pennsylvania",
  },
  {
    type: "School",
    name: "Lock Haven University",
  },
  {
    type: "School",
    name: "St Margaret School of Nursing",
  },
  {
    type: "School",
    name: "Lutheran Theological Seminary at Gettysburg",
  },
  {
    type: "School",
    name: "Lutheran Theological Seminary at Philadelphia",
  },
  {
    type: "School",
    name: "Luzerne County Community College",
  },
  {
    type: "School",
    name: "Lycoming College",
  },
  {
    type: "School",
    name: "Erie Business Center-New Castle",
  },
  {
    type: "School",
    name: "Manor College",
  },
  {
    type: "School",
    name: "Mansfield University of Pennsylvania",
  },
  {
    type: "School",
    name: "Marywood University",
  },
  {
    type: "School",
    name: "Vet Tech Institute",
  },
  {
    type: "School",
    name: "Roxborough Memorial Hospital School of Nursing",
  },
  {
    type: "School",
    name: "Mercyhurst University",
  },
  {
    type: "School",
    name: "Messiah College",
  },
  {
    type: "School",
    name: "Metropolitan Career Center Computer Technology Institute",
  },
  {
    type: "School",
    name: "Millersville University of Pennsylvania",
  },
  {
    type: "School",
    name: "Misericordia University",
  },
  {
    type: "School",
    name: "Montgomery County Community College",
  },
  {
    type: "School",
    name: "Moore College of Art and Design",
  },
  {
    type: "School",
    name: "Moravian College",
  },
  {
    type: "School",
    name: "Mount Aloysius College",
  },
  {
    type: "School",
    name: "Muhlenberg College",
  },
  {
    type: "School",
    name: "Kaplan Career Institute-Philadelphia",
  },
  {
    type: "School",
    name: "Neumann University",
  },
  {
    type: "School",
    name: "New Castle School of Trades",
  },
  {
    type: "School",
    name: "Newport Business Institute-Lower Burrell",
  },
  {
    type: "School",
    name: "Northampton County Area Community College",
  },
  {
    type: "School",
    name: "Pittsburgh's Ohio Valley Hospital School of Nursing",
  },
  {
    type: "School",
    name: "Orleans Technical Institute",
  },
  {
    type: "School",
    name: "Pennsylvania Academy of Cosmetology Arts and Sciences-Johnstown",
  },
  {
    type: "School",
    name: "Salus University",
  },
  {
    type: "School",
    name: "Pennsylvania Institute of Technology",
  },
  {
    type: "School",
    name: "Pennsylvania State University-Penn State Erie-Behrend College",
  },
  {
    type: "School",
    name: "Pennsylvania State University-Penn State Great Valley",
  },
  {
    type: "School",
    name: "Pennsylvania State University-College of Medicine",
  },
  {
    type: "School",
    name: "Pennsylvania State University-Penn State New Kensington",
  },
  {
    type: "School",
    name: "Pennsylvania State University-Penn State Shenango",
  },
  {
    type: "School",
    name: "Pennsylvania State University-Penn State Wilkes-Barre",
  },
  {
    type: "School",
    name: "Pennsylvania State University-Penn State Worthington Scranton",
  },
  {
    type: "School",
    name: "Pennsylvania State System of Higher Education-Central Office",
  },
  {
    type: "School",
    name: "Pennsylvania State University-Penn State Lehigh Valley",
  },
  {
    type: "School",
    name: "Pennsylvania State University-Penn State Altoona",
  },
  {
    type: "School",
    name: "Pennsylvania State University-Penn State Beaver",
  },
  {
    type: "School",
    name: "Pennsylvania State University-Penn State Berks",
  },
  {
    type: "School",
    name: "Pennsylvania State University-Penn State Harrisburg",
  },
  {
    type: "School",
    name: "Pennsylvania State University-Penn State Brandywine",
  },
  {
    type: "School",
    name: "Pennsylvania State University-Penn State Dubois",
  },
  {
    type: "School",
    name: "Pennsylvania State University-Penn State Fayette- Eberly",
  },
  {
    type: "School",
    name: "Pennsylvania State University-Penn State Hazleton",
  },
  {
    type: "School",
    name: "Pennsylvania State University-Main Campus",
  },
  {
    type: "School",
    name: "Pennsylvania State University-Penn State Greater Allegheny",
  },
  {
    type: "School",
    name: "Pennsylvania State University-Penn State Mont Alto",
  },
  {
    type: "School",
    name: "Pennsylvania State University-Penn State Abington",
  },
  {
    type: "School",
    name: "Pennsylvania State University-Penn State Schuylkill",
  },
  {
    type: "School",
    name: "Pennsylvania State University-Penn State York",
  },
  {
    type: "School",
    name: "Pace Institute",
  },
  {
    type: "School",
    name: "Peirce College",
  },
  {
    type: "School",
    name: "Penn Commercial Business/Technical School",
  },
  {
    type: "School",
    name: "Pennco Tech-Bristol",
  },
  {
    type: "School",
    name: "Pennsylvania Academy of Cosmetology Arts and Sciences-Du Bois",
  },
  {
    type: "School",
    name: "Pennsylvania Academy of the Fine Arts",
  },
  {
    type: "School",
    name: "Pennsylvania Gunsmith School",
  },
  {
    type: "School",
    name: "Pennsylvania Institute of Taxidermy Inc",
  },
  {
    type: "School",
    name: "Cortiva Institute-Pennsylvania School of Muscle Therapy",
  },
  {
    type: "School",
    name: "Pennsylvania College of Art and Design",
  },
  {
    type: "School",
    name: "University of Pennsylvania",
  },
  {
    type: "School",
    name: "Philadelphia University",
  },
  {
    type: "School",
    name: "The University of the Arts",
  },
  {
    type: "School",
    name: "Cairn University-Langhorne",
  },
  {
    type: "School",
    name: "Philadelphia College of Osteopathic Medicine",
  },
  {
    type: "School",
    name: "University of the Sciences",
  },
  {
    type: "School",
    name: "Community College of Philadelphia",
  },
  {
    type: "School",
    name: "University of Pittsburgh-Bradford",
  },
  {
    type: "School",
    name: "University of Pittsburgh-Greensburg",
  },
  {
    type: "School",
    name: "University of Pittsburgh-Johnstown",
  },
  {
    type: "School",
    name: "University of Pittsburgh-Pittsburgh Campus",
  },
  {
    type: "School",
    name: "University of Pittsburgh-Titusville",
  },
  {
    type: "School",
    name: "Beaver Falls Beauty Academy",
  },
  {
    type: "School",
    name: "Pittsburgh Institute of Aeronautics",
  },
  {
    type: "School",
    name: "Pittsburgh Institute of Mortuary Science Inc",
  },
  {
    type: "School",
    name: "Pittsburgh Technical Institute",
  },
  {
    type: "School",
    name: "Pittsburgh Theological Seminary",
  },
  {
    type: "School",
    name: "Prism Career Institute-Upper Darby",
  },
  {
    type: "School",
    name: "Point Park University",
  },
  {
    type: "School",
    name: "Joseph F McCloskey School of Nursing at Schuylkill Health",
  },
  {
    type: "School",
    name: "The Salon Professional Academy-Altoona",
  },
  {
    type: "School",
    name: "Reading Area Community College",
  },
  {
    type: "School",
    name: "Reading Hospital School of Health Sciences",
  },
  {
    type: "School",
    name: "Reconstructionist Rabbinical College",
  },
  {
    type: "School",
    name: "Reformed Presbyterian Theological Seminary",
  },
  {
    type: "School",
    name: "The Restaurant School at Walnut Hill College",
  },
  {
    type: "School",
    name: "Kaplan Career Institute-Broomall",
  },
  {
    type: "School",
    name: "Robert Morris University",
  },
  {
    type: "School",
    name: "Rosedale Technical Institute",
  },
  {
    type: "School",
    name: "Rosemont College",
  },
  {
    type: "School",
    name: "Saint Francis University",
  },
  {
    type: "School",
    name: "Saint Joseph's University",
  },
  {
    type: "School",
    name: "Saint Vincent College",
  },
  {
    type: "School",
    name: "Saint Vincent Seminary",
  },
  {
    type: "School",
    name: "Schuylkill Technology Center",
  },
  {
    type: "School",
    name: "University of Scranton",
  },
  {
    type: "School",
    name: "Seton Hill University",
  },
  {
    type: "School",
    name: "Heritage Valley Sewickley School of Nursing",
  },
  {
    type: "School",
    name: "University of Pittsburgh Medical Center-Shadyside School of Nursing",
  },
  {
    type: "School",
    name: "Sharon Regional Health System School of Nursing",
  },
  {
    type: "School",
    name: "Laurel Technical Institute",
  },
  {
    type: "School",
    name: "Shippensburg University of Pennsylvania",
  },
  {
    type: "School",
    name: "Slippery Rock University of Pennsylvania",
  },
  {
    type: "School",
    name: "Saint Charles Borromeo Seminary-Overbrook",
  },
  {
    type: "School",
    name: "South Hills Beauty Academy Inc",
  },
  {
    type: "School",
    name: "South Hills School of Business & Technology",
  },
  {
    type: "School",
    name: "St Lukes Hospital School of Nursing",
  },
  {
    type: "School",
    name: "Stroudsburg School of Cosmetology",
  },
  {
    type: "School",
    name: "Susquehanna University",
  },
  {
    type: "School",
    name: "Swarthmore College",
  },
  {
    type: "School",
    name: "Thaddeus Stevens College of Technology",
  },
  {
    type: "School",
    name: "Talmudical Yeshiva of Philadelphia",
  },
  {
    type: "School",
    name: "Temple University",
  },
  {
    type: "School",
    name: "Theological Seminary of the Reformed Episcopal Church",
  },
  {
    type: "School",
    name: "Thiel College",
  },
  {
    type: "School",
    name: "Thomas Jefferson University",
  },
  {
    type: "School",
    name: "Fortis Institute-Erie",
  },
  {
    type: "School",
    name: "Triangle Tech Inc-Erie",
  },
  {
    type: "School",
    name: "Triangle Tech Inc-Pittsburgh",
  },
  {
    type: "School",
    name: "Triangle Tech Inc-Greensburg",
  },
  {
    type: "School",
    name: "Triangle Tech Inc-Dubois",
  },
  {
    type: "School",
    name: "Trinity Episcopal School for Ministry",
  },
  {
    type: "School",
    name: "Ursinus College",
  },
  {
    type: "School",
    name: "Valley Forge Christian College",
  },
  {
    type: "School",
    name: "Valley Forge Military College",
  },
  {
    type: "School",
    name: "Venus Beauty Academy",
  },
  {
    type: "School",
    name: "Villanova University",
  },
  {
    type: "School",
    name: "Washington Hospital School of Nursing",
  },
  {
    type: "School",
    name: "Washington Hospital School of Radiography",
  },
  {
    type: "School",
    name: "Washington & Jefferson College",
  },
  {
    type: "School",
    name: "Waynesburg University",
  },
  {
    type: "School",
    name: "Welder Training and Testing Institute",
  },
  {
    type: "School",
    name: "West Chester University of Pennsylvania",
  },
  {
    type: "School",
    name: "Western Pennsylvania Hospital School of Nursing",
  },
  {
    type: "School",
    name: "Sanford-Brown Institute-Pittsburgh",
  },
  {
    type: "School",
    name: "Westminster College",
  },
  {
    type: "School",
    name: "Westminster Theological Seminary",
  },
  {
    type: "School",
    name: "Westmoreland County Community College",
  },
  {
    type: "School",
    name: "Widener University-Main Campus",
  },
  {
    type: "School",
    name: "Wilkes University",
  },
  {
    type: "School",
    name: "Newport Business Institute-Williamsport",
  },
  {
    type: "School",
    name: "Wilson College",
  },
  {
    type: "School",
    name: "Yeshivath Beth Moshe",
  },
  {
    type: "School",
    name: "York College Pennsylvania",
  },
  {
    type: "School",
    name: "YTI Career Institute-York",
  },
  {
    type: "School",
    name: "Yorktowne Business Institute",
  },
  {
    type: "School",
    name: "Empire Beauty School-Providence",
  },
  {
    type: "School",
    name: "Brown University",
  },
  {
    type: "School",
    name: "Bryant University",
  },
  {
    type: "School",
    name: "Johnson & Wales University-Providence",
  },
  {
    type: "School",
    name: "Sanford-Brown Institute-Cranston",
  },
  {
    type: "School",
    name: "New England Institute of Technology",
  },
  {
    type: "School",
    name: "New England Tractor Trailer Training School of Rhode Island",
  },
  {
    type: "School",
    name: "Newport School of Hairdressing-Main Campus",
  },
  {
    type: "School",
    name: "Providence College",
  },
  {
    type: "School",
    name: "Rhode Island College",
  },
  {
    type: "School",
    name: "Community College of Rhode Island",
  },
  {
    type: "School",
    name: "University of Rhode Island",
  },
  {
    type: "School",
    name: "Rhode Island School of Design",
  },
  {
    type: "School",
    name: "Roger Williams University",
  },
  {
    type: "School",
    name: "Salve Regina University",
  },
  {
    type: "School",
    name: "St Joseph School of Nursing",
  },
  {
    type: "School",
    name: "Empire Beauty School-Warwick",
  },
  {
    type: "School",
    name: "Northpoint Bible College",
  },
  {
    type: "School",
    name: "Aiken Technical College",
  },
  {
    type: "School",
    name: "Allen University",
  },
  {
    type: "School",
    name: "Anderson University",
  },
  {
    type: "School",
    name: "Charleston Southern University",
  },
  {
    type: "School",
    name: "Technical College of the Lowcountry",
  },
  {
    type: "School",
    name: "Benedict College",
  },
  {
    type: "School",
    name: "Bob Jones University",
  },
  {
    type: "School",
    name: "Southern Wesleyan University",
  },
  {
    type: "School",
    name: "Charleston Cosmetology Institute",
  },
  {
    type: "School",
    name: "College of Charleston",
  },
  {
    type: "School",
    name: "Charzanne Beauty College",
  },
  {
    type: "School",
    name: "Northeastern Technical College",
  },
  {
    type: "School",
    name: "Citadel Military College of South Carolina",
  },
  {
    type: "School",
    name: "Claflin University",
  },
  {
    type: "School",
    name: "Clemson University",
  },
  {
    type: "School",
    name: "Clinton College",
  },
  {
    type: "School",
    name: "Coker College",
  },
  {
    type: "School",
    name: "Columbia International University",
  },
  {
    type: "School",
    name: "Columbia College",
  },
  {
    type: "School",
    name: "Converse College",
  },
  {
    type: "School",
    name: "Denmark Technical College",
  },
  {
    type: "School",
    name: "Erskine College",
  },
  {
    type: "School",
    name: "Florence-Darlington Technical College",
  },
  {
    type: "School",
    name: "Forrest College",
  },
  {
    type: "School",
    name: "Francis Marion University",
  },
  {
    type: "School",
    name: "Furman University",
  },
  {
    type: "School",
    name: "Cathedral Bible College",
  },
  {
    type: "School",
    name: "Greenville Technical College",
  },
  {
    type: "School",
    name: "Horry-Georgetown Technical College",
  },
  {
    type: "School",
    name: "Kenneth Shuler School of Cosmetology-North Augusta",
  },
  {
    type: "School",
    name: "Kenneth Shuler School of Cosmetology and Nails-Columbia",
  },
  {
    type: "School",
    name: "Lander University",
  },
  {
    type: "School",
    name: "Limestone College",
  },
  {
    type: "School",
    name: "Lenoir-Rhyne University-Lutheran Theological Southern Seminary",
  },
  {
    type: "School",
    name: "Medical University of South Carolina",
  },
  {
    type: "School",
    name: "Midlands Technical College",
  },
  {
    type: "School",
    name: "Morris College",
  },
  {
    type: "School",
    name: "Newberry College",
  },
  {
    type: "School",
    name: "North Greenville University",
  },
  {
    type: "School",
    name: "Orangeburg Calhoun Technical College",
  },
  {
    type: "School",
    name: "Piedmont Technical College",
  },
  {
    type: "School",
    name: "Presbyterian College",
  },
  {
    type: "School",
    name: "University of South Carolina-Aiken",
  },
  {
    type: "School",
    name: "University of South Carolina-Beaufort",
  },
  {
    type: "School",
    name: "University of South Carolina-Columbia",
  },
  {
    type: "School",
    name: "University of South Carolina-Lancaster",
  },
  {
    type: "School",
    name: "University of South Carolina-Salkehatchie",
  },
  {
    type: "School",
    name: "University of South Carolina-Sumter",
  },
  {
    type: "School",
    name: "University of South Carolina-Union",
  },
  {
    type: "School",
    name: "Coastal Carolina University",
  },
  {
    type: "School",
    name: "South Carolina State University",
  },
  {
    type: "School",
    name: "University of South Carolina-Upstate",
  },
  {
    type: "School",
    name: "Sherman College of Straight Chiropractic",
  },
  {
    type: "School",
    name: "Spartanburg Methodist College",
  },
  {
    type: "School",
    name: "Spartanburg Community College",
  },
  {
    type: "School",
    name: "Central Carolina Technical College",
  },
  {
    type: "School",
    name: "Sumter Beauty College",
  },
  {
    type: "School",
    name: "Tri-County Technical College",
  },
  {
    type: "School",
    name: "Trident Technical College",
  },
  {
    type: "School",
    name: "Voorhees College",
  },
  {
    type: "School",
    name: "Williamsburg Technical College",
  },
  {
    type: "School",
    name: "Winthrop University",
  },
  {
    type: "School",
    name: "Wofford College",
  },
  {
    type: "School",
    name: "York Technical College",
  },
  {
    type: "School",
    name: "Augustana College",
  },
  {
    type: "School",
    name: "Black Hills Beauty College",
  },
  {
    type: "School",
    name: "Black Hills State University",
  },
  {
    type: "School",
    name: "Kilian Community College",
  },
  {
    type: "School",
    name: "Dakota State University",
  },
  {
    type: "School",
    name: "Dakota Wesleyan University",
  },
  {
    type: "School",
    name: "Headlines Academy Inc",
  },
  {
    type: "School",
    name: "Lake Area Technical Institute",
  },
  {
    type: "School",
    name: "Avera McKennan Hospital School of Radiologic Technology",
  },
  {
    type: "School",
    name: "Mitchell Technical Institute",
  },
  {
    type: "School",
    name: "Mount Marty College",
  },
  {
    type: "School",
    name: "National American University-Rapid City",
  },
  {
    type: "School",
    name: "National American University-Sioux Falls",
  },
  {
    type: "School",
    name: "Sioux Falls Seminary",
  },
  {
    type: "School",
    name: "Northern State University",
  },
  {
    type: "School",
    name: "Oglala Lakota College",
  },
  {
    type: "School",
    name: "Presentation College",
  },
  {
    type: "School",
    name: "Avera Sacred Heart Hospital",
  },
  {
    type: "School",
    name: "South Dakota School of Mines and Technology",
  },
  {
    type: "School",
    name: "South Dakota State University",
  },
  {
    type: "School",
    name: "Sinte Gleska University",
  },
  {
    type: "School",
    name: "University of Sioux Falls",
  },
  {
    type: "School",
    name: "Sanford Medical Center",
  },
  {
    type: "School",
    name: "Sisseton Wahpeton College",
  },
  {
    type: "School",
    name: "Southeast Technical Institute",
  },
  {
    type: "School",
    name: "Stewart School",
  },
  {
    type: "School",
    name: "University of South Dakota",
  },
  {
    type: "School",
    name: "Western Dakota Technical Institute",
  },
  {
    type: "School",
    name: "American Baptist College",
  },
  {
    type: "School",
    name: "Aquinas College",
  },
  {
    type: "School",
    name: "Arnolds Beauty School",
  },
  {
    type: "School",
    name: "Tennessee College of Applied Technology-Athens",
  },
  {
    type: "School",
    name: "Austin Peay State University",
  },
  {
    type: "School",
    name: "Baptist Memorial College of Health Sciences",
  },
  {
    type: "School",
    name: "Belmont University",
  },
  {
    type: "School",
    name: "Bethel University",
  },
  {
    type: "School",
    name: "Bryan College-Dayton",
  },
  {
    type: "School",
    name: "Carson-Newman University",
  },
  {
    type: "School",
    name: "Chattanooga State Community College",
  },
  {
    type: "School",
    name: "Christian Brothers University",
  },
  {
    type: "School",
    name: "Pentecostal Theological Seminary",
  },
  {
    type: "School",
    name: "Cleveland State Community College",
  },
  {
    type: "School",
    name: "Columbia State Community College",
  },
  {
    type: "School",
    name: "Concorde Career College-Memphis",
  },
  {
    type: "School",
    name: "Tennessee College of Applied Technology-Covington",
  },
  {
    type: "School",
    name: "Cumberland University",
  },
  {
    type: "School",
    name: "Lipscomb University",
  },
  {
    type: "School",
    name: "Tennessee College of Applied Technology-Dickson",
  },
  {
    type: "School",
    name: "Daymar Institute-Nashville",
  },
  {
    type: "School",
    name: "Dyersburg State Community College",
  },
  {
    type: "School",
    name: "East Tennessee State University",
  },
  {
    type: "School",
    name: "Chattanooga College Medical Dental and Technical Careers",
  },
  {
    type: "School",
    name: "Tennessee College of Applied Technology-Elizabethton",
  },
  {
    type: "School",
    name: "Emmanuel Christian Seminary",
  },
  {
    type: "School",
    name: "Fayetteville College of Cosmetology Arts and Sciences",
  },
  {
    type: "School",
    name: "Fisk University",
  },
  {
    type: "School",
    name: "Welch College",
  },
  {
    type: "School",
    name: "Freed-Hardeman University",
  },
  {
    type: "School",
    name: "Tennessee College of Applied Technology-Harriman",
  },
  {
    type: "School",
    name: "Tennessee College of Applied Technology-Hartsville",
  },
  {
    type: "School",
    name: "Hiwassee College",
  },
  {
    type: "School",
    name: "Tennessee College of Applied Technology-Hohenwald",
  },
  {
    type: "School",
    name: "Tennessee College of Applied Technology-Jacksboro",
  },
  {
    type: "School",
    name: "Jackson State Community College",
  },
  {
    type: "School",
    name: "John A Gupton College",
  },
  {
    type: "School",
    name: "Johnson University",
  },
  {
    type: "School",
    name: "Paul Mitchell the School-Nashville",
  },
  {
    type: "School",
    name: "King University",
  },
  {
    type: "School",
    name: "South College",
  },
  {
    type: "School",
    name: "Knoxville Institute of Hair Design",
  },
  {
    type: "School",
    name: "Lane College",
  },
  {
    type: "School",
    name: "Le Moyne-Owen College",
  },
  {
    type: "School",
    name: "Lee University",
  },
  {
    type: "School",
    name: "Lincoln Memorial University",
  },
  {
    type: "School",
    name: "Tennessee College of Applied Technology-Livingston",
  },
  {
    type: "School",
    name: "Martin Methodist College",
  },
  {
    type: "School",
    name: "Maryville College",
  },
  {
    type: "School",
    name: "Tennessee College of Applied Technology-McKenzie",
  },
  {
    type: "School",
    name: "Genesis Career College-Cookeville",
  },
  {
    type: "School",
    name: "Meharry Medical College",
  },
  {
    type: "School",
    name: "Memphis College of Art",
  },
  {
    type: "School",
    name: "Tennessee College of Applied Technology-Memphis",
  },
  {
    type: "School",
    name: "University of Memphis",
  },
  {
    type: "School",
    name: "Memphis Theological Seminary",
  },
  {
    type: "School",
    name: "Victory University",
  },
  {
    type: "School",
    name: "Middle Tennessee State University",
  },
  {
    type: "School",
    name: "Middle Tennessee School of Anesthesia Inc",
  },
  {
    type: "School",
    name: "Milligan College",
  },
  {
    type: "School",
    name: "Tennessee College of Applied Technology-Morristown",
  },
  {
    type: "School",
    name: "Motlow State Community College",
  },
  {
    type: "School",
    name: "Tennessee College of Applied Technology-Murfreesboro",
  },
  {
    type: "School",
    name: "Lincoln College of Technology-Nashville",
  },
  {
    type: "School",
    name: "Nashville College of Medical Careers",
  },
  {
    type: "School",
    name: "Nashville State Community College",
  },
  {
    type: "School",
    name: "Tennessee College of Applied Technology-Newbern",
  },
  {
    type: "School",
    name: "O'More College of Design",
  },
  {
    type: "School",
    name: "Tennessee College of Applied Technology-Paris",
  },
  {
    type: "School",
    name: "Plaza Beauty School",
  },
  {
    type: "School",
    name: "Tennessee College of Applied Technology-Pulaski",
  },
  {
    type: "School",
    name: "Rhodes College",
  },
  {
    type: "School",
    name: "Tennessee College of Applied Technology-Ripley",
  },
  {
    type: "School",
    name: "Roane State Community College",
  },
  {
    type: "School",
    name: "Tennessee College of Applied Technology-Crump",
  },
  {
    type: "School",
    name: "Southwest Tennessee Community College",
  },
  {
    type: "School",
    name: "Tennessee College of Applied Technology-Shelbyville",
  },
  {
    type: "School",
    name: "Sewanee-The University of the South",
  },
  {
    type: "School",
    name: "Tennessee College of Applied Technology-Oneida-Huntsville",
  },
  {
    type: "School",
    name: "Tennessee College of Applied Technology-Crossville",
  },
  {
    type: "School",
    name: "Tennessee College of Applied Technology-McMinnville",
  },
  {
    type: "School",
    name: "Tennessee College of Applied Technology-Jackson",
  },
  {
    type: "School",
    name: "Tennessee College of Applied Technology-Knoxville",
  },
  {
    type: "School",
    name: "Tennessee College of Applied Technology-Whiteville",
  },
  {
    type: "School",
    name: "Pellissippi State Community College",
  },
  {
    type: "School",
    name: "Southern Adventist University",
  },
  {
    type: "School",
    name: "Southern College of Optometry",
  },
  {
    type: "School",
    name: "Tennessee Wesleyan College",
  },
  {
    type: "School",
    name: "The University of Tennessee-Chattanooga",
  },
  {
    type: "School",
    name: "The University of Tennessee-Knoxville",
  },
  {
    type: "School",
    name: "The University of Tennessee-Martin",
  },
  {
    type: "School",
    name: "Fountainhead College of Technology",
  },
  {
    type: "School",
    name: "Tennessee School of Beauty of Knoxville Inc",
  },
  {
    type: "School",
    name: "Tennessee State University",
  },
  {
    type: "School",
    name: "Tennessee Technological University",
  },
  {
    type: "School",
    name: "Tennessee Temple University",
  },
  {
    type: "School",
    name: "Trevecca Nazarene University",
  },
  {
    type: "School",
    name: "Northeast State Community College",
  },
  {
    type: "School",
    name: "Tusculum College",
  },
  {
    type: "School",
    name: "Union University",
  },
  {
    type: "School",
    name: "New Concepts School of Cosmetology",
  },
  {
    type: "School",
    name: "Vanderbilt University",
  },
  {
    type: "School",
    name: "Volunteer Beauty Academy-Dyersburg",
  },
  {
    type: "School",
    name: "Volunteer State Community College",
  },
  {
    type: "School",
    name: "Walters State Community College",
  },
  {
    type: "School",
    name: "West Tennessee Business College",
  },
  {
    type: "School",
    name: "William Moore College of Technology",
  },
  {
    type: "School",
    name: "Abilene Christian University",
  },
  {
    type: "School",
    name: "Alamo Community College District Central Office",
  },
  {
    type: "School",
    name: "Alvin Community College",
  },
  {
    type: "School",
    name: "Amarillo College",
  },
  {
    type: "School",
    name: "Amberton University",
  },
  {
    type: "School",
    name: "American Commercial College-San Angelo",
  },
  {
    type: "School",
    name: "American Commercial College-Abilene",
  },
  {
    type: "School",
    name: "American Commercial College-Odessa",
  },
  {
    type: "School",
    name: "Angelina College",
  },
  {
    type: "School",
    name: "Angelo State University",
  },
  {
    type: "School",
    name: "Arlington Baptist College",
  },
  {
    type: "School",
    name: "Arlington Career Institute",
  },
  {
    type: "School",
    name: "The Art Institute of Houston",
  },
  {
    type: "School",
    name: "Austin College",
  },
  {
    type: "School",
    name: "Austin Community College District",
  },
  {
    type: "School",
    name: "Austin Presbyterian Theological Seminary",
  },
  {
    type: "School",
    name: "Baldwin Beauty School-South Austin",
  },
  {
    type: "School",
    name: "Baptist Health System School of Health Professions",
  },
  {
    type: "School",
    name: "Baptist Missionary Association Theological Seminary",
  },
  {
    type: "School",
    name: "Texas A & M University Health Science Center",
  },
  {
    type: "School",
    name: "Baylor College of Medicine",
  },
  {
    type: "School",
    name: "Baylor University",
  },
  {
    type: "School",
    name: "Coastal Bend College",
  },
  {
    type: "School",
    name: "Blinn College",
  },
  {
    type: "School",
    name: "Remington College-Dallas Campus",
  },
  {
    type: "School",
    name: "Vet Tech Institute of Houston",
  },
  {
    type: "School",
    name: "Brazosport College",
  },
  {
    type: "School",
    name: "Brookhaven College",
  },
  {
    type: "School",
    name: "Capitol City Trade and Technical School",
  },
  {
    type: "School",
    name: "Capitol City Careers",
  },
  {
    type: "School",
    name: "Cedar Valley College",
  },
  {
    type: "School",
    name: "Central Texas College",
  },
  {
    type: "School",
    name: "Charles and Sues School of Hair Design",
  },
  {
    type: "School",
    name: "Cisco College",
  },
  {
    type: "School",
    name: "Clarendon College",
  },
  {
    type: "School",
    name: "Concordia University-Texas",
  },
  {
    type: "School",
    name: "Conlee's College of Cosmetology",
  },
  {
    type: "School",
    name: "North Central Texas College",
  },
  {
    type: "School",
    name: "Texas A & M University-Corpus Christi",
  },
  {
    type: "School",
    name: "Cosmetology Career Center LLC",
  },
  {
    type: "School",
    name: "Court Reporting Institute of Dallas",
  },
  {
    type: "School",
    name: "Dallas Baptist University",
  },
  {
    type: "School",
    name: "Dallas Christian College",
  },
  {
    type: "School",
    name: "Dallas County Community College District",
  },
  {
    type: "School",
    name: "Dallas Institute of Funeral Service",
  },
  {
    type: "School",
    name: "Dallas Theological Seminary",
  },
  {
    type: "School",
    name: "University of Dallas",
  },
  {
    type: "School",
    name: "Del Mar College",
  },
  {
    type: "School",
    name: "DeVry University-Texas",
  },
  {
    type: "School",
    name: "Career Point College",
  },
  {
    type: "School",
    name: "East Texas Baptist University",
  },
  {
    type: "School",
    name: "Texas A & M University-Texarkana",
  },
  {
    type: "School",
    name: "Texas A & M University-Commerce",
  },
  {
    type: "School",
    name: "Eastfield College",
  },
  {
    type: "School",
    name: "El Centro College",
  },
  {
    type: "School",
    name: "El Paso Community College",
  },
  {
    type: "School",
    name: "Western Technical College",
  },
  {
    type: "School",
    name: "Western Technical College",
  },
  {
    type: "School",
    name: "Episcopal Theological Seminary of the Southwest",
  },
  {
    type: "School",
    name: "Exposito School of Hair Design",
  },
  {
    type: "School",
    name: "South University-The Art Institute of Dallas",
  },
  {
    type: "School",
    name: "Fort Worth Beauty School",
  },
  {
    type: "School",
    name: "Frank Phillips College",
  },
  {
    type: "School",
    name: "Franklin Beauty School",
  },
  {
    type: "School",
    name: "Galveston College",
  },
  {
    type: "School",
    name: "Grayson College",
  },
  {
    type: "School",
    name: "Hallmark College",
  },
  {
    type: "School",
    name: "Hardin-Simmons University",
  },
  {
    type: "School",
    name: "Trinity Valley Community College",
  },
  {
    type: "School",
    name: "Hill College",
  },
  {
    type: "School",
    name: "Houston Baptist University",
  },
  {
    type: "School",
    name: "University of Houston-Clear Lake",
  },
  {
    type: "School",
    name: "Houston Community College",
  },
  {
    type: "School",
    name: "University of Houston-Downtown",
  },
  {
    type: "School",
    name: "University of Houston-Victoria",
  },
  {
    type: "School",
    name: "University of Houston",
  },
  {
    type: "School",
    name: "Howard College",
  },
  {
    type: "School",
    name: "Howard Payne University",
  },
  {
    type: "School",
    name: "Huston-Tillotson University",
  },
  {
    type: "School",
    name: "University of the Incarnate Word",
  },
  {
    type: "School",
    name: "International Business College-El Paso",
  },
  {
    type: "School",
    name: "International Business College-El Paso",
  },
  {
    type: "School",
    name: "International Business College-El Paso",
  },
  {
    type: "School",
    name: "ITT Technical Institute-Arlington",
  },
  {
    type: "School",
    name: "ITT Technical Institute-Houston West",
  },
  {
    type: "School",
    name: "Jacksonville College-Main Campus",
  },
  {
    type: "School",
    name: "Jarvis Christian College",
  },
  {
    type: "School",
    name: "KD College Conservatory of Film and Dramatic Arts",
  },
  {
    type: "School",
    name: "Kilgore College",
  },
  {
    type: "School",
    name: "Lamar University",
  },
  {
    type: "School",
    name: "Lamar State College-Orange",
  },
  {
    type: "School",
    name: "Lamar State College-Port Arthur",
  },
  {
    type: "School",
    name: "Laredo Beauty College Inc",
  },
  {
    type: "School",
    name: "Laredo Community College",
  },
  {
    type: "School",
    name: "Texas A & M International University",
  },
  {
    type: "School",
    name: "Lee College",
  },
  {
    type: "School",
    name: "LeTourneau University",
  },
  {
    type: "School",
    name: "Lincoln College of Technology-Grand Prairie",
  },
  {
    type: "School",
    name: "Lubbock Hair Academy",
  },
  {
    type: "School",
    name: "Lubbock Christian University",
  },
  {
    type: "School",
    name: "College of the Mainland",
  },
  {
    type: "School",
    name: "University of Mary Hardin-Baylor",
  },
  {
    type: "School",
    name: "McLennan Community College",
  },
  {
    type: "School",
    name: "McMurry University",
  },
  {
    type: "School",
    name: "Covenant School of Nursing and Allied Health",
  },
  {
    type: "School",
    name: "Metroplex Beauty School",
  },
  {
    type: "School",
    name: "Midland College",
  },
  {
    type: "School",
    name: "Midwestern State University",
  },
  {
    type: "School",
    name: "Mims Classic Beauty College",
  },
  {
    type: "School",
    name: "Wade College",
  },
  {
    type: "School",
    name: "Southern Careers Institute-Austin",
  },
  {
    type: "School",
    name: "Mountain View College",
  },
  {
    type: "School",
    name: "Everest Institute-San Antonio",
  },
  {
    type: "School",
    name: "Tint School of Makeup and Cosmetology-Irving",
  },
  {
    type: "School",
    name: "Navarro College",
  },
  {
    type: "School",
    name: "Neilson Beauty College",
  },
  {
    type: "School",
    name: "Lone Star College System",
  },
  {
    type: "School",
    name: "North Lake College",
  },
  {
    type: "School",
    name: "University of North Texas",
  },
  {
    type: "School",
    name: "Northeast Texas Community College",
  },
  {
    type: "School",
    name: "Northwood University-Texas",
  },
  {
    type: "School",
    name: "Oblate School of Theology",
  },
  {
    type: "School",
    name: "Ocean Corporation",
  },
  {
    type: "School",
    name: "Odessa College",
  },
  {
    type: "School",
    name: "Ogle School Hair Skin Nails-Arlington",
  },
  {
    type: "School",
    name: "Ogle School Hair Skin Nails-Ft Worth",
  },
  {
    type: "School",
    name: "Our Lady of the Lake University",
  },
  {
    type: "School",
    name: "The University of Texas-Pan American",
  },
  {
    type: "School",
    name: "The University of Texas at Brownsville",
  },
  {
    type: "School",
    name: "Panola College",
  },
  {
    type: "School",
    name: "Paris Junior College",
  },
  {
    type: "School",
    name: "Paul Quinn College",
  },
  {
    type: "School",
    name: "Prairie View A & M University",
  },
  {
    type: "School",
    name: "Ranger College",
  },
  {
    type: "School",
    name: "Aviation Institute of Maintenance-Houston",
  },
  {
    type: "School",
    name: "Rice University",
  },
  {
    type: "School",
    name: "Richland College",
  },
  {
    type: "School",
    name: "Kaplan College-McAllen",
  },
  {
    type: "School",
    name: "Saint Edward's University",
  },
  {
    type: "School",
    name: "St Philip's College",
  },
  {
    type: "School",
    name: "University of St Thomas",
  },
  {
    type: "School",
    name: "Sam Houston State University",
  },
  {
    type: "School",
    name: "San Antonio College",
  },
  {
    type: "School",
    name: "San Jacinto Community College",
  },
  {
    type: "School",
    name: "Schreiner University",
  },
  {
    type: "School",
    name: "Seguin Beauty School-Seguin",
  },
  {
    type: "School",
    name: "St Mary's University",
  },
  {
    type: "School",
    name: "South Plains College",
  },
  {
    type: "School",
    name: "South Texas Barber College Inc",
  },
  {
    type: "School",
    name: "South Texas College of Law",
  },
  {
    type: "School",
    name: "South Texas Vo-Tech Institute",
  },
  {
    type: "School",
    name: "South Texas Vocational Technical Institute-McAllen",
  },
  {
    type: "School",
    name: "Southern Methodist University",
  },
  {
    type: "School",
    name: "Southwest School of Business and Technical Careers-San Antonio",
  },
  {
    type: "School",
    name: "Southwest Institute of Technology",
  },
  {
    type: "School",
    name: "Southwest Texas Junior College",
  },
  {
    type: "School",
    name: "Southwestern Assemblies of God University",
  },
  {
    type: "School",
    name: "Southwestern University",
  },
  {
    type: "School",
    name: "Stephen F Austin State University",
  },
  {
    type: "School",
    name: "Texas State University",
  },
  {
    type: "School",
    name: "Southwestern Adventist University",
  },
  {
    type: "School",
    name: "Southwestern Christian College",
  },
  {
    type: "School",
    name: "Sul Ross State University",
  },
  {
    type: "School",
    name: "Tarleton State University",
  },
  {
    type: "School",
    name: "Tarrant County College District",
  },
  {
    type: "School",
    name: "Temple College",
  },
  {
    type: "School",
    name: "University of Texas Southwestern Medical Center",
  },
  {
    type: "School",
    name: "The University of Texas Health Science Center at San Antonio",
  },
  {
    type: "School",
    name: "The University of Texas Medical Branch",
  },
  {
    type: "School",
    name: "Texas State Technical College-System",
  },
  {
    type: "School",
    name: "Texas State Technical College-Waco",
  },
  {
    type: "School",
    name: "Texarkana College",
  },
  {
    type: "School",
    name: "Texas A & M University-Kingsville",
  },
  {
    type: "School",
    name: "Texas A & M University-Galveston",
  },
  {
    type: "School",
    name: "Texas A & M University-College Station",
  },
  {
    type: "School",
    name: "Texas A & M University-System Office",
  },
  {
    type: "School",
    name: "The University of Texas at Arlington",
  },
  {
    type: "School",
    name: "The University of Texas at Austin",
  },
  {
    type: "School",
    name: "The University of Texas at Dallas",
  },
  {
    type: "School",
    name: "The University of Texas at El Paso",
  },
  {
    type: "School",
    name: "The University of Texas at Tyler",
  },
  {
    type: "School",
    name: "Texas Chiropractic College Foundation Inc",
  },
  {
    type: "School",
    name: "Texas Christian University",
  },
  {
    type: "School",
    name: "Texas College",
  },
  {
    type: "School",
    name: "University of North Texas Health Science Center",
  },
  {
    type: "School",
    name: "Texas Lutheran University",
  },
  {
    type: "School",
    name: "The University of Texas of the Permian Basin",
  },
  {
    type: "School",
    name: "The University of Texas at San Antonio",
  },
  {
    type: "School",
    name: "Texas School of Business-North Campus",
  },
  {
    type: "School",
    name: "Texas Southern University",
  },
  {
    type: "School",
    name: "The University of Texas System Office",
  },
  {
    type: "School",
    name: "Texas Tech University",
  },
  {
    type: "School",
    name: "Texas Vocational Schools Inc",
  },
  {
    type: "School",
    name: "Texas Wesleyan University",
  },
  {
    type: "School",
    name: "Texas Woman's University",
  },
  {
    type: "School",
    name: "Trinity University",
  },
  {
    type: "School",
    name: "The University of Texas Health Science Center at Houston",
  },
  {
    type: "School",
    name: "Texas State Technical College-Harlingen",
  },
  {
    type: "School",
    name: "Texas State Technical College-West Texas",
  },
  {
    type: "School",
    name: "Texas Tech University Health Sciences Center",
  },
  {
    type: "School",
    name: "Tyler Junior College",
  },
  {
    type: "School",
    name: "University of Houston-System Administration",
  },
  {
    type: "School",
    name: "Universal Technical Institute of Texas Inc.",
  },
  {
    type: "School",
    name: "University of Cosmetology Arts & Sciences-McAllen",
  },
  {
    type: "School",
    name: "Vernon College",
  },
  {
    type: "School",
    name: "Victoria Beauty College Inc",
  },
  {
    type: "School",
    name: "Victoria College",
  },
  {
    type: "School",
    name: "Vogue College of Cosmetology-McAllen",
  },
  {
    type: "School",
    name: "Wayland Baptist University",
  },
  {
    type: "School",
    name: "Weatherford College",
  },
  {
    type: "School",
    name: "West Texas A & M University",
  },
  {
    type: "School",
    name: "Western Texas College",
  },
  {
    type: "School",
    name: "Wharton County Junior College",
  },
  {
    type: "School",
    name: "Wiley College",
  },
  {
    type: "School",
    name: "AmeriTech College-Provo",
  },
  {
    type: "School",
    name: "Bridgerland Applied Technology College",
  },
  {
    type: "School",
    name: "Brigham Young University-Provo",
  },
  {
    type: "School",
    name: "Brigham Young University-Hawaii",
  },
  {
    type: "School",
    name: "Broadview University-West Jordan",
  },
  {
    type: "School",
    name: "Cameo College of Essential Beauty",
  },
  {
    type: "School",
    name: "Sherman Kendall Academy-Salt Lake City",
  },
  {
    type: "School",
    name: "Sherman Kendall Academy-Midvale",
  },
  {
    type: "School",
    name: "Davis Applied Technology College",
  },
  {
    type: "School",
    name: "Dixie State University",
  },
  {
    type: "School",
    name: "Evans Hairstyling College-Cedar City",
  },
  {
    type: "School",
    name: "Evans Hairstyling College-St George",
  },
  {
    type: "School",
    name: "Paul Mitchell the School-Salt Lake City",
  },
  {
    type: "School",
    name: "Eagle Gate College-Murray",
  },
  {
    type: "School",
    name: "ITT Technical Institute-Murray",
  },
  {
    type: "School",
    name: "Latter-day Saints Business College",
  },
  {
    type: "School",
    name: "Everest College-Salt Lake City",
  },
  {
    type: "School",
    name: "Ogden-Weber Applied Technology College",
  },
  {
    type: "School",
    name: "Snow College",
  },
  {
    type: "School",
    name: "Southern Utah University",
  },
  {
    type: "School",
    name: "Stevens-Henager College-Ogden",
  },
  {
    type: "School",
    name: "Stevens-Henager College of Business-Provo",
  },
  {
    type: "School",
    name: "Uintah Basin Applied Technology College",
  },
  {
    type: "School",
    name: "Utah State University",
  },
  {
    type: "School",
    name: "Utah Valley University",
  },
  {
    type: "School",
    name: "Salt Lake Community College",
  },
  {
    type: "School",
    name: "University of Utah",
  },
  {
    type: "School",
    name: "Weber State University",
  },
  {
    type: "School",
    name: "Westminster College",
  },
  {
    type: "School",
    name: "Bennington College",
  },
  {
    type: "School",
    name: "Burlington College",
  },
  {
    type: "School",
    name: "Castleton State College",
  },
  {
    type: "School",
    name: "Champlain College",
  },
  {
    type: "School",
    name: "Community College of Vermont",
  },
  {
    type: "School",
    name: "Goddard College",
  },
  {
    type: "School",
    name: "Green Mountain College",
  },
  {
    type: "School",
    name: "Johnson State College",
  },
  {
    type: "School",
    name: "Lyndon State College",
  },
  {
    type: "School",
    name: "Marlboro College",
  },
  {
    type: "School",
    name: "Middlebury College",
  },
  {
    type: "School",
    name: "New England Culinary Institute",
  },
  {
    type: "School",
    name: "Norwich University",
  },
  {
    type: "School",
    name: "O'Briens Aveda Institute",
  },
  {
    type: "School",
    name: "Saint Michael's College",
  },
  {
    type: "School",
    name: "SIT Graduate Institute",
  },
  {
    type: "School",
    name: "College of St Joseph",
  },
  {
    type: "School",
    name: "Southern Vermont College",
  },
  {
    type: "School",
    name: "Sterling College",
  },
  {
    type: "School",
    name: "Vermont Law School",
  },
  {
    type: "School",
    name: "Vermont State Colleges-Office of the Chancellor",
  },
  {
    type: "School",
    name: "Vermont Technical College",
  },
  {
    type: "School",
    name: "University of Vermont",
  },
  {
    type: "School",
    name: "Bar Palma Beauty Careers Academy",
  },
  {
    type: "School",
    name: "Advanced Technology Institute",
  },
  {
    type: "School",
    name: "Averett University",
  },
  {
    type: "School",
    name: "Blue Ridge Community College",
  },
  {
    type: "School",
    name: "Bluefield College",
  },
  {
    type: "School",
    name: "Bridgewater College",
  },
  {
    type: "School",
    name: "College of William and Mary",
  },
  {
    type: "School",
    name: "Regent University",
  },
  {
    type: "School",
    name: "Central School of Practical Nursing",
  },
  {
    type: "School",
    name: "Central Virginia Community College",
  },
  {
    type: "School",
    name: "Christopher Newport University",
  },
  {
    type: "School",
    name: "Suffolk Beauty Academy",
  },
  {
    type: "School",
    name: "Bryant & Stratton College-Virginia Beach",
  },
  {
    type: "School",
    name: "Bryant & Stratton College-Richmond",
  },
  {
    type: "School",
    name: "Jefferson College of Health Sciences",
  },
  {
    type: "School",
    name: "Dabney S Lancaster Community College",
  },
  {
    type: "School",
    name: "Danville Community College",
  },
  {
    type: "School",
    name: "Eastern Virginia Medical School",
  },
  {
    type: "School",
    name: "Centura College-Virginia Beach",
  },
  {
    type: "School",
    name: "Emory & Henry College",
  },
  {
    type: "School",
    name: "Eastern Mennonite University",
  },
  {
    type: "School",
    name: "Eastern Shore Community College",
  },
  {
    type: "School",
    name: "Ferrum College",
  },
  {
    type: "School",
    name: "George Mason University",
  },
  {
    type: "School",
    name: "Germanna Community College",
  },
  {
    type: "School",
    name: "Hampden-Sydney College",
  },
  {
    type: "School",
    name: "Hampton University",
  },
  {
    type: "School",
    name: "Hollins University",
  },
  {
    type: "School",
    name: "J Sargeant Reynolds Community College",
  },
  {
    type: "School",
    name: "James Madison University",
  },
  {
    type: "School",
    name: "John Tyler Community College",
  },
  {
    type: "School",
    name: "Everest College-Newport News",
  },
  {
    type: "School",
    name: "Liberty University",
  },
  {
    type: "School",
    name: "Longwood University",
  },
  {
    type: "School",
    name: "Lord Fairfax Community College",
  },
  {
    type: "School",
    name: "Lynchburg College",
  },
  {
    type: "School",
    name: "Lynchburg General Hospital School of Nursing",
  },
  {
    type: "School",
    name: "Mary Baldwin College",
  },
  {
    type: "School",
    name: "University of Mary Washington",
  },
  {
    type: "School",
    name: "Marymount University",
  },
  {
    type: "School",
    name: "Danville Regional Medical Center School of Health Professions",
  },
  {
    type: "School",
    name: "Mountain Empire Community College",
  },
  {
    type: "School",
    name: "American National University",
  },
  {
    type: "School",
    name: "New River Community College",
  },
  {
    type: "School",
    name: "Sentara College of Health Sciences",
  },
  {
    type: "School",
    name: "Tidewater Tech-Trades",
  },
  {
    type: "School",
    name: "Norfolk State University",
  },
  {
    type: "School",
    name: "Northern Virginia Community College",
  },
  {
    type: "School",
    name: "Old Dominion University",
  },
  {
    type: "School",
    name: "Patrick Henry Community College",
  },
  {
    type: "School",
    name: "Paul D Camp Community College",
  },
  {
    type: "School",
    name: "Southside Regional Medical Center Professional Schools",
  },
  {
    type: "School",
    name: "Miller-Motte Technical College-Lynchburg",
  },
  {
    type: "School",
    name: "Piedmont Virginia Community College",
  },
  {
    type: "School",
    name: "Radford University",
  },
  {
    type: "School",
    name: "Sylvain Melloul International Hair Academy",
  },
  {
    type: "School",
    name: "Randolph-Macon College",
  },
  {
    type: "School",
    name: "Randolph College",
  },
  {
    type: "School",
    name: "Rappahannock Community College",
  },
  {
    type: "School",
    name: "Fortis College-Norfolk",
  },
  {
    type: "School",
    name: "Richard Bland College of the College of William and Mary",
  },
  {
    type: "School",
    name: "Bon Secours Memorial College of Nursing",
  },
  {
    type: "School",
    name: "University of Richmond",
  },
  {
    type: "School",
    name: "Riverside School of Health Careers",
  },
  {
    type: "School",
    name: "Roanoke College",
  },
  {
    type: "School",
    name: "Bon Secours St Mary's Hospital School of Medical Imaging",
  },
  {
    type: "School",
    name: "Saint Pauls College",
  },
  {
    type: "School",
    name: "Shenandoah University",
  },
  {
    type: "School",
    name: "Southern Virginia University",
  },
  {
    type: "School",
    name: "Southside Virginia Community College",
  },
  {
    type: "School",
    name: "Southwest Virginia Community College",
  },
  {
    type: "School",
    name: "Springfield Beauty Academy",
  },
  {
    type: "School",
    name: "Staunton School of Cosmetology",
  },
  {
    type: "School",
    name: "Strayer University-Virginia",
  },
  {
    type: "School",
    name: "Sweet Briar College",
  },
  {
    type: "School",
    name: "Thomas Nelson Community College",
  },
  {
    type: "School",
    name: "Tidewater Community College",
  },
  {
    type: "School",
    name: "Union Presbyterian Seminary",
  },
  {
    type: "School",
    name: "The University of Virginia's College at Wise",
  },
  {
    type: "School",
    name: "Virginia Highlands Community College",
  },
  {
    type: "School",
    name: "Virginia Intermont College",
  },
  {
    type: "School",
    name: "Virginia Polytechnic Institute and State University",
  },
  {
    type: "School",
    name: "Virginia Western Community College",
  },
  {
    type: "School",
    name: "Virginia Commonwealth University",
  },
  {
    type: "School",
    name: "University of Virginia-Main Campus",
  },
  {
    type: "School",
    name: "Virginia Military Institute",
  },
  {
    type: "School",
    name: "Virginia School of Hair Design",
  },
  {
    type: "School",
    name: "Virginia University of Lynchburg",
  },
  {
    type: "School",
    name: "Virginia State University",
  },
  {
    type: "School",
    name: "Virginia Union University",
  },
  {
    type: "School",
    name: "Virginia Wesleyan College",
  },
  {
    type: "School",
    name: "Wards Corner Beauty Academy-Norfolk",
  },
  {
    type: "School",
    name: "Washington and Lee University",
  },
  {
    type: "School",
    name: "Sanford-Brown College-Tysons Corner",
  },
  {
    type: "School",
    name: "Washington County Adult Skill Center",
  },
  {
    type: "School",
    name: "Woodrow Wilson Rehabilitation Center",
  },
  {
    type: "School",
    name: "Wytheville Community College",
  },
  {
    type: "School",
    name: "Beauty Academy",
  },
  {
    type: "School",
    name: "The Art Institute of Seattle",
  },
  {
    type: "School",
    name: "Evergreen Beauty and Barber College-Bellevue",
  },
  {
    type: "School",
    name: "Bellevue College",
  },
  {
    type: "School",
    name: "Bellingham Beauty School",
  },
  {
    type: "School",
    name: "Bellingham Technical College",
  },
  {
    type: "School",
    name: "Big Bend Community College",
  },
  {
    type: "School",
    name: "Everest College-Bremerton",
  },
  {
    type: "School",
    name: "Everest College-Renton",
  },
  {
    type: "School",
    name: "Central Washington University",
  },
  {
    type: "School",
    name: "Centralia College",
  },
  {
    type: "School",
    name: "City University of Seattle",
  },
  {
    type: "School",
    name: "Lucas Marc Academy",
  },
  {
    type: "School",
    name: "Clark College",
  },
  {
    type: "School",
    name: "Clover Park Technical College",
  },
  {
    type: "School",
    name: "Columbia Basin College",
  },
  {
    type: "School",
    name: "Cornish College of the Arts",
  },
  {
    type: "School",
    name: "Divers Institute of Technology",
  },
  {
    type: "School",
    name: "Eastern Washington University",
  },
  {
    type: "School",
    name: "Edmonds Community College",
  },
  {
    type: "School",
    name: "Everett Community College",
  },
  {
    type: "School",
    name: "Paroba College of Cosmetology",
  },
  {
    type: "School",
    name: "The Evergreen State College",
  },
  {
    type: "School",
    name: "Pierce College at Fort Steilacoom",
  },
  {
    type: "School",
    name: "Glen Dow Academy of Hair Design",
  },
  {
    type: "School",
    name: "Gonzaga University",
  },
  {
    type: "School",
    name: "Grays Harbor College",
  },
  {
    type: "School",
    name: "Green River Community College",
  },
  {
    type: "School",
    name: "Toni & Guy Hairdressing Academy-Shoreline",
  },
  {
    type: "School",
    name: "Heritage University",
  },
  {
    type: "School",
    name: "Highline Community College",
  },
  {
    type: "School",
    name: "Interface College-Spokane",
  },
  {
    type: "School",
    name: "International Air and Hospitality Academy",
  },
  {
    type: "School",
    name: "ITT Technical Institute-Seattle",
  },
  {
    type: "School",
    name: "ITT Technical Institute-Spokane Valley",
  },
  {
    type: "School",
    name: "Bastyr University",
  },
  {
    type: "School",
    name: "Gene Juarez Academy of Beauty-Mountlake Terrace",
  },
  {
    type: "School",
    name: "Bates Technical College",
  },
  {
    type: "School",
    name: "Lake Washington Institute of Technology",
  },
  {
    type: "School",
    name: "Lower Columbia College",
  },
  {
    type: "School",
    name: "Trinity Lutheran College",
  },
  {
    type: "School",
    name: "Cascade Beauty College",
  },
  {
    type: "School",
    name: "BJ's Beauty & Barber College",
  },
  {
    type: "School",
    name: "Northwest Hair Academy",
  },
  {
    type: "School",
    name: "Seattle Community College-North Campus",
  },
  {
    type: "School",
    name: "Northwest University",
  },
  {
    type: "School",
    name: "Olympic College",
  },
  {
    type: "School",
    name: "Perry Technical Institute",
  },
  {
    type: "School",
    name: "Pacific Lutheran University",
  },
  {
    type: "School",
    name: "Peninsula College",
  },
  {
    type: "School",
    name: "University of Puget Sound",
  },
  {
    type: "School",
    name: "Renton Technical College",
  },
  {
    type: "School",
    name: "Saint Martin's University",
  },
  {
    type: "School",
    name: "Seattle Community College-South Campus",
  },
  {
    type: "School",
    name: "Seattle Central College",
  },
  {
    type: "School",
    name: "Everest College-Seattle",
  },
  {
    type: "School",
    name: "Seattle Pacific University",
  },
  {
    type: "School",
    name: "Seattle University",
  },
  {
    type: "School",
    name: "Shoreline Community College",
  },
  {
    type: "School",
    name: "Skagit Valley College",
  },
  {
    type: "School",
    name: "South Puget Sound Community College",
  },
  {
    type: "School",
    name: "Spokane Community College",
  },
  {
    type: "School",
    name: "Spokane Falls Community College",
  },
  {
    type: "School",
    name: "Stylemaster College of Hair Design",
  },
  {
    type: "School",
    name: "Tacoma Community College",
  },
  {
    type: "School",
    name: "The Hair School",
  },
  {
    type: "School",
    name: "Walla Walla Community College",
  },
  {
    type: "School",
    name: "Walla Walla University",
  },
  {
    type: "School",
    name: "Washington State University",
  },
  {
    type: "School",
    name: "University of Washington-Seattle Campus",
  },
  {
    type: "School",
    name: "Wenatchee Valley College",
  },
  {
    type: "School",
    name: "Everest College-Vancouver",
  },
  {
    type: "School",
    name: "Western Washington University",
  },
  {
    type: "School",
    name: "Whatcom Community College",
  },
  {
    type: "School",
    name: "Whitman College",
  },
  {
    type: "School",
    name: "Whitworth University",
  },
  {
    type: "School",
    name: "Yakima Valley Community College",
  },
  {
    type: "School",
    name: "Alderson Broaddus University",
  },
  {
    type: "School",
    name: "Appalachian Beauty School",
  },
  {
    type: "School",
    name: "Appalachian Bible College",
  },
  {
    type: "School",
    name: "B M Spurr School of Practical Nursing",
  },
  {
    type: "School",
    name: "Ben Franklin Career Center",
  },
  {
    type: "School",
    name: "Bethany College",
  },
  {
    type: "School",
    name: "Bluefield State College",
  },
  {
    type: "School",
    name: "Cabell County Career Technology Center",
  },
  {
    type: "School",
    name: "Charleston School of Beauty Culture",
  },
  {
    type: "School",
    name: "University of Charleston",
  },
  {
    type: "School",
    name: "Clarksburg Beauty Academy and School of Massage Therapy",
  },
  {
    type: "School",
    name: "Concord University",
  },
  {
    type: "School",
    name: "Davis & Elkins College",
  },
  {
    type: "School",
    name: "Fairmont State University",
  },
  {
    type: "School",
    name: "Glenville State College",
  },
  {
    type: "School",
    name: "HRDE-Stanley Technical Institute-Clarksburg",
  },
  {
    type: "School",
    name: "Huntington Junior College",
  },
  {
    type: "School",
    name: "James Rumsey Technical Institute",
  },
  {
    type: "School",
    name: "Ralph R Willis Career and Technical Center",
  },
  {
    type: "School",
    name: "Human Resource Development and Employment Inc",
  },
  {
    type: "School",
    name: "Marshall University",
  },
  {
    type: "School",
    name: "Mercer County Technical Education Center",
  },
  {
    type: "School",
    name: "Meredith Manor International Equestrian Center",
  },
  {
    type: "School",
    name: "Monongalia County Technical Education Center",
  },
  {
    type: "School",
    name: "Morgantown Beauty College Inc",
  },
  {
    type: "School",
    name: "Mountain State College",
  },
  {
    type: "School",
    name: "Everest Institute-Cross Lanes",
  },
  {
    type: "School",
    name: "Opportunities Industrialization Center",
  },
  {
    type: "School",
    name: "Ohio Valley University",
  },
  {
    type: "School",
    name: "West Virginia University at Parkersburg",
  },
  {
    type: "School",
    name: "Potomac State College of West Virginia University",
  },
  {
    type: "School",
    name: "Academy of Careers and Technology",
  },
  {
    type: "School",
    name: "Salem International University",
  },
  {
    type: "School",
    name: "Shepherd University",
  },
  {
    type: "School",
    name: "Southern West Virginia Community and Technical College",
  },
  {
    type: "School",
    name: "Fred W Eberle Technical Center",
  },
  {
    type: "School",
    name: "West Virginia School of Osteopathic Medicine",
  },
  {
    type: "School",
    name: "West Virginia State University",
  },
  {
    type: "School",
    name: "West Virginia University Hospital Departments of Rad Tech and Nutrition",
  },
  {
    type: "School",
    name: "West Liberty University",
  },
  {
    type: "School",
    name: "West Virginia University Institute of Technology",
  },
  {
    type: "School",
    name: "West Virginia Wesleyan College",
  },
  {
    type: "School",
    name: "West Virginia Business College-Wheeling",
  },
  {
    type: "School",
    name: "West Virginia Junior College-Charleston",
  },
  {
    type: "School",
    name: "West Virginia Junior College-Morgantown",
  },
  {
    type: "School",
    name: "International Beauty School 4",
  },
  {
    type: "School",
    name: "West Virginia Northern Community College",
  },
  {
    type: "School",
    name: "West Virginia University",
  },
  {
    type: "School",
    name: "Wheeling Jesuit University",
  },
  {
    type: "School",
    name: "Wood County School of Practical Nursing",
  },
  {
    type: "School",
    name: "Advanced Institute of Hair Design-Glendale",
  },
  {
    type: "School",
    name: "VICI Aveda Institute",
  },
  {
    type: "School",
    name: "Alverno College",
  },
  {
    type: "School",
    name: "Madison Area Technical College",
  },
  {
    type: "School",
    name: "Bellin College",
  },
  {
    type: "School",
    name: "Beloit College",
  },
  {
    type: "School",
    name: "Blackhawk Technical College",
  },
  {
    type: "School",
    name: "Cardinal Stritch University",
  },
  {
    type: "School",
    name: "Carroll University",
  },
  {
    type: "School",
    name: "Carthage College",
  },
  {
    type: "School",
    name: "Columbia College of Nursing",
  },
  {
    type: "School",
    name: "Concordia University-Wisconsin",
  },
  {
    type: "School",
    name: "Edgewood College",
  },
  {
    type: "School",
    name: "Fox Valley Technical College",
  },
  {
    type: "School",
    name: "Gateway Technical College",
  },
  {
    type: "School",
    name: "Gill-Tech Academy of Hair Design",
  },
  {
    type: "School",
    name: "The Salon Professional Academy-Eau Claire",
  },
  {
    type: "School",
    name: "ITT Technical Institute-Greenfield",
  },
  {
    type: "School",
    name: "DeVry University-Wisconsin",
  },
  {
    type: "School",
    name: "Lakeland College",
  },
  {
    type: "School",
    name: "Lakeshore Technical College",
  },
  {
    type: "School",
    name: "Lawrence University",
  },
  {
    type: "School",
    name: "Maranatha Baptist University",
  },
  {
    type: "School",
    name: "Marian University",
  },
  {
    type: "School",
    name: "Marquette University",
  },
  {
    type: "School",
    name: "Empire Beauty School-Madison",
  },
  {
    type: "School",
    name: "Medical College of Wisconsin",
  },
  {
    type: "School",
    name: "Mid-State Technical College",
  },
  {
    type: "School",
    name: "Milwaukee Area Technical College",
  },
  {
    type: "School",
    name: "Milwaukee Institute of Art & Design",
  },
  {
    type: "School",
    name: "Milwaukee School of Engineering",
  },
  {
    type: "School",
    name: "Moraine Park Technical College",
  },
  {
    type: "School",
    name: "Mount Mary University",
  },
  {
    type: "School",
    name: "Nashotah House",
  },
  {
    type: "School",
    name: "Nicolet Area Technical College",
  },
  {
    type: "School",
    name: "Northcentral Technical College",
  },
  {
    type: "School",
    name: "Northeast Wisconsin Technical College",
  },
  {
    type: "School",
    name: "Northland International University",
  },
  {
    type: "School",
    name: "Northland College",
  },
  {
    type: "School",
    name: "Ripon College",
  },
  {
    type: "School",
    name: "Sacred Heart School of Theology",
  },
  {
    type: "School",
    name: "Saint Norbert College",
  },
  {
    type: "School",
    name: "Silver Lake College of the Holy Family",
  },
  {
    type: "School",
    name: "State College of Beauty Culture Inc",
  },
  {
    type: "School",
    name: "Southwest Wisconsin Technical College",
  },
  {
    type: "School",
    name: "Bryant & Stratton College-Milwaukee",
  },
  {
    type: "School",
    name: "University of Wisconsin Colleges",
  },
  {
    type: "School",
    name: "Viterbo University",
  },
  {
    type: "School",
    name: "Chippewa Valley Technical College",
  },
  {
    type: "School",
    name: "Waukesha County Technical College",
  },
  {
    type: "School",
    name: "Western Technical College",
  },
  {
    type: "School",
    name: "University of Wisconsin-Whitewater",
  },
  {
    type: "School",
    name: "Wisconsin Indianhead Technical College",
  },
  {
    type: "School",
    name: "Wisconsin School of Professional Psychology",
  },
  {
    type: "School",
    name: "Paul Mitchell the School-Green Bay",
  },
  {
    type: "School",
    name: "University of Wisconsin-Eau Claire",
  },
  {
    type: "School",
    name: "University of Wisconsin-Green Bay",
  },
  {
    type: "School",
    name: "University of Wisconsin-La Crosse",
  },
  {
    type: "School",
    name: "Wisconsin Lutheran College",
  },
  {
    type: "School",
    name: "University of Wisconsin-Oshkosh",
  },
  {
    type: "School",
    name: "University of Wisconsin-Parkside",
  },
  {
    type: "School",
    name: "Herzing University-Madison",
  },
  {
    type: "School",
    name: "University of Wisconsin-Stout",
  },
  {
    type: "School",
    name: "University of Wisconsin-Superior",
  },
  {
    type: "School",
    name: "University of Wisconsin-System Administration",
  },
  {
    type: "School",
    name: "University of Wisconsin-Madison",
  },
  {
    type: "School",
    name: "University of Wisconsin-Milwaukee",
  },
  {
    type: "School",
    name: "University of Wisconsin-Platteville",
  },
  {
    type: "School",
    name: "University of Wisconsin-River Falls",
  },
  {
    type: "School",
    name: "University of Wisconsin-Stevens Point",
  },
  {
    type: "School",
    name: "Casper College",
  },
  {
    type: "School",
    name: "Central Wyoming College",
  },
  {
    type: "School",
    name: "Eastern Wyoming College",
  },
  {
    type: "School",
    name: "Laramie County Community College",
  },
  {
    type: "School",
    name: "Northwest College",
  },
  {
    type: "School",
    name: "Sheridan College",
  },
  {
    type: "School",
    name: "Western Wyoming Community College",
  },
  {
    type: "School",
    name: "Cheeks International Academy of Beauty Culture-Cheyenne",
  },
  {
    type: "School",
    name: "Wyotech-Laramie",
  },
  {
    type: "School",
    name: "University of Wyoming",
  },
  {
    type: "School",
    name: "American Samoa Community College",
  },
  {
    type: "School",
    name: "Guam Community College",
  },
  {
    type: "School",
    name: "University of Guam",
  },
  {
    type: "School",
    name: "Northern Marianas College",
  },
  {
    type: "School",
    name: "Institute of Beauty Careers",
  },
  {
    type: "School",
    name: "Educational Technical College-Recinto de Bayamon",
  },
  {
    type: "School",
    name: "American University of Puerto Rico",
  },
  {
    type: "School",
    name: "American University of Puerto Rico",
  },
  {
    type: "School",
    name: "American Educational College",
  },
  {
    type: "School",
    name: "Antilles School of Technical Careers",
  },
  {
    type: "School",
    name: "Universidad Adventista de las Antillas",
  },
  {
    type: "School",
    name: "Atlantic University College",
  },
  {
    type: "School",
    name: "Bayamon Central University",
  },
  {
    type: "School",
    name: "Columbia Centro Universitario-Caguas",
  },
  {
    type: "School",
    name: "Carlos Albizu University-San Juan",
  },
  {
    type: "School",
    name: "Caribbean University-Bayamon",
  },
  {
    type: "School",
    name: "Caribbean University-Carolina",
  },
  {
    type: "School",
    name: "Pontifical Catholic University of Puerto Rico-Arecibo",
  },
  {
    type: "School",
    name: "Pontifical Catholic University of Puerto Rico-Ponce",
  },
  {
    type: "School",
    name: "Centro de Estudios Multidisciplinarios-San Juan",
  },
  {
    type: "School",
    name: "Universidad Teologica del Caribe",
  },
  {
    type: "School",
    name: "Colegio Universitario de San Juan",
  },
  {
    type: "School",
    name: "Universidad Metropolitana",
  },
  {
    type: "School",
    name: "Puerto Rico Conservatory of Music",
  },
  {
    type: "School",
    name: "Center for Advanced Studies On Puerto Rico and the Caribbean",
  },
  {
    type: "School",
    name: "EDP University of Puerto Rico Inc-San Sebastian",
  },
  {
    type: "School",
    name: "Emma's Beauty Academy-Mayaguez",
  },
  {
    type: "School",
    name: "Escuela de Artes Plasticas de Puerto Rico",
  },
  {
    type: "School",
    name: "Instituto Tecnologico de Puerto Rico-Recinto de Manati",
  },
  {
    type: "School",
    name: "Sistema Universitario Ana G Mendez",
  },
  {
    type: "School",
    name: "Huertas College",
  },
  {
    type: "School",
    name: "Humacao Community College",
  },
  {
    type: "School",
    name: "ICPR Junior College-Arecibo",
  },
  {
    type: "School",
    name: "ICPR Junior College-Mayaguez",
  },
  {
    type: "School",
    name: "Instituto de Educacion Tecnica Ocupacional La Reine-Manati",
  },
  {
    type: "School",
    name: "Hispanic American College",
  },
  {
    type: "School",
    name: "Instituto de Banca y Comercio Inc",
  },
  {
    type: "School",
    name: "Instituto Tecnologico de Puerto Rico-Recinto de Guayama",
  },
  {
    type: "School",
    name: "Instituto Tecnologico de Puerto Rico-Recinto de Ponce",
  },
  {
    type: "School",
    name: "Instituto Tecnologico de Puerto Rico-Recinto de San Juan",
  },
  {
    type: "School",
    name: "Inter American University of Puerto Rico-San German",
  },
  {
    type: "School",
    name: "Inter American University of Puerto Rico-Aguadilla",
  },
  {
    type: "School",
    name: "Inter American University of Puerto Rico-Arecibo",
  },
  {
    type: "School",
    name: "Inter American University of Puerto Rico-Barranquitas",
  },
  {
    type: "School",
    name: "Inter American University of Puerto Rico-Metro",
  },
  {
    type: "School",
    name: "Inter American University of Puerto Rico-Ponce",
  },
  {
    type: "School",
    name: "Inter American University of Puerto Rico-Central Office",
  },
  {
    type: "School",
    name: "Inter American University of Puerto Rico-Fajardo",
  },
  {
    type: "School",
    name: "Inter American University of Puerto Rico-Guayama",
  },
  {
    type: "School",
    name: "Inter American University of Puerto Rico-Bayamon",
  },
  {
    type: "School",
    name: "Inter American University of Puerto Rico-School of Law",
  },
  {
    type: "School",
    name: "International Technical College",
  },
  {
    type: "School",
    name: "Liceo de Arte y Tecnologia",
  },
  {
    type: "School",
    name: "MBTI Business Training Institute",
  },
  {
    type: "School",
    name: "Modern Hairstyling Institute-Bayamon",
  },
  {
    type: "School",
    name: "Modern Hairstyling Institute-Arecibo",
  },
  {
    type: "School",
    name: "National University College-Bayamon",
  },
  {
    type: "School",
    name: "National University College-Arecibo",
  },
  {
    type: "School",
    name: "Ponce Paramedical College Inc",
  },
  {
    type: "School",
    name: "Ponce School of Medicine and Health Sciences",
  },
  {
    type: "School",
    name: "University of Puerto Rico-Aguadilla",
  },
  {
    type: "School",
    name: "University of Puerto Rico-Arecibo",
  },
  {
    type: "School",
    name: "University of Puerto Rico-Bayamon",
  },
  {
    type: "School",
    name: "University of Puerto Rico-Carolina",
  },
  {
    type: "School",
    name: "University of Puerto Rico-Cayey",
  },
  {
    type: "School",
    name: "University of Puerto Rico-Central Administration",
  },
  {
    type: "School",
    name: "University of Puerto Rico-Humacao",
  },
  {
    type: "School",
    name: "University of Puerto Rico-Utuado",
  },
  {
    type: "School",
    name: "University of Puerto Rico-Mayaguez",
  },
  {
    type: "School",
    name: "University of Puerto Rico-Medical Sciences",
  },
  {
    type: "School",
    name: "University of Puerto Rico-Ponce",
  },
  {
    type: "School",
    name: "University of Puerto Rico-Rio Piedras",
  },
  {
    type: "School",
    name: "Universidad Del Este",
  },
  {
    type: "School",
    name: "Ryder Memorial School for Practical Nursing",
  },
  {
    type: "School",
    name: "Rogies School of Beauty Culture",
  },
  {
    type: "School",
    name: "Universidad del Sagrado Corazon",
  },
  {
    type: "School",
    name: "Seminario Evangelico de Puerto Rico",
  },
  {
    type: "School",
    name: "Universidad Central Del Caribe",
  },
  {
    type: "School",
    name: "Universidad Politecnica de Puerto Rico",
  },
  {
    type: "School",
    name: "Pontifical Catholic University of Puerto Rico-Mayaguez",
  },
  {
    type: "School",
    name: "Universidad Del Turabo",
  },
  {
    type: "School",
    name: "College of Micronesia-FSM",
  },
  {
    type: "School",
    name: "Palau Community College",
  },
  {
    type: "School",
    name: "University of the Virgin Islands",
  },
  {
    type: "School",
    name: "Stanford University",
  },
  {
    type: "School",
    name: "Purdue University-Main Campus",
  },
  {
    type: "School",
    name: "Blue Hills Regional Technical School",
  },
  {
    type: "School",
    name: "Parker University",
  },
  {
    type: "School",
    name: "EDP Univeristy of Puerto Rico Inc-San Juan",
  },
  {
    type: "School",
    name: "ICPR Junior College-General Institutional",
  },
  {
    type: "School",
    name: "ITT Technical Institute-Sylmar",
  },
  {
    type: "School",
    name: "ITT Technical Institute-Westminster",
  },
  {
    type: "School",
    name: "Widener University-Delaware Campus",
  },
  {
    type: "School",
    name: "City College-Fort Lauderdale",
  },
  {
    type: "School",
    name: "Meridian College",
  },
  {
    type: "School",
    name: "Georgia Perimeter College",
  },
  {
    type: "School",
    name: "Georgia Piedmont Technical College",
  },
  {
    type: "School",
    name: "Toni & Guy Hairdressing Academy-Coeur D Alene",
  },
  {
    type: "School",
    name: "Cortiva Institute-Chicago",
  },
  {
    type: "School",
    name: "ETI School of Skilled Trades",
  },
  {
    type: "School",
    name: "Lincoln College of Technology-Florence",
  },
  {
    type: "School",
    name: "Trend Setters' Academy of Beauty Culture-Elizabethtown",
  },
  {
    type: "School",
    name: "Traxlers School of Hair",
  },
  {
    type: "School",
    name: "Infinity Career College",
  },
  {
    type: "School",
    name: "Vatterott College-Berkeley",
  },
  {
    type: "School",
    name: "Metro Business College-Rolla",
  },
  {
    type: "School",
    name: "Metro Business College-Jefferson City",
  },
  {
    type: "School",
    name: "Warren County Community College",
  },
  {
    type: "School",
    name: "St John's College",
  },
  {
    type: "School",
    name: "Yeshiva and Kollel Harbotzas Torah",
  },
  {
    type: "School",
    name: "Bais Medrash Elyon",
  },
  {
    type: "School",
    name: "Antioch University-Los Angeles",
  },
  {
    type: "School",
    name: "Antioch University-Santa Barbara",
  },
  {
    type: "School",
    name: "Antioch University-New England",
  },
  {
    type: "School",
    name: "Antioch University-Seattle",
  },
  {
    type: "School",
    name: "Antioch University-Midwest",
  },
  {
    type: "School",
    name: "Mid-America Christian University",
  },
  {
    type: "School",
    name: "Platt College-Tulsa",
  },
  {
    type: "School",
    name: "Ponca City Beauty College",
  },
  {
    type: "School",
    name: "Francis Tuttle Technology Center",
  },
  {
    type: "School",
    name: "Career Point College",
  },
  {
    type: "School",
    name: "Central Technology Center",
  },
  {
    type: "School",
    name: "Carrington College-Portland",
  },
  {
    type: "School",
    name: "Career Technology Center of Lackawanna County",
  },
  {
    type: "School",
    name: "Kaplan Career Institute-Nashville",
  },
  {
    type: "School",
    name: "Kaplan College-El Paso",
  },
  {
    type: "School",
    name: "Houston Graduate School of Theology",
  },
  {
    type: "School",
    name: "Palo Alto College",
  },
  {
    type: "School",
    name: "Sebring Career Schools-Huntsville",
  },
  {
    type: "School",
    name: "University of Cosmetology Arts & Sciences-San Antonio Perrin",
  },
  {
    type: "School",
    name: "Central Texas Beauty College-Round Rock",
  },
  {
    type: "School",
    name: "Empire Beauty School-Manitowoc",
  },
  {
    type: "School",
    name: "Unification Theological Seminary",
  },
  {
    type: "School",
    name: "Athens Technical College",
  },
  {
    type: "School",
    name: "Maricopa Skill Center",
  },
  {
    type: "School",
    name: "Carrington College California-San Leandro",
  },
  {
    type: "School",
    name: "Headmasters School of Hair Design",
  },
  {
    type: "School",
    name: "Razzle Dazzle College of Hair Design Inc",
  },
  {
    type: "School",
    name: "Beckfield College-Florence",
  },
  {
    type: "School",
    name: "University of Louisiana-System Administration",
  },
  {
    type: "School",
    name: "Dorsey Business Schools-Wayne",
  },
  {
    type: "School",
    name: "Ecumenical Theological Seminary",
  },
  {
    type: "School",
    name: "Ross Medical Education Center-Saginaw",
  },
  {
    type: "School",
    name: "Sussex County Community College",
  },
  {
    type: "School",
    name: "Landmark College",
  },
  {
    type: "School",
    name: "National American University-Independence",
  },
  {
    type: "School",
    name: "Mississippi Community College Board",
  },
  {
    type: "School",
    name: "Yeshiva Gedolah of Greater Detroit",
  },
  {
    type: "School",
    name: "Austin Graduate School of Theology",
  },
  {
    type: "School",
    name: "Collin County Community College District",
  },
  {
    type: "School",
    name: "Owensboro Community and Technical College",
  },
  {
    type: "School",
    name: "Heads West Kentucky Beauty College",
  },
  {
    type: "School",
    name: "Jenny Lea Academy of Cosmetology",
  },
  {
    type: "School",
    name: "Empire Beauty School-Hurstborne",
  },
  {
    type: "School",
    name: "Southeast School of Cosmetology",
  },
  {
    type: "School",
    name: "Cloyd's Beauty School 3 Inc",
  },
  {
    type: "School",
    name: "Moore Norman Technology Center",
  },
  {
    type: "School",
    name: "Fayette County Career & Technical Institute Practical Nursing Program",
  },
  {
    type: "School",
    name: "Franklin County Career and Technology Center",
  },
  {
    type: "School",
    name: "Paul Mitchell the School-Knoxville",
  },
  {
    type: "School",
    name: "Ogle School Hair Skin Nails-Hurst",
  },
  {
    type: "School",
    name: "Okefenokee Technical College",
  },
  {
    type: "School",
    name: "Fashion Institute of Design & Merchandising-San Diego",
  },
  {
    type: "School",
    name: "National American University-Albuquerque",
  },
  {
    type: "School",
    name: "Tennessee College of Applied Technology-Nashville",
  },
  {
    type: "School",
    name: "ECPI University",
  },
  {
    type: "School",
    name: "Ms Robert's Academy of Beauty Culture-Villa Park",
  },
  {
    type: "School",
    name: "Anthem Institute-Jersey City",
  },
  {
    type: "School",
    name: "Paul Mitchell the School-Houston",
  },
  {
    type: "School",
    name: "ATI Career Training Center-Dallas",
  },
  {
    type: "School",
    name: "TDDS Technical Institute",
  },
  {
    type: "School",
    name: "Fortis Institute-Forty Fort",
  },
  {
    type: "School",
    name: "Laurel Business Institute",
  },
  {
    type: "School",
    name: "Antonelli Medical and Professional Institute",
  },
  {
    type: "School",
    name: "Aviation Institute of Maintenance-Philadelphia",
  },
  {
    type: "School",
    name: "Sanford-Brown Institute-Trevose",
  },
  {
    type: "School",
    name: "Fortis Institute-Mulberry",
  },
  {
    type: "School",
    name: "Carrington College-Phoenix Westside",
  },
  {
    type: "School",
    name: "Dorsey Business Schools-Roseville",
  },
  {
    type: "School",
    name: "The Salon Professional Academy-Williston",
  },
  {
    type: "School",
    name: "Moler-Pickens Beauty Academy",
  },
  {
    type: "School",
    name: "Red River Technology Center",
  },
  {
    type: "School",
    name: "Kaplan Career Institute-Harrisburg",
  },
  {
    type: "School",
    name: "ITT Technical Institute-Newburgh",
  },
  {
    type: "School",
    name: "Southern Union State Community College",
  },
  {
    type: "School",
    name: "South University-Columbia",
  },
  {
    type: "School",
    name: "Everest University-Brandon",
  },
  {
    type: "School",
    name: "Clinton Technical School",
  },
  {
    type: "School",
    name: "Consolidated School of Business-Lancaster",
  },
  {
    type: "School",
    name: "Greene County Career and Technology Center",
  },
  {
    type: "School",
    name: "Lac Courte Oreilles Ojibwa Community College",
  },
  {
    type: "School",
    name: "University of Wisconsin Extension",
  },
  {
    type: "School",
    name: "Diesel Driving Academy-Shreveport",
  },
  {
    type: "School",
    name: "Pima Medical Institute-Mesa",
  },
  {
    type: "School",
    name: "Platt College-Los Angeles",
  },
  {
    type: "School",
    name: "Platt College-Aurora",
  },
  {
    type: "School",
    name: "Kaplan University-Davenport Campus",
  },
  {
    type: "School",
    name: "Kaplan University-Cedar Falls Campus",
  },
  {
    type: "School",
    name: "Paul Mitchell the School-Boise",
  },
  {
    type: "School",
    name: "Christian Life College",
  },
  {
    type: "School",
    name: "Knowledge Systems Institute",
  },
  {
    type: "School",
    name: "Educators of Beauty College of Cosmetology-Rockford",
  },
  {
    type: "School",
    name: "ITT Technical Institute-Arlington Heights",
  },
  {
    type: "School",
    name: "Northwestern College-Southwestern Campus",
  },
  {
    type: "School",
    name: "Tulsa Technology Center-Lemley Campus",
  },
  {
    type: "School",
    name: "Tulsa Technology Center-Riverside Campus",
  },
  {
    type: "School",
    name: "Tulsa Technology Center-Broken Arrow Campus",
  },
  {
    type: "School",
    name: "Douglas J Aveda Institute",
  },
  {
    type: "School",
    name: "ITT Technical Institute-Troy",
  },
  {
    type: "School",
    name: "Ross Medical Education Center-Brighton",
  },
  {
    type: "School",
    name: "Ross Medical Education Center-Ann Arbor",
  },
  {
    type: "School",
    name: "Taylortown School of Beauty Inc",
  },
  {
    type: "School",
    name: "Pike-Lincoln Technical Center",
  },
  {
    type: "School",
    name: "Pennsylvania Institute of Health and Technology",
  },
  {
    type: "School",
    name: "Skyline College-Roanoke",
  },
  {
    type: "School",
    name: "Bethel Seminary-San Diego",
  },
  {
    type: "School",
    name: "St Charles Community College",
  },
  {
    type: "School",
    name: "Brandman University",
  },
  {
    type: "School",
    name: "New College of Florida",
  },
  {
    type: "School",
    name: "Montana Bible College",
  },
  {
    type: "School",
    name: "Arizona Academy of Beauty-North",
  },
  {
    type: "School",
    name: "Empire Beauty School-Prescott",
  },
  {
    type: "School",
    name: "Le Cordon Bleu College of Culinary Arts-Scottsdale",
  },
  {
    type: "School",
    name: "Conservatory of Recording Arts and Sciences",
  },
  {
    type: "School",
    name: "Adrian's College of Beauty Turlock",
  },
  {
    type: "School",
    name: "California Career School",
  },
  {
    type: "School",
    name: "San Joaquin Valley College-Fresno",
  },
  {
    type: "School",
    name: "Rolf Institute of Structural Integration",
  },
  {
    type: "School",
    name: "Heritage College-Denver",
  },
  {
    type: "School",
    name: "Empire Beauty School-Highland",
  },
  {
    type: "School",
    name: "Sunstate Academy",
  },
  {
    type: "School",
    name: "Elizabeth Grady School of Esthetics and Massage Therapy",
  },
  {
    type: "School",
    name: "Ross Medical Education Center-Port Huron",
  },
  {
    type: "School",
    name: "Capri Institute of Hair Design-Clifton",
  },
  {
    type: "School",
    name: "Raphael's School of Beauty Culture Inc-Alliance",
  },
  {
    type: "School",
    name: "Metro Technology Centers",
  },
  {
    type: "School",
    name: "Heald College-Roseville",
  },
  {
    type: "School",
    name: "Daymar College-Bowling Green",
  },
  {
    type: "School",
    name: "Everest College-Everett",
  },
  {
    type: "School",
    name: "Luna Community College",
  },
  {
    type: "School",
    name: "Grabber School of Hair Design",
  },
  {
    type: "School",
    name: "Yeshivah Gedolah Rabbinical College",
  },
  {
    type: "School",
    name: "University of the District of Columbia-David A Clarke School of Law",
  },
  {
    type: "School",
    name: "Raphael's School of Beauty Culture Inc-Brunswick",
  },
  {
    type: "School",
    name: "Caribbean University-Ponce",
  },
  {
    type: "School",
    name: "Caribbean University-Vega Baja",
  },
  {
    type: "School",
    name: "University of Advancing Technology",
  },
  {
    type: "School",
    name: "Paradise Valley Community College",
  },
  {
    type: "School",
    name: "Chandler-Gilbert Community College",
  },
  {
    type: "School",
    name: "St John's Hospital School of Clinical Lab Science",
  },
  {
    type: "School",
    name: "Madison Media Institute",
  },
  {
    type: "School",
    name: "Sanford-Brown Institute-Landover",
  },
  {
    type: "School",
    name: "Sanford-Brown Institute-White Plains",
  },
  {
    type: "School",
    name: "Sanford-Brown Institute-Garden City",
  },
  {
    type: "School",
    name: "Great Plains Technology Center",
  },
  {
    type: "School",
    name: "Montebello Beauty College",
  },
  {
    type: "School",
    name: "Roane-Jackson Technical Center",
  },
  {
    type: "School",
    name: "Total Cosmetology Training Center",
  },
  {
    type: "School",
    name: "Iverson Business School",
  },
  {
    type: "School",
    name: "Lawrence County Career and Technical Center-Practical Nursing Program",
  },
  {
    type: "School",
    name: "Pioneer Technology Center",
  },
  {
    type: "School",
    name: "Portage Lakes Career Center",
  },
  {
    type: "School",
    name: "Eastern College of Health Vocations-New Orleans",
  },
  {
    type: "School",
    name: "Bell Mar Beauty College",
  },
  {
    type: "School",
    name: "Cannella School of Hair Design-Chicago",
  },
  {
    type: "School",
    name: "Caddo Kiowa Technology Center",
  },
  {
    type: "School",
    name: "Kaplan College-San Antonio-San Pedro",
  },
  {
    type: "School",
    name: "Oswego County BOCES",
  },
  {
    type: "School",
    name: "Le Cordon Bleu College of Culinary Arts-Austin",
  },
  {
    type: "School",
    name: "The Art Institute of New York City",
  },
  {
    type: "School",
    name: "Paris II Educational Center",
  },
  {
    type: "School",
    name: "International School of Skin Nailcare & Massage Therapy",
  },
  {
    type: "School",
    name: "Palomar Institute of Cosmetology",
  },
  {
    type: "School",
    name: "Southern Oklahoma Technology Center",
  },
  {
    type: "School",
    name: "Vista College",
  },
  {
    type: "School",
    name: "Autry Technology Center",
  },
  {
    type: "School",
    name: "Greene County Vocational School District",
  },
  {
    type: "School",
    name: "Canadian Valley Technology Center",
  },
  {
    type: "School",
    name: "Wilkes-Barre Area Career and Technical Center Practical Nursing",
  },
  {
    type: "School",
    name: "Mayo Graduate School",
  },
  {
    type: "School",
    name: "American Beauty Institute",
  },
  {
    type: "School",
    name: "Meridian Technology Center",
  },
  {
    type: "School",
    name: "Waynesville Career Center",
  },
  {
    type: "School",
    name: "Connecticut Institute of Hair Design",
  },
  {
    type: "School",
    name: "Capitol School of Hairstyling and Esthetics",
  },
  {
    type: "School",
    name: "Morris County Vocational School District",
  },
  {
    type: "School",
    name: "Ross Medical Education Center-Roosevelt Park",
  },
  {
    type: "School",
    name: "Ross Medical Education Center-Kentwood",
  },
  {
    type: "School",
    name: "South Florida Bible College and Theological Seminary",
  },
  {
    type: "School",
    name: "Cloyd's Barber School 2 Inc",
  },
  {
    type: "School",
    name: "Universal Technical Institute of Illinois Inc",
  },
  {
    type: "School",
    name: "Pennsylvania College of Technology",
  },
  {
    type: "School",
    name: "Commonwealth Institute of Funeral Service",
  },
  {
    type: "School",
    name: "Delta College of Arts & Technology",
  },
  {
    type: "School",
    name: "InterCoast Colleges-Orange",
  },
  {
    type: "School",
    name: "Stone Child College",
  },
  {
    type: "School",
    name: "New York Academy of Art",
  },
  {
    type: "School",
    name: "Suffolk County Community College",
  },
  {
    type: "School",
    name: "Las Positas College",
  },
  {
    type: "School",
    name: "Blades School of Hair Design",
  },
  {
    type: "School",
    name: "Altamaha Technical College",
  },
  {
    type: "School",
    name: "Ogeechee Technical College",
  },
  {
    type: "School",
    name: "Remington College-Mobile Campus",
  },
  {
    type: "School",
    name: "Everest College-Aurora",
  },
  {
    type: "School",
    name: "Southwest Florida College",
  },
  {
    type: "School",
    name: "Mercy-St Luke's School of Radiologic Technology",
  },
  {
    type: "School",
    name: "ITT Technical Institute-Norwood",
  },
  {
    type: "School",
    name: "Southwest Acupuncture College-Santa Fe",
  },
  {
    type: "School",
    name: "Northwest Technology Center-Alva",
  },
  {
    type: "School",
    name: "Mercer County Career Center",
  },
  {
    type: "School",
    name: "ITT Technical Institute-Knoxville",
  },
  {
    type: "School",
    name: "ITT Technical Institute-Austin",
  },
  {
    type: "School",
    name: "ITT Technical Institute-Houston North",
  },
  {
    type: "School",
    name: "DeVry University's Keller Graduate School of Management-Arizona",
  },
  {
    type: "School",
    name: "California State University-San Marcos",
  },
  {
    type: "School",
    name: "Argosy University-Hawaii",
  },
  {
    type: "School",
    name: "DeVry University's Keller Graduate School of Management-Missouri",
  },
  {
    type: "School",
    name: "Baptist Theological Seminary at Richmond",
  },
  {
    type: "School",
    name: "Kaplan College-Modesto",
  },
  {
    type: "School",
    name: "Kaplan University-Des Moines Campus",
  },
  {
    type: "School",
    name: "Hairmasters Institute of Cosmetology",
  },
  {
    type: "School",
    name: "Hair Arts Academy",
  },
  {
    type: "School",
    name: "Pinnacle Career Institute-Lawrence",
  },
  {
    type: "School",
    name: "Interactive College of Technology-Newport",
  },
  {
    type: "School",
    name: "Career Technical College-Monroe",
  },
  {
    type: "School",
    name: "Empire Beauty School-Lowell",
  },
  {
    type: "School",
    name: "Ross Medical Education Center-Taylor",
  },
  {
    type: "School",
    name: "McNally Smith College of Music",
  },
  {
    type: "School",
    name: "Allied Health Careers",
  },
  {
    type: "School",
    name: "National Tractor Trailer School Inc-Liverpool",
  },
  {
    type: "School",
    name: "Interactive Learning Systems-Houston",
  },
  {
    type: "School",
    name: "Star Career Academy�Brick",
  },
  {
    type: "School",
    name: "New Castle Beauty Academy",
  },
  {
    type: "School",
    name: "NorthWest Arkansas Community College",
  },
  {
    type: "School",
    name: "Madera Beauty College",
  },
  {
    type: "School",
    name: "Colorado Heights University",
  },
  {
    type: "School",
    name: "Brewster Technical Center",
  },
  {
    type: "School",
    name: "Hodges University",
  },
  {
    type: "School",
    name: "Everest University-Lakeland",
  },
  {
    type: "School",
    name: "Argosy University-Atlanta",
  },
  {
    type: "School",
    name: "University of St Augustine for Health Sciences",
  },
  {
    type: "School",
    name: "Capital Area School of Practical Nursing",
  },
  {
    type: "School",
    name: "Hair Professionals Career College",
  },
  {
    type: "School",
    name: "Alexandria School of Scientific Therapeutics",
  },
  {
    type: "School",
    name: "Empire Beauty School-Bloomfield",
  },
  {
    type: "School",
    name: "Southwest Technology Center",
  },
  {
    type: "School",
    name: "Western Area Career and Technology Center",
  },
  {
    type: "School",
    name: "Daymar Institute-Clarksville",
  },
  {
    type: "School",
    name: "Nossi College of Art",
  },
  {
    type: "School",
    name: "Seguin Beauty School-New Braunfels",
  },
  {
    type: "School",
    name: "ITT Technical Institute-Norfolk",
  },
  {
    type: "School",
    name: "Pima Medical Institute-Seattle",
  },
  {
    type: "School",
    name: "Mineral County Vocational Technical Center",
  },
  {
    type: "School",
    name: "Hypnosis Motivation Institute",
  },
  {
    type: "School",
    name: "Los Angeles ORT College-Los Angeles Campus",
  },
  {
    type: "School",
    name: "Everest College-West Los Angeles",
  },
  {
    type: "School",
    name: "Thanh Le College School of Cosmetology",
  },
  {
    type: "School",
    name: "Southeastern Technical College",
  },
  {
    type: "School",
    name: "Massachusetts School of Law",
  },
  {
    type: "School",
    name: "ITT Technical Institute-Albuquerque",
  },
  {
    type: "School",
    name: "Community Services Division-Alliance City",
  },
  {
    type: "School",
    name: "Universal Technical Institute-Auto Motorcycle & Marine Mechanics Institute Division-Orlando",
  },
  {
    type: "School",
    name: "D G Erwin Technical Center",
  },
  {
    type: "School",
    name: "Rosel School of Cosmetology",
  },
  {
    type: "School",
    name: "Worsham College of Mortuary Science",
  },
  {
    type: "School",
    name: "Bryan University",
  },
  {
    type: "School",
    name: "Oregon College of Oriental Medicine",
  },
  {
    type: "School",
    name: "Central Pennsylvania Institute of Science and Technology",
  },
  {
    type: "School",
    name: "Northwest Educational Center",
  },
  {
    type: "School",
    name: "Sebring Career Schools-Houston",
  },
  {
    type: "School",
    name: "PCI Health Training Center",
  },
  {
    type: "School",
    name: "Windham Memorial Hospital-Radiologic Technology Program",
  },
  {
    type: "School",
    name: "Bethel Seminary of the East",
  },
  {
    type: "School",
    name: "Miami-Jacobs Career College-Columbus",
  },
  {
    type: "School",
    name: "Bryant & Stratton College-Eastlake",
  },
  {
    type: "School",
    name: "Hamrick School",
  },
  {
    type: "School",
    name: "Alabama State College of Barber Styling",
  },
  {
    type: "School",
    name: "Fortis College-Mobile",
  },
  {
    type: "School",
    name: "Arkansas Beauty School",
  },
  {
    type: "School",
    name: "Everest College-Ontario",
  },
  {
    type: "School",
    name: "Downey Adult School",
  },
  {
    type: "School",
    name: "Heald College-Stockton",
  },
  {
    type: "School",
    name: "Heald College-Hayward",
  },
  {
    type: "School",
    name: "CRU Institute",
  },
  {
    type: "School",
    name: "Modern Technology School",
  },
  {
    type: "School",
    name: "National Career Education",
  },
  {
    type: "School",
    name: "Everest College-Anaheim",
  },
  {
    type: "School",
    name: "Thuy Princess Beauty College",
  },
  {
    type: "School",
    name: "North-West College-Glendale",
  },
  {
    type: "School",
    name: "Everest College-City of Industry",
  },
  {
    type: "School",
    name: "Fremont College",
  },
  {
    type: "School",
    name: "Pomona Unified School District Adult and Career Education",
  },
  {
    type: "School",
    name: "University of Phoenix-Online Campus",
  },
  {
    type: "School",
    name: "University of Phoenix-San Diego Campus",
  },
  {
    type: "School",
    name: "Universal College of Beauty Inc-Los Angeles 2",
  },
  {
    type: "School",
    name: "IBMC College",
  },
  {
    type: "School",
    name: "Xenon International Academy-Denver",
  },
  {
    type: "School",
    name: "Academy of Cosmetology",
  },
  {
    type: "School",
    name: "ITT Technical Institute-Lake Mary",
  },
  {
    type: "School",
    name: "Sunstate Academy",
  },
  {
    type: "School",
    name: "Heritage Institute-Jacksonville",
  },
  {
    type: "School",
    name: "Fortis College-Smyrna",
  },
  {
    type: "School",
    name: "Remington College-Honolulu Campus",
  },
  {
    type: "School",
    name: "Vatterott College-Des Moines",
  },
  {
    type: "School",
    name: "Brown Mackie College-Quad Cities",
  },
  {
    type: "School",
    name: "ETI School of Skilled Trades",
  },
  {
    type: "School",
    name: "Pivot Point Academy-Bloomingdale",
  },
  {
    type: "School",
    name: "Xenon International Academy-Kansas City",
  },
  {
    type: "School",
    name: "Baton Rouge College",
  },
  {
    type: "School",
    name: "Diesel Driving Academy-Baton Rouge",
  },
  {
    type: "School",
    name: "Jonesville Beauty School",
  },
  {
    type: "School",
    name: "New England Hair Academy",
  },
  {
    type: "School",
    name: "Rob Roy Academy-Worcester",
  },
  {
    type: "School",
    name: "Upper Cape Cod Regional Technical School",
  },
  {
    type: "School",
    name: "All-State Career-Baltimore",
  },
  {
    type: "School",
    name: "New England School of Communications",
  },
  {
    type: "School",
    name: "Everest Institute-Kalamazoo",
  },
  {
    type: "School",
    name: "Adler Graduate School",
  },
  {
    type: "School",
    name: "Lebanon Technology and Career Center",
  },
  {
    type: "School",
    name: "Academy of Hair Design-Jackson",
  },
  {
    type: "School",
    name: "KC's School of Hair Design",
  },
  {
    type: "School",
    name: "Mitchells Hairstyling Academy-Goldsboro",
  },
  {
    type: "School",
    name: "Lincoln Technical Institute-Moorestown",
  },
  {
    type: "School",
    name: "Monmouth County Vocational School District",
  },
  {
    type: "School",
    name: "Kaplan College-Las Vegas",
  },
  {
    type: "School",
    name: "Beauty School of Middletown",
  },
  {
    type: "School",
    name: "Bryant & Stratton College-Southtowns",
  },
  {
    type: "School",
    name: "Continental School of Beauty Culture-Olean",
  },
  {
    type: "School",
    name: "National Tractor Trailer School Inc-Buffalo",
  },
  {
    type: "School",
    name: "Yeshiva Gedolah Imrei Yosef D'spinka",
  },
  {
    type: "School",
    name: "Cleveland Institute of Dental-Medical Assistants-Mentor",
  },
  {
    type: "School",
    name: "Cleveland Institute of Dental-Medical Assistants-Lyndhurst",
  },
  {
    type: "School",
    name: "Knox County Career Center",
  },
  {
    type: "School",
    name: "Madison Adult Career Center",
  },
  {
    type: "School",
    name: "Remington College-Cleveland Campus",
  },
  {
    type: "School",
    name: "Brown Mackie College-Findlay",
  },
  {
    type: "School",
    name: "Toledo Academy of Beauty Culture-East",
  },
  {
    type: "School",
    name: "U S Grant Joint Vocational School",
  },
  {
    type: "School",
    name: "CC's Cosmetology College",
  },
  {
    type: "School",
    name: "Chisholm Trail Technology Center",
  },
  {
    type: "School",
    name: "Gordon Cooper Technology Center",
  },
  {
    type: "School",
    name: "Kiamichi Technology Center-Atoka",
  },
  {
    type: "School",
    name: "Kiamichi Technology Center-Durant",
  },
  {
    type: "School",
    name: "Kiamichi Technology Center-Hugo",
  },
  {
    type: "School",
    name: "Kiamichi Technology Center-McAlester",
  },
  {
    type: "School",
    name: "Kiamichi Technology Center-Idabel",
  },
  {
    type: "School",
    name: "Kiamichi Technology Center-Poteau",
  },
  {
    type: "School",
    name: "Kiamichi Technology Center-Spiro",
  },
  {
    type: "School",
    name: "Kiamichi Technology Center-Talihina",
  },
  {
    type: "School",
    name: "Le Cordon Bleu College of Culinary Arts-Portland",
  },
  {
    type: "School",
    name: "YTI Career Institute-Altoona",
  },
  {
    type: "School",
    name: "Eastern Center for Arts and Technology",
  },
  {
    type: "School",
    name: "Empire Beauty School-Center City Philadelphia",
  },
  {
    type: "School",
    name: "Oakbridge Academy of Arts",
  },
  {
    type: "School",
    name: "Sanford-Brown Institute-Wilkins Township",
  },
  {
    type: "School",
    name: "Centro de Estudios Multidisciplinarios-Humacao",
  },
  {
    type: "School",
    name: "Colegio Mayor de Tecnologia Inc",
  },
  {
    type: "School",
    name: "Institucion Chaviano de Mayaguez",
  },
  {
    type: "School",
    name: "EDIC College",
  },
  {
    type: "School",
    name: "Liceo de Arte-Dise-O y Comercio",
  },
  {
    type: "School",
    name: "Universal Technology College of Puerto Rico",
  },
  {
    type: "School",
    name: "Academy of Hair Technology",
  },
  {
    type: "School",
    name: "Mr Wayne's School of Unisex Hair Design",
  },
  {
    type: "School",
    name: "Southern Institute of Cosmetology",
  },
  {
    type: "School",
    name: "Queen City College",
  },
  {
    type: "School",
    name: "College of the Marshall Islands",
  },
  {
    type: "School",
    name: "American National College",
  },
  {
    type: "School",
    name: "ITT Technical Institute-San Antonio",
  },
  {
    type: "School",
    name: "Remington College-Fort Worth Campus",
  },
  {
    type: "School",
    name: "University of Cosmetology Arts & Sciences-San Antonio Jamar",
  },
  {
    type: "School",
    name: "School of Automotive Machinists",
  },
  {
    type: "School",
    name: "SW School of Business and Technical Careers-Cosmetology",
  },
  {
    type: "School",
    name: "SW School of Business and Technical Careers",
  },
  {
    type: "School",
    name: "Texas College of Cosmetology-Abilene",
  },
  {
    type: "School",
    name: "Vista College-Online",
  },
  {
    type: "School",
    name: "Centura College-Newport News",
  },
  {
    type: "School",
    name: "Centura College-Norfolk",
  },
  {
    type: "School",
    name: "Virginia Beach City Public Schools School of Practical Nursing",
  },
  {
    type: "School",
    name: "Northwest College of Art & Design",
  },
  {
    type: "School",
    name: "University of Washington-Bothell Campus",
  },
  {
    type: "School",
    name: "University of Washington-Tacoma Campus",
  },
  {
    type: "School",
    name: "Huntington School of Beauty Culture-Main Campus",
  },
  {
    type: "School",
    name: "Valley College-Beckley",
  },
  {
    type: "School",
    name: "Valley College-Martinsburg",
  },
  {
    type: "School",
    name: "Valley College-Princeton",
  },
  {
    type: "School",
    name: "Bellus Academy-Poway",
  },
  {
    type: "School",
    name: "Capstone College",
  },
  {
    type: "School",
    name: "Central Coast College",
  },
  {
    type: "School",
    name: "ITT Technical Institute-Torrance",
  },
  {
    type: "School",
    name: "Pacific College of Oriental Medicine-San Diego",
  },
  {
    type: "School",
    name: "Artistic Nails and Beauty Academy-Tampa",
  },
  {
    type: "School",
    name: "National Aviation Academy of Tampa Bay",
  },
  {
    type: "School",
    name: "Sanford-Brown Institute-Tampa",
  },
  {
    type: "School",
    name: "Cain's Barber College Inc",
  },
  {
    type: "School",
    name: "Lawton Career Institute-Oak Park Campus",
  },
  {
    type: "School",
    name: "Madison Oneida BOCES-Practical Nursing Program",
  },
  {
    type: "School",
    name: "Astrodome Career Centers",
  },
  {
    type: "School",
    name: "Center for Advanced Legal Studies",
  },
  {
    type: "School",
    name: "Houston Training School-Main Campus",
  },
  {
    type: "School",
    name: "Remington College-Houston Campus",
  },
  {
    type: "School",
    name: "Bay Mills Community College",
  },
  {
    type: "School",
    name: "Fond du Lac Tribal and Community College",
  },
  {
    type: "School",
    name: "Northwest Indian College",
  },
  {
    type: "School",
    name: "Marinello Schools of Beauty-Provo",
  },
  {
    type: "School",
    name: "Provo College",
  },
  {
    type: "School",
    name: "Paul Mitchell the School-Provo",
  },
  {
    type: "School",
    name: "University of Phoenix-Utah Campus",
  },
  {
    type: "School",
    name: "Utah College of Massage Therapy-Salt Lake City",
  },
  {
    type: "School",
    name: "CET-Coachella",
  },
  {
    type: "School",
    name: "CET-Oxnard",
  },
  {
    type: "School",
    name: "CET-Santa Maria",
  },
  {
    type: "School",
    name: "CET-Rancho Temecula",
  },
  {
    type: "School",
    name: "Colorado School of Traditional Chinese Medicine",
  },
  {
    type: "School",
    name: "Massage Therapy Institute of Colorado",
  },
  {
    type: "School",
    name: "Michigan College of Beauty-Troy",
  },
  {
    type: "School",
    name: "Ogle School Hair Skin Nails-Dallas",
  },
  {
    type: "School",
    name: "Phoenix Seminary",
  },
  {
    type: "School",
    name: "Faust Institute of Cosmetology-Spirit Lake",
  },
  {
    type: "School",
    name: "Seattle Vocational Institute",
  },
  {
    type: "School",
    name: "Baker College of Port Huron",
  },
  {
    type: "School",
    name: "Empire Beauty School-Hyannis",
  },
  {
    type: "School",
    name: "Brookstone College-Greensboro",
  },
  {
    type: "School",
    name: "Cheeks International Academy of Beauty Culture-Fort Collins",
  },
  {
    type: "School",
    name: "Cheeks International Academy of Beauty Culture-Greeley",
  },
  {
    type: "School",
    name: "Colorado School of Healing Arts",
  },
  {
    type: "School",
    name: "Remington College-Colorado Springs Campus",
  },
  {
    type: "School",
    name: "Empire Beauty School-Arvada",
  },
  {
    type: "School",
    name: "Westwood College-Denver South",
  },
  {
    type: "School",
    name: "Kaplan College-Denver",
  },
  {
    type: "School",
    name: "Healing Arts Institute",
  },
  {
    type: "School",
    name: "ITT Technical Institute-San Bernardino",
  },
  {
    type: "School",
    name: "Los Angeles ORT College-Van Nuys Campus",
  },
  {
    type: "School",
    name: "Texas School of Business-Southwest",
  },
  {
    type: "School",
    name: "University of Phoenix-Puerto Rico Campus",
  },
  {
    type: "School",
    name: "Mt Diablo Adult Education",
  },
  {
    type: "School",
    name: "Royale College of Beauty",
  },
  {
    type: "School",
    name: "Westech College",
  },
  {
    type: "School",
    name: "Greater Lowell Technical School",
  },
  {
    type: "School",
    name: "Academy of Hair Design-Pearl",
  },
  {
    type: "School",
    name: "King's College",
  },
  {
    type: "School",
    name: "Miller-Motte Technical College-Clarksville",
  },
  {
    type: "School",
    name: "Genesis Career College-Lebanon",
  },
  {
    type: "School",
    name: "Kaplan College-Dallas",
  },
  {
    type: "School",
    name: "Star College of Cosmetology 2",
  },
  {
    type: "School",
    name: "Southwest Collegiate Institute for the Deaf",
  },
  {
    type: "School",
    name: "Fortis College-Richmond",
  },
  {
    type: "School",
    name: "Midwest College of Oriental Medicine-Racine",
  },
  {
    type: "School",
    name: "Hacienda La Puente Adult Education",
  },
  {
    type: "School",
    name: "La Belle Beauty Academy",
  },
  {
    type: "School",
    name: "Advanced Technical Centers",
  },
  {
    type: "School",
    name: "Hawaii Community College",
  },
  {
    type: "School",
    name: "Regency Beauty Institute-Winston-Salem",
  },
  {
    type: "School",
    name: "Robert Fiance Beauty Schools-North Plainfield",
  },
  {
    type: "School",
    name: "Long Island Beauty School-Hauppauge",
  },
  {
    type: "School",
    name: "Buckeye Joint Vocational School",
  },
  {
    type: "School",
    name: "Sandusky Career Center",
  },
  {
    type: "School",
    name: "Greater Altoona Career & Technology Center",
  },
  {
    type: "School",
    name: "Northeastern Hospital School of Nursing",
  },
  {
    type: "School",
    name: "Central Texas Beauty College-Temple",
  },
  {
    type: "School",
    name: "Dance Theatre of Harlem Inc",
  },
  {
    type: "School",
    name: "Erie 2 Chautauqua Cattaraugus BOCES-Practical Nursing Program",
  },
  {
    type: "School",
    name: "Empire Beauty School-Brooklyn",
  },
  {
    type: "School",
    name: "Houston Training School-Gulfgate",
  },
  {
    type: "School",
    name: "Star Career Academy-Philadelphia",
  },
  {
    type: "School",
    name: "Antonelli College-Hattiesburg",
  },
  {
    type: "School",
    name: "Eastern New Mexico University-Ruidoso Campus",
  },
  {
    type: "School",
    name: "Newbridge College-Santa Ana",
  },
  {
    type: "School",
    name: "Kaplan College-Stockton",
  },
  {
    type: "School",
    name: "The Bryman School of Arizona",
  },
  {
    type: "School",
    name: "Beacon College",
  },
  {
    type: "School",
    name: "Acupuncture and Integrative Medicine College-Berkeley",
  },
  {
    type: "School",
    name: "Estrella Mountain Community College",
  },
  {
    type: "School",
    name: "Heartland Community College",
  },
  {
    type: "School",
    name: "University of the Potomac-Washington DC Campus",
  },
  {
    type: "School",
    name: "Rabbi Jacob Joseph School",
  },
  {
    type: "School",
    name: "Sanford-Brown Institute-Ft Lauderdale",
  },
  {
    type: "School",
    name: "The Salon Professional Academy-Melbourne",
  },
  {
    type: "School",
    name: "Fortis Institute-Scranton",
  },
  {
    type: "School",
    name: "Milan Institute of Cosmetology-Amarillo",
  },
  {
    type: "School",
    name: "Everglades University",
  },
  {
    type: "School",
    name: "Seminar L'moros Bais Yaakov",
  },
  {
    type: "School",
    name: "Herzing University-Winter Park",
  },
  {
    type: "School",
    name: "Coral Ridge Nurses Assistant Training School Inc",
  },
  {
    type: "School",
    name: "Emil Fries Piano Hospital and Training Center",
  },
  {
    type: "School",
    name: "Fairview Beauty Academy",
  },
  {
    type: "School",
    name: "Florida College of Natural Health-Pompano Beach",
  },
  {
    type: "School",
    name: "National College-Nashville",
  },
  {
    type: "School",
    name: "College of Biblical Studies-Houston",
  },
  {
    type: "School",
    name: "Inner State Beauty School",
  },
  {
    type: "School",
    name: "Virginia College-Pensacola",
  },
  {
    type: "School",
    name: "Mid-EastCTC-Adult Education",
  },
  {
    type: "School",
    name: "Everest University-South Orlando",
  },
  {
    type: "School",
    name: "Polaris Career Center",
  },
  {
    type: "School",
    name: "P&A Scholars Beauty School",
  },
  {
    type: "School",
    name: "Rensselaer BOCES-Practical Nursing Program",
  },
  {
    type: "School",
    name: "ASM Beauty World Academy",
  },
  {
    type: "School",
    name: "The Salter School-Tewksbury Campus",
  },
  {
    type: "School",
    name: "Sanford-Brown College-Collinsville",
  },
  {
    type: "School",
    name: "Sharps Academy of Hair Styling",
  },
  {
    type: "School",
    name: "ICC Technical Institute",
  },
  {
    type: "School",
    name: "College of Health Care Professions-Northwest",
  },
  {
    type: "School",
    name: "School of Professional Horticulture at the New York Botanical Garden",
  },
  {
    type: "School",
    name: "Fortis College-Houston",
  },
  {
    type: "School",
    name: "Volunteer Beauty Academy-Madison",
  },
  {
    type: "School",
    name: "Watkins College of Art Design & Film",
  },
  {
    type: "School",
    name: "Chicago ORT Technical Institute",
  },
  {
    type: "School",
    name: "Alhambra Beauty College",
  },
  {
    type: "School",
    name: "American Auto Institute",
  },
  {
    type: "School",
    name: "Asian American International Beauty College",
  },
  {
    type: "School",
    name: "Avance Beauty College",
  },
  {
    type: "School",
    name: "Colton-Redlands-Yucaipa Regional Occupational Program",
  },
  {
    type: "School",
    name: "Copper Mountain Community College",
  },
  {
    type: "School",
    name: "Diversified Vocational College",
  },
  {
    type: "School",
    name: "Bristol University",
  },
  {
    type: "School",
    name: "UEI College-Fresno",
  },
  {
    type: "School",
    name: "Mt Sierra College",
  },
  {
    type: "School",
    name: "Wyotech-Long Beach",
  },
  {
    type: "School",
    name: "Santiago Canyon College",
  },
  {
    type: "School",
    name: "Southern California Institute of Technology",
  },
  {
    type: "School",
    name: "Soka University of America",
  },
  {
    type: "School",
    name: "Summit College",
  },
  {
    type: "School",
    name: "Advance Beauty College",
  },
  {
    type: "School",
    name: "Career Colleges of America",
  },
  {
    type: "School",
    name: "World Mission University",
  },
  {
    type: "School",
    name: "Yo San University of Traditional Chinese Medicine",
  },
  {
    type: "School",
    name: "Coast Career Institute",
  },
  {
    type: "School",
    name: "Cannella School of Hair Design-Blue Island",
  },
  {
    type: "School",
    name: "Delta College Inc",
  },
  {
    type: "School",
    name: "Colorado Technical University-Sioux Falls",
  },
  {
    type: "School",
    name: "Southwest University of Visual Arts-Albuquerque",
  },
  {
    type: "School",
    name: "Tri-State Cosmetology Institute",
  },
  {
    type: "School",
    name: "Widener University-Harrisburg Campus",
  },
  {
    type: "School",
    name: "Volunteer Beauty Academy-System Office",
  },
  {
    type: "School",
    name: "Volunteer Beauty Academy-Nashville",
  },
  {
    type: "School",
    name: "Frontier Community College",
  },
  {
    type: "School",
    name: "Lincoln Trail College",
  },
  {
    type: "School",
    name: "Wabash Valley College",
  },
  {
    type: "School",
    name: "American Institute of Interior Design",
  },
  {
    type: "School",
    name: "Brookline College-Tempe",
  },
  {
    type: "School",
    name: "Baker College of Auburn Hills",
  },
  {
    type: "School",
    name: "Baker College of Clinton Township",
  },
  {
    type: "School",
    name: "Inter American University of Puerto Rico-School of Optometry",
  },
  {
    type: "School",
    name: "Schiller International University",
  },
  {
    type: "School",
    name: "Vatterott College-Springfield",
  },
  {
    type: "School",
    name: "Vatterott College-Joplin",
  },
  {
    type: "School",
    name: "Vatterott College-Kansas City",
  },
  {
    type: "School",
    name: "Coconino Community College",
  },
  {
    type: "School",
    name: "University System of Maryland-Research Centers",
  },
  {
    type: "School",
    name: "Sanford-Brown College-Houston",
  },
  {
    type: "School",
    name: "Sanford-Brown Institute-Jacksonville",
  },
  {
    type: "School",
    name: "Sanford-Brown College-Dallas",
  },
  {
    type: "School",
    name: "Baker College of Cadillac",
  },
  {
    type: "School",
    name: "Emma's Beauty Academy-Juana Diaz",
  },
  {
    type: "School",
    name: "Franklin Academy",
  },
  {
    type: "School",
    name: "Columbia Centro Universitario-Yauco",
  },
  {
    type: "School",
    name: "Pima Medical Institute-Denver",
  },
  {
    type: "School",
    name: "Fortis College-Miami",
  },
  {
    type: "School",
    name: "ASA College",
  },
  {
    type: "School",
    name: "Ohio State School of Cosmetology-Westerville",
  },
  {
    type: "School",
    name: "Nationwide Beauty Academy",
  },
  {
    type: "School",
    name: "Yeshivas Novominsk",
  },
  {
    type: "School",
    name: "Southern Careers Institute-Corpus Christi",
  },
  {
    type: "School",
    name: "Kiamichi Technology Center-Stigler",
  },
  {
    type: "School",
    name: "Rabbinical College of Ohr Shimon Yisroel",
  },
  {
    type: "School",
    name: "Carroll Community College",
  },
  {
    type: "School",
    name: "University of Phoenix-Southern Arizona Campus",
  },
  {
    type: "School",
    name: "University of Phoenix-Colorado Campus",
  },
  {
    type: "School",
    name: "University of Phoenix-Albuquerque Campus",
  },
  {
    type: "School",
    name: "University of Phoenix-Bay Area Campus",
  },
  {
    type: "School",
    name: "University of Phoenix-Southern California Campus",
  },
  {
    type: "School",
    name: "Galen College of Nursing-Tampa Bay",
  },
  {
    type: "School",
    name: "Galen College of Nursing-San Antonio",
  },
  {
    type: "School",
    name: "Southeastern Beauty School-Columbus North Lumpkin",
  },
  {
    type: "School",
    name: "Southeastern Beauty School-Columbus Midtown",
  },
  {
    type: "School",
    name: "Sarasota School of Massage Therapy",
  },
  {
    type: "School",
    name: "Westwood College-Dupage",
  },
  {
    type: "School",
    name: "Wright Career College",
  },
  {
    type: "School",
    name: "Daymar College-Louisville",
  },
  {
    type: "School",
    name: "Sanford-Brown College-Hazelwood",
  },
  {
    type: "School",
    name: "Schuyler Steuben Chemung Tioga Allegany BOCES",
  },
  {
    type: "School",
    name: "Delaware Chenango Madison Otsego BOCES-Practical Nursing Program",
  },
  {
    type: "School",
    name: "DCI Career Institute",
  },
  {
    type: "School",
    name: "Central Susquehanna Intermediate Unit LPN Career",
  },
  {
    type: "School",
    name: "Myotherapy College of Utah",
  },
  {
    type: "School",
    name: "Graham Webb International Academy of Hair",
  },
  {
    type: "School",
    name: "City College-Gainesville",
  },
  {
    type: "School",
    name: "Arizona State University-West",
  },
  {
    type: "School",
    name: "Connecticut Center for Massage Therapy-Westport",
  },
  {
    type: "School",
    name: "ITT Technical Institute-Jacksonville",
  },
  {
    type: "School",
    name: "G Skin & Beauty Institute",
  },
  {
    type: "School",
    name: "Cosmetology Training Center",
  },
  {
    type: "School",
    name: "Stage One-The Hair School",
  },
  {
    type: "School",
    name: "Empire Beauty School-Malden",
  },
  {
    type: "School",
    name: "Minnesota School of Business-Brooklyn Center",
  },
  {
    type: "School",
    name: "ITT Technical Institute-Omaha",
  },
  {
    type: "School",
    name: "Omaha School of Massage and Healthcare of Herzing University",
  },
  {
    type: "School",
    name: "Xenon International Academy-Omaha",
  },
  {
    type: "School",
    name: "Capri Institute of Hair Design-Brick",
  },
  {
    type: "School",
    name: "Capri Institute of Hair Design-Kenilworth",
  },
  {
    type: "School",
    name: "Capri Institute of Hair Design-Paramus",
  },
  {
    type: "School",
    name: "Orlo School of Hair Design and Cosmetology",
  },
  {
    type: "School",
    name: "Delaware Area Career Center",
  },
  {
    type: "School",
    name: "Eastland-Fairfield Career and Technical Schools",
  },
  {
    type: "School",
    name: "Empire Beauty School-Cincinnati",
  },
  {
    type: "School",
    name: "Mahoning County Career and Technical Center",
  },
  {
    type: "School",
    name: "Ohio Hi Point Joint Vocational School District",
  },
  {
    type: "School",
    name: "Pickaway Ross Joint Vocational School District",
  },
  {
    type: "School",
    name: "Pioneer Career and Technology Center",
  },
  {
    type: "School",
    name: "Raphael's School of Beauty Culture Inc-Boardman",
  },
  {
    type: "School",
    name: "Scioto County Career Technical Center",
  },
  {
    type: "School",
    name: "Trumbull Career & Technical Center",
  },
  {
    type: "School",
    name: "Northwest Technology Center-Fairview",
  },
  {
    type: "School",
    name: "Lake Erie College of Osteopathic Medicine",
  },
  {
    type: "School",
    name: "University of Cosmetology Arts & Sciences-Harlingen",
  },
  {
    type: "School",
    name: "Gene Juarez Academy of Beauty-Federal Way",
  },
  {
    type: "School",
    name: "Empire Beauty School-Appleton",
  },
  {
    type: "School",
    name: "Empire Beauty School-Green Bay",
  },
  {
    type: "School",
    name: "Warren County Career Center",
  },
  {
    type: "School",
    name: "Brown Mackie College-Fort Wayne",
  },
  {
    type: "School",
    name: "Paul Mitchell the School-Wichita",
  },
  {
    type: "School",
    name: "Pinnacle Institute of Cosmetology",
  },
  {
    type: "School",
    name: "Career Training Academy-Monroeville",
  },
  {
    type: "School",
    name: "Southern Careers Institute-Pharr",
  },
  {
    type: "School",
    name: "Texas State Technical College-Marshall",
  },
  {
    type: "School",
    name: "Florida National University-Main Campus",
  },
  {
    type: "School",
    name: "Georgia Institute of Cosmetology",
  },
  {
    type: "School",
    name: "Nouvelle Institute",
  },
  {
    type: "School",
    name: "ITT Technical Institute-Fort Lauderdale",
  },
  {
    type: "School",
    name: "Franklin Technology-MSSU",
  },
  {
    type: "School",
    name: "Carson City Beauty Academy",
  },
  {
    type: "School",
    name: "Professional Technical Institution Inc",
  },
  {
    type: "School",
    name: "Erikson Institute",
  },
  {
    type: "School",
    name: "South Texas College",
  },
  {
    type: "School",
    name: "Tennessee Board of Regents",
  },
  {
    type: "School",
    name: "Pike County Joint Vocational School District",
  },
  {
    type: "School",
    name: "Lorain County Joint Vocational School District",
  },
  {
    type: "School",
    name: "Roger Williams University School of Law",
  },
  {
    type: "School",
    name: "Everest Institute-Kendall",
  },
  {
    type: "School",
    name: "California State University-Monterey Bay",
  },
  {
    type: "School",
    name: "Sanford-Brown College-St Peters",
  },
  {
    type: "School",
    name: "Colorado Technical University-Kansas City",
  },
  {
    type: "School",
    name: "Heald College-Salinas",
  },
  {
    type: "School",
    name: "Academy of Somatic Healing Arts",
  },
  {
    type: "School",
    name: "Heritage College-Oklahoma City",
  },
  {
    type: "School",
    name: "Ann Arbor Institute of Massage Therapy",
  },
  {
    type: "School",
    name: "Brown Mackie College-Atlanta",
  },
  {
    type: "School",
    name: "Bryant & Stratton College-Henrietta",
  },
  {
    type: "School",
    name: "The Art Institute of California-Argosy University Hollywood",
  },
  {
    type: "School",
    name: "Sage College",
  },
  {
    type: "School",
    name: "Anthem College-Denver",
  },
  {
    type: "School",
    name: "Lincoln Technical Institute-Hartford",
  },
  {
    type: "School",
    name: "Ohio Center for Broadcasting-Valley View",
  },
  {
    type: "School",
    name: "EINE Inc",
  },
  {
    type: "School",
    name: "Laurus Technical Institute",
  },
  {
    type: "School",
    name: "Illinois School of Health Careers-Chicago Campus",
  },
  {
    type: "School",
    name: "Marian Health Careers Center-Los Angeles Campus",
  },
  {
    type: "School",
    name: "Cleveland Clinic Health System-School of Diagnostic Imaging",
  },
  {
    type: "School",
    name: "National Institute of Massotherapy",
  },
  {
    type: "School",
    name: "Academy for Nursing and Health Occupations",
  },
  {
    type: "School",
    name: "Shawsheen Valley Regional Vocational Technical School",
  },
  {
    type: "School",
    name: "Shear Ego International School of Hair Design",
  },
  {
    type: "School",
    name: "Remington College-Memphis Campus",
  },
  {
    type: "School",
    name: "Star Truck Driving School-Hickory Hills",
  },
  {
    type: "School",
    name: "Fayette Institute of Technology",
  },
  {
    type: "School",
    name: "Professional Beauty School",
  },
  {
    type: "School",
    name: "Capella University",
  },
  {
    type: "School",
    name: "College of Menominee Nation",
  },
  {
    type: "School",
    name: "Leech Lake Tribal College",
  },
  {
    type: "School",
    name: "Anthem College-Sacramento",
  },
  {
    type: "School",
    name: "Southwest Acupuncture College-Albuquerque",
  },
  {
    type: "School",
    name: "Crimson Technical College",
  },
  {
    type: "School",
    name: "Stone Academy-Waterbury",
  },
  {
    type: "School",
    name: "East San Gabriel Valley Regional Occupational Program",
  },
  {
    type: "School",
    name: "Yukon Beauty College Inc",
  },
  {
    type: "School",
    name: "ITT Technical Institute-Little Rock",
  },
  {
    type: "School",
    name: "ITT Technical Institute-Oxnard",
  },
  {
    type: "School",
    name: "ITT Technical Institute-Louisville",
  },
  {
    type: "School",
    name: "ITT Technical Institute-Greenville",
  },
  {
    type: "School",
    name: "ITT Technical Institute-Cordova",
  },
  {
    type: "School",
    name: "Cairn University-Wisconsin Wilderness",
  },
  {
    type: "School",
    name: "Toni & Guy Hairdressing Academy-Atlanta",
  },
  {
    type: "School",
    name: "Mitchell's Hair Styling Academy-Raleigh",
  },
  {
    type: "School",
    name: "Salon Academy",
  },
  {
    type: "School",
    name: "Artistic Academy of Hair Design",
  },
  {
    type: "School",
    name: "Motoring Technical Training Institute",
  },
  {
    type: "School",
    name: "Baker College of Jackson",
  },
  {
    type: "School",
    name: "Phagans Newport Academy of Cosmetology Careers",
  },
  {
    type: "School",
    name: "Mech-Tech College",
  },
  {
    type: "School",
    name: "Baldwin Beauty School-North Austin",
  },
  {
    type: "School",
    name: "ITT Technical Institute-Everett",
  },
  {
    type: "School",
    name: "Jean Madeline Aveda Institute",
  },
  {
    type: "School",
    name: "Cosmetology Schools Inc",
  },
  {
    type: "School",
    name: "ITT Technical Institute-Bessemer",
  },
  {
    type: "School",
    name: "ITT Technical Institute-Orland Park",
  },
  {
    type: "School",
    name: "Pacific College of Oriental Medicine-New York",
  },
  {
    type: "School",
    name: "Baker College Center for Graduate Studies",
  },
  {
    type: "School",
    name: "Johnson & Wales University-North Miami",
  },
  {
    type: "School",
    name: "ITT Technical Institute-Pittsburgh",
  },
  {
    type: "School",
    name: "Tricoci University of Beauty Culture-Indianapolis",
  },
  {
    type: "School",
    name: "Trine University-Regional/Non-Traditional Campuses",
  },
  {
    type: "School",
    name: "Pennsylvania Highlands Community College",
  },
  {
    type: "School",
    name: "Nevada Career Institute",
  },
  {
    type: "School",
    name: "Phillips Theological Seminary",
  },
  {
    type: "School",
    name: "Empire Beauty School-Hanover",
  },
  {
    type: "School",
    name: "Industrial Management Training Institute",
  },
  {
    type: "School",
    name: "Faris Technical Institute Inc",
  },
  {
    type: "School",
    name: "Four-D College",
  },
  {
    type: "School",
    name: "Allen School-Jamaica",
  },
  {
    type: "School",
    name: "Premiere Career College",
  },
  {
    type: "School",
    name: "Tennessee Academy of Cosmetology-Stage Road",
  },
  {
    type: "School",
    name: "The University of Texas MD Anderson Cancer Center",
  },
  {
    type: "School",
    name: "University of Phoenix-Las Vegas Campus",
  },
  {
    type: "School",
    name: "Carousel Beauty College-Springfield",
  },
  {
    type: "School",
    name: "Carousel Beauty College-Kettering",
  },
  {
    type: "School",
    name: "Paul Mitchell the School-Huntsville",
  },
  {
    type: "School",
    name: "Estes Institute of Cosmetology Arts and Science",
  },
  {
    type: "School",
    name: "Baldwin Park Adult & Community Education",
  },
  {
    type: "School",
    name: "Ventura Adult and Continuing Education",
  },
  {
    type: "School",
    name: "Marinello Schools of Beauty-Sacramento",
  },
  {
    type: "School",
    name: "Albert I Prince Technical High School",
  },
  {
    type: "School",
    name: "Bullard-Havens Technical High School",
  },
  {
    type: "School",
    name: "Howell Cheney Technical High School",
  },
  {
    type: "School",
    name: "W F Kaynor Technical High School",
  },
  {
    type: "School",
    name: "Platt Technical High School",
  },
  {
    type: "School",
    name: "College of Business and Technology-Kendall",
  },
  {
    type: "School",
    name: "City College-Altamonte Springs",
  },
  {
    type: "School",
    name: "Augusta Area Dietetic Internship-University Hospital",
  },
  {
    type: "School",
    name: "Empire Beauty School-Dunwoody",
  },
  {
    type: "School",
    name: "Michael's School of Beauty",
  },
  {
    type: "School",
    name: "Cobb Beauty College Inc",
  },
  {
    type: "School",
    name: "Career Center of Southern Illinois",
  },
  {
    type: "School",
    name: "Xenon International Academy-Wichita",
  },
  {
    type: "School",
    name: "Camelot College",
  },
  {
    type: "School",
    name: "Mr Bernard's School of Hair Fashion Inc",
  },
  {
    type: "School",
    name: "Hastings Beauty School",
  },
  {
    type: "School",
    name: "Kirksville Area Technical Center",
  },
  {
    type: "School",
    name: "South Central Career Center",
  },
  {
    type: "School",
    name: "Saline County Career Center",
  },
  {
    type: "School",
    name: "Warrensburg Area Career Center",
  },
  {
    type: "School",
    name: "Nevada Regional Technical Center",
  },
  {
    type: "School",
    name: "Cass Career Center",
  },
  {
    type: "School",
    name: "Applied Technology Services",
  },
  {
    type: "School",
    name: "Central College of Cosmetology",
  },
  {
    type: "School",
    name: "Southeast Missouri Hospital College of Nursing and Health Sciences",
  },
  {
    type: "School",
    name: "Dallas County Technical Center",
  },
  {
    type: "School",
    name: "Messenger College",
  },
  {
    type: "School",
    name: "Tennessee Academy of Cosmetology-Shelby Drive",
  },
  {
    type: "School",
    name: "Cheveux School of Hair Design",
  },
  {
    type: "School",
    name: "Durham Beauty Academy",
  },
  {
    type: "School",
    name: "Union County Vocational Technical School",
  },
  {
    type: "School",
    name: "Prism Career Institute-Philadelphia",
  },
  {
    type: "School",
    name: "Clinton Essex Warren Washington BOCES-Practical Nursing Program",
  },
  {
    type: "School",
    name: "Jefferson Lewis BOCES-Practical Nursing Program",
  },
  {
    type: "School",
    name: "Rochester General Hospital School of Medical Technology",
  },
  {
    type: "School",
    name: "Veeb Nassau County School of Practical Nursing",
  },
  {
    type: "School",
    name: "Syracuse City Schools Practical Nursing Program",
  },
  {
    type: "School",
    name: "Cattaraugus Allegany BOCES-Practical Nursing Program",
  },
  {
    type: "School",
    name: "Orange Ulster BOCES-Practical Nursing Program",
  },
  {
    type: "School",
    name: "Wayne Finger Lakes BOCES-Practical Nursing Program",
  },
  {
    type: "School",
    name: "Broome Delaware Tioga BOCES-Practical Nursing Program",
  },
  {
    type: "School",
    name: "Washington Saratoga Warren Hamilton Essex BOCES-Practical Nursing Program",
  },
  {
    type: "School",
    name: "Albany BOCES-Adult Practical Nursing Program",
  },
  {
    type: "School",
    name: "Ulster County BOCES-Practical Nursing Program",
  },
  {
    type: "School",
    name: "Eastern Suffolk BOCES-Practical Nursing Program",
  },
  {
    type: "School",
    name: "Rockland County BOCES-Practical Nursing Program",
  },
  {
    type: "School",
    name: "New York College of Health Professions",
  },
  {
    type: "School",
    name: "Career Institute of Health and Technology",
  },
  {
    type: "School",
    name: "Charles Stuart School of Diamond Setting",
  },
  {
    type: "School",
    name: "Penta County Joint Vocational School",
  },
  {
    type: "School",
    name: "Career and Technology Education Centers of Licking County",
  },
  {
    type: "School",
    name: "Willoughby-Eastlake School of Practical Nursing",
  },
  {
    type: "School",
    name: "Washington County Career Center-Adult Technical Training",
  },
  {
    type: "School",
    name: "Ohio Center for Broadcasting-Cincinnati",
  },
  {
    type: "School",
    name: "Vantage Career Center",
  },
  {
    type: "School",
    name: "Tri County Technology Center",
  },
  {
    type: "School",
    name: "Indian Capital Technology Center-Muskogee",
  },
  {
    type: "School",
    name: "Western Technology Center",
  },
  {
    type: "School",
    name: "Mid-America Technology Center",
  },
  {
    type: "School",
    name: "Northeast Technology Center-Pryor",
  },
  {
    type: "School",
    name: "Eastern Oklahoma County Technology Center",
  },
  {
    type: "School",
    name: "Wes Watkins Technology Center",
  },
  {
    type: "School",
    name: "Technical Institute of Cosmetology Arts and Sciences",
  },
  {
    type: "School",
    name: "York County School of Technology-Adult & Continuing Education",
  },
  {
    type: "School",
    name: "Chester County Intermediate Unit",
  },
  {
    type: "School",
    name: "Jefferson County Dubois Area Vocational Technical Practical Nursing Program",
  },
  {
    type: "School",
    name: "Greater Johnstown Career and Technology Center",
  },
  {
    type: "School",
    name: "Mifflin-Juniata Career and Technology Center",
  },
  {
    type: "School",
    name: "Hanover Public School District Practical Nursing Program",
  },
  {
    type: "School",
    name: "Lenape Technical School Practical Nursing Program",
  },
  {
    type: "School",
    name: "Venango County Area Vocational Technical School",
  },
  {
    type: "School",
    name: "Lancaster County Career and Technology Center",
  },
  {
    type: "School",
    name: "Lebanon County Area Vocational Technical School",
  },
  {
    type: "School",
    name: "Indiana County Technology Center",
  },
  {
    type: "School",
    name: "Northern Tier Career Center",
  },
  {
    type: "School",
    name: "Clarion County Career Center Practical Nursing Program",
  },
  {
    type: "School",
    name: "Hazleton Area Career Center",
  },
  {
    type: "School",
    name: "All-State Career School-Pittsburgh",
  },
  {
    type: "School",
    name: "Carlos F Daniels Area Vocational School",
  },
  {
    type: "School",
    name: "Serbias Technical College",
  },
  {
    type: "School",
    name: "Colegio Tecnologico y Comercial de Puerto Rico",
  },
  {
    type: "School",
    name: "Quality Technical and Beauty College",
  },
  {
    type: "School",
    name: "D'Mart Institute",
  },
  {
    type: "School",
    name: "Automeca Technical College-Aguadilla",
  },
  {
    type: "School",
    name: "Fortis Institute-Cookeville",
  },
  {
    type: "School",
    name: "North Central Institute",
  },
  {
    type: "School",
    name: "Pipo Academy of Hair Design",
  },
  {
    type: "School",
    name: "Tint School of Makeup and Cosmetology-Dallas",
  },
  {
    type: "School",
    name: "United Technical Center",
  },
  {
    type: "School",
    name: "Charles H McCann Technical School",
  },
  {
    type: "School",
    name: "Ailano School of Cosmetology",
  },
  {
    type: "School",
    name: "Carsten Institute of Cosmetology",
  },
  {
    type: "School",
    name: "Central California School",
  },
  {
    type: "School",
    name: "Mercy Hospital School of Practical Nursing-Plantation General Hospital",
  },
  {
    type: "School",
    name: "Marion County Community Technical and Adult Education Center",
  },
  {
    type: "School",
    name: "Rivertown School of Beauty Barber Skin Care and Nails",
  },
  {
    type: "School",
    name: "Atlanta Institute of Music",
  },
  {
    type: "School",
    name: "Illinois Center for Broadcasting",
  },
  {
    type: "School",
    name: "Marion Community Schools-Tucker Career & Technology Center",
  },
  {
    type: "School",
    name: "St Louis Hair Academy",
  },
  {
    type: "School",
    name: "New Dimensions School of Hair Design",
  },
  {
    type: "School",
    name: "Adirondack Beauty School",
  },
  {
    type: "School",
    name: "Forbes Road Career and Technology Center",
  },
  {
    type: "School",
    name: "Jna Institute of Culinary Arts",
  },
  {
    type: "School",
    name: "Cortiva Institute-Seattle",
  },
  {
    type: "School",
    name: "Putnam Career and Technical Center",
  },
  {
    type: "School",
    name: "Argosy University-Washington DC",
  },
  {
    type: "School",
    name: "Crawford County Career and Technical Center Practical Nursing Program",
  },
  {
    type: "School",
    name: "University of Phoenix-Sacramento Valley Campus",
  },
  {
    type: "School",
    name: "DeVry University's Keller Graduate School of Management-Georgia",
  },
  {
    type: "School",
    name: "Texas College of Cosmetology-San Angelo",
  },
  {
    type: "School",
    name: "Beauty Schools of America-Hialeah",
  },
  {
    type: "School",
    name: "Automeca Technical College-Bayamon",
  },
  {
    type: "School",
    name: "Automeca Technical College-Caguas",
  },
  {
    type: "School",
    name: "Automeca Technical College-Ponce",
  },
  {
    type: "School",
    name: "Everest University-Melbourne",
  },
  {
    type: "School",
    name: "Centura College-Chesapeake",
  },
  {
    type: "School",
    name: "University of Phoenix-Hawaii Campus",
  },
  {
    type: "School",
    name: "Florida College of Natural Health-Miami",
  },
  {
    type: "School",
    name: "Prism Career Institute-Cherry Hill",
  },
  {
    type: "School",
    name: "Amerian National College",
  },
  {
    type: "School",
    name: "Francois D College of Hair Skin and Nails",
  },
  {
    type: "School",
    name: "Southwest College of Naturopathic Medicine & Health Sciences",
  },
  {
    type: "School",
    name: "Escuela de Peritos Electricistas de Isabela Inc",
  },
  {
    type: "School",
    name: "Virginia College-Birmingham",
  },
  {
    type: "School",
    name: "Virginia College-Huntsville",
  },
  {
    type: "School",
    name: "Yeshiva D'monsey Rabbinical College",
  },
  {
    type: "School",
    name: "Lincoln Technical Institute-Mahwah",
  },
  {
    type: "School",
    name: "Career Technical Institute",
  },
  {
    type: "School",
    name: "Northwest Vista College",
  },
  {
    type: "School",
    name: "ITT Technical Institute-Getzville",
  },
  {
    type: "School",
    name: "Oconee Fall Line Technical College",
  },
  {
    type: "School",
    name: "York County Community College",
  },
  {
    type: "School",
    name: "Northeast Technology Center-Afton",
  },
  {
    type: "School",
    name: "Western Suffolk BOCES",
  },
  {
    type: "School",
    name: "Sanford-Brown College-Atlanta",
  },
  {
    type: "School",
    name: "Sanford-Brown College-Middleburg Heights",
  },
  {
    type: "School",
    name: "Arkansas State University-Mountain Home",
  },
  {
    type: "School",
    name: "Columbia Gorge Community College",
  },
  {
    type: "School",
    name: "Arizona State University-Polytechnic",
  },
  {
    type: "School",
    name: "Colleen O'Haras Beauty Academy",
  },
  {
    type: "School",
    name: "Bakke Graduate University",
  },
  {
    type: "School",
    name: "Tillamook Bay Community College",
  },
  {
    type: "School",
    name: "Arizona Board of Regents",
  },
  {
    type: "School",
    name: "Argosy University-Schaumburg",
  },
  {
    type: "School",
    name: "DeVry University's Keller Graduate School of Management-California",
  },
  {
    type: "School",
    name: "Manhattan School of Computer Technology",
  },
  {
    type: "School",
    name: "University of Phoenix-Louisiana Campus",
  },
  {
    type: "School",
    name: "University of Phoenix-Detroit Campus",
  },
  {
    type: "School",
    name: "Brown Mackie College-Hopkinsville",
  },
  {
    type: "School",
    name: "Indian Capital Technology Center-Sallisaw",
  },
  {
    type: "School",
    name: "Indian Capital Technology Center-Stilwell",
  },
  {
    type: "School",
    name: "Globelle Technical Institute",
  },
  {
    type: "School",
    name: "Hairitage Hair Academy",
  },
  {
    type: "School",
    name: "Houghton Lake Institute of Cosmetology",
  },
  {
    type: "School",
    name: "Arizona College-Glendale",
  },
  {
    type: "School",
    name: "Vanguard-Sentinel Adult Career and Technology Center",
  },
  {
    type: "School",
    name: "House of Heavilin Beauty College-Raymore",
  },
  {
    type: "School",
    name: "Living Arts College",
  },
  {
    type: "School",
    name: "New York Conservatory for Dramatic Arts",
  },
  {
    type: "School",
    name: "Eastern International College-Jersey City",
  },
  {
    type: "School",
    name: "Academia Serrant Inc",
  },
  {
    type: "School",
    name: "San Joaquin Valley College-Fresno Aviation",
  },
  {
    type: "School",
    name: "Champion Institute of Cosmetology",
  },
  {
    type: "School",
    name: "Pacific College",
  },
  {
    type: "School",
    name: "American College of Healthcare",
  },
  {
    type: "School",
    name: "Compu-Med Vocational Careers Corp",
  },
  {
    type: "School",
    name: "Professional Electrical School Inc",
  },
  {
    type: "School",
    name: "American Commercial College-Wichita Falls",
  },
  {
    type: "School",
    name: "Midwestern University-Glendale",
  },
  {
    type: "School",
    name: "Oregon Coast Community College",
  },
  {
    type: "School",
    name: "Le Cordon Bleu College of Culinary Arts-Pasadena",
  },
  {
    type: "School",
    name: "Gwinnett College-Sandy Springs",
  },
  {
    type: "School",
    name: "Central Mass School of Massage and Therapy Inc",
  },
  {
    type: "School",
    name: "Lincoln Technical Institute-Brockton",
  },
  {
    type: "School",
    name: "Mildred Elley-Pittsfield Campus",
  },
  {
    type: "School",
    name: "Embry-Riddle Aeronautical University-Worldwide",
  },
  {
    type: "School",
    name: "Computer Systems Institute",
  },
  {
    type: "School",
    name: "Merkaz Bnos-Business School",
  },
  {
    type: "School",
    name: "Lia Schorr Institute of Cosmetic Skin Care Training",
  },
  {
    type: "School",
    name: "ITT Technical Institute-Webster",
  },
  {
    type: "School",
    name: "CET-Alexandria",
  },
  {
    type: "School",
    name: "CET-Durham",
  },
  {
    type: "School",
    name: "Aviation Institute of Maintenance-Chesapeake",
  },
  {
    type: "School",
    name: "Centura College-Richmond Main",
  },
  {
    type: "School",
    name: "Technical Education Center-Osceola",
  },
  {
    type: "School",
    name: "Miami Ad School-Miami Beach",
  },
  {
    type: "School",
    name: "Green Country Technology Center",
  },
  {
    type: "School",
    name: "Nova College de Puerto Rico",
  },
  {
    type: "School",
    name: "Styles and Profiles Beauty College",
  },
  {
    type: "School",
    name: "John Wesley International Barber and Beauty College",
  },
  {
    type: "School",
    name: "Platt College-Lawton",
  },
  {
    type: "School",
    name: "Southeastern College-Greenacres",
  },
  {
    type: "School",
    name: "Ottawa University-Milwaukee",
  },
  {
    type: "School",
    name: "Argosy University-Tampa",
  },
  {
    type: "School",
    name: "The Illinois Institute of Art-Schaumburg",
  },
  {
    type: "School",
    name: "Star Truck Driving School-Bensenville",
  },
  {
    type: "School",
    name: "Cambria-Rowe Business College-Indiana",
  },
  {
    type: "School",
    name: "Facultad de Derecho Eugenio Maria de Hostos",
  },
  {
    type: "School",
    name: "Klamath Community College",
  },
  {
    type: "School",
    name: "San Bernardino Community College District",
  },
  {
    type: "School",
    name: "The Art Institute of Phoenix",
  },
  {
    type: "School",
    name: "Aerosim Flight Academy",
  },
  {
    type: "School",
    name: "American College of Acupuncture and Oriental Med",
  },
  {
    type: "School",
    name: "AOMA Graduate School of Integrative Medicine",
  },
  {
    type: "School",
    name: "Arlington Medical Institute",
  },
  {
    type: "School",
    name: "Urban College of Boston",
  },
  {
    type: "School",
    name: "ITT Technical Institute-Henderson",
  },
  {
    type: "School",
    name: "Anthem College-Minnesota",
  },
  {
    type: "School",
    name: "Colorado Technical University-Greenwood Village",
  },
  {
    type: "School",
    name: "Branford Hall Career Institute-Windsor Campus",
  },
  {
    type: "School",
    name: "Heald College-Portland",
  },
  {
    type: "School",
    name: "Golf Academy of America-Altamonte Springs",
  },
  {
    type: "School",
    name: "Golf Academy of America-Phoenix",
  },
  {
    type: "School",
    name: "Everest College-Merrillville",
  },
  {
    type: "School",
    name: "Strayer University-Maryland",
  },
  {
    type: "School",
    name: "Empire Beauty School-Bordentown",
  },
  {
    type: "School",
    name: "Empire Beauty School-Monroeville",
  },
  {
    type: "School",
    name: "Southern Careers Institute-San Antonio",
  },
  {
    type: "School",
    name: "Virginia School of Massage",
  },
  {
    type: "School",
    name: "ITT Technical Institute-Miami",
  },
  {
    type: "School",
    name: "Cayce Reilly School of Massotherapy",
  },
  {
    type: "School",
    name: "CET-Sacramento",
  },
  {
    type: "School",
    name: "ITT Technical Institute-Harrisburg",
  },
  {
    type: "School",
    name: "ITT Technical Institute-Tarentum",
  },
  {
    type: "School",
    name: "ITT Technical Institute-Norwood",
  },
  {
    type: "School",
    name: "ITT Technical Institute-Strongsville",
  },
  {
    type: "School",
    name: "Prism Career Institute-West Atlantic City",
  },
  {
    type: "School",
    name: "Everest Institute-Marietta",
  },
  {
    type: "School",
    name: "The Creative Center",
  },
  {
    type: "School",
    name: "Studio Jewelers",
  },
  {
    type: "School",
    name: "Praxis Institute",
  },
  {
    type: "School",
    name: "American College of Traditional Chinese Medicine",
  },
  {
    type: "School",
    name: "Herndon Career Center",
  },
  {
    type: "School",
    name: "San Juan Bautista School of Medicine",
  },
  {
    type: "School",
    name: "Texas Health and Science University",
  },
  {
    type: "School",
    name: "Carver Career Center",
  },
  {
    type: "School",
    name: "New Hampshire Institute of Art",
  },
  {
    type: "School",
    name: "StenoTech Career Institute-Fairfield",
  },
  {
    type: "School",
    name: "Cayuga Onondaga BOCES-Practical Nursing Program",
  },
  {
    type: "School",
    name: "Clearfield County Career and Technology Center",
  },
  {
    type: "School",
    name: "Colegio de Cinematograf�a Artes y Television",
  },
  {
    type: "School",
    name: "Delaware County Technical School-Practical Nursing Program",
  },
  {
    type: "School",
    name: "Everest College-Torrance",
  },
  {
    type: "School",
    name: "The Institute for Health Education",
  },
  {
    type: "School",
    name: "Mid-Del Technology Center",
  },
  {
    type: "School",
    name: "BIR Training Center",
  },
  {
    type: "School",
    name: "New York Institute of Massage Inc",
  },
  {
    type: "School",
    name: "Jolie Hair and Beauty Academy-Ludlow",
  },
  {
    type: "School",
    name: "Dutchess BOCES-Practical Nursing Program",
  },
  {
    type: "School",
    name: "Escuela Tecnica de Electricidad",
  },
  {
    type: "School",
    name: "Institute of Technology Inc",
  },
  {
    type: "School",
    name: "Garnet Career Center",
  },
  {
    type: "School",
    name: "Bristol Technical Education Center",
  },
  {
    type: "School",
    name: "Genesee Valley BOCES-Practical Nursing Program",
  },
  {
    type: "School",
    name: "Hamilton Fulton Montgomery BOCES-Practical Nursing Program",
  },
  {
    type: "School",
    name: "Henrico County-Saint Marys Hospital School of Practical Nursing",
  },
  {
    type: "School",
    name: "Herkimer County BOCES-Practical Nursing Program",
  },
  {
    type: "School",
    name: "International Beauty College",
  },
  {
    type: "School",
    name: "Dewey University-Hato Rey",
  },
  {
    type: "School",
    name: "Cape Coral Institute of Technology",
  },
  {
    type: "School",
    name: "Massachusetts General Hospital Dietetic Internship",
  },
  {
    type: "School",
    name: "Mercy Hospital School of Nursing",
  },
  {
    type: "School",
    name: "American Beauty Academy",
  },
  {
    type: "School",
    name: "Northland Career Center",
  },
  {
    type: "School",
    name: "Norwich Technical High School",
  },
  {
    type: "School",
    name: "Ocean County Vocational-Technical School",
  },
  {
    type: "School",
    name: "Putnam Westchester BOCES-Practical Nursing Program",
  },
  {
    type: "School",
    name: "Sullivan County BOCES-Practical Nursing Program",
  },
  {
    type: "School",
    name: "Kaplan College-San Antonio-Ingram",
  },
  {
    type: "School",
    name: "Trinity College of Puerto Rico",
  },
  {
    type: "School",
    name: "Virginia Sewing Machines and School Center",
  },
  {
    type: "School",
    name: "Vogue Beauty and Barber School",
  },
  {
    type: "School",
    name: "Yeshiva of the Telshe Alumni",
  },
  {
    type: "School",
    name: "Northeast Technology Center-Kansas",
  },
  {
    type: "School",
    name: "Kenneth Shuler School of Cosmetology-Rock Hill",
  },
  {
    type: "School",
    name: "South Orange County Community College District",
  },
  {
    type: "School",
    name: "DeVry College of New York",
  },
  {
    type: "School",
    name: "DeVry University-Indiana",
  },
  {
    type: "School",
    name: "University of Phoenix-Western Washington Campus",
  },
  {
    type: "School",
    name: "University of Phoenix-Central Florida Campus",
  },
  {
    type: "School",
    name: "University of Phoenix-Oregon Campus",
  },
  {
    type: "School",
    name: "CET-El Paso",
  },
  {
    type: "School",
    name: "Kaplan College-Riverside",
  },
  {
    type: "School",
    name: "Appalachian School of Law",
  },
  {
    type: "School",
    name: "LIU Hudson at Westchester",
  },
  {
    type: "School",
    name: "Platt College-Ontario",
  },
  {
    type: "School",
    name: "Delaware College of Art and Design",
  },
  {
    type: "School",
    name: "The Art Institute of California-Argosy University Los Angeles",
  },
  {
    type: "School",
    name: "American University of Health Sciences",
  },
  {
    type: "School",
    name: "Career Networks Institute",
  },
  {
    type: "School",
    name: "Monty Tech",
  },
  {
    type: "School",
    name: "CHOICE High School and Technical Center",
  },
  {
    type: "School",
    name: "Lincoln Technical Institute-Lincoln",
  },
  {
    type: "School",
    name: "Wayne County Schools Career Center",
  },
  {
    type: "School",
    name: "Blessing Hospital School of Medical Laboratory Technology",
  },
  {
    type: "School",
    name: "Carolinas College of Health Sciences",
  },
  {
    type: "School",
    name: "Lester E Cox Medical Center-School of Medical Technology",
  },
  {
    type: "School",
    name: "Western Governors University",
  },
  {
    type: "School",
    name: "Santa Barbara Business College-Ventura",
  },
  {
    type: "School",
    name: "Sullivan and Cogliano Training Center",
  },
  {
    type: "School",
    name: "Herzing University-Kenner",
  },
  {
    type: "School",
    name: "Spencerian College-Lexington",
  },
  {
    type: "School",
    name: "Florida Gulf Coast University",
  },
  {
    type: "School",
    name: "Seacoast Career Schools-Sanford Campus",
  },
  {
    type: "School",
    name: "Little Priest Tribal College",
  },
  {
    type: "School",
    name: "National Labor College",
  },
  {
    type: "School",
    name: "ITT Technical Institute-Richardson",
  },
  {
    type: "School",
    name: "South Louisiana Community College",
  },
  {
    type: "School",
    name: "HRDE-Stanley Technical Institute-Parkersburg",
  },
  {
    type: "School",
    name: "Pima Medical Institute-Chula Vista",
  },
  {
    type: "School",
    name: "Lincoln College of Technology-Marietta",
  },
  {
    type: "School",
    name: "Academy of Hair Design-Lufkin",
  },
  {
    type: "School",
    name: "Academy of Hair Design-Beaumont",
  },
  {
    type: "School",
    name: "Baltimore School of Massage-Linthicum",
  },
  {
    type: "School",
    name: "Charter College-Canyon Country",
  },
  {
    type: "School",
    name: "Toni & Guy Hairdressing Academy-Worcester",
  },
  {
    type: "School",
    name: "Northwest HVAC/R Training Center",
  },
  {
    type: "School",
    name: "L T International Beauty School",
  },
  {
    type: "School",
    name: "Michigan Jewish Institute",
  },
  {
    type: "School",
    name: "Myotherapy Institute",
  },
  {
    type: "School",
    name: "Florida College of Integrative Medicine",
  },
  {
    type: "School",
    name: "New Professions Technical Institute",
  },
  {
    type: "School",
    name: "Strand College of Hair Design",
  },
  {
    type: "School",
    name: "Cosmetology Career Institute",
  },
  {
    type: "School",
    name: "Branford Hall Career Institute-Southington Campus",
  },
  {
    type: "School",
    name: "City College-Miami",
  },
  {
    type: "School",
    name: "ITT Technical Institute-Arnold",
  },
  {
    type: "School",
    name: "ITT Technical Institute-Oak Brook",
  },
  {
    type: "School",
    name: "ITT Technical Institute-Albany",
  },
  {
    type: "School",
    name: "ITT Technical Institute-Liverpool",
  },
  {
    type: "School",
    name: "Ilisagvik College",
  },
  {
    type: "School",
    name: "The Community College of Baltimore County",
  },
  {
    type: "School",
    name: "Golf Academy of America-Myrtle Beach",
  },
  {
    type: "School",
    name: "Florida Coastal School of Law",
  },
  {
    type: "School",
    name: "White Earth Tribal and Community College",
  },
  {
    type: "School",
    name: "Centura College-North Charleston",
  },
  {
    type: "School",
    name: "Blue Cliff College-Metairie",
  },
  {
    type: "School",
    name: "Creative Images Institute of Cosmetology-North Dayton",
  },
  {
    type: "School",
    name: "Dewey University-Carolina",
  },
  {
    type: "School",
    name: "Yeshiva College of the Nations Capital",
  },
  {
    type: "School",
    name: "University of Phoenix-South Florida Campus",
  },
  {
    type: "School",
    name: "University of Phoenix-North Florida Campus",
  },
  {
    type: "School",
    name: "University of Phoenix-West Florida Campus",
  },
  {
    type: "School",
    name: "University of Phoenix-Maryland Campus",
  },
  {
    type: "School",
    name: "University of Phoenix-Oklahoma City Campus",
  },
  {
    type: "School",
    name: "University of Phoenix-Tulsa Campus",
  },
  {
    type: "School",
    name: "Louisiana State University Health Sciences Center-Shreveport",
  },
  {
    type: "School",
    name: "Central Career Institute LLC",
  },
  {
    type: "School",
    name: "Franklin Career Institute",
  },
  {
    type: "School",
    name: "Montessori Institute of Milwaukee",
  },
  {
    type: "School",
    name: "Hair Academy II",
  },
  {
    type: "School",
    name: "Pryor Beauty College",
  },
  {
    type: "School",
    name: "Argosy University-Phoenix",
  },
  {
    type: "School",
    name: "Mr Leon's School of Hair Design-Lewiston",
  },
  {
    type: "School",
    name: "Vatterott College-St Joseph",
  },
  {
    type: "School",
    name: "Vatterott College-Sunset Hills",
  },
  {
    type: "School",
    name: "Anthem College-Orlando",
  },
  {
    type: "School",
    name: "Southwest Acupuncture College-Boulder",
  },
  {
    type: "School",
    name: "Utah College of Massage Therapy-Utah Valley",
  },
  {
    type: "School",
    name: "River Parishes Community College",
  },
  {
    type: "School",
    name: "Watson School of Biological Sciences at Cold Spring Harbor Laboratory",
  },
  {
    type: "School",
    name: "Los Angeles Film School",
  },
  {
    type: "School",
    name: "Argosy University-Orange County",
  },
  {
    type: "School",
    name: "Dewey University-Bayamon",
  },
  {
    type: "School",
    name: "National American University-Bloomington",
  },
  {
    type: "School",
    name: "A.B.I. School of Barbering & Cosmetology of Chelsea Inc.",
  },
  {
    type: "School",
    name: "Century College",
  },
  {
    type: "School",
    name: "FINE Mortuary College",
  },
  {
    type: "School",
    name: "International Baptist College and Seminary",
  },
  {
    type: "School",
    name: "The Medical Arts School",
  },
  {
    type: "School",
    name: "Fortis College-Foley",
  },
  {
    type: "School",
    name: "Medical Training College",
  },
  {
    type: "School",
    name: "Professional Training Centers",
  },
  {
    type: "School",
    name: "Toni & Guy Hairdressing Academy-Carrollton",
  },
  {
    type: "School",
    name: "University of Connecticut-Tri-Campus",
  },
  {
    type: "School",
    name: "University of Connecticut-Avery Point",
  },
  {
    type: "School",
    name: "University of Connecticut-Stamford",
  },
  {
    type: "School",
    name: "ITT Technical Institute-Saint Rose",
  },
  {
    type: "School",
    name: "ITT Technical Institute-Richmond",
  },
  {
    type: "School",
    name: "Vatterott College-Oklahoma City",
  },
  {
    type: "School",
    name: "University of Management and Technology",
  },
  {
    type: "School",
    name: "Baton Rouge Community College",
  },
  {
    type: "School",
    name: "ITT Technical Institute-Lathrop",
  },
  {
    type: "School",
    name: "International Yacht Restoration School",
  },
  {
    type: "School",
    name: "Linfield College-School of Nursing",
  },
  {
    type: "School",
    name: "Linfield College-Adult Degree Program",
  },
  {
    type: "School",
    name: "DeVry University's Keller Graduate School of Management-Virginia",
  },
  {
    type: "School",
    name: "DeVry University's Keller Graduate School of Management-Wisconsin",
  },
  {
    type: "School",
    name: "Academy of Massage Therapy",
  },
  {
    type: "School",
    name: "Star Career Academy-Syosset",
  },
  {
    type: "School",
    name: "Midwest Technical Institute-Springfield",
  },
  {
    type: "School",
    name: "Advanced Barber College and Hair Design",
  },
  {
    type: "School",
    name: "The College of Health Care Professions-Austin",
  },
  {
    type: "School",
    name: "The English Center",
  },
  {
    type: "School",
    name: "ICDC College",
  },
  {
    type: "School",
    name: "Monteclaro Escuela de Hoteleria y Artes Culinarias",
  },
  {
    type: "School",
    name: "New Community Workforce Development Center",
  },
  {
    type: "School",
    name: "Dallas Nursing Institute",
  },
  {
    type: "School",
    name: "Professional Golfers Career College",
  },
  {
    type: "School",
    name: "Richmond School of Health and Technology",
  },
  {
    type: "School",
    name: "Milan Institute-San Antonio Ingram",
  },
  {
    type: "School",
    name: "Westwood College-Anaheim",
  },
  {
    type: "School",
    name: "Rudae's School of Beauty Culture-Ft Wayne",
  },
  {
    type: "School",
    name: "InterCoast Colleges-Burbank",
  },
  {
    type: "School",
    name: "Carrington College California-Antioch",
  },
  {
    type: "School",
    name: "Carrington College California-San Jose",
  },
  {
    type: "School",
    name: "Lincoln Technical Institute-Cromwell",
  },
  {
    type: "School",
    name: "Stevens-Henager College-Murray",
  },
  {
    type: "School",
    name: "Brookline College-Tucson",
  },
  {
    type: "School",
    name: "Everest Institute-Jonesboro",
  },
  {
    type: "School",
    name: "McCann School of Business & Technology",
  },
  {
    type: "School",
    name: "Carrington College California-Pleasant Hill",
  },
  {
    type: "School",
    name: "Ridley-Lowell Business & Technical Institute-Poughkeepsie",
  },
  {
    type: "School",
    name: "Florida College of Natural Health-Maitland",
  },
  {
    type: "School",
    name: "Florida College of Natural Health-Bradenton",
  },
  {
    type: "School",
    name: "Modern Hairstyling Institute-Carolina",
  },
  {
    type: "School",
    name: "University of Phoenix-Southern Colorado Campus",
  },
  {
    type: "School",
    name: "University of Phoenix-West Michigan Campus",
  },
  {
    type: "School",
    name: "Stratford University",
  },
  {
    type: "School",
    name: "Universal Technical Institute of California Inc",
  },
  {
    type: "School",
    name: "American InterContinental University-Atlanta",
  },
  {
    type: "School",
    name: "American InterContinental University-South Florida",
  },
  {
    type: "School",
    name: "University of Phoenix-Philadelphia Campus",
  },
  {
    type: "School",
    name: "University of Phoenix-Pittsburgh Campus",
  },
  {
    type: "School",
    name: "Everest Institute-Decatur",
  },
  {
    type: "School",
    name: "Everest College-Chesapeake",
  },
  {
    type: "School",
    name: "Everest Institute-Greenspoint",
  },
  {
    type: "School",
    name: "Rancho Santiago Community College District Office",
  },
  {
    type: "School",
    name: "The Academy of Hair Design Six",
  },
  {
    type: "School",
    name: "Eastern West Virginia Community and Technical College",
  },
  {
    type: "School",
    name: "Aviation Institute of Maintenance-Indianapolis",
  },
  {
    type: "School",
    name: "Branford Hall Career Institute-Springfield Campus",
  },
  {
    type: "School",
    name: "Remington College-Little Rock Campus",
  },
  {
    type: "School",
    name: "Newbridge College-San Diego East",
  },
  {
    type: "School",
    name: "Everest University-Jacksonville",
  },
  {
    type: "School",
    name: "IVAEM College",
  },
  {
    type: "School",
    name: "Midwest College of Oriental Medicine-Chicago",
  },
  {
    type: "School",
    name: "Lincoln Technical Institute-Hamden",
  },
  {
    type: "School",
    name: "Argosy University-Seattle",
  },
  {
    type: "School",
    name: "Carrington College-Spokane",
  },
  {
    type: "School",
    name: "Texas School of Business-Friendswood",
  },
  {
    type: "School",
    name: "ITT Technical Institute-Wilmington",
  },
  {
    type: "School",
    name: "Pierce College at Puyallup",
  },
  {
    type: "School",
    name: "Texas Tech University System Administration",
  },
  {
    type: "School",
    name: "DeVry University-Florida",
  },
  {
    type: "School",
    name: "Cascadia Community College",
  },
  {
    type: "School",
    name: "University of Phoenix-St Louis Campus",
  },
  {
    type: "School",
    name: "Johnson & Wales University-Denver",
  },
  {
    type: "School",
    name: "University of Phoenix-Cleveland Campus",
  },
  {
    type: "School",
    name: "Du Bois Business College-Huntingdon",
  },
  {
    type: "School",
    name: "Du Bois Business College-Oil City",
  },
  {
    type: "School",
    name: "DeVry University-Maryland",
  },
  {
    type: "School",
    name: "CBD College",
  },
  {
    type: "School",
    name: "East West College of Natural Medicine",
  },
  {
    type: "School",
    name: "Infinity Career College",
  },
  {
    type: "School",
    name: "Lincoln Technical Institute-Fern Park",
  },
  {
    type: "School",
    name: "Atlantic Institute of Oriental Medicine",
  },
  {
    type: "School",
    name: "ATS Institute of Technology",
  },
  {
    type: "School",
    name: "Bennett Career Institute",
  },
  {
    type: "School",
    name: "Blue Cliff College-Shreveport",
  },
  {
    type: "School",
    name: "Blue Cliff College-Lafayette",
  },
  {
    type: "School",
    name: "Quest College",
  },
  {
    type: "School",
    name: "Central Florida Institute",
  },
  {
    type: "School",
    name: "Community Care College",
  },
  {
    type: "School",
    name: "Marinello Schools of Beauty-Topeka",
  },
  {
    type: "School",
    name: "Crossroads Bible College",
  },
  {
    type: "School",
    name: "Erie 1 BOCES",
  },
  {
    type: "School",
    name: "Fayette Beauty Academy",
  },
  {
    type: "School",
    name: "HDS Truck Driving Institute",
  },
  {
    type: "School",
    name: "International Training Careers",
  },
  {
    type: "School",
    name: "King's University",
  },
  {
    type: "School",
    name: "Lacy Cosmetology School-Aiken",
  },
  {
    type: "School",
    name: "Fortis College-Baton Rouge",
  },
  {
    type: "School",
    name: "My Le's Beauty College",
  },
  {
    type: "School",
    name: "New Concept Massage and Beauty School",
  },
  {
    type: "School",
    name: "New York College of Traditional Chinese Medicine",
  },
  {
    type: "School",
    name: "Fortis College-Orange Park",
  },
  {
    type: "School",
    name: "Northeastern Seminary",
  },
  {
    type: "School",
    name: "Bryan College-Gold River",
  },
  {
    type: "School",
    name: "Omega Institute of Cosmetology",
  },
  {
    type: "School",
    name: "Pacific Islands University",
  },
  {
    type: "School",
    name: "PCI College",
  },
  {
    type: "School",
    name: "Rosedale Bible College",
  },
  {
    type: "School",
    name: "American Career College at St Francis",
  },
  {
    type: "School",
    name: "Seattle Institute of Oriental Medicine",
  },
  {
    type: "School",
    name: "Shawnee Beauty College",
  },
  {
    type: "School",
    name: "Acupuncture and Massage College",
  },
  {
    type: "School",
    name: "Southeastern School of Cosmetology",
  },
  {
    type: "School",
    name: "Universal Therapeutic Massage Institute",
  },
  {
    type: "School",
    name: "Upper Valley Educators Institute",
  },
  {
    type: "School",
    name: "Keck Graduate Institute",
  },
  {
    type: "School",
    name: "Aviation Institute of Maintenance-Atlanta",
  },
  {
    type: "School",
    name: "National Beauty College",
  },
  {
    type: "School",
    name: "Star Career Academy-Egg Harbor",
  },
  {
    type: "School",
    name: "Empire Beauty School-Kennesaw",
  },
  {
    type: "School",
    name: "Anthem Career College-Nashville",
  },
  {
    type: "School",
    name: "Anthem College-Irving",
  },
  {
    type: "School",
    name: "Antioch University-System Administration",
  },
  {
    type: "School",
    name: "Westwood College-River Oaks",
  },
  {
    type: "School",
    name: "ITT Technical Institute-Green Bay",
  },
  {
    type: "School",
    name: "Career Training Academy-Pittsburgh",
  },
  {
    type: "School",
    name: "Huntingdon County Career and Technology Center",
  },
  {
    type: "School",
    name: "Missouri College of Cosmetology North",
  },
  {
    type: "School",
    name: "New York Automotive and Diesel Institute",
  },
  {
    type: "School",
    name: "Remington College-Baton Rouge Campus",
  },
  {
    type: "School",
    name: "Everest College-Ontario Metro",
  },
  {
    type: "School",
    name: "Kaplan College-Beaumont",
  },
  {
    type: "School",
    name: "Kaplan College-Laredo",
  },
  {
    type: "School",
    name: "The Art Institute of Washington",
  },
  {
    type: "School",
    name: "Everest Institute-Dearborn",
  },
  {
    type: "School",
    name: "Centura College-Richmond Westend",
  },
  {
    type: "School",
    name: "New Saint Andrews College",
  },
  {
    type: "School",
    name: "Arkansas State University-Newport",
  },
  {
    type: "School",
    name: "Marlboro College-Graduate School",
  },
  {
    type: "School",
    name: "University of Phoenix-Boston Campus",
  },
  {
    type: "School",
    name: "University of Phoenix-Dallas Campus",
  },
  {
    type: "School",
    name: "University of Phoenix-Houston Campus",
  },
  {
    type: "School",
    name: "University of Phoenix-Milwaukee Campus",
  },
  {
    type: "School",
    name: "University of Phoenix-Idaho Campus",
  },
  {
    type: "School",
    name: "Westwood College-Inland Empire",
  },
  {
    type: "School",
    name: "DeVry University-Virginia",
  },
  {
    type: "School",
    name: "DeVry University-Washington",
  },
  {
    type: "School",
    name: "DeVry University's Keller Graduate School of Management-Ohio",
  },
  {
    type: "School",
    name: "DeVry University's Keller Graduate School of Management-Texas",
  },
  {
    type: "School",
    name: "DeVry University-Colorado",
  },
  {
    type: "School",
    name: "Louisiana Delta Community College",
  },
  {
    type: "School",
    name: "ITT Technical Institute-Levittown",
  },
  {
    type: "School",
    name: "Atenas College",
  },
  {
    type: "School",
    name: "Southwest Skill Center-Campus of Estrella Mountain Community College",
  },
  {
    type: "School",
    name: "Onondaga Cortland Madison BOCES",
  },
  {
    type: "School",
    name: "National American University-Ellsworth AFB Extension",
  },
  {
    type: "School",
    name: "National American University-Albuquerque West",
  },
  {
    type: "School",
    name: "National American University-Brooklyn Center",
  },
  {
    type: "School",
    name: "Interactive Learning Systems-Pasadena",
  },
  {
    type: "School",
    name: "Pillar College",
  },
  {
    type: "School",
    name: "Miami Ad School-Minneapolis",
  },
  {
    type: "School",
    name: "Miami Ad School-San Francisco",
  },
  {
    type: "School",
    name: "Oregon State University-Cascades Campus",
  },
  {
    type: "School",
    name: "Milan Institute-Boise",
  },
  {
    type: "School",
    name: "Milan Institute-Amarillo",
  },
  {
    type: "School",
    name: "Vatterott College-Dividend",
  },
  {
    type: "School",
    name: "Vatterott College-Tulsa",
  },
  {
    type: "School",
    name: "Vatterott College-Wichita",
  },
  {
    type: "School",
    name: "Star Career Academy-Newark",
  },
  {
    type: "School",
    name: "Southern University Law Center",
  },
  {
    type: "School",
    name: "International Academy of Design and Technology-Orlando",
  },
  {
    type: "School",
    name: "Lincoln Technical Institute-Lowell",
  },
  {
    type: "School",
    name: "Everest College-Vancouver Massage Therapy",
  },
  {
    type: "School",
    name: "Texas Barber Colleges and Hairstyling Schools",
  },
  {
    type: "School",
    name: "Everest Institute-Hobby",
  },
  {
    type: "School",
    name: "Miller-Motte Technical College-North Charleston",
  },
  {
    type: "School",
    name: "American Career College-Anaheim",
  },
  {
    type: "School",
    name: "Saginaw Chippewa Tribal College",
  },
  {
    type: "School",
    name: "Wyo Tech-Blairsville",
  },
  {
    type: "School",
    name: "Richmont Graduate University",
  },
  {
    type: "School",
    name: "The Seattle School of Theology & Psychology",
  },
  {
    type: "School",
    name: "DeVry University's Keller Graduate School of Management-Washington",
  },
  {
    type: "School",
    name: "Northwest College-Hillsboro",
  },
  {
    type: "School",
    name: "San Diego State University-Imperial Valley Campus",
  },
  {
    type: "School",
    name: "American Broadcasting School-Arlington",
  },
  {
    type: "School",
    name: "Miami-Jacobs Career College-Sharonville",
  },
  {
    type: "School",
    name: "East Valley Institute of Technology",
  },
  {
    type: "School",
    name: "West Coast Ultrasound Institute",
  },
  {
    type: "School",
    name: "Irell & Manella Graduate School of Biological Sciences at City of Hope",
  },
  {
    type: "School",
    name: "Design's School of Cosmetology",
  },
  {
    type: "School",
    name: "Paul Mitchell the School-Santa Barbara",
  },
  {
    type: "School",
    name: "University of the Rockies",
  },
  {
    type: "School",
    name: "Eli Whitney Technical High School",
  },
  {
    type: "School",
    name: "Vinal Technical High School",
  },
  {
    type: "School",
    name: "Windham Technical High School",
  },
  {
    type: "School",
    name: "Harris School of Business-Wilmington Campus",
  },
  {
    type: "School",
    name: "Ultimate Medical Academy-Clearwater",
  },
  {
    type: "School",
    name: "Florida Barber Academy",
  },
  {
    type: "School",
    name: "Jacksonville Beauty Institute",
  },
  {
    type: "School",
    name: "M-DCPS The English Center",
  },
  {
    type: "School",
    name: "Florida Education Institute",
  },
  {
    type: "School",
    name: "Superior Career Institute",
  },
  {
    type: "School",
    name: "Institute of Technical Arts",
  },
  {
    type: "School",
    name: "National Graduate School of Quality Management",
  },
  {
    type: "School",
    name: "Texas County Technical College",
  },
  {
    type: "School",
    name: "Aviation Institute of Maintenance-Kansas City",
  },
  {
    type: "School",
    name: "Blue Cliff College-Gulfport",
  },
  {
    type: "School",
    name: "Apex School of Theology",
  },
  {
    type: "School",
    name: "Institute for Therapeutic Massage",
  },
  {
    type: "School",
    name: "Metropolitan Learning Institute",
  },
  {
    type: "School",
    name: "Orleans Niagara BOCES-Practical Nursing Program",
  },
  {
    type: "School",
    name: "Yeshiva Shaarei Torah of Rockland",
  },
  {
    type: "School",
    name: "Leon Studio One School of Hair Design",
  },
  {
    type: "School",
    name: "St Joseph's Medical Center School of Radiography",
  },
  {
    type: "School",
    name: "American Institute of Alternative Medicine",
  },
  {
    type: "School",
    name: "Brown Aveda Institute-Mentor",
  },
  {
    type: "School",
    name: "Institute of Technology Inc",
  },
  {
    type: "School",
    name: "Susquehanna County Career and Technology Center",
  },
  {
    type: "School",
    name: "Centro de Capacitacion y Asesoramiento Vetelba",
  },
  {
    type: "School",
    name: "Universidad Pentecostal Mizpa",
  },
  {
    type: "School",
    name: "Caribbean Forensic and Technical College",
  },
  {
    type: "School",
    name: "Concorde Career College-Grand Prairie",
  },
  {
    type: "School",
    name: "Kussad Institute of Court Reporting",
  },
  {
    type: "School",
    name: "Lamar Institute of Technology",
  },
  {
    type: "School",
    name: "MJ's Beauty Academy Inc",
  },
  {
    type: "School",
    name: "Aviation Institute of Maintenance-Dallas",
  },
  {
    type: "School",
    name: "Healing Mountain Massage School",
  },
  {
    type: "School",
    name: "Career Training Solutions",
  },
  {
    type: "School",
    name: "Rudy & Kelly Academy of Hair and Nails",
  },
  {
    type: "School",
    name: "South Branch Career and Technical Center",
  },
  {
    type: "School",
    name: "Nevada State College",
  },
  {
    type: "School",
    name: "Virginia College-Jackson",
  },
  {
    type: "School",
    name: "Virginia College-Austin",
  },
  {
    type: "School",
    name: "California State University-Channel Islands",
  },
  {
    type: "School",
    name: "ITT Technical Institute-Springfield",
  },
  {
    type: "School",
    name: "ITT Technical Institute-Chantilly",
  },
  {
    type: "School",
    name: "The Art Institute of California-Argosy University Orange County",
  },
  {
    type: "School",
    name: "Franklin W Olin College of Engineering",
  },
  {
    type: "School",
    name: "Lincoln Technical Institute-Northeast Philadelphia",
  },
  {
    type: "School",
    name: "Lincoln Technical Institute-Center City Philadelphia",
  },
  {
    type: "School",
    name: "Won Institute of Graduate Studies",
  },
  {
    type: "School",
    name: "Lincoln Technical Institute-Paramus",
  },
  {
    type: "School",
    name: "Pima Medical Institute-Colorado Springs",
  },
  {
    type: "School",
    name: "University of Phoenix-Kansas City Campus",
  },
  {
    type: "School",
    name: "University of Phoenix-Atlanta Campus",
  },
  {
    type: "School",
    name: "University of Phoenix-Chicago Campus",
  },
  {
    type: "School",
    name: "University of Phoenix-Nashville Campus",
  },
  {
    type: "School",
    name: "University of Phoenix-Northern Virginia Campus",
  },
  {
    type: "School",
    name: "David's Academy of Beauty",
  },
  {
    type: "School",
    name: "Birthwise Midwifery School",
  },
  {
    type: "School",
    name: "Argosy University-Dallas",
  },
  {
    type: "School",
    name: "Le Cordon Bleu College of Culinary Arts-Orlando",
  },
  {
    type: "School",
    name: "Paul Mitchell the School-Costa Mesa",
  },
  {
    type: "School",
    name: "Ave Maria School of Law",
  },
  {
    type: "School",
    name: "Everest College-Arlington",
  },
  {
    type: "School",
    name: "Tulsa Welding School-Jacksonville",
  },
  {
    type: "School",
    name: "ITT Technical Institute-Canton",
  },
  {
    type: "School",
    name: "ITT Technical Institute-Plymouth Meeting",
  },
  {
    type: "School",
    name: "Pennsylvania College of Health Sciences",
  },
  {
    type: "School",
    name: "DeVry University-North Carolina",
  },
  {
    type: "School",
    name: "West Virginia Junior College-Bridgeport",
  },
  {
    type: "School",
    name: "Antioch University-PhD Program in Leadership and Change",
  },
  {
    type: "School",
    name: "Vatterott College-Cleveland",
  },
  {
    type: "School",
    name: "St Louis College of Health Careers-Fenton",
  },
  {
    type: "School",
    name: "Interactive College of Technology-Gainesville",
  },
  {
    type: "School",
    name: "San Joaquin Valley College-Ontario",
  },
  {
    type: "School",
    name: "Anthem Institute-Cherry Hill",
  },
  {
    type: "School",
    name: "Westwood College-Ft Worth",
  },
  {
    type: "School",
    name: "Westwood College-Dallas",
  },
  {
    type: "School",
    name: "Alaska Christian College",
  },
  {
    type: "School",
    name: "Grantham University",
  },
  {
    type: "School",
    name: "Minnesota School of Business-Plymouth",
  },
  {
    type: "School",
    name: "Carrington College-Albuquerque",
  },
  {
    type: "School",
    name: "NASCAR Technical Institute",
  },
  {
    type: "School",
    name: "Everest Institute-Tigard",
  },
  {
    type: "School",
    name: "University of the Potomac-VA Campus",
  },
  {
    type: "School",
    name: "Anthem Institute-Las Vegas",
  },
  {
    type: "School",
    name: "Milan Institute-Palm Desert",
  },
  {
    type: "School",
    name: "Stenotype Institute of Jacksonville Inc-Orlando",
  },
  {
    type: "School",
    name: "National American University-Overland Park",
  },
  {
    type: "School",
    name: "Everest Institute-Austin",
  },
  {
    type: "School",
    name: "Everest College-Burr Ridge",
  },
  {
    type: "School",
    name: "Medvance Institute-West Palm",
  },
  {
    type: "School",
    name: "Tohono O'Odham Community College",
  },
  {
    type: "School",
    name: "Everest College-Dallas",
  },
  {
    type: "School",
    name: "Edward Via College of Osteopathic Medicine",
  },
  {
    type: "School",
    name: "The Salter School-Malden Campus",
  },
  {
    type: "School",
    name: "DeVry University-Pennsylvania",
  },
  {
    type: "School",
    name: "Pacific College of Oriental Medicine-Chicago",
  },
  {
    type: "School",
    name: "Ronny J's Barber Styling",
  },
  {
    type: "School",
    name: "Southwest Institute of Healing Arts",
  },
  {
    type: "School",
    name: "InterCoast Colleges-Riverside",
  },
  {
    type: "School",
    name: "Ottawa University-Jeffersonville",
  },
  {
    type: "School",
    name: "University of Antelope Valley",
  },
  {
    type: "School",
    name: "Birthingway College of Midwifery",
  },
  {
    type: "School",
    name: "Blue Water College of Cosmetology Inc",
  },
  {
    type: "School",
    name: "Pima Medical Institute-Albuquerque West",
  },
  {
    type: "School",
    name: "Brighton Center's Center for Employment Training",
  },
  {
    type: "School",
    name: "California Healing Arts College",
  },
  {
    type: "School",
    name: "Dallas Barber & Stylist College",
  },
  {
    type: "School",
    name: "NorthShore University HealthSystem School of Nurse Anesthesia",
  },
  {
    type: "School",
    name: "Faith Evangelical College & Seminary",
  },
  {
    type: "School",
    name: "Family of Faith College",
  },
  {
    type: "School",
    name: "Hood Theological Seminary",
  },
  {
    type: "School",
    name: "Colegio Educativo Tecnologico Industrial Inc",
  },
  {
    type: "School",
    name: "International Technological University",
  },
  {
    type: "School",
    name: "Irene's Myomassology Institute",
  },
  {
    type: "School",
    name: "Jay's Technical Institute",
  },
  {
    type: "School",
    name: "Mr John's School of Cosmetology Esthetics & Nails-Jacksonville",
  },
  {
    type: "School",
    name: "NTMA Training Centers of Southern California",
  },
  {
    type: "School",
    name: "P C Age-Jersey City",
  },
  {
    type: "School",
    name: "P C Age-Edison",
  },
  {
    type: "School",
    name: "American Institute-Margate",
  },
  {
    type: "School",
    name: "South Florida Institute of Technology",
  },
  {
    type: "School",
    name: "Southeastern Institute-Charleston",
  },
  {
    type: "School",
    name: "Southeastern College-Jacksonville",
  },
  {
    type: "School",
    name: "Toni & Guy Hairdressing Academy-Colorado Springs",
  },
  {
    type: "School",
    name: "Trend Barber College",
  },
  {
    type: "School",
    name: "Illinois CareerPath Institute",
  },
  {
    type: "School",
    name: "Valley Grande Institute for Academic Studies",
  },
  {
    type: "School",
    name: "West Coast University-Los Angeles",
  },
  {
    type: "School",
    name: "Williamson Christian College",
  },
  {
    type: "School",
    name: "International Barber & Style College",
  },
  {
    type: "School",
    name: "Illinois Eastern Community College-System Office",
  },
  {
    type: "School",
    name: "Triangle Tech Inc-Sunbury",
  },
  {
    type: "School",
    name: "DigiPen Institute of Technology",
  },
  {
    type: "School",
    name: "California College of Vocational Careers",
  },
  {
    type: "School",
    name: "Fortis Institute-Fort Lauderdale",
  },
  {
    type: "School",
    name: "University of Phoenix-Cincinnati Campus",
  },
  {
    type: "School",
    name: "University of Phoenix-Wichita Campus",
  },
  {
    type: "School",
    name: "University of Phoenix-Charlotte Campus",
  },
  {
    type: "School",
    name: "Connecticut Center for Massage Therapy-Groton",
  },
  {
    type: "School",
    name: "Pierpont Community and Technical College",
  },
  {
    type: "School",
    name: "ITT Technical Institute-Duluth",
  },
  {
    type: "School",
    name: "ITT Technical Institute-Hilliard",
  },
  {
    type: "School",
    name: "University of Phoenix-Indianapolis Campus",
  },
  {
    type: "School",
    name: "Career Institute of Health and Technology",
  },
  {
    type: "School",
    name: "Dewey University-Hato Rey",
  },
  {
    type: "School",
    name: "InterCoast Colleges-West Covina",
  },
  {
    type: "School",
    name: "American College of Healthcare Sciences",
  },
  {
    type: "School",
    name: "Texas School of Business-East",
  },
  {
    type: "School",
    name: "Le Cordon Bleu College of Culinary Arts-Atlanta",
  },
  {
    type: "School",
    name: "Colorado Media School",
  },
  {
    type: "School",
    name: "Paul Mitchell the School-Rhode Island",
  },
  {
    type: "School",
    name: "Miller-Motte Technical College-Chattanooga",
  },
  {
    type: "School",
    name: "Milan Institute-Sparks",
  },
  {
    type: "School",
    name: "Empire Beauty School-Framingham",
  },
  {
    type: "School",
    name: "Westwood College-Chicago Loop",
  },
  {
    type: "School",
    name: "Interactive Learning Systems-North Houston",
  },
  {
    type: "School",
    name: "Bexley Hall Episcopal Seminary",
  },
  {
    type: "School",
    name: "University of North Texas System",
  },
  {
    type: "School",
    name: "Anthem College-Fenton",
  },
  {
    type: "School",
    name: "Everest Institute-Norcross",
  },
  {
    type: "School",
    name: "Strayer University-Tennessee",
  },
  {
    type: "School",
    name: "Strayer University-Pennsylvania",
  },
  {
    type: "School",
    name: "Kaplan College-Brownsville",
  },
  {
    type: "School",
    name: "Kaplan College-Corpus Christi",
  },
  {
    type: "School",
    name: "School of Health",
  },
  {
    type: "School",
    name: "Everest College-Arlington",
  },
  {
    type: "School",
    name: "University of Phoenix-Columbus Georgia Campus",
  },
  {
    type: "School",
    name: "University of Phoenix-Memphis Campus",
  },
  {
    type: "School",
    name: "University of Phoenix-Columbus Ohio Campus",
  },
  {
    type: "School",
    name: "University of Phoenix-Little Rock Campus",
  },
  {
    type: "School",
    name: "Toni & Guy Hairdressing Academy-Boise",
  },
  {
    type: "School",
    name: "Charlie's Guard-Detective Bureau and Academy Inc",
  },
  {
    type: "School",
    name: "PCI Academy-Plymouth",
  },
  {
    type: "School",
    name: "DeVry University's Keller Graduate School of Management-Pennsylvania",
  },
  {
    type: "School",
    name: "DeVry University-Nevada",
  },
  {
    type: "School",
    name: "Everest College-Tacoma",
  },
  {
    type: "School",
    name: "DeVry University-Oregon",
  },
  {
    type: "School",
    name: "National University College-Rio Grande",
  },
  {
    type: "School",
    name: "Creative Images Institute of Cosmetology-South Dayton",
  },
  {
    type: "School",
    name: "Westwood College-Houston South",
  },
  {
    type: "School",
    name: "Fortis Institute-Jacksonville",
  },
  {
    type: "School",
    name: "Brookline College-Albuquerque",
  },
  {
    type: "School",
    name: "Byzantine Catholic Seminary of Saints Cyril and Methodius",
  },
  {
    type: "School",
    name: "Remington College-Cleveland West Campus",
  },
  {
    type: "School",
    name: "Northcentral University",
  },
  {
    type: "School",
    name: "Colorado Technical University-Online",
  },
  {
    type: "School",
    name: "Baker College of Allen Park",
  },
  {
    type: "School",
    name: "Bayamon Community College",
  },
  {
    type: "School",
    name: "Harris School of Business-Dover Campus",
  },
  {
    type: "School",
    name: "Folsom Lake College",
  },
  {
    type: "School",
    name: "LeGrand Institute of Cosmetology Inc",
  },
  {
    type: "School",
    name: "Everest Institute-Detroit",
  },
  {
    type: "School",
    name: "Daymar Institute-Murfreesboro",
  },
  {
    type: "School",
    name: "Cambridge Technical Institute",
  },
  {
    type: "School",
    name: "Empire Beauty School-Midlothian",
  },
  {
    type: "School",
    name: "Empire Beauty School-Owings Mills",
  },
  {
    type: "School",
    name: "Empire Beauty School-West Mifflin",
  },
  {
    type: "School",
    name: "Bold Beauty Academy",
  },
  {
    type: "School",
    name: "ATI College-Norwalk",
  },
  {
    type: "School",
    name: "Advance Science Institute",
  },
  {
    type: "School",
    name: "Advanced College",
  },
  {
    type: "School",
    name: "Advance Tech College",
  },
  {
    type: "School",
    name: "Advanced Training Associates",
  },
  {
    type: "School",
    name: "American Advanced Technicians Institute",
  },
  {
    type: "School",
    name: "Anamarc College-El Paso Central",
  },
  {
    type: "School",
    name: "Baptist University of the Americas",
  },
  {
    type: "School",
    name: "The Beauty Institute",
  },
  {
    type: "School",
    name: "Beis Medrash Heichal Dovid",
  },
  {
    type: "School",
    name: "Bellefonte Academy of Beauty",
  },
  {
    type: "School",
    name: "Blue Cliff Career College",
  },
  {
    type: "School",
    name: "Career Beauty College",
  },
  {
    type: "School",
    name: "Paul Mitchell the School-Gastonia",
  },
  {
    type: "School",
    name: "CES College",
  },
  {
    type: "School",
    name: "Computer Tutor Business and Technical Institute",
  },
  {
    type: "School",
    name: "Court Reporting Institute of Louisiana",
  },
  {
    type: "School",
    name: "Auguste Escoffier School of Culinary Arts-Austin",
  },
  {
    type: "School",
    name: "Culinary Institute Inc",
  },
  {
    type: "School",
    name: "Dade Medical College-Miami",
  },
  {
    type: "School",
    name: "Escuela Hotelera de San Juan",
  },
  {
    type: "School",
    name: "Expertise Cosmetology Institute",
  },
  {
    type: "School",
    name: "Paul Mitchell the School-Monroe",
  },
  {
    type: "School",
    name: "Healing Arts Institute",
  },
  {
    type: "School",
    name: "Health Works Institute",
  },
  {
    type: "School",
    name: "In Session Arts of Cosmetology Beauty School",
  },
  {
    type: "School",
    name: "Moore Career College",
  },
  {
    type: "School",
    name: "Industrial Technical College",
  },
  {
    type: "School",
    name: "Institute of Beauty Occupation and Technology Course",
  },
  {
    type: "School",
    name: "Institute of Clinical Acupuncture & Oriental Med",
  },
  {
    type: "School",
    name: "Institute of Hair Design",
  },
  {
    type: "School",
    name: "MedTech Institute-Atlanta Campus",
  },
  {
    type: "School",
    name: "Jones International University",
  },
  {
    type: "School",
    name: "Leston College",
  },
  {
    type: "School",
    name: "Lynndale Fundamentals of Beauty School",
  },
  {
    type: "School",
    name: "Medical Professional Institute",
  },
  {
    type: "School",
    name: "New Life Theological Seminary",
  },
  {
    type: "School",
    name: "Nightingale College",
  },
  {
    type: "School",
    name: "Omnitech Institute",
  },
  {
    type: "School",
    name: "PITC Institute",
  },
  {
    type: "School",
    name: "Skinworks School of Advanced Skincare",
  },
  {
    type: "School",
    name: "Southeastern Institute-Columbia",
  },
  {
    type: "School",
    name: "Technical Learning Centers Inc",
  },
  {
    type: "School",
    name: "Total Look School of Cosmetology & Massage Therapy",
  },
  {
    type: "School",
    name: "The Art Institute of Tucson",
  },
  {
    type: "School",
    name: "Turning Point Beauty College",
  },
  {
    type: "School",
    name: "Unitech Training Academy-Lafayette",
  },
  {
    type: "School",
    name: "Mountwest Community and Technical College",
  },
  {
    type: "School",
    name: "Centura College-Columbia",
  },
  {
    type: "School",
    name: "Flagler College-Tallahassee",
  },
  {
    type: "School",
    name: "Kanawha Valley Community and Technical College",
  },
  {
    type: "School",
    name: "American InterContinental University-Online",
  },
  {
    type: "School",
    name: "Westwood College-Atlanta Midtown",
  },
  {
    type: "School",
    name: "ITT Technical Institute-Eden Prairie",
  },
  {
    type: "School",
    name: "Virginia College-Mobile",
  },
  {
    type: "School",
    name: "Le Cordon Bleu College of Culinary Arts-Las Vegas",
  },
  {
    type: "School",
    name: "International Academy of Design and Technology-Troy",
  },
  {
    type: "School",
    name: "American InterContinental University-Houston",
  },
  {
    type: "School",
    name: "Sanford-Brown College-Houston North Loop",
  },
  {
    type: "School",
    name: "University of California-Merced",
  },
  {
    type: "School",
    name: "Everest College-Fort Worth",
  },
  {
    type: "School",
    name: "Remington College-North Houston Campus",
  },
  {
    type: "School",
    name: "Paul Mitchell the School-Orlando",
  },
  {
    type: "School",
    name: "Regency Beauty Institute-Maplewood",
  },
  {
    type: "School",
    name: "Pima Medical Institute-Las Vegas",
  },
  {
    type: "School",
    name: "Remington College-Nashville Campus",
  },
  {
    type: "School",
    name: "Platt College-North OKC",
  },
  {
    type: "School",
    name: "Central Methodist University-College of Graduate and Extended Studies",
  },
  {
    type: "School",
    name: "Westwood College-Northlake",
  },
  {
    type: "School",
    name: "University of Phoenix-Jersey City Campus",
  },
  {
    type: "School",
    name: "University of Phoenix-Minneapolis St Paul Campus",
  },
  {
    type: "School",
    name: "Kaplan College-Fort Worth",
  },
  {
    type: "School",
    name: "Kaplan College-Midland",
  },
  {
    type: "School",
    name: "Kaplan College-Lubbock",
  },
  {
    type: "School",
    name: "Carrington College California-Emeryville",
  },
  {
    type: "School",
    name: "North-West College-Riverside",
  },
  {
    type: "School",
    name: "Everest Institute-Chelsea",
  },
  {
    type: "School",
    name: "Everest Institute-Eagan",
  },
  {
    type: "School",
    name: "University of Phoenix-Louisville Campus",
  },
  {
    type: "School",
    name: "DeVry University-Minnesota",
  },
  {
    type: "School",
    name: "Kaplan University-Council Bluffs Campus",
  },
  {
    type: "School",
    name: "Everest University-Orange Park",
  },
  {
    type: "School",
    name: "Wyotech-West Sacramento",
  },
  {
    type: "School",
    name: "Everest Institute-Bissonnet",
  },
  {
    type: "School",
    name: "Everest College-McLean",
  },
  {
    type: "School",
    name: "Colegio Tecnico de Electricidad Galloza",
  },
  {
    type: "School",
    name: "Empire Beauty School-St Paul",
  },
  {
    type: "School",
    name: "Arthur's Beauty School Inc-Pine Bluff",
  },
  {
    type: "School",
    name: "Arthur's Beauty School Inc-Conway",
  },
  {
    type: "School",
    name: "Vatterott College-St Charles",
  },
  {
    type: "School",
    name: "Anthem Institute-North Brunswick",
  },
  {
    type: "School",
    name: "Anthem Career College-Memphis",
  },
  {
    type: "School",
    name: "Anthem College-Kansas City",
  },
  {
    type: "School",
    name: "Spa Tech Institute-Ipswich",
  },
  {
    type: "School",
    name: "Spa Tech Institute-Westboro",
  },
  {
    type: "School",
    name: "Spa Tech Institute-Plymouth",
  },
  {
    type: "School",
    name: "Milan Institute of Cosmetology-San Antonio Walzem",
  },
  {
    type: "School",
    name: "Bridgemont Community and Technical College",
  },
  {
    type: "School",
    name: "Wards Corner Beauty Academy-Virginia Beach",
  },
  {
    type: "School",
    name: "Neumont University",
  },
  {
    type: "School",
    name: "Johnson & Wales University-Charlotte",
  },
  {
    type: "School",
    name: "University of Phoenix-Central Valley Campus",
  },
  {
    type: "School",
    name: "L'Ecole Culinaire-St Louis",
  },
  {
    type: "School",
    name: "Roseman University of Health Sciences",
  },
  {
    type: "School",
    name: "Fortis Institute-Port Saint Lucie",
  },
  {
    type: "School",
    name: "Fortis Institute-Miami",
  },
  {
    type: "School",
    name: "Aviation Institute of Maintenance-Manassas",
  },
  {
    type: "School",
    name: "Everest College-North Aurora",
  },
  {
    type: "School",
    name: "Cosmetology School of Arts and Sciences",
  },
  {
    type: "School",
    name: "Universal Technical Institute of Pennsylvania Inc",
  },
  {
    type: "School",
    name: "Heritage College-Kansas City",
  },
  {
    type: "School",
    name: "Kenneth Shuler School of Cosmetology-Spartanburg",
  },
  {
    type: "School",
    name: "Kenneth Shuler School of Cosmetology-Columbia",
  },
  {
    type: "School",
    name: "Institute for the Psychological Sciences",
  },
  {
    type: "School",
    name: "Pivot Point Academy-Chicago",
  },
  {
    type: "School",
    name: "Lincoln Technical Institute-Shelton",
  },
  {
    type: "School",
    name: "Minnesota School of Business-Waite Park",
  },
  {
    type: "School",
    name: "Minnesota School of Business-Shakopee",
  },
  {
    type: "School",
    name: "Northwest Career College",
  },
  {
    type: "School",
    name: "Soma Institute-The National School of Clinical Massage Therapy",
  },
  {
    type: "School",
    name: "Xtreme Career Institute",
  },
  {
    type: "School",
    name: "Marinello Schools of Beauty-Niantic",
  },
  {
    type: "School",
    name: "American Academy of Acupuncture and Oriental Medicine",
  },
  {
    type: "School",
    name: "Arizona School of Acupuncture and Oriental Medicine",
  },
  {
    type: "School",
    name: "Ave Maria University",
  },
  {
    type: "School",
    name: "Blue Sky School of Professional Massage and Therapeutic Bodywork",
  },
  {
    type: "School",
    name: "Buchanan Beauty College",
  },
  {
    type: "School",
    name: "CALC Institute of Technology",
  },
  {
    type: "School",
    name: "Cambridge Junior College-Yuba City",
  },
  {
    type: "School",
    name: "Cambridge Institute of Allied Health & Technology",
  },
  {
    type: "School",
    name: "Career Care Institute",
  },
  {
    type: "School",
    name: "ATA Career Education",
  },
  {
    type: "School",
    name: "Career Quest Learning Centers-Lansing",
  },
  {
    type: "School",
    name: "Central State Massage Academy",
  },
  {
    type: "School",
    name: "Community Christian College",
  },
  {
    type: "School",
    name: "Crossett School of Cosmetology",
  },
  {
    type: "School",
    name: "Culpeper Cosmetology Training Center",
  },
  {
    type: "School",
    name: "Day Spa Career College",
  },
  {
    type: "School",
    name: "Eastern School of Acupuncture and Traditional Medicine",
  },
  {
    type: "School",
    name: "Ecclesia College",
  },
  {
    type: "School",
    name: "Employment Solutions-College for Technical Education",
  },
  {
    type: "School",
    name: "European Massage Therapy School-Skokie",
  },
  {
    type: "School",
    name: "Evergreen Beauty and Barber College-Everett",
  },
  {
    type: "School",
    name: "Paul Mitchell the School-Great Lakes",
  },
  {
    type: "School",
    name: "Healthcare Training Institute",
  },
  {
    type: "School",
    name: "John D Rockefeller IV Career Center",
  },
  {
    type: "School",
    name: "Ladera Career Paths Training Centers",
  },
  {
    type: "School",
    name: "Los Angeles Music Academy",
  },
  {
    type: "School",
    name: "Maple Springs Baptist Bible College and Seminary",
  },
  {
    type: "School",
    name: "MCI Institute of Technology",
  },
  {
    type: "School",
    name: "Oregon Career & Technology Center",
  },
  {
    type: "School",
    name: "Performance Training Institute",
  },
  {
    type: "School",
    name: "Pacific Coast Trade School",
  },
  {
    type: "School",
    name: "Centura Institute",
  },
  {
    type: "School",
    name: "Precision Manufacturing Institute",
  },
  {
    type: "School",
    name: "Professional Massage Training Center",
  },
  {
    type: "School",
    name: "Regency School of Hair Design",
  },
  {
    type: "School",
    name: "Renaissance College-Massage Program",
  },
  {
    type: "School",
    name: "Rosslyn Training Academy of Cosmetology",
  },
  {
    type: "School",
    name: "SAE Institute of Technology-Nashville",
  },
  {
    type: "School",
    name: "eClips School of Cosmetology and Barbering",
  },
  {
    type: "School",
    name: "Shear Academy",
  },
  {
    type: "School",
    name: "Southern Technical College",
  },
  {
    type: "School",
    name: "Stanbridge College",
  },
  {
    type: "School",
    name: "Styletrends Barber and Hairstyling Academy",
  },
  {
    type: "School",
    name: "Universal Career School",
  },
  {
    type: "School",
    name: "Universal College of Healing Arts",
  },
  {
    type: "School",
    name: "Uta Mesivta of Kiryas Joel",
  },
  {
    type: "School",
    name: "W L Bonner College",
  },
  {
    type: "School",
    name: "Harrisburg University of Science and Technology",
  },
  {
    type: "School",
    name: "The Art Institute of Ohio-Cincinnati",
  },
  {
    type: "School",
    name: "Stevens-Henager College-Logan",
  },
  {
    type: "School",
    name: "University of Phoenix-Cheyenne Campus",
  },
  {
    type: "School",
    name: "University of Phoenix-Springfield  Campus",
  },
  {
    type: "School",
    name: "University of Phoenix-Des Moines Campus",
  },
  {
    type: "School",
    name: "University of Phoenix-San Antonio Campus",
  },
  {
    type: "School",
    name: "University of Phoenix-Austin Campus",
  },
  {
    type: "School",
    name: "University of Phoenix-Richmond-Virginia Beach Campus",
  },
  {
    type: "School",
    name: "University of Phoenix-Raleigh Campus",
  },
  {
    type: "School",
    name: "Blue Ridge Community and Technical College",
  },
  {
    type: "School",
    name: "Lincoln Technical Institute-Suffield",
  },
  {
    type: "School",
    name: "International Academy of Design and Technology-Henderson",
  },
  {
    type: "School",
    name: "International Academy of Design and Technology-Seattle",
  },
  {
    type: "School",
    name: "International Academy of Design and Technology-Nashville",
  },
  {
    type: "School",
    name: "Le Cordon Bleu College of Culinary Arts-Miami",
  },
  {
    type: "School",
    name: "Le Cordon Bleu College of Culinary Arts-Minneapolis",
  },
  {
    type: "School",
    name: "Anthem College-Portland",
  },
  {
    type: "School",
    name: "Career Technical College-Shreveport",
  },
  {
    type: "School",
    name: "ITT Technical Institute-Kansas City",
  },
  {
    type: "School",
    name: "ITT Technical Institute-Kennesaw",
  },
  {
    type: "School",
    name: "ITT Technical Institute-Owings Mills",
  },
  {
    type: "School",
    name: "ITT Technical Institute-Warrensville Heights",
  },
  {
    type: "School",
    name: "Union Graduate College",
  },
  {
    type: "School",
    name: "Colorado State University-System Office",
  },
  {
    type: "School",
    name: "Chambersburg Beauty School",
  },
  {
    type: "School",
    name: "Daytona College",
  },
  {
    type: "School",
    name: "Delta Technical College",
  },
  {
    type: "School",
    name: "United States University",
  },
  {
    type: "School",
    name: "Westwood College-Arlington Ballston",
  },
  {
    type: "School",
    name: "Kaplan College-Bakersfield",
  },
  {
    type: "School",
    name: "Kaplan College-Fresno",
  },
  {
    type: "School",
    name: "Empire Beauty School-Avondale",
  },
  {
    type: "School",
    name: "Empire Beauty School-North Tucson",
  },
  {
    type: "School",
    name: "Empire Beauty School-Littleton",
  },
  {
    type: "School",
    name: "Empire Beauty School-Aurora",
  },
  {
    type: "School",
    name: "Everest Institute-Gahanna",
  },
  {
    type: "School",
    name: "Everest College-Merrionette Park",
  },
  {
    type: "School",
    name: "Everest College-Earth City",
  },
  {
    type: "School",
    name: "Everest College-Mesa",
  },
  {
    type: "School",
    name: "Everest Institute-Silver Spring",
  },
  {
    type: "School",
    name: "Alvareitas College of Cosmetology-Belleville",
  },
  {
    type: "School",
    name: "AmeriTech College-Draper",
  },
  {
    type: "School",
    name: "Argosy University-Los Angeles",
  },
  {
    type: "School",
    name: "Brown Mackie College-Miami",
  },
  {
    type: "School",
    name: "Regency Beauty Institute-Darien",
  },
  {
    type: "School",
    name: "Regency Beauty Institute-Aurora",
  },
  {
    type: "School",
    name: "Regency Beauty Institute-Madison",
  },
  {
    type: "School",
    name: "Regency Beauty Institute-Ridgedale",
  },
  {
    type: "School",
    name: "San Joaquin Valley College-Modesto",
  },
  {
    type: "School",
    name: "Fortis College-Largo",
  },
  {
    type: "School",
    name: "Platt College-Moore",
  },
  {
    type: "School",
    name: "Platt College-Dallas",
  },
  {
    type: "School",
    name: "Universal Technical Institute of Massachusetts Inc",
  },
  {
    type: "School",
    name: "Eagle Gate College-Layton",
  },
  {
    type: "School",
    name: "Carsten Institute of Cosmetology",
  },
  {
    type: "School",
    name: "Academy of Hair Design-Jasper",
  },
  {
    type: "School",
    name: "Expression College for Digital Arts",
  },
  {
    type: "School",
    name: "Unitech Training Academy-West Monroe",
  },
  {
    type: "School",
    name: "Daymar College-Bellevue",
  },
  {
    type: "School",
    name: "DeVry University-Oklahoma",
  },
  {
    type: "School",
    name: "Salter School-Fall River",
  },
  {
    type: "School",
    name: "Seacoast Career School-Manchester Campus",
  },
  {
    type: "School",
    name: "Instituto de Educacion Tecnica Ocupacional La Reine-Aguadilla",
  },
  {
    type: "School",
    name: "New River Community and Technical College",
  },
  {
    type: "School",
    name: "The College of Health Care Professions-Southwest Houston",
  },
  {
    type: "School",
    name: "Eagle Gate College-Salt Lake City",
  },
  {
    type: "School",
    name: "Kaplan College-Palm Springs",
  },
  {
    type: "School",
    name: "University of Phoenix-Savannah Campus",
  },
  {
    type: "School",
    name: "University of Phoenix-Northern Nevada Campus",
  },
  {
    type: "School",
    name: "Minnesota School of Business-Rochester",
  },
  {
    type: "School",
    name: "Georgia Gwinnett College",
  },
  {
    type: "School",
    name: "Phoenix Institute of Herbal Medicine & Acupuncture",
  },
  {
    type: "School",
    name: "Tucson College of Beauty",
  },
  {
    type: "School",
    name: "California Career College",
  },
  {
    type: "School",
    name: "Palladium Technical Academy",
  },
  {
    type: "School",
    name: "Palace Beauty College",
  },
  {
    type: "School",
    name: "National Polytechnic College",
  },
  {
    type: "School",
    name: "American Career College-Ontario",
  },
  {
    type: "School",
    name: "Asher College",
  },
  {
    type: "School",
    name: "American Institute of Massage Therapy",
  },
  {
    type: "School",
    name: "University of East-West Medicine",
  },
  {
    type: "School",
    name: "Blake Austin College",
  },
  {
    type: "School",
    name: "Mojave Barber College",
  },
  {
    type: "School",
    name: "Aviator College of Aeronautical Science and Technology",
  },
  {
    type: "School",
    name: "Total International Career Institute",
  },
  {
    type: "School",
    name: "Trendsetters School of Beauty & Barbering",
  },
  {
    type: "School",
    name: "CDA Technical Institute",
  },
  {
    type: "School",
    name: "American Institute of Beauty",
  },
  {
    type: "School",
    name: "North Florida Cosmetology Institute Inc",
  },
  {
    type: "School",
    name: "Florida Academy of Health & Beauty",
  },
  {
    type: "School",
    name: "Augusta School of Massage",
  },
  {
    type: "School",
    name: "ATA College",
  },
  {
    type: "School",
    name: "SUM Bible College and Theological Seminary",
  },
  {
    type: "School",
    name: "Compass Career College",
  },
  {
    type: "School",
    name: "Hair Expressions Academy",
  },
  {
    type: "School",
    name: "Nuvo College of Cosmetology",
  },
  {
    type: "School",
    name: "WellSpring School of Allied Health-Kansas City",
  },
  {
    type: "School",
    name: "Hair Academy 110",
  },
  {
    type: "School",
    name: "Healing Touch Career College",
  },
  {
    type: "School",
    name: "The Salon Professional Academy-Fargo",
  },
  {
    type: "School",
    name: "Massage Therapy Trainining Institute",
  },
  {
    type: "School",
    name: "Institute of Professional Careers",
  },
  {
    type: "School",
    name: "Elite Academy of Beauty Arts",
  },
  {
    type: "School",
    name: "Aveda Institute-New York",
  },
  {
    type: "School",
    name: "Brookline College-Oklahoma City",
  },
  {
    type: "School",
    name: "Northwest Regional Technology Institute",
  },
  {
    type: "School",
    name: "National Massage Therapy Institute",
  },
  {
    type: "School",
    name: "MyrAngel Beauty Institute",
  },
  {
    type: "School",
    name: "Institute of Hair Design",
  },
  {
    type: "School",
    name: "Tennessee Career Institute",
  },
  {
    type: "School",
    name: "Texas Health School",
  },
  {
    type: "School",
    name: "Mai-trix Beauty College",
  },
  {
    type: "School",
    name: "North West Beauty School",
  },
  {
    type: "School",
    name: "Maximum Style Tec School of Cosmetology",
  },
  {
    type: "School",
    name: "Careers Unlimited",
  },
  {
    type: "School",
    name: "Mountainland Applied Technology College",
  },
  {
    type: "School",
    name: "Avi Career Training",
  },
  {
    type: "School",
    name: "Yakima Beauty School",
  },
  {
    type: "School",
    name: "Mountain State School of Massage",
  },
  {
    type: "School",
    name: "Doane College-Lincoln Grand Island and Master",
  },
  {
    type: "School",
    name: "Shorter University-College of Adult & Professional Programs",
  },
  {
    type: "School",
    name: "Arkansas State University-System Office",
  },
  {
    type: "School",
    name: "The Art Institute of Indianapolis",
  },
  {
    type: "School",
    name: "Eastern International College-Belleville",
  },
  {
    type: "School",
    name: "San Joaquin Valley College-Rancho Cordova",
  },
  {
    type: "School",
    name: "San Joaquin Valley College-Central Administrative Office",
  },
  {
    type: "School",
    name: "Illinois School of Health Careers-O'Hare Campus",
  },
  {
    type: "School",
    name: "MedTech College",
  },
  {
    type: "School",
    name: "Broken Arrow Beauty College-Tulsa",
  },
  {
    type: "School",
    name: "Universal Technical Institute of Northern California Inc",
  },
  {
    type: "School",
    name: "ITT Technical Institute-Charlotte South",
  },
  {
    type: "School",
    name: "ITT Technical Institute-Clovis",
  },
  {
    type: "School",
    name: "ITT Technical Institute-Dunmore",
  },
  {
    type: "School",
    name: "ITT Technical Institute-Swartz Creek",
  },
  {
    type: "School",
    name: "ITT Technical Institute-Lexington",
  },
  {
    type: "School",
    name: "ITT Technical Institute-Maumee",
  },
  {
    type: "School",
    name: "ITT Technical Institute-Oklahoma City",
  },
  {
    type: "School",
    name: "ITT Technical Institute-Tulsa",
  },
  {
    type: "School",
    name: "University of Phoenix-Omaha Campus",
  },
  {
    type: "School",
    name: "University of Phoenix-Northwest Arkansas Campus",
  },
  {
    type: "School",
    name: "University of Phoenix-Madison Campus",
  },
  {
    type: "School",
    name: "University of Phoenix-Columbia Campus",
  },
  {
    type: "School",
    name: "The Art Institute of California-Argosy University Inland Empire",
  },
  {
    type: "School",
    name: "National American University-Zona Rosa",
  },
  {
    type: "School",
    name: "West Hills College-Lemoore",
  },
  {
    type: "School",
    name: "Stautzenberger College-Brecksville",
  },
  {
    type: "School",
    name: "Stone Academy-East Hartford",
  },
  {
    type: "School",
    name: "Westwood College-Annandale",
  },
  {
    type: "School",
    name: "West Hills Community College District",
  },
  {
    type: "School",
    name: "Unitech Training Academy-Houma",
  },
  {
    type: "School",
    name: "Kaplan College-Milwaukee",
  },
  {
    type: "School",
    name: "Miller-Motte Technical College-Madison",
  },
  {
    type: "School",
    name: "Rasmussen College-Illinois",
  },
  {
    type: "School",
    name: "International Institute for Restorative Practices",
  },
  {
    type: "School",
    name: "Educational Technical College-Recinto de Coamo",
  },
  {
    type: "School",
    name: "Educational Technical College-Recinto de san Sebastian",
  },
  {
    type: "School",
    name: "Argosy University-Denver",
  },
  {
    type: "School",
    name: "College America-Colorado Springs",
  },
  {
    type: "School",
    name: "College America-Fort Collins",
  },
  {
    type: "School",
    name: "Automotive Training Center-Warminster",
  },
  {
    type: "School",
    name: "Sanford-Brown College-West Allis",
  },
  {
    type: "School",
    name: "Empire Beauty School-Milwaukee",
  },
  {
    type: "School",
    name: "The Robert B Miller College",
  },
  {
    type: "School",
    name: "University of Phoenix-Fairfield County Campus",
  },
  {
    type: "School",
    name: "University of Phoenix-Harrisburg Campus",
  },
  {
    type: "School",
    name: "University of South Florida-St Petersburg",
  },
  {
    type: "School",
    name: "Triangle Tech Inc-Bethlehem",
  },
  {
    type: "School",
    name: "DeVry University-Utah",
  },
  {
    type: "School",
    name: "Arizona State University-Downtown Phoenix",
  },
  {
    type: "School",
    name: "Milan Institute-Clovis",
  },
  {
    type: "School",
    name: "Branford Hall Career Institute-Albany Campus",
  },
  {
    type: "School",
    name: "Harris School of Business-Linwood Campus",
  },
  {
    type: "School",
    name: "The Art Institute of Tennessee-Nashville",
  },
  {
    type: "School",
    name: "Strayer University-Florida",
  },
  {
    type: "School",
    name: "Pima Medical Institute-Renton",
  },
  {
    type: "School",
    name: "College of Business and Technology-Flagler",
  },
  {
    type: "School",
    name: "College of Business and Technology-Hialeah",
  },
  {
    type: "School",
    name: "Miller-Motte College-Cary",
  },
  {
    type: "School",
    name: "Miami-Jacobs Career College-Springboro",
  },
  {
    type: "School",
    name: "Dewey University-Juana D�az",
  },
  {
    type: "School",
    name: "Dewey University-Fajardo",
  },
  {
    type: "School",
    name: "Dewey University-Arroyo",
  },
  {
    type: "School",
    name: "Regency Beauty Institute-Independence",
  },
  {
    type: "School",
    name: "Regency Beauty Institute-St Peters",
  },
  {
    type: "School",
    name: "Regency Beauty Institute-Peoria",
  },
  {
    type: "School",
    name: "Regency Beauty Institute-Greenwood",
  },
  {
    type: "School",
    name: "Regency Beauty Institute-Mehlville",
  },
  {
    type: "School",
    name: "Regency Beauty Institute-Duluth",
  },
  {
    type: "School",
    name: "Regency Beauty Institute-Fairview Heights",
  },
  {
    type: "School",
    name: "Regency Beauty Institute-Champaign",
  },
  {
    type: "School",
    name: "Regency Beauty Institute-Olathe",
  },
  {
    type: "School",
    name: "The College of Health Care Professions-San Antonio",
  },
  {
    type: "School",
    name: "Everest Institute-South Plainfield",
  },
  {
    type: "School",
    name: "Kaplan Career Institute-Detroit",
  },
  {
    type: "School",
    name: "Daymar College-Madisonville",
  },
  {
    type: "School",
    name: "Brittany Beauty School",
  },
  {
    type: "School",
    name: "Lincoln Technical Institute-Whitestone",
  },
  {
    type: "School",
    name: "American Public University System",
  },
  {
    type: "School",
    name: "Huntsville Bible College",
  },
  {
    type: "School",
    name: "Beaumont Adult School",
  },
  {
    type: "School",
    name: "Make-up Designory",
  },
  {
    type: "School",
    name: "Video Symphony EnterTraining Inc",
  },
  {
    type: "School",
    name: "Gnomon School of Visual Effects",
  },
  {
    type: "School",
    name: "Coachella Valley Beauty College",
  },
  {
    type: "School",
    name: "Career College of California",
  },
  {
    type: "School",
    name: "Valley College of Medical Careers",
  },
  {
    type: "School",
    name: "Academy of Natural Therapy Inc",
  },
  {
    type: "School",
    name: "Academy Di Capelli-School of Cosmetology",
  },
  {
    type: "School",
    name: "Delaware Learning Institute of Cosmetology",
  },
  {
    type: "School",
    name: "Dragon Rises College of Oriental Medicine",
  },
  {
    type: "School",
    name: "Palm Beach Academy of Health & Beauty",
  },
  {
    type: "School",
    name: "SABER College",
  },
  {
    type: "School",
    name: "Taylor College",
  },
  {
    type: "School",
    name: "Pensacola School of Massage Therapy & Health Careers",
  },
  {
    type: "School",
    name: "Hawaii College of Oriental Medicine",
  },
  {
    type: "School",
    name: "Ideal Beauty Academy",
  },
  {
    type: "School",
    name: "Success Schools",
  },
  {
    type: "School",
    name: "Louisiana Culinary Institute",
  },
  {
    type: "School",
    name: "Bais Medrash Toras Chesed",
  },
  {
    type: "School",
    name: "Center for Natural Wellness School of Massage Therapy",
  },
  {
    type: "School",
    name: "Ace Computer Training Center",
  },
  {
    type: "School",
    name: "New Age Training",
  },
  {
    type: "School",
    name: "Dayton School of Medical Massage",
  },
  {
    type: "School",
    name: "Northcoast Medical Training Academy",
  },
  {
    type: "School",
    name: "American Institute of Medical Technology",
  },
  {
    type: "School",
    name: "Carib Technological Institute",
  },
  {
    type: "School",
    name: "Visible Music College",
  },
  {
    type: "School",
    name: "CCI Training Center-Arlington",
  },
  {
    type: "School",
    name: "Champion Beauty College",
  },
  {
    type: "School",
    name: "Professional Careers Institute",
  },
  {
    type: "School",
    name: "Southeast Texas Career Institute",
  },
  {
    type: "School",
    name: "Southwest Applied Technology Center",
  },
  {
    type: "School",
    name: "Academy of Cosmetology",
  },
  {
    type: "School",
    name: "Milwaukee Career College",
  },
  {
    type: "School",
    name: "University of the West",
  },
  {
    type: "School",
    name: "American College of Education",
  },
  {
    type: "School",
    name: "South University-Tampa",
  },
  {
    type: "School",
    name: "Milan Institute of Cosmetology-San Antonio Military",
  },
  {
    type: "School",
    name: "Appalachian College of Pharmacy",
  },
  {
    type: "School",
    name: "Averett University-Non-Traditional Programs",
  },
  {
    type: "School",
    name: "Paul Mitchell the School-Tampa",
  },
  {
    type: "School",
    name: "Paul Mitchell the School-San Diego",
  },
  {
    type: "School",
    name: "Paul Mitchell the School-Michigan",
  },
  {
    type: "School",
    name: "Paul Mitchell the School-Sherman Oaks",
  },
  {
    type: "School",
    name: "Paul Mitchell the School-Mclean",
  },
  {
    type: "School",
    name: "Miami-Jacobs Career College-Troy",
  },
  {
    type: "School",
    name: "ATI College-Santa Ana",
  },
  {
    type: "School",
    name: "Blue Cliff College-Houma",
  },
  {
    type: "School",
    name: "The Art Institute of Salt Lake City",
  },
  {
    type: "School",
    name: "Fortis College-Columbus",
  },
  {
    type: "School",
    name: "Fortis College-Cincinnati",
  },
  {
    type: "School",
    name: "Fortis Institute-Baltimore",
  },
  {
    type: "School",
    name: "The Art Institute of Charleston",
  },
  {
    type: "School",
    name: "The Art Institute of California-Argosy University Sacramento",
  },
  {
    type: "School",
    name: "Blue Cliff College-Fayetteville",
  },
  {
    type: "School",
    name: "Blue Cliff College-Alexandria",
  },
  {
    type: "School",
    name: "StenoTech Career Institute-Piscataway",
  },
  {
    type: "School",
    name: "Anthem College-Brookfield",
  },
  {
    type: "School",
    name: "Ultimate Medical Academy-Tampa",
  },
  {
    type: "School",
    name: "Beauty Schools of America-North Miami Beach",
  },
  {
    type: "School",
    name: "Virginia College-Biloxi",
  },
  {
    type: "School",
    name: "ITT Technical Institute-St Petersburg",
  },
  {
    type: "School",
    name: "ITT Technical Institute-Baton Rouge",
  },
  {
    type: "School",
    name: "ITT Technical Institute-Columbia",
  },
  {
    type: "School",
    name: "ITT Technical Institute-Wichita",
  },
  {
    type: "School",
    name: "ITT Technical Institute-Atlanta",
  },
  {
    type: "School",
    name: "ITT Technical Institute-Mobile",
  },
  {
    type: "School",
    name: "ITT Technical Institute-Chattanooga",
  },
  {
    type: "School",
    name: "ITT Technical Institute-South Bend",
  },
  {
    type: "School",
    name: "Virginia College-School of Business and Health-Chattanooga",
  },
  {
    type: "School",
    name: "Strayer University-Delaware",
  },
  {
    type: "School",
    name: "Brite Divinity School",
  },
  {
    type: "School",
    name: "Strayer University-Alabama",
  },
  {
    type: "School",
    name: "Brown Aveda Institute-Rocky River",
  },
  {
    type: "School",
    name: "Clary Sage College",
  },
  {
    type: "School",
    name: "Broadview University-Layton",
  },
  {
    type: "School",
    name: "International Academy of Design and Technology-Sacramento",
  },
  {
    type: "School",
    name: "University of Phoenix-Birmingham Campus",
  },
  {
    type: "School",
    name: "International Academy of Design and Technology-San Antonio",
  },
  {
    type: "School",
    name: "University of Phoenix-Augusta Campus",
  },
  {
    type: "School",
    name: "University of Phoenix-Washington DC Campus",
  },
  {
    type: "School",
    name: "University of Phoenix-Chattanooga Campus",
  },
  {
    type: "School",
    name: "DeVry University-Michigan",
  },
  {
    type: "School",
    name: "DeVry University-Tennessee",
  },
  {
    type: "School",
    name: "Argosy University-Inland Empire",
  },
  {
    type: "School",
    name: "Argosy University-Nashville",
  },
  {
    type: "School",
    name: "Argosy University-San Diego",
  },
  {
    type: "School",
    name: "Rasmussen College-Wisconsin",
  },
  {
    type: "School",
    name: "Empire Beauty School-Lisle",
  },
  {
    type: "School",
    name: "Empire Beauty School-Richmond",
  },
  {
    type: "School",
    name: "Empire Beauty School-North Hills",
  },
  {
    type: "School",
    name: "Empire Beauty School-Concord",
  },
  {
    type: "School",
    name: "Empire Beauty School-Arlington Heights",
  },
  {
    type: "School",
    name: "Lexington Healing Arts Academy",
  },
  {
    type: "School",
    name: "Empire Beauty School-Hooksett",
  },
  {
    type: "School",
    name: "The Institute of Beauty and Wellness",
  },
  {
    type: "School",
    name: "InterCoast Colleges-Carson",
  },
  {
    type: "School",
    name: "InterCoast Career Institute-South Portland",
  },
  {
    type: "School",
    name: "Carrington College California-Stockton",
  },
  {
    type: "School",
    name: "Carrington College California-Citrus Heights",
  },
  {
    type: "School",
    name: "Euphoria Institute of Beauty Arts & Sciences-Summerlin",
  },
  {
    type: "School",
    name: "Euphoria Institute of Beauty Arts & Sciences-Green Valley",
  },
  {
    type: "School",
    name: "Empire Beauty School-Portsmouth",
  },
  {
    type: "School",
    name: "LIU Riverhead",
  },
  {
    type: "School",
    name: "Newbridge College-Long Beach",
  },
  {
    type: "School",
    name: "Regency Beauty Institute-Lakewood",
  },
  {
    type: "School",
    name: "Regency Beauty Institute-Westminster",
  },
  {
    type: "School",
    name: "Regency Beauty Institute-Avon",
  },
  {
    type: "School",
    name: "Regency Beauty Institute-Joliet",
  },
  {
    type: "School",
    name: "Regency Beauty Institute-Rockford",
  },
  {
    type: "School",
    name: "Regency Beauty Institute-Greenfield",
  },
  {
    type: "School",
    name: "Regency Beauty Institute-Metro Center",
  },
  {
    type: "School",
    name: "Regency Beauty Institute-East Tucson",
  },
  {
    type: "School",
    name: "Regency Beauty Institute-Tri-County",
  },
  {
    type: "School",
    name: "Regency Beauty Institute-Eastgate",
  },
  {
    type: "School",
    name: "Regency Beauty Institute-Topeka",
  },
  {
    type: "School",
    name: "Columbia Southern University",
  },
  {
    type: "School",
    name: "Arizona Summit Law School",
  },
  {
    type: "School",
    name: "Arizona Culinary Institute",
  },
  {
    type: "School",
    name: "InfoTech Career College",
  },
  {
    type: "School",
    name: "Trident University International",
  },
  {
    type: "School",
    name: "Coastline Beauty College",
  },
  {
    type: "School",
    name: "Career College Consultants",
  },
  {
    type: "School",
    name: "San Diego College",
  },
  {
    type: "School",
    name: "Montessori Casa International",
  },
  {
    type: "School",
    name: "Branford Academy of Hair and Cosmetology",
  },
  {
    type: "School",
    name: "Academy of Massage and Bodywork",
  },
  {
    type: "School",
    name: "Digital Media Arts College",
  },
  {
    type: "School",
    name: "Academy for Five Element Acupuncture",
  },
  {
    type: "School",
    name: "Academy of Career Training",
  },
  {
    type: "School",
    name: "Management Resources Institute",
  },
  {
    type: "School",
    name: "Paul Mitchell the School-Miami",
  },
  {
    type: "School",
    name: "Wolford College",
  },
  {
    type: "School",
    name: "Aveda Institute-South Florida",
  },
  {
    type: "School",
    name: "Aveda Institute-Tallahassee",
  },
  {
    type: "School",
    name: "Shear Excellence Hair Academy",
  },
  {
    type: "School",
    name: "Carlson College of Massage Therapy",
  },
  {
    type: "School",
    name: "Bio-Chi Institute of Massage Therapy",
  },
  {
    type: "School",
    name: "SOLEX Medical Academy",
  },
  {
    type: "School",
    name: "MyComputerCareer.com-Indianapolis",
  },
  {
    type: "School",
    name: "Z Hair Academy",
  },
  {
    type: "School",
    name: "DiGrigoli School of Cosmetology",
  },
  {
    type: "School",
    name: "Millennium Training Institute",
  },
  {
    type: "School",
    name: "Gallery College of Beauty",
  },
  {
    type: "School",
    name: "Lakewood School of Therapeutic Massage",
  },
  {
    type: "School",
    name: "The Salon Spa Academy",
  },
  {
    type: "School",
    name: "Trend Setters School of Cosmetology",
  },
  {
    type: "School",
    name: "Academy of Hair Design-Springfield",
  },
  {
    type: "School",
    name: "Corinth Academy of Cosmetology",
  },
  {
    type: "School",
    name: "Southeastern Institute-Charlotte",
  },
  {
    type: "School",
    name: "Gentle Healing School of Massage",
  },
  {
    type: "School",
    name: "Yeshivas Be'er Yitzchok",
  },
  {
    type: "School",
    name: "Yeshiva Toras Chaim",
  },
  {
    type: "School",
    name: "Talmudical Seminary of Bobov",
  },
  {
    type: "School",
    name: "New York Methodist Hospital Center for Allied Health Education",
  },
  {
    type: "School",
    name: "Institute of Allied Medical Professions-New York",
  },
  {
    type: "School",
    name: "Micropower Career Institute",
  },
  {
    type: "School",
    name: "Monroe 2 Orleans BOCES-Center for Workforce Development",
  },
  {
    type: "School",
    name: "Aveda Fredric's Institute-Cincinnati",
  },
  {
    type: "School",
    name: "MyComputerCareer.com-TechSkills",
  },
  {
    type: "School",
    name: "CDE Career Institute",
  },
  {
    type: "School",
    name: "Instituto Educativo Premier",
  },
  {
    type: "School",
    name: "Charleston School of Law",
  },
  {
    type: "School",
    name: "Elite College of Cosmetology",
  },
  {
    type: "School",
    name: "Memphis Institute of Barbering",
  },
  {
    type: "School",
    name: "Manuel and Theresa's School of Hair Design-Bryan",
  },
  {
    type: "School",
    name: "Southwest University at El Paso",
  },
  {
    type: "School",
    name: "Paul Mitchell the School-Austin",
  },
  {
    type: "School",
    name: "Aveda Institute-Provo",
  },
  {
    type: "School",
    name: "Dixie Applied Technology College",
  },
  {
    type: "School",
    name: "Centura College-Alexandria",
  },
  {
    type: "School",
    name: "Southeast Culinary & Hospitality College",
  },
  {
    type: "School",
    name: "Dominion School of Hair Design",
  },
  {
    type: "School",
    name: "Victoria's Academy of Cosmetology",
  },
  {
    type: "School",
    name: "Inland Massage Institute",
  },
  {
    type: "School",
    name: "Sunnyside Beauty Academy",
  },
  {
    type: "School",
    name: "Wisconsin Academy",
  },
  {
    type: "School",
    name: "The Art Institute of Pittsburgh-Online Division",
  },
  {
    type: "School",
    name: "University of South Florida-Sarasota-Manatee",
  },
  {
    type: "School",
    name: "Brown Mackie College-Indianapolis",
  },
  {
    type: "School",
    name: "College America-Cheyenne",
  },
  {
    type: "School",
    name: "Paul Mitchell the School-San Antonio",
  },
  {
    type: "School",
    name: "Centro de Estudios Multidisciplinarios-Bayamon",
  },
  {
    type: "School",
    name: "Bryant & Stratton College-Wauwatosa",
  },
  {
    type: "School",
    name: "Minnesota School of Business-Blaine",
  },
  {
    type: "School",
    name: "The Art Institute of Michigan",
  },
  {
    type: "School",
    name: "Lacy Cosmetology School-Lexington",
  },
  {
    type: "School",
    name: "Lacy Cosmetology School-Goose Creek",
  },
  {
    type: "School",
    name: "The Art Institute of Austin",
  },
  {
    type: "School",
    name: "The Art Institute of California-Argosy University-Silicon Valley",
  },
  {
    type: "School",
    name: "Remington College-Houston Southeast Campus",
  },
  {
    type: "School",
    name: "Remington College-Shreveport Campus",
  },
  {
    type: "School",
    name: "National American University-Austin",
  },
  {
    type: "School",
    name: "National American University-Wichita",
  },
  {
    type: "School",
    name: "Lawton Career Institute-Warren Main Campus",
  },
  {
    type: "School",
    name: "Fortis College-Landover",
  },
  {
    type: "School",
    name: "Jenny Lea Academy of Cosmetology and Aesthetics",
  },
  {
    type: "School",
    name: "ITT Technical Institute-Cary",
  },
  {
    type: "School",
    name: "ITT Technical Institute-Madison",
  },
  {
    type: "School",
    name: "ITT Technical Institute-Clive",
  },
  {
    type: "School",
    name: "ITT Technical Institute-Columbus",
  },
  {
    type: "School",
    name: "ITT Technical Institute-Phoenix",
  },
  {
    type: "School",
    name: "ITT Technical Institute-Madison",
  },
  {
    type: "School",
    name: "ITT Technical Institute-High Point",
  },
  {
    type: "School",
    name: "The Hair Academy",
  },
  {
    type: "School",
    name: "The Art Institutes International�Kansas City",
  },
  {
    type: "School",
    name: "The Art Institute of Raleigh-Durham",
  },
  {
    type: "School",
    name: "Le Cordon Bleu College of Culinary Arts-Sacramento",
  },
  {
    type: "School",
    name: "Le Cordon Bleu College of Culinary Arts-Seattle",
  },
  {
    type: "School",
    name: "Le Cordon Bleu College of Culinary Arts-Cambridge",
  },
  {
    type: "School",
    name: "Le Cordon Bleu College of Culinary Arts-Dallas",
  },
  {
    type: "School",
    name: "Ross Medical Education Center-New Baltimore",
  },
  {
    type: "School",
    name: "Ross College-Sylvania",
  },
  {
    type: "School",
    name: "Argosy University-Salt Lake City",
  },
  {
    type: "School",
    name: "Escuela De Troqueleria Y Herramentaje",
  },
  {
    type: "School",
    name: "Virginia College-Montgomery",
  },
  {
    type: "School",
    name: "Fortis Institute-Nashville",
  },
  {
    type: "School",
    name: "Beckfield College-Tri-County",
  },
  {
    type: "School",
    name: "Community Technology Learning Center of Portage",
  },
  {
    type: "School",
    name: "David-Curtis School of Floral Design",
  },
  {
    type: "School",
    name: "Dental Assistant Pro LLC-Columbus",
  },
  {
    type: "School",
    name: "Dental Assistant Pro-Lebanon",
  },
  {
    type: "School",
    name: "Iverson Institute",
  },
  {
    type: "School",
    name: "Galen College of Nursing-Cincinnati",
  },
  {
    type: "School",
    name: "Harmony Path School of Massage Therapy",
  },
  {
    type: "School",
    name: "Institute of Therapeutic Massage",
  },
  {
    type: "School",
    name: "Pinnacle Career Institute-North Kansas City",
  },
  {
    type: "School",
    name: "Strayer University-Kentucky",
  },
  {
    type: "School",
    name: "Strayer University-North Carolina",
  },
  {
    type: "School",
    name: "Strayer University-New Jersey",
  },
  {
    type: "School",
    name: "International Culinary Arts and Sciences Institute",
  },
  {
    type: "School",
    name: "Kaplan College-Cincinnati",
  },
  {
    type: "School",
    name: "National College-Dayton",
  },
  {
    type: "School",
    name: "National College-Youngstown",
  },
  {
    type: "School",
    name: "National College-Cincinnati",
  },
  {
    type: "School",
    name: "National College-Stow",
  },
  {
    type: "School",
    name: "Harris School of Business-Hamilton Campus",
  },
  {
    type: "School",
    name: "Empire Beauty School-Eden Prairie",
  },
  {
    type: "School",
    name: "Ohio Business College-Hilliard",
  },
  {
    type: "School",
    name: "Ohio Center for Broadcasting-Columbus",
  },
  {
    type: "School",
    name: "Ohio Medical Career Center",
  },
  {
    type: "School",
    name: "Ohio Technical College-PowerSport Institute",
  },
  {
    type: "School",
    name: "Reflexology Certification Institute",
  },
  {
    type: "School",
    name: "Toledo Restaurant Training Center",
  },
  {
    type: "School",
    name: "Dade Medical College-Miami Lakes",
  },
  {
    type: "School",
    name: "The King�s College",
  },
  {
    type: "School",
    name: "DeVry University-Kentucky",
  },
  {
    type: "School",
    name: "Chamberlain College of Nursing-Administrative Office",
  },
  {
    type: "School",
    name: "Chamberlain College of Nursing-Illinois",
  },
  {
    type: "School",
    name: "Chamberlain College of Nursing-Ohio",
  },
  {
    type: "School",
    name: "Chamberlain College of Nursing-Arizona",
  },
  {
    type: "School",
    name: "Career Quest Learning Centers-Jackson",
  },
  {
    type: "School",
    name: "Regency Beauty Institute-Fort Collins",
  },
  {
    type: "School",
    name: "Regency Beauty Institute-Tolleson",
  },
  {
    type: "School",
    name: "Regency Beauty Institute-Pasadena",
  },
  {
    type: "School",
    name: "Regency Beauty Institute-Dayton",
  },
  {
    type: "School",
    name: "Regency Beauty Institute-Akron",
  },
  {
    type: "School",
    name: "Regency Beauty Institute-Detroit Southgate",
  },
  {
    type: "School",
    name: "Regency Beauty Institute-Flint",
  },
  {
    type: "School",
    name: "Regency Beauty Institute-Grand Rapids",
  },
  {
    type: "School",
    name: "Regency Beauty Institute-Cypresswood",
  },
  {
    type: "School",
    name: "Bryan University",
  },
  {
    type: "School",
    name: "Regency Beauty Institute-Austin",
  },
  {
    type: "School",
    name: "Regency Beauty Institute-Mesa",
  },
  {
    type: "School",
    name: "Regency Beauty Institute-Nashville",
  },
  {
    type: "School",
    name: "Regency Beauty Institute-Charlotte",
  },
  {
    type: "School",
    name: "Regency Beauty Institute-North Olmsted",
  },
  {
    type: "School",
    name: "Regency Beauty Institute-Columbus",
  },
  {
    type: "School",
    name: "Regency Beauty Institute-Elgin",
  },
  {
    type: "School",
    name: "The Salon Professional Academy-Appleton",
  },
  {
    type: "School",
    name: "Milan Institute of Cosmetology-Fairfield",
  },
  {
    type: "School",
    name: "Milan Institute of Cosmetology-Reno",
  },
  {
    type: "School",
    name: "Milan Institute of Cosmetology-Visalia",
  },
  {
    type: "School",
    name: "Ottawa University-Online",
  },
  {
    type: "School",
    name: "Broadview University-Orem",
  },
  {
    type: "School",
    name: "Paul Mitchell the School-Esani",
  },
  {
    type: "School",
    name: "Institute of Production and Recording",
  },
  {
    type: "School",
    name: "Virginia College-Greenville",
  },
  {
    type: "School",
    name: "Paul Mitchell the School-Birmingham",
  },
  {
    type: "School",
    name: "Taft University System",
  },
  {
    type: "School",
    name: "Mayfield College",
  },
  {
    type: "School",
    name: "International Polytechnic Institute",
  },
  {
    type: "School",
    name: "Academy of Esthetics and Cosmetology",
  },
  {
    type: "School",
    name: "BioHealth College",
  },
  {
    type: "School",
    name: "International Professional School of Bodywork",
  },
  {
    type: "School",
    name: "Borner's Barber College",
  },
  {
    type: "School",
    name: "Paul Mitchell the School-Pasadena",
  },
  {
    type: "School",
    name: "San Francisco Institute of Esthetics and Cosmetology",
  },
  {
    type: "School",
    name: "Laurus College",
  },
  {
    type: "School",
    name: "Healing Hands School of Holistic Health",
  },
  {
    type: "School",
    name: "Career Development Institute",
  },
  {
    type: "School",
    name: "Auguste Escoffier School of Culinary Arts-Boulder",
  },
  {
    type: "School",
    name: "Aspen University",
  },
  {
    type: "School",
    name: "Institute of Taoist Education and Acupuncture",
  },
  {
    type: "School",
    name: "National Beauty College",
  },
  {
    type: "School",
    name: "Denver School of Nursing",
  },
  {
    type: "School",
    name: "Cambridge Institute of Health & Technology",
  },
  {
    type: "School",
    name: "Cozmo The School",
  },
  {
    type: "School",
    name: "Allied Health Institute",
  },
  {
    type: "School",
    name: "North Florida Academy",
  },
  {
    type: "School",
    name: "Professional Hands Institute",
  },
  {
    type: "School",
    name: "Celebrity School of Beauty",
  },
  {
    type: "School",
    name: "Paul Mitchell the School-Atlanta",
  },
  {
    type: "School",
    name: "Evans Hairstyling College-Rexburg",
  },
  {
    type: "School",
    name: "Hair Academy Paul Mitchell Partner School",
  },
  {
    type: "School",
    name: "University of Aesthetics-Chicago",
  },
  {
    type: "School",
    name: "University of Aesthetics-Downers Grove",
  },
  {
    type: "School",
    name: "Tricoci University of Beauty Culture-Chicago",
  },
  {
    type: "School",
    name: "Tricoci University of Beauty Culture-Glendale Heights",
  },
  {
    type: "School",
    name: "Tricoci University of Beauty Culture-Peoria",
  },
  {
    type: "School",
    name: "Tricoci University of Beauty Culture-Rockford",
  },
  {
    type: "School",
    name: "Frederick School of Cosmetology",
  },
  {
    type: "School",
    name: "Omega Studios' School of Applied Recording Arts & Sciences",
  },
  {
    type: "School",
    name: "Focus-Hope Information Technologies Center",
  },
  {
    type: "School",
    name: "International Cosmetology Academy",
  },
  {
    type: "School",
    name: "French Academy of Cosmetology",
  },
  {
    type: "School",
    name: "Healing Arts Center",
  },
  {
    type: "School",
    name: "Paul Mitchell the School-Springfield",
  },
  {
    type: "School",
    name: "Urshan Graduate School of Theology",
  },
  {
    type: "School",
    name: "Mississippi Institute of Aesthetics Nails & Cosmetology",
  },
  {
    type: "School",
    name: "College of Western Idaho",
  },
  {
    type: "School",
    name: "Aveda Institute-Chapel Hill",
  },
  {
    type: "School",
    name: "Charlotte School of Law",
  },
  {
    type: "School",
    name: "Daoist Traditions College of Chinese Medical Arts",
  },
  {
    type: "School",
    name: "Total Image Beauty Academy",
  },
  {
    type: "School",
    name: "Jersey College",
  },
  {
    type: "School",
    name: "American Institute of Medical Sciences & Education",
  },
  {
    type: "School",
    name: "Casal Institute of Nevada",
  },
  {
    type: "School",
    name: "Career School of NY",
  },
  {
    type: "School",
    name: "John Paolo's Xtreme Beauty Institute-Goldwell Product Artistry",
  },
  {
    type: "School",
    name: "Ann Marie's World of Beauty School",
  },
  {
    type: "School",
    name: "Yeshiva of Machzikai Hadas",
  },
  {
    type: "School",
    name: "Aveda Institute-Columbus",
  },
  {
    type: "School",
    name: "Paul Mitchell the School-Cincinnati",
  },
  {
    type: "School",
    name: "Cutter's Edge School of Cosmetology",
  },
  {
    type: "School",
    name: "Hands on Therapy",
  },
  {
    type: "School",
    name: "MediaTech Institute-Dallas",
  },
  {
    type: "School",
    name: "PCCenter",
  },
  {
    type: "School",
    name: "Aveda Institute-San Antonio",
  },
  {
    type: "School",
    name: "Skin Institute",
  },
  {
    type: "School",
    name: "Skin Science Institute",
  },
  {
    type: "School",
    name: "Global Health College",
  },
  {
    type: "School",
    name: "Pacific Northwest University of Health Sciences",
  },
  {
    type: "School",
    name: "Visions in Hair Design Institute of Cosmetology",
  },
  {
    type: "School",
    name: "Brensten Education",
  },
  {
    type: "School",
    name: "The Chicago School of Professional Psychology at Irvine",
  },
  {
    type: "School",
    name: "California InterContinental University",
  },
  {
    type: "School",
    name: "Fortis College-Dothan",
  },
  {
    type: "School",
    name: "Fortis Institute-Pensacola",
  },
  {
    type: "School",
    name: "Fortis College-Montgomery",
  },
  {
    type: "School",
    name: "Fortis College School of Cosmetology",
  },
  {
    type: "School",
    name: "Woodland Community College",
  },
  {
    type: "School",
    name: "Dorsey Business Schools-Farmington Hills",
  },
  {
    type: "School",
    name: "Dorsey Business Schools-Roseville Culinary Academy",
  },
  {
    type: "School",
    name: "Vatterott College-Appling Farms",
  },
  {
    type: "School",
    name: "Minnesota School of Business-Moorhead",
  },
  {
    type: "School",
    name: "Brown Mackie College-Boise",
  },
  {
    type: "School",
    name: "Brown Mackie College-Tulsa",
  },
  {
    type: "School",
    name: "Fortis Institute-Birmingham",
  },
  {
    type: "School",
    name: "Daymar College-Scottsville",
  },
  {
    type: "School",
    name: "Miller-Motte College-Greenville",
  },
  {
    type: "School",
    name: "The Chicago School of Professional Psychology at Los Angeles",
  },
  {
    type: "School",
    name: "Metro Business College-Arnold",
  },
  {
    type: "School",
    name: "The Chicago School of Professional Psychology at Westwood",
  },
  {
    type: "School",
    name: "Cardiac and Vascular Institute of Ultrasound",
  },
  {
    type: "School",
    name: "Virginia College-Jacksonville",
  },
  {
    type: "School",
    name: "Studio Academy of Beauty",
  },
  {
    type: "School",
    name: "California Nurses Educational Institute",
  },
  {
    type: "School",
    name: "Central Nursing College",
  },
  {
    type: "School",
    name: "RWM Fiber Optics",
  },
  {
    type: "School",
    name: "Paul Mitchell the School-Sacramento",
  },
  {
    type: "School",
    name: "Providence Christian College",
  },
  {
    type: "School",
    name: "Summit Salon & Beauty School",
  },
  {
    type: "School",
    name: "Oxford Academy of Hair Design",
  },
  {
    type: "School",
    name: "Institute of World Politics",
  },
  {
    type: "School",
    name: "Pontifical John Paul II Institute for Studies on Marriage and Family",
  },
  {
    type: "School",
    name: "Medical Institute of Palm Beach",
  },
  {
    type: "School",
    name: "Lake Lanier School of Massage",
  },
  {
    type: "School",
    name: "Oliver Finley Academy of Cosmetology",
  },
  {
    type: "School",
    name: "National Career College",
  },
  {
    type: "School",
    name: "Aveda Institute-Chicago",
  },
  {
    type: "School",
    name: "Tricoci University of Beauty Culture-Libertyville",
  },
  {
    type: "School",
    name: "Tricoci University of Beauty Culture-Bridgeview",
  },
  {
    type: "School",
    name: "The Temple-A Paul Mitchell Partner School",
  },
  {
    type: "School",
    name: "Center for Massage & Natural Health",
  },
  {
    type: "School",
    name: "Academy of Hair Design-Oklahoma City",
  },
  {
    type: "School",
    name: "Education and Technology Institute",
  },
  {
    type: "School",
    name: "Pulse Beauty Academy-A Paul Mitchell Partner School",
  },
  {
    type: "School",
    name: "Liberty Technical College",
  },
  {
    type: "School",
    name: "Toni & Guy Hairdressing Academy-Cranston",
  },
  {
    type: "School",
    name: "South Texas Training Center",
  },
  {
    type: "School",
    name: "Columbia College",
  },
  {
    type: "School",
    name: "Vermont College of Fine Arts",
  },
  {
    type: "School",
    name: "Advanced Welding Institute",
  },
  {
    type: "School",
    name: "The Art Institute of Washington-Dulles",
  },
  {
    type: "School",
    name: "Paul Mitchell the School-Memphis",
  },
  {
    type: "School",
    name: "Pima Medical Institute-East Valley",
  },
  {
    type: "School",
    name: "Virginia College-Charleston",
  },
  {
    type: "School",
    name: "The Ohio Academy Paul Mitchell Partner School-Cleveland",
  },
  {
    type: "School",
    name: "The Ohio Academy Paul Mitchell Partner School-Columbus",
  },
  {
    type: "School",
    name: "Georgia Beauty Academy",
  },
  {
    type: "School",
    name: "Stautzenberger Institute-Allen Park",
  },
  {
    type: "School",
    name: "Empire Beauty School-Lauderhill",
  },
  {
    type: "School",
    name: "Empire Beauty School-Spring Lake Park",
  },
  {
    type: "School",
    name: "MediaTech Institute-Austin",
  },
  {
    type: "School",
    name: "MediaTech Institute-Houston",
  },
  {
    type: "School",
    name: "Fortis College-Phoenix",
  },
  {
    type: "School",
    name: "Miller-Motte College-Raleigh",
  },
  {
    type: "School",
    name: "Lamson Institute",
  },
  {
    type: "School",
    name: "Euphoria Institute of Beauty Arts & Sciences-Las Vegas",
  },
  {
    type: "School",
    name: "Lincoln College of Technology-Toledo",
  },
  {
    type: "School",
    name: "College of Hair Design-East Campus",
  },
  {
    type: "School",
    name: "International Academy of Design and Technology-Online",
  },
  {
    type: "School",
    name: "Le Cordon Bleu College of Culinary Arts-St Louis",
  },
  {
    type: "School",
    name: "Sanford-Brown College-San Antonio",
  },
  {
    type: "School",
    name: "Heritage College-Wichita",
  },
  {
    type: "School",
    name: "Heritage College-Little Rock",
  },
  {
    type: "School",
    name: "Argosy University-Phoenix Online Division",
  },
  {
    type: "School",
    name: "MedTech College-Greenwood Campus",
  },
  {
    type: "School",
    name: "MedTech College-Ft Wayne Campus",
  },
  {
    type: "School",
    name: "MedTech College-Lexington Campus",
  },
  {
    type: "School",
    name: "ITT Technical Institute-Madison",
  },
  {
    type: "School",
    name: "ITT Technical Institute-Springfield",
  },
  {
    type: "School",
    name: "ITT Technical Institute-Huntington",
  },
  {
    type: "School",
    name: "ITT Technical Institute-Concord",
  },
  {
    type: "School",
    name: "ITT Technical Institute-Fort Myers",
  },
  {
    type: "School",
    name: "ITT Technical Institute-Charlotte North",
  },
  {
    type: "School",
    name: "Fortis College-Salt Lake City",
  },
  {
    type: "School",
    name: "Polytechnic University of Puerto Rico-Miami",
  },
  {
    type: "School",
    name: "Polytechnic University of Puerto Rico-Orlando",
  },
  {
    type: "School",
    name: "Ross Medical Education Center-Fort Wayne",
  },
  {
    type: "School",
    name: "Ross Medical Education Center-Portage",
  },
  {
    type: "School",
    name: "The Commonwealth Medical College",
  },
  {
    type: "School",
    name: "Josef's School of Hair Design Inc-Fargo West",
  },
  {
    type: "School",
    name: "Northeast Technology Center-Claremore",
  },
  {
    type: "School",
    name: "Salon Success Academy-Fontana",
  },
  {
    type: "School",
    name: "Salon Success Academy-Redlands",
  },
  {
    type: "School",
    name: "Brown Mackie College-Phoenix",
  },
  {
    type: "School",
    name: "Strayer University-West Virginia",
  },
  {
    type: "School",
    name: "Strayer University-Utah",
  },
  {
    type: "School",
    name: "Strayer University-Ohio",
  },
  {
    type: "School",
    name: "Regency Beauty Institute-Tinley Park",
  },
  {
    type: "School",
    name: "Regency Beauty Institute-Columbia",
  },
  {
    type: "School",
    name: "Regency Beauty Institute-South Bend",
  },
  {
    type: "School",
    name: "Regency Beauty Institute-Detroit Lakeside",
  },
  {
    type: "School",
    name: "Regency Beauty Institute-Fort Myers",
  },
  {
    type: "School",
    name: "Regency Beauty Institute-Arlington",
  },
  {
    type: "School",
    name: "Regency Beauty Institute-Durham",
  },
  {
    type: "School",
    name: "Minnesota School of Business-Elk River",
  },
  {
    type: "School",
    name: "Brown Mackie College-Greenville",
  },
  {
    type: "School",
    name: "Globe University-Sioux Falls",
  },
  {
    type: "School",
    name: "Globe University-Eau Claire",
  },
  {
    type: "School",
    name: "Vanguard College of Cosmetology-Baton Rouge",
  },
  {
    type: "School",
    name: "Globe University-Minneapolis",
  },
  {
    type: "School",
    name: "San Joaquin Valley College-Hesperia",
  },
  {
    type: "School",
    name: "Beauty Schools of America-Homestead",
  },
  {
    type: "School",
    name: "ATI Career Training Center-Dallas",
  },
  {
    type: "School",
    name: "South Texas Vocational Technical Institute-Brownsville",
  },
  {
    type: "School",
    name: "South Texas Vocational Technical Institute-Corpus Christi",
  },
  {
    type: "School",
    name: "L'Ecole Culinaire-Memphis",
  },
  {
    type: "School",
    name: "University of Minnesota-Rochester",
  },
  {
    type: "School",
    name: "Health And Style Institute",
  },
  {
    type: "School",
    name: "CBT College-Cutler Bay",
  },
  {
    type: "School",
    name: "Provo College�American Fork",
  },
  {
    type: "School",
    name: "National College-Columbus",
  },
  {
    type: "School",
    name: "Kaplan Career Institute-Boston",
  },
  {
    type: "School",
    name: "Herzing University-Toledo",
  },
  {
    type: "School",
    name: "Capri Beauty College",
  },
  {
    type: "School",
    name: "Milan Institute of Cosmetology-Concord",
  },
  {
    type: "School",
    name: "Milan Institute-Bakersfield",
  },
  {
    type: "School",
    name: "Homestead Schools",
  },
  {
    type: "School",
    name: "Carrington College-Las Vegas",
  },
  {
    type: "School",
    name: "Carrington College-Reno",
  },
  {
    type: "School",
    name: "Chamberlain College of Nursing-Florida",
  },
  {
    type: "School",
    name: "Remington College-Columbia Campus",
  },
  {
    type: "School",
    name: "Remington College of Nursing Orlando",
  },
  {
    type: "School",
    name: "Washington Barber College Inc",
  },
  {
    type: "School",
    name: "InterCoast Colleges-Elk Grove",
  },
  {
    type: "School",
    name: "Universal Barber College",
  },
  {
    type: "School",
    name: "Horizon University",
  },
  {
    type: "School",
    name: "San Diego Culinary Institute",
  },
  {
    type: "School",
    name: "Beyond 21st Century Beauty Academy",
  },
  {
    type: "School",
    name: "Franklin Career College",
  },
  {
    type: "School",
    name: "Academy for Jewish Religion-California",
  },
  {
    type: "School",
    name: "Hollywood Beauty College",
  },
  {
    type: "School",
    name: "Angeles College",
  },
  {
    type: "School",
    name: "Angeles Institute",
  },
  {
    type: "School",
    name: "Paul Mitchell the School-Temecula",
  },
  {
    type: "School",
    name: "European Academy of Cosmetology and Hairdressing",
  },
  {
    type: "School",
    name: "International Institute of Cosmetology",
  },
  {
    type: "School",
    name: "Florida School of Traditional Midwifery",
  },
  {
    type: "School",
    name: "Walton Career Development Center",
  },
  {
    type: "School",
    name: "Immokalee Technical Center",
  },
  {
    type: "School",
    name: "American Academy of Cosmetology",
  },
  {
    type: "School",
    name: "University of Fort Lauderdale",
  },
  {
    type: "School",
    name: "Aviation Institute of Maintenance-Orlando",
  },
  {
    type: "School",
    name: "The Salon Professional Academy-Tampa",
  },
  {
    type: "School",
    name: "The Salon Professional Academy-Gainesville",
  },
  {
    type: "School",
    name: "The Salon Professional Academy-The Villages",
  },
  {
    type: "School",
    name: "The Hair Academy Inc",
  },
  {
    type: "School",
    name: "Profile Institute of Barber-Styling",
  },
  {
    type: "School",
    name: "The Process Institute of Cosmetology",
  },
  {
    type: "School",
    name: "New Hope Christian College-Honolulu",
  },
  {
    type: "School",
    name: "D & L Academy of Hair Design",
  },
  {
    type: "School",
    name: "Master Educators Beauty School",
  },
  {
    type: "School",
    name: "Ambria College of Nursing",
  },
  {
    type: "School",
    name: "Midwestern Career College",
  },
  {
    type: "School",
    name: "Paul Mitchell the School-Normal",
  },
  {
    type: "School",
    name: "Innovations Design Academy",
  },
  {
    type: "School",
    name: "Aveda Fredric's Institute-Indianapolis",
  },
  {
    type: "School",
    name: "The Salon Professional Academy-Anderson",
  },
  {
    type: "School",
    name: "Eric Fisher Academy",
  },
  {
    type: "School",
    name: "A & W Healthcare Educators",
  },
  {
    type: "School",
    name: "My Le's Beauty College",
  },
  {
    type: "School",
    name: "Blackstone Valley Vocational Regional School District",
  },
  {
    type: "School",
    name: "Southern Worcester County Regional Voc School District",
  },
  {
    type: "School",
    name: "L'esprit Academy",
  },
  {
    type: "School",
    name: "Marketti Academy of Cosmetology",
  },
  {
    type: "School",
    name: "CenterPoint Massage and Shiatsu Therapy School and Clinic",
  },
  {
    type: "School",
    name: "Avalon School of Cosmetology",
  },
  {
    type: "School",
    name: "American Business and Technology University",
  },
  {
    type: "School",
    name: "City Vision College",
  },
  {
    type: "School",
    name: "Transformed Barber and Cosmetology Academy",
  },
  {
    type: "School",
    name: "Bitterroot School of Cosmetology",
  },
  {
    type: "School",
    name: "Atlanta Beauty & Barber Academy",
  },
  {
    type: "School",
    name: "The Salon Professional Academy-Great Falls",
  },
  {
    type: "School",
    name: "Park West Barber School",
  },
  {
    type: "School",
    name: "National Career Institute",
  },
  {
    type: "School",
    name: "Toni & Guy Hairdressing Academy-Albuquerque",
  },
  {
    type: "School",
    name: "International Academy of Style",
  },
  {
    type: "School",
    name: "EDP School of Computer Programming",
  },
  {
    type: "School",
    name: "New York Medical Career Training Center",
  },
  {
    type: "School",
    name: "Institute of Culinary Education",
  },
  {
    type: "School",
    name: "New Life Business Institute",
  },
  {
    type: "School",
    name: "The Salon Professional Academy-Tonawanda",
  },
  {
    type: "School",
    name: "Onondaga School of Therapeutic Massage-Syracuse",
  },
  {
    type: "School",
    name: "Academy of Cosmetology and Esthetics NYC",
  },
  {
    type: "School",
    name: "Finger Lakes School of Massage",
  },
  {
    type: "School",
    name: "Four County Career Center",
  },
  {
    type: "School",
    name: "Vanity School of Cosmetology",
  },
  {
    type: "School",
    name: "Tolles Career and Technical Center",
  },
  {
    type: "School",
    name: "The Salon Professional Academy-Perrysburg",
  },
  {
    type: "School",
    name: "Portland Actors Conservatory",
  },
  {
    type: "School",
    name: "Somerset County Technology Center",
  },
  {
    type: "School",
    name: "Metro Beauty Academy",
  },
  {
    type: "School",
    name: "Barone Beauty Academy",
  },
  {
    type: "School",
    name: "Academy for Careers and Technology",
  },
  {
    type: "School",
    name: "Aiken School of Cosmetology",
  },
  {
    type: "School",
    name: "Love Beauty School Inc",
  },
  {
    type: "School",
    name: "Professional Career Training Institute",
  },
  {
    type: "School",
    name: "Cannon Institute of Higher Learning",
  },
  {
    type: "School",
    name: "Cardiotech Ultrasound School",
  },
  {
    type: "School",
    name: "DuVall's School of Cosmetology",
  },
  {
    type: "School",
    name: "Advanced Beauty College",
  },
  {
    type: "School",
    name: "Texas Beauty College",
  },
  {
    type: "School",
    name: "Avenue Five Institute",
  },
  {
    type: "School",
    name: "Corpus Christi Beauty Academy",
  },
  {
    type: "School",
    name: "The Salon Professional Academy-Lewisville",
  },
  {
    type: "School",
    name: "Renaissance Academie",
  },
  {
    type: "School",
    name: "Bethel College",
  },
  {
    type: "School",
    name: "Institute of Advanced Medical Esthetics",
  },
  {
    type: "School",
    name: "Gary Manuel Aveda Institute",
  },
  {
    type: "School",
    name: "Northwest School of Wooden Boat Building",
  },
  {
    type: "School",
    name: "Bainbridge Graduate Institute",
  },
  {
    type: "School",
    name: "The Salon Professional Academy-Onalaska",
  },
  {
    type: "School",
    name: "Regency Beauty Institute-North Tucson",
  },
  {
    type: "School",
    name: "Kaplan College-Chula Vista",
  },
  {
    type: "School",
    name: "InterCoast Colleges-Roseville",
  },
  {
    type: "School",
    name: "West Coast University-Orange County",
  },
  {
    type: "School",
    name: "West Coast University-Ontario",
  },
  {
    type: "School",
    name: "Kaplan College-Pembroke Pines",
  },
  {
    type: "School",
    name: "Dade Medical College-Homestead",
  },
  {
    type: "School",
    name: "Virginia College-Augusta",
  },
  {
    type: "School",
    name: "Midwest Technical Institute-East Peoria",
  },
  {
    type: "School",
    name: "Paul Mitchell the School-Chicago",
  },
  {
    type: "School",
    name: "Kaplan College-Indianapolis",
  },
  {
    type: "School",
    name: "Regency Beauty Institute-Evansville",
  },
  {
    type: "School",
    name: "Regency Beauty Institute-Wichita",
  },
  {
    type: "School",
    name: "Regency Beauty Institute-Shreveport",
  },
  {
    type: "School",
    name: "Regency Beauty Institute-Springfield",
  },
  {
    type: "School",
    name: "Regency Beauty Institute-Canton",
  },
  {
    type: "School",
    name: "Regency Beauty Institute-Spartanburg",
  },
  {
    type: "School",
    name: "Regency Beauty Institute-Chattanooga",
  },
  {
    type: "School",
    name: "Regency Beauty Institute-Mesquite",
  },
  {
    type: "School",
    name: "Kaplan Career Institute-Dearborn",
  },
  {
    type: "School",
    name: "Dorsey Business Schools-Waterford Pontiac",
  },
  {
    type: "School",
    name: "Regina's College of Beauty-High Point",
  },
  {
    type: "School",
    name: "Miller-Motte College-Fayetteville",
  },
  {
    type: "School",
    name: "Drake College of Business-Newark",
  },
  {
    type: "School",
    name: "European Massage Therapy School-Las Vegas",
  },
  {
    type: "School",
    name: "Harrison College-Grove City",
  },
  {
    type: "School",
    name: "Miller-Motte Technical College-Columbus",
  },
  {
    type: "School",
    name: "National University College-Ponce",
  },
  {
    type: "School",
    name: "Fortis Institute-Grand Prairie",
  },
  {
    type: "School",
    name: "Paul Mitchell the School-St George",
  },
  {
    type: "School",
    name: "The Art Institute of Virginia Beach",
  },
  {
    type: "School",
    name: "Bryant & Stratton College-Bayshore",
  },
  {
    type: "School",
    name: "Gerstner Sloan-Kettering Graduate School of Biomedical Sciences",
  },
  {
    type: "School",
    name: "Daymar College-Online",
  },
  {
    type: "School",
    name: "Virginia College-Columbia",
  },
  {
    type: "School",
    name: "Sanford-Brown College-Dearborn",
  },
  {
    type: "School",
    name: "Sanford-Brown College-Grand Rapids",
  },
  {
    type: "School",
    name: "Sanford-Brown College-Indianapolis",
  },
  {
    type: "School",
    name: "Sanford-Brown College-Hillside",
  },
  {
    type: "School",
    name: "Sanford-Brown College-Tinley Park",
  },
  {
    type: "School",
    name: "Sanford-Brown College-Phoenix",
  },
  {
    type: "School",
    name: "Sanford-Brown Institute-Orlando",
  },
  {
    type: "School",
    name: "Fortis College-Indianapolis",
  },
  {
    type: "School",
    name: "Kaplan College-Arlington",
  },
  {
    type: "School",
    name: "Kaplan College-Charlotte",
  },
  {
    type: "School",
    name: "Kaplan College-Jacksonville",
  },
  {
    type: "School",
    name: "Globe University-Madison East",
  },
  {
    type: "School",
    name: "Minnesota School of Business-Lakeville",
  },
  {
    type: "School",
    name: "Globe University�Green Bay",
  },
  {
    type: "School",
    name: "Globe University�Madison West",
  },
  {
    type: "School",
    name: "Globe University�Wausau",
  },
  {
    type: "School",
    name: "Dade Medical College-Hollywood",
  },
  {
    type: "School",
    name: "Empire Beauty School-E Memphis",
  },
  {
    type: "School",
    name: "Empire Beauty School-Nashville",
  },
  {
    type: "School",
    name: "Empire Beauty School-Jackson",
  },
  {
    type: "School",
    name: "Empire Beauty School-Springfield",
  },
  {
    type: "School",
    name: "Empire Beauty School-Paramus",
  },
  {
    type: "School",
    name: "Empire Beauty School-Speedway",
  },
  {
    type: "School",
    name: "Empire Beauty School-Morrow",
  },
  {
    type: "School",
    name: "Strayer University-Arkansas",
  },
  {
    type: "School",
    name: "Strayer University-Georgia",
  },
  {
    type: "School",
    name: "Strayer University-Louisiana",
  },
  {
    type: "School",
    name: "Strayer University-Mississippi",
  },
  {
    type: "School",
    name: "Strayer University-South Carolina",
  },
  {
    type: "School",
    name: "Strayer University-Texas",
  },
  {
    type: "School",
    name: "The Art Institute of San Antonio",
  },
  {
    type: "School",
    name: "Globe University-La Crosse",
  },
  {
    type: "School",
    name: "The Hair Design School-S Memphis",
  },
  {
    type: "School",
    name: "The Hair Design School-Charlotte",
  },
  {
    type: "School",
    name: "The Hair Design School-Durham",
  },
  {
    type: "School",
    name: "The Hair Design School-E Greensboro",
  },
  {
    type: "School",
    name: "The Hair Design School-Winston-Salem",
  },
  {
    type: "School",
    name: "Empire Beauty School-West Palm",
  },
  {
    type: "School",
    name: "Empire Beauty School-Pineville",
  },
  {
    type: "School",
    name: "The Hair Design School-N Memphis",
  },
  {
    type: "School",
    name: "Broadview Entertainment Arts University",
  },
  {
    type: "School",
    name: "Bryant & Stratton College-Hampton",
  },
  {
    type: "School",
    name: "Paul Mitchell the School-Phoenix",
  },
  {
    type: "School",
    name: "All Beauty College",
  },
  {
    type: "School",
    name: "Northern California Institute of Cosmetology Inc",
  },
  {
    type: "School",
    name: "Golden State College of Court Reporting",
  },
  {
    type: "School",
    name: "Preferred College of Nursing-Los Angeles",
  },
  {
    type: "School",
    name: "Trinity Vocational Center",
  },
  {
    type: "School",
    name: "SICE Paul Mitchell Partner School",
  },
  {
    type: "School",
    name: "Cosmo Beauty Academy",
  },
  {
    type: "School",
    name: "Paul Mitchell the School-Fresno",
  },
  {
    type: "School",
    name: "Unitek College",
  },
  {
    type: "School",
    name: "Gurnick Academy of Medical Arts",
  },
  {
    type: "School",
    name: "Southern California University SOMA",
  },
  {
    type: "School",
    name: "Paul Mitchell the School-East Bay",
  },
  {
    type: "School",
    name: "South University�Richmond",
  },
  {
    type: "School",
    name: "South University�Virginia Beach",
  },
  {
    type: "School",
    name: "Aveda Institute-Denver",
  },
  {
    type: "School",
    name: "Paul Mitchell the School-Colorado Springs",
  },
  {
    type: "School",
    name: "The Salon Professional Academy-Colorado Springs",
  },
  {
    type: "School",
    name: "Paul Mitchell the School-Delaware",
  },
  {
    type: "School",
    name: "Future-Tech Institute",
  },
  {
    type: "School",
    name: "Marchman Technical Education Center",
  },
  {
    type: "School",
    name: "Eureka Institute of Health and Beauty",
  },
  {
    type: "School",
    name: "The Salon Professional Academy-Ft Myers",
  },
  {
    type: "School",
    name: "SOLEX College",
  },
  {
    type: "School",
    name: "Unity Cosmetology College",
  },
  {
    type: "School",
    name: "Universal Spa Training Academy",
  },
  {
    type: "School",
    name: "The Salon Professional Academy-Evansville",
  },
  {
    type: "School",
    name: "Tri County Regional Vocational Technical High School",
  },
  {
    type: "School",
    name: "Compass College of Cinematic Arts",
  },
  {
    type: "School",
    name: "Nova Academy of Cosmetology",
  },
  {
    type: "School",
    name: "Victory Trade School",
  },
  {
    type: "School",
    name: "Starting Points Inc",
  },
  {
    type: "School",
    name: "The Lab-Paul Mitchell Partner School",
  },
  {
    type: "School",
    name: "SAE Institute of Technology-New York",
  },
  {
    type: "School",
    name: "John Paolo's Xtreme Beauty Institute-Goldwell Product Artistry",
  },
  {
    type: "School",
    name: "The Artisan College of Cosmetology",
  },
  {
    type: "School",
    name: "Sage School of Massage",
  },
  {
    type: "School",
    name: "Lawyer's Assistant School of Dallas",
  },
  {
    type: "School",
    name: "ABC Beauty Academy",
  },
  {
    type: "School",
    name: "Salon & Spa Institute",
  },
  {
    type: "School",
    name: "American Beauty Academy",
  },
  {
    type: "School",
    name: "The Salon Professional Academy-Tacoma",
  },
  {
    type: "School",
    name: "Academy of Cosmetology",
  },
  {
    type: "School",
    name: "The Academy Waukesha",
  },
  {
    type: "School",
    name: "ITT Technical Institute-Merrillville",
  },
  {
    type: "School",
    name: "ITT Technical Institute-Tallahassee",
  },
  {
    type: "School",
    name: "ITT Technical Institute-Salem",
  },
  {
    type: "School",
    name: "ITT Technical Institute-Akron",
  },
  {
    type: "School",
    name: "ITT Technical Institute-Cedar Rapids",
  },
  {
    type: "School",
    name: "ITT Technical Institute-Corona",
  },
  {
    type: "School",
    name: "ITT Technical Institute-Johnson City",
  },
  {
    type: "School",
    name: "ITT Technical Institute-DeSoto",
  },
  {
    type: "School",
    name: "ITT Technical Institute-North Charleston",
  },
  {
    type: "School",
    name: "ITT Technical Institute-Aurora",
  },
  {
    type: "School",
    name: "ITT Technical Institute-West Covina",
  },
  {
    type: "School",
    name: "ITT Technical Institute-Culver City",
  },
  {
    type: "School",
    name: "ITT Technical Institute-Dearborn",
  },
  {
    type: "School",
    name: "ITT Technical Institute-Las Vegas",
  },
  {
    type: "School",
    name: "Touro University Worldwide",
  },
  {
    type: "School",
    name: "Touro University California",
  },
  {
    type: "School",
    name: "The Chicago School of Professional Psychology at Washington DC",
  },
  {
    type: "School",
    name: "Anamarc College-Santa Teresa",
  },
  {
    type: "School",
    name: "Touro University Nevada",
  },
  {
    type: "School",
    name: "National College-Willoughby Hills",
  },
  {
    type: "School",
    name: "Herzing University-Kenosha",
  },
  {
    type: "School",
    name: "Herzing University-Brookfield",
  },
  {
    type: "School",
    name: "Heald College-Modesto",
  },
  {
    type: "School",
    name: "Fortis Institute-Houston",
  },
  {
    type: "School",
    name: "Southern Careers Institute-Brownsville",
  },
  {
    type: "School",
    name: "Southern Careers Institute-Corpus Christi 2",
  },
  {
    type: "School",
    name: "Southern Careers Institute-Harlingen",
  },
  {
    type: "School",
    name: "Strayer University-Global Region",
  },
  {
    type: "School",
    name: "Regina's College of Beauty-Charlotte",
  },
  {
    type: "School",
    name: "Kenneth Shuler School of Cosmetology-Florence",
  },
  {
    type: "School",
    name: "Fortis College-Columbia",
  },
  {
    type: "School",
    name: "Brown Mackie College-Albuquerque",
  },
  {
    type: "School",
    name: "Brown Mackie College-St Louis",
  },
  {
    type: "School",
    name: "Avant Gard The School",
  },
  {
    type: "School",
    name: "Toni & Guy Hairdressing Academy-Bellingham",
  },
  {
    type: "School",
    name: "California University of Management and Sciences",
  },
  {
    type: "School",
    name: "Ross Medical Education Center-Davison",
  },
  {
    type: "School",
    name: "Ross Medical Education Center-Granger",
  },
  {
    type: "School",
    name: "Ross Medical Education Center-Niles",
  },
  {
    type: "School",
    name: "Ross Medical Education Center-Canton",
  },
  {
    type: "School",
    name: "Pima Medical Institute-Houston",
  },
  {
    type: "School",
    name: "Illinois Center for Broadcasting�Chicago Campus",
  },
  {
    type: "School",
    name: "South Texas Vocational Technical Institute-San Antonio",
  },
  {
    type: "School",
    name: "Concorde Career Institute-Dallas",
  },
  {
    type: "School",
    name: "Concorde Career Institute-Orlando",
  },
  {
    type: "School",
    name: "Concorde Career Institute-San Antonio",
  },
  {
    type: "School",
    name: "Ecotech Institute",
  },
  {
    type: "School",
    name: "Johnson & Wales University-Online",
  },
  {
    type: "School",
    name: "Everest College-Fort Worth South",
  },
  {
    type: "School",
    name: "Everest College-Santa Ana",
  },
  {
    type: "School",
    name: "Geisinger-Lewistown Hospital School of Nursing",
  },
  {
    type: "School",
    name: "Moreno Valley College",
  },
  {
    type: "School",
    name: "Hair Design Institute at Fifth Avenue-New York",
  },
  {
    type: "School",
    name: "Onondaga School of Therapeutic Massage-Rochester",
  },
  {
    type: "School",
    name: "Norco College",
  },
  {
    type: "School",
    name: "Daymar College-Louisville",
  },
  {
    type: "School",
    name: "Dorsey Business Schools-Saginaw",
  },
  {
    type: "School",
    name: "Milan Institute of Cosmetology-La Quinta",
  },
  {
    type: "School",
    name: "Milan Institute-Nampa",
  },
  {
    type: "School",
    name: "Milan Institute of Cosmetology-El Paso",
  },
  {
    type: "School",
    name: "Universal Technical Institute - Dallas Fort Worth",
  },
  {
    type: "School",
    name: "Finger Lakes School of Massage",
  },
  {
    type: "School",
    name: "Park Avenue School of Cosmetology",
  },
  {
    type: "School",
    name: "Salter School of Nursing and Allied Health",
  },
  {
    type: "School",
    name: "Salter School-New Bedford",
  },
  {
    type: "School",
    name: "Harris School of Business-Upper Darby Campus",
  },
  {
    type: "School",
    name: "Stratford School of Aviation Maintenance Technicians",
  },
  {
    type: "School",
    name: "Brown Mackie College-San Antonio",
  },
  {
    type: "School",
    name: "Hollywood Institute of Beauty Careers",
  },
  {
    type: "School",
    name: "Cortiva Institute-New Jersey",
  },
  {
    type: "School",
    name: "Cambridge Junior College-Woodland",
  },
  {
    type: "School",
    name: "Anthem College-Atlanta",
  },
  {
    type: "School",
    name: "ICPR Junior College-Manati",
  },
  {
    type: "School",
    name: "Western Beauty Institute",
  },
  {
    type: "School",
    name: "Ridley-Lowell Business & Technical Institute-Danbury",
  },
  {
    type: "School",
    name: "Artistic Nails and Beauty Academy-Lakeland",
  },
  {
    type: "School",
    name: "Paul Mitchell the School-Murfreesboro",
  },
  {
    type: "School",
    name: "Allied American University",
  },
  {
    type: "School",
    name: "American Sentinel University",
  },
  {
    type: "School",
    name: "Tribeca Flashpoint Media Arts Academy",
  },
  {
    type: "School",
    name: "Hawaii Medical College",
  },
  {
    type: "School",
    name: "Carolina College of Hair Design",
  },
  {
    type: "School",
    name: "Virginia College-Baton Rouge",
  },
  {
    type: "School",
    name: "Remington College-Heathrow Campus",
  },
  {
    type: "School",
    name: "College of Massage Therapy",
  },
  {
    type: "School",
    name: "Miller-Motte College-Jacksonville",
  },
  {
    type: "School",
    name: "Miller-Motte Technical College-Augusta",
  },
  {
    type: "School",
    name: "Miller-Motte Technical College-Conway",
  },
  {
    type: "School",
    name: "Virginia College-Macon",
  },
  {
    type: "School",
    name: "Virginia College-Spartanburg",
  },
  {
    type: "School",
    name: "Aveda Institute-Portland",
  },
  {
    type: "School",
    name: "Chamberlain College of Nursing-Virginia",
  },
  {
    type: "School",
    name: "Stevens-Henager College-Boise",
  },
  {
    type: "School",
    name: "The Salon Professional Academy-Cincinnati",
  },
  {
    type: "School",
    name: "Penrose Academy",
  },
  {
    type: "School",
    name: "Virginia College-Richmond",
  },
  {
    type: "School",
    name: "Skin Institute",
  },
  {
    type: "School",
    name: "Northwest Institute of Literary Arts",
  },
  {
    type: "School",
    name: "Body Therapy Institute",
  },
  {
    type: "School",
    name: "Beauty Academy of South Florida",
  },
  {
    type: "School",
    name: "Ogle School Hair Skin Nails-North Dallas",
  },
  {
    type: "School",
    name: "The Salon Professional Academy-North Little Rock",
  },
  {
    type: "School",
    name: "The Salon Professional Academy-St Charles",
  },
  {
    type: "School",
    name: "American Institute",
  },
  {
    type: "School",
    name: "Mildred Elley-New York Campus",
  },
  {
    type: "School",
    name: "National Paralegal College",
  },
  {
    type: "School",
    name: "Carolina College of Biblical Studies",
  },
  {
    type: "School",
    name: "Star Career Academy-Audubon",
  },
  {
    type: "School",
    name: "St Luke University",
  },
  {
    type: "School",
    name: "Northeast Technology Center-System Office",
  },
  {
    type: "School",
    name: "Allstate Hairstyling & Barber College",
  },
  {
    type: "School",
    name: "Oxford Graduate School",
  },
  {
    type: "School",
    name: "Jung Tao School of Classical Chinese Medicine",
  },
  {
    type: "School",
    name: "New York Film Academy",
  },
  {
    type: "School",
    name: "The Collective School Of Music",
  },
  {
    type: "School",
    name: "International College of Cosmetology",
  },
  {
    type: "School",
    name: "GP Institute of Cosmetology",
  },
  {
    type: "School",
    name: "Bella Capelli Academy",
  },
  {
    type: "School",
    name: "Institute of Medical Careers",
  },
  {
    type: "School",
    name: "Toni & Guy Hairdressing Academy-Modesto",
  },
  {
    type: "School",
    name: "Georgia Christian University",
  },
  {
    type: "School",
    name: "Flagler Technical Institute",
  },
  {
    type: "School",
    name: "Galaxy Medical College",
  },
  {
    type: "School",
    name: "American Medical Sciences Center",
  },
  {
    type: "School",
    name: "Mandalyn Academy",
  },
  {
    type: "School",
    name: "Jose Maria Vargas University",
  },
  {
    type: "School",
    name: "Laird Institute of Spa Therapy",
  },
  {
    type: "School",
    name: "D A Dorsey Educational Center",
  },
  {
    type: "School",
    name: "Keweenaw Bay Ojibwa Community College",
  },
  {
    type: "School",
    name: "Meridian Institute of Surgical Assisting",
  },
  {
    type: "School",
    name: "Mauna Loa Helicopters",
  },
  {
    type: "School",
    name: "Acaydia School of Aesthetics",
  },
  {
    type: "School",
    name: "Manhattan Institute",
  },
  {
    type: "School",
    name: "Cosmetic Arts Institute",
  },
  {
    type: "School",
    name: "Holistic Massage Training Institute",
  },
  {
    type: "School",
    name: "Diamonds Cosmetology College",
  },
  {
    type: "School",
    name: "Tramy Beauty School",
  },
  {
    type: "School",
    name: "Scholars Cosmetology University",
  },
  {
    type: "School",
    name: "Northern Virginia School of Therapeutic Massage",
  },
  {
    type: "School",
    name: "Paul Mitchell the School-St Louis",
  },
  {
    type: "School",
    name: "Advanced Training Institute",
  },
  {
    type: "School",
    name: "Cosmopolitan Beauty and Tech School",
  },
  {
    type: "School",
    name: "The Salon Professional Academy-Elgin",
  },
  {
    type: "School",
    name: "CCIC Beauty College",
  },
  {
    type: "School",
    name: "Salon 496 Barber Academy",
  },
  {
    type: "School",
    name: "Shepherds Theological Seminary",
  },
  {
    type: "School",
    name: "Taylor Andrews Academy of Hair Design-West Jordan",
  },
  {
    type: "School",
    name: "Santa Ana Beauty Academy",
  },
  {
    type: "School",
    name: "Grace College of Divinity",
  },
  {
    type: "School",
    name: "Cosmetology College of Franklin County",
  },
  {
    type: "School",
    name: "Rochester School of Hair Design",
  },
  {
    type: "School",
    name: "Aveda Institute-New Mexico",
  },
  {
    type: "School",
    name: "Nashville Barber and Style Academy",
  },
  {
    type: "School",
    name: "American Trade School",
  },
  {
    type: "School",
    name: "Estelle Skin Care and Spa Institute",
  },
  {
    type: "School",
    name: "Paul Mitchell the School-Honolulu",
  },
  {
    type: "School",
    name: "Elite Cosmetology School",
  },
  {
    type: "School",
    name: "Academy of Aesthetic Arts",
  },
  {
    type: "School",
    name: "American Technical Institute",
  },
  {
    type: "School",
    name: "Twin Rivers Adult School",
  },
  {
    type: "School",
    name: "Bergin University of Canine Studies",
  },
  {
    type: "School",
    name: "Aveda Institute-Boise",
  },
  {
    type: "School",
    name: "Progressive Training Centers",
  },
  {
    type: "School",
    name: "American Health Institute",
  },
  {
    type: "School",
    name: "Pima Medical Institute-South Denver",
  },
  {
    type: "School",
    name: "Real Barbers College",
  },
  {
    type: "School",
    name: "Capilo School of Hair Design",
  },
  {
    type: "School",
    name: "Pure Aesthetics",
  },
  {
    type: "School",
    name: "Lindsey Institute of Cosmetology",
  },
  {
    type: "School",
    name: "The Salon Professional Academy-Iowa City",
  },
  {
    type: "School",
    name: "Buckner Barber School",
  },
  {
    type: "School",
    name: "Simmons College of Kentucky",
  },
  {
    type: "School",
    name: "Atelier Esthetique Institute of Esthetics",
  },
  {
    type: "School",
    name: "Ashdown College of Health Sciences",
  },
  {
    type: "School",
    name: "Advanced College of Cosmetology",
  },
  {
    type: "School",
    name: "North American University",
  },
  {
    type: "School",
    name: "Entourage Institute of Beauty and Esthetics",
  },
  {
    type: "School",
    name: "United Beauty College",
  },
  {
    type: "School",
    name: "Cinta Aveda Institute",
  },
  {
    type: "School",
    name: "Long Island Nail & Skin Care Institute",
  },
  {
    type: "School",
    name: "Mesivta Keser Torah",
  },
  {
    type: "School",
    name: "Southern California Health Institute",
  },
  {
    type: "School",
    name: "Best Care Training Institute",
  },
  {
    type: "School",
    name: "First Class Cosmetology School",
  },
  {
    type: "School",
    name: "Millennia Atlantic University",
  },
  {
    type: "School",
    name: "Abcott Institute",
  },
  {
    type: "School",
    name: "The Salon Professional Academy-Kenosha",
  },
  {
    type: "School",
    name: "Paul Mitchell the School-Indianapolis",
  },
  {
    type: "School",
    name: "Taylor Andrews Academy-St George",
  },
  {
    type: "School",
    name: "Seymour Beauty Academy",
  },
  {
    type: "School",
    name: "Protege Academy",
  },
  {
    type: "School",
    name: "Colorado Academy of Veterinary Technology",
  },
  {
    type: "School",
    name: "Northeast Technical Institute",
  },
  {
    type: "School",
    name: "Salinas Beauty College Inc",
  },
  {
    type: "School",
    name: "Academy for Salon Professionals",
  },
  {
    type: "School",
    name: "Elite School of Cosmetology",
  },
  {
    type: "School",
    name: "Paul Mitchell the School-Las Vegas",
  },
  {
    type: "School",
    name: "The Salon Professional Academy-Kokomo",
  },
  {
    type: "School",
    name: "Hair Academy",
  },
  {
    type: "School",
    name: "Florida Academy",
  },
  {
    type: "School",
    name: "Institute for Doctoral Studies in the Visual Arts",
  },
  {
    type: "School",
    name: "State Career College",
  },
  {
    type: "School",
    name: "Academy for Salon Professionals",
  },
  {
    type: "School",
    name: "Paul Mitchell The School-Spokane",
  },
  {
    type: "School",
    name: "DeVry University's Keller Graduate School of Management-Maryland",
  },
  {
    type: "School",
    name: "DeVry University's Keller Graduate School of Management-North Carolina",
  },
  {
    type: "School",
    name: "DeVry University's Keller Graduate School of Management-Florida",
  },
  {
    type: "School",
    name: "DeVry College of New York's Keller Graduate School of Management",
  },
  {
    type: "School",
    name: "DeVry University's Keller Graduate School of Management-Indiana",
  },
  {
    type: "School",
    name: "DeVry University's Keller Graduate School of Management-Nevada",
  },
  {
    type: "School",
    name: "DeVry University's Keller Graduate School of Management-Colorado",
  },
  {
    type: "School",
    name: "DeVry University's Keller Graduate School of Management-Minnesota",
  },
  {
    type: "School",
    name: "DeVry University's Keller Graduate School of Management-Oklahoma",
  },
  {
    type: "School",
    name: "DeVry University's Keller Graduate School of Management-Utah",
  },
  {
    type: "School",
    name: "DeVry University's Keller Graduate School of Management-Tennessee",
  },
  {
    type: "School",
    name: "DeVry University's Keller Graduate School of Management-Michigan",
  },
  {
    type: "School",
    name: "DeVry University's Keller Graduate School of Management-Kentucky",
  },
  {
    type: "School",
    name: "DeVry University's Keller Graduate School of Management-New Jersey",
  },
  {
    type: "School",
    name: "DeVry University's Keller Graduate School of Management-Oregon",
  },
  {
    type: "School",
    name: "Lincoln College of New England-Hartford",
  },
  {
    type: "School",
    name: "Lincoln College of Technology-Columbus",
  },
  {
    type: "School",
    name: "Lincoln College of Technology-Cleveland",
  },
  {
    type: "School",
    name: "Northwestern Institute of Health and Technology",
  },
  {
    type: "School",
    name: "J Renee Career Facilitation",
  },
  {
    type: "School",
    name: "National Personal Training Institute of Colorado",
  },
  {
    type: "School",
    name: "National Personal Training Institute of Colorado",
  },
  {
    type: "School",
    name: "International College of Beauty Arts & Sciences",
  },
  {
    type: "School",
    name: "The Salon Professional Academy-Shorewood",
  },
  {
    type: "School",
    name: "John Paul the Great Catholic University",
  },
  {
    type: "School",
    name: "ITT Technical Institute-Durham",
  },
  {
    type: "School",
    name: "ITT Technical Institute-Hanover",
  },
  {
    type: "School",
    name: "University of Phoenix-Jackson Campus",
  },
  {
    type: "School",
    name: "University of Phoenix-Delaware Campus",
  },
  {
    type: "School",
    name: "Everest College-Milwaukee",
  },
  {
    type: "School",
    name: "Independence University",
  },
  {
    type: "School",
    name: "National American University-Tulsa",
  },
  {
    type: "School",
    name: "National American University-Centennial",
  },
  {
    type: "School",
    name: "National American University-Lee's Summit",
  },
  {
    type: "School",
    name: "National American University-Colorado Springs South",
  },
  {
    type: "School",
    name: "Globe University-Appleton",
  },
  {
    type: "School",
    name: "Salter College-Chicopee",
  },
  {
    type: "School",
    name: "Chamberlain College of Nursing-Missouri",
  },
  {
    type: "School",
    name: "Chamberlain College of Nursing-Texas",
  },
  {
    type: "School",
    name: "ITT Technical Institute-Oakland",
  },
  {
    type: "School",
    name: "Continental School of Beauty Culture-Mattydale",
  },
  {
    type: "School",
    name: "Everest College-Bedford Park",
  },
  {
    type: "School",
    name: "Fortis College-Akron",
  },
  {
    type: "School",
    name: "Carrington College California-Pomona",
  },
  {
    type: "School",
    name: "Regency Beauty Institute-Copperwood",
  },
  {
    type: "School",
    name: "Regency Beauty Institute-Little Rock",
  },
  {
    type: "School",
    name: "Regency Beauty Institute-Baltimore Golden Ring",
  },
  {
    type: "School",
    name: "Regency Beauty Institute-El Paso",
  },
  {
    type: "School",
    name: "Regency Beauty Institute-Jacksonville Regency",
  },
  {
    type: "School",
    name: "Regency Beauty Institute-Knoxville",
  },
  {
    type: "School",
    name: "Regency Beauty Institute-Fayetteville",
  },
  {
    type: "School",
    name: "Regency Beauty Institute-Lansing",
  },
  {
    type: "School",
    name: "Regency Beauty Institute-Jacksonville Orange Park",
  },
  {
    type: "School",
    name: "Regency Beauty Institute-Crystal Lake",
  },
  {
    type: "School",
    name: "Regency Beauty Institute-Manassas",
  },
  {
    type: "School",
    name: "Regency Beauty Institute-Castleton",
  },
  {
    type: "School",
    name: "Regency Beauty Institute-North Nashville",
  },
  {
    type: "School",
    name: "Regency Beauty Institute-Round Rock",
  },
  {
    type: "School",
    name: "Regency Beauty Institute-Merrillville",
  },
  {
    type: "School",
    name: "Regency Beauty Institute-Roanoke",
  },
  {
    type: "School",
    name: "Regency Beauty Institute-Toledo",
  },
  {
    type: "School",
    name: "Regency Beauty Institute-SW Houston",
  },
  {
    type: "School",
    name: "Everest College-Atlanta West",
  },
  {
    type: "School",
    name: "Broadview University-Boise",
  },
  {
    type: "School",
    name: "Advanced Institute of Hair Design-Madison",
  },
  {
    type: "School",
    name: "Paul Mitchell The School-Columbia",
  },
  {
    type: "School",
    name: "Paul Mitchell The School-Charleston",
  },
  {
    type: "School",
    name: "Everest College-Melrose Park",
  },
  {
    type: "School",
    name: "Kenneth Shuler School of Cosmetology-Greenville",
  },
  {
    type: "School",
    name: "South University-Accelerated Graduate Programs",
  },
  {
    type: "School",
    name: "South University-The Art Institute of Fort Worth",
  },
  {
    type: "School",
    name: "South University-Novi",
  },
  {
    type: "School",
    name: "Northwest College-Eugene",
  },
  {
    type: "School",
    name: "Northwest College-Tualatin",
  },
  {
    type: "School",
    name: "Harrison College-Morrisville",
  },
  {
    type: "School",
    name: "Trend Setters' Academy of Beauty Culture-Louisville",
  },
  {
    type: "School",
    name: "National University College-Caguas",
  },
  {
    type: "School",
    name: "Beauty Schools of America",
  },
  {
    type: "School",
    name: "CET-Soledad",
  },
  {
    type: "School",
    name: "MarJon School of Beauty ltd-Lockport",
  },
  {
    type: "School",
    name: "Houston Training Schools-Southwest",
  },
  {
    type: "School",
    name: "Vanguard College of Cosmetology-Metairie",
  },
  {
    type: "School",
    name: "New England Tractor Trailer Training School of Massachusetts",
  },
  {
    type: "School",
    name: "Heritage College-Columbus",
  },
  {
    type: "School",
    name: "Utah College of Massage Therapy-Vegas",
  },
  {
    type: "School",
    name: "Utah College of Massage Therapy-Tempe",
  },
  {
    type: "School",
    name: "Utah College of Massage Therapy-Phoenix",
  },
  {
    type: "School",
    name: "Utah College of Massage Therapy-Westminster",
  },
  {
    type: "School",
    name: "Utah College of Massage Therapy-Aurora",
  },
  {
    type: "School",
    name: "Sanford-Brown College-Portland",
  },
  {
    type: "School",
    name: "Tint School of Makeup and Cosmetology-Grand Prairie",
  },
  {
    type: "School",
    name: "Dewey University-Yabucoa",
  },
  {
    type: "School",
    name: "Dewey University-Hatillo",
  },
  {
    type: "School",
    name: "Dewey University-Manati",
  },
  {
    type: "School",
    name: "Sanford-Brown College-Skokie",
  },
  {
    type: "School",
    name: "Baltimore School of Massage-York",
  },
  {
    type: "School",
    name: "Allen School-Phoenix",
  },
  {
    type: "School",
    name: "Oklahoma Technical College",
  },
  {
    type: "School",
    name: "Midwest Technical Institute-Moline",
  },
  {
    type: "School",
    name: "Star Career Academy-Clifton",
  },
  {
    type: "School",
    name: "Unitech Training Academy-Alexandria",
  },
  {
    type: "School",
    name: "Unitech Training Academy-Lake Charles",
  },
  {
    type: "School",
    name: "Aveda Institute-Los Angeles",
  },
  {
    type: "School",
    name: "Career Quest Learning Center-Kalamazoo",
  },
  {
    type: "School",
    name: "Buchanan Beauty College",
  },
  {
    type: "School",
    name: "WellSpring School of Allied Health-Lawrence",
  },
  {
    type: "School",
    name: "The Art Institute of Wisconsin",
  },
  {
    type: "School",
    name: "Brown Mackie College-Birmingham",
  },
  {
    type: "School",
    name: "Regina's College of Beauty",
  },
  {
    type: "School",
    name: "New York Film Academy",
  },
  {
    type: "School",
    name: "Manuel and Theresa's School of Hair Design-Victoria",
  },
  {
    type: "School",
    name: "Coast Career Institute",
  },
  {
    type: "School",
    name: "Tricoci University of Beauty Culture-Chicago NE",
  },
  {
    type: "School",
    name: "ITT Technical Institute-Bradenton",
  },
  {
    type: "School",
    name: "ITT Technical Institute-Phoenix West",
  },
  {
    type: "School",
    name: "ITT Technical Institute-Brooklyn Center",
  },
  {
    type: "School",
    name: "ITT Technical Institute-Orlando",
  },
  {
    type: "School",
    name: "ITT Technical Institute-Waco",
  },
  {
    type: "School",
    name: "ITT Technical Institute-Myrtle Beach",
  },
  {
    type: "School",
    name: "University of Phoenix-Knoxville Campus",
  },
  {
    type: "School",
    name: "Azusa Pacific Online University",
  },
  {
    type: "School",
    name: "Minneapolis Media Institute",
  },
  {
    type: "School",
    name: "College America-Phoenix",
  },
  {
    type: "School",
    name: "CollegeAmerica-Stevens Henager College",
  },
  {
    type: "School",
    name: "Avalon School of Cosmetology-Phoenix",
  },
  {
    type: "School",
    name: "Avalon School of Cosmetology-Layton",
  },
  {
    type: "School",
    name: "Hair Design Institute",
  },
  {
    type: "School",
    name: "Hair Design Institute",
  },
  {
    type: "School",
    name: "University of Phoenix-Baton Rouge Campus",
  },
  {
    type: "School",
    name: "University of Phoenix-Lafayette Campus",
  },
  {
    type: "School",
    name: "University of Phoenix-Shreveport-Bossier Campus",
  },
  {
    type: "School",
    name: "All-State Career School-Allied Health Campus",
  },
  {
    type: "School",
    name: "Aveda Institute-Des Moines",
  },
  {
    type: "School",
    name: "Fortis Institute-Lawrenceville",
  },
  {
    type: "School",
    name: "Relay Graduate School of Education",
  },
  {
    type: "School",
    name: "Brown Mackie College-Oklahoma City",
  },
  {
    type: "School",
    name: "Sanford-Brown College-Austin",
  },
  {
    type: "School",
    name: "Miller-Motte Technical College-Macon",
  },
  {
    type: "School",
    name: "Miller-Motte Technical College-Gulfport",
  },
  {
    type: "School",
    name: "International Academy of Medical Reflexology",
  },
  {
    type: "School",
    name: "International Culinary Center-California",
  },
  {
    type: "School",
    name: "South University�Savannah Online",
  },
  {
    type: "School",
    name: "Fortis College-Montgomery",
  },
  {
    type: "School",
    name: "San Joaquin Valley College-Temecula",
  },
  {
    type: "School",
    name: "Rio Grande Bible Institute",
  },
  {
    type: "School",
    name: "Miller-Motte Technical College-Roanoke",
  },
  {
    type: "School",
    name: "Whitworth University-Adult Degree Programs",
  },
  {
    type: "School",
    name: "The Santa Barbara and Ventura Colleges of Law�Santa Barbara",
  },
  {
    type: "School",
    name: "The University of America",
  },
  {
    type: "School",
    name: "Kaplan College-Chesapeake",
  },
  {
    type: "School",
    name: "American Broadcasting School-Online Program",
  },
  {
    type: "School",
    name: "Springfield College-School of Human Services",
  },
  {
    type: "School",
    name: "Marian Health Careers Center-Van Nuys Campus",
  },
  {
    type: "School",
    name: "Brown College-Brooklyn Center",
  },
  {
    type: "School",
    name: "Milan Institute-Las Vegas",
  },
  {
    type: "School",
    name: "Williamson Free School of Mechanical Trades",
  },
  {
    type: "School",
    name: "Virginia College-Savannah",
  },
  {
    type: "School",
    name: "Virginia College-Columbus",
  },
  {
    type: "School",
    name: "Donna's Academy of Hair Design",
  },
  {
    type: "School",
    name: "New York School of Esthetics & Day Spa",
  },
  {
    type: "School",
    name: "Panache Academy of Beauty",
  },
  {
    type: "School",
    name: "Finger Lakes Health College of Nursing",
  },
  {
    type: "School",
    name: "ASI Career Institute",
  },
  {
    type: "School",
    name: "Chicago School for Piano Technology",
  },
  {
    type: "School",
    name: "Midwest Technical Institute-Ridgeland",
  },
  {
    type: "School",
    name: "Christine Valmy International School of Esthetics & Cosmetology",
  },
  {
    type: "School",
    name: "City College-Hollywood",
  },
  {
    type: "School",
    name: "Paul Mitchell the School-Arlington",
  },
  {
    type: "School",
    name: "Rocky Mountain University of Health Professions",
  },
  {
    type: "School",
    name: "MediaTech Institute-Oceanside",
  },
  {
    type: "School",
    name: "Christie's Education",
  },
  {
    type: "School",
    name: "Prince Institute-Great Lakes",
  },
  {
    type: "School",
    name: "Palmetto Beauty School",
  },
  {
    type: "School",
    name: "Paul Mitchell the School-Fort Myers",
  },
  {
    type: "School",
    name: "Paul Mitchell The School-Raleigh",
  },
  {
    type: "School",
    name: "Stella and Charles Guttman Community College",
  },
  {
    type: "School",
    name: "Lil Lou's Barber College",
  },
  {
    type: "School",
    name: "Bryan University",
  },
  {
    type: "School",
    name: "Virginia College-Tulsa",
  },
  {
    type: "School",
    name: "Criswell College",
  },
  {
    type: "School",
    name: "Virginia College-Knoxville",
  },
  {
    type: "School",
    name: "Columbia Academy of Cosmetology",
  },
  {
    type: "School",
    name: "Academy of Couture Art",
  },
  {
    type: "School",
    name: "Global Institute",
  },
  {
    type: "School",
    name: "Shear Learning Academy of Cosmetology",
  },
  {
    type: "School",
    name: "University of Cosmetology Arts & Sciences-La Joya",
  },
  {
    type: "School",
    name: "Preferred College of Nursing-Van Nuys",
  },
  {
    type: "School",
    name: "Bryant & Stratton College-Akron",
  },
  {
    type: "School",
    name: "Annenberg School of Nursing",
  },
  {
    type: "School",
    name: "American Medical Academy",
  },
  {
    type: "School",
    name: "Tooele Applied Technology College",
  },
  {
    type: "School",
    name: "Chamberlain College of Nursing-Georgia",
  },
  {
    type: "School",
    name: "Chamberlain College of Nursing-Indiana",
  },
  {
    type: "School",
    name: "Carrington College-Mesquite",
  },
  {
    type: "School",
    name: "Universidad Internacional Iberoamericana",
  },
  {
    type: "School",
    name: "Sessions College for Professional Design",
  },
  {
    type: "School",
    name: "Southeastern Technical Careers Institute Inc",
  },
  {
    type: "School",
    name: "Virginia College-Shreveport Bossier City",
  },
  {
    type: "School",
    name: "Strayer University-Wisconsin",
  },
  {
    type: "School",
    name: "Strayer University-Indiana",
  },
  {
    type: "School",
    name: "Tulsa Welding School-Jacksonville",
  },
  {
    type: "School",
    name: "Herzing University",
  },
  {
    type: "School",
    name: "Regency Beauty Institute-Hoover",
  },
  {
    type: "School",
    name: "Regency Beauty Institute-San Antonio",
  },
  {
    type: "School",
    name: "Regency Beauty Institute-Carrollwood",
  },
  {
    type: "School",
    name: "Regency Beauty Institute-Clearwater",
  },
  {
    type: "School",
    name: "Regency Beauty Institute-Newport News",
  },
  {
    type: "School",
    name: "Regency Beauty Institute-Lewisville",
  },
  {
    type: "School",
    name: "Regency Beauty Institute-Dallas",
  },
  {
    type: "School",
    name: "Regency Beauty Institute-Plano",
  },
  {
    type: "School",
    name: "Empire Beauty School-Virginia Beach",
  },
  {
    type: "School",
    name: "Sanford-Brown College-Columbus",
  },
  {
    type: "School",
    name: "Ross Medical Education Center-Morgantown",
  },
  {
    type: "School",
    name: "Paul Mitchell the School-Ogden",
  },
  {
    type: "School",
    name: "CollegeAmerica-Colorado Springs South",
  },
  {
    type: "School",
    name: "Everest Institute-Bensalem",
  },
  {
    type: "School",
    name: "Golf Academy of America-Farmers Branch",
  },
  {
    type: "School",
    name: "Utah College of Massage Therapy-Dallas",
  },
  {
    type: "School",
    name: "Miami Ad School-New York",
  },
  {
    type: "School",
    name: "Lacy Cosmetology School-West Ashley",
  },
  {
    type: "School",
    name: "Dade Medical College-West Palm Beach",
  },
  {
    type: "School",
    name: "Dade Medical College-Jacksonville",
  },
  {
    type: "School",
    name: "WestMed College",
  },
  {
    type: "School",
    name: "Larry's Barber College",
  },
  {
    type: "School",
    name: "Futura Career Institute",
  },
  {
    type: "School",
    name: "Access Careers",
  },
  {
    type: "School",
    name: "Flair Beauty College",
  },
  {
    type: "School",
    name: "Eternity Cosmetology School",
  },
  {
    type: "School",
    name: "Tri-State Institute of Hair Design",
  },
  {
    type: "School",
    name: "Vogue College of Cosmetology-San Antonio Fredericksburg",
  },
  {
    type: "School",
    name: "Vogue College of Cosmetology",
  },
  {
    type: "School",
    name: "Tomorrow's Image Barber Academy of Virginia",
  },
  {
    type: "School",
    name: "Excel Learning Center",
  },
  {
    type: "School",
    name: "College of International Esthetics Inc",
  },
  {
    type: "School",
    name: "Medical Allied Career Center",
  },
  {
    type: "School",
    name: "Bais HaMedrash and Mesivta of Baltimore",
  },
  {
    type: "School",
    name: "Long Island Barber Institute",
  },
  {
    type: "School",
    name: "Toni & Guy Hairdressing Academy-Toledo",
  },
  {
    type: "School",
    name: "American Institute of Medical Sonography",
  },
  {
    type: "School",
    name: "Destination Academy for Spa and Salon Professionals",
  },
  {
    type: "School",
    name: "Classic Cooking Academy",
  },
  {
    type: "School",
    name: "School of Court Reporting",
  },
  {
    type: "School",
    name: "Curve Metric School of Hair Design",
  },
  {
    type: "School",
    name: "Athena Career Academy",
  },
  {
    type: "School",
    name: "Yeshiva Gedolah Zichron Leyma",
  },
  {
    type: "School",
    name: "The Barber School",
  },
  {
    type: "School",
    name: "Be'er Yaakov Talmudic Seminary",
  },
  {
    type: "School",
    name: "RGV Careers",
  },
  {
    type: "School",
    name: "Alexander Academy",
  },
  {
    type: "School",
    name: "LeMelange Academy of Hair",
  },
  {
    type: "School",
    name: "Aveda Institute-Tucson",
  },
  {
    type: "School",
    name: "Velvet Touch Academy of Cosmetology",
  },
  {
    type: "School",
    name: "Ambiance Beauty & Barber Academy Inc",
  },
  {
    type: "School",
    name: "Diamond Beauty College",
  },
  {
    type: "School",
    name: "NCP College of Nursing-South San Francisco",
  },
  {
    type: "School",
    name: "University Academy of Hair Design",
  },
  {
    type: "School",
    name: "Florida Institute of Recording Sound and Technology",
  },
  {
    type: "School",
    name: "New Dimensions Beauty Academy Inc",
  },
  {
    type: "School",
    name: "Jolei's Hair Institute",
  },
  {
    type: "School",
    name: "Paul Mitchell the School-Reno",
  },
  {
    type: "School",
    name: "Boise Barber College",
  },
  {
    type: "School",
    name: "Austin Kade Academy",
  },
  {
    type: "School",
    name: "Aesthetic Science Institute",
  },
  {
    type: "School",
    name: "Elaine Sterling Institute",
  },
  {
    type: "School",
    name: "Wade Gordon Hairdressing Academy",
  },
  {
    type: "School",
    name: "Mitsu Sato Hair Academy",
  },
  {
    type: "School",
    name: "SAE Institute of Technology-Los Angeles",
  },
  {
    type: "School",
    name: "Florida Vocational Institute",
  },
  {
    type: "School",
    name: "SAE Institute of Technology-Atlanta",
  },
  {
    type: "School",
    name: "Academy di Firenze",
  },
  {
    type: "School",
    name: "SAE Institute of Technology-Miami",
  },
  {
    type: "School",
    name: "Colorado State University-Global Campus",
  },
  {
    type: "School",
    name: "Paul Mitchell the School-Overland Park",
  },
  {
    type: "School",
    name: "Medspa Academies",
  },
  {
    type: "School",
    name: "Brighton Institute of Cosmetology",
  },
  {
    type: "School",
    name: "Strayer University-Minnesota",
  },
  {
    type: "School",
    name: "Strayer University-Illinois",
  },
  {
    type: "School",
    name: "West Coast University-Dallas",
  },
  {
    type: "School",
    name: "Stevens-Henager College-St George",
  },
  {
    type: "School",
    name: "National American University-Bellevue",
  },
  {
    type: "School",
    name: "National American University-Burnsville",
  },
  {
    type: "School",
    name: "National American University-Mesquite",
  },
  {
    type: "School",
    name: "Empire Beauty School-Savannah",
  },
  {
    type: "School",
    name: "Ogle School Hair Skin Nails-Denton",
  },
  {
    type: "School",
    name: "Empire Beauty School-West Greensboro",
  },
  {
    type: "School",
    name: "California College San Diego",
  },
  {
    type: "School",
    name: "California College San Diego",
  },
  {
    type: "School",
    name: "Old Town Barber College-Topeka",
  },
  {
    type: "School",
    name: "Jolie Hair and Beauty Academy-Northfield",
  },
  {
    type: "School",
    name: "Hays Academy of Hair Design",
  },
  {
    type: "School",
    name: "Bellus Academy",
  },
  {
    type: "School",
    name: "Cortiva Institute-Seattle",
  },
  {
    type: "School",
    name: "MotoRing Technical Training Institute",
  },
  {
    type: "School",
    name: "Leon Studio One School of Hair Design & Career Training Center",
  },
  {
    type: "School",
    name: "Columbia College",
  },
  {
    type: "School",
    name: "NCP College of Nursing-Hayward",
  },
  {
    type: "School",
    name: "ITT Technical Institute-Philadelphia",
  },
  {
    type: "School",
    name: "ITT Technical Institute-Marlton",
  },
  {
    type: "School",
    name: "ITT Technical Institute-Germantown",
  },
  {
    type: "School",
    name: "ITT Technical Institute-Grand Rapids",
  },
  {
    type: "School",
    name: "ITT Technical Institute-Southfield",
  },
  {
    type: "School",
    name: "ITT Technical Institute-West Palm Beach",
  },
  {
    type: "School",
    name: "ITT Technical Institute-Salem",
  },
  {
    type: "School",
    name: "ITT Technical Institute-Deerfield Beach",
  },
  {
    type: "School",
    name: "ITT Technical Institute-Indianapolis East",
  },
  {
    type: "School",
    name: "ITT Technical Institute-Douglasville",
  },
  {
    type: "School",
    name: "ITT Technical Institute-Overland Park",
  },
  {
    type: "School",
    name: "ITT Technical Institute-Springfield",
  },
  {
    type: "School",
    name: "ITT Technical Institute-West Chester",
  },
  {
    type: "School",
    name: "University of Phoenix-McAllen Campus",
  },
  {
    type: "School",
    name: "Pennsylvania State University-World Campus",
  },
  {
    type: "School",
    name: "Medical Career Institute",
  },
  {
    type: "School",
    name: "Advance Beauty Techs Academy",
  },
  {
    type: "School",
    name: "Longs Peak Academy",
  },
  {
    type: "School",
    name: "Brand College",
  },
  {
    type: "School",
    name: "Hinton Barber College",
  },
  {
    type: "School",
    name: "Advanced Career Institute",
  },
  {
    type: "School",
    name: "Digital Film Academy",
  },
  {
    type: "School",
    name: "MyComputerCareer.com-Raleigh",
  },
  {
    type: "School",
    name: "Kaplan University-Augusta Campus",
  },
  {
    type: "School",
    name: "South University-Austin",
  },
  {
    type: "School",
    name: "South University-Cleveland",
  },
  {
    type: "School",
    name: "Bryant & Stratton College-Online",
  },
  {
    type: "School",
    name: "Virginia College-Florence",
  },
  {
    type: "School",
    name: "Ogle School Hair Skin Nails-San Antonio",
  },
  {
    type: "School",
    name: "Elizabethtown College School of Continuing and Professional Studies",
  },
  {
    type: "School",
    name: "Fortis College-Cutler Bay",
  },
  {
    type: "School",
    name: "Anamarc College-El Paso East",
  },
  {
    type: "School",
    name: "Ideal Beauty Academy",
  },
  {
    type: "School",
    name: "Minnesota School of Cosmetology-Plymouth Campus",
  },
  {
    type: "School",
    name: "Mercyhurst University-North East Campus",
  },
  {
    type: "School",
    name: "Warner Pacific College Adult Degree Program",
  },
  {
    type: "School",
    name: "Platt College-Riverside",
  },
  {
    type: "School",
    name: "Savannah Law School",
  },
  {
    type: "School",
    name: "Empire Beauty School-Glen Burnie",
  },
  {
    type: "School",
    name: "Everest College-Woodbridge",
  },
  {
    type: "School",
    name: "Everest College-Kansas City",
  },
  {
    type: "School",
    name: "International School of Cosmetology-Toni & Guy Hairdressing Academy",
  },
  {
    type: "School",
    name: "InterCoast Career Institute-Salem",
  },
  {
    type: "School",
    name: "International Sports Sciences Association",
  },
  {
    type: "School",
    name: "Abraham Lincoln University",
  },
  {
    type: "School",
    name: "National College-Canton",
  },
  {
    type: "School",
    name: "Shiloh University",
  },
  {
    type: "School",
    name: "Court Reporting Institute of Arlington",
  },
  {
    type: "School",
    name: "Vatterott College-Fairview Heights",
  },
  {
    type: "School",
    name: "Ross Medical Education Center-Dayton",
  },
  {
    type: "School",
    name: "Ross Medical Education Center-Ontario",
  },
  {
    type: "School",
    name: "Ross Medical Education Center-Bowling Green",
  },
  {
    type: "School",
    name: "Florida Institute of Technology-Online",
  },
  {
    type: "School",
    name: "Rasmussen College-Kansas",
  },
  {
    type: "School",
    name: "NewCourtland Education Center",
  },
  {
    type: "School",
    name: "Columbia Institute",
  },
  {
    type: "School",
    name: "Wadsworth Center-NY State Dept of Health",
  },
  {
    type: "School",
    name: "EMS Training Institute",
  },
  {
    type: "School",
    name: "School of Missionary Aviation Technology",
  },
  {
    type: "School",
    name: "Ace Cosmetology and Barber Training Center",
  },
  {
    type: "School",
    name: "Salt Lake Baptist College",
  },
  {
    type: "School",
    name: "Manthano Christian College",
  },
  {
    type: "School",
    name: "California Miramar University",
  },
  {
    type: "School",
    name: "Rocky Vista University",
  },
  {
    type: "School",
    name: "Virginia College-Greensboro",
  },
  {
    type: "School",
    name: "The College of Health Care Professions-Dallas",
  },
  {
    type: "School",
    name: "The College of Health Care Professions-Fort Worth",
  },
  {
    type: "School",
    name: "Shear Finesse Hairstyling Academy",
  },
  {
    type: "School",
    name: "Redstone College-Denver East",
  },
  {
    type: "School",
    name: "Academy of Salon Professionals",
  },
  {
    type: "School",
    name: "CyberTex Institute of Technology",
  },
  {
    type: "School",
    name: "UEI College-Santa Cruz",
  },
  {
    type: "School",
    name: "New England Tractor Trailer Training School of CT-Bridgeport",
  },
  {
    type: "School",
    name: "Gemini School of Visual Arts & Communication",
  },
  {
    type: "School",
    name: "Paul Mitchell the School-Woodbridge",
  },
  {
    type: "School",
    name: "College of the Muscogee Nation",
  },
  {
    type: "School",
    name: "The Salon Professional Academy-Huntsville",
  },
  {
    type: "School",
    name: "Midwives College of Utah",
  },
  {
    type: "School",
    name: "Aspen Beauty Academy of Laurel",
  },
  {
    type: "School",
    name: "M T Training Center",
  },
  {
    type: "School",
    name: "Berks Career & Technology Center",
  },
  {
    type: "School",
    name: "Future Generations Graduate School",
  },
  {
    type: "School",
    name: "Carthage R9 School District-Carthage Technical Center",
  },
  {
    type: "School",
    name: "Grace Mission University",
  },
  {
    type: "School",
    name: "World A Cuts Barber Institute",
  },
  {
    type: "School",
    name: "Photographic Center Northwest",
  },
  {
    type: "School",
    name: "PCCTI IT and Healthcare",
  },
  {
    type: "School",
    name: "Sotheby's Institute of Art-NY",
  },
  {
    type: "School",
    name: "Top Nails & Hair Beauty School",
  },
  {
    type: "School",
    name: "Radians College",
  },
  {
    type: "School",
    name: "Santa Ana Beauty College",
  },
  {
    type: "School",
    name: "Shepherds College",
  },
  {
    type: "School",
    name: "Woodruff Medical Training and Testing",
  },
  {
    type: "School",
    name: "Helms College",
  },
  {
    type: "School",
    name: "American Academy of Personal Training",
  },
  {
    type: "School",
    name: "Technology Center",
  },
  {
    type: "School",
    name: "Ukiah Adult School",
  },
  {
    type: "School",
    name: "Riverside County Office of Education",
  },
  {
    type: "School",
    name: "Institute of Health Sciences",
  },
  {
    type: "School",
    name: "Mid-America Baptist Theological Seminary",
  },
  {
    type: "School",
    name: "Mid-South Christian College",
  },
  {
    type: "School",
    name: "Advanced Computing Institute",
  },
  {
    type: "School",
    name: "New York Institute of Beauty",
  },
  {
    type: "School",
    name: "Ultrasound Medical Institute",
  },
  {
    type: "School",
    name: "Beautiful You School of Nail Technology",
  },
  {
    type: "School",
    name: "Harmon's Beauty School",
  },
  {
    type: "School",
    name: "Xavier College School of Nursing",
  },
  {
    type: "School",
    name: "Lawrence & Company College of Cosmetology",
  },
  {
    type: "School",
    name: "Cactus Academy",
  },
  {
    type: "School",
    name: "Kaizen Beauty Academy",
  },
  {
    type: "School",
    name: "Southern Texas Careers Academy",
  },
  {
    type: "School",
    name: "Sharp Edgez Barber Institute",
  },
  {
    type: "School",
    name: "Salon Professional Academy",
  },
  {
    type: "School",
    name: "Cosmotech School of Cosmetology",
  },
  {
    type: "School",
    name: "Prestige Health & Beauty Sciences Academy",
  },
  {
    type: "School",
    name: "Grace College of Barbering",
  },
  {
    type: "School",
    name: "California Career Institute",
  },
  {
    type: "School",
    name: "Cosmo Factory Cosmetology Academy",
  },
  {
    type: "School",
    name: "Grace School of Theology",
  },
  {
    type: "School",
    name: "Yeshiva Gedolah Kesser Torah",
  },
  {
    type: "School",
    name: "Universal Training Institute",
  },
  {
    type: "School",
    name: "Yeshiva Yesodei Hatorah",
  },
  {
    type: "School",
    name: "Rizzieri Institute",
  },
  {
    type: "School",
    name: "Bonnie Joseph Academy of Cosmetology and Barbering",
  },
  {
    type: "School",
    name: "Healthcare Training Institute",
  },
  {
    type: "School",
    name: "W Academy of Salon and Spa",
  },
  {
    type: "School",
    name: "Boca Beauty Academy",
  },
  {
    type: "School",
    name: "United Medical and Business Institute",
  },
  {
    type: "School",
    name: "Paul Mitchell the School-Jersey Shore",
  },
  {
    type: "School",
    name: "City Pointe Beauty Academy",
  },
  {
    type: "School",
    name: "Chrysm Institute of Esthetics",
  },
  {
    type: "School",
    name: "Sanford-Burnham Medical Research Institute",
  },
  {
    type: "School",
    name: "National Personal Training Institute of Columbus",
  },
  {
    type: "School",
    name: "Bly's School of Cosmetology",
  },
  {
    type: "School",
    name: "Toni & Guy Hairdressing Academy-Manteca",
  },
  {
    type: "School",
    name: "Belle Academy of Cosmetology",
  },
  {
    type: "School",
    name: "Strayer University-Missouri",
  },
  {
    type: "School",
    name: "Dorsey Business Schools-Lansing",
  },
  {
    type: "School",
    name: "ITT Technical Institute-Pensacola",
  },
  {
    type: "School",
    name: "ITT Technical Institute-San Antonio East",
  },
  {
    type: "School",
    name: "MedTech Institute-Orlando Campus",
  },
  {
    type: "School",
    name: "Tulsa Welding School-Tulsa",
  },
  {
    type: "School",
    name: "Empire Beauty School-Augusta",
  },
  {
    type: "School",
    name: "South University-High Point",
  },
  {
    type: "School",
    name: "Empire Beauty School-Vernon Hills",
  },
  {
    type: "School",
    name: "Empire Beauty School-Stone Park",
  },
  {
    type: "School",
    name: "Court Reporting Institute of St Louis",
  },
  {
    type: "School",
    name: "San Joaquin Valley College-Lancaster",
  },
  {
    type: "School",
    name: "San Joaquin Valley College-San Diego",
  },
  {
    type: "School",
    name: "Ohio Business College-Dayton",
  },
  {
    type: "School",
    name: "Empire Beauty School-Rochester",
  },
  {
    type: "School",
    name: "American Career College-Long Beach",
  },
  {
    type: "School",
    name: "Ross Medical Education Center-Kokomo",
  },
  {
    type: "School",
    name: "Ross Medical Education Center-Erlanger",
  },
  {
    type: "School",
    name: "Ross Medical Education Center-Charleston",
  },
  {
    type: "School",
    name: "L'Ecole Culinaire-Kansas City",
  },
  {
    type: "School",
    name: "Utah College of Massage Therapy-Houston",
  },
  {
    type: "School",
    name: "InterCoast Colleges-Fairfield",
  },
  {
    type: "School",
    name: "Vatterott College-ex'treme Institute by Nelly-St Louis",
  },
  {
    type: "School",
    name: "Personal Fitness & Nutrition Center",
  },
  {
    type: "School",
    name: "Southern Careers Institute-San Antonio",
  },
  {
    type: "School",
    name: "College of Business and Technology-Miami Gardens",
  },
  {
    type: "School",
    name: "Milan Institute of Cosmetology-Nampa",
  },
  {
    type: "School",
    name: "Milan Institute-Merced",
  },
  {
    type: "School",
    name: "Midwest Technical Institute-Springfield",
  },
  {
    type: "School",
    name: "Finger Lakes School of Massage",
  },
  {
    type: "School",
    name: "Georgia Regents University",
  },
  {
    type: "School",
    name: "Middle Georgia State College",
  },
  {
    type: "School",
    name: "Trenz Beauty Academy",
  },
  {
    type: "School",
    name: "Paul Mitchell the School-Denver",
  },
  {
    type: "School",
    name: "Academy of Interactive Entertainment",
  },
  {
    type: "School",
    name: "Arrojo Cosmetology School",
  },
  {
    type: "School",
    name: "Cosmetology Academy of Texarkana",
  },
  {
    type: "School",
    name: "Virginia Baptist College",
  },
  {
    type: "School",
    name: "Lynnes Welding Training",
  },
  {
    type: "School",
    name: "Aviation Institute of Maintenance-Las Vegas",
  },
  {
    type: "School",
    name: "Tulsa Technology Center-Owasso Campus",
  },
  {
    type: "School",
    name: "Tulsa Technology Center-Sand Springs Campus",
  },
  {
    type: "School",
    name: "National American University-Austin South",
  },
  {
    type: "School",
    name: "National American University-Lewisville",
  },
  {
    type: "School",
    name: "National American University-Georgetown",
  },
  {
    type: "School",
    name: "National American University-Richardson",
  },
  {
    type: "School",
    name: "National American University-Rochester",
  },
  {
    type: "School",
    name: "National American University-Weldon Spring",
  },
  {
    type: "School",
    name: "National American University-Wichita West",
  },
  {
    type: "School",
    name: "National American University-Indianapolis",
  },
  {
    type: "School",
    name: "National American University-Tigard",
  },
  {
    type: "School",
    name: "The Art Institute of St Louis",
  },
  {
    type: "School",
    name: "DeVry College of New York",
  },
  {
    type: "School",
    name: "DeVry University-Arizona",
  },
  {
    type: "School",
    name: "DeVry University-California",
  },
  {
    type: "School",
    name: "DeVry University-Colorado",
  },
  {
    type: "School",
    name: "DeVry University-Florida",
  },
  {
    type: "School",
    name: "DeVry University-Georgia",
  },
  {
    type: "School",
    name: "DeVry University-Illinois",
  },
  {
    type: "School",
    name: "DeVry University-Indiana",
  },
  {
    type: "School",
    name: "DeVry University-Kentucky",
  },
  {
    type: "School",
    name: "DeVry University-Maryland",
  },
  {
    type: "School",
    name: "DeVry University-Michigan",
  },
  {
    type: "School",
    name: "DeVry University-Minnesota",
  },
  {
    type: "School",
    name: "DeVry University-Missouri",
  },
  {
    type: "School",
    name: "DeVry University-Nevada",
  },
  {
    type: "School",
    name: "DeVry University-New Jersey",
  },
  {
    type: "School",
    name: "DeVry University-North Carolina",
  },
  {
    type: "School",
    name: "DeVry University-Ohio",
  },
  {
    type: "School",
    name: "DeVry University-Oklahoma",
  },
  {
    type: "School",
    name: "DeVry University-Oregon",
  },
  {
    type: "School",
    name: "DeVry University-Pennsylvania",
  },
  {
    type: "School",
    name: "DeVry University-Tennessee",
  },
  {
    type: "School",
    name: "DeVry University-Texas",
  },
  {
    type: "School",
    name: "DeVry University-Utah",
  },
  {
    type: "School",
    name: "DeVry University-Virginia",
  },
  {
    type: "School",
    name: "DeVry University-Washington",
  },
  {
    type: "School",
    name: "DeVry University-Wisconsin",
  },
  {
    type: "School",
    name: "University of North Georgia",
  },
  {
    type: "School",
    name: "South Georgia State College",
  },
  {
    type: "School",
    name: "Northeastern University Global Network",
  },
  {
    type: "School",
    name: "Georgia Military College-Distance Learning Campuses",
  },
  {
    type: "School",
    name: "Florida Polytechnic University",
  },
  {
    type: "School",
    name: "Brown Mackie College-Dallas",
  },
  {
    type: "School",
    name: "American Institute-Toms River",
  },
  {
    type: "School",
    name: "Texas Covenant Education",
  },
  {
    type: "School",
    name: "Arizona College-Mesa",
  },
  {
    type: "School",
    name: "American College of Healthcare",
  },
  {
    type: "School",
    name: "Antioch College",
  },
  {
    type: "School",
    name: "Texas A & M University-Central Texas",
  },
  {
    type: "School",
    name: "Central Georgia Technical College",
  },
  {
    type: "School",
    name: "Barber School of Pittsburgh",
  },
  {
    type: "School",
    name: "EDMC Central Administrative Office",
  },
  {
    type: "School",
    name: "The Salon Professional Academy-South Plainfield",
  },
  {
    type: "School",
    name: "Arizona State University-Skysong",
  },
  {
    type: "School",
    name: "Louisiana Delta Community College",
  },
];

export default institutions;
