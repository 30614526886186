import React, { useEffect } from "react";
import { useFirebase } from "react-redux-firebase";

const Logout: React.FC = () => {
  const firebase = useFirebase();

  useEffect(() => {
    (async () => {
      const auth = firebase.auth();
      const user = auth.currentUser?.uid;
      window.analytics.identify(user);
      try {
        await auth.signOut();
        window.analytics.track("Successful logout");
      } catch (error) {
        window.analytics.track("Unsuccessful logout");
        console.log(JSON.stringify(error));
      }
    })();
  });

  return null;
};

export default Logout;
