import React, { useState } from "react";
import styled from "styled-components";
import Title from "../../ui-kit/OneWeekPlanTitle/Title";
import Subtitle from "../../ui-kit/OneWeekPlanSubtitle/Subtitle";
import { Activity, Resource } from "../../../model/Resource";
import ActivityEditor from "../../sections/edit/ActivityEditor";
import RoundedButton from "../../ui-kit/RoundedButton/RoundedButton";
import ActivityFactory from "../../../model/ActivityFactory";
import StepBar from "../StepBar/StepBar";
import AddMaterialSubPage from "../../sections/subpages/AddMaterialSubPage";
import AddMaterialPageFooter from "../../editor/AddMaterialPageFooter";
import { Box } from "grommet";

interface ActivitiesEditorProps {
  onChange?: (activities: Activity[]) => void;
  onClickContinue?: () => void;
  activities: Activity[];
}

const Content = styled(Box)`
  width: 100%;
  height: 100%;

  & .buttons {
    padding-top: 20px;
    text-align: center;
  }
`;

const ScrollBox = styled(Box)`
  overflow-y: scroll;
  max-height: 100%;
`;

const StyledTitle = styled(Title)`
  margin: 0 0 24px 0;
`;

const StyledSubTitle = styled(Subtitle)`
  margin-bottom: 12px;
`;

const StyledActivityEditor = styled(ActivityEditor)`
  margin-bottom: 10px;
`;

const ActivitiesEditor: React.FC<ActivitiesEditorProps> = (props) => {
  const [activities, setActivities] = useState<Activity[]>(props.activities);
  const [showMaterialsSearchPage, setShowSearchMaterialsPage] = useState(false);

  const [resourcesToBeAdded, setResourcesToBeAdded] = useState<Resource[]>([]);
  const [selectedActivityIndex, setSelectedActivityIndex] = useState<
    number | undefined
  >(undefined);

  return (
    <Content>
      {showMaterialsSearchPage ? (
        <Box height="100%" direction="column" justify="between">
          <div>
            <AddMaterialSubPage
              onBack={() => setShowSearchMaterialsPage(false)}
              resourcesToBeAdded={resourcesToBeAdded}
              setResourcesToBeAdded={(resources) => {
                setResourcesToBeAdded(resources);
              }}
              selectedActivityIndex={selectedActivityIndex}
            />
          </div>
          <Box direction="row" justify="between" align="center">
            <AddMaterialPageFooter
              quantityOfResources={resourcesToBeAdded.length}
              onAddSelection={() => {
                const newActivities = activities.map((activity, index) => {
                  if (index === selectedActivityIndex) {
                    activity.resources = [
                      ...activity.resources,
                      ...resourcesToBeAdded,
                    ];
                    resourcesToBeAdded.forEach((resourceToBeAdded) => {
                      activity?.body?.content?.push({
                        type: "file-button",
                        attrs: {
                          id: resourceToBeAdded.id,
                          name: resourceToBeAdded.title,
                          type: resourceToBeAdded.type,
                          url: resourceToBeAdded.url,
                        },
                      });
                    });
                  }
                  return activity;
                });
                props.onChange?.(newActivities);
                setTimeout(() => {
                  setResourcesToBeAdded([]);
                  setSelectedActivityIndex(undefined);
                  setShowSearchMaterialsPage(false);
                }, 200);
              }}
              onClearSelection={() => {
                setResourcesToBeAdded([]);
              }}
            />
          </Box>
        </Box>
      ) : (
        <>
          <StepBar percentage={60} text="Step 3 out of 5" />
          <Box direction="column" justify="between">
            <ScrollBox direction="column">
              <StyledTitle text="Schedule your assignments for this week." />
              <StyledSubTitle text="Use this section to plan your weekly course assignments. You can add one or multiple assignments, upload materials you have prepared, or search from our database. Don’t forget to add the date to schedule the deadline." />
              {activities.map((activity, index) => (
                <StyledActivityEditor
                  key={`activity-${index}`}
                  index={index}
                  activity={activity}
                  onChange={(activity) => {
                    const newActivities = activities.map((oldActivity) =>
                      oldActivity.id === activity.id ? activity : oldActivity
                    );

                    props.onChange?.(newActivities);
                    setActivities(newActivities);
                  }}
                  onClickSearchForMaterials={() => {
                    setShowSearchMaterialsPage(true);
                    setSelectedActivityIndex(index);
                  }}
                />
              ))}
              <div>
                <RoundedButton
                  type="tertiary"
                  variant="blue"
                  onClick={() => {
                    const factory = new ActivityFactory();
                    const newActivity = factory.create();
                    const newActivities = [...activities, newActivity];
                    setActivities(newActivities);
                    props.onChange?.(newActivities);
                  }}
                >
                  + Add New Activity
                </RoundedButton>
              </div>
            </ScrollBox>
            <div className="buttons">
              <RoundedButton
                type="primary"
                variant="blue"
                onClick={() => props.onClickContinue?.()}
              >
                Continue
              </RoundedButton>
            </div>
          </Box>
        </>
      )}
    </Content>
  );
};

export default ActivitiesEditor;
