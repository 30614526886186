import React from "react";
import { Policies, Policy } from "../../../model/Syllabus";
import SectionEditorProps from "./SectionEditorProps";
import PolicyEditor from "./PolicyEditor";
import styled from "styled-components";
import { Accordion, AccordionPanel, Box, Image } from "grommet";

const StyledDiv = styled.div`
  display: flex;
  background-size: 45%;
  background-repeat: no-repeat;
`;
const StyledBox = styled(Box)`
  width: 50%;
  height: 456px;
  margin-bottom: 0;
  margin-left: 50%;
  margin-top: 35%;
`;

const PoliciesEditor: React.FC<SectionEditorProps> = ({
  section,
  onChange,
}) => {
  const policies = section as Policies;

  const onChangePolicies = (policy: Policy, index: number) => {
    const newPolicies = { ...policies };
    newPolicies.policies[index] = policy;
    onChange(newPolicies);
  };

  if (policies.policies.length === 0) {
    return (
      <>
        <StyledDiv>
          Let's get started and add some policies! Select your policies using
          the list on the right.
        </StyledDiv>
        <StyledBox>
          <Image src="/images/illustrations/policies.svg" />
        </StyledBox>
      </>
    );
  } else {
    return (
      <Accordion a11yTitle={"Policies added"}>
        {policies.policies.map((policy, index) => {
          return (
            <AccordionPanel label={policy.title} key={"accordion-" + index}>
              <Box pad="xsmall" a11yTitle={policy.title}>
                <PolicyEditor
                  key={policy.title}
                  policy={policy}
                  onChange={(changedPolicy) => {
                    onChangePolicies(changedPolicy, index);
                  }}
                  index={index}
                />
              </Box>
            </AccordionPanel>
          );
        })}
      </Accordion>
    );
  }
};

export default PoliciesEditor;
