import React, { Fragment } from "react";
import { Instructors } from "../../../model/Syllabus";
import styled from "styled-components";
import Container from "../../viewer/customization/Container";
import H4 from "../../viewer/customization/H4";

interface InstructorsSectionProps {
  section: Instructors;
}

const StyledContainer = styled(Container)`
  padding: 4px;
`;

const InstructorsSection: React.FC<InstructorsSectionProps> = (props) => {
  const section = props.section;
  return (
    <>
      <H4 a11yTitle={"Instructors Section"}>Instructor(s)</H4>
      {section.instructors.map((instructor, index) => {
        return (
          <Fragment key={index}>
            <StyledContainer>{instructor.name}</StyledContainer>
            <StyledContainer>{instructor.title}</StyledContainer>
            <StyledContainer>
              <a href={"mailto:" + instructor.email}>{instructor.email}</a>
            </StyledContainer>
            <StyledContainer>{instructor.phone}</StyledContainer>
            <StyledContainer>{instructor.office?.room}</StyledContainer>
            <StyledContainer>{instructor.office?.building}</StyledContainer>
            <StyledContainer>
              {instructor.office?.mailingAddress}
            </StyledContainer>
            {index !== section.instructors.length - 1 && <br />}
          </Fragment>
        );
      })}
    </>
  );
};

export default InstructorsSection;
