import { mergeAttributes, Node } from "@tiptap/core";
import { ReactNodeViewRenderer } from "@tiptap/react";
import FileComponentView from "./FileComponentView";

export default Node.create({
  name: "file-button",
  group: "block",
  draggable: true,
  selectable: true,

  addAttributes() {
    return {
      name: {
        default: "File Name",
      },
      size: {
        default: 0,
      },
      type: {
        default: "PDF",
      },
      loading: {
        default: false,
      },
      url: {
        default: "",
      },
      id: {
        default: "",
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: "file-button",
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ["file-button", mergeAttributes(HTMLAttributes)];
  },

  addNodeView() {
    return ReactNodeViewRenderer(FileComponentView);
  },
});
