import React from "react";
import { Blank, IconProps } from "grommet-icons";

const ArrowBottom: React.FC<IconProps> = (props) => {
  return (
    <Blank viewBox="0 0 24 24" {...props} color="plain">
      <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
      >
        <path
          d="M1.13504 6.87576e-07L6.86496 1.86651e-07C7.7128 1.12531e-07 8.17595 0.988856 7.63318 1.64018L4.76822 5.07813C4.36843 5.55789 3.63157 5.55789 3.23178 5.07814L0.36682 1.64019C-0.175952 0.988858 0.287204 7.61697e-07 1.13504 6.87576e-07Z"
          fill="rgba(13, 58, 248, 1)"
        />
      </svg>
    </Blank>
  );
};
export default ArrowBottom;
