import React from "react";
import { Blank, IconProps } from "grommet-icons";

const DropdownArrows: React.FC<IconProps> = (props) => {
  return (
    <Blank viewBox="0 0 24 24" {...props} color="plain">
      <svg
        width="6"
        height="9"
        viewBox="0 0 6 9"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.35355 8.64645L5.14645 6.85355C5.46143 6.53857 5.23835 6 4.79289 6L1.20711 6C0.761654 6 0.538571 6.53857 0.853554 6.85355L2.64645 8.64645C2.84171 8.84171 3.15829 8.84171 3.35355 8.64645Z"
          fill="#030B1D"
        />
        <path
          d="M2.64645 0.353553L0.853553 2.14645C0.538571 2.46143 0.761654 3 1.20711 3L4.79289 3C5.23835 3 5.46143 2.46143 5.14645 2.14645L3.35355 0.353553C3.15829 0.158291 2.84171 0.158291 2.64645 0.353553Z"
          fill="#030B1D"
        />
      </svg>
    </Blank>
  );
};
export default DropdownArrows;
