import React, { useContext, useState } from "react";
import { NodeViewWrapper } from "@tiptap/react";
import FileButton from "components/ui-kit/FileButton/FileButton";
import { format } from "date-fns";
import { RichTextEditorContext } from "../RichTextEditorContext";
import { FileButtonContext } from "../Editor/FileButtonContext";
import BlurrableContent from "../../../BlurrableContent";
import ContentPreviewerOverlay from "../../ContentPreviewerOverlay/ContentPreviewerOverlay";
import { getMimeType } from "../../../../helpers/fileTypesMapper";

const FileComponentView = (props: any) => {
  const { mode, unlocked, clickOnPrivateContent } = useContext(
    RichTextEditorContext
  );
  const [showOverlay, setShowOverlay] = useState(false);
  const { onDelete } = useContext(FileButtonContext);

  return (
    <>
      <NodeViewWrapper
        className="file-button"
        style={{
          width: "fit-content",
          padding: "10px 0",
          background: "transparent",
        }}
      >
        <BlurrableContent
          locked={!unlocked}
          onClickOnBlur={() => {
            clickOnPrivateContent?.();
          }}
        >
          <FileButton
            fileType={props.node.attrs.type}
            mode={mode}
            fileName={props.node.attrs.name}
            fileDate={`Created ${format(new Date(), "dd MMM, yyyy")}`}
            fileInfo={props.node.attrs.size}
            onClickDownload={() => {
              if (!unlocked) return;
              const link = document.createElement("a");
              link.setAttribute("download", props.node.attrs.name);
              link.href = props.node.attrs.url;
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            }}
            onClickPreview={() => setShowOverlay(true)}
            loading={props.node.attrs.loading}
            url={props.node.attrs.url}
            id={props.node.attrs.id}
            onClickDelete={() => onDelete(props.node.attrs.id)}
          />
        </BlurrableContent>
      </NodeViewWrapper>
      {showOverlay && (
        <ContentPreviewerOverlay
          url={props.node.attrs.url}
          fileType={getMimeType(props.node.attrs.type)}
          fileName={props.node.attrs.name}
          onClickBack={() => setShowOverlay(false)}
          onClickDownload={() => {
            const link = document.createElement("a");
            link.setAttribute("download", props.node.attrs.name);
            link.href = props.node.attrs.url;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }}
          onClickOpen={() => window.open(props.node.attrs.url, "_blank")}
        />
      )}
    </>
  );
};

export default FileComponentView;
