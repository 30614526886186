import React from "react";
import { FormField, TextInput as TextInputGrommet } from "grommet";
import styled from "styled-components";
import clsx from "clsx";
import Error from "../../../assets/icons/Error";
import { Blank } from "grommet-icons";

interface TextInputProps {
  value: string | number;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: () => void;
  a11yTitle?: string;
  placeholder?: string;
  type?: "text" | "password" | "number";
  error?: string;
  label?: string;
  disabled?: boolean;
  icon?: React.ReactElement;
  active?: boolean;
  reverse?: boolean;
  className?: string;
  extra?: React.ReactElement;
  autoFocus?: boolean;
  required?: boolean;
}

const defaultProps: Partial<TextInputProps> = {
  reverse: true,
};

const StyledFormField = styled(FormField)`
  &.disabled .iconColor {
    color: #9da2ac !important;
  }

  & > input:focus {
    background: none;
    border: none;
  }

  .text-error {
    margin: 3px 0;
    font-family: Open Sans;
    font-size: 12px;
    line-height: 16px;

    color: #e51144;

    .text {
      margin-left: 6px;
    }
  }

  .label {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #474e61;
    margin: 8px 0;

    &.disabled {
      color: #9da2ac;
    }
  }

  .required {
    color: #e51144;
    margin-left: 5px;
  }

  .input-container {
    position: relative;
  }

  input {
    background: #ffffff;
    border: 1px solid #e8f0fe;
    box-shadow: inset 0 0.5px 4px rgba(13, 34, 90, 0.15);
    border-radius: 4px;
    padding: 0 16px;
    font-family: Open Sans;
    font-weight: normal;
    color: #030b1d;
    height: 40px;
    font-size: 16px;

    &:active,
    &.active,
    &:focus {
      border: 1px solid #0d3af8 !important;
      outline: none;
      box-shadow: none !important;

      &.disabled {
        border: 1px solid #9da2ac !important;
      }
    }

    &:hover {
      border: 1px solid #bfd1f0;
    }

    &::placeholder {
      color: #474e61 !important;
    }

    &.disabled {
      color: #9da2ac;
      border: 1px solid #9da2ac;
      box-shadow: none;

      &:hover {
        cursor: not-allowed;
      }

      &::placeholder {
        color: #9da2ac !important;
      }
    }

    &.error {
      border: 1px solid #e51144 !important;
    }

    &.withIcon {
      padding-left: 36px;
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  & .iconColor {
    color: #0d3af8;
  }
`;

const TextInput: React.FC<TextInputProps> = (props) => {
  return (
    <StyledFormField
      disabled={props.disabled}
      className={clsx({ disabled: props.disabled, [props.className!]: true })}
    >
      {props.label && (
        <span
          className={clsx({
            label: true,
            disabled: props.disabled,
          })}
        >
          {props.label}
          {props.required && <span className="required">*</span>}
        </span>
      )}
      <div className="input-container">
        {props.extra}
        <TextInputGrommet
          className={clsx({
            disabled: props.disabled,
            error: !!props.error,
            active: props.active,
            withIcon: props.icon,
          })}
          autoFocus={props.autoFocus}
          disabled={props.disabled}
          placeholder={props.placeholder}
          value={props.value}
          a11yTitle={props.a11yTitle}
          onChange={props.onChange}
          type={props.type}
          focusIndicator={true}
          onFocus={() => props.onFocus?.()}
          icon={
            <Blank color="currentColor" className="iconColor">
              {props.icon}
            </Blank>
          }
          reverse={props.reverse}
        />
      </div>
      {props.error && (
        <div className="text-error">
          <Error size="8px" />
          <span className="text">{props.error}</span>
        </div>
      )}
    </StyledFormField>
  );
};

TextInput.defaultProps = defaultProps;

export default TextInput;
