import React from "react";
import { CourseDescription } from "../../../model/Syllabus";
import SectionEditorProps from "./SectionEditorProps";
import useDelayedState from "../../../hooks/useDelayedState";
import RichTextEditorWrapper from "components/ui-kit/RichTextEditor/RichTextEditorWrapper";

const CourseDescriptionEditor: React.FC<SectionEditorProps> = ({
  section,
  onChange,
  error,
}) => {
  const [courseDescription, setCourseDescription] =
    useDelayedState<CourseDescription>(
      { ...(section as CourseDescription) },
      onChange
    );

  return (
    <RichTextEditorWrapper
      id="course-description-text-editor"
      placeholder="Add a description for your course."
      key="course-description-description"
      value={courseDescription.description}
      error={error?.errors.description}
      onChange={(content) => {
        setCourseDescription({
          ...courseDescription,
          description: content,
        });
      }}
      label="Edit course description"
      sublabel="Add a description for your course. This will explain what the course is about and its general aspects."
      required={true}
    />
  );
};

export default CourseDescriptionEditor;
