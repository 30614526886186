import { Syllabus } from "model/Syllabus";
import {
  TeacherModeActions,
  TeacherModeActionType,
} from "redux/teacher-mode/types/TeacherModeTypes";

export interface TeacherModeState {
  tempSyllabus: Syllabus[];
}

const initialState: TeacherModeState = {
  tempSyllabus: [],
};

export const TeacherModeReducer = (
  state = initialState,
  action: TeacherModeActions
): TeacherModeState => {
  switch (action.type) {
    case TeacherModeActionType.SaveTempSyllabus:
      return {
        ...state,
        tempSyllabus: [
          ...state.tempSyllabus.filter(
            (s) => s.firebaseId !== action.payload.firebaseId
          ),
          action.payload,
        ],
      };
    case TeacherModeActionType.DeleteTempSyllabus:
      return {
        ...state,
        tempSyllabus: state.tempSyllabus.filter(
          (syllabus) => syllabus.firebaseId !== action.payload
        ),
      };
    case TeacherModeActionType.DeleteAllSyllabi:
      return initialState;
    default:
      return state;
  }
};
