import React from "react";
import { Blank, IconProps } from "grommet-icons";

const Elipse: React.FC<IconProps> = (props) => {
  return (
    <Blank {...props} viewBox="0 0 24 24" color="plain">
      <svg
        width="24"
        height="24"
        viewBox="0 0 8 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="4" cy="4" r="4" fill="currentColor" />
      </svg>
    </Blank>
  );
};

export default Elipse;
