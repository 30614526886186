import React from "react";
import RoundedButton from "../ui-kit/RoundedButton/RoundedButton";
import { Box } from "grommet";
import styled from "styled-components";
import TextButton from "../ui-kit/TextButton/TextButton";

interface AddMaterialPageFooterProps {
  onClearSelection?: () => void;
  onAddSelection?: () => void;
  quantityOfResources?: number;
}

const SaveRoundedButton = styled(RoundedButton)`
  margin: 0 4px;
`;

const AddMaterialPageFooter: React.FC<AddMaterialPageFooterProps> = (props) => {
  return (
    <>
      <div className="footer-left">
        <Box
          direction="row"
          align="start"
          justify="start"
          pad={{ vertical: "small" }}
        >
          <TextButton onClick={() => {}} disabled={true}>
            {props.quantityOfResources} Material(s) Selected
          </TextButton>
        </Box>
      </div>
      <div className="footer-right">
        <Box
          direction="row"
          align="end"
          justify="end"
          pad={{ vertical: "small" }}
        >
          <SaveRoundedButton
            onClick={() => props.onClearSelection?.()}
            type="tertiary"
          >
            Clear Selection
          </SaveRoundedButton>
          <SaveRoundedButton
            onClick={() => props.onAddSelection?.()}
            type="primary"
          >
            Add Selection
          </SaveRoundedButton>
        </Box>
      </div>
    </>
  );
};

export default AddMaterialPageFooter;
