import { configureStore as configureStoreReduxToolkit } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import { reduxFirestore } from "redux-firestore";
import thunk from "redux-thunk";
import firebase from "../config/fbLoader";
import RootReducer from "./RootReducer";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { Dispatch } from "react";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";

const sagaMiddleware = createSagaMiddleware();

const persistConfig = {
  key: "root",
  storage,
  whitelist: [
    "integrations",
    "onboarding",
    "oneWeekPlan",
    "common",
    "students",
    "syllabus",
    "syllabusTemplate",
    "teacherMode",
  ],
};

const persistedReducer = persistReducer(persistConfig, RootReducer);

const store = configureStoreReduxToolkit({
  reducer: persistedReducer,
  devTools: true,
  middleware: [sagaMiddleware, thunk],
  enhancers: [reduxFirestore(firebase)],
});

export type AppState = ReturnType<typeof RootReducer>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<Dispatch<any>>();
export const useAppSelector: TypedUseSelectorHook<AppState> = useSelector;

export const configureStore = () => {
  return store;
};
