import React, { useState } from "react";
import Copyright from "./Copyright";
import { useFirebase } from "react-redux-firebase";
import { useAppDispatch } from "../redux/configureStore";
import { addSnackbarMessage } from "../redux/common/actions/CommonActions";
import { v4 as uuidv4 } from "uuid";
import { useHistory } from "react-router-dom";
import { Avatar, Box, Text } from "grommet";
import RoundedButton from "./ui-kit/RoundedButton/RoundedButton";
import { Lock } from "grommet-icons";
import TextInput from "./TextInput";
import styled from "styled-components";

const Container = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 50px;
`;

const Form = styled.form`
  width: 100%;
  margin-top: 10px;
`;

const Paper = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ForgotPasswordPage: React.FC = () => {
  const firebase = useFirebase();
  const [email, setEmail] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const history = useHistory();

  const handleSubmit = async () => {
    try {
      setLoading(true);
      await firebase.auth().sendPasswordResetEmail(email);

      dispatch(
        addSnackbarMessage({
          message: "We already sent you an email to reestablish your password",
          type: "normal",
          id: uuidv4(),
        })
      );
      history.push("/login");
    } catch (e) {
      dispatch(
        addSnackbarMessage({
          message: e.message,
          type: "critical",
          id: uuidv4(),
        })
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      <Paper>
        <Box margin={{ bottom: "small" }} align="center" gap="small">
          <Avatar size="medium" background="light-2">
            <Lock color="black" />
          </Avatar>
          <Text size="large" a11yTitle="Sign In">
            Did you forget your password?
          </Text>
        </Box>
        <Form>
          <TextInput
            margin={{ vertical: "xsmall" }}
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            a11yTitle="Email Address"
            placeholder="Email Address"
          />
          <Box align="center" pad={{ vertical: "small" }}>
            <RoundedButton
              type="primary"
              variant="blue"
              onClick={() => handleSubmit()}
              disabled={loading}
              loading={loading}
            >
              Recover password
            </RoundedButton>
          </Box>
        </Form>
      </Paper>
      <Box margin={{ vertical: "medium" }}>
        <Copyright />
      </Box>
    </Container>
  );
};

export default ForgotPasswordPage;
