import React from "react";
import { Section } from "../../model/Syllabus";
import CustomSectionEditor from "../sections/edit/CustomSectionEditor";
import CourseTitleEditor from "../sections/edit/CourseTitleEditor";
import CourseDescriptionEditor from "../sections/edit/CourseDescriptionEditor";
import CreditHoursEditor from "../sections/edit/CreditHoursEditor";
import ClassTimesEditor from "../sections/edit/ClassTimesEditor";
import OfficeHoursEditor from "../sections/edit/OfficeHoursEditor";
import InstructorsEditor from "../sections/edit/InstructorsEditor";
import RequiredTextsEditor from "../sections/edit/RequiredTextsEditor";
import GradingPolicyEditor from "../sections/edit/GradingPolicyEditor";
import ScheduleEditor from "../sections/edit/schedule/ScheduleEditor";
import SkillsEditor from "../sections/edit/SkillsEditor";
import PrerequisitesEditor from "../sections/edit/PrerequisitesEditor";
import MaterialsEditor from "../sections/edit/MaterialsEditor";
import LocationEditor from "../sections/edit/LocationEditor";
import CourseObjectivesEditor from "../sections/edit/CourseObjectivesEditor";
import PoliciesEditor from "../sections/edit/PoliciesEditor";
import WeekEditor from "../sections/edit/WeekEditor";
import SectionEditorProps from "components/sections/edit/SectionEditorProps";

const SectionEditor: React.FC<SectionEditorProps> = ({
  section,
  onChange,
  index,
  onSubPageSelected,
  needsRegistration,
  error,
}) => {
  const _section = section;

  // Propagate changes to parent
  const setSection = (newSection: Section) => {
    if (newSection.attachments) {
      newSection.attachments = section.attachments;
      onChange(newSection);
    } else {
      onChange(newSection);
    }
  };

  const editorProps: SectionEditorProps = {
    section: _section,
    onChange: setSection,
    index: index,
    onSubPageSelected: onSubPageSelected,
    error: error,
  };

  const editor = () => {
    switch (_section.type) {
      case "Schedule":
        return <ScheduleEditor {...editorProps} />;
      case "GradingPolicy":
        return <GradingPolicyEditor {...editorProps} />;
      case "RequiredTexts":
        //currently this is just a placeholder for the attachment viewer
        return <RequiredTextsEditor />;
      case "Instructors":
        return <InstructorsEditor {...editorProps} />;
      case "ClassTimes":
        return <ClassTimesEditor {...editorProps} />;
      case "CourseDescription":
        return <CourseDescriptionEditor {...editorProps} />;
      case "CourseObjectives":
        return <CourseObjectivesEditor {...editorProps} />;
      case "CourseTitle":
        return <CourseTitleEditor {...editorProps} />;
      case "CreditHours":
        return <CreditHoursEditor {...editorProps} />;
      case "CustomSection":
        return <CustomSectionEditor {...editorProps} />;
      case "OfficeHours":
        return <OfficeHoursEditor {...editorProps} />;
      case "Materials":
        return <MaterialsEditor {...editorProps} />;
      case "Policies":
        return <PoliciesEditor {...editorProps} />;
      case "Skills":
        return <SkillsEditor {...editorProps} />;
      case "Prerequisites":
        return <PrerequisitesEditor {...editorProps} />;
      case "Location":
        return <LocationEditor {...editorProps} />;
      case "Module":
        return (
          <WeekEditor needsRegistration={needsRegistration} {...editorProps} />
        );
      default:
        return <div>Module "{section.type}" not found</div>;
    }
  };

  return <>{editor()}</>;
};

export default SectionEditor;
