import { Syllabus } from "../model/Syllabus";
import { useCallback, useEffect, useState } from "react";
import {
  convertContentRef,
  convertFbTimestampToDate,
  getSyllabusById,
  getValuesByKey,
  searchDocRefsByContestRefs,
} from "../utils/fbUtil";
import { useFirestore } from "react-redux-firebase";
import { useSelector } from "react-redux";
import { AppState } from "../redux/configureStore";
import { appConfig } from "../config/config";

export const useGetSyllabus = (id?: string) => {
  const firestore = useFirestore();
  const [syllabus, setSyllabus] = useState<Syllabus | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const getSyllabus = useCallback(
    async (id: string) => {
      setIsLoading(true);
      try {
        const syllabus = await getSyllabusById(id, firestore);
        const contentRefList = getValuesByKey(syllabus, "contentRef");
        const docRefList = await searchDocRefsByContestRefs(
          contentRefList,
          firestore
        );
        convertContentRef(syllabus, docRefList);
        syllabus.firebaseId = id;
        setSyllabus(syllabus);
      } catch (error) {
        setErrorMessage(error.message);
      } finally {
        setIsLoading(false);
      }
    },
    [firestore]
  );

  useEffect(() => {
    if (id) {
      getSyllabus(id);
    }
  }, [getSyllabus, id]);

  return { syllabus, isLoading, errorMessage };
};

export const useGetMySyllabi = (): readonly [
  Syllabus[],
  boolean,
  string,
  () => void
] => {
  const auth = useSelector((state: AppState) => state.firebase.auth);
  const firestore = useFirestore();
  const [syllabi, setSyllabi] = useState<Syllabus[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  // @Todo: load syllabi from firestore redux:
  //
  // useFirestoreConnect({
  //     collection: 'syllabi',
  //     where: [['createdBy', '==', auth.uid], ['version', '==', appConfig.syllabusVersion]]
  // });
  // const s = useSelector((state: AppState) => state.firestore.data.syllabi);

  const getMySyllabi = useCallback(async () => {
    setIsLoading(true);
    try {
      const result = await firestore
        .collection("syllabi")
        .where("createdBy", "==", auth.uid)
        .where("version", "==", appConfig.syllabusVersion)
        .get();
      const data = result.docs.map((doc) => {
        const syllabus = doc.data();
        syllabus.firebaseId = doc.id;
        convertFbTimestampToDate(syllabus);
        return syllabus;
      }) as Syllabus[];

      setSyllabi(data);
    } catch (error) {
      setErrorMessage(error.message);
    } finally {
      setIsLoading(false);
    }
  }, [firestore, auth.uid]);

  useEffect(() => {
    getMySyllabi();
  }, [getMySyllabi]);

  return [syllabi, isLoading, errorMessage, getMySyllabi] as const;
};
