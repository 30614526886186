import React from "react";

import ColorSelector from "./ColorSelector";
import FontSelector from "./FontSelector";
import { FontFamily } from "../viewer/customization/FontConfiguration";

interface CustomStyleWrapperProps {
  onSelectedFont?: (font: FontFamily) => void;
  selectedFont?: FontFamily;
  onSelectedColor?: (color: string) => void;
  selectedColor?: string;
}

const CustomStyleWrapper: React.FC<CustomStyleWrapperProps> = (props) => {
  return (
    <div>
      <FontSelector
        selectedFont={props.selectedFont}
        onSelectedFont={props.onSelectedFont}
      />
      <ColorSelector
        selectedColor={props.selectedColor}
        onSelectedColor={props.onSelectedColor}
      />
    </div>
  );
};

export default CustomStyleWrapper;
