import React from "react";
import { CourseObjectives } from "../../../model/Syllabus";
import SectionEditorProps from "./SectionEditorProps";
import ObjectivesEditor from "./ObjectivesEditor";

// Editor for list of course objectives:
// - add an objective
// - edit objectives
//
const CourseObjectivesEditor: React.FC<SectionEditorProps> = ({
  section,
  onChange,
  error,
}) => {
  // const [_section, _setSection] = useState({...(section as CourseObjectives)});
  const objectives = (section as CourseObjectives).objectives;

  return (
    <>
      <ObjectivesEditor
        objectives={objectives}
        onChange={(objectives) => {
          onChange({
            ...(section as CourseObjectives),
            objectives: objectives,
          });
        }}
        error={error}
      />
    </>
  );
};

export default CourseObjectivesEditor;
