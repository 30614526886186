import React from "react";
import { useAppDispatch, useAppSelector } from "../redux/configureStore";
import { removeSnackbarMessage } from "../redux/common/actions/CommonActions";
import { Box, Notification } from "grommet";

const SnackbarLists = () => {
  const messages = useAppSelector((state) => state.common.snackbarMessages);
  const dispatch = useAppDispatch();

  const handleClose = (id: string) => {
    dispatch(removeSnackbarMessage(id));
  };

  return (
    <>
      {messages.map((message) => {
        return (
          <Box pad={{ bottom: "large" }} align="center" key={message.id}>
            <Notification
              key={message.id}
              title={message.message}
              status={message.type}
              toast={false}
              message={
                message.type === "critical" ? `Error N° ${message.id}` : ""
              }
              onClose={() => handleClose(message.id)}
            />
          </Box>
        );
      })}
    </>
  );
};

export default SnackbarLists;
