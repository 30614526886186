import React from "react";
import { Module, Schedule } from "../../../model/Syllabus";
import ScheduleViewer from "../../ScheduleViewer";
import { Activity } from "../../../model/Resource";

interface ScheduleSectionProps {
  section: Schedule;
  onClickWeek?: (week: Module) => void;
  onClickActivity?: (activity: Activity) => void;
}

const ScheduleSection: React.FC<ScheduleSectionProps> = (props) => {
  const section = props.section;
  return (
    <ScheduleViewer
      schedule={section}
      onClickWeek={props.onClickWeek}
      onClickActivity={props.onClickActivity}
    />
  );
};

export default ScheduleSection;
