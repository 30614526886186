import React, { useRef, useState } from "react";
import { Prerequisite, Prerequisites } from "../../../model/Syllabus";
import SectionEditorProps from "./SectionEditorProps";
import useDelayedState from "../../../hooks/useDelayedState";
import RoundedButton from "../../ui-kit/RoundedButton/RoundedButton";
import { Box, Grid, ResponsiveContext } from "grommet";
import IconButton from "components/IconButton";
import { getUniqueId } from "../../editor/SectionFactory";
import RichTextEditorWrapper from "../../ui-kit/RichTextEditor/RichTextEditorWrapper";
import Delete from "assets/icons/Delete";
import { HidableComponent } from "components/hoc/Hidable";
import Alert from "components/Alert";

interface PrerequisiteEditorProps {
  id: number;
  key: string;
  prerequisite: Prerequisite;
  onChange: (prerequisite: Prerequisite) => void;
  onDelete: () => void;
  error: any;
  label?: string;
}

// Editor component for a single prerequisite
const PrerequisiteEditor: React.FC<PrerequisiteEditorProps> = (props) => {
  const [prerequisite, setPrerequisite] = useDelayedState<Prerequisite>(
    { ...props.prerequisite },
    props.onChange
  );

  return (
    <Grid
      rows={["xxxsmall"]}
      columns={["medium"]}
      areas={[{ name: "header", start: [0, 0], end: [1, 0] }]}
      responsive={true}
      style={{ position: "relative" }}
      a11yTitle={"Add Prerequisites"}
    >
      <Box gridArea="header" a11yTitle={"Prerequisites input"} tabIndex={1}>
        <RichTextEditorWrapper
          id={`prerequisites-text-editor-${props.id}`}
          placeholder="List prerequisites for this course."
          key={props.key}
          value={prerequisite.body}
          onChange={(content) => {
            setPrerequisite({
              type: "Prerequisite",
              body: content,
              id: getUniqueId(),
            });
          }}
          error={props.error}
          label={props.label}
          required={true}
        />
        <IconButton
          mediaQuery={"@media (max-width: 764px) {left: 170%}"}
          styles={{ position: "absolute", right: "-2px", top: "25px" }}
          icon={<Delete size="18px" />}
          onClick={() => {
            props.onDelete();
          }}
          a11yTitle={"Button to remove current prerequisite"}
        />
      </Box>
    </Grid>
  );
};

// Editor for list of prerequisites:
// - add a prerequisite
// - change prerequisites
//
const PrerequisitesEditor: React.FC<SectionEditorProps> = ({
  section,
  onChange,
  error,
}) => {
  const [_section, _setSection] = useState({ ...(section as Prerequisites) });
  const prerequisitesRef = useRef(_section.prerequisites);
  const [indexOffset, setIndexOffset] = useState(0);
  prerequisitesRef.current = _section.prerequisites;
  // Propagate changes to parent
  const setSection = (newSection: Prerequisites) => {
    onChange(newSection);
    _setSection(newSection);
  };

  // Replace an existing prerequisite
  const onChangePrerequisite = (prerequisite: Prerequisite, index: number) => {
    const newPrerequisites = [...prerequisitesRef.current];
    newPrerequisites[index] = prerequisite;
    setSection({
      ..._section,
      prerequisites: newPrerequisites,
    });
  };

  // Append a new prerequisite to the section
  const addPrerequisite = () => {
    setSection({
      ..._section,
      prerequisites: [
        ..._section.prerequisites,
        {
          type: "Prerequisite",
          body: {
            type: "doc",
            content: [
              {
                type: "paragraph",
              },
            ],
          },
          id: getUniqueId(),
        },
      ],
    });
  };

  const onDeletePrerequisite = (index: number) => {
    const newPrerequisites = [...prerequisitesRef.current];
    newPrerequisites.splice(index, 1);
    setSection({
      ..._section,
      prerequisites: newPrerequisites,
    });
    setIndexOffset(indexOffset + newPrerequisites.length + 1);
  };

  // Render
  return (
    <ResponsiveContext.Consumer>
      {(size) => (
        <Box
          direction="column"
          align="rigth"
          gap={"large"}
          width={size === "medium" ? "200px" : "700px"}
        >
          <Box
            gridArea="input"
            a11yTitle={"Prerequisites input"}
            width={size === "medium" ? "200px" : "700px"}
            gap={"large"}
          >
            {_section.prerequisites.map((prerequisite, index) => (
              <PrerequisiteEditor
                id={index}
                key={"prerequisite-" + index + indexOffset}
                prerequisite={prerequisite}
                onChange={(prerequisite) =>
                  onChangePrerequisite(prerequisite, index)
                }
                onDelete={() => onDeletePrerequisite(index)}
                error={error?.errors[`prerequisites[${index}].body`]}
                label={`Prerequisite ${index + 1}`}
              />
            ))}
          </Box>
          <Box
            gridArea="button"
            a11yTitle={"Button to add another prerequisite"}
            width={"medium"}
          >
            <RoundedButton type={"primary"} onClick={() => addPrerequisite()}>
              Add a Prerequisite
            </RoundedButton>
          </Box>
          <HidableComponent isVisible={!!error?.errors.prerequisites}>
            <Alert>{error?.errors.prerequisites}</Alert>
          </HidableComponent>
        </Box>
      )}
    </ResponsiveContext.Consumer>
  );
};

export default PrerequisitesEditor;
