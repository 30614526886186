import { SectionValidator } from "./SectionValidator";
import { Module } from "model/Syllabus";
import Joi from "joi";
import { errorMapper } from "../../../helpers/errorMapper";
import _ from "lodash";

export default class ModuleValidator extends SectionValidator {
  validate(section: Module) {
    const schema = Joi.object({
      activities: Joi.array().items(
        Joi.object({
          title: Joi.string().min(3).messages({
            "string.min":
              "Activity Title length must be at least {#limit} characters long",
            "string.empty": "Activity Title cannot be empty",
          }),
        })
      ),
    }).options({ allowUnknown: true, abortEarly: false });

    const sectionErrorItem = {
      section: section,
      errors: errorMapper(section, schema),
    };

    return !_.isEmpty(sectionErrorItem.errors) ? sectionErrorItem : undefined;
  }
}
