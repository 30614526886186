import React, { useEffect } from "react";

//currently this is a placeholder that only uses the attachment viewer and Google Books as the sole integration
const RequiredTextsEditor: React.FC = () => {
  useEffect(() => {}, []);

  return <></>;
};

export default RequiredTextsEditor;
