import React, { useContext } from "react";
import styled from "styled-components";
import { SyllabusThemeContext } from "./SyllabusTheme";
import { Heading as GrommetHeading } from "grommet";
import { FontFamily, FontLevel, resolveFontFamily } from "./FontConfiguration";

interface HeadingProps {
  className?: string;
  a11yTitle?: string;
  level: Exclude<FontLevel, "paragraph">;
}

interface StyledComponentProps {
  fontFamily: FontFamily;
  customColor: string;
  level: FontLevel;
}

const StyledHeader = styled(GrommetHeading)<StyledComponentProps>`
  margin: 0;
  ${(props: StyledComponentProps) => `
    line-height: 1;
    font-family: ${props.fontFamily};
    color: ${props.customColor};
    font-size: ${resolveFontFamily(props.fontFamily, props.level).size}px;
    font-weight: ${resolveFontFamily(props.fontFamily, props.level).weight};
  `}
`;

const Heading: React.FC<HeadingProps> = (props) => {
  const { fontFamily, primaryColor } = useContext(SyllabusThemeContext);
  return (
    <StyledHeader
      level={props.level}
      className={props.className}
      customColor={primaryColor}
      fontFamily={fontFamily}
      a11yTitle={props.a11yTitle}
    >
      {props.children}
    </StyledHeader>
  );
};

export default Heading;
