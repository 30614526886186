import React from "react";
import styled from "styled-components";
import { Text } from "grommet";
import { format } from "date-fns";

interface OpenedPillProps {
  date: Date;
}

const Pill = styled.div`
  background: #dde6ff;
  align-content: center;
  justify-content: center;
  display: flex;
  border-radius: 2px;
  padding: 0 10px;
  width: fit-content;

  & .text {
    font-family: Open Sans;
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    color: #030b1d;
    padding: 2px;
  }
`;

const OpenedPill: React.FC<OpenedPillProps> = (props) => {
  return (
    <Pill>
      <Text className="text">Opened {format(props.date, "LLL do hh:mma")}</Text>
    </Pill>
  );
};

export default OpenedPill;
