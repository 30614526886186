import { Syllabus } from "../../../model/Syllabus";
import { Activity } from "../../../model/Resource";
import { JSONContent } from "@tiptap/react";

export enum OneWeekPlanActionType {
  SaveTempSyllabus = "SaveTempSyllabus",
  DeleteTempSyllabus = "DeleteTempSyllabus",
  SaveObjectives = "SaveObjectives",
  SaveActivities = "SaveActivities",
  SaveCourseTitle = "SaveCourseTitle",
  SaveInstructor = "SaveInstructor",
  SaveSchool = "SaveSchool",
}

export interface SaveTempSyllabus {
  type: typeof OneWeekPlanActionType.SaveTempSyllabus;
  payload: Syllabus;
}

export interface DeleteTempSyllabus {
  type: typeof OneWeekPlanActionType.DeleteTempSyllabus;
  payload: undefined;
}

export interface SaveObjectives {
  type: typeof OneWeekPlanActionType.SaveObjectives;
  payload: JSONContent;
}

export interface SaveActivities {
  type: typeof OneWeekPlanActionType.SaveActivities;
  payload: Activity[];
}

export interface SaveCourseTitle {
  type: typeof OneWeekPlanActionType.SaveCourseTitle;
  payload: string;
}

export interface SaveInstructor {
  type: typeof OneWeekPlanActionType.SaveInstructor;
  payload: string;
}

export interface SaveSchool {
  type: typeof OneWeekPlanActionType.SaveSchool;
  payload: string;
}

export type OneWeekPlanActions =
  | SaveTempSyllabus
  | DeleteTempSyllabus
  | SaveObjectives
  | SaveActivities
  | SaveCourseTitle
  | SaveInstructor
  | SaveSchool;
