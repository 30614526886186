import React from "react";
import { Blank, IconProps } from "grommet-icons";
import { ChildrenIconProps } from "./FileTypeIcon";
import { IconColors } from "../../theme/GlobalTheme";

const DEFAULT: React.FC<IconProps & ChildrenIconProps> = (props) => {
  return (
    <Blank viewBox="0 0 128 128" {...props} color="plain">
      <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
      >
        <path
          fill="white"
          d="M14.86.56H8a2.34,2.34,0,0,0-1.62.68,2.26,2.26,0,0,0-.67,1.61V21.14A2.29,2.29,0,0,0,8,23.42H20.59a2.29,2.29,0,0,0,2.29-2.28V8.55Z"
        />
        <path
          fill={
            props.disabled
              ? IconColors["icon-disabled"]
              : IconColors["icon-dark-blue"]
          }
          d="M14.63,1.13,22.3,8.79V21.13a1.72,1.72,0,0,1-1.72,1.71H8a1.7,1.7,0,0,1-1.21-.5,1.66,1.66,0,0,1-.5-1.21l0-18.29a1.7,1.7,0,0,1,.5-1.21A1.74,1.74,0,0,1,8,1.14h6.62m.23-.57H8a2.34,2.34,0,0,0-1.62.68,2.26,2.26,0,0,0-.67,1.61V21.14A2.29,2.29,0,0,0,8,23.42H20.59a2.29,2.29,0,0,0,2.29-2.28V8.55l-8-8Z"
        />
        <path
          fill={
            props.disabled
              ? IconColors["icon-disabled"]
              : IconColors["icon-light-blue"]
          }
          d="M14.86.85V6.27a2.28,2.28,0,0,0,2.28,2.29h5.43Z"
        />
        <path
          fill={
            props.disabled
              ? IconColors["icon-disabled"]
              : IconColors["icon-dark-blue"]
          }
          d="M22.58,8.84H17.15a2.57,2.57,0,0,1-2.57-2.57V.85a.3.3,0,0,1,.17-.27.31.31,0,0,1,.32.06l7.71,7.71a.28.28,0,0,1,.07.31A.29.29,0,0,1,22.58,8.84Zm-7.43-7.3V6.27a2,2,0,0,0,2,2h4.74Z"
        />
        <rect
          fill={
            props.disabled
              ? IconColors["icon-disabled"]
              : IconColors["icon-blue"]
          }
          x="1.16"
          y="11.13"
          width="20"
          height="9.14"
          rx="0.86"
        />
        <path
          fill={
            props.disabled
              ? IconColors["icon-disabled"]
              : IconColors["icon-dark-blue"]
          }
          d="M20.3,11.7a.28.28,0,0,1,.28.29v7.42a.29.29,0,0,1-.29.29H2a.29.29,0,0,1-.29-.28V12A.29.29,0,0,1,2,11.71H20.3m0-.57H2a.85.85,0,0,0-.85.86v7.43a.86.86,0,0,0,.86.85H20.3a.85.85,0,0,0,.86-.86V12a.86.86,0,0,0-.86-.85Z"
        />
        <path
          fill="white"
          d="M8.53,13.46v.74H6.62v1.18H8.09v.73H6.62v1.94H5.7V13.46Z"
        />
        <path fill="white" d="M10.1,13.46V18H9.19V13.46Z" />
        <path fill="white" d="M11.93,17.31h1.51V18H11V13.46h.92Z" />
        <path
          fill="white"
          d="M15,14.2v1.15h1.54v.73H15v1.21h1.74V18H14V13.45h2.66v.74Z"
        />
      </svg>
    </Blank>
  );
};

export default DEFAULT;
