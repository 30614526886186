import React from "react";
import styled from "styled-components";

interface DropItemProps {
  text: string;
  onClick?: () => void;
  disabled?: boolean;
}

const StyledDropItem = styled.div<DropItemProps>`
  font-family: Open Sans;
  font-weight: normal;
  font-size: 16px;
  padding: 8px;

  color: #030b1d;

  &:hover,
  &:focus-visible {
    background: #eff3ff;
    cursor: pointer;
  }

  ${({ disabled }) =>
    disabled &&
    `
    background: #eee;
    color: #9DA2AC;
    
    &:hover {
      cursor: not-allowed; 
      background: #eee;
      color: #9DA2AC;
    }
  `}
`;

const DropItem: React.FC<DropItemProps> = (props) => {
  return (
    <StyledDropItem
      {...props}
      onClick={() => !props.disabled && props.onClick?.()}
      tabIndex={1}
    >
      {props.text}
    </StyledDropItem>
  );
};

export default DropItem;
