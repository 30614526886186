import React from "react";
import { Blank, IconProps } from "grommet-icons";
import { ChildrenIconProps } from "./FileTypeIcon";
import { IconColors } from "../../theme/GlobalTheme";

const PNG: React.FC<IconProps & ChildrenIconProps> = (props) => {
  return (
    <Blank viewBox="0 0 39 42" {...props} color="plain">
      <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
      >
        <path
          fill="white"
          d="M14.79.59H7.93A2.29,2.29,0,0,0,5.65,2.89V21.17A2.29,2.29,0,0,0,8,23.46H20.52a2.29,2.29,0,0,0,2.29-2.29V8.59Z"
        />
        <path
          fill={
            props.disabled
              ? IconColors["icon-disabled"]
              : IconColors["icon-dark-blue"]
          }
          d="M14.56,1.17l7.67,7.66V21.16a1.72,1.72,0,0,1-1.71,1.72H8a1.62,1.62,0,0,1-1.21-.5,1.67,1.67,0,0,1-.51-1.21V2.89A1.71,1.71,0,0,1,7.93,1.17h6.63m.23-.58H7.93A2.29,2.29,0,0,0,5.65,2.89V21.17A2.29,2.29,0,0,0,8,23.46H20.52a2.29,2.29,0,0,0,2.29-2.29V8.59l-8-8Z"
        />
        <path
          fill={
            props.disabled
              ? IconColors["icon-disabled"]
              : IconColors["icon-light-blue"]
          }
          d="M14.79.88V6.31a2.28,2.28,0,0,0,2.28,2.28h5.43Z"
        />
        <path
          fill={
            props.disabled
              ? IconColors["icon-disabled"]
              : IconColors["icon-dark-blue"]
          }
          d="M22.51,8.88H17.08a2.57,2.57,0,0,1-2.57-2.57V.88A.28.28,0,0,1,15,.68l7.72,7.71a.28.28,0,0,1-.2.49ZM15.08,1.57V6.31a2,2,0,0,0,2,2h4.74Z"
        />
        <rect
          fill={
            props.disabled
              ? IconColors["icon-disabled"]
              : IconColors["icon-blue"]
          }
          x="1.09"
          y="11.17"
          width="20"
          height="9.14"
          rx="0.86"
        />
        <path
          fill={
            props.disabled
              ? IconColors["icon-disabled"]
              : IconColors["icon-dark-blue"]
          }
          d="M20.23,11.73a.28.28,0,0,1,.28.29v7.43a.28.28,0,0,1-.29.28L2,19.75a.29.29,0,0,1-.29-.29V12a.27.27,0,0,1,.28-.28l18.29,0m0-.57H1.94a.87.87,0,0,0-.86.86v7.43a.86.86,0,0,0,.86.86H20.23a.87.87,0,0,0,.86-.86V12a.86.86,0,0,0-.85-.86Z"
        />
        <path
          fill="white"
          d="M7.78,15.6a1.26,1.26,0,0,1-.55.52,2,2,0,0,1-.94.2H5.53v1.76H4.61V13.49H6.29a1.94,1.94,0,0,1,.91.19,1.25,1.25,0,0,1,.56.5,1.4,1.4,0,0,1,.19.73A1.47,1.47,0,0,1,7.78,15.6Zm-1-.2A.64.64,0,0,0,7,14.91c0-.44-.25-.67-.75-.67H5.52v1.34h.73A.78.78,0,0,0,6.82,15.4Z"
        />
        <path
          fill="white"
          d="M12.52,18.08H11.6L9.52,14.93v3.15H8.6V13.49h.92l2.08,3.15V13.48h.92Z"
        />
        <path
          fill="white"
          d="M16.62,14.87a1,1,0,0,0-.43-.44,1.36,1.36,0,0,0-.65-.15,1.43,1.43,0,0,0-.72.18,1.26,1.26,0,0,0-.49.53,1.65,1.65,0,0,0-.18.79,1.71,1.71,0,0,0,.18.8,1.28,1.28,0,0,0,.51.52,1.49,1.49,0,0,0,.75.19,1.35,1.35,0,0,0,.86-.28,1.38,1.38,0,0,0,.44-.78H15.31v-.71h2.48v.81a2.11,2.11,0,0,1-.39.88,2.29,2.29,0,0,1-.78.66,2.34,2.34,0,0,1-1.07.24,2.53,2.53,0,0,1-1.2-.29,2.26,2.26,0,0,1-.84-.84,2.38,2.38,0,0,1-.3-1.2,2.45,2.45,0,0,1,.3-1.22,2.16,2.16,0,0,1,.84-.83,2.42,2.42,0,0,1,1.19-.3,2.33,2.33,0,0,1,1.35.38,1.89,1.89,0,0,1,.79,1.06Z"
        />
      </svg>
    </Blank>
  );
};
export default PNG;
