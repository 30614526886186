import React from "react";
import { Blank, IconProps } from "grommet-icons";
import { IconColors } from "../../theme/GlobalTheme";
import { ChildrenIconProps } from "./FileTypeIcon";

const VMA: React.FC<IconProps & ChildrenIconProps> = (props) => {
  return (
    <Blank viewBox="0 0 39 42" {...props} color="plain">
      <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
      >
        <path
          fill="white"
          d="M14.83.59H8a2.29,2.29,0,0,0-1.62.67,2.32,2.32,0,0,0-.66,1.62V21.17A2.29,2.29,0,0,0,8,23.46H20.56a2.29,2.29,0,0,0,2.28-2.29V8.59Z"
        />
        <path
          fill={
            props.disabled
              ? IconColors["icon-disabled"]
              : IconColors["icon-dark-blue"]
          }
          d="M14.59,1.17l7.67,7.66V21.16a1.72,1.72,0,0,1-1.71,1.72H8a1.65,1.65,0,0,1-1.21-.5,1.7,1.7,0,0,1-.5-1.21V2.89A1.71,1.71,0,0,1,8,1.17h6.62m.24-.58H8a2.29,2.29,0,0,0-1.62.67,2.32,2.32,0,0,0-.66,1.62V21.17A2.29,2.29,0,0,0,8,23.46H20.56a2.29,2.29,0,0,0,2.28-2.29V8.59l-8-8Z"
        />
        <path
          fill={
            props.disabled
              ? IconColors["icon-disabled"]
              : IconColors["icon-light-blue"]
          }
          d="M14.83.88V6.31a2.29,2.29,0,0,0,2.29,2.28h5.43Z"
        />
        <path
          fill={
            props.disabled
              ? IconColors["icon-disabled"]
              : IconColors["icon-dark-blue"]
          }
          d="M22.55,8.88H17.12a2.58,2.58,0,0,1-2.58-2.57V.88a.27.27,0,0,1,.18-.26A.28.28,0,0,1,15,.68l7.72,7.71a.29.29,0,0,1,.06.31A.3.3,0,0,1,22.55,8.88ZM15.11,1.57V6.31a2,2,0,0,0,2,2h4.74Z"
        />
        <rect
          fill={
            props.disabled
              ? IconColors["icon-disabled"]
              : IconColors["icon-blue"]
          }
          x="1.12"
          y="11.17"
          width="20"
          height="9.14"
          rx="0.86"
        />
        <path
          fill={
            props.disabled
              ? IconColors["icon-disabled"]
              : IconColors["icon-dark-blue"]
          }
          d="M20.26,11.73a.29.29,0,0,1,.29.29v7.43a.27.27,0,0,1-.28.28L2,19.75a.28.28,0,0,1-.28-.29V12A.28.28,0,0,1,2,11.75l18.28,0m0-.57H2a.86.86,0,0,0-.86.86v7.43a.86.86,0,0,0,.85.86H20.27a.87.87,0,0,0,.86-.86V12a.86.86,0,0,0-.86-.86Z"
        />
        <path
          fill="white"
          d="M8.05,13.49,6.37,18.08H5.26L3.57,13.49h1l1.26,3.65,1.25-3.65Z"
        />
        <path
          fill="white"
          d="M13.6,13.49v4.59h-.92v-3l-1.23,3h-.7l-1.23-3v3H8.61V13.49H9.65l1.46,3.41,1.46-3.41Z"
        />
        <path
          fill="white"
          d="M17.33,17.2H15.5l-.3.87h-1l1.65-4.59H17l1.65,4.59h-1Zm-.25-.74-.67-1.92-.66,1.92Z"
        />
      </svg>
    </Blank>
  );
};
export default VMA;
