import { Syllabus } from "../../../model/Syllabus";

export enum OnboardingActionType {
  SaveTempSyllabus = "SaveTempSyllabus",
  DeleteTempSyllabus = "DeleteTempSyllabus",
}

export interface SaveTempSyllabus {
  type: typeof OnboardingActionType.SaveTempSyllabus;
  payload: Syllabus;
}

export interface DeleteTempSyllabus {
  type: typeof OnboardingActionType.DeleteTempSyllabus;
  payload: undefined;
}

export type OnboardingActions = SaveTempSyllabus | DeleteTempSyllabus;
