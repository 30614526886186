import React, { useState } from "react";
import { Material, Materials } from "../../../model/Syllabus";
import SectionEditorProps from "./SectionEditorProps";
import useDelayedState from "../../../hooks/useDelayedState";
import TextInputListItem from "../../TextInputListItem";
import TextInputList from "../../TextInputList";
import { HidableComponent } from "components/hoc/Hidable";
import Alert from "components/Alert";

interface MaterialEditorProps {
  material: Material;
  onChange: (material: Material) => void;
  onDelete: () => void;
  error?: string;
  index: number;
}

const MaterialEditor: React.FC<MaterialEditorProps> = (props) => {
  const [material, setMaterial] = useDelayedState<Material>(
    props.material,
    props.onChange
  );

  return (
    <TextInputListItem
      label={`Material ${(props.index ?? 0) + 1}`}
      required={true}
      error={props.error}
      autoFocus={true}
      gridA11yTitle={"Add materials"}
      value={material.body}
      boxA11yTitle={""}
      inputA11yTitle={"Material input"}
      inputPlaceholder={"Add supplemental and required materials."}
      onInputChange={(event: any) => {
        if (event.target.value !== undefined) {
          setMaterial({
            ...material,
            body: event.target.value,
          });
        }
      }}
      buttonA11yTitle={"Button to remove this material"}
      onButtonClick={() => {
        props.onDelete();
      }}
    />
  );
};

// Editor for list of materials:
// - add a material item
// - change materials
//
const MaterialsEditor: React.FC<SectionEditorProps> = ({
  section,
  onChange,
  error,
}) => {
  const [_section, _setSection] = useState({ ...(section as Materials) });
  const [indexOffset, setIndexOffset] = useState(0);
  // Propagate changes to parent
  const setSection = (newSection: Materials) => {
    onChange(newSection);
    _setSection(newSection);
  };

  // Replace an existing material item
  const onChangeMaterial = (material: Material, index: number) => {
    const newMaterials = [..._section.materials];
    newMaterials[index] = material;
    setSection({
      ..._section,
      materials: newMaterials,
    });
  };

  // Append a new material to the section
  const addMaterial = () => {
    setSection({
      ..._section,
      materials: [..._section.materials, { body: {}, id: "" } as Material],
    });
  };

  // Delete a material from the section
  const deleteMaterial = (index: number) => {
    const newMaterials = _section;
    newMaterials.materials.splice(index, 1);
    setSection(newMaterials);
    setIndexOffset(indexOffset + newMaterials.materials.length + 1);
  };

  // Render
  return (
    <>
      <TextInputList
        gridA11yTitle={""}
        boxA11yTitle={""}
        buttonA11yTitle={"Button to add more materials"}
        addContent={() => addMaterial()}
        buttonText={"Add Materials"}
      >
        {_section.materials.map((material, index) => (
          <MaterialEditor
            key={"material-" + index + indexOffset}
            material={material}
            onChange={(material) => onChangeMaterial(material, index)}
            onDelete={() => deleteMaterial(index)}
            error={error?.errors[`materials[${index}].body`]}
            index={index}
          />
        ))}
      </TextInputList>
      <HidableComponent isVisible={!!error?.errors.materials}>
        <Alert>{error?.errors.materials}</Alert>
      </HidableComponent>
    </>
  );
};

export default MaterialsEditor;
