import { youtubeConfig } from "../../config/config";
import axios from "axios";
import { VideoResponse } from "./VideoResponse";
import { VideoResource } from "../../model/Resource";
import { Result } from "../../utils/Result";

export default class YoutubeSDK {
  async search(query: string): Promise<Result<VideoResource[]>> {
    try {
      const results = await axios.get(
        `https://www.googleapis.com/youtube/v3/search?q=${query}&term=${query}&key=${youtubeConfig.key}&part=snippet&type=video&maxResults=50`
      );
      const data = results.data as VideoResponse;

      return Result.ok(
        data.items.map(
          (item) =>
            ({
              id: item.id.videoId,
              title: item.snippet.title,
              type: "Video",
              youtubeId: item.id.videoId,
              url: `https://youtu.be/${item.id.videoId}`,
              image: item.snippet.thumbnails.high.url,
              snippet: {
                channelTitle: item.snippet.channelTitle,
                publishedAt: item.snippet.publishedAt,
              },
            } as VideoResource)
        )
      );
    } catch (e) {
      return Result.fail(e.message);
    }
  }
}
